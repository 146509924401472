import React, { Component } from 'react';
import { bool, func, any, object, string } from 'prop-types';
import classnames from 'classnames';
import { Tooltip } from '@payscale/design';
import './CheckboxInput.scss';

class CheckboxInput extends Component {
  static propTypes = {
    checked: bool,
    disabled: bool,
    onClick: func,
    id: any,
    style: object,
    className: string,
    tooltipText: string
  };

  onClickHandler = event => {
    if (this.props.onClick) {
      if (this.props.id !== undefined) {
        this.props.onClick(this.props.id);
        return;
      }
      this.props.onClick(event);
    }
  };

  render() {
    const { checked, disabled, style, className, tooltipText } = this.props;
    const checkboxClass = classnames(
      'checkbox icon-border icon-check-empty',
      {
        disabled: disabled
      },
      className
    );
    const iconClass = classnames({
      icon: true,
      'icon-check': checked,
      disabled: disabled
    });

    return (
      <div style={style} className={checkboxClass} onClick={disabled ? null : this.onClickHandler}>
        <i className={iconClass} />

        {tooltipText && (
          <Tooltip className="checkbox-tooltip" hoverText={tooltipText} id="checkbox-tooltip" position="bottom" />
        )}
      </div>
    );
  }
}

export default CheckboxInput;
