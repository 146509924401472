const DASHBOARD_EMPLOYEES = 'DASHBOARD_EMPLOYEES';
const DASHBOARD_EMPLOYEES_SUCCESS = 'DASHBOARD_EMPLOYEES_SUCCESS';
const DASHBOARD_EMPLOYEES_FAIL = 'DASHBOARD_EMPLOYEES_FAIL';
const DASHBOARD_MARK_AS_SEEN = 'DASHBOARD_MARK_AS_SEEN';
const DASHBOARD_MARK_AS_SEEN_SUCCESS = 'DASHBOARD_MARK_AS_SEEN_SUCCESS';
const DASHBOARD_MARK_AS_SEEN_FAIL = 'DASHBOARD_MARK_AS_SEEN_FAIL';
const DASHBOARD_DISMISS_EMPLOYEE = 'DASHBOARD_DISMISS_EMPLOYEE';
const DASHBOARD_DISMISS_EMPLOYEE_SUCCESS = 'DASHBOARD_DISMISS_EMPLOYEE_SUCCESS';
const DASHBOARD_DISMISS_EMPLOYEE_FAIL = 'DASHBOARD_DISMISS_EMPLOYEE_FAIL';
const DASHBOARD_SET_MARKET_OR_RANGE = 'DASHBOARD_SET_MARKET_OR_RANGE';

const initialState = {
  loading: false,
  error: null,
  marketOrRange: (window.localStorage.getItem('employees-facets-outliers_toggle') || 'Market').toLowerCase(),
  marketOutliersEmployees: [],
  rangeOutliersEmployees: [],
  employeeToMarketStats: {},
  employeeToRangeStats: {}
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case DASHBOARD_EMPLOYEES:
      return {
        ...state,
        loading: true,
        error: null
      };
    case DASHBOARD_EMPLOYEES_SUCCESS:
      return {
        ...state,
        loading: false,
        marketOutliersEmployees: action.result.market_employees.data,
        rangeOutliersEmployees: action.result.range_employees.data,
        employeeToRangeStats: getEmployeeStats(action.result.range_employees.summaryStats),
        employeeToMarketStats: getEmployeeStats(action.result.market_employees.summaryStats)
      };
    case DASHBOARD_EMPLOYEES_FAIL:
      return {
        ...state,
        loading: false,
        error: action && action.result && action.result.reason ? action.result.reason : 'Failed to load a reason',
        marketOutliersEmployees: [],
        rangeOutliersEmployees: []
      };
    case DASHBOARD_MARK_AS_SEEN:
      return {
        ...state,
        error: null
      };
    case DASHBOARD_MARK_AS_SEEN_SUCCESS:
      return {
        ...state
      };
    case DASHBOARD_MARK_AS_SEEN_FAIL:
      return {
        ...state,
        error: action.result.reason
      };
    case DASHBOARD_DISMISS_EMPLOYEE:
      return {
        ...state
      };
    case DASHBOARD_DISMISS_EMPLOYEE_SUCCESS:
      return {
        ...state,
        marketOutliersEmployees:
          state.marketOrRange === 'market'
            ? markEmployeeAsDismissed(state.marketOutliersEmployees, action.employeeNumber)
            : state.marketOutliersEmployees,
        rangeOutliersEmployees:
          state.marketOrRange === 'range'
            ? markEmployeeAsDismissed(state.rangeOutliersEmployees, action.employeeNumber)
            : state.rangeOutliersEmployees
      };
    case DASHBOARD_DISMISS_EMPLOYEE_FAIL:
      return {
        ...state,
        error: action.result.reason
      };
    case DASHBOARD_SET_MARKET_OR_RANGE:
      return {
        ...state,
        marketOrRange: action.marketOrRange.toLowerCase()
      };

    default:
      return { ...state };
  }
}

export function getDashboardEmployees() {
  return {
    types: [DASHBOARD_EMPLOYEES, DASHBOARD_EMPLOYEES_SUCCESS, DASHBOARD_EMPLOYEES_FAIL],
    promise: client => client.get('/api/baby-panther/dashboard/employees')
  };
}

export function markAsSeen() {
  return (dispatch, getState) => {
    const state = getState().employeesToMarket;
    const marketOrRange = state.marketOrRange;
    const employeeNumbers = (marketOrRange === 'market' ? state.marketOutliersEmployees : state.rangeOutliersEmployees)
      .filter(emp => !emp.dismissed)
      .slice(0, 5)
      .filter(emp => !emp.seen)
      .map(emp => emp._source.employee_number_none);
    if (employeeNumbers.length) {
      dispatch({
        types: [DASHBOARD_MARK_AS_SEEN, DASHBOARD_MARK_AS_SEEN_SUCCESS, DASHBOARD_MARK_AS_SEEN_FAIL],
        promise: client =>
          client.post('/api/baby-panther/dashboard/markAsSeen', {
            params: { employeeNumbers, outlierType: marketOrRange }
          })
      });
    }
  };
}

export function dismiss(employeeNumber) {
  return (dispatch, getState) => {
    const state = getState().employeesToMarket;
    const marketOrRange = state.marketOrRange;
    dispatch({
      types: [DASHBOARD_DISMISS_EMPLOYEE, DASHBOARD_DISMISS_EMPLOYEE_SUCCESS, DASHBOARD_DISMISS_EMPLOYEE_FAIL],
      promise: client =>
        client.post('/api/baby-panther/dashboard/dismiss', {
          params: {
            employeeNumber,
            outlierType: marketOrRange
          }
        }),
      employeeNumber
    })
      .then(() => {
        dispatch(markAsSeen());
      })
      .catch(err => {
        console.error('Error dismissing dashboard employees', err);
      });
  };
}

export function setMarketOrRange(marketOrRange) {
  window.localStorage.setItem('employees-facets-outliers_toggle', marketOrRange);
  return (dispatch, getState) => {
    dispatch({
      type: DASHBOARD_SET_MARKET_OR_RANGE,
      marketOrRange
    });
  };
}

function getEmployeeStats(summaryStats) {
  let employeeToMarketStatDetail = [];
  for (let i = 0; i < summaryStats.length; i++) {
    if (['emp_count_to_market', 'pay_to_market', 'emp_count_to_range', 'pay_to_range'].includes(summaryStats[i].key)) {
      for (let j = 0; j < summaryStats[i].details.length; j++) {
        employeeToMarketStatDetail.push(summaryStats[i].details[j]);
      }
    }
  }

  let employeeToMarketStats = {};
  for (let i = 0; i < employeeToMarketStatDetail.length; i++) {
    switch (employeeToMarketStatDetail[i].label) {
      case '<10th':
        employeeToMarketStats['totalBelow'] = employeeToMarketStatDetail[i].value;
        break;
      case '>90th':
        employeeToMarketStats['totalAbove'] = employeeToMarketStatDetail[i].value;
        break;
      case 'Overpaid':
        employeeToMarketStats['overPaid'] = employeeToMarketStatDetail[i].value;
        break;
      case 'Underpaid':
        employeeToMarketStats['underPaid'] = employeeToMarketStatDetail[i].value;
        break;
      case 'Above Range':
        employeeToMarketStats['totalAbove'] = employeeToMarketStatDetail[i].value;
        break;
      case 'Below Range':
        employeeToMarketStats['totalBelow'] = employeeToMarketStatDetail[i].value;
        break;
    }
  }

  return employeeToMarketStats;
}

function markEmployeeAsDismissed(employeeList, employeeNumber) {
  for (let i = 0; i < employeeList.length; i++) {
    if (employeeList[i]._source.employee_number_none === employeeNumber) {
      employeeList[i].dismissed = true;
      break;
    }
  }
  return employeeList;
}
