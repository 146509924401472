import React, { Component } from 'react';
import { bool, func, string } from 'prop-types';
import classnames from 'classnames';
import Select from '../../Select/Select';
import { BUILD_BY_MANUAL, BUILD_BY_PERCENTAGE, BUILD_BY_TARGET } from '../../Utils/constants';
import './BuildMinMaxByDropdown.scss';

const dropdownValues = [
  { name: 'Target Range Spread', value: BUILD_BY_TARGET },
  { name: 'Percentage of Mid', value: BUILD_BY_PERCENTAGE },
  { name: 'Manual', value: BUILD_BY_MANUAL }
];

class BuildMinMaxByDropdown extends Component {
  static propTypes = {
    buildMinMaxBy: string,
    isGlobal: bool,
    rangeType: string,
    saveBuildMinMaxBy: func
  };

  constructor(props) {
    super(props);
  }

  handleChangeMinMaxBy = e => {
    const { saveBuildMinMaxBy } = this.props;
    const saveValue = dropdownValues.find(item => item.name === e.name)?.value;
    saveBuildMinMaxBy(saveValue);
  };

  render() {
    const { buildMinMaxBy, isGlobal, rangeType } = this.props;
    const dropdownItems =
      isGlobal || rangeType !== BUILD_BY_MANUAL
        ? dropdownValues.filter(item => item.value !== BUILD_BY_MANUAL)
        : dropdownValues;

    return (
      <div className={classnames('jbr__dropdown--container', { 'jbr__dropdown-alternate': !isGlobal })}>
        <h5 className="jbr__dropdown--label">Build Min and Max by</h5>
        <Select
          btnClass="btn-dark"
          dropdownClass="dropdown-dark"
          onSelect={this.handleChangeMinMaxBy}
          options={dropdownItems}
          defaultOption={dropdownValues.find(option => option.value === buildMinMaxBy)}
        />
      </div>
    );
  }
}

export default BuildMinMaxByDropdown;
