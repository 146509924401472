const OPEN_SCENARIO_AS_STRUCTURE = 'OPEN_SCENARIO_AS_STRUCTURE';
const CLOSE_SCENARIO_AS_STRUCTURE = 'CLOSE_SCENARIO_AS_STRUCTURE';

const SAVE_LIVE_STRUCTURE = 'SAVE_LIVE_STRUCTURE';
const SAVE_LIVE_STRUCTURE_SUCCESS = 'SAVE_LIVE_STRUCTURE_SUCCESS';
const SAVE_LIVE_STRUCTURE_FAIL = 'SAVE_LIVE_STRUCTURE_FAIL';

const REVIEW_CHANGES = 'REVIEW_CHANGES';
const REVIEW_CHANGES_SUCCESS = 'REVIEW_CHANGES_SUCCESS';
const REVIEW_CHANGES_FAIL = 'REVIEW_CHANGES_FAIL';

import { getModelAndStructure } from './modelStructureList';

const initialState = {
  loading: false,
  loaded: false,
  error: null,
  reviewChangesLoading: false,
  scenarioAsStructure: false,
  reviewChangesList: []
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case OPEN_SCENARIO_AS_STRUCTURE:
      return {
        ...state,
        scenarioAsStructure: true
      };
    case CLOSE_SCENARIO_AS_STRUCTURE:
      return {
        ...state,
        scenarioAsStructure: false
      };
    case SAVE_LIVE_STRUCTURE:
      return {
        ...state,
        loading: true
      };
    case SAVE_LIVE_STRUCTURE_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        scenarioAsStructure: false,
        error: null
      };
    case SAVE_LIVE_STRUCTURE_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        data: null,
        error: action.error
      };
    case REVIEW_CHANGES:
      return {
        ...state,
        loading: true,
        reviewChangesLoading: true
      };
    case REVIEW_CHANGES_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        reviewChangesLoading: false,
        reviewChangesList: action.result,
        error: null
      };
    case REVIEW_CHANGES_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        reviewChangesLoading: false,
        data: null,
        error: action.error
      };
    default:
      return { ...state };
  }
}

export function openScenarioAsStructure() {
  return {
    type: 'OPEN_SCENARIO_AS_STRUCTURE'
  };
}

export function closeScenarioAsStructure() {
  return {
    type: 'CLOSE_SCENARIO_AS_STRUCTURE'
  };
}

export function saveLiveStructure(modelId, salary_display_code, history) {
  return (dispatch, getState) => {
    const createAjax = dispatch({
      types: [SAVE_LIVE_STRUCTURE, SAVE_LIVE_STRUCTURE_SUCCESS, SAVE_LIVE_STRUCTURE_FAIL],
      promise: client =>
        client.post('api/structures/saveStructures/saveModelAsStructure', {
          params: { modelId: modelId, model_salary_display_code: salary_display_code }
        })
    });
    createAjax
      .then(function getCurrentStructureSelect(results) {
        if (results.success) {
          dispatch(getModelAndStructure())
            .then(list => {
              history.push('/structure-model/' + 'structure-' + results.structure_code_hash);
            })
            .catch(err => {
              console.error('Error getting model and structure', err);
            });
        }
      })
      .catch(err => {
        console.error('Error saving model as structure', err);
      });
  };
}

export function loadSaveStructureReviewChanges(options) {
  return {
    types: [REVIEW_CHANGES, REVIEW_CHANGES_SUCCESS, REVIEW_CHANGES_FAIL],
    promise: client =>
      client.get('api/structures/saveStructures/getListOfSaveChanges', {
        params: { modelId: options.modelId }
      })
  };
}
