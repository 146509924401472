import PropTypes from 'prop-types';
import React, { Component } from 'react';

export default class SimpleSlideAnimation extends Component {
  static propTypes = {
    show: PropTypes.bool,
    className: PropTypes.string,
    children: PropTypes.any
  };

  constructor(props) {
    super(props);
    this.state = {
      transitionClass: false,
      removeContainer: true,
      overflowHidden: true,
      show: props.show
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const props = this.props;
    if (nextProps.show !== props.show && nextProps.show) {
      this.setState({ ...this.state, removeContainer: false, show: false });
      setTimeout(() => {
        this.setState({ ...this.state, show: true });
      }, 40);
      setTimeout(() => {
        this.setState({ ...this.state, overflowHidden: false });
      }, 500); // 40ms delay plus 400ms animation plus a little extra
    } else {
      if (!nextProps.show && this.state.show) {
        this.setState({ ...this.state, show: nextProps.show, overflowHidden: true });
      } else {
        this.setState({ ...this.state, show: nextProps.show });
      }
    }
  }

  componentDidUpdate(prevProps) {
    const props = this.props;
    if (prevProps.show !== props.show && !props.show) {
      setTimeout(() => {
        this.setState({ ...this.state, removeContainer: true });
      }, 400); // eslint-disable-line react/no-did-update-set-state
    }
  }

  render() {
    const transitionClass = this.state.show ? ' transitionIn' : '';
    const overflowClass = this.state.overflowHidden ? '' : ' overflow-visible';

    let component = (
      <div className={'simpleSlide__hidden-container' + overflowClass}>
        <div className={'simpleSlide__animation-container' + transitionClass}>{this.props.children}</div>
      </div>
    );

    if (this.state.removeContainer) {
      component = null;
    }

    return component;
  }
}
