import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import clearSearch from './SearchBar';

export default class SearchBarResults extends Component {
  static propTypes = {
    propTypesIsNowUsed: PropTypes.object,
    InputGroup: PropTypes.object,
    type: PropTypes.string,
    doUniversalSearch: PropTypes.func,
    universalSearch: PropTypes.object,
    setSearchResultVisibility: PropTypes.func,
    setSearchValue: PropTypes.func,
    resetSearchResults: PropTypes.func,
    jobsPageSearch: PropTypes.func,
    setFocusLink: PropTypes.func,
    showFullSearchStyles: PropTypes.bool,
    history: PropTypes.object,
    closeFullSearchBar: PropTypes.func
  };

  constructor(props) {
    super(props);
    this.allChildren = [];
    this.rowCnt = 0;
    this.link = '';
    this.rowLinkClick = this.rowLinkClick.bind(this);
    this.clearSearch = clearSearch;
  }

  componentDidUpdate() {
    this.props.setFocusLink(this.link, this.docTitle);
  }

  getCategoryInfo(searchType) {
    for (let idx = 0; idx < this.props.universalSearch.searchTypes.length; idx++) {
      const currSearchType = this.props.universalSearch.searchTypes[idx];
      if (searchType === currSearchType.doc_type) {
        return currSearchType;
      }
    }
  }

  initCap(str) {
    return str.toLowerCase().replace(/(?:^|\s)[a-z]/g, function strInitFn(strInit) {
      return strInit.toUpperCase();
    });
  }

  rowLinkClick(link, docTitle, docId) {
    this.props.history.push('/' + link);
    setTimeout(this.props.resetSearchResults, 500);
    this.props.closeFullSearchBar();
  }

  createSection(categoryName, categoryRows) {
    const catInfo = this.getCategoryInfo(categoryName);

    if (categoryRows.length > 0) {
      this.hasData = true;
    } else {
      this.hasData = false;
    }

    return (
      <div className="table--genericGrid table--searchResults" key={'search-results-' + categoryName}>
        <div className={'table__header'}>
          <div>
            <span className="table__heading">
              <i className={catInfo.icon} />
              {catInfo.pretty_name ? catInfo.pretty_name : this.initCap(categoryName)}
            </span>
          </div>
        </div>
        <ul className={'table__body'}>
          {categoryRows.map(searchRow => {
            let link = catInfo.base_url + '/detail/' + searchRow._id;
            let iframedPage = false;
            if (catInfo.base_url === 'users') {
              link = 'user-administration/' + searchRow.fullText;
            }

            if (link.indexOf('#dashboards/') !== -1 || link.indexOf('#reportWriter/') !== -1) {
              iframedPage = true;
              link = `${catInfo.base_url}/${searchRow._id}`;
            }

            this.rowCnt++; // eslint-disable-line no-param-reassign
            let rowFocusCls = '';
            if (this.rowCnt === this.props.universalSearch.focusRowIdx) {
              rowFocusCls = 'table__body--row--focus';
              this.link = link;
              this.docTitle = searchRow.fullText;
            }

            let row;
            if (link.match('#') && !iframedPage) {
              // error is not to be fixed until reactjs upgrade: https://github.com/facebook/react/issues/6911
              row = (
                <li className={'table__body--row ' + rowFocusCls} key={'search-result-doc' + searchRow._id}>
                  <a href={window.app.apiPrefix + link}>{searchRow.fullText}</a>
                </li>
              );
            } else {
              // error is not to be fixed until reactjs upgrade: https://github.com/facebook/react/issues/6911
              row = (
                <li className={'table__body--row ' + rowFocusCls} key={'search-result-doc' + searchRow._id}>
                  <a onClick={this.rowLinkClick.bind(this, link, searchRow.fullText, searchRow._id)}>
                    {searchRow.fullText}
                  </a>
                </li>
              );
            }

            return row;
          })}
        </ul>
      </div>
    );
  }

  render() {
    const searchResults = this.props.universalSearch.items;
    const searchBar = this;
    const showFullSearchStyles = this.props.showFullSearchStyles;

    this.rowCnt = 0;
    const searchSections = [];
    if (searchResults.category) {
      Object.keys(searchResults.category).forEach(function handleCat(searchCategory) {
        searchSections.push(searchBar.createSection(searchCategory, searchResults.category[searchCategory]));
      });
    }
    const border = this.props.universalSearch.items.rowCount > 0 ? 'searchBar__searchResults--border' : '';

    return (
      <div>
        <div
          className={'searchBar--searchResults ' + border + (showFullSearchStyles ? ' fullSearch ' : '')}
          ref="results"
        >
          {searchSections}
        </div>
      </div>
    );
  }
}
