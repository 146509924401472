/**
 * Retrieves the current browser name and version.
 * NOTE: This is a direct port of the old tcl code in merit/www/ticketing/ticket-add.adp's
 * get_browser() and get_browser_version() with added support for MS Edge
 * @returns {string} browser name + version
 */
export const getBrowser = () => {
  const userAgent = navigator.userAgent;
  let temp;
  let matches = userAgent.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];

  if (/trident/i.test(matches[1])) {
    temp = /\brv[ :]+(\d+)/g.exec(userAgent) || [];
    return 'IE ' + (temp[1] || '');
  }
  if (matches[1] === 'Chrome') {
    temp = userAgent.match(/\bOPR\/(\d+)/);
    if (temp != null) {
      return 'Opera ' + temp[1];
    }
    temp = userAgent.match(/\bEdge\/(\d+)/);
    if (temp != null) {
      return 'Edge ' + temp[1];
    }
  }
  matches = matches[2] ? [matches[1], matches[2]] : [navigator.appName, navigator.appVersion, '-?'];
  if ((temp = userAgent.match(/version\/(\d+)/i)) != null) {
    matches.splice(1, 1, temp[1]);
  }
  return `${matches[0]} ${matches[1]}`;
};
