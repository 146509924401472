const STRUCTURE_CHART = 'STRUCTURE_CHART';
const STRUCTURE_CHART_SUCCESS = 'STRUCTURE_CHART_SUCCESS';
const STRUCTURE_CHART_FAIL = 'STRUCTURE_CHART_FAIL';
import { updateChart } from './currentStructure';

const initialState = {
  loading: false,
  loaded: false,
  error: null,
  chart: {}
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case STRUCTURE_CHART:
      return {
        ...state,
        loading: true
      };
    case STRUCTURE_CHART_SUCCESS:
      return {
        ...state,
        loading: action.updateChart ? true : false,
        loaded: action.updateChart ? false : true,
        error: null,
        highChartsJson: action.result
      };
    case STRUCTURE_CHART_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        data: null,
        error: action.error
      };
    default:
      return { ...state };
  }
}

export function getStructureChart(options) {
  return (dispatch, getState) => {
    const ajax = dispatch({
      types: [STRUCTURE_CHART, STRUCTURE_CHART_SUCCESS, STRUCTURE_CHART_FAIL],
      promise: client =>
        client.get('/api/structures/structure-graph/createStructureGraph', {
          params: options
        }),
      updateChart: options.mode === 'existing' && options.model_id ? true : false
    });
    ajax
      .then(function getUpdatedChart(results) {
        if (options.model_id && options.mode == 'existing') {
          dispatch(updateChart(getState().structureChart.highChartsJson));
        }
      })
      .catch(err => {
        console.error('Error creating structure graph', err);
      });
  };
}
