import React, { Component } from 'react';
import SummaryCard from './SummaryCard';
import './SummaryPanel.scss';
import { array, func, bool, string } from 'prop-types';

class SummaryPanel extends Component {
  static propTypes = {
    duplicateMatches: array.isRequired,
    zeroWeightedMatches: array.isRequired,
    generateReport: func.isRequired,
    navigateNextStep: func.isRequired,
    hideSlideout: func.isRequired,
    jobsWithNoEmployees: string,
    isLab: bool
  };

  constructor(props) {
    super(props);
    this.skipReviewAndGenerateReport = this.skipReviewAndGenerateReport.bind(this);
  }

  skipReviewAndGenerateReport() {
    this.props.generateReport(this.props.hideSlideout);
  }

  render() {
    const { navigateNextStep, duplicateMatches, zeroWeightedMatches, isLab, jobsWithNoEmployees } = this.props;
    const actionVerbiage = isLab ? 'Include all matches & generate report' : 'Skip review & generate report';

    return (
      <div className="pcenter-match-review-summary-panel">
        <SummaryCard
          duplicateMatchesCount={duplicateMatches.length}
          zeroWeightedMatchesCount={zeroWeightedMatches.length}
          isLab={isLab}
        />
        {isLab ? (
          <div className="extra-summary">
            <h4 className="extra-summary__header">Matches will automatically be excluded from the report</h4>
            <div className="extra-summary__body">
              <span className="extra-summary__category">Internal Jobs with no employees</span>
              <h2 className="extra-summary__value">{jobsWithNoEmployees}</h2>
            </div>
          </div>
        ) : null}
        <div className="pcenter-match-review-summary-panel__actions">
          <a className="pcenter-match-review-summary-panel__action-link" onClick={this.skipReviewAndGenerateReport}>
            {actionVerbiage}
          </a>
          <button className="pxl-btn pxl-btn-primary" onClick={navigateNextStep}>
            Review Matches
          </button>
        </div>
      </div>
    );
  }
}

export default SummaryPanel;
