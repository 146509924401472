const CERTS_JOB_LIST = 'CERTS_JOB_LIST';
const CERTS_JOB_LIST_SUCCESS = 'CERTS_JOB_LIST_SUCCESS';
const CERTS_JOB_LIST_FAIL = 'CERTS_JOB_LIST_FAIL';
const CERTIFICATIONS_SEARCH_LIST = 'CERTIFICATIONS_SEARCH_LIST';
const CERTIFICATIONS_SEARCH_LIST_SUCCESS = 'CERTIFICATIONS_SEARCH_LIST_SUCCESS';
const CERTIFICATIONS_SEARCH_LIST_FAIL = 'CERTIFICATIONS_SEARCH_LIST_FAIL';

const initialState = {
  loading: false,
  loaded: false,
  error: null,
  items: [],
  certsSearchItems: [],
  allCerts: []
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case CERTS_JOB_LIST:
      return {
        ...initialState,
        loading: true
      };
    case CERTS_JOB_LIST_SUCCESS:
      let reportAnswers = []; // eslint-disable-line prefer-const
      let certList = action.certList; // eslint-disable-line prefer-const
      if (!certList) {
        certList = action.result;
      }
      if (certList && certList.length) {
        certList.map(answer => {
          reportAnswers.push({ value: answer.answer, label: answer.answer });
        });
      }
      return {
        ...state,
        loading: false,
        loaded: true,
        error: null,
        items: reportAnswers,
        allCerts: certList
      };
    case CERTS_JOB_LIST_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        data: null,
        error: action.error
      };
    case CERTIFICATIONS_SEARCH_LIST:
      return {
        ...state,
        loading: true
      };
    case CERTIFICATIONS_SEARCH_LIST_SUCCESS:
      let searchAnswers = []; // eslint-disable-line prefer-const
      let searchCertList = action.certList; // eslint-disable-line prefer-const
      if (!searchCertList) {
        searchCertList = action.result;
      }
      if (searchCertList && searchCertList.length) {
        searchCertList.map(answer => {
          searchAnswers.push(answer.answer);
        });
      }
      action.searchCallback(action.query.searchParam, searchAnswers);
      return {
        ...state,
        loading: false,
        loaded: true,
        error: null,
        certsSearchItems: action.result
      };
    case CERTIFICATIONS_SEARCH_LIST_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        data: null,
        error: action.error
      };
    default:
      return { ...state };
  }
}

export function getJobCerts(options) {
  return {
    types: [CERTS_JOB_LIST, CERTS_JOB_LIST_SUCCESS, CERTS_JOB_LIST_FAIL],
    promise: client => client.get('/api/payscale/certifications/certifications', { params: options.params }),
    query: options.params
  };
}

export function populateJobCerts(certList) {
  return (dispatch, getState) => {
    dispatch({
      type: 'CERTS_JOB_LIST_SUCCESS',
      certList: certList
    });
  };
}

export function certificationsSearch(options) {
  options.params.searchParam = options.params.searchParam.trim();
  return {
    types: [CERTIFICATIONS_SEARCH_LIST, CERTIFICATIONS_SEARCH_LIST_SUCCESS, CERTIFICATIONS_SEARCH_LIST_FAIL],
    promise: client => client.get('/api/payscale/certifications/searchcertifications', { params: options.params }),
    query: options.params,
    searchCallback: options.searchCallback
  };
}
