export const SHOW_SLIDEOUT = 'SHOW_SLIDEOUT';
export const HIDE_SLIDEOUT = 'HIDE_SLIDEOUT';
export const UPDATE_SLIDEOUT = 'UPDATE_SLIDEOUT';

const initialState = {
  open: false,
  slideoutType: null,
  slideoutProps: {}
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SHOW_SLIDEOUT:
      return {
        ...state,
        open: true,
        slideoutType: action.slideoutType,
        slideoutProps: action.slideoutProps || {}
      };
    case HIDE_SLIDEOUT:
      return {
        ...state,
        open: false
      };
    case UPDATE_SLIDEOUT:
      return {
        ...state,
        slideoutProps: { ...state.slideoutProps, ...action.slideoutProps }
      };
    default:
      return state;
  }
}

export function showSlideout(slideoutType, slideoutProps) {
  return function(dispatch) {
    dispatch({
      type: SHOW_SLIDEOUT,
      slideoutType: slideoutType,
      slideoutProps: slideoutProps
    });
  };
}

export function updateSlideout(slideoutProps) {
  return function(dispatch) {
    dispatch({
      type: UPDATE_SLIDEOUT,
      slideoutProps: slideoutProps
    });
  };
}

export function hideSlideout() {
  return { type: HIDE_SLIDEOUT };
}

export const slideoutActions = {
  showSlideout,
  hideSlideout,
  updateSlideout
};
