import { combineReducers } from 'redux';

import jobRanges from './jobRanges';
import { reducer as jobRangesSearch } from './addJobsToModel/jobRangesSearch';
import addJobsList from './addJobsToModel/addJobsList';
import modelDetails from './modelDetails/modelDetails';
import moveToCurrentStats from './moveToCurrentStats';
import jobRangeDetails from './jobRangeDetails';
import slideout from '../../../../Slideout/SlideoutReducer';

const rootReducer = combineReducers({
  jobRanges,
  jobRangesSearch,
  addJobsList,
  modelDetails,
  moveToCurrentStats,
  jobRangeDetails,
  slideout
});

export default rootReducer;
