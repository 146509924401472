import React, { Component } from 'react';
import { connect } from 'react-redux';
import { object, bool, string, func, number, array } from 'prop-types';
import { bindActionCreators } from 'redux';

import Modal from '../../../Modal/Modal';
import ModalBody from '../../../Modal/ModalBody/ModalBody';
import ModalFooter from '../../../Modal/ModalFooter/ModalFooter';

import { actions as versionCheckActions } from './VersionCheckReducer';

import './VersionCheck.scss';

export class VersionCheck extends Component {
  static propTypes = {
    actions: object,
    versionCheck: object
  };

  constructor(props) {
    super(props);
    this.reloadForNewVersion = this.reloadForNewVersion.bind(this);
    this.state = {
      showNewVersionReloadPopup: false
    };
  }

  componentDidMount() {
    this.props.actions.startCheckInterfaceVersionUpdate();
  }

  UNSAFE_componentWillUnmount() {
    this.props.actions.stopCheckInterfaceVersionUpdate();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.versionCheck.uiSourceVersion !== this.props.versionCheck.uiSourceVersion) {
      const scriptTags = document.getElementsByTagName('script');
      for (let i = 0; i < scriptTags.length; i++) {
        if (scriptTags[i].src) {
          const bundleRegex = /main.bundle.(.*?).js/;
          const bundleHash = bundleRegex.exec(scriptTags[i].src);
          if (bundleHash && bundleHash !== '' && bundleHash.length > 0) {
            if (bundleHash[1] !== this.props.versionCheck.uiSourceVersion) {
              this.setState({
                showNewVersionReloadPopup: true
              });
            }
            break;
          }
        }
      }
    }
  }

  reloadForNewVersion() {
    window.location.reload();
  }

  render() {
    if (this.state.showNewVersionReloadPopup) {
      return (
        <Modal displayCloseButton={false} show={true} allowClose={false} className="version-check-modal">
          <ModalBody>
            <h1 className="pxl-header-small">New Version</h1>
            <span>
              While you were away, we updated our database with the most current insights we have available, to ensure
              your work is always accurate. Please refresh to view it now.
            </span>
          </ModalBody>
          <ModalFooter hideCancelButton={true} submitBtnTxtName="Refresh" submitBtnClick={this.reloadForNewVersion} />
        </Modal>
      );
    } else {
      return null;
    }
  }
}

const mapStateToProps = ({ versionCheck }) => ({ versionCheck });

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(versionCheckActions, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VersionCheck);
