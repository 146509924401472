const defaultInitialState = {};

export default class SummaryStats {
  constructor(nameSpace, initialState = defaultInitialState) {
    this.STAT_HOVER = `${nameSpace}_STAT_HOVER`;
    this.STAT_EXIT_HOVER = `${nameSpace}_STAT_EXIT_HOVER`;
    this.SEARCH_SUCCESS = `${nameSpace}_SEARCH_SUCCESS`;

    this.initialState = initialState;
    this.nameSpace = nameSpace.toLowerCase();
  }

  createReducer(extendReducerFn, reduceInstanceParams) {
    if (reduceInstanceParams) {
      Object.assign(this, reduceInstanceParams);
    }

    return (state = this.initialState, action = {}) => {
      if (typeof extendReducerFn === 'function') {
        const newState = extendReducerFn(state, action);
        if (newState) return newState;
      }

      let items = state.items; // eslint-disable-line prefer-const

      switch (action.type) {
        case this.STAT_HOVER:
          items.map(item => {
            if (item.key === action.key) {
              item.hover = true;
            } else {
              item.hover = false;
            }
          });
          return {
            ...state,
            items: items
          };
        case this.STAT_EXIT_HOVER:
          items.map(item => {
            item.hover = false;
          });
          return {
            ...state,
            items: items
          };
        case this.SEARCH_SUCCESS:
          return {
            ...state,
            loading: false,
            loaded: true,
            error: null,
            items: action.result.summaryStats
          };
        default:
          return { ...state };
      }
    };
  }

  hover(key) {
    return (dispatch, getStore) => {
      dispatch({
        type: this.STAT_HOVER,
        key: key
      });
    };
  }

  exitHover(key) {
    return (dispatch, getStore) => {
      dispatch({
        type: this.STAT_EXIT_HOVER,
        key: key
      });
    };
  }

  getActions() {
    return {
      summaryStatHover: this.hover.bind(this),
      summaryStatExitHover: this.exitHover.bind(this)
    };
  }

  getSelectors() {
    return {};
  }
}
