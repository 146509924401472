import { showSlideout } from '../../../Slideout/SlideoutReducer';
import apiClient from '../../../lib/apiClient';
import { getCSRFPair } from '../../../redux/stateSelectors';
import addAlert from '../../../AlertToast/addAlert';
import Fuse from 'fuse.js';
import { SLIDEOUTS } from '../../../Slideout/Slideouts';

export const JOB_RANGES_MODEL_JOB_ROW_CLICK = 'JOB_RANGES_MODEL_JOB_ROW_CLICK';
export const JOB_RANGES_RUN_COST_ANALYSIS = 'JOB_RANGES_RUN_COST_ANALYSIS';
export const JOB_RANGES_RUN_COST_ANALYSIS_SUCCESS = 'JOB_RANGES_RUN_COST_ANALYSIS_SUCCESS';
export const JOB_RANGES_RUN_COST_ANALYSIS_FAIL = 'JOB_RANGES_RUN_COST_ANALYSIS_FAIL';
export const JOB_RANGES_RUN_COST_ANALYSIS_EMPLOYEES_SUCCESS = 'JOB_RANGES_RUN_COST_ANALYSIS_EMPLOYEES_SUCCESS';
export const JOB_RANGES_RUN_COST_ANALYSIS_EMPLOYEES_FAIL = 'JOB_RANGES_RUN_COST_ANALYSIS_EMPLOYEES_FAIL';
export const JOB_RANGES_NAVIGATE_JOB = 'JOB_RANGES_NAVIGATE_JOB';
export const GET_JBR_AUDIT_ITEMS_SUCCESS = 'GET_JBR_AUDIT_ITEMS_SUCCESS';
export const JBR_AUDIT_MODAL_OPEN = 'JBR_AUDIT_MODAL_OPEN';
export const JBR_AUDIT_MODAL_CLOSE = 'JBR_AUDIT_MODAL_CLOSE';
export const SEARCH_JBR_AUDIT_ITEMS = 'SEARCH_JBR_AUDIT_ITEMS';
export const GET_JOB_LAST_MODIFIED_DATE_SUCCESS = 'GET_JOB_LAST_MODIFIED_DATE_SUCCESS';
export const GET_JOB_LAST_MODIFIED_DATE_ERROR = 'GET_JOB_LAST_MODIFIED_DATE_ERROR';

export function showJobRangeDetails(job) {
  return (dispatch, getState) => {
    if (getState().jobRanges.jobRangeDetails.loading) return;

    const modelDetails = getState().jobRanges.modelDetails;

    const index = modelDetails.filteredModelJobs.indexOf(job);
    if (!getState().slideout.open) {
      dispatch(showSlideout(SLIDEOUTS.JOB_RANGES_JOB_DETAILS));
    }
    dispatch(getLastModifiedDate(job.org_job_id));
    dispatch({ type: JOB_RANGES_MODEL_JOB_ROW_CLICK, job, index });
    dispatch(getJobRangeCostAnalysis(job, index));
  };
}

export function getJobRangeCostAnalysis(job) {
  return async (dispatch, getState) => {
    try {
      const csrfPair = getCSRFPair(getState());
      const limit = getState().jobRanges.jobRangeDetails.employeesLimit;
      const data = {
        ...csrfPair,
        job,
        limit
      };

      dispatch({ type: JOB_RANGES_RUN_COST_ANALYSIS });
      const result = await apiClient.apiPost('/api/jobRanges/rangeCostAnalysis/run/costAnalysis', data);
      if (result.data.success === false) {
        dispatch({ type: JOB_RANGES_RUN_COST_ANALYSIS_FAIL });
        return;
      }
      dispatch({
        type: JOB_RANGES_RUN_COST_ANALYSIS_SUCCESS,
        ...result.data,
        job
      });
    } catch (err) {
      console.log('could not get cost analysis data for job range', err);
      dispatch({ type: JOB_RANGES_RUN_COST_ANALYSIS_FAIL });
    }
  };
}

export function navigateJob(direction) {
  return (dispatch, getState) => {
    const { index } = getState().jobRanges.jobRangeDetails;
    const modelJobs = getState().jobRanges.modelDetails.filteredModelJobs;
    const newIndex = index + direction;
    if (newIndex < 0 || newIndex > modelJobs.length - 1) return;

    const jobRangeModel = getState().jobRanges.modelDetails.jobRangeModel;
    if (!jobRangeModel) return;

    const newJob = modelJobs[newIndex];
    dispatch(getLastModifiedDate(newJob.org_job_id));
    dispatch({ type: JOB_RANGES_NAVIGATE_JOB, newIndex, newJob });
    dispatch(getJobRangeCostAnalysis(newJob));
  };
}

export function getJobRangeCostAnalysisEmployees(job, offset, limit) {
  return async (dispatch, getState) => {
    try {
      const csrfPair = getCSRFPair(getState());
      const data = {
        ...csrfPair,
        job,
        offset,
        limit
      };

      dispatch({ type: JOB_RANGES_RUN_COST_ANALYSIS });
      const result = await apiClient.apiPost('/api/jobRanges/rangeCostAnalysis/run/listEmployees', data);

      if (result.data.success === false) {
        dispatch({ type: JOB_RANGES_RUN_COST_ANALYSIS_FAIL });
        return;
      }

      dispatch({
        type: JOB_RANGES_RUN_COST_ANALYSIS_EMPLOYEES_SUCCESS,
        job,
        offset,
        limit,
        result: result.data
      });
    } catch (err) {
      console.log('could not get cost analysis data for job range', err);
      dispatch({ type: JOB_RANGES_RUN_COST_ANALYSIS_EMPLOYEES_FAIL });
    }
  };
}

export function openAuditModal(job) {
  return async (dispatch, getState) => {
    const csrfPair = getCSRFPair(getState());
    const data = {
      ...csrfPair,
      org_job_code: job.org_job_code,
      org_job_code_key: job.org_job_code_key
    };

    dispatch({ type: JBR_AUDIT_MODAL_OPEN });
    try {
      const result = await apiClient.apiPost('/api/hris/audit-trail/audit-trail/getAuditHistory', data);

      if (result.data.success === false) dispatch({ type: 'GET_JBR_AUDIT_TRAIL_FAIL' });
      else dispatch({ type: GET_JBR_AUDIT_ITEMS_SUCCESS, auditItems: result.data });
    } catch (err) {
      dispatch({ type: 'GET_JBR_AUDIT_TRAIL_FAIL' });
    }
  };
}

export function closeAuditModal() {
  return { type: JBR_AUDIT_MODAL_CLOSE };
}

export function SearchJobAuditItems({ target: { value } }, auditItems) {
  return { type: SEARCH_JBR_AUDIT_ITEMS, auditItems: filterAuditItems(auditItems, value) };
}

export function getLastModifiedDate(orgJobId, benchmarkYear) {
  return async dispatch => {
    if (orgJobId && benchmarkYear) {
      const errorMessage = 'Error retrieving last modified date';

      try {
        const result = await apiClient.apiGet('/api/market-pricing/jobs/getLastModifiedDate', {
          params: {
            orgJobId,
            benchmarkYear
          }
        });

        dispatch({ type: GET_JOB_LAST_MODIFIED_DATE_SUCCESS, lastModifiedDate: result.data.lastModifiedDate });
      } catch (error) {
        addAlert(errorMessage, 'warning');
        dispatch({ type: GET_JOB_LAST_MODIFIED_DATE_ERROR });
      }
    }
  };
}

function filterAuditItems(auditItems, query) {
  if (!query || query === '') {
    if (query === '') {
      for (let i = 0; i < auditItems.length; i++) {
        auditItems[i].match = true;
      }
    }
    return auditItems;
  }

  let searchVals = []; // eslint-disable-line prefer-const

  for (let x = 0; x < auditItems.length; x++) {
    let auditRec = auditItems[x]; // eslint-disable-line prefer-const
    auditRec.action = '';

    for (let y = 0; y < auditRec.event_data.changes.length; y++) {
      let change = auditRec.event_data.changes[y]; // eslint-disable-line prefer-const
      auditRec.action += ' ' + change.action + ' ' + change.property;

      if (change.removedItems) {
        for (let z = 0; z < change.removedItems.length; z++) {
          auditRec.action += ' ' + change.removedItems[z].auditDisplayValue;
        }
      }
      if (change.addedItems) {
        for (let z = 0; z < change.addedItems.length; z++) {
          auditRec.action += ' ' + change.addedItems[z].auditDisplayValue;
        }
      }
    }

    searchVals.push(auditRec);
  }

  // Returns array of filtered items
  const fuse = new Fuse(searchVals, {
    shouldSort: true,
    threshold: 0.3,
    location: 0,
    distance: 100,
    maxPatternLength: 32,
    minMatchCharLength: 1,
    keys: ['eventTimePretty', 'first_name', 'last_name', 'action']
  });

  const filteredItems = fuse.search(query);

  for (let i = 0; i < auditItems.length; i++) {
    // Loop through all items returned by the API, and set item.match to false - used for rerendering.
    const item = auditItems[i];
    item.match = false;
    for (let j = 0; j < filteredItems.length; j++) {
      // Loop through all filtered items for each item in the base list, compare unique ids, and set match
      if (item.eventTimeSort === filteredItems[j].eventTimeSort) {
        item.match = true;
      }
    }
  }

  const arr = auditItems.map(cut => {
    return { ...cut };
  });
  return [...arr];
}
