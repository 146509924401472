import React, { Component } from 'react';
import './ReviewToolbar.scss';
import {
  REVIEW_STEP_SUMMARY,
  REVIEW_STEP_DUPLICATES,
  REVIEW_STEP_ZERO_WEIGHTS
} from '../../../reducers/MatchReviewReducer';
import NavigateStepButton from './NavigateStepButton';
import GenerateReportButton from './GenerateReportButton';
import * as MatchReviewUtils from '../../../utils/MatchReviewUtils';
import { ParticipationMatchOrgJob, ParticipationMatch } from '../../../types/MatchReviewTypes';
import { oneOf, func, arrayOf } from 'prop-types';
import SlideoutFooterNavButton from '../../../../../Slideout/Footer/SlideoutFooterNavButton';

const propTypes = {
  previousReviewStep: oneOf([REVIEW_STEP_SUMMARY, REVIEW_STEP_ZERO_WEIGHTS, REVIEW_STEP_DUPLICATES]),
  nextReviewStep: oneOf([REVIEW_STEP_SUMMARY, REVIEW_STEP_ZERO_WEIGHTS, REVIEW_STEP_DUPLICATES]),
  navigatePreviousStep: func.isRequired,
  navigateNextStep: func.isRequired,
  duplicateMatches: arrayOf(ParticipationMatchOrgJob).isRequired,
  zeroWeightedMatches: arrayOf(ParticipationMatch).isRequired,
  finishReviewing: func.isRequired
};

class ReviewToolbar extends Component {
  constructor(props) {
    super(props);
    this.generateReport = this.generateReport.bind(this);
    this.renderPrevious = this.renderPrevious.bind(this);
    this.renderNext = this.renderNext.bind(this);
  }

  generateReport() {
    this.props.finishReviewing(this.props.hideSlideout);
  }

  renderButton(step, isNext, navigationClick) {
    switch (step) {
      case REVIEW_STEP_SUMMARY:
        return <SlideoutFooterNavButton text="Review matches summary" isNext={isNext} onClick={navigationClick} />;
      case REVIEW_STEP_DUPLICATES:
        return (
          <SlideoutFooterNavButton
            text="Review jobs with duplicate matches"
            isNext={isNext}
            onClick={navigationClick}
          />
        );
      case REVIEW_STEP_ZERO_WEIGHTS: {
        return (
          <SlideoutFooterNavButton
            text="Review zero weighted survey job matches"
            isNext={isNext}
            onClick={navigationClick}
          />
        );
      }
      default:
        return null;
    }
  }

  renderPrevious(props) {
    return this.renderButton(props.previousReviewStep, false, props.navigatePreviousStep);
  }

  renderNext(props) {
    const button = this.renderButton(props.nextReviewStep, true, props.navigateNextStep);
    if (button) {
      return button;
    } else {
      return <GenerateReportButton onClick={this.generateReport} />;
    }
  }

  render() {
    return (
      //  TODO: Refactor this to use the new "SlideoutFooter"
      <div className="pcenter-match-review__toolbar slideout-footer">
        {this.renderPrevious(this.props)}
        {this.renderNext(this.props)}
      </div>
    );
  }
}

ReviewToolbar.propTypes = propTypes;
export default ReviewToolbar;
