import SearchPanel from '../../../../../ps-components/src/redux/facetedSearch';
import { actions as employeeSummaryActions } from './employeeSummary';

const SET_SELECTED_FACET_ONLY = 'EMPLOYEES_SET_SELECTED_FACET_ONLY';
const SET_FILTER_ONLY = 'EMPLOYEES_SET_FILTER_ONLY';
const SET_FILTER = 'EMPLOYEES_SET_FILTER';

const initialState = {
  facetSelection: {},
  facets: [],
  loading: false,
  loaded: false,
  error: null,
  items: [],
  query: '',
  showAddButton: true,
  addButtonText: 'Add Employee',
  resetFacetText: 'Reset Filters',
  searchText: 'Find Employees',
  pageCode: 'employees',
  filters: {},
  limit: 100,
  additionalFacets: [
    {
      id: 'outliers_toggle',
      type: 'FacetDistinctItems',
      facetSort: -1,
      title: 'Outliers',
      queryParam: 'range_outliers',
      localStorage: true,
      collapsible: true,
      collapsedDefault: false,
      items: [
        {
          key: 'Market',
          val: null,
          doc_count: 0
        },
        {
          key: 'Range',
          val: 'range_outliers',
          doc_count: 0
        }
      ]
    }
  ]
};

export const employeesSearchPanel = new SearchPanel('EMPLOYEES', initialState);

function extendReducer(state, action) {
  switch (action.type) {
    case SET_SELECTED_FACET_ONLY:
      return {
        ...state,
        facetSelection: {
          ...state.facetSelection,
          [action.facetId]: { [action.facetItem.key]: true }
        }
      };
    case SET_FILTER_ONLY:
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.id]: action.value
        }
      };
    case 'EMPLOYEE_DELETE':
      return {
        ...state,
        loading: true
      };
    case 'EMPLOYEE_DELETE_SUCCESS':
      return {
        ...state,
        loading: false,
        loaded: true,
        error: action.result.success ? null : action.result.message,
        items: []
      };
    case 'EMPLOYEE_DELETE_FAIL':
      return {
        ...state,
        loading: false,
        loaded: true,
        error: action
      };
    default:
      return null;
  }
}
export const reducer = employeesSearchPanel.createReducer(extendReducer, {
  searchAPI: '/api/elasticsearch/search/queryStringEmployees',
  additionalSearchParams: state => {
    return { pageCode: 'employees' };
  }
});

export const actions = employeesSearchPanel.getActions();
export const selectors = employeesSearchPanel.getSelectors();

export function getFacetById(state, id) {
  return state.employeesSearch.facets.filter(facet => facet.id === id)[0];
}

actions.viewFacet = function viewFacet(facetId, facetItem, only) {
  return (dispatch, getStore) => {
    const { loaded } = getStore().jobsSearch;

    dispatch({ type: 'EMPLOYEES_REMOVE_ALL_SELECTED' });

    if (loaded) {
      const facet = getFacetById(getStore(), facetId);
      actions.facetItemClick(facet, facetItem, only)(dispatch, getStore);
    } else {
      dispatch({
        type: SET_SELECTED_FACET_ONLY,
        facetId,
        facetItem
      });
    }
  };
};

actions.setFilter = function addFilter({ id, value, only }) {
  return (dispatch, getStore) => {
    const { loaded } = getStore().employeesSearch;

    dispatch({ type: 'EMPLOYEES_SET_QUERY', query: '' });
    dispatch({ type: 'EMPLOYEES_REMOVE_ALL_SELECTED' });

    dispatch({
      type: only ? SET_FILTER_ONLY : SET_FILTER,
      id,
      value
    });
    if (loaded) {
      const params = selectors.getSearchParameters(getStore());
      dispatch(actions.search({ params }));
    }
  };
};

actions.toggleMarketOutliers = (facet, value) => {
  return (dispatch, getStore) => {
    const { facets, facetSelection } = getStore().employeesSearch;

    // If value isn't changing, don't do anything
    if (facetSelection['outliers_toggle'] === value) {
      return;
    }

    const rangeDistributionFacet = facets.find(f => f.id === 'range_distribution');
    const marketDistributionFacet = facets.find(f => f.id === 'market_distribution');
    if (value === 'Range') {
      dispatch({ type: 'EMPLOYEES_FACET_RESET', facet: marketDistributionFacet, facetSelection: facetSelection });
    } else if (value === 'Market') {
      dispatch({ type: 'EMPLOYEES_FACET_RESET', facet: rangeDistributionFacet, facetSelection: facetSelection });
    }

    dispatch(actions.facetDistinctItemClick(facet, value));
  };
};

employeesSearchPanel.onSearchSuccess = (dispatch, skipSummaryChart) => {
  if (!skipSummaryChart) {
    dispatch(employeeSummaryActions.getEmployeeSummaryCharts());
  }
};
