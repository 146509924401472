import listPanel from '../genericGrid/listPanel';
import * as employeeActions from './employeeActions';
import { getSearchItemById } from './selectors';

const initialState = {
  pageName: 'employees',
  sort: {},
  allowSort: false,
  allowCheckAll: false,
  expandedMaxColumnCount: 1,
  selectedListRows: [],
  columns: [
    {
      title: 'Employee',
      dataKey: 'full_name_standard',
      field_type_code: 'text'
    },
    {
      title: 'Location',
      dataKey: 'work_location_standard',
      field_type_code: 'text'
    },
    {
      title: 'Department',
      dataKey: 'department_standard',
      field_type_code: 'text'
    },
    {
      title: 'Base Salary',
      titleRenderer: 'titleRenderFn',
      dataKey: 'base_salary_default',
      field_type_code: 'monetary'
    }
  ]
};

let actions = {}; // eslint-disable-line prefer-const

// extend or override action creators here:
actions.loadDetailsPanel = employeeActions.loadDetailsPanel;
actions.loadComparisonPanel = employeeActions.loadComparisonPanel;
actions.handleRowSelect = function handleRowSelect(...args) {
  const [, getStore, selectedRows] = args;
  const selected = [].concat(selectedRows);
  const length = selected.length;
  if (!length) return;
  if (length === 1) {
    const item = getSearchItemById(getStore(), selected[0]);
    actions.loadDetailsPanel(...args, item);
  }
  if (length > 1) actions.loadComparisonPanel(...args);
};

export const employeesListPanel = listPanel('EMPLOYEES', initialState, actions);
export const reducer = employeesListPanel.reducer;

// extend reducer here:
employeesListPanel.extendReducer = function extendReducer(state, action) {
  switch (action.type) {
    default:
      return null;
    case 'EMPLOYEE_DELETE_SUCCESS':
      return {
        ...state,
        selectedListRows: []
      };
  }
};
