import map from 'lodash/map';
import apiClient from '../../../lib/apiClient';
const JOB_FAMILY_LIST = 'JOB_FAMILY_LIST';
const JOB_FAMILY_LIST_SUCCESS = 'JOB_FAMILY_LIST_SUCCESS';
const JOB_FAMILY_LIST_FAIL = 'JOB_FAMILY_LIST_FAIL';
const GET_JOB_FAMILIES = 'GET_JOB_FAMILIES';
const ADD_JOB_FAMILY_OPTION = 'ADD_JOB_FAMILY_OPTION';

const initialState = {
  loading: false,
  loaded: false,
  error: null,
  items: []
};

const defaultFamilyList = map(
  [
    'Sales',
    'Marketing',
    'Human Resources',
    'IT Services',
    'Finance and Accounting',
    'Engineering',
    'Research and Engineering',
    'Finance',
    'Logistics & Supply Chain',
    'Corporate Non - Officer',
    'Legal',
    'Operations',
    'Information Technology',
    'Research & Development',
    'Clinical Development',
    'Customer Service',
    'General Services',
    'Customer Sales & Service',
    'Communications',
    'Quality',
    'Executive',
    'Capital Management',
    'Manufacturing Operations',
    'Procurement',
    'General & Administrative',
    'Accounting',
    'General Management',
    'Business Development',
    'Quality Assurance, Regulatory & Compliance',
    'Facilities'
  ],
  family => {
    return { label: family, value: family };
  }
);

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case JOB_FAMILY_LIST:
      return {
        ...initialState,
        loading: true
      };
    case JOB_FAMILY_LIST_SUCCESS:
      let familyList = defaultFamilyList;
      if (action.result && action.result.length) {
        familyList = action.result.map(row => {
          return { value: row.family, label: row.family };
        });
      }
      return {
        ...state,
        loading: false,
        loaded: true,
        error: null,
        items: familyList
      };
    case JOB_FAMILY_LIST_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error
      };
    case GET_JOB_FAMILIES:
      return {
        ...state
      };
    case ADD_JOB_FAMILY_OPTION:
      const newItems = state.items;
      newItems.push({ label: action.label, value: action.value });

      return {
        ...state,
        items: newItems
      };
    default:
      return { ...state };
  }
}

export function addJobFamilyOption(label, value) {
  return { type: ADD_JOB_FAMILY_OPTION, label, value };
}

export function getJobFamilies(currency) {
  return async dispatch => {
    try {
      dispatch({ type: JOB_FAMILY_LIST });

      const results = await apiClient.apiGet('/api/hris/job-family/all', { params: { currency } });
      dispatch({ type: JOB_FAMILY_LIST_SUCCESS, result: results.data });
    } catch (error) {
      dispatch({ type: JOB_FAMILY_LIST_FAIL, error: error });
    }
  };
}

export function getJobFamilyList() {
  return async dispatch => {
    try {
      dispatch({ type: JOB_FAMILY_LIST });
      const results = await apiClient.apiGet('/api/hris/job-family/allUnassociated', { params: {} });
      dispatch({ type: JOB_FAMILY_LIST_SUCCESS, result: results.data });
    } catch (error) {
      dispatch({ type: JOB_FAMILY_LIST_FAIL, error: error });
    }
  };
}

export function getJobFamilyListForSet(setId) {
  return async dispatch => {
    try {
      dispatch({ type: JOB_FAMILY_LIST });
      const results = await apiClient.apiGet('/api/hris/job-family/allUnassociatedBySet', { params: { setId } });

      dispatch({
        type: JOB_FAMILY_LIST_SUCCESS,
        result: results.data
      });

      return results.data;
    } catch (error) {
      dispatch({ type: JOB_FAMILY_LIST_FAIL, error: error });
    }
  };
}

export function populateJobFamilies(familyList) {
  return (dispatch, getState) => {
    dispatch({
      type: JOB_FAMILY_LIST_SUCCESS,
      familyList: familyList
    });
  };
}
