import PropTypes from 'prop-types';
import React, { Component } from 'react';

export default class LoadingIndicator extends Component {
  static propTypes = {
    loading: PropTypes.bool,
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
  };

  indicator() {
    return (
      <div className="div__container--loadingIndicator">
        <div className="div--loadingIndicator">
          <svg className="loadingIndicator--circle" viewBox="25 25 50 50">
            <circle className="circle--path" cx="50" cy="50" r="20" fill="none" strokeWidth="2" strokeMiterlimit="10" />
          </svg>
        </div>
      </div>
    );
  }

  render() {
    if (!this.props.loading) return this.props.children || null;

    if (!React.Children.count(this.props.children)) {
      return this.indicator();
    }

    return (
      <div style={{ position: 'relative' }}>
        {this.indicator()}
        {this.props.children}
      </div>
    );
  }
}
