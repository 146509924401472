import React, { FC } from 'react';

interface FatalErrorProps {
  fatalError?: Error;
  fatalReason?: string;
  image?: string;
  imageClass?: string;
}

const FatalError: FC<FatalErrorProps> = (props: FatalErrorProps) => {
  const { fatalError, fatalReason, image, imageClass } = props;

  const imageClassName = imageClass ? imageClass : 'img__class';
  const visualImage = image ? image : '/view/static/lab/imgs/paper_airplane.svg';

  // the fatalReason is for developers/debuggers and not meant to be shown
  console.error(`FatalError: ${fatalReason}, ${fatalError}`);

  return (
    <div className="common__fatal-error">
      <div className="item-not-found__msg-box">
        <div>
          <span className="msg__first-row">Oops......</span>
        </div>
        <div>
          <img className={imageClassName} src={visualImage} />
        </div>
        <div className="msg__second-row__div">
          <span className="msg__second-row">
            We&apos;re having trouble loading the app. Our operations team has been notified and is investigating.
            <br />
            Please try refreshing the page. If the issue persists, log out and try again. <br />
          </span>
        </div>
      </div>
    </div>
  );
};

export default FatalError;