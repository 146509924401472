import React from 'react';
import { Modal } from 'react-bootstrap/lib';
import PropTypes from 'prop-types';
import { Alert } from '@payscale/design';
import './SubmitFailure.scss';

const propTypes = {
  history: PropTypes.object.isRequired,
  doSupport: PropTypes.func.isRequired,
  doClose: PropTypes.func.isRequired
};

const SubmitFailure = props => {
  return (
    <div className="pcenter-match-review-submit-failure">
      <Alert
        message={
          <div>
            <h4>Failed to generate the report. Please try again.</h4>
            <div className="pcenter-match-review-submit-failure__text">
              You can also <a onClick={props.doSupport}>contact support</a> or your client manager.
            </div>
          </div>
        }
        onClickClose={props.doClose}
        close={true}
      />
    </div>
  );
};

SubmitFailure.propTypes = propTypes;
export default SubmitFailure;
