import apiClient from '../../../lib/apiClient';

const CERTS_JOB_LIST = 'CERTS_JOB_LIST';
const CERTS_JOB_LIST_SUCCESS = 'CERTS_JOB_LIST_SUCCESS';
const CERTS_JOB_LIST_FAIL = 'CERTS_JOB_LIST_FAIL';
const CERTIFICATIONS_SEARCH_LIST = 'CERTIFICATIONS_SEARCH_LIST';
const CERTIFICATIONS_SEARCH_LIST_SUCCESS = 'CERTIFICATIONS_SEARCH_LIST_SUCCESS';
const CERTIFICATIONS_SEARCH_LIST_FAIL = 'CERTIFICATIONS_SEARCH_LIST_FAIL';
const RESET_JOB_CERTIFICATIONS = 'RESET_JOB_CERTIFICATIONS';

const initialState = {
  loading: false,
  loaded: false,
  error: null,
  items: [],
  certsSearchItems: [],
  allCerts: []
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case CERTS_JOB_LIST:
      return {
        ...initialState,
        loading: true
      };
    case CERTS_JOB_LIST_SUCCESS:
      let reportAnswers = []; // eslint-disable-line prefer-const
      let certList = action.certList; // eslint-disable-line prefer-const
      if (!certList) {
        certList = action.result;
      }
      if (certList && certList.length) {
        certList.map(answer => {
          reportAnswers.push({ value: answer.answer, label: answer.answer });
        });
      }
      return {
        ...state,
        loading: false,
        loaded: true,
        error: null,
        items: reportAnswers,
        allCerts: certList
      };
    case CERTS_JOB_LIST_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        data: null,
        error: action.error
      };
    case CERTIFICATIONS_SEARCH_LIST:
      return {
        ...state,
        loading: true
      };
    case CERTIFICATIONS_SEARCH_LIST_SUCCESS:
      let searchAnswers = []; // eslint-disable-line prefer-const
      let searchCertList = action.certList; // eslint-disable-line prefer-const
      if (!searchCertList) {
        searchCertList = action.result;
      }
      if (searchCertList && searchCertList.length) {
        searchCertList.map(answer => {
          searchAnswers.push(answer.answer);
        });
      }
      action.searchCallback(action.query.searchParam, searchAnswers);
      return {
        ...state,
        loading: false,
        loaded: true,
        error: null,
        certsSearchItems: action.result
      };
    case CERTIFICATIONS_SEARCH_LIST_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        data: null,
        error: action.error
      };
    case RESET_JOB_CERTIFICATIONS:
      return {
        ...initialState
      };
    default:
      return { ...state };
  }
}

export function getJobCerts(options) {
  return async dispatch => {
    try {
      dispatch({ type: CERTS_JOB_LIST });
      const result = await apiClient.apiGet('/api/payscale/certifications/certifications', { params: options.params });

      if (!result.data.success === false) {
        dispatch({ type: CERTS_JOB_LIST_FAIL, error: result.data.reason });
      } else {
        dispatch({ type: CERTS_JOB_LIST_SUCCESS, result: result.data, query: options.params });
      }
    } catch (error) {
      dispatch({ type: CERTS_JOB_LIST_FAIL, error: error });
    }
  };
}

export function populateJobCerts(certList) {
  return (dispatch, getState) => {
    dispatch({
      type: CERTS_JOB_LIST_SUCCESS,
      certList: certList
    });
  };
}

export function certificationsSearch(options) {
  options.params.searchParam = options.params.searchParam.trim();
  return async dispatch => {
    try {
      dispatch({ type: CERTIFICATIONS_SEARCH_LIST });
      const result = await apiClient.apiGet('/api/payscale/certifications/searchcertifications', {
        params: options.params
      });

      dispatch({
        type: CERTIFICATIONS_SEARCH_LIST_SUCCESS,
        result: result.data,
        query: options.params,
        searchCallback: options.searchCallback
      });
    } catch (error) {
      dispatch({ type: CERTIFICATIONS_SEARCH_LIST_FAIL, error: error });
    }
  };
}

export function resetJobCertificationsReducer() {
  return { type: RESET_JOB_CERTIFICATIONS };
}
