import PropTypes from 'prop-types';
import React, { Component } from 'react';
import drawChart from './RangeChartD3/RangeChartD3';
import CommonErrorBoundary from '../Util/CommonErrorBoundary';

export default class RangeChart extends Component {
  static propTypes = {
    history: PropTypes.object,
    loading: PropTypes.object,
    labelsVisible: PropTypes.bool,
    alwaysShowLabels: PropTypes.bool,
    payDisplayType: PropTypes.string,
    altColors: PropTypes.array,
    highVariance: PropTypes.bool,
    data: PropTypes.array,
    /** min and max of all the ranges combined (from a list). Use if you want to align a list of charts in a vertical column*/
    scaleMin: PropTypes.number,
    scaleMax: PropTypes.number,
    width: PropTypes.number,
    height: PropTypes.number,
    barHeight: PropTypes.number,
    labelFontSize: PropTypes.number,
    labelToBarSpacingBottom: PropTypes.number,
    labelToBarSpacingTop: PropTypes.number,
    labelToLabelMinSpacing: PropTypes.number,
    invertLabels: PropTypes.bool,
    labelTextAnchor: PropTypes.array,
    hideTopLabels: PropTypes.bool,
    hideBottomLabels: PropTypes.bool,
    /** signature for this function is (value, index, context)*/
    topLabelFormatter: PropTypes.func,
    bottomLabelFormatter: PropTypes.func,
    formatCurrency: PropTypes.func,
    className: PropTypes.string,
    colors: PropTypes.array,
    /** Supplied to the label formatters in the context obj to do what you want with. A shallow equals is used to rerender the chart*/
    roundToNearest: PropTypes.number,
    /** 'annual' or 'hourly'  This is supplied to the formatter functions in the context obj*/
    payType: PropTypes.string,
    /** ID is required */
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    topLabelFontWeight: PropTypes.string,
    bottomLabelFontWeight: PropTypes.string,
    hasPointer: PropTypes.bool,
    /** line properties */
    hasLine: PropTypes.bool,
    lineStartingPosition: PropTypes.number,
    lineHeight: PropTypes.number,
    textFillColor: PropTypes.string
  };

  static defaultProps = {
    data: [],
    scaleMin: 20,
    scaleMax: 130,
    width: 300,
    height: 50,
    barHeight: 30,
    labelFontSize: 12,
    labelToBarSpacingBottom: 20,
    labelToBarSpacingTop: 10,
    labelToLabelMinSpacing: 65,
    labelTextAnchor: ['middle', 'middle', 'middle'],
    invertLabels: false,
    hideTopLabels: false,
    hideBottomLabels: false,
    colors: ['#5389d5', '#a7eff1', '#00aaa4', '#7e4ab2'],
    roundToNearest: undefined,
    payType: undefined,
    id: 'default-chart',
    topLabelFontWeight: 'bolder',
    bottomLabelFontWeight: 'normal',
    hasPointer: true,
    hasLine: false,
    lineStartingPosition: 0,
    lineHeight: 3,
    textFillColor: '#333333'
  };

  componentDidMount() {
    this.update();
  }

  shouldComponentUpdate(nextProps) {
    const hasNewData =
      this.props.data.filter((data, idx) => data === nextProps.data[idx]).length !== this.props.data.length ||
      this.props.data.length !== nextProps.data.length;
    const newScale = this.props.scaleMax !== nextProps.scaleMax || this.props.scaleMin !== nextProps.scaleMin;
    const reRender = this.props.loading && !nextProps.loading;
    const hoverChange = this.props.labelsVisible !== nextProps.labelsVisible;
    const payDisplayTypeChange = this.props.payDisplayType !== nextProps.payDisplayType;
    const roundingChange = this.props.roundToNearest !== nextProps.roundToNearest;
    const payType = this.props.payType !== nextProps.payType;
    const newColors = !(
      this.props.colors.length === nextProps.colors.length &&
      this.props.colors.every((value, index) => value === nextProps.colors[index])
    );

    return (
      reRender ||
      hoverChange ||
      payDisplayTypeChange ||
      hasNewData ||
      newScale ||
      roundingChange ||
      payType ||
      newColors
    );
  }

  componentDidUpdate(prevProps) {
    const hoverChange = prevProps.labelsVisible !== this.props.labelsVisible;
    if (hoverChange) {
      if (this.toggleLabels) {
        this.toggleLabels(this.props.labelsVisible);
      }
      return;
    }

    this.update();
  }

  update() {
    const config = {
      topLabelFormatter: RangeChart.topLabelFormatter,
      bottomLabelFormatter: RangeChart.bottomLabelFormatter,
      ...this.props
    };
    this.toggleLabels = drawChart(config);
  }

  static topLabelFormatter(val, index, context) {
    switch (index) {
      case 0:
        return '25th';
      case 1:
        return '50th';
      case 2:
        return '75th';
    }
  }

  static bottomLabelFormatter(val, index, context) {
    if (isNaN(val)) {
      return val; // Do not add $K for N/A
    }

    return '$' + val + 'K';
  }

  render() {
    return (
      <CommonErrorBoundary>
        <svg
          className={'survey-range-chart-' + this.props.id + ' ' + this.props.className}
          style={{ overflow: 'visible' }}
        >
          {' '}
        </svg>
      </CommonErrorBoundary>
    );
  }
}
