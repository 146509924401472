import moment from 'moment';
import { validateEmailFn } from '../../../../../ps-components/src/Utils/emailUtils';

const EDIT_EMPLOYEE_LAUNCH = 'EDIT_EMPLOYEE_LAUNCH';
const EDIT_EMPLOYEE_CLOSE = 'EDIT_EMPLOYEE_CLOSE';
const SAVE_EMPLOYEE = 'SAVE_EMPLOYEE';
const SAVE_EMPLOYEE_SUCCESS = 'SAVE_EMPLOYEE_SUCCESS';
const SAVE_EMPLOYEE_FAIL = 'SAVE_EMPLOYEE_FAIL';
const EDIT_EMPLOYEE_DATA_CHANGE = 'EDIT_EMPLOYEE_DATA_CHANGE';
const GET_JOB_TITLE_OPT_SUCCESS = 'GET_JOB_TITLE_OPT_SUCCESS';

const EMPLOYEE_SHOW_DELETE_MODAL = 'EMPLOYEE_SHOW_DELETE_MODAL';
const EMPLOYEE_CANCEL_DELETE = 'EMPLOYEE_CANCEL_DELETE';
const EMPLOYEE_DELETE_SUCCESS = 'EMPLOYEE_DELETE_SUCCESS';
const EMPLOYEE_DELETE = 'EMPLOYEE_DELETE';

const GET_DIRECT_REPORTS = 'GET_DIRECT_REPORTS';
const GET_DIRECT_REPORTS_SUCCESS = 'GET_DIRECT_REPORTS_SUCCESS';
const GET_DIRECT_REPORTS_FAIL = 'GET_DIRECT_REPORTS_FAIL';

const GET_ALL_EMPLOYEES_EMAILS = 'GET_ALL_EMPLOYEES_EMAILS';
const GET_ALL_EMPLOYEES_EMAILS_SUCCESS = 'GET_ALL_EMPLOYEES_EMAILS_SUCCESS';
const GET_ALL_EMPLOYEES_EMAILS_FAIL = 'GET_ALL_EMPLOYEES_EMAILS_FAIL';

let emailMessage = '';
const initialState = Object.freeze({
  dirty: false,
  editMode: false,
  employeeDetails: {},
  fromEmployeeDetailsPanel: false,
  panelVisible: false,
  saving: false,
  saved: false,
  invalidDetails: [],
  emailValidationMessage: emailMessage,
  employeeUpdateError: {},
  showDeleteModal: false,
  allEeInfo: {}
});

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case EDIT_EMPLOYEE_LAUNCH:
      return {
        ...state,
        editMode: true,
        employeeDetails: action.employeeDetails,
        invalidDetails: [],
        emailValidationMessage: emailMessage,
        fromEmployeeDetailsPanel: true,
        panelVisible: true,
        save: false
      };
    case EDIT_EMPLOYEE_CLOSE:
      return {
        ...initialState
      };
    case EMPLOYEE_SHOW_DELETE_MODAL:
      return {
        ...state,
        showDeleteModal: true,
        pendingDeleteEEId: action.employeeNumber
      };
    case EMPLOYEE_CANCEL_DELETE:
      return {
        ...state,
        showDeleteModal: false
      };
    case EMPLOYEE_DELETE_SUCCESS:
      return {
        ...state,
        selectedListRows: [],
        panelVisible: false,
        dirty: true,
        editMode: false,
        employeeDetails: {},
        fromEmployeeDetailsPanel: false,
        saving: false,
        saved: false,
        invalidDetails: [],
        emailValidationMessage: emailMessage,
        employeeUpdateError: {},
        showDeleteModal: false
      };
    case EMPLOYEE_DELETE:
      return {
        ...state,
        showDeleteModal: false
      };
    case GET_DIRECT_REPORTS_SUCCESS:
      return {
        ...state,
        directReports: action.result
      };
    case SAVE_EMPLOYEE:
      return {
        ...state,
        saving: true
      };
    case SAVE_EMPLOYEE_SUCCESS:
      return {
        ...state,
        saving: false,
        saved: true,
        employeeUpdateError: action.result.success === false ? action.result : {}
      };
    case SAVE_EMPLOYEE_FAIL:
      return {
        ...state,
        saving: false
      };
    case EDIT_EMPLOYEE_DATA_CHANGE:
      return {
        ...state,
        employeeDetails: action.updatedEmployeeDetails,
        invalidDetails: validateEmployeeDetails(action.updatedEmployeeDetails, state.allEeInfo),
        emailValidationMessage: emailMessage,
        dirty: true
      };
    case GET_JOB_TITLE_OPT_SUCCESS:
      // It would be better to get this result in proper format from backend.
      // We need data in label/value pair in order to use it with latest react select library
      const result = [];
      for (let i = 0; i < action.result.length; i++) {
        result.push({ label: action.result[i].search_result, value: action.result[i].search_result });
      }
      action.callback(result);
      return { ...state };
    default:
      return { ...state };

    case GET_ALL_EMPLOYEES_EMAILS:
      return {
        ...state
      };
    case GET_ALL_EMPLOYEES_EMAILS_SUCCESS:
      return {
        ...state,
        allEeInfo: new Map(action.result.eeInfo.map(i => [i.email, i]))
      };
    case GET_ALL_EMPLOYEES_EMAILS_FAIL:
      return {
        ...state,
        allEeInfo: {}
      };
  }
}

function validateEmployeeEmail(employeeDetails, invalidDetails, allEeInfo) {
  const emailFieldName = 'email';
  // validate email format
  if (employeeDetails.email && !validateEmailFn(employeeDetails.email)) {
    invalidDetails.push(emailFieldName);
    emailMessage = 'Invalid email.';
    return;
  }

  //check existing ee
  if (employeeDetails.email) {
    const matched = allEeInfo.get(employeeDetails.email);
    if (matched && matched.employeenumber !== employeeDetails.employeeNumber) {
      invalidDetails.push(emailFieldName);
      emailMessage = 'Email address already exists for an employee.';
    }
  }
}

function validateEmployeeDetails(employeeDetails, allEeInfo) {
  let invalidDetails = [];

  if (!employeeDetails.orgJobTitle || employeeDetails.orgJobTitle.trim().length < 1) {
    invalidDetails.push('orgJobTitle');
  }

  const dateFields = ['salaryEffectiveDate', 'dateOfHire', 'plannedStockOptionsDate'];
  for (let i = 0; i < dateFields.length; i++) {
    if (employeeDetails[dateFields[i]] && !moment(employeeDetails[dateFields[i]]).isValid()) {
      invalidDetails.push(dateFields[i]);
    }
  }

  validateEmployeeEmail(employeeDetails, invalidDetails, allEeInfo);

  const numericalFields = [
    'baseSalary',
    'hourlyRate',
    'bonusPaid',
    'variablePayTarget',
    'lumpSum',
    'bonusTarget',
    'us401kEmployerCost',
    'fsa',
    'hsa',
    'stdEmployerCost',
    'standardWorkHours',
    'ltdEmployerCost',
    'overtimeShiftPay',
    'transportation',
    'vacation',
    'sick',
    'holidays',
    'personalLeave',
    'medicalInsurance',
    'dentalInsurance',
    'visionInsurance',
    'lifeInsurance',
    'plannedStockOptions',
    'plannedStockDollars',
    'commissionsPaid',
    'otherCash',
    'annualBonusTarget',
    'ltiPaid',
    'addDisability'
  ];

  for (let i = 0; i < numericalFields.length; i++) {
    if (employeeDetails[numericalFields[i]] && isNaN(employeeDetails[numericalFields[i]])) {
      invalidDetails.push(numericalFields[i]);
    }
  }

  if (
    (employeeDetails.incentiveTargetPercent && isNaN(employeeDetails.incentiveTargetPercent)) ||
    employeeDetails.incentiveTargetPercent < 0 ||
    employeeDetails.incentiveTargetPercent > 2
  ) {
    invalidDetails.push('incentiveTargetPercent');
  }

  return invalidDetails;
}
