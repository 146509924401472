import React, { Component } from 'react';

/*
  Log into identity immediately, then re-login every 5 minutes
 */
export default class IdentityLogin extends Component {
  constructor(props) {
    super(props);

    this.state = { refreshCount: 0 };

    const resetInterval = 60000 * 5;
    const componentInstance = this;
    setInterval(function() {
      componentInstance.setState({ refreshCount: componentInstance.state.refreshCount + 1 });
    }, resetInterval);
  }

  render() {
    // do not render until we have a user session
    if (typeof window !== 'object' || !window || !window.app || !window.app.userId) return null;

    console.log(`using iframe to log userId=${window.app.userId} into Identity Server`);
    return (
      <iframe
        style={{ display: 'none' }}
        src={'/' + window.app.subsite + '/app/auth/login-to-identity?resetInterval=' + this.state.refreshCount}
      />
    );
  }
}
