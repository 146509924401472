import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classnames from 'classnames';
import checkIE from '../../Utils/checkForIE.js';
import './TableIndicator.scss';

export default class TableIndicator extends Component {
  static propTypes = {
    text: PropTypes.string,
    iconClass: PropTypes.string,
    colSpan: PropTypes.number,
    stickyTop: PropTypes.number,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node])
  };

  render() {
    const { text, iconClass, colSpan, stickyTop, children } = this.props;
    let styles = { top: stickyTop + 'px', marginTop: stickyTop + 'px' };
    const isIE = checkIE();

    return (
      <tr className={classnames({ 'table-indicator__row-container': isIE })}>
        <td
          className={classnames('table-indicator__row pxl-text-body', { 'table-indicator__sticky': stickyTop })}
          style={styles}
          colSpan={colSpan}
        >
          {children || (
            <div className="table-indicator__container">
              <div className="table-indicator__inner">
                <i className={iconClass}></i>
                <span className="table-indicator__text">{text}</span>
              </div>
            </div>
          )}
        </td>
      </tr>
    );
  }
}
