import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './MidpointAdjustmentSetting.scss';

export default class MidpointAdjustmentSetting extends Component {
  static propTypes = {
    className: PropTypes.string,
    midpointAdjustment: PropTypes.number,
    handleMidpointAdjustmentValueUpdate: PropTypes.func.isRequired,
    handleMidpointAdjustmentClick: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      hoveringEdit: false,
      editingValue: false,
      hasChanges: false,
      midpointAdjustment: this.roundMidpoint(this.props.midpointAdjustment)
    };
    this.delayedSubmitTimer = null;
    this.doSubmit = this.doSubmit.bind(this);
    this.doDelayedSubmit = this.doDelayedSubmit.bind(this);
    this.clearDelayedSubmit = this.clearDelayedSubmit.bind(this);
    this.handleEditValueFocus = this.handleEditValueFocus.bind(this);
    this.handleEditValueChange = this.handleEditValueChange.bind(this);
    this.handleEditValueBlur = this.handleEditValueBlur.bind(this);
    this.handleValueEditClick = this.handleValueEditClick.bind(this);
    this.handleValueMouseEnter = this.handleValueMouseEnter.bind(this);
    this.handleValueMouseLeave = this.handleValueMouseLeave.bind(this);
    this.handleEditValueKeyUp = this.handleEditValueKeyUp.bind(this);
    this.decreaseMidpointAdjustment = this.props.handleMidpointAdjustmentClick.bind(this, -1);
    this.increaseMidpointAdjustment = this.props.handleMidpointAdjustmentClick.bind(this, 1);
  }

  UNSAFE_componentWillReceiveProps = props => {
    this.setState({ midpointAdjustment: this.roundMidpoint(props.midpointAdjustment) });
  };

  doDelayedSubmit() {
    this.clearDelayedSubmit();
    this.setState({ hasChanges: true });
    this.delayedSubmitTimer = setTimeout(() => {
      this.doSubmit();
    }, 1000);
  }

  doSubmit(clearEditState) {
    this.clearDelayedSubmit();
    let value = parseFloat(this.state.midpointAdjustment);
    if (isNaN(value)) value = 0;

    this.props.handleMidpointAdjustmentValueUpdate(Math.round(value * 1000) / 1000);
    if (clearEditState) {
      this.setState({ hasChanges: false, editingValue: false });
    } else {
      this.setState({ hasChanges: false });
    }
  }

  clearDelayedSubmit() {
    if (this.delayedSubmitTimer) {
      clearTimeout(this.delayedSubmitTimer);
    }
  }

  handleEditValueFocus(event) {
    event.target.select();
  }

  handleEditValueBlur() {
    this.setState({ editingValue: false });

    if (!this.state.hasChanges) {
      return;
    }
    this.doSubmit();
  }

  handleEditValueKeyUp(event) {
    if (event.key === 'Enter') {
      if (this.state.hasChanges) {
        this.doSubmit(true);
      } else {
        this.setState({ editingValue: false });
      }
    } else {
      this.doDelayedSubmit();
    }
  }

  handleValueEditClick() {
    this.setState({ editingValue: !this.state.editingValue });
  }

  handleValueMouseEnter() {
    this.setState({ hoveringEdit: true });
  }

  handleValueMouseLeave() {
    this.setState({ hoveringEdit: false });
  }

  handleEditValueChange(event) {
    const value = event.target.value;
    //  Only allow the following formats: Empty, '-' (in case its the beginning of a negative number)
    //  as well as numbers such as 125.352 and -5.32 (up to 3 decimal places)
    if (value.match(/^[-]?[0-9]\d*(\.\d{0,3})?$/) === null && value !== '' && value !== '-') {
      return;
    }

    const newMidpointAdjustmentValue = value;
    this.setState({ midpointAdjustment: newMidpointAdjustmentValue });
  }

  renderEditIcon() {
    const showIconStyle = this.state.hoveringEdit ? '' : 'midpoint-adjustment-setting__pencil-icon--hidden';
    return (
      <i
        className={`midpoint-edit-icon icon-pencil midpoint-adjustment-setting__pencil-icon ${showIconStyle}`}
        onClick={this.handleValueEditClick}
      />
    );
  }

  roundMidpoint(value) {
    return Math.round(value * 100);
  }

  renderValueComponent() {
    const value = this.state.midpointAdjustment;
    if (this.state.editingValue) {
      //  This is here in order to dynamically change the width of the input widget to keep it centered
      //  with the % sign so that edit vs display mode doesn't result in jumping in at alignment
      //  NOTE: This assumes that the font characters are 9 pixels each (might be a better way to query for this)
      const width = Math.min(60, ('' + value).length * 9 + 5);
      const style = { width: width + 'px' };
      return (
        <div className="midpoint-adjustment-setting__value-container">
          <input
            className="midpoint-adjustment-setting__value--edit"
            data-test-id="jbr-setting-midpoint-edit-value"
            style={style}
            type="text"
            value={value}
            autoFocus={true}
            onKeyUp={this.handleEditValueKeyUp}
            onChange={this.handleEditValueChange}
            onBlur={this.handleEditValueBlur}
            onFocus={this.handleEditValueFocus}
          />
          <span className="midpoint-adjustment-setting__pct-symbol--edit">%</span>
        </div>
      );
    } else {
      return (
        //  NOTE: Wrapper is needed for layout purposes
        <div>
          <div className="midpoint-adjustment-setting__value">{value}%</div>
        </div>
      );
    }
  }

  render() {
    return (
      <div className={`midpoint-adjustment-setting ${this.props.className ? this.props.className : ''}`}>
        <h4 className="midpoint__adjustment-title">Adjust Current Midpoint By</h4>
        <div className="midpoint-adjustment-setting__controls-container">
          <button
            className="midpoint-adjustment-setting__round-btn minus midpoint-adjustment-setting__subtract-btn"
            data-test-id="jbr-setting-midpoint-adjust-minus"
            onClick={this.decreaseMidpointAdjustment}
          />
          <div
            className="midpoint-adjustment-setting__edit-container"
            onMouseEnter={this.handleValueMouseEnter}
            onMouseLeave={this.handleValueMouseLeave}
            onClick={this.handleValueEditClick}
            data-test-id="jbr-setting-midpoint-edit-container"
          >
            {this.renderValueComponent()}
            {this.renderEditIcon()}
          </div>
          <button
            className="midpoint-adjustment-setting__round-btn plus midpoint-adjustment-setting__add-btn"
            data-test-id="jbr-setting-midpoint-adjust-plus"
            onClick={this.increaseMidpointAdjustment}
          />
        </div>
      </div>
    );
  }
}
