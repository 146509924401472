import apiClient from '../../../lib/apiClient';

const JOB_STRUCTURE_LIST = 'JOB_STRUCTURE_LIST';
const JOB_STRUCTURE_LIST_SUCCESS = 'JOB_STRUCTURE_LIST_SUCCESS';
const JOB_STRUCTURE_LIST_FAIL = 'JOB_STRUCTURE_LIST_FAIL';

const initialState = {
  loading: false,
  loaded: false,
  error: null,
  jobStructureItems: {}
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case JOB_STRUCTURE_LIST:
      return {
        ...state,
        loading: true
      };
    case JOB_STRUCTURE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: null,
        jobStructureItems: action.result
      };
    case JOB_STRUCTURE_LIST_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        data: null,
        error: action.error
      };
    default:
      return { ...state };
  }
}

export function getJobStructureList() {
  return async (dispatch, getState) => {
    const orgJobId = getState().addJob.orgJobId;
    const options = {
      orgJobId: orgJobId
    };

    try {
      dispatch({ type: JOB_STRUCTURE_LIST });
      const result = await apiClient.apiGet('api/structures/grades/getJobStructures', { params: options });

      dispatch({ type: JOB_STRUCTURE_LIST_SUCCESS, result: result.data });
    } catch (error) {
      dispatch({ type: JOB_STRUCTURE_LIST_FAIL, error: error });
    }
  };
}
