export interface InitServerSessionResponse {
  destination: string;
}

export interface IdentityUser {
  access_token: string;
  expires_at: number;
  id_token: string;
  profile: any;
  scope: string;
  session_state: string;
  token_type: string;
}

export async function initServerSession(accessToken: string): Promise<InitServerSessionResponse> {
  const res = await fetch('/auth/init-server-session', {
    credentials: 'same-origin', // this is the spec default, but Safari was treating it as 'omit'
    method: 'post',
    headers: { Authorization: `Bearer ${accessToken}` }
  });

  const body: InitServerSessionResponse = await res.json();
  return body;
}

export async function postAuthCallback(user: IdentityUser): Promise<string> {
  const { destination } = await initServerSession(user.access_token);
  console.debug(`Server session initialized, setting post-auth destination to ${destination}`);
  return destination;
}
