import PropTypes from 'prop-types';
import React, { Component } from 'react';
import SiteSwitcher from './SiteSwitcher';
import { Link } from 'react-router-dom';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import { Dropdown } from '@payscale/design';

export default class AdminBar extends Component {
  static propTypes = {
    close: PropTypes.func,
    SearchBarComponent: PropTypes.object,
    show: PropTypes.bool,
    app: PropTypes.object,
    logoStatusClass: PropTypes.string,
    downloadFiles: PropTypes.func,
    syncData: PropTypes.func,
    syncProgress: PropTypes.func,
    sites: PropTypes.object,
    userRights: PropTypes.object,
    refreshSiteMarketReports: PropTypes.func,
    refreshMarketReports: PropTypes.object,
    migrateCompanySurvey: PropTypes.object,
    beginCompanySurveyMigration: PropTypes.func
  };

  state = {
    syncInterval: null,
    showSyncComplete: false,
    sourceSubsites: []
  };

  constructor(props) {
    super(props);

    // Define subsites that are allowed to be synced from
    this.state = {
      sourceSubsites: [
        { subsite: 'merlintech2', displayName: 'Standard Demo' },
        { subsite: 'merlintechsmb', displayName: 'Small Demo' }
      ]
    };
  }

  startSync = subsite => {
    const options = {
      sourceUrl: subsite,
      targetUrl: this.props.app.subsite
    };

    this.props.syncData(options);
    if (this.state.syncInterval) {
      clearInterval(this.state.syncInterval);
    }
    const syncInterval = setInterval(this.props.syncProgress, 1500);
    this.setState({ syncInterval });
  };

  UNSAFE_componentWillReceiveProps(nextProps, nextState) {
    if (!this.props.sites.showSyncComplete && nextProps.sites.showSyncComplete) {
      // display sync complete for 5 seconds
      this.setState({ showSyncComplete: true });
      setTimeout(() => {
        this.setState({ showSyncComplete: false });
      }, 5000);
    }
  }

  adminGear(superAdminRights) {
    if (superAdminRights && this.props.app.is_global_organization) {
      return (
        <span className={'adminbar--dropdownLabel'}>
          {superAdminRights && this.props.app.is_global_organization ? (
            <Link to="/admin-utils" onClick={this.props.close}>
              <i className="fa fa-gear fa-lg adminBar--siteAdmin__gear" />
            </Link>
          ) : null}
        </span>
      );
    }

    return <span style={{ width: 5, paddingLeft: 5 }}> </span>;
  }

  render() {
    const showSwitcher = this.props.app.subsites.length > 1;
    const superAdminRights = this.props.app.user_rights.super_admin_p;

    const userRights = this.props.userRights;
    const showDownloadBtn =
      userRights &&
      (userRights.super_admin_p ||
        userRights.admin_p ||
        userRights.group_admin_p ||
        userRights.site_admin ||
        userRights.global_admin);

    const showSyncing = this.props.sites.showSyncing;

    const currentSubsite = this.props.app.subsites.find(o => o.url === this.props.app.subsite);

    let switcherLabelClass = ' noSwitcher';
    if (showSwitcher) switcherLabelClass = '';

    return (
      <div className={'adminBar__container' + this.props.logoStatusClass} id="adminBar-container">
        <div className={'adminBar__close pull-right pointer'} onClick={this.props.close}>
          <i className="fa fa-times fa-lg" />
        </div>

        <div className="AdminBar--links__container center-vertical pull-right">
          {this.props.app.user_rights.super_admin_p ||
          this.props.app.user_rights.mp_admin_p ||
          this.props.app.user_rights.admin_p ? (
            <div className="pointer" onClick={this.props.close}>
              <Link to="/site-administration" onClick={this.props.close}>
                <i className="fa fa-gear fa-lg" /> Manage Site
              </Link>
            </div>
          ) : null}
        </div>

        <div className="AdminBar--links__container center-vertical pull-right">
          {showDownloadBtn ? (
            <div className="pointer" onClick={this.props.downloadFiles}>
              <i className="icon-border icon-circle-thin">
                <i className="icon-down" />
              </i>
              <span>Download Files</span>
            </div>
          ) : null}
        </div>

        <div className="AdminBar--links__container center-vertical pull-right">
          {this.props.app.user_rights.super_admin_p ? (
            <div className="pointer" onClick={this.props.beginCompanySurveyMigration}>
              <i className="fa fa-refresh fa-lg" />
              {this.props.migrateCompanySurvey.migrateBtnText}
            </div>
          ) : null}
        </div>
        {this.props.app.user_rights.super_admin_p ? (
          <div className="AdminBar--links__container center-vertical pull-right">
            <div className="pointer" onClick={this.props.refreshSiteMarketReports}>
              <i className="fa fa-refresh fa-lg" /> {this.props.refreshMarketReports.refreshBtnText}{' '}
            </div>
          </div>
        ) : null}

        <div className="adminBar--subsitedropdown__container center-vertical pull-left">
          <span className={'adminbar--dropdownLabel pull-left' + switcherLabelClass}>Admin</span>
          <SiteSwitcher
            name="form-field-name"
            value={'value here'}
            options={['1', '2', '3']}
            onChange={() => {}}
            newSubsites={this.props.sites}
            subsite={this.props.app.subsite}
            subsites={this.props.app.subsites || []}
            className="pull-left site-switcher"
          />
          {this.adminGear(superAdminRights)}
          <span className={'adminbar--links__container'}>
            {currentSubsite && currentSubsite.subsite_status === 'Demo' ? (
              <Dropdown btnClass="pxl-btn--primary" btnText="Sync Data" disabled={!!showSyncing}>
                {this.state.sourceSubsites.map((subsite, index) => (
                  <a
                    key={index}
                    className="pxl-dropdown__item"
                    href="#"
                    onClick={() => this.startSync(subsite.subsite)}
                  >
                    {subsite.displayName}
                  </a>
                ))}
              </Dropdown>
            ) : null}
            {showSyncing ? (
              <span>
                <i className="fa fa-refresh fa-spin fa-spin adminBar--siteAdmin__syncing" />
                <span>Syncing data...</span>
              </span>
            ) : null}

            <ReactCSSTransitionGroup
              transitionName="sync-status"
              transitionEnterTimeout={50}
              transitionLeaveTimeout={5000}
            >
              {this.state.showSyncComplete ? (
                <span>
                  <i className="fa fa-check-circle-o adminBar--siteAdmin__syncCheck" />
                  <span>Data sync completed</span>
                </span>
              ) : null}
            </ReactCSSTransitionGroup>
          </span>
        </div>
        <div className="clearfix" />
      </div>
    );
  }
}
