import PropTypes from 'prop-types';
import React, { Component } from 'react';
import SearchBar from '../../components/Search/SearchBar';
import SearchBarResults from '../../components/Search/SearchBarResults';
import { connect } from 'react-redux';
import * as actions from '../../reducers/modules/universalSearch/universalSearch';
import * as universalSearchActions from '../../reducers/modules/universalSearch/universalSearch';
import { actions as jobSearchActions } from '../../reducers/modules/jobs/jobsSearch';

@connect(
  state => ({
    items: state.universalSearch.resultItems,
    loaded: state.universalSearch.loaded,
    loading: state.universalSearch.loading,
    error: state.universalSearch.error,
    universalSearch: state.universalSearch
  }),
  { ...actions, ...universalSearchActions, jobsPageSearch: jobSearchActions.search }
)
export default class Search extends Component {
  static propTypes = {
    items: PropTypes.array,
    history: PropTypes.object,
    type: PropTypes.string,
    search: PropTypes.func,
    universalSearch: PropTypes.object,
    searchBarValue: PropTypes.object,
    doUniversalSearch: PropTypes.func,
    setSearchResultVisibility: PropTypes.func,
    setSearchValue: PropTypes.func,
    resetSearchResults: PropTypes.func,
    jobsPageSearch: PropTypes.func,
    searchResultsFocusRow: PropTypes.func,
    setFocusLink: PropTypes.func,
    showFullSearch: PropTypes.bool,
    showFullSearchStyles: PropTypes.bool,
    closeFullSearchBar: PropTypes.func
  };

  constructor(props) {
    super(props);

    this.blur = this.blur.bind(this);
    this.click = this.click.bind(this);
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (
      this.props.universalSearch.query === nextProps.universalSearch.query &&
      this.props.universalSearch.focusRowIdx === nextProps.universalSearch.focusRowIdx &&
      this.props.universalSearch.focusRowLink === nextProps.universalSearch.focusRowLink &&
      this.props.universalSearch.focusRowDocTitle === nextProps.universalSearch.focusRowDocTitle &&
      this.props.universalSearch.showSearchResults === nextProps.universalSearch.showSearchResults &&
      this.props.showFullSearch === nextProps.showFullSearch &&
      this.props.showFullSearchStyles === nextProps.showFullSearchStyles &&
      JSON.stringify(this.props.universalSearch.items) === JSON.stringify(nextProps.universalSearch.items)
    ) {
      return false;
    }

    return true;
  }

  blur() {
    setTimeout(() => {
      this.props.setSearchResultVisibility(false);
    }, 500);
  }

  click() {
    this.props.setSearchResultVisibility(true);
  }

  render() {
    let clsResults = 'hidden';
    if (this.props.universalSearch.showSearchResults) {
      clsResults = '';
    }

    return (
      <div onBlur={this.blur} onClick={this.click}>
        <SearchBar
          setSearchResultVisibility={this.props.setSearchResultVisibility}
          universalSearch={this.props.universalSearch}
          doUniversalSearch={this.props.doUniversalSearch}
          setSearchValue={this.props.setSearchValue}
          resetSearchResults={this.props.resetSearchResults}
          jobsPageSearch={this.props.jobsPageSearch}
          // searchBarValue={this.props.searchBarValue}
          searchResultsFocusRow={this.props.searchResultsFocusRow}
          history={this.props.history}
          showFullSearch={this.props.showFullSearch}
          showFullSearchStyles={this.props.showFullSearchStyles}
          closeFullSearchBar={this.props.closeFullSearchBar}
        />
        <div className={'' + clsResults}>
          <SearchBarResults
            universalSearch={this.props.universalSearch}
            doUniversalSearch={this.props.doUniversalSearch}
            setSearchValue={this.props.setSearchValue}
            resetSearchResults={this.props.resetSearchResults}
            jobsPageSearch={this.props.jobsPageSearch}
            searchBarValue={this.props.searchBarValue}
            setFocusLink={this.props.setFocusLink}
            showFullSearchStyles={this.props.showFullSearchStyles}
            closeFullSearchBar={this.props.closeFullSearchBar}
            history={this.props.history}
          />
        </div>
      </div>
    );
  }
}
