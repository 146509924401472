import templateSettings from 'lodash/templateSettings';
import template from 'lodash/template';
import round from 'lodash/round';
import isDate from 'lodash/isDate';

import { calculateFte } from '../../../panther-react/src/helpers/calculateFte';
import { roundToPrecision } from '../../../panther-react/src/helpers/utils';
import { formatNumber } from '../Utils/currencyUtils';

export const combineName = name => {
  return name.toLowerCase().replace(/ /g, '-');
};

export const dateTime = millisecondsSinceEpoch => new Date(millisecondsSinceEpoch).toString();

export const dynamicColumnTemplate = (data, dynamicColumns, optionalInterpolation) => {
  if (optionalInterpolation) {
    templateSettings.interpolate = optionalInterpolation;
  } else {
    // finds characters that match ${valuehere}
    templateSettings.interpolate = /\${([\s\S]+?)}/g;
  }

  const templateFunc = template(JSON.stringify(data));

  try {
    const formattedData = templateFunc(dynamicColumns);
    data = JSON.parse(formattedData);
  } catch (e) {
    if (e instanceof ReferenceError) {
      //TODO: switch real logging
      alert('Unable to format value: ' + e.message);
    }
  }

  return data;
};

export const longMonthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

export function isValidDate(d) {
  return d instanceof Date && !isNaN(d);
}

export const utcShortDateString = dateInput => {
  if (!isValidDate(dateInput) || !isDate(dateInput)) {
    return '';
  }

  return `${longMonthNames[dateInput.getUTCMonth()].substr(
    0,
    3
  )} ${dateInput.getUTCDate()}, ${dateInput.getUTCFullYear()}`;
};

export const shortDateString = dateInput => {
  if (!isValidDate(dateInput) || !isDate(dateInput)) {
    return '';
  }

  return `${longMonthNames[dateInput.getMonth()].substr(0, 3)} ${dateInput.getDate()}, ${dateInput.getFullYear()}`;
};

export const longDateString = dateInput => {
  if (!isValidDate(dateInput) || !isDate(dateInput)) {
    return '';
  }

  return `${longMonthNames[dateInput.getMonth()]} ${dateInput.getDate()}, ${dateInput.getFullYear()}`;
};

export const numberWithCommas = value => {
  return formatNumber(value, null, null);
};

export const getCurrentYear = () => {
  return new Date().getFullYear();
};

export const lowerCaseFirstLetter = word => {
  return word.charAt(0).toLowerCase() + word.substr(1);
};

export const capitalizeFirstLetter = word => {
  if (!word) return;
  return word.charAt(0).toUpperCase() + word.slice(1);
};

export const camelCase = word => {
  if (!word) return;
  return word
    .replace(/(?:^\w|[A-Z]|\b\w)/g, (letter, index) => {
      return index == 0 ? letter.toLowerCase() : letter.toUpperCase();
    })
    .replace(/\s+/g, '');
};

export const roundValue = (value, roundTo) => {
  if (value === Number(Infinity)) return '';
  if (roundTo >= 0) {
    return Number(parseFloat(value)).toLocaleString('en', {
      minimumFractionDigits: roundTo,
      maximumFractionDigits: roundTo
    });
  } else {
    const pow = Math.pow(10, roundTo * -1);
    const roundedValue = Math.round(value / pow) * pow;
    return roundedValue.toLocaleString('en', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    });
  }
};

export function formatCurrency(value, noDecimals, excludeDollarSign) {
  const parsedValue = parseFloat(value);
  if (value - parsedValue + 1 >= 0) {
    // add commas as thousands separators
    let formatted = `$${parsedValue.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')}`;
    if (excludeDollarSign) {
      formatted = formatted.slice(1);
    }
    return noDecimals ? formatted.slice(0, -3) : formatted;
  }

  return value;
}

//Only flags with the word Flag (ex. teamFlag) or show will be used for feature flags.
export const setPendoFeatureFlags = flags => {
  const featureFlag = [];
  Object.keys(flags).forEach(feature => {
    if (feature.indexOf('Flag') >= 0) {
      if (flags[feature] === true) {
        featureFlag.push(feature.split('Flag')[0] + '_on');
      } else {
        featureFlag.push(feature.split('Flag')[0] + '_off');
      }
    } else if (feature.indexOf('show') >= 0) {
      if (flags[feature] === true) {
        featureFlag.push(feature + '_on');
      } else {
        featureFlag.push(feature + '_off');
      }
    }
  });

  return featureFlag;
};

export const setPendoDarklyFlags = flags => {
  const darklyFlag = [];
  Object.keys(flags).forEach(feature => {
    if (feature.indexOf('Flag') <= 0 && feature.indexOf('show_') <= 0) {
      if (flags[feature] === true) {
        darklyFlag.push(feature + '_on');
      } else {
        darklyFlag.push(feature + '_off');
      }
    }
  });

  return darklyFlag;
};

export function getTotalProfilesAnalyzed(row) {
  let totalProfilesAnalyzed = 0;

  if (
    row &&
    row.payscale_job_info &&
    row.payscale_job_info.Reports &&
    row.payscale_job_info.Reports.payReport &&
    row.payscale_job_info.Reports.payReport.reports &&
    row.payscale_job_info.Reports.payReport.reports.totalProfilesAnalyzed
  ) {
    totalProfilesAnalyzed = row.payscale_job_info.Reports.payReport.reports.totalProfilesAnalyzed;
  }

  if (totalProfilesAnalyzed >= 1000) {
    totalProfilesAnalyzed /= 1000;
    totalProfilesAnalyzed = `${totalProfilesAnalyzed.toFixed(1)}K`;
  } else {
    totalProfilesAnalyzed = `${totalProfilesAnalyzed.toFixed(0)}`;
  }

  return totalProfilesAnalyzed;
}

export function splitWorkLocation(workLocation) {
  let country = '';
  let state = '';
  let city = '';
  if (workLocation && workLocation !== '' && workLocation.split(';').length > 1 && workLocation.split(',').length > 1) {
    country = workLocation.split(';', 2)[1].trimLeft();
    state = workLocation
      .split(';', 2)[0]
      .split(',', 2)[1]
      .trimLeft();
    city = workLocation.split(';', 2)[0].split(',', 1)[0];
  } else if (workLocation && workLocation != '' && workLocation.split(';').length > 1) {
    country = workLocation.split(';', 2)[1].trimLeft();
    city = workLocation.split(';', 1)[0].split(',', 1)[0];
  } else if (workLocation && workLocation != '') {
    country = workLocation;
  } else if (window.app.default_currency === 'CAD' || workLocation === 'Canada (Natl)') {
    country = 'Canada';
  } else if (window.app.default_currency === 'GBP' || workLocation === 'United Kingdom (Natl)') {
    country = 'United Kingdom';
  } else {
    country = 'United States';
  }
  return {
    country: country,
    state: state,
    city: city
  };
}

export function translateSurveyStatus(status) {
  if (status) {
    return 'Included';
  }
  return 'Excluded';
}

export function formatCurrencyIntl(value, intl, app, metadata) {
  // available options to pass into intl
  // intl: {
  //   currency:            specific currency to use, overriding default
  //   country_code:
  //   preferOriginalValue,
  //   preferHourlyRate,
  //   removeDecimals:      whether to remove any fractional component
  //   useThousands:        whether to round/display as thousands
  //   useHourly:           force display to two decimal digits
  //   isChart:             whether value is to be displayed in a chart
  //  }

  // an object can be passed into metadata for additional data.
  // currently used for passing in fte value for individual employee {fte: 1, employeeWorkHours: 40}

  if (typeof value !== 'number') {
    return value;
  }

  const currentCurrency = (intl && intl.currency) || app.default_currency;
  // Transform value based on hourly, fte, etc
  const preferOriginalValue = intl && intl.preferOriginalValue;
  const preferHourlyRate = intl && intl.preferHourlyRate;
  const monetaryDivisor = getMonetaryDivisor(app.monetaryDisplay, intl);
  if (!preferHourlyRate && !preferOriginalValue) {
    // If we don't prefer hourly rate, apply divisor
    let fte = 1.0;

    if (metadata && metadata.employeeWorkHours) {
      fte = calculateFte(metadata.employeeWorkHours, currentCurrency, intl.country_code, app);
    }

    if (metadata && metadata.fte) {
      // an object can be passed into metadata for additional data.
      // currently used for passing in fte value for individual employee {fte: 1}
      fte = metadata.fte;
    }

    value /= monetaryDivisor * fte;
  }

  // Figure out number of decimal places to display based on currency and/or app settings
  const currencies = app.currencies ? app.currencies : window.app.currencies;
  const currencyMatch = currencies.find(currency => currency.currency === currentCurrency);
  let decimalPlaces = getDesiredDecimalPlaces(app, monetaryDivisor, currencyMatch, intl);

  if (!intl) {
    return formatCurrency(roundToPrecision(value, 0));
  }

  // set a max of two decimal places
  decimalPlaces = intl.removeDecimals ? 0 : Math.min(decimalPlaces, 2);

  let intlVal;

  // useThousands is currently used both in the context of a large value being passed in for division, like 50000
  // being formatted as $50.0K, as well as a small value just for formatting, like 50 => $50.0K. This logic should be
  // split up to reduce complexity when possible
  if (intl.useThousands) {
    let useEndingValue = 'K';
    if (value >= 1000) {
      value /= 1000;
    }
    if (value >= 1000) {
      value /= 1000;
      useEndingValue = 'M';
    }
    if (value >= 1000) {
      value /= 1000;
      useEndingValue = 'B';
    }
    value = intl.removeDecimals ? Math.round(value) : value.toFixed(1);
    intlVal = `${value}${useEndingValue}`;
  } else if (intl.useHourly) {
    // commas for thousands and 2 digits for hourly
    intlVal = roundToPrecision(value, decimalPlaces).toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    });
  } else {
    intlVal = roundToPrecision(value, decimalPlaces).toLocaleString();
  }

  // Skip the match based returns if match fails
  if (!currencyMatch) {
    return `${intl.currency} ${intlVal}`;
  }

  // no need to show currency symbol in current currency or for any charts
  if (currentCurrency === app.default_currency || intl.isChart || currencyMatch.symbol) {
    return `${currencyMatch.symbol}${intlVal}`;
  }

  return `${currencyMatch.currency_pretty} ${intlVal}`;
}

export function getMonetaryDivisor(monetaryDisplay, intl) {
  if (intl && intl.useHourly === true) {
    // if passed in, override monetary display
    return 2080;
  }

  let monetaryDivisor = 1;
  if (monetaryDisplay) {
    switch (monetaryDisplay) {
      case 'thousands':
        monetaryDivisor = 1000;
        break;
      case 'annual':
        // divide by 1, so do nothing
        break;
      case 'monthly':
        monetaryDivisor = 12;
        break;
      case 'hourly':
        monetaryDivisor = 2080;
        break;
      case 'millions':
        monetaryDivisor = 1000000;
        break;
      default:
        break;
    }
  }

  return monetaryDivisor;
}

export function getDesiredDecimalPlaces(app, monetaryDivisor, currencyMatch, intl) {
  let numDecimalPlaces = 0; // default to annual/thousands

  // Round the value if monetary rounding is not set or is equal to 0, before other manipulations
  if (app) {
    if (app.monetaryDivisor === 2080 || monetaryDivisor === 2080) {
      // If we have a currency match, use the rounding for the currency, otherwise default to two

      return currencyMatch && currencyMatch.hourly_decimals ? currencyMatch.hourly_decimals : 2;
    }

    // Or if this is annual, round to nearest dollar
    if (
      app.monetary_display === 'thousands' ||
      app.monetary_display === 'annual' ||
      app.monetaryDivisor === 1 ||
      (intl && intl.useThousands)
    ) {
      return 0;
    }

    // Least priority, default to app.field_formatting
    if (app && app.field_formatting && app.field_formatting.monetary_rounding) {
      return app.field_formatting.monetary_rounding;
    }
  }

  // If we have no extra formatting info information, return the plain rounded value
  return 0;
}

export function parseNumCheckNull(str, isInt) {
  str = str instanceof Array ? str[0] : str;
  if (str === undefined || str === null) return '-';
  if (str) return numberWithCommas(str);
  return '0';
}

export function parseFloatCheckNull(str) {
  if (str) return numberWithCommas(100 * parseFloat(str));
  return '-';
}

export function getDefaultRoundings(preferences = {}, displayIn = null) {
  let { monetaryDisplay, monetaryRounding, numericRounding, percentRounding } = preferences;
  let newDisplayIn = displayIn;
  monetaryRounding = monetaryRounding;
  numericRounding = numericRounding || 1;
  percentRounding = percentRounding || 2;

  if (!newDisplayIn) {
    newDisplayIn = monetaryDisplay || 'annual';
  }

  if (!monetaryRounding && monetaryRounding !== 0) {
    if (newDisplayIn === 'annual') {
      monetaryRounding = 0;
    } else if (newDisplayIn === 'thousands') {
      monetaryRounding = 1;
    } else if (newDisplayIn === 'hourly') {
      monetaryRounding = 2;
    } else {
      monetaryRounding = field_formatting.monetary_rounding || 2;
    }
  }

  return { monetaryRounding, numericRounding, percentRounding, displayIn: newDisplayIn };
}

export function applyRounding(data, dataType, preferences = {}, displayIn) {
  preferences = getDefaultRoundings(preferences, displayIn);
  const { monetaryRounding, percentRounding, numericRounding } = preferences;
  const newData = data instanceof Array ? data : [data];
  const newRows = newData.map(value => {
    let newValue = value;
    if (value) {
      if (dataType === 'monetary') {
        if (displayIn === 'hourly') {
          value = parseFloat(value) / 2080;
        }
        newValue = round(value, monetaryRounding);
        if (monetaryRounding > 0) {
          newValue = newValue.toFixed(monetaryRounding);
        }
      } else if (dataType === 'percent') {
        newValue = round(value, percentRounding);
        if (percentRounding > 0) {
          newValue = newValue.toFixed(percentRounding);
        }
      } else if (dataType === 'numeric') {
        newValue = round(value, numericRounding);
        if (numericRounding > 0) {
          newValue = newValue.toFixed(numericRounding);
        }
      }
    }
    return newValue;
  });

  return data instanceof Array ? newRows : newRows[0];
}

export function createDataTestId(string, descriptor) {
  return `${string
    .replace(/[{()}]/g, '')
    .replace(/\s+/g, '-')
    .toLowerCase()}-${descriptor}`;
}
