import apiClient from 'ps-components/src/lib/apiClient';
import { selectors as jobSelectors } from './jobsSearch';

const COMPARE = 'COMPARE_SEARCH';
const COMPARE_SUCCESS = 'COMPARE_SUCCESS';
const COMPARE_FAIL = 'COMPARE_FAIL';
const JOB_COMPARISON_RANGE_CHART_LOAD = 'JOB_COMPARISON_RANGE_CHART_LOAD';
const JOB_COMPARISON_RANGE_CHART_SUCCESS = 'JOB_COMPARISON_RANGE_CHART_SUCCESS';
const JOB_COMPARISON_RANGE_CHART_FAIL = 'JOB_COMPARISON_RANGE_CHART_FAIL';
export const GET_ORG_JOBS_BY_ID_START = 'GET_ORG_JOBS_BY_ID_START';
export const GET_ORG_JOBS_BY_ID_SUCCESS = 'GET_ORG_JOBS_BY_ID_SUCCESS';
export const GET_ORG_JOBS_BY_ID_FAILED = 'GET_ORG_JOBS_BY_ID_FAILED';
export const GET_GEO_DIFF_START = 'GET_GEO_DIFF_START';
export const GET_GEO_DIFF_SUCCESS = 'GET_GEO_DIFF_SUCCESS';
export const GET_GEO_DIFF_ERROR = 'GET_GEO_DIFF_ERROR';
const GET_ALL_WORK_LOCATION_EXACTS_SUCCESS = 'GET_ALL_WORK_LOCATION_EXACTS_SUCCESS';
const GET_ESTIMATED_IMPACT_RESET = 'GET_ESTIMATED_IMPACT_RESET';
const GET_ESTIMATED_IMPACT_SUCCESS = 'GET_ESTIMATED_IMPACT_SUCCESS';
const GET_ESTIMATED_IMPACT_ERROR = 'GET_ESTIMATED_IMPACT_ERROR';
const GET_SELECTED_JOBS_TO_COPY = 'GET_SELECTED_JOBS_TO_COPY';
const JOB_ACTION_SLIDEOUT_RESULT = 'JOB_ACTION_SLIDEOUT_RESULT';
export const JOB_ACTION_SLIDEOUT_FAILURE = 'JOB_ACTION_SLIDEOUT_FAILURE';
export const JOB_ACTION_SLIDEOUT_START = 'JOB_ACTION_SLIDEOUT_START';
const TOGGLE_JOB_MOVE_SLIDEOUT = 'TOGGLE_JOB_MOVE_SLIDEOUT';
const TOGGLE_JOB_COPY_SLIDEOUT = 'TOGGLE_JOB_COPY_SLIDEOUT';

const initialState = {
  loading: false,
  loaded: false,
  error: null,
  items: {},
  query: '',
  jobIdList: [],
  rangeChartsLoading: false,
  highChartsJson: null,
  charts: null,
  chartsFailedToLoad: false,
  selectedCurrency: null,
  geoDiff: [],
  workLocationExact: [],
  estimatedImpactLoading: false,
  estimatedImpactError: null,
  estimatedImpact: {
    above: {
      current: 0,
      new: 0
    },
    below: {
      current: 0,
      new: 0
    }
  },
  selectedWorkLocation: null,
  selectedJobIdsToCopy: [],
  jobActionSlideoutSuccess: false,
  jobActionSlideoutFailure: false
};

function extractNodes(data) {
  const nodes = {
    titles: ['Title'],
    workLocation: ['Work Location'],
    experience: ['Experience'],
    education: ['Education']
  };

  if (data) {
    if (data.length > 1) {
      for (let idx = 0; idx < data.length; idx++) {
        const row = data[idx];

        if (row.org_job_title) {
          nodes.titles.push(row.org_job_title);
        } else {
          nodes.titles.push('Job Title');
        }
        if (row.payscale_job_info.workLocation) {
          nodes.workLocation.push(row.payscale_job_info.workLocation);
        } else if (row.org_job_code_key && row.org_job_code_key !== ' ') {
          nodes.workLocation.push(row.org_job_code_key);
        } else {
          nodes.workLocation.push('');
        }
        if (row.payscale_job_info.YearsExperience) {
          nodes.experience.push(row.payscale_job_info.YearsExperience);
        } else {
          nodes.experience.push('');
        }
        if (row.payscale_job_info.HighestDegreeEarned) {
          nodes.education.push(row.payscale_job_info.HighestDegreeEarned);
        } else {
          nodes.education.push('');
        }
      }
    }
  }
  return nodes;
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case COMPARE:
      return {
        ...state,
        loading: true,
        loaded: false,
        error: null,
        query: action.query,
        items: {},
        itemsComparison: null
      };
    case COMPARE_SUCCESS:
      const nodes = extractNodes(action.result.formData);
      const orderedItems = action.result;
      return {
        ...state,
        loading: false,
        loaded: true,
        error: null,
        items: orderedItems,
        itemsComparison: nodes
      };
    case COMPARE_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
        query: null,
        items: {},
        itemsComparison: null
      };
    case JOB_COMPARISON_RANGE_CHART_LOAD:
      return {
        ...state,
        highChartsJson: null,
        jobIdList: [],
        charts: null,
        rangeChartsLoading: true,
        chartsFailedToLoad: false,
        selectedCurrency: action.currency
      };
    case JOB_COMPARISON_RANGE_CHART_SUCCESS:
      const descendingCharts = action.result.chartData.sort((a, b) => Number(b.data['50th']) - Number(a.data['50th']));
      return {
        ...state,
        highChartsJson: action.result.highChartsJson,
        charts: descendingCharts,
        jobIdList: action.result.job_id,
        rangeChartsLoading: false,
        chartsFailedToLoad: false,
        selectedCurrency: action.currency
      };
    case JOB_COMPARISON_RANGE_CHART_FAIL:
      return {
        ...state,
        highChartsJson: null,
        jobIdList: [],
        charts: null,
        rangeChartsLoading: true,
        chartsFailedToLoad: true,
        selectedCurrency: action.currency
      };
    case GET_GEO_DIFF_SUCCESS:
      return {
        ...state,
        geoDiff: action.geoDiff
      };
    case GET_GEO_DIFF_ERROR:
      return {
        ...state,
        error: action.error
      };
    case GET_ALL_WORK_LOCATION_EXACTS_SUCCESS:
      return {
        ...state,
        workLocationExact: action.workLocationExact
      };
    case GET_ESTIMATED_IMPACT_RESET:
      return {
        ...state,
        estimatedImpactLoading: true,
        estimatedImpactError: null,
        selectedWorkLocation: action.selectedWorkLocation
      };
    case GET_ESTIMATED_IMPACT_SUCCESS:
      return {
        ...state,
        estimatedImpactLoading: false,
        estimatedImpactError: null,
        estimatedImpact: action.result
      };
    case GET_ESTIMATED_IMPACT_ERROR:
      return {
        ...state,
        estimatedImpactLoading: false,
        estimatedImpactError: action.error
      };
    case GET_SELECTED_JOBS_TO_COPY:
      return {
        ...state,
        selectedJobIdsToCopy: action.selectedJobIds
      };
    case JOB_ACTION_SLIDEOUT_START:
      return {
        ...state,
        jobActionSlideoutSuccess: false,
        jobActionSlideoutFailure: false
      };
    case JOB_ACTION_SLIDEOUT_RESULT:
      return {
        ...state,
        jobActionSlideoutSuccess: action.result.completed
      };
    case JOB_ACTION_SLIDEOUT_FAILURE:
      return {
        ...state,
        jobActionSlideoutFailure: true
      };
    case TOGGLE_JOB_COPY_SLIDEOUT:
    case TOGGLE_JOB_MOVE_SLIDEOUT:
      return {
        ...state,
        jobActionSlideoutFailure: false,
        jobActionSlideoutSuccess: false
      };
    default:
      return { ...state };
  }
}

export function doJobComparison(params) {
  // const params = {params: {org_job_id: selectedList}};
  return {
    types: [COMPARE, COMPARE_SUCCESS, COMPARE_FAIL],
    promise: client => client.post('/api/hris/job-details/jobCompare', { params: params }),
    params
  };
}

export function getAllWorkLocationExacts() {
  return async (dispatch, getState) => {
    try {
      const workLocationExacts = getState().jobComparison.workLocationExact;
      if (workLocationExacts.length) {
        return workLocationExacts;
      }
      const res = await apiClient.apiGet('/api/hris/jobs/getAllWorkLocationExacts', {
        params: {}
      });

      let workLocations;
      if (res && res.data) {
        workLocations = res.data.result.rows
          .map(item => item.work_location_exact)
          .filter(location => location.includes('; United States') && !location.includes('undefined'));
        dispatch({ type: GET_ALL_WORK_LOCATION_EXACTS_SUCCESS, workLocationExact: workLocations });
      }
      return workLocations;
    } catch {
      dispatch({ type: GET_ORG_JOBS_BY_ID_FAILED });
    }
  };
}

export function getAllWorkLocationExactsForJobs() {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const res = await apiClient.apiGet('/api/hris/jobs/getAllWorkLocationExactsForJobs', {
        params: {
          ...jobSelectors.getSearchParameters(state),
          includedIds: state.jobsList.multiSelectedRows,
          excludedIds: state.jobsList.multiExcludedRows
        }
      });

      let workLocations = {};
      if (res && res.data) {
        res.data.result.rows.map(row => (workLocations[row.org_job_id] = row.work_location_exact));
        dispatch({ type: GET_SELECTED_JOBS_TO_COPY, selectedJobIds: res.data.selectedJobIds.selectedIds });
      }
      return workLocations;
    } catch {
      dispatch({ type: GET_ORG_JOBS_BY_ID_FAILED });
    }
  };
}

export function getGeoDiff(fromCities, toCity) {
  return async dispatch => {
    try {
      const result = await apiClient.apiPost(`/api/hris/jobs/getGeoDiff`, {
        fromCities,
        toCity
      });

      if (result.status < 300) {
        dispatch({ type: GET_GEO_DIFF_SUCCESS, geodiff: result.data.data.value.rows });
        return result.data.data.value.rows.slice(0, 100);
      }
      dispatch({ type: GET_GEO_DIFF_ERROR });
    } catch (error) {
      dispatch({ type: GET_GEO_DIFF_ERROR, error: error });
    }
  };
}

export function getEstimatedImpact(newWorkLocation) {
  return async (dispatch, getState) => {
    dispatch({ type: GET_ESTIMATED_IMPACT_RESET, selectedWorkLocation: newWorkLocation });

    try {
      const state = getState();
      const result = await apiClient.apiGet('/api/hris/employeeSummaries/marketAnalysisSummary', {
        params: {
          ...jobSelectors.getSearchParameters(state),
          includedIds: state.jobsList.multiSelectedRows,
          excludedIds: state.jobsList.multiExcludedRows,
          newWorkLocation
        }
      });

      // If we're getting impact for another location, throw out this one
      if (getState().jobComparison.selectedWorkLocation !== newWorkLocation) {
        return;
      }

      if (!result.data.success) {
        dispatch({ type: GET_ESTIMATED_IMPACT_ERROR, error: result.data.reason });
      } else {
        dispatch({ type: GET_ESTIMATED_IMPACT_SUCCESS, result: result.data });
      }
    } catch (error) {
      if (getState().jobComparison.selectedWorkLocation === newWorkLocation) {
        dispatch({ type: GET_ESTIMATED_IMPACT_ERROR, error });
      }
    }
  };
}

export function loadJobComparisonCharts(options) {
  // const params = {params: {org_job_id: selectedList}};
  const chartUrl = '/api/hris/job-detail-charts/jobRanges';
  const params = {
    org_job_id: options.org_job_id,
    currency: options.currency,
    annual_or_hourly: options.annual_or_hourly
  };

  return {
    types: ['JOB_COMPARISON_RANGE_CHART_LOAD', 'JOB_COMPARISON_RANGE_CHART_SUCCESS', 'JOB_COMPARISON_RANGE_CHART_FAIL'],
    promise: client => client.get(chartUrl, { params: params }),
    params
  };
}
