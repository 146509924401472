const JOB_CHART = 'JOB_CHART';
const JOB_CHART_SUCCESS = 'JOB_CHART_SUCCESS';
const JOB_CHART_FAIL = 'JOB_CHART_FAIL';

const initialState = {
  loading: false,
  loaded: false,
  error: null,
  chart: {},
  pricingStatusMode: 'allJobs'
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case JOB_CHART:
      return {
        ...state,
        loading: true,
        pricingStatusMode: action.params.pricingStatusMode
      };
    case JOB_CHART_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: null,
        highChartsJson: action.result
      };
    case JOB_CHART_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        data: null,
        error: action.error
      };
    default:
      return { ...state };
  }
}

export function getJobPriceStatusChart(params = {}) {
  if (params.category_pk === undefined) {
    params.category_pk = 2;
  }

  if (params.analyticsAggMode === undefined) {
    params.analyticsAggMode = true;
  }

  if (params.pricingStatusMode === undefined) {
    params.pricingStatusMode = 'allJobs';
  }

  return {
    types: [JOB_CHART, JOB_CHART_SUCCESS, JOB_CHART_FAIL],
    promise: client =>
      client.get('/api/baby-panther/home-charts/jobPriceStatus', { params: params }),
    params: params
  };
}
