import { addNestedChildrenReducers } from '../../../helpers/utils';
import groupList from './groupList';
import allocation from './allocation';

const initialState = {
  performanceBuckets: [],
  /*
  {
      name: 'Does Not Meet',
      employeeCount: 10,
      rawSliderValue: 25
    }
   */

  payBuckets: [],
  /*
  {
      name: 'Above',
      rawSliderValue: 10
    }
   */
  matrixCells: []
  /*
  {
      performanceBucket: 'Exceeds',
      payBucket: 'Min',
      employeeCount: 8,
      payIncreasePct: 0.04
    }
   */
};

export default addNestedChildrenReducers(reducer, { groupList, allocation });
function reducer(state = initialState, action) {
  switch (action.type) {
    case 'PAY_RANGE_GRID':
      return {
        ...state
      };
    case 'PAY_RANGE_GRID_SUCCESS':
      return {
        ...state
      };
    case 'MATRIX_CALC':
      return {
        ...state,
        matrixCells: action.matrixCells,
        performanceBuckets: action.performanceBuckets,
        payBuckets: action.payBuckets
      };
    case 'GET_MATRIX_GROUP_SUCCESS':
      if (!action.result || (action.result && !action.result.groupModel)) {
        return { ...state };
      }
      const { payBuckets, performanceBuckets } = action.result.groupModel;
      const cellData = action.result.cellData;

      return {
        ...state,
        payBuckets,
        performanceBuckets,
        matrixCells: cellData.cells
      };
    case 'CHANGE_MATRIX_RANGE_TYPE_SUCCESS':
      return {
        ...state,
        matrixCells: action.result.cellData.cells
      };
    default:
      return state;
  }
}
