const EMPLOYEE_COMPARE = 'EMPLOYEE_COMPARE_SEARCH';
const EMPLOYEE_COMPARE_SUCCESS = 'EMPLOYEE_COMPARE_SUCCESS';
const EMPLOYEE_COMPARE_FAIL = 'EMPLOYEE_COMPARE_FAIL';
import { formatCurrencyIntl } from '../../../helpers/utils';

const initialState = {
  loading: false,
  loaded: false,
  error: null,
  items: {},
  query: '',
  employeeIdList: []
};

function extractNodes(data, currency) {
  const intl = { useThousands: false, useHourly: false, display: 'symbol', currency: currency };
  const nodes = {
    fullName: ['Name'],
    titles: ['Title'],
    experience: ['Experience'],
    salaries: ['Salary'],
    salary_effective_date: ['Salary Effective Date'],
    bonuses: ['Bonuses'],
    hireDates: ['Hire Date'],
    ethnicity: ['Ethnicity'],
    department: ['Department'],
    currency: ['Currency'],

    flsa_exempt_p: ['FLSA Exempt'],
    full_part_time: ['Full Time'],
    highest_degree: ['Highest Degree'],
    highest_degree_date: ['Highest Degree Date'],
    hourly_rate: ['Hourly Rate'],
    manager_name: ['Manager'],
    org_unit: ['Org Unit'],
    overtime_eligible_p: ['Overtime Eligible'],
    planned_increase_1: ['Planned Increase'],
    planned_increase_1_date: ['Planned Increase Date'],
    pmp_rating: ['PMP Rating'],
    std_work_hours: ['Standard Work Hours'],
    tcc: ['Total Cash Comp'],
    work_location: ['Work Location']
  };

  if (data) {
    if (data.length > 1) {
      for (let idx = 0; idx < data.length; idx++) {
        const row = data[idx];

        if (row.full_name) {
          nodes.fullName.push(row.full_name);
        } else {
          nodes.fullName.push('Employee Name');
        }
        if (row.job_title) {
          nodes.titles.push(row.job_title);
        } else {
          nodes.titles.push('Job Title');
        }
        if (row.experience_pretty) {
          nodes.experience.push(row.experience_pretty);
        } else {
          nodes.experience.push('');
        }
        if (row.base_salary) {
          nodes.salaries.push(formatCurrencyIntl(row.base_salary, intl, window.app));
        }
        if (row.bonus_paid) {
          nodes.bonuses.push(formatCurrencyIntl(Math.round(row.bonus_paid), intl, window.app));
        }
        if (row.date_of_hire) {
          nodes.hireDates.push(row.date_of_hire);
        }
        if (row.ethnicity) {
          nodes.ethnicity.push(row.ethnicity);
        }
        if (row.department) {
          nodes.department.push(row.department);
        }
        if (row.currency) {
          nodes.currency.push(row.currency);
        }
        if (row.flsa_exempt_p) {
          nodes.flsa_exempt_p.push(row.flsa_exempt_p);
        }
        if (row.full_part_time) {
          nodes.full_part_time.push(row.full_part_time);
        }
        if (row.highest_degree) {
          nodes.highest_degree.push(row.highest_degree);
        }
        if (row.highest_degree_date) {
          if (row.highest_degree_date.length > 9) {
            nodes.highest_degree_date.push(row.highest_degree_date.substring(0, 10));
          } else {
            nodes.highest_degree_date.push(row.highest_degree_date);
          }
        }
        if (row.hourly_rate) {
          nodes.hourly_rate.push(row.hourly_rate);
        }
        if (row.manager_name) {
          nodes.manager_name.push(row.manager_name);
        }
        if (row.org_unit) {
          nodes.org_unit.push(row.org_unit);
        }
        if (row.overtime_eligible_p) {
          nodes.overtime_eligible_p.push(row.overtime_eligible_p);
        }
        if (row.planned_increase_1) {
          nodes.planned_increase_1.push(
            formatCurrencyIntl(row.planned_increase_1, intl, window.app)
          );
        }
        if (row.planned_increase_1_date) {
          nodes.planned_increase_1_date.push(row.planned_increase_1_date);
        }
        if (row.pmp_rating) {
          nodes.pmp_rating.push(row.pmp_rating);
        }
        if (row.std_work_hours) {
          nodes.std_work_hours.push(row.std_work_hours);
        }
        if (row.tcc) {
          nodes.tcc.push(formatCurrencyIntl(row.tcc, intl, window.app));
        }
        if (row.work_location) {
          nodes.work_location.push(row.work_location);
        }
        if (row.salary_effective_date) {
          nodes.salary_effective_date.push(row.salary_effective_date);
        }
      }
    }
  }

  return nodes;
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case EMPLOYEE_COMPARE:
      return {
        ...state,
        loading: true,
        query: action.query,
        items: action.result
      };
    case EMPLOYEE_COMPARE_SUCCESS:
      const nodes = extractNodes(action.result.formData, action.options.currency);
      return {
        ...state,
        loading: false,
        loaded: true,
        error: null,
        items: action.result,
        itemsComparison: nodes
      };
    case EMPLOYEE_COMPARE_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        items: {},
        error: action.error
      };
    default:
      return { ...state };
  }
}

export function doEmployeeComparison(options) {
  const params = {
    params: { org_employee_id: options.org_employee_id, currency: options.currency }
  };
  return {
    types: [EMPLOYEE_COMPARE, EMPLOYEE_COMPARE_SUCCESS, EMPLOYEE_COMPARE_FAIL],
    promise: client => client.get('/api/hris/employee-details/employeeCompare', params),
    options
  };
}

export function loadEmployeeCharts(options) {
  return {
    types: ['EMPLOYEE_CHARTS_LOAD', 'EMPLOYEE_CHARTS_SUCCESS', 'EMPLOYEE_CHARTS_FAIL'],
    promise: client =>
      client.get('/api/hris/employee-details/payRangeAndGradeMultiple', {
        params: { org_job_ids: options.org_job_ids, currency: options.currency }
      })
  };
}
