import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './SearchBarMP.scss';

class SearchBarMP extends Component {
  static propTypes = {
    onChange: PropTypes.func,
    onSearch: PropTypes.func,
    value: PropTypes.string,
    className: PropTypes.string,
    placeholder: PropTypes.string,
    testId: PropTypes.string,
    autoComplete: PropTypes.bool,
    searchName: PropTypes.string,
    isDisabled: PropTypes.bool,
    defaultValue: PropTypes.string
  };

  static defaultProps = {
    autoComplete: false,
    isDisabled: false
  };

  constructor(props) {
    super(props);
    this.onKeyDown = this.onKeyDown.bind(this);
  }

  onKeyDown(event) {
    if (this.props.onSearch && event.key === 'Enter') {
      this.props.onSearch(event);
    }
  }

  render() {
    let {
      className,
      onSearch,
      testId,
      placeholder,
      onChange,
      value,
      autoComplete,
      searchName,
      isDisabled,
      defaultValue
    } = this.props;

    let onKeyDown, iconStyle, containerStyle;
    if (isDisabled) {
      onSearch = null;
      onChange = null;
      onKeyDown = null;
      iconStyle = 'icon-search search__bar__icon--disabled';
      containerStyle = `search__bar search__bar--disabled ${className}`;
    } else {
      onKeyDown = this.onKeyDown;
      iconStyle = 'icon-search pointer';
      containerStyle = `search__bar ${className}`;
    }
    return (
      <div className={containerStyle}>
        <span onClick={onSearch}>
          <i className={iconStyle} />
        </span>
        <input
          name={searchName}
          type="textbox"
          autoComplete={autoComplete ? 'on' : 'off'}
          data-test-id={testId}
          placeholder={placeholder}
          className="search__bar-input"
          onChange={onChange}
          onKeyDown={onKeyDown}
          value={value}
          disabled={isDisabled}
          defaultValue={defaultValue}
        />
      </div>
    );
  }
}

export default SearchBarMP;
