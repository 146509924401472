import React, { Component } from 'react';
import Slideout from '../Modal/Slideout';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { slideoutActions } from './SlideoutReducer';

export class SlideoutRoot extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.props.slideout.conditionalSlideout = true;
  }

  componentDidCatch(error, errorInfo) {
    const { history } = this.props;

    history.push(history.location.pathname);
  }

  render() {
    if (!this.props.slideout.slideoutType) {
      return <Slideout {...this.props} />;
    }

    const hideSlideout = this.props.slideout.slideoutProps.hideSlideout
      ? this.props.slideout.slideoutProps.hideSlideout
      : this.props.hideSlideout;
    const SlideoutComponent = this.props.slideout.slideoutType.component;

    return (
      <Slideout
        {...this.props}
        hideSlideout={hideSlideout}
        className={this.props.slideout.slideoutType.containerClassName}
        open={this.props.slideout.open}
        noOverlay={this.props.slideout.slideoutType.noOverlay}
        disableEscape={this.props.slideout.slideoutType.disableEscape}
      >
        <SlideoutComponent
          {...this.props}
          {...this.props.slideout.slideoutType.defaultProps}
          {...this.props.slideout.slideoutProps}
        />
      </Slideout>
    );
  }
}

SlideoutRoot.propTypes = {
  slideout: PropTypes.object,
  showSlideout: PropTypes.func,
  hideSlideout: PropTypes.func,
  className: PropTypes.string
};

export default connect(
  state => ({
    slideout: state.slideout
  }),
  slideoutActions
)(SlideoutRoot);
