import uuid from 'uuid';
import apiClient from '../../../lib/apiClient';
import * as jobActions from './jobActions';
import { actions as jobSurveyListActions } from './jobSurveyList';
import { getJobSkills } from './skillsByJobList';
import { populateJobSkills } from './skillsByJobList';
import { getJobCerts } from './jobCertifications';
import { populateJobCerts } from './jobCertifications';
import { getJobFamilies } from './jobFamilies';
import { suggestJobFamilies } from './suggestFamily';
import { getJobStructureList } from './addJobStructureList';
import { getCareerLevels } from './careerLevels';
import { suggestCareerLevel } from './suggestCareer';
import { getUpdatedMarketSalary, getUpdatedMarketData } from './addJobUpdatedDetails';
import { getJobSurveyCompanyMatchDataCuts } from './jobDetails';
import { throttleActionSync } from '../../../lib/reduxThrottle';
import { JOB_DETAILS_SINGLE_START_LOAD } from './jobDetails';
import { IsJobPriced } from '../JobHelpers';

const ADD_JOB_LAUNCH = 'ADD_JOB_LAUNCH';
const EDIT_JOB_LAUNCH = 'EDIT_JOB_LAUNCH';
const EDIT_JOB_TITLE = 'EDIT_JOB_TITLE';
const CANCEL_EDIT_JOB_TITLE = 'CANCEL_EDIT_JOB_TITLE';
const HIDE_JOB_PANEL = 'HIDE_JOB_PANEL';
const SEARCH = 'SEARCH';
const SEARCH_SUCCESS = 'SEARCH_SUCCESS';
const SEARCH_FAIL = 'SEARCH_FAIL';
const SELECT_JOB = 'SELECT_JOB';
const JOB_SAVE_SUCCESS = 'JOB_SAVE_SUCCESS';
const JOB_SAVE_FAIL = 'JOB_SAVE_FAIL';
const JOB_SAVE = 'JOB_SAVE';
const FAMILY_SAVE_SUCCESS = 'FAMILY_SAVE_SUCCESS';
const FAMILY_SAVE_FAIL = 'FAMILY_SAVE_FAIL';
const FAMILY_SAVE = 'FAMILY_SAVE';
const CAREER_SAVE_SUCCESS = 'CAREER_SAVE_SUCCESS';
const CAREER_SAVE_FAIL = 'CAREER_SAVE_FAIL';
const CAREER_SAVE = 'CAREER_SAVE';
const JOB_SAVE_CHECK_SUCCESS = 'JOB_SAVE_CHECK_SUCCESS';
const JOB_SAVE_CHECK_FAIL = 'JOB_SAVE_CHECK_FAIL';
const JOB_SAVE_CHECK = 'JOB_SAVE_CHECK';
const QUICK_JOB_DETAILS_SAVE = 'QUICK_JOB_DETAILS_SAVE';
const QUICK_JOB_DETAILS_SAVE_SUCCESS = 'QUICK_JOB_DETAILS_SAVE_SUCCESS';
const QUICK_JOB_DETAILS_SAVE_FAIL = 'QUICK_JOB_DETAILS_SAVE_FAIL';
const CREATE_RETRIEVE_REPORT = 'CREATE_RETRIEVE_REPORT';
const CREATE_RETRIEVE_REPORT_SUCCESS = 'CREATE_RETRIEVE_REPORT_SUCCESS';
const RETRIEVE_REPORTS_STARTED = 'RETRIEVE_REPORTS_STARTED';
const RETRIEVE_REPORTS_FINISHED = 'RETRIEVE_REPORTS_FINISHED';
const CREATE_RETRIEVE_REPORT_FAIL = 'CREATE_RETRIEVE_REPORT_FAIL';
const GET_JOB_QUESTION_ANSWER = 'GET_JOB_QUESTION_ANSWER';
const GET_JOB_QUESTION_ANSWER_SUCCESS = 'GET_JOB_QUESTION_ANSWER_SUCCESS';
const GET_JOB_QUESTION_ANSWER_FAIL = 'GET_JOB_QUESTION_ANSWER_FAIL';
const JOB_LOCATION = 'JOB_LOCATION';
const JOB_LOCATION_SUCCESS = 'JOB_LOCATION_SUCCESS';
const JOB_LOCATION_FAIL = 'JOB_LOCATION_FAIL';
const CREATE_RETRIEVE_PAY_REPORT = 'CREATE_RETRIEVE_PAY_REPORT';
const CREATE_RETRIEVE_PAY_REPORT_SUCCESS = 'CREATE_RETRIEVE_PAY_REPORT_SUCCESS';
const CREATE_RETRIEVE_PAY_REPORT_FAIL = 'CREATE_RETRIEVE_PAY_REPORT_FAIL';
const QUESTION_OPTIONS = 'QUESTION_OPTIONS';
const QUESTION_OPTIONS_SUCCESS = 'QUESTION_OPTIONS_SUCCESS';
const QUESTION_OPTIONS_FAIL = 'QUESTION_OPTIONS_FAIL';
const UPLOAD = 'UPLOAD';
const UPLOAD_SUCCESS = 'UPLOAD_SUCCESS';
const UPLOAD_FAIL = 'UPLOAD_FAIL';
const UPLOAD_CANCEL = 'UPLOAD_CANCEL';
const UPLOAD_PROGRESS = 'UPLOAD_PROGRESS';
const REMOVE_JD = 'REMOVE_JD';
const REMOVE_JD_SUCCESS = 'REMOVE_JD_SUCCESS';
const REMOVE_JD_FAIL = 'REMOVE_JD_FAIL';
const ON_DRAG_ENTER = 'ON_DRAG_ENTER';
const ON_DRAG_LEAVE = 'ON_DRAG_LEAVE';
const ON_DRAG_END = 'ON_DRAG_END';
const PAY_STRUCTURE_CHANGE = 'PAY_STRUCTURE_CHANGE';
const GRADE_SAVE = 'GRADE_SAVE';
const GRADE_SAVE_SUCCESS = 'GRADE_SAVE_SUCCESS';
const GRADE_SAVE_FAIL = 'GRADE_SAVE_FAIL';
const ON_DROP_REJECTED = 'ON_DROP_REJECTED';
const DELAY_UPLOAD = 'DELAY_UPLOAD';
const GET_RECOMMEND_GRADE = 'GET_RECOMMEND_GRADE';
const GET_RECOMMEND_GRADE_SUCCESS = 'GET_RECOMMEND_GRADE_SUCCESS';
const GET_RECOMMEND_GRADE_FAIL = 'GET_RECOMMEND_GRADE_FAIL';
const SET_INITIAL_JOB_DETAILS = 'SET_INITIAL_JOB_DETAILS';
const UPDATE_ADD_JOB_DETAILS = 'UPDATE_ADD_JOB_DETAILS';
const GET_QUESTION_MAP_INIT = 'GET_QUESTION_MAP_INIT';
const GET_QUESTION_MAP_SUCCESS = 'GET_QUESTION_MAP_SUCCESS';
const GET_QUESTION_MAP_FAIL = 'GET_QUESTION_MAP_FAIL';
const CLEAR_ADD_JOB_STATE = 'CLEAR_ADD_JOB_STATE';
const GET_MARKET_SALARY_SUCCESS = 'GET_MARKET_SALARY_SUCCESS';
const SHOW_SCROLL_BAR = 'SHOW_SCROLL_BAR';
const HIDE_SCROLL_BAR = 'HIDE_SCROLL_BAR';
const UPDATE_MATCHES_SUCCESS = 'UPDATE_MATCHES_SUCCESS';
const UPDATE_MATCHES = 'UPDATE_MATCHES';
const UPDATE_MATCHES_FAIL = 'UPDATE_MATCHES_FAIL';
const JOB_EXISTS = 'JOB_EXISTS';
const JOB_EXISTS_NO_CODE = 'JOB_EXISTS_NO_CODE';
const DELETE_DETAILS = 'DELETE_DETAILS';
const DELETE_DETAILS_FAIL = 'DELETE_DETAILS_FAIL';
const DELETE_DETAILS_SUCCESS = 'DELETE_DETAILS_SUCCESS';
const JOBS_DISABLE_DETAILS_LOAD = 'JOBS_DISABLE_DETAILS_LOAD';
const JOBS_ENABLE_DETAILS_LOAD = 'JOBS_ENABLE_DETAILS_LOAD';
const SET_JOB_MATCH_SAVING = 'SET_JOB_MATCH_SAVING';
const SET_JOB_MATCH_ERROR = 'SET_JOB_MATCH_ERROR';
const DETAILS_FULLY_LOADED = 'DETAILS_FULLY_LOADED';

const initialState = Object.freeze({
  jobTitles: [],
  enableJodDropZone: false,
  selectedJobId: '',
  selectedJobTitle: '',
  panelVisible: false,
  editMode: false,
  jobWasEdited: false,
  fromJobDetailsPanel: false,
  searchInfoDisabled: false,
  jobCodeValid: '',
  formSections: {
    family: {
      display: false
    },
    experience: {
      display: false
    },
    education: {
      display: false
    },
    responsibilities: {
      display: false
    },
    skillsByJob: {
      display: false
    },
    jobTitleMatches: {
      display: false
    },
    jobDetails: {
      display: true
    },
    jobSearch: {
      display: false
    },
    creationBtns: {
      display: false
    },
    jobGrade: {
      display: false
    },
    payscaleTitleMatch: {
      display: false
    }
  },
  jobDetails: {
    PayscaleJobTitle: '',
    YearsExperience: undefined,
    YearsMinimum: undefined,
    HighestDegreeEarned: '',
    MajorSpecialty: '',
    Skills: [],
    Certifications: [],
    Tasks: [],
    TitleVariations: [],
    JobResponsibilities: [],
    Reports: []
  },
  questionOptions: [],
  HighestDegreeEarnedList: [],
  managementTasks: [],
  orgJobId: null,
  reports: {},
  workLocation: '',
  workLocationExact: '',
  surveyPriceLocation: null,
  workLocationList: [],
  jobCode: '',
  noJobCode: false,
  jobIdLoading: false,
  orgJobTitle: '',
  editJobTitleMode: false,
  jobDescription: null,
  jobDescriptionSuccess: false,
  jobFamily: '',
  careerLevel: '',
  payStructure: '',
  jobGrade: '',
  recommendGrade: {},
  showDropZoneUploading: false,
  showModal: false,
  showDropZone: false,
  dzFiles: null,
  delayFile: null,
  lastRequest: null,
  // This is used for restoring the previous state of a job description when a user cancels a second file upload.
  restoreFile: {},
  uploadProgress: 0,

  loading: false,
  loaded: false,
  searchLoading: false,
  searchLoaded: false,
  jobSavingStatus: 'JOB_NOT_SAVING',
  initialBaseSalary: 0,
  deleting: false,
  dataScienceJobId: null,

  payReportLoading: false,
  jobMatchSaving: false,
  jobMatchError: false,
  detailsFullyLoaded: false
});

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case DELETE_DETAILS:
      return {
        ...state,
        loading: true,
        deleting: true
      };
    case DELETE_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        selectedJobTitle: '',
        selectedJobId: '',
        deleting: false
      };
    case DELETE_DETAILS_FAIL:
      return {
        ...state,
        loading: false
      };
    case ADD_JOB_LAUNCH:
      // const does not make the initialState immutable, and object.assign and object.freeze are not deep
      const defaultInitialDetails = {
        PayscaleJobTitle: '',
        YearsExperience: null,
        YearsMinimum: null,
        HighestDegreeEarned: '',
        MajorSpecialty: '',
        Skills: [],
        Certifications: [],
        Tasks: [],
        TitleVariations: [],
        JobResponsibilities: [],
        Reports: []
      };

      return {
        ...initialState,
        enableJodDropZone: true,
        jobDetails: defaultInitialDetails,
        reports: {},
        panelVisible: true,
        fromJobDetailsPanel: action.fromJobDetailsPanel ? action.fromJobDetailsPanel : false,
        detailsFullyLoaded: true
      };

    case UPDATE_ADD_JOB_DETAILS:
      const updatedJobDetails = action.jobDetails.payscale_job_info;

      if (updatedJobDetails && updatedJobDetails.workLocation && !updatedJobDetails.workLocationExact) {
        // work location doesn't include country
        // let's first fall back on the previous value if it exists
        // work location exact format: city, state; country
        if (
          state.jobDetails &&
          state.jobDetails.workLocationExact &&
          state.jobDetails.workLocationExact.indexOf(';') !== -1
        ) {
          updatedJobDetails.workLocationExact = state.jobDetails.workLocationExact;
        } else {
          updatedJobDetails.workLocationExact = updatedJobDetails.workLocation;
        }
      }

      if (updatedJobDetails && !updatedJobDetails.Certifications) {
        updatedJobDetails.Certifications = [];
      }

      if (updatedJobDetails && !updatedJobDetails.Skills) {
        updatedJobDetails.Skills = [];
      }

      return {
        ...state,
        jobDetails: updatedJobDetails,
        reports: updatedJobDetails ? updatedJobDetails.Reports || {} : {},
        jobSavingStatus: 'Your job details have been autosaved'
      };

    case EDIT_JOB_LAUNCH:
      const editJobDetails = action.jobDetails.payscale_job_info;
      if (editJobDetails && editJobDetails.workLocation && !editJobDetails.workLocationExact) {
        editJobDetails.workLocationExact = editJobDetails.workLocation;
      }
      if (editJobDetails && !editJobDetails.Certifications) {
        editJobDetails.Certifications = [];
      }

      if (editJobDetails && !editJobDetails.Skills) {
        editJobDetails.Skills = [];
      }

      // const does not make the initialState immutable, and object.assign and object.freeze are not deep
      const defaultDetails = {
        PayscaleJobTitle: '',
        YearsExperience: null,
        YearsMinimum: null,
        HighestDegreeEarned: '',
        MajorSpecialty: '',
        Skills: [],
        Certifications: [],
        Tasks: [],
        TitleVariations: [],
        JobResponsibilities: []
      };

      const isJobPriced = IsJobPriced(editJobDetails?.workLocation, editJobDetails?.PayscaleJobTitle);

      return {
        ...initialState,
        enableJodDropZone: true,
        jobDescription: action.jobDetails.job_description,
        jobFamily: action.jobDetails.org_job_unit,
        careerLevel: action.jobDetails.org_job_level,
        payStructure: action.jobDetails.org_salary_structure,
        jobGrade: action.jobDetails.org_salary_grade,
        orgJobId: action.jobDetails.org_job_id,
        orgJobTitle: action.jobDetails.org_job_title,
        jobCode: action.jobDetails.org_job_code,
        workLocation: editJobDetails?.workLocation,
        workLocationExact: editJobDetails?.workLocationExact,
        surveyPriceLocation: editJobDetails?.surveyPriceLocation,
        jobDetails: editJobDetails || defaultDetails,
        reports: editJobDetails?.Reports || {},
        recommendGrade: editJobDetails?.recommendGrade || {},
        panelVisible: true,
        fromJobDetailsPanel: true,
        editMode: true,
        initialBaseSalary: action.jobDetails.market_base_salary_50th,
        formSections: {
          ...initialState.formSections,
          jobDetails: {
            // If the job is not priced, display the add job form
            // Otherwise, display the edit job form
            display: !isJobPriced
          }
        },
        // When job is not priced, add form will display, so don't show loading indicator
        detailsFullyLoaded: !isJobPriced
      };
    case EDIT_JOB_TITLE:
      return {
        ...state,
        enableJodDropZone: true,
        searchInfoDisabled: true,
        editJobTitleMode: true,
        formSections: {
          family: {
            display: true
          },
          experience: {
            display: true
          },
          education: {
            display: true
          },
          responsibilities: {
            display: true
          },
          skillsByJob: {
            display: true
          },
          jobTitleMatches: {
            display: true
          },
          jobDetails: {
            display: true
          },
          jobSearch: {
            display: true
          },
          creationBtns: {
            display: true
          },
          jobGrade: {
            display: window.app.basicProductName !== 'MarketPay'
          },
          payscaleTitleMatch: {
            display: false
          }
        }
      };
    case CANCEL_EDIT_JOB_TITLE:
      return {
        ...state,
        enableJodDropZone: true,
        searchInfoDisabled: false,
        editJobTitleMode: false,
        formSections: {
          family: {
            display: true
          },
          experience: {
            display: true
          },
          education: {
            display: true
          },
          responsibilities: {
            display: true
          },
          skillsByJob: {
            display: true
          },
          jobTitleMatches: {
            display: false
          },
          jobDetails: {
            display: false
          },
          jobSearch: {
            display: false
          },
          creationBtns: {
            display: true
          },
          jobGrade: {
            display: window.app.basicProductName !== 'MarketPay'
          },
          payscaleTitleMatch: {
            display: true
          }
        }
      };
    case HIDE_JOB_PANEL:
      return {
        ...state,
        panelVisible: false
      };
    case SEARCH:
      return {
        ...state,
        loading: true
      };
    case SEARCH_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: null,
        formSections: {
          family: {
            display: true
          },
          experience: {
            display: true
          },
          education: {
            display: true
          },
          responsibilities: {
            display: true
          },
          skillsByJob: {
            display: true
          },
          jobTitleMatches: {
            display: true
          },
          jobDetails: {
            display: true
          },
          jobSearch: {
            display: true
          },
          creationBtns: {
            display: true
          },
          jobGrade: {
            display: window.app.basicProductName !== 'MarketPay'
          },
          payscaleTitleMatch: {
            display: false
          }
        },
        jobTitles: action.result && action.result.JobTitleSuggestions ? action.result.JobTitleSuggestions : [],
        selectedJobId: '',
        orgJobTitle: !action.additionalSearch ? action.jobTitleSearchValue : state.orgJobTitle,
        workLocation: action.workLocation,
        workLocationExact: action.workLocationExact,
        jobCode: action.jobCode,
        noJobCode: action.noJobCode,
        dataScienceJobId: action.result.dataScienceJobId ? action.result.dataScienceJobId : null
      };
    case SEARCH_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        data: null,
        error: action.error
      };
    case FAMILY_SAVE:
      return { ...state, showFooter: true, jobSavingStatus: 'Saving changes...' };
    case FAMILY_SAVE_SUCCESS:
      return { ...state, jobFamily: action.result.org_unit, jobSavingStatus: 'Your job details have been autosaved' };
    case FAMILY_SAVE_FAIL:
      return { ...state, error: action.error, jobSavingStatus: 'Failed to save the job' };
    case CAREER_SAVE:
      return { ...state };
    case CAREER_SAVE_SUCCESS:
      return { ...state, careerLevel: action.result.org_job_level };
    case CAREER_SAVE_FAIL:
      return { ...state, error: action.error };
    case PAY_STRUCTURE_CHANGE:
      return { ...state, payStructure: action.payStructure, jobGrade: '' };
    case GET_RECOMMEND_GRADE:
      return { ...state };
    case GET_RECOMMEND_GRADE_SUCCESS:
      return { ...state, recommendGrade: action.result };
    case GET_RECOMMEND_GRADE_FAIL:
      return { ...state, error: action.error };
    case GRADE_SAVE:
      return { ...state, jobSavingStatus: 'Saving changes...' };
    case GRADE_SAVE_SUCCESS:
      return {
        ...state,
        jobGrade: action.result.org_salary_grade,
        jobSavingStatus: 'Your job details have been autosaved'
      };
    case GRADE_SAVE_FAIL:
      return { ...state, error: action.error, jobSavingStatus: 'Failed to save the job' };
    case JOB_SAVE:
      return {
        ...state,
        jobSavingStatus: 'Saving changes...'
      };
    case JOB_SAVE_SUCCESS:
      let orgJobId = state.orgJobId;
      let jobDetails = state.jobDetails;
      let workLocation = state.workLocation;
      let workLocationExact = state.workLocationExact;
      let surveyPriceLocation = state.surveyPriceLocation;
      if (action.result && action.result.org_job_id) {
        orgJobId = action.result.org_job_id;
      }
      if (action.result && action.result.payscale_job_info) {
        jobDetails = action.result.payscale_job_info;
        workLocation = jobDetails.workLocation;
        workLocationExact = jobDetails.workLocationExact;
        surveyPriceLocation = jobDetails.surveyPriceLocation;
      }

      let jobDescription = state.jobDescription;
      if (!jobDescription) {
        jobDescription = action.result.job_description;
      }

      return {
        ...state,
        enableJodDropZone: false,
        jobIdLoading: false,
        loading: false,
        loaded: false,
        data: null,
        jobWasEdited: true,
        orgJobId: orgJobId,
        jobDetails: jobDetails,
        workLocation: workLocation,
        workLocationExact: workLocationExact,
        surveyPriceLocation: surveyPriceLocation,
        error: null,
        jobDescription: jobDescription,
        jobSavingStatus: 'Your job details have been autosaved',
        editJobTitleMode: false,
        jobCode: action.result && action.result.org_job_code ? action.result.org_job_code : state.jobCode,
        dataScienceJobId: null,
        orgJobTitle: jobDetails?.internalJobTitle
      };
    case JOB_SAVE_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        data: null,
        error: action.error,
        jobSavingStatus: action.status || 'Failed to save the job',
        dataScienceJobId: null
      };
    case JOB_SAVE_CHECK:
      return {
        ...state
      };
    case JOB_EXISTS:
      return {
        ...state,
        jobCodeValid: 'error'
      };
    case JOB_EXISTS_NO_CODE:
      return {
        ...state,
        jobCodeValid: 'error-no-code'
      };
    case JOB_SAVE_CHECK_SUCCESS:
      let searchInfoDisabled = false;
      let jobCodeValid = 'error';
      if (action.result.success) {
        searchInfoDisabled = true;
        jobCodeValid = '';
      }
      return {
        ...state,
        loading: false,
        loaded: false,
        jobCodeValid: jobCodeValid,
        searchInfoDisabled: searchInfoDisabled,
        workLocation: action.result.workLocation,
        workLocationExact: action.result.workLocationExact,
        data: null,
        error: null
      };
    case JOB_SAVE_CHECK_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        data: null,
        error: action.error
      };
    case SELECT_JOB:
      return {
        ...state,
        jobIdLoading: state.editMode ? false : true,
        formSections: {
          family: {
            display: true
          },
          experience: {
            display: true
          },
          education: {
            display: true
          },
          responsibilities: {
            display: true
          },
          skillsByJob: {
            display: true
          },
          jobTitleMatches: {
            display: false
          },
          jobDetails: {
            display: false
          },
          jobSearch: {
            display: false
          },
          creationBtns: {
            display: true
          },
          jobGrade: {
            display: window.app.basicProductName !== 'MarketPay'
          },
          payscaleTitleMatch: {
            display: true
          }
        },
        selectedJobId: action.selectedJobId,
        selectedJobTitle: action.selectedJobTitle
      };
    case CREATE_RETRIEVE_REPORT:
      return {
        ...state,
        loading: true,
        payReportLoading: true
      };
    case CREATE_RETRIEVE_REPORT_SUCCESS:
      let currReport = state.reports; // eslint-disable-line prefer-const
      let currJobDetails = state.jobDetails; // eslint-disable-line prefer-const
      let recommendGrade = state.recommendGrade; // eslint-disable-line prefer-const
      if (action.result && action.result.payReport && action.result.payReport.success === false) {
        return { ...state };
      }
      if (!action.result || (action.result && action.result.success === false)) {
        return { ...state };
      }
      //there's a race condition here where action.result ends up null in this if statement
      if (action.result && action.updateReportCountSent >= updateReportCount && action.updatePayReport) {
        if (action.result.recommendGrade) {
          recommendGrade = action.result.recommendGrade;
        }
        if (action.result.payReport) {
          currReport.payReport = action.result.payReport;
        }
        // We actually want to update any report that comes back
        if (action.result.trendsReport) {
          currReport.trendsReport = action.result.trendsReport;
        }
        if (action.result.validation) {
          currReport.validation = action.result.validation;
        }
        if (action.result.yearsExperienceReport) {
          currReport.yearsExperienceReport = action.result.yearsExperienceReport;
        }
        if (action.result.marketMediansReport) {
          currReport.marketMediansReport = action.result.marketMediansReport;
        }
        if (action.result.quickMarketMedianReport) {
          currReport.quickMarketMedianReport = action.result.quickMarketMedianReport;
        }
        if (currJobDetails) {
          currJobDetails.Reports = currReport;
        }
      } else if (action.updateReportCountSent >= updateReportCount && action.result) {
        currReport = action.result;
        if (currReport.recommendGrade) {
          recommendGrade = currReport.recommendGrade;
        }
        if (currJobDetails) {
          currJobDetails.Reports = currReport;
        }
      } else if (action.result && !action.updatePayReport) {
        // If we get here when initially obtaining reports
        // the reports object has not yet been defined
        if (!currReport) {
          currReport = {};
        }
        // Update all report but the pay report
        if (action.result.trendsReport) {
          currReport.trendsReport = action.result.trendsReport;
        }
        if (action.result.validation) {
          currReport.validation = action.result.validation;
        }
        if (action.result.yearsExperienceReport) {
          currReport.yearsExperienceReport = action.result.yearsExperienceReport;
        }
        if (action.result.marketMediansReport) {
          currReport.marketMediansReport = action.result.marketMediansReport;
        }
        if (action.result.quickMarketMedianReport) {
          currReport.quickMarketMedianReport = action.result.quickMarketMedianReport;
        }
        if (currReport.recommendGrade) {
          recommendGrade = currReport.recommendGrade;
        }
        if (currJobDetails) {
          currJobDetails.Reports = currReport;
        }
      }
      return {
        ...state,
        loading: false,
        payReportLoading: false,
        loaded: true,
        recommendGrade: recommendGrade,
        reports: currReport,
        jobDetails: currJobDetails,
        error: null
      };
    case CREATE_RETRIEVE_REPORT_FAIL:
      return {
        ...state,
        loading: false,
        payReportLoading: false,
        loaded: false,
        data: null,
        error: action.error
      };
    case RETRIEVE_REPORTS_STARTED:
      return {
        ...state,
        searchLoading: true,
        searchLoaded: false,
        jobTitles: []
      };
    case RETRIEVE_REPORTS_FINISHED:
      return {
        ...state,
        searchLoading: false,
        searchLoaded: true,
        jobSavingStatus: 'JOB_NOT_SAVING'
      };
    case CREATE_RETRIEVE_PAY_REPORT:
      return {
        ...state,
        jobTitles: state.jobTitles.map(jobData => {
          if (jobData.JobTitle === action.jobTitle) {
            return {
              ...jobData,
              loading: true,
              loaded: false,
              error: null
            };
          }
          return jobData;
        })
      };
    case CREATE_RETRIEVE_PAY_REPORT_SUCCESS:
      return {
        ...state,
        jobTitles: state.jobTitles.map(jobData => {
          if (jobData.JobTitle === action.result.jobTitle) {
            return {
              ...jobData,
              initialPayReports: action.result,
              loading: false,
              loaded: true,
              error: null
            };
          }
          return jobData;
        })
      };
    case CREATE_RETRIEVE_PAY_REPORT_FAIL:
      if (action.result && action.result.jobTitle) {
        return {
          ...state,
          jobTitles: state.jobTitles.map(jobData => {
            if (jobData.JobTitle === action.result.jobTitle) {
              return {
                ...jobData,
                loading: false,
                loaded: true,
                error: action.error
              };
            }
            return jobData;
          })
        };
      } else {
        return {
          ...state,
          loading: false,
          loaded: false,
          error: action.error
        };
      }
    case GET_JOB_QUESTION_ANSWER:
      return {
        ...state,
        loading: true
      };
    case GET_QUESTION_MAP_SUCCESS:
      return {
        ...state,
        reports: {
          ...state.reports,
          validation: {
            ...state.reports.validation,
            suggestions: state.reports.validation.suggestions.map(suggestion => {
              if (action.result && action.result.QuestionMap[suggestion.questionId]) {
                const dataType = action.result.QuestionMap[suggestion.questionId].DataType;
                return {
                  ...suggestion,
                  dataType
                };
              } else {
                return suggestion;
              }
            })
          }
        }
      };
    case GET_JOB_QUESTION_ANSWER_SUCCESS:
      let currReports = state.reports; // eslint-disable-line prefer-const
      let suggestionsList = []; // eslint-disable-line prefer-const
      if (currReports && currReports.validation && currReports.validation.suggestions) {
        suggestionsList = currReports.validation.suggestions; // eslint-disable-line prefer-const
        for (let valIdx = 0; valIdx < suggestionsList.length; valIdx++) {
          let isNumber = false; // eslint-disable-line prefer-const
          if (suggestionsList[valIdx].questionId === action.questionId) {
            suggestionsList[valIdx].answers = action.result;
            if (action.result.length == 0) {
              suggestionsList[valIdx].type = popularAnswerType(suggestionsList[valIdx].questionId);
              suggestionsList[valIdx].max = popularAnswerMax(suggestionsList[valIdx].questionId);
            } else {
              suggestionsList[valIdx].type = popularAnswerMultiTrue(suggestionsList[valIdx].questionId);
            }
          }
        }
      }
      let currAnswerDetails = state.jobDetails; // eslint-disable-line prefer-const
      if (currAnswerDetails && currAnswerDetails.Reports) {
        currAnswerDetails.Reports = currReports;
      }
      return {
        ...state,
        loading: false,
        loaded: true,
        reports: currReports,
        jobDetails: currAnswerDetails,
        error: null
      };
    case GET_JOB_QUESTION_ANSWER_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        data: null,
        error: action.error
      };
    case QUESTION_OPTIONS:
      return {
        ...state,
        loading: true
      };
    case QUESTION_OPTIONS_SUCCESS:
      let managementTasksList = []; // eslint-disable-line prefer-const
      let HighestDegreeEarnedList = []; // eslint-disable-line prefer-const

      // jobalyzer must not be returning a result here
      if (!action.result) {
        return {
          ...state,
          error: 'No action.result in QUESTION_OPTIONS_SUCCESS'
        };
      }

      for (let mIdx = 0; mIdx < action.result.length; mIdx++) {
        const resultName = action.result[mIdx];
        if (resultName.PeopleManagementTasks && resultName.PeopleManagementTasks.length) {
          managementTasksList.push(resultName.PeopleManagementTasks);
        }
        if (resultName.HighestDegreeEarned && resultName.HighestDegreeEarned.length) {
          HighestDegreeEarnedList.push(resultName.HighestDegreeEarned);
        }
      }

      return {
        ...state,
        loading: false,
        loaded: true,
        questionOptions: action.result,
        HighestDegreeEarnedList: HighestDegreeEarnedList[0],
        managementTasks: managementTasksList,
        error: null
      };
    case QUESTION_OPTIONS_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        data: null,
        error: action.error
      };
    case JOB_LOCATION:
      return {
        ...state
      };
    case JOB_LOCATION_SUCCESS:
      action.searchCallback(action.result);
      return {
        ...state,
        loading: false,
        loaded: false,
        data: null,
        workLocationList: action.result,
        // searchCallback: searchCallback(null, {options: action.result}),
        error: null
      };
    case JOB_LOCATION_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        data: null,
        error: action.error
      };
    case UPLOAD:
      const description = { job_description: 'loading' };
      return {
        ...state,
        showDropZone: false,
        showDropZoneUploading: true,
        jobDescription: description,
        dzFile: action.dzFile[0].name,
        lastRequest: action.ajaxClient.lastRequest
      };
    case UPLOAD_SUCCESS:
      let jobDesc = { pretty_name: '' };
      if (action.result.job_description) {
        jobDesc = { pretty_name: action.result.job_description };
      } else if (action.result.pretty_name) {
        // adding a new job only returns pretty_name
        jobDesc = { pretty_name: action.result.pretty_name };
      }

      return {
        ...state,
        jobDescriptionSuccess: true,
        jobDescription: jobDesc,
        showDropZone: false,
        showDropZoneUploading: false,
        rejectReason: '',
        restoreFile: {
          jobDescriptionSuccess: true,
          jobDescription: jobDesc,
          showDropZone: false,
          showDropZoneUploading: false
        }
      };
    case UPLOAD_FAIL:
      return {
        ...state,
        jobDescriptionSuccess: false,
        jobDescription: null,
        showDropZone: false,
        showDropZoneUploading: false,
        rejectReason: ''
      };
    case UPLOAD_PROGRESS:
      return {
        ...state
      };
    case DELAY_UPLOAD:
      return {
        ...state,
        delayFile: action.delayFile
      };
    case UPLOAD_CANCEL:
      return {
        ...state,
        jobDescriptionSuccess: state.restoreFile.jobDescriptionSuccess,
        jobDescription: state.restoreFile.jobDescription,
        showDropZone: state.restoreFile.showDropZone,
        showDropZoneUploading: state.restoreFile.showDropZoneUploading,
        rejectReason: ''
      };
    case REMOVE_JD:
      return {
        ...state
      };
    case REMOVE_JD_SUCCESS:
      return {
        ...state,
        delayFile: null,
        dzFile: null,
        jobDescription: action.result.job_description,
        jobDescriptionSuccess: false,
        rejectReason: ''
      };
    case REMOVE_JD_FAIL:
      return {
        ...state,
        jobDescriptionSuccess: false,
        jobDescription: null,
        showDropZone: false,
        showDropZoneUploading: false,
        rejectReason: ''
      };
    case ON_DRAG_ENTER:
      return {
        ...state,
        showModal: true,
        showDropZone: true
      };
    case ON_DRAG_LEAVE:
      return {
        ...state,
        showModal: false,
        showDropZone: false
      };
    case ON_DRAG_END:
      return {
        ...state,
        showModal: false,
        showDropZone: false
      };
    case ON_DROP_REJECTED:
      return {
        ...state,
        showModal: false,
        showDropZone: false,
        dzFile: action.files,
        rejectReason: action.rejectReason
      };
    case QUICK_JOB_DETAILS_SAVE:
      return {
        ...state,
        jobSavingStatus: 'Saving changes...'
      };
    case QUICK_JOB_DETAILS_SAVE_SUCCESS:
      return {
        ...state,
        enableJodDropZone: false,
        jobIdLoading: false,
        loading: false,
        loaded: false,
        data: null,
        jobWasEdited: true,
        error: null,
        jobSavingStatus: 'Your job details have been autosaved',
        editJobTitleMode: false
      };
    case QUICK_JOB_DETAILS_SAVE_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        data: null,
        error: action.error,
        jobSavingStatus: action.status || 'Failed to save the job'
      };
    case SET_INITIAL_JOB_DETAILS:
      return {
        ...state,
        jobDetails: action.jobDetails ? action.jobDetails : state.jobDetails
      };
    case CLEAR_ADD_JOB_STATE:
      return initialState;
    case SET_JOB_MATCH_SAVING:
      return {
        ...state,
        jobMatchSaving: action.value
      };
    case SET_JOB_MATCH_ERROR:
      return {
        ...state,
        jobMatchError: action.value
      };
    case DETAILS_FULLY_LOADED:
      return {
        ...state,
        detailsFullyLoaded: true
      };
    default:
      return { ...state };
  }
}

let updateReportCount = 1; // eslint-disable-line prefer-const
let actions = {}; // eslint-disable-line prefer-const

// extend or override action creators here:
actions.loadDetailsPanel = jobActions.loadDetailsPanel;
actions.markDirtyJob = jobActions.markDirtyJob;

// Some fields they want numbers and others text to be able to pass to jobazlyer. No logic to figure it out, but guessing
export function popularAnswerType(questionId) {
  const numberFieldArray = [
    'NumberOfLawyers',
    'AdditionalIncome',
    'AnnualBonus',
    'AnnualSalesCommissions',
    'AnnualSalesVolume',
    'AnnualStoreRevenue',
    'CompanySales',
    'NumberOfLawyers',
    'NumberOfPartners',
    'OvertimeHours',
    'AverageSalePrice',
    'SigningAuthorityAmountAuthorized',
    'NumberSupervised',
    'AnnualHotelRevenue',
    'AvgRoomRate',
    'YearlyPay',
    'WeeklyTips',
    'SalaryHoursPerWeek',
    'AssetsManaged',
    'BudgetUnderManagement',
    'SalesQuotaPercentage',
    'TravelPercentage'
  ];
  if (numberFieldArray.includes(questionId)) {
    return 'number';
  }

  return 'text';
}

export function popularAnswerMax(questionId) {
  if (questionId == 'TravelPercentage') {
    return 100;
  }
  return null;
}

// Some fields are multi select pulldowns in responsibilities section and some are single selects.
// No documentation so guess to figure it out
export function popularAnswerMultiTrue(questionId) {
  const singleFieldArray = [
    'SigningAuthority',
    'StockExchangeListing',
    'SecurityClearanceType',
    'SecurityClearanceStatus'
  ];
  if (singleFieldArray.includes(questionId)) {
    return false;
  }
  return true;
}

export function closeEditJobSlideout() {
  return (dispatch, getState) => {
    dispatch({ type: HIDE_JOB_PANEL });
    dispatch({ type: SHOW_SCROLL_BAR });

    // If details will reload after slideout closes, go ahead and set loading state
    const jobId = getState().addJob.orgJobId;
    if (getState().addJob.fromJobDetailsPanel && jobId) {
      dispatch({ type: JOB_DETAILS_SINGLE_START_LOAD, id: jobId });
    }
  };
}

export function loadDetailsAfterSlideoutClose() {
  return (dispatch, getState) => {
    dispatch({ type: JOBS_ENABLE_DETAILS_LOAD });
    const jobId = getState().addJob.orgJobId;
    // reload the details panel
    if (getState().addJob.fromJobDetailsPanel && jobId) {
      // no longer passing dispatch, instead dispatch the action
      dispatch(jobActions.loadDetailsPanel(jobId));
      if (getState().addJob.editMode && getState().addJob.jobWasEdited) {
        // no longer passing dispatch, instead dispatch the action
        dispatch(jobActions.markDirtyJob(getState(), jobId));
      }
    } else if (jobId && window.app.basicProductName !== 'MarketPay') {
      window.location = './jobs/detail/' + jobId;
    }
  };
}

export function selectJob(jobID, jobTitle) {
  return async dispatch => {
    try {
      dispatch({ type: QUESTION_OPTIONS, selectedJobId: jobID, selectedJobTitle: jobTitle });

      const result = await apiClient.apiGet('api/payscale/jobalyzerReport/questionOptions');

      dispatch({ type: JOBS_DISABLE_DETAILS_LOAD });
      dispatch({ type: QUESTION_OPTIONS_SUCCESS, result: result.data });
      dispatch({
        type: SELECT_JOB,
        selectedJobId: jobID,
        selectedJobTitle: jobTitle
      });
    } catch (error) {
      dispatch({ type: QUESTION_OPTIONS_FAIL, error: error, selectedJobId: jobID, selectedJobTitle: jobTitle });
    }
  };
}

//This gets basic report data. Use updatePayReport to include other pay factors
export function getReportData(options) {
  const reportData = {
    reportData: {
      answers: {
        jobTitle: options.jobDetails.PayscaleJobTitle ? options.jobDetails.PayscaleJobTitle : options.jobTitle,
        location: options.workLocation,
        YearsExperience:
          options.jobDetails.YearsExperience || options.jobDetails.YearsExperience == ''
            ? options.jobDetails.YearsExperience
            : null,
        YearsMinimum: options.jobDetails.YearsMinimum,
        HighestDegreeEarned: options.jobDetails.HighestDegreeEarned,
        MajorSpecialty: options.jobDetails.MajorSpecialty,
        Skills: options.jobDetails.SkillNameList ? options.jobDetails.SkillNameList : options.jobDetails.Skills,
        Certifications: options.jobDetails.CertNameList
          ? options.jobDetails.CertNameList
          : options.jobDetails.Certifications
      }
    },
    orgJobId: options.orgJobId ? options.orgJobId : null
  };
  return reportData;
}

// This function gets all reports. It then loops through validation report to get the dynamic job questions
export function createAndRetrieveReports(options) {
  options.reportData = JSON.stringify(options.reportData);
  if (options.reportTypes) {
    options.reportTypes = JSON.stringify(options.reportTypes);
  }

  return async (dispatch, getState) => {
    updateReportCount++;
    dispatch(getJobFamilies());
    dispatch(getCareerLevels());

    try {
      dispatch({ type: CREATE_RETRIEVE_REPORT });
      const result = await apiClient.apiGet('/api/hris/jobJobalyzerReports/createRetrieveUpdateReports', {
        params: options
      });

      dispatch({
        type: CREATE_RETRIEVE_REPORT_SUCCESS,
        result: result.data,
        query: options,
        updateReportCountSent: updateReportCount
      });
      dispatch(getJobStructureList());

      if (result.data.payReport && result.data.payReport.reports.basePayReport) {
        dispatch(suggestCareerLevel({ basePay50th: result.data.payReport.reports.basePayReport.percentile50 }));
      }

      if (result.data && result.data.validation && result.data.validation.suggestions) {
        for (let resultValIdx = 0; resultValIdx < result.data.validation.suggestions.length; resultValIdx++) {
          const answerOptions = {
            jobTitle: result.data.jobTitle,
            questionId: result.data.validation.suggestions[resultValIdx].questionId
          };

          try {
            dispatch({ type: GET_JOB_QUESTION_ANSWER });
            const result = await apiClient.apiGet('api/payscale/jobalyzerReport/jobResponsesDriver', {
              params: answerOptions
            });

            dispatch({
              type: GET_JOB_QUESTION_ANSWER_SUCCESS,
              result: result.data,
              query: answerOptions,
              questionId: answerOptions.questionId
            });
          } catch (error) {
            dispatch({ type: GET_JOB_QUESTION_ANSWER_FAIL, error: error });
          }
        }

        dispatch(retrieveSuggestionDataTypes());
      }
    } catch (error) {
      dispatch({ type: CREATE_RETRIEVE_REPORT_FAIL, error: error });
    }
    if (!getState().jobsList.skipDetailsLoad) {
      dispatch(getUpdatedMarketSalary({ orgJobId: getState().addJob.orgJobId }));
    }
    dispatch(jobSurveyListActions('getSurveyJobs', getState().addJob.orgJobId));
  };
}

// Each questionId that comes back from their jobazlyer validation report.
// These need to be sent to the jobResponsesDriver that will get the values for the pulldowns
export function retrieveDynamicAnswers(options) {
  return async (dispatch, getState) => {
    let reports = getState().addJob?.reports;

    if (options?.reports) {
      reports = options.reports;
    }

    if (reports && reports.validation && reports.validation.suggestions) {
      for (let resultValIdx = 0; resultValIdx < reports.validation.suggestions.length; resultValIdx++) {
        const answerOptions = {
          jobTitle: getState().addJob.jobTitle,
          questionId: reports.validation.suggestions[resultValIdx].questionId
        };
        try {
          dispatch({ type: GET_JOB_QUESTION_ANSWER });
          const result = await apiClient.apiGet('api/payscale/jobalyzerReport/jobResponsesDriver', {
            params: answerOptions
          });

          dispatch({
            type: GET_JOB_QUESTION_ANSWER_SUCCESS,
            result: result.data,
            query: answerOptions,
            questionId: answerOptions.questionId
          });
        } catch (error) {
          dispatch({ type: GET_JOB_QUESTION_ANSWER_FAIL, error: error });
        }
      }
    }
  };
}

export function retrieveSuggestionDataTypes() {
  return async (dispatch, getState) => {
    if (
      getState().addJob.reports &&
      getState().addJob.reports.validation &&
      getState().addJob.reports.validation.suggestions
    ) {
      try {
        dispatch({ type: GET_QUESTION_MAP_INIT });
        const result = await apiClient.apiGet('api/payscale/jobalyzerReport/getQuestionMap');

        dispatch({ type: GET_QUESTION_MAP_SUCCESS, result: result.data });
      } catch (error) {
        dispatch({ type: GET_QUESTION_MAP_FAIL, error: error });
      }
    }
  };
}

// saves a job for the first time. Waits for a jobId then gets all the reports for that job
export function getAllReports(options) {
  const reportData = getReportData(options);
  return async (dispatch, getState) => {
    options.dataScienceJobId = getState().addJob?.dataScienceJobId;
    try {
      dispatch({ type: JOB_SAVE });
      const result = await apiClient.apiPost('/api/hris/jobs/saveJob', options);

      dispatch({ type: JOB_SAVE_SUCCESS, result: result.data, query: options });
      reportData.orgJobId = getState().addJob.orgJobId;
      reportData.existingJob = options.existingJob;
      if (reportData.existingJob) {
        reportData.newJobTitle = options.jobTitle;
      }
      // In the context of the add/edit job, if there are JD files to be uploaded then
      // dispatch those events
      if (getState().addJob.delayFile) {
        dispatch(onDropDelayed(getState().addJob.delayFile, getState().addJob.orgJobId));
      }

      // We want to assign only the new comp factors for the first report back
      if (result && result.data && result.data.payscale_job_info) {
        const jobDetailsResult = result.data.payscale_job_info;
        if (jobDetailsResult.YearsExperience) {
          reportData.reportData.answers.YearsExperience = jobDetailsResult.YearsExperience;
        }
        if (jobDetailsResult.YearsMinimum) {
          reportData.reportData.answers.YearsMinimum = jobDetailsResult.YearsMinimum;
        }
        if (jobDetailsResult.ManagementRole) {
          reportData.reportData.answers.ManagementRole = jobDetailsResult.ManagementRole;
        }
        if (jobDetailsResult.PracticeArea) {
          reportData.reportData.answers.PracticeArea = jobDetailsResult.PracticeArea;
        }
        if (jobDetailsResult.PeopleTypesManaged) {
          reportData.reportData.answers.PeopleTypesManaged = jobDetailsResult.PeopleTypesManaged;
        }
        if (jobDetailsResult.NumberSupervised) {
          reportData.reportData.answers.NumberSupervised = jobDetailsResult.NumberSupervised;
        }
        if (jobDetailsResult.HighestDegreeEarned) {
          reportData.reportData.answers.HighestDegreeEarned = jobDetailsResult.HighestDegreeEarned;
        }
      }
      const isMP = window.app.basicProductName === 'MarketPay';
      if (isMP) {
        reportData.reportTypes = ['payReport', 'yearsExperienceReport'];
      } else if (getState().jobsList.skipDetailsLoad) {
        reportData.reportTypes = ['payReport', 'yearsExperienceReport'];
      }

      await dispatch(createAndRetrieveReports(reportData));
      if (
        result &&
        result.data.autoMatch &&
        result.data.autoMatch.success &&
        result.data.autoMatch.surveyJobs &&
        result.data.autoMatch.surveyJobs.length
      ) {
        dispatch({
          type: UPDATE_MATCHES_SUCCESS,
          result: {
            success: result.data.autoMatch.success,
            surveyJobMatches: result.data.autoMatch.surveyJobs
          }
        });
        let surveyJobs = null;
        if (result && result.data && result.data.autoMatch && result.data.autoMatch.surveyJobs) {
          surveyJobs = result.data.autoMatch.surveyJobs;
        } else if (result && result.autoMatch && result.autoMatch.surveyJobs) {
          surveyJobs = result.autoMatch.surveyJobs;
        }
        const jobSurveyCompanyDispatch = getJobSurveyCompanyMatchDataCuts(getState, surveyJobs);
        if (jobSurveyCompanyDispatch) {
          dispatch(jobSurveyCompanyDispatch);
        }
      }
    } catch (error) {
      dispatch({ type: JOB_SAVE_FAIL, error: error });
    }
  };
}

export function createAndRetrievePayReports(options) {
  return async (dispatch, getState) => {
    options.reportData = JSON.stringify(options.reportData);
    if (options.reportTypes) {
      options.reportTypes = JSON.stringify(options.reportTypes);
    }

    try {
      dispatch({ type: CREATE_RETRIEVE_PAY_REPORT });
      const result = await apiClient.apiGet('/api/hris/jobJobalyzerReports/createRetrieveUpdateReports', {
        params: options
      });

      dispatch({
        type: CREATE_RETRIEVE_PAY_REPORT_SUCCESS,
        result: result.data,
        query: options
      });
    } catch (error) {
      dispatch({ type: CREATE_RETRIEVE_PAY_REPORT_FAIL, error: error });
    }
  };
}

// 1. Check if job exists. 2. get titles from titleSearch api.
// 3. Get a payReport based on each title to show graph beside titles
export function titleJobSearch(options) {
  return async (dispatch, getState) => {
    dispatch({ type: RETRIEVE_REPORTS_STARTED });

    if (options.checkJobExists) {
      try {
        dispatch({ type: JOB_SAVE_CHECK });
        const ajax = await apiClient.apiPost('/api/hris/jobs/checkJobExists', options.params);

        if (!ajax.data.success && !options.params.noJobCode) {
          dispatch({ type: JOB_EXISTS });
          return;
        } else if (!ajax.data.success && options.params.noJobCode) {
          dispatch({ type: JOB_EXISTS_NO_CODE });
          return;
        } else dispatch({ type: JOB_SAVE_CHECK_SUCCESS, result: ajax.data });
      } catch (error) {
        dispatch({ type: JOB_SAVE_CHECK_FAIL, error: error });
      }
    }

    try {
      dispatch({ type: SEARCH });
      const getTitlesAjax = await apiClient.apiGet('/api/payscale/titleMatch/titleSearch', { params: options.params });
      dispatch({
        type: SEARCH_SUCCESS,
        result: getTitlesAjax.data,
        query: options.params.title,
        workLocation: options.params.workLocation,
        workLocationExact: options.params.workLocation,
        jobTitleSearchValue: options.params.title,
        jobCode: options.params.jobCode,
        additionalSearch: options.params.additionalSearch,
        noJobCode: options.params.noJobCode
      });

      let jobTitles = [];
      if (getTitlesAjax) {
        const workLocation = getState().addJob.workLocationExact;
        jobTitles = getTitlesAjax.data.JobTitleSuggestions;
        // grab the first three title search result reports
        // Then grab the rest
        let initialSearchCount = 3;
        if (jobTitles.length < initialSearchCount) {
          initialSearchCount = jobTitles.length;
        }
        let initialRetrieveReports = new Array();
        for (let jobIdx = 0; jobIdx < initialSearchCount; jobIdx++) {
          const reportPromise = new Promise((resolve, reject) => {
            dispatch(createAndRetrievePayReports(getPayReportData(jobTitles[jobIdx].JobTitle, workLocation)))
              .then(() => {
                resolve();
              })
              .catch(err => {
                console.error('Error create retrieve pay reports', err);
              });
          });
          initialRetrieveReports.push(reportPromise);
        }

        dispatch({ type: RETRIEVE_REPORTS_FINISHED });

        Promise.all(initialRetrieveReports).then(() => {
          for (let jobIdx = initialSearchCount; jobIdx < jobTitles.length; jobIdx++) {
            dispatch(createAndRetrievePayReports(getPayReportData(jobTitles[jobIdx].JobTitle, workLocation)));
          }
        });
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: SEARCH_FAIL, error: error });
    }

    dispatch({ type: GET_MARKET_SALARY_SUCCESS, market_base_salary_50th: 0 });
  };
}

// This function requests ALL reports except validation to be updated by jobalyzer
// Validation in jobalyzer does not return the full list when reportData has answers that are part of validation
export function updatePayReport(options) {
  return async (dispatch, getState) => {
    const workLocation = getState().addJob.surveyPriceLocation
      ? getState().addJob.surveyPriceLocation
      : getState().addJob.workLocationExact;
    let yearsExperience = getState().addJob.jobDetails.YearsExperience;
    if (!yearsExperience) {
      yearsExperience = null;
    }

    let reportTypes = [
      'payReport',
      'marketMediansReport',
      'quickMarketMedianReport',
      'trendsReport',
      'yearsExperienceReport',
      'dataSummaryReport'
    ];

    if (options.reportTypes) {
      reportTypes = options.reportTypes;
    }
    // Jobalyzer requires this format
    const reportData = {
      reportTypes: reportTypes,
      reportData: {
        answers: {
          jobTitle: getState().addJob.jobDetails.PayscaleJobTitle,
          location: workLocation,
          YearsExperience:
            options.YearsExperience || options.YearsExperience == '' ? options.YearsExperience : yearsExperience,
          YearsMinimum:
            options.YearsMinimum || options.YearsMinimum == ''
              ? options.YearsMinimum
              : getState().addJob.jobDetails.YearsMinimum,
          HighestDegreeEarned: options.HighestDegreeEarned
            ? options.HighestDegreeEarned
            : getState().addJob.jobDetails.HighestDegreeEarned,
          MajorSpecialty: options.MajorSpecialty ? options.MajorSpecialty : getState().addJob.jobDetails.MajorSpecialty,
          Skills: options.Skills ? options.Skills : getState().addJob.jobDetails.SkillNameList,
          Certifications: options.Certifications ? options.Certifications : getState().addJob.jobDetails.CertNameList
        }
      },
      orgJobId: getState().addJob.orgJobId,
      auditId: options.auditId
    };
    // There is a list of job responsibilities in jobDetails.JobResponsibilities.
    // The list that comes back from jobalyzer validation report has questionId
    // I use this list to add onto the reportData answers
    const jobPayFactors = {};
    const jobJobResponsibilities = getState().addJob.jobDetails.JobResponsibilities;
    const jobDetails = getState().addJob.jobDetails;
    if (options && options.questionId) {
      jobDetails[options.questionId] = options.value;
      // Dont send additional info to jobalyzer. Jobalyzer not smart enough to know management roles
      if (options.questionId == 'ManagementRole' && options.value != 'Yes') {
        jobDetails.PeopleManagementTasks = [];
        jobDetails.PeopleTypesManaged = null;
        jobDetails.NumberSupervised = null;
      }
    }
    Object.keys(getState().addJob.jobDetails).forEach(function getKeys(key, index) {
      if (jobJobResponsibilities && jobJobResponsibilities.includes(key)) {
        jobPayFactors[key] = jobDetails[key];
      }
    });

    reportData.reportData.answers = Object.assign({}, reportData.reportData.answers, jobPayFactors);

    reportData.reportData = JSON.stringify(reportData.reportData);
    reportData.reportTypes = JSON.stringify(reportData.reportTypes);
    // Keep counts to track which upadte was requested
    updateReportCount++;
    try {
      dispatch({ type: CREATE_RETRIEVE_REPORT });
      const result = await apiClient.apiGet('/api/hris/jobJobalyzerReports/createRetrieveUpdateReports', {
        params: reportData
      });
      dispatch({
        type: CREATE_RETRIEVE_REPORT_SUCCESS,
        result: result.data,
        query: reportData,
        updatePayReport: true,
        updateReportCountSent: updateReportCount
      });
      if (!options.excludeJobDetailsUpdate) {
        dispatch(getUpdatedMarketSalary({ orgJobId: getState().addJob.orgJobId }));
      } else {
        // Only recieve new market data like base 50th
        dispatch(getUpdatedMarketData({ orgJobId: getState().addJob.orgJobId }));
      }
    } catch (error) {
      dispatch({ type: CREATE_RETRIEVE_REPORT_FAIL, error: error });
    }

    let addJobForm = getState().addJob; // eslint-disable-line prefer-const
    if (
      (addJobForm.jobDetails.Reports && !addJobForm.jobDetails.Reports.validation) ||
      (addJobForm.jobDetails.Reports &&
        addJobForm.jobDetails.Reports.validation &&
        addJobForm.jobDetails.Reports.validation.suggestions &&
        addJobForm.jobDetails.Reports.validation.suggestions.length == 0)
    ) {
      dispatch(updateValidationReport());
    }
  };
}

export function updateValidationReport(options) {
  return async (dispatch, getState) => {
    const workLocation = getState().addJob.surveyPriceLocation
      ? getState().addJob.surveyPriceLocation
      : getState().addJob.workLocationExact;
    //Jobalyzer wont give back correct info if extra reportData...
    const reportData = {
      reportTypes: ['validation'],
      reportData: {
        answers: {
          jobTitle: getState().addJob.jobDetails.PayscaleJobTitle,
          location: workLocation,
          YearsExperience: null,
          YearsMinimum: null,
          HighestDegreeEarned: '',
          MajorSpecialty: '',
          Skills: [],
          Certifications: []
        }
      },
      orgJobId: getState().addJob.orgJobId
    };
    reportData.reportData = JSON.stringify(reportData.reportData);
    reportData.reportTypes = JSON.stringify(reportData.reportTypes);

    try {
      dispatch({ type: CREATE_RETRIEVE_REPORT });
      const result = await apiClient.apiGet('/api/hris/jobJobalyzerReports/createRetrieveUpdateReports', {
        params: reportData
      });

      dispatch({
        type: CREATE_RETRIEVE_REPORT_SUCCESS,
        result: result.data,
        query: reportData
      });

      dispatch(retrieveDynamicAnswers(reportData));
      dispatch(retrieveSuggestionDataTypes());
    } catch (error) {
      dispatch({ type: CREATE_RETRIEVE_REPORT_FAIL, error: error });
    }
  };
}

// This is for updating and saving a job with a new detail such as years experience.
// On the react side pass in all the jobDetails and the backend will
// update payscale_job_info in org_jobs with the new jobDetails
export function saveJob(options) {
  return async (dispatch, getState) => {
    const addJobState = getState().addJob;
    // add job's org job id
    const jobId = addJobState.orgJobId;
    // add job's selected job id
    const selectedJobId = addJobState.selectedJobId;
    // job details selected job id
    const jobDetails = getState().jobDetails;
    let jobDetailsJobId = null;
    if (jobDetails && jobDetails.jobIdList && jobDetails.jobIdList.length > 0) {
      jobDetailsJobId = jobDetails.jobIdList[0];
    }
    if (jobId !== selectedJobId || selectedJobId !== jobDetailsJobId || jobId !== jobDetailsJobId) {
      // PSP-23726 Don't do a save if the job id's don't match what's currently selected
      // The addJob.orgJobId gets replaced multiple times throughout the redux
      // workflow and we have a potential for failure if a request
      // from a previous job finishes after a new job is selected.
      dispatch({
        type: JOB_SAVE_FAIL,
        error: 'Job Save Failed',
        status: 'Unfortunately, we didn’t quite get that. Please give us a second to catch up.'
      });
    }
    if (options.jobDetails) {
      // PSP-23726 - This line of code is commented out as it sometimes grabs the wrong title match
      // options.jobDetails.PayscaleJobTitle = addJobState.jobDetails.PayscaleJobTitle;
      // options.jobDetails.Reports = getState().addJob.reports;
      options.jobDetails = JSON.stringify(options.jobDetails);
    }
    options.dataScienceJobId = getState().addJob?.dataScienceJobId;
    try {
      dispatch({ type: JOB_SAVE });
      const result = await apiClient.apiPost('/api/hris/jobs/saveJob', options);

      dispatch({
        type: JOB_SAVE_SUCCESS,
        result: result.data,
        query: options
      });

      if (
        result &&
        result.data.autoMatch &&
        result.data.autoMatch.success &&
        result.data.autoMatch.surveyJobs &&
        result.data.autoMatch.surveyJobs.length
      ) {
        dispatch({
          type: UPDATE_MATCHES_SUCCESS,
          result: {
            success: result.data.autoMatch.success,
            surveyJobMatches: result.data.autoMatch.surveyJobs
          }
        });
        let surveyJobs = null;
        if (result && result.data && result.data.autoMatch && result.data.autoMatch.surveyJobs) {
          surveyJobs = result.data.autoMatch.surveyJobs;
        } else if (result && result.autoMatch && result.autoMatch.surveyJobs) {
          surveyJobs = result.autoMatch.surveyJobs;
        }
        const jobSurveyCompanyDispatch = getJobSurveyCompanyMatchDataCuts(getState, surveyJobs);
        if (jobSurveyCompanyDispatch) {
          dispatch(jobSurveyCompanyDispatch);
        }
      }
    } catch (error) {
      dispatch({ type: JOB_SAVE_FAIL, error: error });
    }
  };
}

export function saveJobAndUpdatePay(saveJobArgs, updatePayArgs) {
  return async (dispatch, getState) => {
    const auditId = uuid.v4();
    saveJobArgs.auditId = auditId;
    updatePayArgs.auditId = auditId;

    await dispatch(saveJob(saveJobArgs));
    throttleActionSync(updatePayReport(updatePayArgs), 'updatePayReport', dispatch, { lastActionOnly: true });
  };
}

export function saveJobFamily(options, updatePayArgs) {
  return async (dispatch, getState) => {
    options.orgJobId = getState().addJob.orgJobId;
    try {
      dispatch({ type: FAMILY_SAVE });
      const result = await apiClient.apiPost('/api/hris/job-family/saveJobFamily', options);
      dispatch({ type: FAMILY_SAVE_SUCCESS, result: result.data });

      if (
        getState().addJob.jobDetails.Reports &&
        getState().addJob.jobDetails.Reports.payReport &&
        getState().addJob.jobDetails.Reports.payReport.reports &&
        getState().addJob.jobDetails.Reports.payReport.reports.basePayReport
      ) {
        dispatch(
          suggestCareerLevel({
            basePay50th: getState().addJob.jobDetails.Reports.payReport.reports.basePayReport.percentile50
          })
        );
      }
      throttleActionSync(updatePayReport(updatePayArgs), 'updatePayReport', dispatch, { lastActionOnly: true });
      dispatch(getUpdatedMarketSalary({ orgJobId: options.orgJobId }));
    } catch (error) {
      dispatch({ type: FAMILY_SAVE_FAIL, error: error });
    }
  };
}

export function saveCareerLevel(options) {
  return async (dispatch, getState) => {
    options.orgJobId = getState().addJob.orgJobId;
    try {
      dispatch({ type: CAREER_SAVE });
      const result = await apiClient.apiPost('/api/hris/jobs/saveCareerLevel', options);

      dispatch({ type: CAREER_SAVE_SUCCESS, result: result.data });
    } catch (error) {
      dispatch({ type: CAREER_SAVE_FAIL, error: error });
    }
  };
}

export function changePayStructure(options) {
  return async (dispatch, getState) => {
    options.orgJobId = getState().addJob.orgJobId;
    options.org_salary_structure = options.structure;
    options.orgSalaryStructure = options.structure;
    if (!options.skipJobGradeReset) {
      dispatch({
        type: PAY_STRUCTURE_CHANGE,
        payStructure: options.structure
      });
    }

    try {
      dispatch({ type: GET_RECOMMEND_GRADE });
      const result = await apiClient.apiPost('/api/hris/grades/recommendGrade', options);

      dispatch({ type: GET_RECOMMEND_GRADE_SUCCESS, result: result.data });
    } catch (error) {
      dispatch({ type: GET_RECOMMEND_GRADE_FAIL, error: error });
    }
  };
}

export function saveJobGrade(options) {
  return async (dispatch, getState) => {
    options.orgJobId = getState().addJob.orgJobId;
    options.org_salary_structure = getState().addJob.payStructure;
    try {
      dispatch({ type: GRADE_SAVE });
      const result = await apiClient.apiPost('/api/hris/jobs/saveJobGrade', options);

      dispatch({ type: GRADE_SAVE_SUCCESS, result: result.data });
    } catch (error) {
      dispatch({ type: GRADE_SAVE_FAIL, error: error });
    }
  };
}

export function removeJobDescription() {
  return async (dispatch, getState) => {
    const jobId = getState().addJob.orgJobId;
    try {
      dispatch({ type: REMOVE_JD });
      const result = await apiClient.apiPost('/api/util/convert-doc/removeJobDescription?orgJobId=' + jobId);

      dispatch({ type: REMOVE_JD_SUCCESS, result: result.data });
    } catch (error) {
      dispatch({ type: REMOVE_JD_FAIL, error: error });
    }
  };
}

export function jobLocationSearch(options) {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: JOB_LOCATION });
      const result = await apiClient.apiGet('/api/payscale/locations/locationSearch', { params: options.params });
      let data = result.data;

      if (window.app.basicProductName === 'MarketPay') {
        if (options.orgJobCurrency === 'USD') {
          data = result.data.filter(item => item.label.includes('United States'));
        } else {
          data = result.data.filter(item => item.label.includes('Canada'));
        }
      }

      dispatch({ type: JOB_LOCATION_SUCCESS, result: data, searchCallback: options.searchCallback });
      if (options.returnLocationResults) {
        return data;
      }
    } catch (error) {
      dispatch({ type: JOB_LOCATION_FAIL, error: error });
    }
  };
}

export function launchEditJobSlideout(jobInfo) {
  return (dispatch, getState) => {
    dispatch({ type: EDIT_JOB_LAUNCH, jobDetails: jobInfo.details });
    dispatch({ type: HIDE_SCROLL_BAR });
  };
}

export function editJobSlideoutLoad() {
  return (dispatch, getState) => {
    const detailsLoadPromises = [];

    detailsLoadPromises.push(dispatch(getJobStructureList()));
    detailsLoadPromises.push(dispatch(getJobFamilies()));
    detailsLoadPromises.push(dispatch(getCareerLevels()));
    const jobSurveyCompanyDispatch = getJobSurveyCompanyMatchDataCuts(getState);
    if (jobSurveyCompanyDispatch) {
      detailsLoadPromises.push(dispatch(jobSurveyCompanyDispatch));
    }

    const addJobState = getState().addJob;

    if (IsJobPriced(addJobState.workLocation, addJobState.jobDetails?.PayscaleJobTitle)) {
      detailsLoadPromises.push(dispatch(selectJob(addJobState.orgJobId, addJobState.jobDetails.PayscaleJobTitle)));

      // MP does not use job family in their crowd sourced feature so this call is not needed.
      if (window.app.basicProductName !== 'MarketPay' && !addJobState.family) {
        detailsLoadPromises.push(
          dispatch(suggestJobFamilies({ payscaleJobTitle: addJobState.jobDetails.PayscaleJobTitle }))
        );
      }

      if (addJobState.jobDetails?.Reports?.payReport?.reports?.basePayReport) {
        detailsLoadPromises.push(
          dispatch(
            suggestCareerLevel({
              basePay50th: addJobState.jobDetails.Reports.payReport.reports.basePayReport.percentile50
            })
          )
        );
      }

      if (addJobState.jobDetails.AllSkills) {
        detailsLoadPromises.push(dispatch(populateJobSkills(addJobState.jobDetails.AllSkills)));
      } else {
        detailsLoadPromises.push(
          dispatch(getJobSkills({ params: { title: addJobState.jobDetails.PayscaleJobTitle } }))
        );
      }

      if (addJobState.jobDetails.AllCerts) {
        detailsLoadPromises.push(dispatch(populateJobCerts(addJobState.jobDetails.AllCerts)));
      } else {
        detailsLoadPromises.push(dispatch(getJobCerts({ params: { title: addJobState.jobDetails.PayscaleJobTitle } })));
      }

      if (
        (addJobState.jobDetails.Reports &&
          (!addJobState.jobDetails.Reports.yearsExperienceReport || !addJobState.jobDetails.Reports.validation)) ||
        (addJobState.jobDetails.Reports?.validation?.suggestions &&
          !addJobState.jobDetails.Reports.validation.suggestions.length)
      ) {
        // Need to re-run all reports and validation
        const workLocation = addJobState.jobDetails.surveyPriceLocation || addJobState.jobDetails.workLocationExact;
        let addJobForm = getState().addJob; // eslint-disable-line prefer-const
        addJobForm.workLocation = workLocation;
        throttleActionSync(updatePayReport(addJobForm), 'updatePayReport', dispatch, { lastActionOnly: true });
      } else if (addJobState.jobDetails.Reports) {
        // Get dynamic jobs
        const workLocation = addJobState.jobDetails.surveyPriceLocation || addJobState.jobDetails.workLocationExact;
        let addJobForm = getState().addJob; // eslint-disable-line prefer-const
        addJobForm.workLocation = workLocation;
        const dynamicReportData = getReportData(addJobForm);
        detailsLoadPromises.push(dispatch(retrieveDynamicAnswers(dynamicReportData)));
        detailsLoadPromises.push(dispatch(retrieveSuggestionDataTypes()));
      }
    }

    Promise.all(detailsLoadPromises).then(() => {
      dispatch({ type: DETAILS_FULLY_LOADED });
    });
  };
}

export function editJobTitleSearch(params) {
  return (dispatch, getState) => {
    dispatch(editJobTitle());
    dispatch(titleJobSearch(params));
  };
}

export function editJobTitle(jobInfo) {
  return (dispatch, getState) => {
    dispatch({
      type: EDIT_JOB_TITLE
    });
  };
}

export function cancelEditJobTitle() {
  return (dispatch, getState) => {
    dispatch({
      type: CANCEL_EDIT_JOB_TITLE
    });
  };
}

export function onDrop(files, orgJobId) {
  // files is an array of files, from which ApiClient will attach files[0]
  return {
    types: [UPLOAD, UPLOAD_SUCCESS, UPLOAD_FAIL, UPLOAD_PROGRESS],
    promise: client => client.post('/api/hris/uploads/jobUpload?orgJobId=' + orgJobId, { files }),
    showModal: false,
    showDropZone: false,
    dzFile: files
  };
}

export function onDropDelayed(files, orgJobId) {
  // must be named "files" to work with the DropzoneJS library we're using for uplaods
  // files is an array of files, from which ApiClient will attach files[0]
  return {
    types: [UPLOAD, UPLOAD_SUCCESS, UPLOAD_FAIL, UPLOAD_PROGRESS],
    promise: client => client.post('/api/hris/uploads/jobUpload?orgJobId=' + orgJobId, { files }),
    dzFile: files
  };
}

export function onDropRejected(files, orgJobId, rejectReason) {
  return (dispatch, getState) => {
    dispatch({
      type: ON_DROP_REJECTED,
      dzFile: files,
      rejectReason: rejectReason
    });
  };
}

export function delayUpload(files) {
  return (dispatch, getState) => {
    dispatch({
      type: DELAY_UPLOAD,
      delayFile: files
    });
  };
}

export function cancelUpload() {
  return (dispatch, getState) => {
    if (getState().addJob.lastRequest) {
      getState().addJob.lastRequest.abort();
    }

    dispatch({
      type: UPLOAD_CANCEL,
      showModal: false,
      showDropZone: false,
      showDropZoneUploading: false,
      jobDescription: null,
      dzFile: null
    });
  };
}

export function onDragEnter() {
  return (dispatch, getState) => {
    dispatch({
      type: ON_DRAG_ENTER,
      showModal: true,
      showDropZone: true
    });
  };
}

export function onDragLeave() {
  return (dispatch, getState) => {
    dispatch({
      type: ON_DRAG_LEAVE,
      showModal: false,
      showDropZone: false
    });
  };
}

export function addJobSlideOut(history) {
  return dispatch => {
    history.push('/jobs/');
    dispatch({ type: ADD_JOB_LAUNCH, fromJobDetailsPanel: true });
    dispatch({ type: HIDE_SCROLL_BAR });
  };
}

export function setInitialJobDetails(jobDetails) {
  return dispatch => {
    dispatch({ type: SET_INITIAL_JOB_DETAILS, jobDetails: jobDetails });
  };
}

export function updateMatchesAndPay(matches, jobId, saveJobParams, updatePayArgs) {
  return async dispatch => {
    const matchParams = new Array();
    matches.forEach(match => {
      const { proposedAdj: adj, proposedGeo: geo, proposedWgt: wgt, match_id: matchId } = match;
      const matchData = { adj, geo, wgt, matchId };
      matchParams.push(matchData);
    });

    const options = { params: { matches: JSON.stringify(matchParams), org_job_id: jobId } };

    try {
      dispatch({ type: UPDATE_MATCHES });
      const result = await apiClient.apiGet('/api/market-pricing/job-matches/updateMatches', options);

      dispatch({ type: UPDATE_MATCHES_SUCCESS, result: result.data });

      dispatch(saveJobAndUpdatePay(saveJobParams, updatePayArgs));
    } catch (error) {
      dispatch({ type: UPDATE_MATCHES_FAIL, error: error });
    }
  };
}

export function clearAddJobState() {
  return dispatch => {
    dispatch({ type: CLEAR_ADD_JOB_STATE });
  };
}

export function deletePayscaleJobDetails(orgJobId) {
  return async dispatch => {
    try {
      dispatch({ type: DELETE_DETAILS });
      const result = await apiClient.apiGet('/api/market-pricing/jobs/deletePayScaleJobInfo', {
        params: { orgJobId }
      });

      dispatch({ type: DELETE_DETAILS_SUCCESS, result: result.data });
      return result;
    } catch (error) {
      dispatch({ type: DELETE_DETAILS_FAIL, error: error });
    }
  };
}

export function quickUpdateJobDetailsAndPay(options, updatePayArgs) {
  return async (dispatch, getState) => {
    const auditId = uuid.v4();
    options.auditId = auditId;
    updatePayArgs.auditId = auditId;
    // Don't grab latest job details
    updatePayArgs.excludeJobDetailsUpdate = true;

    const addJobState = getState().addJob;
    // add job's org job id
    const jobId = addJobState.orgJobId;
    // add job's selected job id
    const selectedJobId = addJobState.selectedJobId;
    // job details selected job id
    const jobDetails = getState().jobDetails;
    let jobDetailsJobId = null;
    if (jobDetails && jobDetails.jobIdList && jobDetails.jobIdList.length > 0) {
      jobDetailsJobId = jobDetails.jobIdList[0];
    }
    if (jobId !== selectedJobId || selectedJobId !== jobDetailsJobId || jobId !== jobDetailsJobId) {
      // PSP-23726 Don't do a save if the job id's don't match what's currently selected
      dispatch({
        type: QUICK_JOB_DETAILS_SAVE_FAIL,
        error: 'Job Save Failed',
        status: 'Unfortunately, we didn’t quite get that. Please give us a second to catch up.'
      });
    }

    try {
      dispatch({ type: QUICK_JOB_DETAILS_SAVE });
      const result = await apiClient.apiPost('/api/hris/jobs/quickUpdatePayscaleJobInfo', options);
      if (result.data.success) {
        dispatch({
          type: QUICK_JOB_DETAILS_SAVE_SUCCESS,
          result: result.data,
          query: options
        });
        throttleActionSync(updatePayReport(updatePayArgs), 'updatePayReport', dispatch, { lastActionOnly: true });
      } else {
        dispatch({ type: QUICK_JOB_DETAILS_SAVE_FAIL, error: result.data.reason });
      }
    } catch (error) {
      dispatch({ type: QUICK_JOB_DETAILS_SAVE_FAIL, error: error });
    }
  };
}

export function setJobMatchSaving(saving) {
  return (dispatch, getState) => {
    dispatch({ type: SET_JOB_MATCH_SAVING, value: saving });
  };
}

export function setJobMatchError(hasError) {
  return (dispatch, getState) => {
    dispatch({ type: SET_JOB_MATCH_ERROR, value: hasError });
  };
}

function getPayReportData(jobTitle, workLocation) {
  const reportData = {
    reportTypes: ['payReport'],
    reportData: {
      answers: {
        jobTitle: jobTitle,
        location: workLocation,
        YearsExperience: null
      }
    }
  };
  return reportData;
}
