const ADD_EMPLOYEE_JOB_SEARCH_SUCCESS = 'ADD_EMPLOYEE_JOB_SEARCH_SUCCESS';
const ADD_EMPLOYEE_LAUNCH = 'ADD_EMPLOYEE_LAUNCH';
const ADD_EMPLOYEE_CLOSE = 'ADD_EMPLOYEE_CLOSE';
const SAVE_ADD_EMPLOYEE = 'SAVE_ADD_EMPLOYEE';
const SAVE_ADD_EMPLOYEE_SUCCESS = 'SAVE_ADD_EMPLOYEE_SUCCESS';
const SAVE_ADD_EMPLOYEE_FAIL = 'SAVE_ADD_EMPLOYEE_FAIL';
const ADD_EMPLOYEE_EXISTS = 'ADD_EMPLOYEE_EXISTS';

const initialState = Object.freeze({
  dirty: false,
  isFormValid: true,
  panelVisible: false,
  saving: false,
  saved: false,
  employeeId: null,
  employeeExists: false,
  error: null
});

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case ADD_EMPLOYEE_LAUNCH:
      return {
        ...initialState,
        panelVisible: true
      };
    case ADD_EMPLOYEE_CLOSE:
      return {
        ...initialState
      };
    case SAVE_ADD_EMPLOYEE:
      return {
        ...state,
        saving: true,
        error: '',
        employeeExists: false
      };
    case SAVE_ADD_EMPLOYEE_SUCCESS:
      return {
        ...state,
        saving: false,
        saved: !!action.result.employeeId,
        employeeId: action.result.employeeId,
        error: action.result.reason ? action.result.reason : null
      };
    case ADD_EMPLOYEE_EXISTS:
      return {
        ...state,
        employeeExists: true
      };
    case SAVE_ADD_EMPLOYEE_FAIL:
      return {
        ...state,
        saving: false,
        saved: false,
        error: action.result.reason
      };
    case ADD_EMPLOYEE_JOB_SEARCH_SUCCESS:
      action.callback(null, {
        options: action.result
      });
      return {
        ...state
      };
    default:
      return {
        ...state
      };
  }
}
