import listPanel from '../genericGrid/listPanel';
import * as jobActions from './jobActions';
import { getSearchItemById } from './selectors';

const initialState = {
  pageName: 'jobs',
  sort: {},
  allowSort: false,
  allowCheckAll: true,
  expandedMaxColumnCount: 1,
  selectedListRows: [],
  multiSelectedRows: [],
  multiExcludedRows: [],
  isSelectAllChecked: false,
  showDeleteModal: false,
  print: false,
  dirtyJobs: [],
  columns: [
    {
      title: 'Job Title',
      dataKey: 'org_job_title_english',
      field_type_code: 'text'
    },
    {
      title: 'Location',
      dataKey: 'work_location_job_code_key_standard',
      field_type_code: 'text'
    },
    {
      title: 'Pay at 50th',
      dataKey: 'market_base_salary_50th_default',
      field_type_code: 'monetary'
    },
    {
      title: 'Range',
      dataKey: ['market_base_salary_25th_default', 'market_base_salary_75th_default'],
      field_type_code: 'monetary'
    }
  ]
};

let actions = {}; // eslint-disable-line prefer-const

// extend or override action creators here:
actions.loadDetailsPanel = jobActions.loadDetailsPanel;

actions.loadComparisonPanel = jobActions.loadComparisonPanel;

actions.handleRowSelect = function handleRowSelect(...args) {
  const [, getStore, selectedRows] = args;
  const selected = [].concat(selectedRows);
  const length = selected.length;
  if (!length) {
    return;
  }

  if (length === 1) {
    const item = getSearchItemById(getStore(), selected[0]);
    actions.loadDetailsPanel(...args, item);
  }

  if (length > 1) {
    actions.loadComparisonPanel(...args);
  }
};

actions.createEntityAction = function createEntityAction() {
  return dispatch => {
    dispatch({
      type: 'ADD_JOB_LAUNCH',
      fromJobDetailsPanel: true
    });
    dispatch({
      type: 'HIDE_SCROLL_BAR'
    });
  };
};

actions.cancelDelete = function cancelDelete() {
  return {
    type: 'JOBS_CANCEL_DELETE'
  };
};

actions.confirmDelete = function confirmDelete() {
  return (dispatch, getState) => {
    const { pendingDeleteJobId: jobId } = getState().jobsList;
    const options = { orgJobId: jobId };
    dispatch({
      types: ['JOB_DELETE', 'JOB_DELETE_SUCCESS', 'JOB_DELETE_FAIL'],
      promise: client => client.get('/api/hris/jobs/deleteJob', { params: options })
    });
  };
};

export const jobsListPanel = listPanel('JOBS', initialState, actions);
export const reducer = jobsListPanel.reducer;

// extend reducer here:
jobsListPanel.extendReducer = function extendReducer(state, action) {
  switch (action.type) {
    default:
      return null;
    case 'JOBS_SHOW_DELETE_MODAL':
      return {
        ...state,
        showDeleteModal: true,
        pendingDeleteJobId: action.jobId
      };
    case 'JOBS_CANCEL_DELETE':
      return {
        ...state,
        showDeleteModal: false,
        pendingDeleteJobId: null
      };
    case 'JOB_DELETE_SUCCESS':
      return {
        ...state,
        selectedListRows: []
      };
    case 'JOB_DELETE':
      return {
        ...state,
        showDeleteModal: false,
        pendingDeleteJobId: null
      };
    case 'JOB_DOWNLOAD':
      return {
        ...state,
        pendingPrintJobId: action.jobId
      };
    case 'JOB_DOWNLOAD_SUCCESS':
      return {
        ...state,
        downloadSuccess: true
      };
    case 'JOBS_MARK_DIRTY':
      return {
        ...state,
        dirtyJobs: action.dirtyJobList
      };
  }
};
