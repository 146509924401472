import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Modal from 'react-awesome-modal';

const propTypes = {
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  modelName: PropTypes.string.isRequired
};

export default class JobRangesRemoveJobsModal extends Component {
  static propTypes = propTypes;

  render() {
    const { onConfirm, onCancel, modelName } = this.props;

    return (
      <Modal visible onClickAway={onCancel} effect="fadeInDown" height={'275'} width={'450'}>
        <div className="jobRanges-confirm-modal-content">
          <h1 className="pxl-header-small">Confirm Remove</h1>
          <div className="modal-text">{`Are you sure you want to remove all jobs from the "${modelName}" model?`}</div>
          <div className="button-container">
            <a className="pxl-margin-small" onClick={onCancel}>
              Cancel
            </a>
            <button
              className="pxl-btn pxl-btn-primary"
              onClick={onConfirm}
              data-test-id="jbr-remove-jobs-confirm-button"
            >
              Remove
            </button>
          </div>
        </div>
      </Modal>
    );
  }
}
