import React from 'react';
import '../styles/unsupported.scss';

function UnsupportedViewer(props) {
  return (
    <div className="file-viewer__driver-view">
      <div className="unsupported-message">
        {props.unsupportedComponent ? (
          <props.unsupportedComponent {...props} />
        ) : (
          <p className="alert">
            <b>{`.${props.fileType}`}</b> is not a supported file type.
          </p>
        )}
      </div>
    </div>
  );
}

export default UnsupportedViewer;
