import React, { Component } from 'react';
import Select, { Creatable } from 'react-select';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './ReactSelect.scss';
import CommonErrorBoundary from '../Util/CommonErrorBoundary';

class ReactSelect extends Component {
  static propTypes = {
    name: PropTypes.string,
    classNamePrefix: PropTypes.string,
    className: PropTypes.string,
    isClearable: PropTypes.bool,
    options: PropTypes.array,
    getOptionValue: PropTypes.func,
    getOptionLabel: PropTypes.func,
    value: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    isMulti: PropTypes.bool,
    noOptionsMessage: PropTypes.func,
    instanceId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    components: PropTypes.object,
    openMenuOnFocus: PropTypes.bool,
    autoFocus: PropTypes.bool,
    onBlur: PropTypes.func,
    isDisabled: PropTypes.bool,
    isCreatable: PropTypes.bool,
    darkMode: PropTypes.bool
  };

  static defaultProps = {
    classNamePrefix: 'react-select',
    isClearable: false,
    isMulti: false,
    darkMode: false
  };

  render() {
    const { className, darkMode } = this.props;

    const cardClass = classNames({
      'react-select': true,
      'dark-mode-select': darkMode,
      [className]: !!className
    });

    const props = { ...this.props, className: cardClass };

    return (
      <CommonErrorBoundary>{props.isCreatable ? <Creatable {...props} /> : <Select {...props} />}</CommonErrorBoundary>
    );
  }
}

export default ReactSelect;
