import * as Fuse from 'fuse.js';
import { mapDispatchToActions } from '../../../../../panther-react/src/helpers/utils.js';
import { AxiosInstance, AxiosResponse, AxiosPromise } from 'axios';
import { RoleIds, ActivationLinkExpirationInDays } from './Constants';

export interface UserState {
  loading: boolean;
  loaded: boolean;
  error: string | null;
  users: User[];
  showAddEditModal: boolean;
  deleteModal: boolean;
  editUser: User | {};
  deleteUser: {};
  allRoles: Role[] | null;
  userMatch: {};
  showManagersPay: boolean;
  filteredUsers: Array<User>;
  roleFilter: string;
  statusFilter: string;
  isInviteEditUserSlideoutOpen: boolean;
  managersInviteSlideoutOpen: boolean;
  managers: Manager[];
  managersListLoading: boolean;
}

export const initialState: UserState = {
  loading: false,
  loaded: false,
  error: null,
  users: [],
  filteredUsers: [],
  roleFilter: "",
  statusFilter: "",
  showAddEditModal: false,
  deleteModal: false,
  editUser: {},
  deleteUser: {},
  allRoles: null,
  userMatch: {},
  showManagersPay: false,
  isInviteEditUserSlideoutOpen: false,
  managersInviteSlideoutOpen: false,
  managers: [],
  managersListLoading: false
};

const fuseOptions = {
  shouldSort: true,
  threshold: 0.3,
  location: 0,
  distance: 100,
  maxPatternLength: 32,
  minMatchCharLength: 1,
  keys: ['first_name', 'last_name', 'full_name', 'email', 'role_name_pretty', 'role', 'blocked', 'userId']
};

function setMatchTrue(users: User[]): User[] {
  for (let i = 0; i < users.length; i++) {
    users[i].match = true;
    if (users[i].invite_token) {
      users[i].blocked = 'Pending';
    }
    if (users[i].blocked === false) {
      users[i].blocked = 'Active';
    }

    if (users[i].blocked === true) {
      users[i].blocked = 'Locked';
    }
  }
  return users;
}

function filterByRoleAndStatus(allUsers: User[], filterRole: string, filterStatus: string): User[] {
  const filteredByRole: User[] = filterRole !== ""
        ? allUsers.filter(user => user.role_name_pretty === filterRole)
        : allUsers;
      const finalFilteredList: User[] = filterStatus !== ""
        ? filteredByRole.filter(user => user.blocked === filterStatus)
        : filteredByRole;
  return finalFilteredList;
}

function filterUsersFn(state: UserState, query: string): User[] {
  const allUsers: User[] = [...state.users];
  const filterByRoleAndStatusUsers: User[] = filterByRoleAndStatus(allUsers, state.roleFilter, state.statusFilter);

  if (!query || query === '') {
    if (query === '') {
      filterByRoleAndStatusUsers.forEach( user => user.match = true);
    }
    return filterByRoleAndStatusUsers;
  }

  // Returns array of filtered items
  const fuse = new Fuse.default(filterByRoleAndStatusUsers, fuseOptions); // eslint-disable-line new-cap

  const filteredItems: User[] = fuse.search(query);

  for (let i = 0; i < filterByRoleAndStatusUsers.length; i++) {
    // Loop through all items returned by the API, and set item.match to false - used for rerendering.
    const item = filterByRoleAndStatusUsers[i];
    item.match = false;
    for (let j = 0; j < filteredItems.length; j++) {
      // Loop through all filtered items for each item in the base list, compare unique ids, and set match
      if (item.user_id === filteredItems[j].user_id) {
        item.match = true;
      }
    }
  }

  return filterByRoleAndStatusUsers;
}

/* eslint-disable @typescript-eslint/class-name-casing */

interface SELECT_USER {
  selectedUser: User;
  type: 'SELECT_USER';
}

interface FILTER_USERS_BY_STATUS_ROLE {
  filterRole: string;
  filterStatus: string;
  type: 'FILTER_USERS_BY_STATUS_ROLE';
}

interface TOGGLE_SELECT_ALL_USERS {
  type: 'TOGGLE_SELECT_ALL_USERS';
  checked: boolean;
}


interface GET_USERS {
  type: 'GET_USERS';
}

interface GET_USERS_SUCCESS {
  type: 'GET_USERS_SUCCESS';
  result: User[];
}

interface GET_USERS_FAIL {
  result: string | null;
  type: 'GET_USERS_FAIL';
}

interface GET_MANAGERS {
  type: 'GET_MANAGERS';
}

interface GET_MANAGERS_SUCCESS {
  type: 'GET_MANAGERS_SUCCESS';
  result: Manager[];
}

interface GET_MANAGERS_FAIL {
  result: string | null;
  type: 'GET_MANAGERS_FAIL';
}

interface GET_ALL_ROLES {
  type: 'GET_ALL_ROLES';
}

interface GET_ALL_ROLES_SUCCESS {
  result: null;
  type: 'GET_ALL_ROLES_SUCCESS';
}

interface GET_ALL_ROLES_FAIL {
  type: 'GET_ALL_ROLES_FAIL';
}

interface USERS_FILTER {
  query: string;
  type: 'USERS_FILTER';
}

interface SAVE_USER {
  type: 'SAVE_USER';
}

interface SAVE_USER_SUCCESS {
  type: 'SAVE_USER_SUCCESS';
}

interface SAVE_USER_FAIL {
  result: string | null;
  type: 'SAVE_USER_FAIL';
}

interface FIND_USER {
  type: 'FIND_USER';
}

interface FIND_USER_SUCCESS {
  result: User[];
  type: 'FIND_USER_SUCCESS';
}

interface FIND_USER_FAIL {
  result: string | null;
  type: 'FIND_USER_FAIL';
}

interface DEACTIVATE_USER {
  type: 'DEACTIVATE_USER';
}

interface DEACTIVATE_USER_SUCCESS {
  type: 'DEACTIVATE_USER_SUCCESS';
}

interface DEACTIVATE_USER_FAIL {
  result: string | null;
  type: 'DEACTIVATE_USER_FAIL';
}

interface OPEN_ADD_EDIT_MODAL {
  rowInfo: User;
  type: 'OPEN_ADD_EDIT_MODAL';
}

interface CLOSE_ADD_EDIT_MODAL {
  type: 'CLOSE_ADD_EDIT_MODAL';
}

interface OPEN_USER_DELETE_MODAL {
  users: User[];
  type: 'OPEN_USER_DELETE_MODAL';
}

interface CLOSE_USER_DELETE_MODAL {
  type: 'CLOSE_USER_DELETE_MODAL';
}

interface RESET_PASSWORD {
  type: 'RESET_PASSWORD';
}

interface RESET_PASSWORD_SUCCESS {
  type: 'RESET_PASSWORD_SUCCESS';
}

interface RESET_PASSWORD_FAIL {
  result: string | null;
  type: 'RESET_PASSWORD_FAIL';
}

interface BECOME_USER {
  type: 'BECOME_USER';
}

interface BECOME_USER_SUCCESS {
  type: 'BECOME_USER_SUCCESS';
}

interface BECOME_USER_FAIL {
  result: string | null;
  type: 'BECOME_USER_FAIL';
}

interface UNLOCK_USER {
  type: 'UNLOCK_USER';
}

interface UNLOCK_USER_SUCCESS {
  type: 'UNLOCK_USER_SUCCESS';
}

interface UNLOCK_USER_FAIL {
  result: string | null;
  type: 'UNLOCK_USER_FAIL';
}

interface SET_SHOW_MANAGERS_PAY {
  type: 'SET_SHOW_MANAGERS_PAY';
}

interface SET_SHOW_MANAGERS_PAY_SUCCESS {
  result: {
    show_managers_pay: boolean;
  };
  type: 'SET_SHOW_MANAGERS_PAY_SUCCESS';
}

interface SET_SHOW_MANAGERS_PAY_FAIL {
  result: string | null;
  type: 'SET_SHOW_MANAGERS_PAY_FAIL';
}

interface GET_SHOW_MANAGERS_PAY {
  type: 'GET_SHOW_MANAGERS_PAY';
}

interface GET_SHOW_MANAGERS_PAY_SUCCESS {
  result: {
    show_managers_pay: boolean;
  };
  type: 'GET_SHOW_MANAGERS_PAY_SUCCESS';
}

interface GET_SHOW_MANAGERS_PAY_FAIL {
  result: string | null;
  type: 'GET_SHOW_MANAGERS_PAY_FAIL';
}

interface SET_DEFAULT_SITE {
  type: 'SET_DEFAULT_SITE';
}

interface SET_DEFAULT_SITE_SUCCESS {
  type: 'SET_DEFAULT_SITE_SUCCESS';
  result: {
    primary_subsite: string;
  };
}

interface SET_DEFAULT_SITE_FAILURE {
  type: 'SET_DEFAULT_SITE_FAILURE';
}

interface CLOSE_INVITE_EDIT_USER_MODAL {
  type: 'CLOSE_INVITE_EDIT_USER_MODAL';
}

interface OPEN_INVITE_EDIT_USER_MODAL {
  rowInfo: User;
  type: 'OPEN_INVITE_EDIT_USER_MODAL';
}

interface OPEN_INVITE_MANAGERS_SLIDEOUT {
  type: 'OPEN_INVITE_MANAGERS_SLIDEOUT';
}

interface CLOSE_INVITE_MANAGERS_SLIDEOUT {
  type: 'CLOSE_INVITE_MANAGERS_SLIDEOUT';
}

interface RESEND_INVITE_FAIL {
  result: string | null;
  type: 'RESEND_INVITE_FAIL';
}

/* eslint-enable @typescript-eslint/class-name-casing */
interface EmptyAction {
  type?: undefined;
}

type UserAction = EmptyAction | SELECT_USER | GET_USERS | GET_USERS_SUCCESS | GET_USERS_FAIL | GET_ALL_ROLES | GET_ALL_ROLES_SUCCESS
 | GET_ALL_ROLES_FAIL | USERS_FILTER | SAVE_USER | SAVE_USER_SUCCESS | SAVE_USER_FAIL | FIND_USER | FIND_USER_SUCCESS
 | FIND_USER_FAIL | DEACTIVATE_USER | DEACTIVATE_USER_SUCCESS | DEACTIVATE_USER_FAIL | OPEN_ADD_EDIT_MODAL | CLOSE_ADD_EDIT_MODAL
 | OPEN_USER_DELETE_MODAL | CLOSE_USER_DELETE_MODAL | RESET_PASSWORD | RESET_PASSWORD_SUCCESS | RESET_PASSWORD_FAIL | BECOME_USER
 | BECOME_USER_SUCCESS | BECOME_USER_FAIL | UNLOCK_USER | UNLOCK_USER_SUCCESS | UNLOCK_USER_FAIL | SET_SHOW_MANAGERS_PAY
 | SET_SHOW_MANAGERS_PAY_SUCCESS | SET_SHOW_MANAGERS_PAY_FAIL | GET_SHOW_MANAGERS_PAY | GET_SHOW_MANAGERS_PAY_SUCCESS
 | GET_SHOW_MANAGERS_PAY_FAIL | SET_DEFAULT_SITE | SET_DEFAULT_SITE_SUCCESS | SET_DEFAULT_SITE_FAILURE | TOGGLE_SELECT_ALL_USERS
 | FILTER_USERS_BY_STATUS_ROLE | OPEN_INVITE_EDIT_USER_MODAL | CLOSE_INVITE_EDIT_USER_MODAL
 | OPEN_INVITE_MANAGERS_SLIDEOUT | CLOSE_INVITE_MANAGERS_SLIDEOUT | GET_MANAGERS | GET_MANAGERS_SUCCESS
 | GET_MANAGERS_FAIL | RESEND_INVITE_FAIL;

export default function reducer(state = initialState, action: UserAction = {}): UserState {
  const allRoles: Role[]  = state.allRoles || [{}, {}, {}];
  let activationLinkExpiration: number | 'NaN' = ActivationLinkExpirationInDays;
  let fn = '';
  let ln = '';
  let email = '';
  let role = RoleIds.Regularuser;
  let privilegeCode = allRoles[2].privilege_code;
  let userId = '';
  let primarySubsite = '';
  let editingUser = null;
  let clientManager = false;
  let implementationManager = false;
  let isSelected = false;

  switch (action.type) {
    case 'FILTER_USERS_BY_STATUS_ROLE':
      // eslint-disable-next-line no-case-declarations
      const allUsers: User[] = [...state.users];
      // eslint-disable-next-line no-case-declarations
      const finalFilteredList: User[] = filterByRoleAndStatus(allUsers, action.filterRole, action.filterStatus);
      return {
        ...state,
        filteredUsers: finalFilteredList,
        roleFilter: action.filterRole,
        statusFilter: action.filterStatus
      };
    case 'TOGGLE_SELECT_ALL_USERS':
      // eslint-disable-next-line no-case-declarations
      const filteredUsers: User[] = [...state.filteredUsers];
      filteredUsers.forEach(user => {
        if(user.match) {
         user.isSelected = action.checked;
        }});
      return {
        ...state,
        filteredUsers
      };
    case 'SELECT_USER':
      // eslint-disable-next-line no-case-declarations
      const userIndex: number = state.filteredUsers.findIndex(user => {
        if(action.selectedUser.invite_token) {
          return user.invite_token === action.selectedUser.invite_token;
        }  else {
          return user.email === action.selectedUser.email;
        }
      });
      return {
        ...state,
        filteredUsers: [
          ...state.filteredUsers.slice(0, userIndex), // everything before current post
        {
            ...state.filteredUsers[userIndex],
            isSelected: !state.filteredUsers[userIndex].isSelected
        },
        ...state.filteredUsers.slice(userIndex + 1), // everything after current post
        ]
      };
    case 'GET_USERS':
      return {
        ...state,
        loading: true,
        loaded: false
      };
    case 'GET_USERS_SUCCESS':
      // eslint-disable-next-line no-case-declarations
      const matchedUsers: User[] = setMatchTrue(action.result);
      return {
        ...state,
        loading: false,
        loaded: true,
        users: matchedUsers,
        filteredUsers: matchedUsers,
        roleFilter: "",
        statusFilter: ""
      };
    case 'GET_USERS_FAIL':
      return {
        ...state,
        loading: false,
        loaded: true,
        error: action.result
      };

    case 'GET_MANAGERS':
      return {
        ...state,
        loading: true,
        loaded: false,
        managersListLoading: true
      };
    case 'GET_MANAGERS_SUCCESS':
      return {
        ...state,
        loading: false,
        loaded: true,
        managers: action.result,
        managersListLoading: false
      };
    case 'GET_MANAGERS_FAIL':
      return {
        ...state,
        loading: false,
        loaded: true,
        error: action.result,
        managersListLoading: false
      };

    case 'GET_ALL_ROLES':
      return {
        ...state,
        allRoles: null
      };
    case 'GET_ALL_ROLES_SUCCESS':
      return {
        ...state,
        allRoles: action.result
      };
    case 'GET_ALL_ROLES_FAIL':
      return {
        ...state,
        allRoles: null
      };

    case 'USERS_FILTER':
      return {
        ...state,
        loading: false,
        loaded: true,
        filteredUsers: filterUsersFn(state, action.query)
      };

    case 'SAVE_USER':
      return {
        ...state,
        loading: true,
        loaded: false,
        error: null,
        managersListLoading: true
      };
    case 'SAVE_USER_SUCCESS':
      return {
        ...state,
        loading: false,
        loaded: true,
        managersListLoading: false
      };
    case 'SAVE_USER_FAIL':
      return {
        ...state,
        loading: false,
        loaded: true,
        error: action.result,
        managersListLoading: false
      };

    case 'FIND_USER':
      return {
        ...state,
        loading: true,
        loaded: false
      };
    case 'FIND_USER_SUCCESS':
      if (action.result[0] == null) {
        return {
          ...state,
          loading: false,
          loaded: true,
          userMatch: {}
        };
      }

      if (action.result[0].activationLinkExpiration) {
        activationLinkExpiration = action.result[0].activationLinkExpiration;
      }

      return {
        ...state,
        loading: false,
        loaded: true,
        userMatch: action.result,
        editUser: {
          /* eslint-disable @typescript-eslint/camelcase */
          first_name: action.result[0].first_name,
          last_name: action.result[0].last_name,
          /* eslint-disable @typescript-eslint/camelcase */
          email: action.result[0].email,
          userId: action.result[0].user_id,
          role: action.result[0].role,
          privilegeCode: action.result[0].privilege_code,
          isSelected: action.result[0].isSelected,
          activationLinkExpiration: activationLinkExpiration,
        }
      };
    case 'FIND_USER_FAIL':
      return {
        ...state,
        loading: false,
        loaded: true,
        error: action.result,
        userMatch: {},
        editUser: {}
      };
    case 'DEACTIVATE_USER':
      return {
        ...state
      };
    case 'DEACTIVATE_USER_SUCCESS':
      return {
        ...state
      };
    case 'DEACTIVATE_USER_FAIL':
      return {
        ...state,
        error: action.result
      };
    case 'OPEN_ADD_EDIT_MODAL':
      if (action.rowInfo && action.rowInfo.email) {
        fn = action.rowInfo.first_name;
        ln = action.rowInfo.last_name;
        userId = action.rowInfo.user_id;
        email = action.rowInfo.email;
        primarySubsite = action.rowInfo.primary_subsite;
        if (action.rowInfo.editingUser) {
          editingUser = action.rowInfo.editingUser;
        }
        role = action.rowInfo.role;
        privilegeCode = action.rowInfo.privilege_code;
        clientManager = action.rowInfo.clientManager;
        implementationManager = action.rowInfo.implementationManager;
        isSelected = action.rowInfo.isSelected;
        activationLinkExpiration =
          action.rowInfo.activationLinkExpiration && !isNaN(action.rowInfo.activationLinkExpiration)
            ? action.rowInfo.activationLinkExpiration
            : activationLinkExpiration;
      }

      return {
        ...state,
        showAddEditModal: true,
        userMatch: {},
        editUser: {
          first_name: fn,
          last_name: ln,
          email: email,
          role: role,
          privilegeCode: privilegeCode,
          userId: userId,
          primarySubsite: primarySubsite,
          editingUser: editingUser,
          clientManager: clientManager,
          implementationManager: implementationManager,
          isSelected,
          activationLinkExpiration: activationLinkExpiration
        }
      };
    case 'CLOSE_ADD_EDIT_MODAL':
      return {
        ...state,
        showAddEditModal: false
      };
    case 'OPEN_USER_DELETE_MODAL':
      return {
        ...state,
        deleteUser: action.users,
        deleteModal: true
      };
    case 'CLOSE_USER_DELETE_MODAL':
      return {
        ...state,
        deleteModal: false
      };
    case 'OPEN_INVITE_EDIT_USER_MODAL':
      if (action.rowInfo && action.rowInfo.email) {
        fn = action.rowInfo.first_name;
        ln = action.rowInfo.last_name;
        userId = action.rowInfo.user_id;
        email = action.rowInfo.email;
        primarySubsite = action.rowInfo.primary_subsite;
        if (action.rowInfo.editingUser) {
          editingUser = action.rowInfo.editingUser;
        }
        role = action.rowInfo.role;
        privilegeCode = action.rowInfo.privilege_code;
        clientManager = action.rowInfo.clientManager;
        implementationManager = action.rowInfo.implementationManager;
        isSelected = action.rowInfo.isSelected;
        activationLinkExpiration =
          action.rowInfo.activationLinkExpiration && !isNaN(action.rowInfo.activationLinkExpiration)
            ? action.rowInfo.activationLinkExpiration
            : activationLinkExpiration;
      }

      return {
        ...state,
        isInviteEditUserSlideoutOpen: true,
        userMatch: {},
        editUser: {
          first_name: fn,
          last_name: ln,
          email: email,
          role: role,
          privilegeCode: privilegeCode,
          userId: userId,
          primarySubsite: primarySubsite,
          editingUser: editingUser,
          clientManager: clientManager,
          implementationManager: implementationManager,
          isSelected,
          activationLinkExpiration: activationLinkExpiration
        }
      };
    case 'CLOSE_INVITE_EDIT_USER_MODAL':
      return {
        ...state,
        isInviteEditUserSlideoutOpen: false
      };
    case 'RESET_PASSWORD':
      return {
        ...state
      };
    case 'RESET_PASSWORD_SUCCESS':
      return {
        ...state
      };
    case 'RESET_PASSWORD_FAIL':
      return {
        ...state,
        error: action.result
      };
    case 'BECOME_USER':
      return {
        ...state
      };
    case 'BECOME_USER_SUCCESS':
      return {
        ...state
      };
    case 'BECOME_USER_FAIL':
      return {
        ...state,
        error: action.result
      };

    case 'UNLOCK_USER':
      return {
        ...state
      };
    case 'UNLOCK_USER_SUCCESS':
      return {
        ...state
      };
    case 'UNLOCK_USER_FAIL':
      return {
        ...state,
        error: action.result
      };

    case 'SET_SHOW_MANAGERS_PAY':
      return {
        ...state
      };
    case 'SET_SHOW_MANAGERS_PAY_SUCCESS':
      return {
        ...state,
        showManagersPay: action.result.show_managers_pay
      };
    case 'SET_SHOW_MANAGERS_PAY_FAIL':
      return {
        ...state,
        error: action.result
      };

    case 'GET_SHOW_MANAGERS_PAY':
      return {
        ...state
      };
    case 'GET_SHOW_MANAGERS_PAY_SUCCESS':
      return {
        ...state,
        showManagersPay: action.result.show_managers_pay
      };
    case 'GET_SHOW_MANAGERS_PAY_FAIL':
      return {
        ...state,
        error: action.result
      };
    case 'SET_DEFAULT_SITE':
      return {
        ...state
      };
    case 'SET_DEFAULT_SITE_SUCCESS':
      window.app.primary_subsite = action.result.primary_subsite;
      return {
        ...state
      };
    case 'SET_DEFAULT_SITE_FAILURE':
      return {
        ...state
      };
    case 'OPEN_INVITE_MANAGERS_SLIDEOUT':
      return {
        ...state,
        managersInviteSlideoutOpen: true
      };
    case 'CLOSE_INVITE_MANAGERS_SLIDEOUT':
      return {
        ...state,
        managersInviteSlideoutOpen: false
      }
    case 'RESEND_INVITE_FAIL':
      return {
        ...state,
        error: action.result
      };
    default:
      return { ...state };
  }
}

type DispatchFunction = (dispatch: Function, getState: Function) => void;

interface ResponseResult {
  success?: boolean;
}

export const actions: {
  getUsers: (query?: string) => void;
  closeDeleteModal: () => void;
  getManagers: () => void;
  [f: string]:
    (() => UserAction)
    | ((filterRole: string, filterStatus: string) => UserAction)
    | ((users: User[]) => UserAction | DispatchFunction | void)
    | ((checked: boolean) => UserAction)
    | ((selectedUser: User) => UserAction | DispatchFunction | void)
    | ((query: string) => UserAction | DispatchFunction | void)
    | ((rowInfo: User) => UserAction | DispatchFunction | void)
    | ((options: SaveUserParams) => UserAction | DispatchFunction | void)
    | ((managers: Manager[], sendLater: boolean) => UserAction | DispatchFunction | void);
} = {
      getUsers: () => {/* Other action creators call this */ },
      closeDeleteModal: () => {/* Other action creators call this */},
      getManagers: () => {/* Other action creators call this */ }
    };

actions.selectUser = function selectUser(selectedUser: User): UserAction {
  return {
      type: 'SELECT_USER',
      selectedUser
  };
};

actions.filterUsersByStatusOrRole =
  function filterUsersByStatusOrRole(filterRole: string, filterStatus: string): UserAction {
    return {
      type: 'FILTER_USERS_BY_STATUS_ROLE',
      filterRole,
      filterStatus
    };
  };

actions.toggleSelectAllUsers = function toggleSelectAllUsers(checked: boolean): UserAction {
  return {
      type: 'TOGGLE_SELECT_ALL_USERS',
      checked
  };
};

actions.openAddEditModal = function openAddEditModal(rowInfo: User): UserAction {
  return {
    type: 'OPEN_ADD_EDIT_MODAL',
    rowInfo
  };
};

actions.closeAddEditModal = function closeAddEditModal(): UserAction {
  return {
    type: 'CLOSE_ADD_EDIT_MODAL'
  };
};

actions.getUsers = function getUsers(query: string): DispatchFunction {
  if (query) {
    return (dispatch: Function): void => {
      dispatch({
        types: ['GET_USERS', 'GET_USERS_SUCCESS', 'GET_USERS_FAIL'],
        promise: (client: AxiosInstance) => client.get('/api/generic-user/users/list')
        .then(() => {
          dispatch({
            type: 'USERS_FILTER',
            query: query
          });
        })
        .catch((err: string) => {
          console.error('Error listing users', err);
        })
      });
    };
  }

  return (dispatch: Function): void => {
    dispatch({
      types: ['GET_USERS', 'GET_USERS_SUCCESS', 'GET_USERS_FAIL'],
      promise: (client: AxiosInstance) => client.get('/api/generic-user/users/list')
    });
  };
};

actions.getManagers = function getManagers() {
  return (dispatch: Function): void => {
    dispatch({
      types: ['GET_MANAGERS', 'GET_MANAGERS_SUCCESS', 'GET_MANAGERS_FAIL'],
      promise: (client: AxiosInstance) => client.get('/api/generic-user/users/getManagers')
    });
  };
};

actions.getAllRoles = function getRoles() {
  return (dispatch: Function): void => {
    dispatch({
      types: ['GET_ALL_ROLES', 'GET_ALL_ROLES_SUCCESS', 'GET_ALL_ROLES_FAIL'],
      promise: (client: AxiosInstance) => client.get('/api/generic-user/privileges/list')
    });
  };
};

actions.resetPassword = function resetPassword(options: object) {
  return (dispatch: Function): void => {
    dispatch({
      types: ['RESET_PASSWORD', 'RESET_PASSWORD_SUCCESS', 'RESET_PASSWORD_FAIL'],
      promise: (client: AxiosInstance) => client
        .get('/api/generic-user/users/resetPassword', { params: options })
        .then(() => {
          dispatch(actions.getUsers());
        })
        .catch((err: string) => {
          console.error('Error resetting password for user', err);
        })
    });
  };
};

actions.resendInvite = function resendInvite(invites: User[]): object {
  return (dispatch: Function): void => {
    dispatch({
      types: ['RESEND_INVITE', 'RESEND_INVITE_SUCCESS', 'RESEND_INVITE_FAIL'],
      promise: (client: AxiosInstance) => client
        .post('/api/generic-user/users/resendInvites', { params: { invites } })
        .then(() => {
          dispatch(actions.getUsers());
        })
        .catch((err: string) => {
          console.error('Error resending invites', err);
        })
    });
  };
};

actions.unlockUser = function unlockUser(options: object) {
  return (dispatch: Function): void => {
    dispatch({
      types: ['UNLOCK_USER', 'UNLOCK_USER_SUCCESS', 'UNLOCK_USER_FAIL'],
      promise: (client: AxiosInstance) => client
        .get('/api/generic-user/users/unlockUser', { params: options })
        .then(() => {
          dispatch(actions.getUsers());
        })
        .catch((err: string) => {
          console.error('Error unlocking user', err);
        })
    });
  };
};

actions.becomeUser = function becomeUser(options: object) {
  return (dispatch: Function): void => {
    // block rendering until redirect
    dispatch({ type: 'BLOCKING_ACTION_BEGIN', explanation: 'Switching user accounts' });

    dispatch({
      types: ['BECOME_USER', 'BECOME_USER_SUCCESS', 'BECOME_USER_FAIL'],
      promise: (client: AxiosInstance) => client
        .get('/api/generic-user/users/becomeUser', { params: options })
        .then((result: AxiosResponse & { success: boolean }) => {
          if (result.success) {
            window.location.href = './';
          }
        })
        .catch((error: string) => {
          console.warn('becomeUser failed', error);
          // unblock rendering to resume where we were
          // XXX this may remount components that didn't expect to be unmounted
          dispatch({ type: 'BLOCKING_ACTION_END' });
        })
    });
  };
};

actions.deactivateConfirmModal = function deactivateConfirmModal(users: User[]): UserAction {
  return {
    type: 'OPEN_USER_DELETE_MODAL',
    users
  };
};

actions.closeDeleteModal = function closeDeleteModal(): UserAction {
  return {
    type: 'CLOSE_USER_DELETE_MODAL'
  };
};

type LabResponse = AxiosResponse & { success: boolean };

actions.deactivateUsers = function deactivateUsers(users: User[]) {
  return async (dispatch: Function): Promise<void> => {
    dispatch({
      types: ['DEACTIVATE_USER', 'DEACTIVATE_USER_SUCCESS', 'DEACTIVATE_USER_FAIL'],
      promise: async (client: AxiosInstance): Promise<void> => {
        const allPromises: AxiosPromise<LabResponse>[] = [];
        const usersToDelete = users.filter((user: User) => user.user_id !== user.invite_token && !user.clientManager && !user.implementationManager);
        const invitesToDelete = users.filter((user: User) => user.invite_token);

        try {
          usersToDelete.forEach((user: User) => allPromises.push(
            client.post('/api/generic-user/users/deactivateUser', {
              params: {
                user_id: user.user_id,
                username: user.email
              }
            })
          ));

          invitesToDelete.forEach((user: User) => allPromises.push(
            client.post('/api/generic-user/users/deleteInvite', {
              params: {
                token: user.invite_token
              }
            })
          ));

          const results = await Promise.all(allPromises) as LabResponse[];
          if (results.some(r => r.success)) {
            dispatch(actions.getUsers());
            dispatch(actions.closeDeleteModal());
          } else {
            dispatch({ type: 'DEACTIVATE_USER_FAIL', result: 'Deactivate User Failed' });
          }
        } catch (err) {
          console.error('Error deactivating user', err);
        }
      }
    });
  };
};

actions.bulkCreateManagerUsers = function bulkCreateManagerUsers(managers: Manager[], sendLater: boolean) {
  return (dispatch: Function): void => {
    const activationLinkExpiration: number = ActivationLinkExpirationInDays * 60 * 60 * 24; // 30 days
    const saveUserCalls: Array<(client: AxiosInstance) => AxiosPromise<LabResponse>> = [];
    managers.forEach(manager => {
      const options: SaveUserParams = {
        email: manager.email,
        firstName: manager.first_name,
        lastName: manager.last_name,
        activationLinkExpiration,
        role: RoleIds.Manager,
        sendEmail: !sendLater
      };
      const saveUserCall = (client: AxiosInstance): AxiosPromise<LabResponse> => client.post('/api/generic-user/users/saveUser', { params: options });
      saveUserCalls.push(saveUserCall);
    });

    const batchedCalls: Array<(client: AxiosInstance) => Promise<LabResponse[]>> = [];

    for (let index = 0; index < saveUserCalls.length; index += 5) {
      const batch = (client: AxiosInstance): Promise<LabResponse[]> =>
        Promise.all(
          saveUserCalls.slice(index, index+5)
          .map(async (saveUserCall) => (await saveUserCall(client)) as LabResponse));
      batchedCalls.push(batch);
    }

    dispatch({
      types: ['SAVE_USER', 'SAVE_USER_SUCCESS', 'SAVE_USER_FAIL'],
      promise:
      async (client: AxiosInstance): Promise<void> => {

        const allResults: boolean[] = [];
         for(let i = 0; i < batchedCalls.length; i++) {
           try {
            const batchResults = await batchedCalls[i](client);
            allResults.push(...batchResults.map(p => p.success));
           }
           catch (err) {
             console.log('Error creating users', err)
           }
         }
         if (allResults.some(r => r)) {
          dispatch(actions.getManagers());
          dispatch(actions.getUsers());
         } else {
          dispatch({ type: 'SAVE_USER_FAIL', result: 'Save User Failed' });
         }
      }
    });
  }
};

actions.saveUser = function saveUser(options: SaveUserParams) {
  return (dispatch: Function, getState: Function): void => {
    const userMatch = getState().userAdmin.userMatch;
    const editUser = getState().userAdmin.editUser;

    if (editUser.userId && editUser.userId != '' && userMatch && !userMatch[0]) {
      const params = {
        fn: options.firstName.trim(),
        ln: options.lastName.trim(),
        //  eslint-disable-next-line
        // @ts-ignore
        userId: options.userId,
        role: options.role,
        activationLinkExpiration: options.activationLinkExpiration,
        sendEmail: options.sendEmail
      };

      dispatch({
          types: ['PROFILE', 'PROFILE_SUCCESS', 'PROFILE_FAILURE'],
          promise: (client: AxiosInstance) => client
            .post('/api/util/profile/updateProfile', { params: params })
            .then((result: AxiosResponse & { success: boolean } ) => {
              if (result.success) {
                dispatch({ type: 'CLOSE_INVITE_EDIT_USER_MODAL' });
                dispatch(actions.getUsers());
              } else {
                dispatch({ type: 'SAVE_USER_FAIL', result: 'Save User Failed' });
              }
            })
            .catch((err: string) => {
              console.error('Error saving user', err);
            })
      });
    } else {
      dispatch({
        types: ['SAVE_USER', 'SAVE_USER_SUCCESS', 'SAVE_USER_FAIL'],
        promise: (client: AxiosInstance) => client.post('/api/generic-user/users/saveUser', { params: options })
          .then((result: AxiosResponse & { success: boolean } ) => {
            if (result.success) {
              dispatch({ type: 'CLOSE_INVITE_EDIT_USER_MODAL' });
              dispatch(actions.getUsers());
            } else {
              dispatch({ type: 'SAVE_USER_FAIL', result: 'Save User Failed' });
            }
          })
          .catch((err: string) => {
            console.error('Error saving user', err);
          })
      });
    }
  };
};

actions.findUser = function findUser(options: object) {
  return (dispatch: Function): void => {
    dispatch({
      types: ['FIND_USER', 'FIND_USER_SUCCESS', 'FIND_USER_FAIL'],
      promise: (client: AxiosInstance) => client.get('/api/generic-user/users/findUser', { params: options })
    });
  };
};

actions.filterUsers = function filterUsers(query: string): UserAction {
  return {
    type: 'USERS_FILTER',
    query: query
  };
};

actions.setDefaultSite = function setDefaultSite(options: object) {
  return (dispatch: Function): void => {
    dispatch({
      types: ['SET_DEFAULT_SITE', 'SET_DEFAULT_SITE_SUCCESS', 'SET_DEFAULT_SITE_FAILURE'],
      promise: (client: AxiosInstance) => client.post('/api/generic-user/users/setPrimarySubSite', { params: options })
    });
  };
};

actions.setShowManagersPay = function setShowManagersPay(options: object) {
  return (dispatch: Function): void => {
    dispatch({
      types: ['SET_SHOW_MANAGERS_PAY', 'SET_SHOW_MANAGERS_PAY_SUCCESS', 'SET_SHOW_MANAGERS_PAY_FAIL'],
      promise: (client: AxiosInstance) => client.get('/api/generic-user/users/setShowManagersPay', { params: options })
    });
  };
};

actions.getShowManagersPay = function getShowManagersPay() {
  return (dispatch: Function): void => {
    dispatch({
      types: ['GET_SHOW_MANAGERS_PAY', 'GET_SHOW_MANAGERS_PAY_SUCCESS', 'GET_SHOW_MANAGERS_PAY_FAIL'],
      promise: (client: AxiosInstance) => client.get('/api/generic-user/users/getShowManagersPay')
    });
  };
};

actions.openInviteEditUserSlideOut = function openInviteEditUserSlideOut(rowInfo: User): UserAction {
  return {
    type: 'OPEN_INVITE_EDIT_USER_MODAL',
    rowInfo
  };
};

actions.closeInviteEditUserSlideOut = function closeInviteEditUserSlideOut(): UserAction {
  return {
    type: 'CLOSE_INVITE_EDIT_USER_MODAL'
  };
};
actions.openInviteManagersSlideout = function openInviteManagersSlideout(): UserAction {
  return {
    type: 'OPEN_INVITE_MANAGERS_SLIDEOUT'
  };
}

actions.closeInviteManagersSlideout = function closeInviteManagersSlideout(): UserAction {
  return {
    type: 'CLOSE_INVITE_MANAGERS_SLIDEOUT'
  };
}

export const userAdminActions = mapDispatchToActions(actions);
