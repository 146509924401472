import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FormGroup, Dropdown, MenuItem, InputGroup, Button } from 'react-bootstrap/lib';

// This is the universal search bar used in the header

export default class SearchBar extends Component {
  static propTypes = {
    doUniversalSearch: PropTypes.func,
    setSearchResultVisibility: PropTypes.func,
    setSearchValue: PropTypes.func,
    universalSearch: PropTypes.object,
    resetSearchResults: PropTypes.func,
    jobsPageSearch: PropTypes.func,
    searchResultsFocusRow: PropTypes.func,
    focusRow: PropTypes.func,
    history: PropTypes.object,
    showFullSearch: PropTypes.bool,
    showFullSearchStyles: PropTypes.bool,
    closeFullSearchBar: PropTypes.func
  };

  constructor(props) {
    super(props);
    this.state = {
      searchType: 'all',
      border: 'gray'
    };

    this.showModal = this.showModal.bind(this);
    this.searchBarKeyPress = this.searchBarKeyPress.bind(this);
    this.clickMenu = this.clickMenu.bind(this);
    this.clearSearch = this.clearSearch.bind(this);
    this.fullSearchClick = this.fullSearchClick.bind(this);
    this.handleSearchClick = this.handleSearchClick.bind(this);
    this.getPlaceholder = this.getPlaceholder.bind(this);
    this.getClasses = this.getClasses.bind(this);
    this.setBorder = this.setBorder.bind(this);
    this.clickDropdown = this.clickDropdown.bind(this);
    this.changeInput = this.changeInput.bind(this);
  }

  componentDidMount() {
    if (document && this.props.showFullSearch) {
      const input = document.getElementById('headerFullSearchInput');
      if (input) setTimeout(() => input.focus(), 500);
    }
  }

  getClasses(searchType) {
    for (let idx = 0; idx < this.props.universalSearch.searchTypes.length; idx++) {
      const currSearchType = this.props.universalSearch.searchTypes[idx];
      if (searchType === currSearchType.doc_type) {
        return currSearchType.icon;
      }
    }
    return 'fa fa-bars';
  }

  getPlaceholder(searchType) {
    for (let idx = 0; idx < this.props.universalSearch.searchTypes.length; idx++) {
      const currSearchType = this.props.universalSearch.searchTypes[idx];
      if (searchType === currSearchType.doc_type && searchType !== 'all') {
        return 'Search for ' + currSearchType.doc_type + '...';
      }
    }
    return this.props.showFullSearchStyles
      ? 'Search for employees, jobs, reports and more'
      : 'Search for everything...';
  }

  setBorder(color) {
    if (color === 'gray') {
      this.setState({ border: 'gray' });
    } else {
      this.setState({ border: 'blue' });
    }
  }

  initCap(str) {
    if (str === 'users') {
      return 'System Users';
    }
    if (str === 'dashboard') {
      return 'Analytics';
    }
    return str.toLowerCase().replace(/(?:^|\s)[a-z]/g, function strInitFn(strInit) {
      return strInit.toUpperCase();
    });
  }

  fullSearchClick() {
    const searchTerm = this.props.universalSearch.query;
    if (!searchTerm) {
      return;
    }

    this.universalSearch(searchTerm);
  }

  universalSearch(searchTerms) {
    if (/^\s*$/.test(searchTerms)) return;

    this.props.doUniversalSearch({
      params: {
        es_query: searchTerms,
        category: this.state.searchType
      }
    });

    // probably have to change focus to the input here
    this.showModal();
  }

  handleSearchClick(event) {
    if (event && event.target) {
      const val = event.target.value;
      if (val.length >= 2) {
        this.showModal();
      }
    }
  }

  changeInput(event) {
    const value = event.currentTarget.value;
    this.props.setSearchValue(value);
  }

  searchBarKeyPress(event) {
    const value = event.currentTarget.value;
    const fullValue = value + event.key;

    if (event.key === 'Escape') this.props.closeFullSearchBar();

    if (event.key === 'ArrowDown' || event.key === 'ArrowUp') {
      let newIdx = 1;
      if (
        (this.props.universalSearch.focusRowIdx === null || this.props.universalSearch.focusRowIdx === undefined) &&
        event.key === 'ArrowDown'
      ) {
        newIdx = 1;
      } else if (event.key === 'ArrowDown') {
        newIdx = this.props.universalSearch.focusRowIdx + 1;
        if (newIdx === this.props.universalSearch.items.rowCount) {
          // get length of search results from reducers store and set to null if currently on last entry (move to top)
          newIdx = null;
        }
      } else if (this.props.universalSearch.focusRowIdx === 1 && event.key === 'ArrowUp') {
        newIdx = null;
      } else if (
        (this.props.universalSearch.focusRowIdx === null || this.props.universalSearch.focusRowIdx === undefined) &&
        event.key === 'ArrowUp'
      ) {
        // get length of search results from reducers store and set newIdx to last entry
        newIdx = this.props.universalSearch.items.rowCount;
      } else if (event.key === 'ArrowUp') {
        newIdx = this.props.universalSearch.focusRowIdx - 1;
      }

      this.props.searchResultsFocusRow(newIdx);
      return;
    }

    if (
      event.key === 'Shift' ||
      event.key === 'Tab' ||
      event.key === 'Alt' ||
      event.key === 'Meta' ||
      event.key === 'CapsLock' ||
      event.key === 'ArrowLeft' ||
      event.key === 'ArrowRight' ||
      (event.key.indexOf('F') === 0 && event.key.length === 2) ||
      event.key === 'Escape'
    ) {
      return;
    }

    if (event.key === 'Enter') {
      if (/^\s*$/.test(value)) return;
      const link = this.props.universalSearch.focusRowLink;
      if (link.match('#')) {
        window.location = window.app.apiPrefix + link;
      } else if (link !== '') {
        this.props.history.push(link);
        this.clearSearch();
        if (this.props.closeFullSearchBar) this.props.closeFullSearchBar();
      }
      return;
    }

    if (event.key === 'Backspace' && value.length > 0) {
      this.universalSearch(value.substring(0, value.length - 1));
      return;
    }

    if (fullValue.length > 1) {
      this.universalSearch(fullValue);
    }
  }

  showModal() {
    this.props.setSearchResultVisibility(true);
  }

  clickDropdown() {
    this.setBorder('blue');
  }

  clearSearch() {
    this.props.resetSearchResults();
  }

  clickMenu(event) {
    const element = event.currentTarget;

    const selectedType = element.getAttribute('data-key');
    this.setState({ searchType: selectedType });
  }

  render() {
    const ph = this.getPlaceholder(this.state.searchType);
    const submenuIcon = this.getClasses(this.state.searchType);
    const { showFullSearchStyles } = this.props;

    let focusCls = '';
    if (this.state.border === 'blue') {
      focusCls = 'searchbar__active';
    } else {
      focusCls = '';
    }

    const clearSearchActive = this.props.universalSearch.query !== '';

    const CategoryDropDown = (
      <Dropdown componentClass={InputGroup.Button} className="inputGroup__btn--dropdown" id="input-dropdown-addon">
        <Dropdown.Toggle className={'btn__dropdown--category ' + focusCls} onClick={this.clickDropdown}>
          <i className={submenuIcon} />
        </Dropdown.Toggle>

        <Dropdown.Menu className="dropdown__menu--category">
          <MenuItem data-key="all" onClick={this.clickMenu}>
            <i className={this.getClasses('all')} /> All
          </MenuItem>
          {this.props.universalSearch.searchTypes.map(searchType => {
            return (
              <MenuItem data-key={searchType.doc_type} key={searchType.doc_type} onClick={this.clickMenu}>
                <i className={this.getClasses(searchType.doc_type)} />
                {this.initCap(searchType.doc_type)}
              </MenuItem>
            );
          })}
        </Dropdown.Menu>
      </Dropdown>
    );

    const FullSearchIcon = showFullSearchStyles ? (
      <span className="fa fa-search fa-2 searchIcon" onClick={this.fullSearchClick} />
    ) : null;

    return (
      <FormGroup className={'formGroup--searchbar' + (showFullSearchStyles ? ' fullSearch' : '')}>
        <InputGroup>
          {showFullSearchStyles ? null : CategoryDropDown}
          <div className="btn-group inputGroup--searchInput">
            {FullSearchIcon}
            <input
              className={'inputGroup__input--search ' + focusCls}
              type="text"
              placeholder={ph}
              onChange={this.changeInput}
              onKeyDown={this.searchBarKeyPress.bind(this)}
              onClick={this.handleSearchClick.bind(this)}
              value={this.props.universalSearch.query}
              id="headerFullSearchInput"
            />
            {(() => {
              if (!clearSearchActive && !showFullSearchStyles) {
                return (
                  <span
                    className="fa fa-search fa-2 searchInput__btn--search searchClick"
                    onClick={this.fullSearchClick}
                  />
                );
              }
              if (clearSearchActive && !showFullSearchStyles) {
                return <span className="fa fa-times fa-2 searchInput__btn--close" onClick={this.clearSearch} />;
              }
            })()}
          </div>
        </InputGroup>
      </FormGroup>
    );
  }
}
