import apiClient from '../../../lib/apiClient';

const ADD_SET = 'ADD_SET';
const CANCEL_DELETE_ALERT_MODAL = 'CANCEL_DELETE_ALERT_MODAL';
const CHANGE_SET_VALUE = 'CHANGE_SET_VALUE';
const CLOSE_EDIT = 'CLOSE_EDIT';
const DELETE_ALERT_MODAL = 'DELETE_ALERT_MODAL';
const DELETE_SET = 'DELETE_SET';
const DELETE_SET_SUCCESS = 'DELETE_SET_SUCCESS';
const DELETE_SET_FAIL = 'DELETE_SET_FAIL';
const EDIT_SET = 'EDIT_SET';
const GET_COMP_SET = 'GET_COMP_SET';
const GET_COMP_SET_FAIL = 'GET_COMP_SET_FAIL';
const GET_COMP_SET_SUCCESS = 'GET_COMP_SET_SUCCESS';
const GET_INDUSTRY_OPT = 'GET_INDUSTRY_OPT';
const GET_INDUSTRY_OPT_FAIL = 'GET_INDUSTRY_OPT_FAIL';
const GET_INDUSTRY_OPT_SUCCESS = 'GET_INDUSTRY_OPT_SUCCESS';
const GET_ORG_TYPE = 'GET_ORG_TYPE';
const GET_ORG_TYPE_FAIL = 'GET_ORG_TYPE_FAIL';
const GET_ORG_TYPE_SUCCESS = 'GET_ORG_TYPE_SUCCESS';
const SAVE_COMP_SET = 'SAVE_COMP_SET';
const SAVE_COMP_SET_FAIL = 'SAVE_COMP_SET_FAIL';
const SAVE_COMP_SET_SUCCESS = 'SAVE_COMP_SET_SUCCESS';
const GET_COMP_SET_COUNT = 'GET_COMP_SET_COUNT';
const GET_COMP_SET_COUNT_FAILURE = 'GET_COMP_SET_COUNT_FAILURE';
const GET_COMP_SET_COUNT_SUCCESS = 'GET_COMP_SET_COUNT_SUCCESS';

const initialState = {
  currentEditSet: false,
  loaded: false,
  loading: false,
  count: 0,
  items: [],
  orgTypes: [],
  saving: false,
  showDeleteAlert: false,
  validation: { isValid: false, noChange: true }
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case ADD_SET:
      return {
        ...state,
        currentEditSet: {
          job_family_list: action.list
        }
      };
    case CANCEL_DELETE_ALERT_MODAL:
      return { ...state, compSetDataToDelete: {}, showDeleteAlert: false };
    case CHANGE_SET_VALUE:
      const newCurrentEditSet = {
        ...state.currentEditSet,
        [action.info.name]: action.info.value
      };

      return {
        ...state,
        currentEditSet: newCurrentEditSet,
        validation: validateFormFields(newCurrentEditSet)
      };
    case CLOSE_EDIT:
      if (state.saving) {
        return state;
      }
      return {
        ...state,
        currentEditSet: false,
        validation: { isValid: false, noChange: true }
      };
    case DELETE_ALERT_MODAL:
      return {
        ...state,
        compSetDataToDelete: { ...action.setData },
        showDeleteAlert: true
      };
    case DELETE_SET:
      return {
        ...state,
        compSetDataToDelete: {},
        items: state.items.filter(compSet => compSet.competitive_set_pk !== action.setData.competitive_set_pk),
        showDeleteAlert: false
      };
    case GET_COMP_SET:
      return {
        ...state,
        loaded: false,
        loading: true
      };
    case DELETE_SET_SUCCESS:
      if (!action.result.success) {
        const newItems = [...state.items];
        newItems.splice(action.setIndex, 0, action.setData);
        return {
          ...state,
          items: newItems
        };
      }
      return state;
    case EDIT_SET:
      return {
        ...state,
        currentEditSet: { ...action.setData }
      };
    case GET_COMP_SET_SUCCESS:
      return {
        ...state,
        items: action.result,
        count: action.result?.length,
        loaded: true,
        loading: false
      };
    case GET_INDUSTRY_OPT_SUCCESS:
      action.callback(action.result);
      return { ...state };
    case GET_ORG_TYPE_SUCCESS:
      return { ...state, orgTypes: action.result };
    case SAVE_COMP_SET:
      return {
        ...state,
        saving: true
      };
    case SAVE_COMP_SET_FAIL:
      return {
        ...state,
        saving: false
      };
    case SAVE_COMP_SET_SUCCESS:
      if (action.result && action.result.success === false) return state;
      if (state.currentEditSet.competitive_set_pk) {
        return {
          ...state,
          items: state.items.map(item => {
            if (item.competitive_set_pk === action.result.competitive_set_pk) {
              return { ...action.setData };
            }
            return { ...item };
          }),
          saving: false
        };
      }

      return {
        ...state,
        currentEditSet: {
          ...state.currentEditSet,
          competitive_set_pk: action.result.competitive_set_pk,
          industry: action.setData.industry
        },
        items: [
          ...state.items,
          {
            ...state.currentEditSet,
            competitive_set_pk: action.result.competitive_set_pk,
            industry: action.setData.industry
          }
        ],
        saving: false
      };
    case GET_COMP_SET_COUNT:
      return {
        ...state,
        loading: true
      };
    case GET_COMP_SET_COUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        count: action.result.count
      };
    case GET_COMP_SET_COUNT_FAILURE:
      return {
        ...state,
        loading: false
      };
    default:
      return {
        ...state
      };
  }
}

const validateFormFields = newCurrentEditSet => {
  const validationResult = { isValid: true };
  [
    'company_type',
    'employees_count',
    'total_revenue',
    'industry',
    'government_contractor',
    'competitive_set_name',
    'default_job_families'
  ].forEach(key => {
    let validation;
    const value = newCurrentEditSet[key] || '';

    // no decimals
    if (typeof value === 'number' && value % 1 != 0) {
      validation = 'error';
    }

    // make sure compSet name has some value
    if (key === 'competitive_set_name' && (value === '' || !value)) {
      validation = 'error';
    }

    // not valid unless all is success
    if (validation === 'error') validationResult.isValid = false;
    validationResult[key] = validation;
  });
  return validationResult;
};

export function addSet(list) {
  return {
    type: ADD_SET,
    list
  };
}

export function cancelDeleteAlert() {
  return {
    type: CANCEL_DELETE_ALERT_MODAL
  };
}

export function changeSetValue(info) {
  return (dispatch, getStore) => {
    const { competitiveSets } = getStore();
    const oldValue = competitiveSets.currentEditSet[info.name];
    if (oldValue === info.value) return;

    dispatch({
      type: CHANGE_SET_VALUE,
      info
    });
  };
}

export function closeEditSet() {
  return {
    type: CLOSE_EDIT
  };
}

export function deleteAlert(setData) {
  return {
    type: DELETE_ALERT_MODAL,
    setData
  };
}

export function deleteSet(setData) {
  return async (dispatch, getStore) => {
    const setIndex = undefined;
    try {
      const data = { ...setData };
      const setIndex = getStore()
        .competitiveSets.items.map((compSet, index) => ({ ...compSet, index }))
        .filter(compSet => compSet.competitive_set_pk === data.competitive_set_pk)[0].index;
      dispatch({ type: DELETE_SET, setData, setIndex });
      const result = await apiClient.apiPost('/api/hris/competitive-sets/delete', data);

      dispatch({
        type: DELETE_SET_SUCCESS,
        result: result.data,
        setData,
        setIndex
      });
    } catch (error) {
      dispatch({ type: DELETE_SET_FAIL, error: error, setData, setIndex });
    }
  };
}

export function editSet(setData) {
  return dispatch => {
    dispatch(getOrgType());
    dispatch({
      type: EDIT_SET,
      setData
    });
  };
}

export function getCompSetsCount() {
  return async dispatch => {
    try {
      dispatch({ type: GET_COMP_SET_COUNT });
      const result = await apiClient.apiPost('/api/hris/competitive-sets/count');
      dispatch({
        type: GET_COMP_SET_COUNT_SUCCESS,
        result: result.data
      });
    } catch (error) {
      dispatch({ type: GET_COMP_SET_COUNT_FAILURE, error });
    }
  };
}

export function getCompSets() {
  return async dispatch => {
    try {
      dispatch({ type: GET_COMP_SET });
      const result = await apiClient.apiPost('/api/hris/competitive-sets/list', { params: {} });
      dispatch({
        type: GET_COMP_SET_SUCCESS,
        result: result.data
      });
    } catch (error) {
      dispatch({ type: GET_COMP_SET_FAIL, error: error });
    }
  };
}

export function getIndustryOptions(loadOptions) {
  return async dispatch => {
    if (loadOptions && loadOptions.params && loadOptions.params.industrySearchTerm) {
      loadOptions.params.industrySearchTerm = loadOptions.params.industrySearchTerm.trim();
    }
    const options = { params: loadOptions.params };
    try {
      dispatch({ type: GET_INDUSTRY_OPT, callback: loadOptions.searchCallback });
      const result = await apiClient.apiGet('/api/payscale/jobalyzerReport/industrySearch', options);

      dispatch({
        type: GET_INDUSTRY_OPT_SUCCESS,
        result: result.data,
        callback: loadOptions.searchCallback
      });
    } catch (error) {
      dispatch({ type: GET_INDUSTRY_OPT_FAIL, error: error, callback: loadOptions.searchCallback });
    }
  };
}

export function getOrgType() {
  return async dispatch => {
    try {
      const options = { params: { questionId: 'OrganizationType' } };
      dispatch({ type: GET_ORG_TYPE });
      const result = await apiClient.apiGet('/api/payscale/jobalyzerReport/getQuestionAnswer', options);

      dispatch({ type: GET_ORG_TYPE_SUCCESS, result: result.data });
    } catch (error) {
      dispatch({ type: GET_ORG_TYPE_FAIL, error: error });
    }
  };
}

export function saveSet(currentEditSet, validation) {
  return async dispatch => {
    if (!validation.isValid && validation.noChange === true) {
      dispatch({ type: CLOSE_EDIT });
      return;
    } else if (!validation.isValid) {
      return;
    }

    if (!currentEditSet.competitive_set_name) currentEditSet.competitive_set_name = 'Competitive Set Name';
    const data = {
      ...currentEditSet,
      last_modifying_user_id: window.app.userId,
      creator_user_id: window.app.userId
    };

    try {
      dispatch({ type: SAVE_COMP_SET, setData: data });
      const result = await apiClient.apiPost('/api/hris/competitive-sets/save', data);
      dispatch({
        type: SAVE_COMP_SET_SUCCESS,
        result: result.data,
        setData: data
      });
      dispatch({ type: CLOSE_EDIT });

      return result.data;
    } catch (error) {
      dispatch({ type: SAVE_COMP_SET_FAIL, error: error, setData: data });
      return;
    }
  };
}
