import React, { Fragment, useEffect, useState } from 'react';
import { array, object, func, string } from 'prop-types';
import AdvancedRow from './AdvancedRow';
import './AdvancedTable.scss';
import { useTable } from 'react-table';
import { getTransformedColumns } from './GenericGrid/GenericGridFunctions';

function AdvancedSubRows(props) {
  if (!props.subRowData) {
    return <div className={props.className} />;
  }

  const subRowColumnsHeaders = getTransformedColumns(
    props.subRowData.termGroups,
    props.subRowData.terms,
    props.subRowData.data,
    props.defaultLeftColumns,
    props.defaultRightColumns,
    props.termsColumnWidthOverride
  );

  // Ignore any user-defined-fields in survey rows, so the columns match the slotted rows
  // because currently, the ported slotted view is not picking up UDFs
  const hiddenColumns =
    props.hiddenColumns?.concat(
      subRowColumnsHeaders
        ?.filter(
          header =>
            header.id?.endsWith('_default') ||
            header.id?.endsWith('_null') ||
            (!props.showUdfs && header.id?.includes('udf'))
        )
        .map(header => header.id)
    ) || [];

  const { rows, prepareRow } = useTable(
    {
      columns: subRowColumnsHeaders,
      data: props.subRowData.data,
      options: props.options,
      autoResetHiddenColumns: false, // Important! must be false to avoid infinite rerender
      autoResetResize: false, // Important! must be false to avoid infinite rerender
      initialState: { hiddenColumns }
    },
    ...props.plugins
  );

  const subRowComponents = rows.map((subRow, index) => {
    prepareRow(subRow);

    return (
      <AdvancedRow
        getRowProps={subRow.getRowProps}
        data={subRow}
        onClick={null}
        className={`${props.rowClassName} sub-row`}
        dataRowKey={`${props.mainRow.id}-${subRow.id}`}
        key={`${props.mainRow.id}-${subRow.id}`}
      >
        <Fragment>
          {props.getDataRow(subRow, subRow.id, {
            ...props.meta,
            isSubRow: true,
            isLastSubRow: index === rows.length - 1,
            getComment: () => props.commentComponent(props.mainRow)
          })}
        </Fragment>
      </AdvancedRow>
    );
  });

  return (
    <div className={props.className}>
      {subRowComponents}

      {props.getShowMoreLink && props.getShowMoreLink(props.mainRow)}
    </div>
  );
}

AdvancedSubRows.propTypes = {
  className: string,
  commentComponent: func,
  defaultLeftColumns: array,
  defaultRightColumns: array,
  getDataRow: func,
  getShowMoreLink: func,
  hiddenColumns: array,
  mainRow: object,
  meta: object,
  options: object,
  plugins: array,
  rowClassName: string,
  rowIndexPrefix: string,
  subRowData: object,
  subRows: array
};

export default AdvancedSubRows;
