import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ListRowChildren from './ListRowChildren';

export default class ListRow extends Component {
  static propTypes = {
    item: PropTypes.object,
    selectedListRows: PropTypes.array,
    rowCheckBoxClick: PropTypes.func,
    rowClick: PropTypes.func,
    columns: PropTypes.array,
    selectMultiple: PropTypes.bool,
    index: PropTypes.number,
    hideCheckBox: PropTypes.bool,
    fieldTypeValueFormatters: PropTypes.object,
    fieldTypeClassFormatters: PropTypes.object,
    idProp: PropTypes.string,
    testIdProp: PropTypes.string,
    getCustomItemClassName: PropTypes.func,
    isDisabled: PropTypes.bool
  };

  getColData(col, item) {
    const keys = [].concat(col.dataKeyRef ? col.dataKeyRef(item) : col.dataKey);

    const data = keys.map(key => {
      const formatter = this.props.fieldTypeValueFormatters[col.field_type_code];
      if (formatter) return formatter(item, item[key]);
      return item[key];
    });

    const value = data.reduce((prev, curr) => (prev ? prev + ' - ' + curr : null));
    return value !== ' ' ? value : null;
  }

  select(item) {
    this.props.rowCheckBoxClick(item);
  }

  rowClick(item, event) {
    if (event.target.tagName === 'INPUT' || event.target.tagName === 'LABEL') {
      return;
    }
    if (this.props.rowClick) this.props.rowClick(item);
  }

  render() {
    const { item, selectedListRows, isDisabled } = this.props;
    const itemId = item[this.props.idProp || '_id'];
    const testId = item[this.props.testIdProp];

    const isSelected = selectedListRows.indexOf(itemId) !== -1;

    let checkbox = (
      <td className="checkbox">
        <input
          className={isDisabled ? null : 'pointer'}
          type="checkbox"
          id={'grid-row-' + itemId}
          checked={isSelected}
          onChange={isDisabled ? null : this.select.bind(this, item)}
          disabled={isDisabled}
        />
        <label htmlFor={'grid-row-' + item._id} />
      </td>
    );

    if (this.props.hideCheckBox) checkbox = <td className="checkbox" />;

    return (
      <tr
        className={
          'table__body--row' + (isSelected ? ' selected' : '') + (isDisabled ? ' table__body--row-disabled' : '')
        }
        id={itemId}
        onClick={isDisabled ? null : this.rowClick.bind(this, item)}
        data-row-test-id={testId}
      >
        {checkbox}
        {this.props.columns.map((col, index) => {
          const isPrimaryCol = index === 0;
          const itemDataClass = isPrimaryCol ? 'gridItem__title' : 'gridItem__value--pay ' + 'index-' + index;
          const data = this.getColData(col, item);

          return (
            <td key={col.title} className={index}>
              <h4 className={itemDataClass}>{data}</h4>
              <ListRowChildren
                children={col.children}
                getColData={this.getColData.bind(this)}
                fieldTypeClassFormatters={this.props.fieldTypeClassFormatters}
                item={item}
              />
            </td>
          );
        })}
      </tr>
    );
  }
}
