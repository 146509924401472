import { mapDispatchToActions } from '../../../helpers/utils.js';
import {
  CLOSE_HELP_FORM,
  SHOW_SCROLL_BAR,
  HIDE_SCROLL_BAR,
  SHOW_HELP_FORM,
  CLOSE_UPLOAD_MODAL,
  SHOW_UPLOAD_MODAL,
  SHOW_FULL_SEARCH_BAR,
  HIDE_FULL_SEARCH_BAR,
  REMOVE_FULL_SEARCH_STYLES,
  SHOW_ADMIN_BAR,
  HIDE_ADMIN_BAR,
  FILE_DOWNLOAD
} from 'labActions/app';

const initialState = {
  overall: 'loading',
  helpFormShown: false,
  uploadModalShown: false,
  showFullSearch: false,
  showFullSearchStyles: false,
  showAdminBar: false,
  showScrollBar: true,
  currencies: [
    {
      currency: 'USD',
      currency_id: 317,
      currency_pretty: 'USD $',
      symbol: '$'
    }
  ],
  currency: 'USD',
  default_currency: 'USD',
  my_currency: 'USD',
  accountMetaData: {},
  field_formatting: {
    monetary_rounding: null,
    numeric_rounding: 3,
    percent_rounding: 2
  },
  isLab: true,
  featureFlag: [],
  splitTests: []
};

const setGlobalApp = app => {
  const appPrefix = window.app.appPrefix;
  const apiPrefix = window.app.apiPrefix;
  const nodeAppPrefix = window.app.nodeAppPrefix;
  window.app = {
    ...app,
    appPrefix,
    apiPrefix,
    nodeAppPrefix
  };
};

export default function reducer(state = initialState, action = {}) {
  let app = {};
  switch (action.type) {
    case 'FATAL_ERROR': // only for errors that put the entire app in an unknown or unusable state
      app = { ...state, nodeAppPrefix: window.app.nodeAppPrefix, overall: 'fatal', fatalReason: action.reason };
      return app;
    case 'GET_APP_DATA':
      return { ...state, overall: 'loading' };
    case 'BLOCKING_ACTION_BEGIN':
      return {
        ...state,
        overall: 'loading',
        overallBeforeBlocking: state.overall,
        blockingExplanation: action.explanation
      };
    case 'BLOCKING_ACTION_END':
      return { ...state, overall: state.overallBeforeBlocking, overallBeforeBlocking: null, blockingExplanation: null };
    case 'GET_APP_DATA_SUCCESS':
      app = { ...state, ...action.result.app, nodeAppPrefix: window.app.nodeAppPrefix, overall: 'loaded' };
      setGlobalApp(app);
      return app;
    case 'GET_APP_DATA_FAIL':
      app = { ...state, nodeAppPrefix: window.app.nodeAppPrefix }; // NOTE consider clearing previous app state
      setGlobalApp(app);
      return app;
    case 'UPDATE_APP_DATA':
      let updateAppReturn = { ...state };

      if (action.copyFromWindow) {
        updateAppReturn[action.copyFromWindow] = window.app[action.copyFromWindow];
      }

      if (action.appLoadFinalProps) {
        updateAppReturn.appLoadFinalProps = true;
      }

      return updateAppReturn;
    case CLOSE_HELP_FORM:
      return { ...state, helpFormShown: false };
    case SHOW_HELP_FORM:
      return { ...state, helpFormShown: true };
    case CLOSE_UPLOAD_MODAL:
      return { ...state, uploadModalShown: false };
    case SHOW_UPLOAD_MODAL:
      return { ...state, uploadModalShown: true };
    case SHOW_FULL_SEARCH_BAR:
      return { ...state, showFullSearch: true, showFullSearchStyles: true };
    case HIDE_FULL_SEARCH_BAR:
      return { ...state, showFullSearch: false };
    case REMOVE_FULL_SEARCH_STYLES:
      return { ...state, showFullSearchStyles: false };
    case SHOW_ADMIN_BAR:
      return { ...state, showAdminBar: true };
    case HIDE_ADMIN_BAR:
      return { ...state, showAdminBar: false };
    case SHOW_SCROLL_BAR:
      return { ...state, showScrollBar: true };
    case HIDE_SCROLL_BAR:
      return { ...state, showScrollBar: false };
    case 'USER_LOGGED_OUT':
      // The data in this store is from /api/util/session-data/sessionData
      // This list is may not be exhaustive in the future given that it's defined server-side, but
      // let's try and obfuscate as much user specific data as possible on logout
      const obfuscatedState = {
        ...state,
        overall: 'loggedOut',
        client_mgr: {},
        email: '',
        first_names: '',
        last_name: '',
        role: '',
        userId: 0,
        user_rights: {}
      };
      setGlobalApp(obfuscatedState);
      return obfuscatedState;
    case 'SET_DEFAULT_CURRENCY_SUCCESS':
      return {
        ...state,
        default_currency: action.result.currency_name
      };
    default:
      return state;
  }
}

export const actions = {};
actions.showHelpForm = () => dispatch => {
  dispatch({
    type: 'HIDE_SCROLL_BAR'
  });
  dispatch({
    type: 'SHOW_HELP_FORM'
  });
};
actions.closeUploadModal = function closeUploadModal() {
  return {
    type: 'CLOSE_UPLOAD_MODAL'
  };
};
actions.showUploadModal = function showUploadModal() {
  return {
    type: 'SHOW_UPLOAD_MODAL'
  };
};
actions.headerSearchButtonClick = function headerSearchButtonClick() {
  return {
    type: 'SHOW_FULL_SEARCH_BAR'
  };
};
actions.closeFullSearchBar = () => dispatch => {
  dispatch({
    type: 'HIDE_FULL_SEARCH_BAR'
  });
  setTimeout(() => dispatch({ type: 'REMOVE_FULL_SEARCH_STYLES' }), 400);
};
actions.adminButtonClick = function headerSearchButtonClick() {
  return {
    type: 'SHOW_ADMIN_BAR'
  };
};
actions.closeAdminBar = function closeFullSearchBar() {
  return {
    type: 'HIDE_ADMIN_BAR'
  };
};
actions.hideScrollBar = function hideScrollBar() {
  return {
    type: 'HIDE_SCROLL_BAR'
  };
};
actions.showScrollBar = function showScrollBar() {
  return {
    type: 'SHOW_SCROLL_BAR'
  };
};
actions.downloadFiles = () => dispatch => {
  dispatch({
    type: 'FILE_DOWNLOAD'
  });
  // TODO PSP-23861 evaluate usage of csrf_token on address bar
  // App is technicalliy a global variable
  // eslint-disable-next-line no-undef
  window.location.href = `${window.app.apiPrefix}api/etl/downloads/downloadFiles?csrf_token=${app.csrf_token}&csrf_timestamp=${app.csrf_timestamp}`;
};

export const getActions = mapDispatchToActions(actions);
