import apiClient from '../../../lib/apiClient';

const SUGGEST_FAMILY_LIST = 'SUGGEST_FAMILY_LIST';
const SUGGEST_FAMILY_LIST_SUCCESS = 'SUGGEST_FAMILY_LIST_SUCCESS';
const SUGGEST_FAMILY_LIST_FAIL = 'SUGGEST_FAMILY_LIST_FAIL';

const initialState = {
  loading: false,
  loaded: false,
  error: null,
  items: []
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SUGGEST_FAMILY_LIST:
      return {
        ...initialState,
        loading: true
      };
    case SUGGEST_FAMILY_LIST_SUCCESS:
      let familyList = [];
      if (action.result) {
        familyList = action.result.map(row => {
          return row.family;
        });
      }
      return {
        ...state,
        loading: false,
        loaded: true,
        error: null,
        items: familyList
      };
    case SUGGEST_FAMILY_LIST_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error
      };
    default:
      return { ...state };
  }
}

export function suggestJobFamilies(options) {
  const title = options.payscaleJobTitle;
  const query = {
    payscale_job_title: options.payscaleJobTitle
  };

  return async dispatch => {
    try {
      dispatch({ type: SUGGEST_FAMILY_LIST });
      const result = await apiClient.apiGet('/api/hris/job-family/suggestJobFamilyForTitle', { params: query });

      dispatch({
        type: SUGGEST_FAMILY_LIST_SUCCESS,
        result: result.data,
        query: query
      });
    } catch (error) {
      dispatch({ type: SUGGEST_FAMILY_LIST_FAIL, error: error });
    }
  };
}
