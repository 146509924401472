import apiClient from '../../../../lib/apiClient';
import { getCSRFPair } from '../../../../redux/stateSelectors';
import addAlert from '../../../../AlertToast/addAlert';
import { getRecalculatedJobData } from '@payscale/ps-isomorphic/dist/JobRanges/JobCalculationUtils';
import { doesJobContainSpecificRangeDetails } from '@payscale/ps-isomorphic/dist/JobRanges/Utils';
import { getJobSettings } from '@payscale/ps-isomorphic/dist/JobRanges/Utils';
import { getModelList } from '../JobRanges';
import { calcProposedRanges, saveJobRangeModel } from '../expandableHeader';
import { getLastModifiedDate } from '../jobRangeDetails';
import {
  JOB_RANGES_RUN_COST_ANALYSIS,
  JOB_RANGES_RUN_COST_ANALYSIS_FAIL,
  JOB_RANGES_RUN_COST_ANALYSIS_SUCCESS
} from '../jobRangeDetails';
import { MIDPOINT_RANGE_TYPE } from '../../Utils/constants';

export const JOB_RANGES_JOB_DETAILS_TOGGLE_EDIT_RANGE_MODEL = 'JOB_RANGES_JOB_DETAILS_TOGGLE_EDIT_RANGE_MODEL';
export const JOB_RANGES_JOB_DETAILS_UPDATE_JOB = 'JOB_RANGES_JOB_DETAILS_UPDATE_JOB';
export const JOB_RANGES_JOB_DETAILS_UPDATE_JOB_FAIL = 'JOB_RANGES_JOB_DETAILS_UPDATE_JOB_FAIL';
export const SAVE_JOB_RANGE_MODEL_JOBS_SUCCESS = 'SAVE_JOB_RANGE_MODEL_JOBS_SUCCESS';
export const SAVE_JOB_RANGE_MODEL_JOBS_FAIL = 'SAVE_JOB_RANGE_MODEL_JOBS_FAIL';
export const REFRESH_JOB_RANGE_MODEL_JOBS = 'REFRESH_JOB_RANGE_MODEL_JOBS';
export const REFRESH_JOB_RANGE_MODEL_JOBS_SUCCESS = 'REFRESH_JOB_RANGE_MODEL_JOBS_SUCCESS';
export const REFRESH_JOB_RANGE_MODEL_JOBS_FAIL = 'REFRESH_JOB_RANGE_MODEL_JOBS_FAIL';
const errorMessage = 'Error updating job range';
/**
 * Toggles the show edit range model state
 */
export function handleToggleShowEditRangeModel() {
  return (dispatch, getState) => {
    const currentShowEditModel = getState().jobRanges.jobRangeDetails.showEditJobRangeModel;
    dispatch({ type: JOB_RANGES_JOB_DETAILS_TOGGLE_EDIT_RANGE_MODEL, value: !currentShowEditModel });
  };
}

/**
 * Handles the "Reset to Global" button click by removing job specific settings
 */
export function handleResetToGlobalClick() {
  return (dispatch, getState) => {
    const jobRangeDetails = getState().jobRanges.jobRangeDetails;
    const model = getState().jobRanges.modelDetails.jobRangeModel;

    //  double check if job Range details is defined (this is used to calculate job data)
    if (!jobRangeDetails || !model) return;

    const job = jobRangeDetails.job;
    const newJob = calculateNewJobData(job, model);
    updateJobSettings(newJob, null, null, null, null, null, null, null, null, null);

    dispatch({ type: JOB_RANGES_JOB_DETAILS_UPDATE_JOB, newJob });
    dispatch(apiPostUpdateModelJobs(newJob));
  };
}

export function refreshPricing(jobId) {
  return async (dispatch, getState) => {
    try {
      const csrfPair = getCSRFPair(getState());
      const { jobRangeModel } = getState().jobRanges.modelDetails;
      const { jobRangeModelId, proposedRangeSetting, targetRangeSpread, rangeType } = jobRangeModel;
      const data = { ...csrfPair, jobRangeModelId };

      dispatch({ type: REFRESH_JOB_RANGE_MODEL_JOBS });

      const result = await apiClient.apiPost('/api/jobRanges/model-jobs/getSelectedJobsWithDetails', data);
      let modelJobs = result.data;

      if ((proposedRangeSetting && targetRangeSpread) || rangeType === MIDPOINT_RANGE_TYPE) {
        modelJobs = calcProposedRanges(modelJobs, jobRangeModel);
      }

      dispatch({ type: REFRESH_JOB_RANGE_MODEL_JOBS_SUCCESS, modelJobs });
      dispatch(saveJobRangeModel());

      const job = modelJobs.find(job => job.id === jobId);
      const model = getState().jobRanges.modelDetails.jobRangeModel;
      const hasManualChanges = !doesJobContainSpecificRangeDetails(job);
      const settings = getJobSettings(model, job);
      const newJob = calculateNewJobData(job, settings, hasManualChanges);
      dispatch(getLastModifiedDate(newJob.org_job_id));
      dispatch({ type: JOB_RANGES_JOB_DETAILS_UPDATE_JOB, newJob });
      dispatch(apiPostUpdateModelJobs(newJob));
    } catch (error) {
      addAlert(errorMessage, 'danger');
      console.log(error);
      dispatch({ type: REFRESH_JOB_RANGE_MODEL_JOBS_FAIL, error: `refreshPricing: ${error}` });
    }
  };
}

/**
 * Updates the current job with updated proposed range setting selection and saves to server.
 *
 * @param option proposed range setting selection event with value
 * @returns {Function} null
 */
export function handleProposedRangeSelect(option) {
  return (dispatch, getState) => {
    const jobRangeDetails = getState().jobRanges.jobRangeDetails;
    const model = getState().jobRanges.modelDetails.jobRangeModel;
    if (!jobRangeDetails || !model) return;

    const job = jobRangeDetails.job;
    const settings = getJobSettings(model, job);

    if (option.value === settings.proposedRangeSetting) return;
    const newJob = calculateNewJobData(job, {
      ...settings,
      proposedRangeSetting: option.value
    });

    dispatch({ type: JOB_RANGES_JOB_DETAILS_UPDATE_JOB, newJob });
    dispatch(apiPostUpdateModelJobs(newJob));
  };
}

export function handleMaxElementChange(option) {
  return (dispatch, getState) => {
    try {
      const jobRangeDetails = getState().jobRanges.jobRangeDetails;
      const model = getState().jobRanges.modelDetails.jobRangeModel;
      if (!jobRangeDetails || !model) return;

      const job = jobRangeDetails.job;
      const settings = getJobSettings(model, job);

      if (option.value === settings.maxPercentageMarketElement) return;

      const newJob = calculateNewJobData(job, {
        ...settings,
        maxPercentageMarketElement: option.value
      });

      dispatch(apiPostUpdateModelJobs(newJob));
      dispatch({ type: JOB_RANGES_JOB_DETAILS_UPDATE_JOB, newJob });
    } catch (error) {
      addAlert(errorMessage, 'danger');
      dispatch({ type: JOB_RANGES_JOB_DETAILS_UPDATE_JOB_FAIL, error: `maxElementChange(job): ${error}` });
    }
  };
}

export function handleMinElementChange(option) {
  return (dispatch, getState) => {
    try {
      const jobRangeDetails = getState().jobRanges.jobRangeDetails;
      const model = getState().jobRanges.modelDetails.jobRangeModel;
      if (!jobRangeDetails || !model) return;

      const job = jobRangeDetails.job;
      const settings = getJobSettings(model, job);

      if (option.value === settings.minPercentageMarketElement) return;

      const newJob = calculateNewJobData(job, {
        ...settings,
        minPercentageMarketElement: option.value
      });

      dispatch(apiPostUpdateModelJobs(newJob));
      dispatch({ type: JOB_RANGES_JOB_DETAILS_UPDATE_JOB, newJob });
    } catch (error) {
      addAlert(errorMessage, 'danger');
      dispatch({ type: JOB_RANGES_JOB_DETAILS_UPDATE_JOB_FAIL, error: `minElementChange(job): ${error}` });
    }
  };
}

/**
 * Updates the current job with updated range type selection and saves to server.
 *
 * @param optionValue range type selection event with value
 * @returns {Function} null
 */
export function handleRangeTypeOptionClick(id, optionValue) {
  return (dispatch, getState) => {
    const jobRangeDetails = getState().jobRanges.jobRangeDetails;
    const model = getState().jobRanges.modelDetails.jobRangeModel;
    if (!jobRangeDetails || !model) return;

    const job = jobRangeDetails.job;
    const settings = getJobSettings(model, job);
    const newRangeType = optionValue.toLowerCase();

    if (newRangeType === settings.rangeType) return;

    const newJob = calculateNewJobData(job, {
      ...settings,
      rangeType: newRangeType
    });

    dispatch({ type: JOB_RANGES_JOB_DETAILS_UPDATE_JOB, newJob });
    dispatch(apiPostUpdateModelJobs(newJob));
  };
}

export function handleProposedMidpointValueUpdate(newProposedMidpointValue) {
  return (dispatch, getState) => {
    const jobRangeDetails = getState().jobRanges.jobRangeDetails;
    const model = getState().jobRanges.modelDetails.jobRangeModel;
    if (!jobRangeDetails || !model) return;

    const job = jobRangeDetails.job;
    const settings = getJobSettings(model, job);

    if (newProposedMidpointValue < 0 || newProposedMidpointValue === settings.proposedMidpoint) return;

    const newJob = calculateNewJobData(job, {
      ...settings,
      proposedMidpoint: newProposedMidpointValue
    });

    dispatch({ type: JOB_RANGES_JOB_DETAILS_UPDATE_JOB, newJob });
    dispatch(apiPostUpdateModelJobs(newJob));
  };
}

export function handleProposedMinValueUpdate(newProposedMinValue) {
  return (dispatch, getState) => {
    try {
      const jobRangeDetails = getState().jobRanges.jobRangeDetails;
      const model = getState().jobRanges.modelDetails.jobRangeModel;
      if (!jobRangeDetails || !model) return;

      const job = jobRangeDetails.job;
      const settings = getJobSettings(model, job);

      if (newProposedMinValue < 0 || newProposedMinValue === settings.proposedMinimum) return;

      const newJob = calculateNewJobData(job, {
        ...settings,
        proposedMinimum: newProposedMinValue
      });

      dispatch(apiPostUpdateModelJobs(newJob));
      dispatch({ type: JOB_RANGES_JOB_DETAILS_UPDATE_JOB, newJob });
    } catch (error) {
      addAlert(errorMessage, 'danger');
      dispatch({ type: JOB_RANGES_JOB_DETAILS_UPDATE_JOB_FAIL, error: `handleProposedMinValueUpdate(job): ${error}` });
    }
  };
}

export function handleProposedMaxValueUpdate(newProposedMaxValue) {
  return (dispatch, getState) => {
    try {
      const jobRangeDetails = getState().jobRanges.jobRangeDetails;
      const model = getState().jobRanges.modelDetails.jobRangeModel;
      if (!jobRangeDetails || !model) return;

      const job = jobRangeDetails.job;
      const settings = getJobSettings(model, job);

      if (newProposedMaxValue < 0 || newProposedMaxValue === settings.proposedMaximum) return;

      const newJob = calculateNewJobData(job, {
        ...settings,
        proposedMaximum: newProposedMaxValue
      });

      dispatch(apiPostUpdateModelJobs(newJob));
      dispatch({ type: JOB_RANGES_JOB_DETAILS_UPDATE_JOB, newJob });
    } catch (error) {
      addAlert(errorMessage, 'danger');
      dispatch({ type: JOB_RANGES_JOB_DETAILS_UPDATE_JOB_FAIL, error: `handleProposedMaxValueUpdate(job): ${error}` });
    }
  };
}

/**
 * Updates the midpoint value
 * @param event the midpoint value adjustment event
 */
export function handleMidpointAdjustmentValueUpdate(value) {
  return (dispatch, getState) => {
    const jobRangeDetails = getState().jobRanges.jobRangeDetails;
    const model = getState().jobRanges.modelDetails.jobRangeModel;
    if (!jobRangeDetails || !model) return;

    const job = jobRangeDetails.job;
    const settings = getJobSettings(model, job);
    const newMidpointAdjustment = value / 100;

    if (newMidpointAdjustment === settings.midpointAdjustment) return;

    const newJob = calculateNewJobData(job, {
      ...settings,
      midpointAdjustment: newMidpointAdjustment
    });

    dispatch({ type: JOB_RANGES_JOB_DETAILS_UPDATE_JOB, newJob });
    dispatch(apiPostUpdateModelJobs(newJob));
  };
}

/**
 * Updates the current job with updated midpoint adjustment and saves to server.
 *
 * @param direction midpoint adjustment to be made
 * @returns {Function} null
 */
export function handleMidpointAdjustmentClick(direction) {
  return (dispatch, getState) => {
    const jobRangeDetails = getState().jobRanges.jobRangeDetails;
    const model = getState().jobRanges.modelDetails.jobRangeModel;
    if (!jobRangeDetails || !model) return;

    const job = jobRangeDetails.job;
    const settings = getJobSettings(model, job);
    const magnitude = 1;
    const newMidpointAdjustment = settings.midpointAdjustment + Math.round(magnitude * direction) / 100;

    const newJob = calculateNewJobData(job, {
      ...settings,
      midpointAdjustment: newMidpointAdjustment
    });

    dispatch({ type: JOB_RANGES_JOB_DETAILS_UPDATE_JOB, newJob });
    dispatch(apiPostUpdateModelJobs(newJob));
  };
}

/**
 * @returns {Function} updated range spread action
 */
export function handleRangeSpreadChange(value) {
  return (dispatch, getState) => {
    const jobRangeDetails = getState().jobRanges.jobRangeDetails;
    const model = getState().jobRanges.modelDetails.jobRangeModel;
    if (!jobRangeDetails || !model) return;

    const job = jobRangeDetails.job;
    const settings = getJobSettings(model, job);

    const newTargetRangeSpread = value / 100;
    if (newTargetRangeSpread < 0 || newTargetRangeSpread === settings.targetRangeSpread) return;

    const newJob = calculateNewJobData(job, {
      ...settings,
      targetRangeSpread: newTargetRangeSpread
    });

    dispatch({ type: JOB_RANGES_JOB_DETAILS_UPDATE_JOB, newJob });
    dispatch(apiPostUpdateModelJobs(newJob));
  };
}

export function handleMinPercentChange(value) {
  return (dispatch, getState) => {
    try {
      const jobRangeDetails = getState().jobRanges.jobRangeDetails;
      const model = getState().jobRanges.modelDetails.jobRangeModel;
      if (!jobRangeDetails || !model) return;

      const job = jobRangeDetails.job;
      const settings = getJobSettings(model, job);

      const newTargetRangeSpread = value / 100;
      if (newTargetRangeSpread < 0 || newTargetRangeSpread === settings.targetRangeSpread) return;

      const newJob = calculateNewJobData(job, {
        ...settings,
        minPercentageOfMid: newTargetRangeSpread
      });

      dispatch({ type: JOB_RANGES_JOB_DETAILS_UPDATE_JOB, newJob });
      dispatch(apiPostUpdateModelJobs(newJob));
    } catch (error) {
      addAlert(errorMessage, 'danger');
      dispatch({ type: JOB_RANGES_JOB_DETAILS_UPDATE_JOB_FAIL, error: `minPercentChange(job): ${error}` });
    }
  };
}

export function handleMaxPercentChange(value) {
  return (dispatch, getState) => {
    try {
      const jobRangeDetails = getState().jobRanges.jobRangeDetails;
      const model = getState().jobRanges.modelDetails.jobRangeModel;
      if (!jobRangeDetails || !model) return;

      const job = jobRangeDetails.job;
      const settings = getJobSettings(model, job);

      const newTargetRangeSpread = value / 100;
      if (newTargetRangeSpread < 0 || newTargetRangeSpread === settings.targetRangeSpread) return;

      const newJob = calculateNewJobData(job, {
        ...settings,
        maxPercentageOfMid: newTargetRangeSpread
      });

      dispatch({ type: JOB_RANGES_JOB_DETAILS_UPDATE_JOB, newJob });
      dispatch(apiPostUpdateModelJobs(newJob));
    } catch (error) {
      addAlert(errorMessage, 'danger');
      dispatch({ type: JOB_RANGES_JOB_DETAILS_UPDATE_JOB_FAIL, error: `maxPercentChange(job): ${error}` });
    }
  };
}

export function handleSaveBuildMinMaxBy(value) {
  return (dispatch, getState) => {
    try {
      const jobRangeDetails = getState().jobRanges.jobRangeDetails;
      const model = getState().jobRanges.modelDetails.jobRangeModel;
      if (!jobRangeDetails || !model) return;

      const job = jobRangeDetails.job;
      const settings = getJobSettings(model, job);
      const newJob = calculateNewJobData(job, {
        ...settings,
        buildMinMaxBy: value
      });

      dispatch({ type: JOB_RANGES_JOB_DETAILS_UPDATE_JOB, newJob });
      dispatch(apiPostUpdateModelJobs(newJob));
    } catch (error) {
      addAlert(errorMessage, 'danger');
      dispatch({ type: JOB_RANGES_JOB_DETAILS_UPDATE_JOB_FAIL, error: `saveBuildMinMaxBy(job): ${error}` });
    }
  };
}

/**
 * Generic handler for job setting blur events
 * @param dispatch dispatcher
 * @param getState getState function
 */
function handleJobSettingBlurEvent(dispatch, getState) {
  const jobRangeDetails = getState().jobRanges.jobRangeDetails;
  if (!jobRangeDetails) return;
  //  NOTE: ASSUME JOB IS UPDATED VIA THE ASSOCIATED MIDPOINT VALUE ON CHANGE HANDLER. ONLY NEED TO POST TO SERVER W UPDATE
  const job = jobRangeDetails.job;
  dispatch(apiPostUpdateModelJobs(job));
}

/**
 * Builds a new job with updated job data calculations
 * @param job the job
 * @param settings the job/model settings
 * @returns {new} new job with calculated data
 */
function calculateNewJobData(job, settings, isRefresh = false) {
  const {
    proposedRangeSetting,
    targetRangeSpread,
    rangeType,
    midpointAdjustment,
    buildMinMaxBy,
    maxPercentageOfMid,
    minPercentageOfMid,
    minPercentageMarketElement,
    maxPercentageMarketElement
  } = settings;

  const newJob = getRecalculatedJobData({ ...settings, job });
  if (isRefresh) {
    updateJobSettings(newJob);
  } else {
    updateJobSettings(
      newJob,
      proposedRangeSetting,
      targetRangeSpread,
      rangeType,
      midpointAdjustment,
      buildMinMaxBy,
      maxPercentageOfMid,
      minPercentageOfMid,
      minPercentageMarketElement,
      maxPercentageMarketElement
    );
  }

  return newJob;
}

/**
 * Updates a job object with the passed in range details
 * @param job the job
 * @param proposedRangeSetting the proposed range settings
 * @param targetRangeSpread the target range spread
 * @param rangeType the range type
 * @param midpointAdjustment the midpoint adjustment
 */
function updateJobSettings(
  job,
  proposedRangeSetting,
  targetRangeSpread,
  rangeType,
  midpointAdjustment,
  buildMinMaxBy,
  maxPercentageOfMid,
  minPercentageOfMid,
  minPercentageMarketElement,
  maxPercentageMarketElement
) {
  job.proposed_range_setting = proposedRangeSetting;
  job.target_range_spread = targetRangeSpread;
  job.range_type = rangeType;
  job.midpoint_adjustment = midpointAdjustment;
  job.build_min_max_by = buildMinMaxBy;
  job.max_percentage_of_mid = maxPercentageOfMid;
  job.min_percentage_of_mid = minPercentageOfMid;
  job.min_percentage_market_element = minPercentageMarketElement;
  job.max_percentage_market_element = maxPercentageMarketElement;
}

/**
 * Posts and updates the model details model jobs to the server.
 */
function apiPostUpdateModelJobs(job) {
  return async (dispatch, getState) => {
    try {
      //  compute cost analysis should only return employee updates that are in view
      const { employeesLimit, employeesOffset } = getState().jobRanges.jobRangeDetails;
      const model = getState().jobRanges.modelDetails.jobRangeModel;

      dispatch({ type: JOB_RANGES_RUN_COST_ANALYSIS });

      const csrfPair = getCSRFPair(getState());
      const data = {
        modelJobs: [job],
        returnCostAnalysis: true,
        limit: employeesLimit,
        offset: employeesOffset,
        ...csrfPair
      };

      const result = await apiClient.apiPost('/api/jobRanges/model-jobs/updateModelJobs', data);

      if (result.data.success === false) {
        dispatch({ type: SAVE_JOB_RANGE_MODEL_JOBS_FAIL });
        dispatch({ type: JOB_RANGES_RUN_COST_ANALYSIS_FAIL });
        return;
      }

      dispatch({ type: SAVE_JOB_RANGE_MODEL_JOBS_SUCCESS, result });
      dispatch({ type: JOB_RANGES_RUN_COST_ANALYSIS_SUCCESS, ...result.data, job });
      dispatch(getModelList(model.jobRangeModelId));
    } catch (err) {
      dispatch({ type: SAVE_JOB_RANGE_MODEL_JOBS_FAIL, error: err });
      dispatch({ type: JOB_RANGES_RUN_COST_ANALYSIS_FAIL, error: err });
    }
  };
}
