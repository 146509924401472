import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@payscale/design/lib/tooltip';
import CellIndicator from '../../LoadingIndicator/CellIndicator/CellIndicator';
import classnames from 'classnames';

import Row from '../Row/Row';
import Cell from '../Cell/Cell';
import { camelCase } from '../..//lib/formatters';
import CheckboxInput from '../../Input/CheckboxInput';
import './TableHeader.scss';

class TableHeader extends Component {
  static propTypes = {
    cellClass: PropTypes.string,
    columns: PropTypes.array,
    headerClass: PropTypes.string,
    headerRowClass: PropTypes.string,
    onClick: PropTypes.func,
    icon: PropTypes.string,
    sortField: PropTypes.string,
    rowClass: PropTypes.string,
    columnClasses: PropTypes.array,
    greatestLength: PropTypes.number,
    columnWidths: PropTypes.array,
    columnMinWidths: PropTypes.array,
    headerGroupings: PropTypes.array,
    useSelectAll: PropTypes.bool,
    onSelectAll: PropTypes.func,
    cellMinWidth: PropTypes.string,
    isSelectAllDisabled: PropTypes.bool,
    headerGroups: PropTypes.array,
    hasGroupedColumns: PropTypes.bool,
    hasStickyLeftCol: PropTypes.bool,
    isAllChecked: PropTypes.bool,
    rowsHaveSelect: PropTypes.bool,
    useLoadingIconForSelectAll: PropTypes.bool,
    hideSelect: PropTypes.bool,
    sortEnabled: PropTypes.bool,
    sortFieldMap: PropTypes.object
  };

  static defaultProps = {
    sortFieldMap: {}
  };

  constructor(props) {
    super(props);
    this.renderSelectAll = this.renderSelectAll.bind(this);
  }

  getSelectedColumn(column) {
    const { sortField, sortFieldMap } = this.props;

    if (column.length > 0) {
      const camelCasedColumn = camelCase(column);
      return sortFieldMap[camelCasedColumn] === sortField;
    }
  }

  renderSelectAll(headerGroupIdx) {
    const {
      useSelectAll,
      cellClass,
      hasStickyLeftCol,
      onSelectAll,
      hasGroupedColumns,
      isAllChecked,
      isSelectAllDisabled,
      rowsHaveSelect,
      useLoadingIconForSelectAll,
      hideSelect
    } = this.props;
    if ((!rowsHaveSelect && !useSelectAll) || hideSelect) return;
    if (hasGroupedColumns && headerGroupIdx === 0) {
      return (
        <Cell
          cellType="header"
          className={classnames('column__heading', 'column__heading-checkbox')}
          key={'table-header-checkbox-all'}
          stickyLeft={hasStickyLeftCol}
          left={hasStickyLeftCol ? 0 : null}
        />
      );
    }

    return (
      <Cell
        cellType="header"
        className={classnames('column__heading', cellClass, 'column__heading-checkbox')}
        key={'table-header-checkbox-all'}
        stickyLeft={hasStickyLeftCol}
        left={hasStickyLeftCol ? 0 : null}
      >
        {useLoadingIconForSelectAll ? (
          <CellIndicator iconClass="icon-animate-spin icon-spin cell-indicator" />
        ) : (
          <CheckboxInput
            style={{ visibility: useSelectAll ? 'visible' : 'hidden' }}
            checked={isAllChecked}
            onClick={onSelectAll}
            disabled={isSelectAllDisabled}
          />
        )}
      </Cell>
    );
  }

  renderSortIcon(sortEnabled, column) {
    if (!sortEnabled) return;
    return (
      <span className="table-header__sort-container">
        {column.isSorted && !column.SubHeaderText && !column.disableSorting ? (
          column.isSortedDesc ? (
            <i className="icon-sort-down" />
          ) : (
            <i className="icon-sort-up table-header__sort-offset" />
          )
        ) : (
          ''
        )}
      </span>
    );
  }

  render() {
    const {
      cellClass,
      columns,
      headerClass,
      icon,
      onClick,
      rowClass,
      columnClasses,
      greatestLength,
      columnMinWidths,
      columnWidths,
      useSelectAll,
      onSelectAll,
      cellMinWidth,
      headerGroups,
      hasGroupedColumns,
      headerRowClass,
      isAllChecked,
      isSelectAllDisabled,
      sortEnabled
    } = this.props;
    let isSelectedColumn = null;
    return (
      <thead className={classnames('table__header', headerClass)}>
        {headerGroups &&
          headerGroups.map((headerGroup, headerGroupIdx) => (
            <Row
              key={'header-group-' + headerGroupIdx}
              getHeaderGroupProps={headerGroup.getHeaderGroupProps}
              className={classnames(headerRowClass, {
                'table__grouping-header--row': hasGroupedColumns && headerGroupIdx === 0,
                'table__header--row': !hasGroupedColumns || headerGroupIdx === 1,
                'table__header--row-grouping': hasGroupedColumns && headerGroupIdx === 1
              })}
            >
              {this.renderSelectAll(headerGroupIdx)}
              {headerGroup.headers.map((column, idx) => {
                const columnHeader = column.render('Header');
                const columnTooltipOverride = column.tooltipOverride;
                const isSortedText = column.isSorted ? (column.isSortedDesc ? ' (Desc)' : ' (Asc)') : '';
                const toolTipHeader = typeof columnHeader === 'string' ? columnHeader + isSortedText : '';
                const isStickyLeft = column.stickyLeft || column.placeholderOf?.stickyLeft || column.parent?.stickyLeft;
                const isStickyRight =
                  column.stickyRight || column.placeholderOf?.stickyRight || column.parent?.stickyRight;
                const tableHeadProps = column.getHeaderProps();
                return (
                  <Cell
                    tableHeadProps={tableHeadProps}
                    cellType="header"
                    stickyLeft={isStickyLeft}
                    stickyRight={isStickyRight}
                    left={column.leftAlignOffset ? column.leftAlignOffset : column.placeholderOf?.leftAlignOffset || 0}
                    right={
                      column.rightAlignOffset ? column.rightAlignOffset : column.placeholderOf?.rightAlignOffset || 0
                    }
                    className={classnames(
                      'column__heading',
                      !hasGroupedColumns || headerGroupIdx === 1 ? cellClass : null,
                      {
                        'no-sort': !sortEnabled || !column.canSort,
                        'table__grouping-header': hasGroupedColumns && headerGroupIdx === 0,
                        'table__grouping-left-child':
                          hasGroupedColumns && column.isLeftGrouped && !headerGroup.headers[idx - 1]?.isRightGrouped,
                        'table__grouping-right-child': hasGroupedColumns && column.isRightGrouped,
                        'table__grouping-child': hasGroupedColumns && column.isGrouped,
                        'right-align': column.rightAlign,
                        'center-align': column.centerAlign,
                        'sticky-left__border-header': column.lastStickyLeft || column.placeholderOf?.lastStickyLeft,
                        'sticky-right__border-header': column.lastStickyRight || column.placeholderOf?.lastStickyRight
                      }
                    )}
                    key={'table-header-' + idx}
                    onClick={onClick}
                    greatestLength={greatestLength}
                    minWidth={columnMinWidths}
                    // If is placeholder column, use placeholder maxWidth instead
                    maxWidth={column.placeholderOf ? column.placeholderOf.maxWidth : column.maxWidth}
                    position={isStickyLeft || isStickyRight ? '' : null}
                  >
                    {column.rightAlign && this.renderSortIcon(sortEnabled, column)}
                    {typeof columnHeader === 'string' ? (
                      <span
                        className="table-header__tooltip-container"
                        {...(column.getSortByToggleProps ? column.getSortByToggleProps() : undefined)}
                        title={undefined}
                      >
                        <Tooltip
                          className="table-header__tooltip"
                          id="table-header__tooltip"
                          tooltipText={columnHeader}
                          position="bottom"
                          display="underline"
                          hoverText={columnTooltipOverride || toolTipHeader}
                        />
                      </span>
                    ) : (
                      <span {...(column.getSortByToggleProps ? column.getSortByToggleProps() : undefined)}>
                        {column.render('Header')}
                      </span>
                    )}
                    {!column.rightAlign && this.renderSortIcon(sortEnabled, column)}
                    {!(hasGroupedColumns && headerGroupIdx === 0) && column.getResizerProps && (
                      <span
                        {...column.getResizerProps()}
                        className={classnames('resizer', { isResizing: column.isResizing })}
                      ></span>
                    )}
                  </Cell>
                );
              })}
            </Row>
          ))}
        {!headerGroups && (
          <Row className={classnames(rowClass)} rowType="header">
            {useSelectAll && (
              <Cell
                cellType="header"
                className={classnames('column__heading', cellClass, 'column__heading-checkbox')}
                key={'table-header-checkbox-all'}
              >
                <CheckboxInput checked={isAllChecked} onClick={onSelectAll} disabled={isSelectAllDisabled} />
              </Cell>
            )}
            {columns.map((column, index) => {
              isSelectedColumn = this.getSelectedColumn(column);
              let customRowClass = '';
              if (columnClasses && columnClasses[index]) {
                customRowClass = columnClasses[index];
              }

              return (
                <Cell
                  cellType="header"
                  className={classnames('column__heading', cellClass, customRowClass)}
                  key={index}
                  width={columnWidths && columnWidths[index]}
                  onClick={onClick}
                  icon={icon}
                  greatestLength={greatestLength}
                  minWidth={columnMinWidths && columnMinWidths[index] ? columnMinWidths[index] : cellMinWidth}
                >
                  {column}
                  {isSelectedColumn && <i className={icon} />}
                </Cell>
              );
            })}
          </Row>
        )}
      </thead>
    );
  }
}

export default TableHeader;
