import { mapDispatchToActions } from '../../../../../panther-react/src/helpers/utils.js';
import uuid from 'uuid';

const initialState = {
  loading: false,
  loaded: false,
  error: null,
  managerEmailList: [],
  showErrorModal: false,
  deactivateModal: false,
  editSite: {},
  changesToSave: {},
  errorMessage: '',
  showDropZone: false,
  showAlertMessage: false,
  showDropZoneUploading: false,
  uploadModalShown: false,
  dzFiles: [],
  dzRejectedFiles: [],
  rejectReason: '',
  inProgressFileCount: 0,
  failedFileCount: 0,
  uploadProgress: 0,
  uploadFinished: false,
  filesUploading: false,
  deactivateSite: {},
  sfId: ''
};

export default function reducer(state = initialState, action = {}) {
  const fileCount = state.inProgressFileCount;
  const failedFileCount = state.failedFileCount;
  let files = state.dzFiles;
  let dzRejectedFiles = state.dzRejectedFiles;

  switch (action.type) {
    case 'GET_SITE_BASICS':
      return {
        ...state,
        loading: true,
        loaded: false
      };

    case 'GET_SITE_BASICS_SUCCESS':
      const date = new Date();
      const month = date.getMonth() + 1;
      const day = date.getDate();
      let defaultPricingYear = date.getFullYear();
      if (month <= 8 && day <= 30) {
        defaultPricingYear = date.getFullYear() - 1;
      }
      let contactFirstName = '';
      let contactLastName = '';
      let url = '';
      let sfId = '';
      let crewGuid = '';
      let organizationId = '';
      let orgGroupId = '';
      let organizationName = '';
      let logo = '';
      let siteStatus = 'Active';
      let defaultCurrency = 'USD';
      let showMonetaryValue = 'thousands';
      let defaultAgingFactor = '';
      let contactJobTitle = '';
      let contactEmail = '';
      let clientManagerEmail = '';
      let implementationManagerEmail = '';
      let requireWhitelist = false;

      if (action.result && action.result[0].organization_id) {
        const result = action.result[0];
        url = result.url;
        sfId = result.sf_id;
        crewGuid = result.crew_guid;
        organizationId = result.organization_id;
        orgGroupId = result.org_group_id;
        organizationName = result.org_name;
        logo = result.logo;
        siteStatus = result.subsite_status;
        defaultPricingYear = result.survey_year;
        defaultCurrency = result.currency;
        showMonetaryValue = result.show_monetary;
        defaultAgingFactor = '';
        contactJobTitle = result.primary_job_title;
        contactFirstName = result.primary_first_name;
        contactLastName = result.primary_last_name;
        contactEmail = result.primary_email_address;
        clientManagerEmail = result.client_mgr_email;
        implementationManagerEmail = result.implementation_mgr_email;
        requireWhitelist = result.requires_whitelist;
      }

      return {
        ...state,
        managerEmailList: state.managerEmailList,
        basicIsDirty: false,
        resetForm: false,
        editSite: {
          url: url,
          sfId: sfId,
          crewGuid: crewGuid,
          organizationId: organizationId,
          orgGroupId: orgGroupId,
          organizationName: organizationName,
          logo: logo,
          siteStatus: siteStatus,
          defaultPricingYear: defaultPricingYear,
          defaultCurrency: defaultCurrency,
          showMonetaryValue: showMonetaryValue,
          defaultAgingFactor: defaultAgingFactor,
          contactFirstName: contactFirstName,
          contactLastName: contactLastName,
          contactJobTitle: contactJobTitle,
          contactEmail: contactEmail,
          clientManagerEmail: clientManagerEmail,
          implementationManagerEmail: implementationManagerEmail,
          requireWhitelist: requireWhitelist,
          changesToSave: {}
        }
      };

    case 'GET_SITE_BASICS_FAIL':
      return {
        ...state,
        loading: false,
        loaded: true,
        error: action.result
      };

    case 'SET_SITE_BASICS_DIRTY_STATE':
      return {
        ...state,
        resetForm: false,
        basicIsDirty: action.basicIsDirty,
        changesToSave: action.changesToSave
      };

    case 'GET_MANAGER_EMAILS':
      return {
        ...state,
        loading: true,
        loaded: false
      };
    case 'GET_MANAGER_EMAILS_SUCCESS':
      return {
        ...state,
        loading: false,
        loaded: true,
        managerEmailList: action.result
      };
    case 'GET_MANAGER_EMAILS_FAIL':
      return {
        ...state,
        loading: false,
        loaded: true,
        error: action.result
      };

    case 'SAVE_SITE_BASIC':
      return {
        ...state,
        loading: true,
        loaded: false
      };

    case 'SAVE_SITE_BASIC_SUCCESS':
      let showErrorModal = false;
      let errorMessage = 'There was an error creating site.';
      if (!action.result.success) {
        showErrorModal = true;
        if (action.result.reason) {
          errorMessage = action.result.reason;
        }
      }
      if (action.result.subsites && action.result.subsites.length > 0) {
        window.app.subsites = action.result.subsites;
      }

      return {
        ...state,
        loading: false,
        loaded: true,
        basicIsDirty: false,
        fullSiteList: window.app.subsites,
        showErrorModal: showErrorModal,
        errorMessage: errorMessage,
        editSite: action.options
      };

    case 'SAVE_SITE_BASIC_FAIL':
      return {
        ...state,
        loading: false,
        loaded: true,
        basicIsDirty: false,
        error: action.result
      };

    case 'RESET_SITE_BASIC_FORM':
      const result = action.originalValues;

      return {
        ...state,
        loading: false,
        loaded: true,
        basicIsDirty: false,
        resetForm: true,
        editSite: {
          crewGuid: result.crewGuid,
          organizationName: result.organizationName,
          logo: result.logo,
          url: result.url,
          sfId: result.sf_id,
          siteStatus: result.siteStatus,
          defaultPricingYear: result.defaultPricingYear,
          defaultCurrency: result.defaultCurrency,
          showMonetaryValue: result.showMonetaryValue,
          defaultAgingFactor: result.defaultAgingFactor,
          contactFirstName: result.contactFirstName,
          contactLastName: result.contactLastName,
          contactJobTitle: result.contactJobTitle,
          contactEmail: result.contactEmail,
          clientManagerEmail: result.clientManagerEmail,
          implementationManagerEmail: result.implementationManagerEmail,
          requireWhitelist: result.requireWhitelist,
          changesToSave: {}
        },
        error: action.result
      };

    case 'LOGO_UPLOAD_ON_DRAG_ENTER':
      return {
        ...state,
        showDropZone: true,
        showAlertMessage: false
      };

    case 'LOGO_UPLOAD_ON_DRAG_LEAVE':
      return {
        ...state,
        showDropZone: false,
        showAlertMessage: false
      };

    case 'LOGO_UPLOAD_ON_DROP_REJECTED':
      if (state.dzRejectedFiles && state.dzRejectedFiles.length > 0) {
        for (let i = 0; i < action.dzRejectedFiles.length; i++) {
          dzRejectedFiles.push(action.dzRejectedFiles[i]);
        }
      } else {
        dzRejectedFiles = action.dzRejectedFiles;
      }

      const maxFileSize = action.maxFileSize / 1000000;
      const mimeTypes = action.mimeTypes.replace(/,./g, ', .');

      let rejectReason = '';
      switch (action.rejectReason) {
        case 'FileSize':
          rejectReason = 'The File size is too large. Must be les than ' + maxFileSize + ' MB.';
          break;

        case 'FileType':
          rejectReason = 'Invalid file type. Valid types are: ' + mimeTypes;
          break;

        default:
          //  rejectReason: 'File upload rejected.';
          break;
      }

      return {
        ...state,
        type: 'LOGO_UPLOAD_ON_DROP_REJECTED',
        showAlertMessage: true,
        showDropZone: false,
        dzRejectedFiles: dzRejectedFiles,
        rejectReason: rejectReason,
        failedFileCount: failedFileCount + action.dzRejectedFiles.length
      };

    case 'LOGO_UPLOAD':
      if (state.dzFiles && state.dzFiles.length < 1) {
        files = action.dzFiles;
      }

      if (state.dzFiles && state.dzFiles.length > 0) {
        for (let i = 0; i < action.dzFiles.length; i++) {
          files.push(action.dzFiles[i]);
        }
      }
      if (!state.dzFiles && action.dzFiles) {
        for (let i = 0; i < action.dzFiles.length; i++) {
          files.push(action.dzFiles[i]);
        }
      }

      return {
        ...state,
        showDropZone: false,
        showAlertMessage: false,
        showDropZoneUploading: true,
        uploadFinished: false,
        filesUploading: true,
        dzFiles: files,
        lastRequest: action.ajaxClient.lastRequest
      };

    case 'LOGO_UPLOAD_SUCCESS':
      let updateddDzFiles = state.dzFiles;
      // map api result file info with dragzone original metadata
      if (action.result.files) {
        for (let fileCnt = 0; fileCnt < action.result.files.length; fileCnt++) {
          let resultFile = action.result.files[fileCnt];
          for (let existingFileCnt = 0; existingFileCnt < state.dzFiles.length; existingFileCnt++) {
            let existingFile = state.dzFiles[existingFileCnt];
            if (resultFile.uuid === existingFile.uuid) {
              updateddDzFiles[existingFileCnt].category = resultFile.category;
            }
          }
        }
      }

      return {
        ...state,
        showDropZone: false,
        showAlertMessage: false,
        inProgressFileCount: state.dzFiles.length,
        uploadFinished: true,
        filesUploading: false,
        rejectReason: '',
        logo: action.result.new_file_name,
        dzFiles: updateddDzFiles
      };

    case 'LOGO_UPLOAD_FAIL':
      return {
        ...state,
        showAlertMessage: true,
        rejectReason: 'There was an error trying to upload your logo',
        inProgressFileCount: fileCount + 1,
        failedFileCount: failedFileCount + 1,
        uploadFinished: false,
        filesUploading: false,
        showDropZone: false,
        showDropZoneUploading: false
      };

    case 'LOGO_UPLOAD_PROGRESS':
      let filesUploading = true;
      if (action.event.progress === 100) {
        filesUploading = false;
      }

      return {
        ...state,
        filesUploading: filesUploading
      };

    case 'CLOSE_ERROR_MODAL':
      return {
        ...state,
        showErrorModal: false
      };

    default:
      return {
        ...state
      };
  }
}

export const actions = {};

actions.getSiteBasics = function getSiteBasics() {
  return (dispatch, getState) => {
    dispatch({
      types: ['GET_SITE_BASICS', 'GET_SITE_BASICS_SUCCESS', 'GET_SITE_BASICS_FAIL'],
      promise: client => client.get('/api/subsites/sites/getSiteInfo')
    });
  };
};

actions.getManagerEmails = function getManagerEmails() {
  return (dispatch, getState) => {
    dispatch({
      types: ['GET_MANAGER_EMAILS', 'GET_MANAGER_EMAILS_SUCCESS', 'GET_MANAGER_EMAILS_FAIL'],
      promise: client => client.get('/api/subsites/sites/internalClientManagerEmails')
    });
  };
};

actions.setDirtyState = function setDirtySate(basicIsDirty, changesToSave) {
  return (dispatch, getState) => {
    dispatch({
      type: 'SET_SITE_BASICS_DIRTY_STATE',
      basicIsDirty,
      changesToSave
    });
  };
};

actions.resetSiteBasicsForm = function resetSiteBasicsForm(originalValues) {
  return (dispatch, getState) => {
    dispatch({
      type: 'RESET_SITE_BASIC_FORM',
      originalValues
    });
  };
};

actions.closeErrorModal = function closeErrorModal(rowInfo) {
  return {
    type: 'CLOSE_ERROR_MODAL'
  };
};

actions.saveSiteBasics = function saveSiteBasics(options) {
  return (dispatch, getState) => {
    const options = getState().siteBasics.changesToSave;
    dispatch({
      types: ['SAVE_SITE_BASIC', 'SAVE_SITE_BASIC_SUCCESS', 'SAVE_SITE_BASIC_FAIL'],
      promise: client => client.post('/api/subsites/sites/update', { params: options }),
      options
    });
  };
};

actions.onDragEnter = function onDragEnter() {
  return (dispatch, getState) => {
    dispatch({
      type: 'LOGO_UPLOAD_ON_DRAG_ENTER',
      showDropZone: true
    });
  };
};

actions.onDragLeave = function onDragLeave() {
  return (dispatch, getState) => {
    dispatch({
      type: 'LOGO_UPLOAD_ON_DRAG_LEAVE',
      showDropZone: true
    });
  };
};

actions.fileUploadOnDrop = function fileUploadOnDrop(files) {
  // files is an array of files, from which ApiClient will attach files[0]
  files.forEach(function(file) {
    file.uuid = uuid.v4();
  });

  const theFile = files;

  return {
    types: ['LOGO_UPLOAD', 'LOGO_UPLOAD_SUCCESS', 'LOGO_UPLOAD_FAIL', 'LOGO_UPLOAD_PROGRESS'],
    promise: client => client.post('/api/subsites/uploads/logo', { files }),
    showDropZone: false,
    dzFiles: files
  };
};

actions.onDropRejected = function onDropRejected(files, rejectReason, mimeTypes, maxFileSize) {
  return (dispatch, getState) => {
    dispatch({
      type: 'LOGO_UPLOAD_ON_DROP_REJECTED',
      dzRejectedFiles: files,
      rejectReason: rejectReason,
      mimeTypes: mimeTypes,
      maxFileSize: maxFileSize
    });
  };
};

export const siteBasicsActions = mapDispatchToActions(actions);
