const PASSWORD = 'PASSWORD';
const PASSWORD_SUCCESS = 'PASSWORD_SUCCESS';
const PASSWORD_FAILURE = 'PASSWORD_FAIL';

const SUBMIT_MODAL_OPEN = 'SUBMIT_MODAL_OPEN';
const SUBMIT_MODAL_CLOSE = 'SUBMIT_MODAL_CLOSE';

const initialState = {
  newPassword: '',
  confirmNewPassword: '',
  openSubmitModal: false
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case PASSWORD:
      return {
        ...state,
        loading: true,
        query: action.query
      };
    case PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: null,
        openSubmitModal: false,
        items: action.result
      };
    case PASSWORD_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        items: null,
        error: action.error
      };
    case SUBMIT_MODAL_OPEN:
      return {
        ...state,
        openSubmitModal: true
      };
    case SUBMIT_MODAL_CLOSE:
      return {
        ...state,
        openSubmitModal: false
      };
    default:
      return { ...state };
  }
}

export function doResetPassword(options = { params: { newPassword: '', newConfirmPassword: '' } }) {
  return {
    types: [PASSWORD, PASSWORD_SUCCESS, PASSWORD_FAILURE],
    promise: client => client.post('/api/util/profile/setPassword', { params: options.params }),
    query: options.params
  };
}

export function submitModalOpen() {
  return {
    type: 'SUBMIT_MODAL_OPEN'
  };
}

export function submitModalClose() {
  return {
    type: 'SUBMIT_MODAL_CLOSE'
  };
}
