export const hrisGroup = {
  show: true,
  description: 'Employees, Jobs, Structures, and Pay History',
  title: 'HRIS Data',
  order: 1
};
export const surveyGroup = {
  show: true,
  description: 'Survey Job Descriptions, Matches, Participants, and Data',
  title: 'Survey Data',
  order: 4
};
export const jobDescriptionsGroup = {
  show: true,
  description: 'Benchmarking Job Descriptions',
  title: 'Job Descriptions',
  order: 3
};
export const otherGroup = {
  show: true,
  description: 'Job code changes, deletions, all other file types',
  title: 'All Other File Types',
  order: 2
};
