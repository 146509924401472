import PropTypes from 'prop-types';
import React, { Component } from 'react';

export default class ListRowChildren extends Component {
  static propTypes = {
    children: PropTypes.array,
    getColData: PropTypes.func,
    fieldTypeClassFormatters: PropTypes.object,
    item: PropTypes.object
  };

  render() {
    if (!this.props.children || !this.props.children.length) return null;
    const { item } = this.props;

    const children = this.props.children.map(child => {
      let data = this.props.getColData(child, item);

      let title = child.title;

      const optional = child.required === undefined || child.required === false;
      if (optional && child.field_type_code === 'text' && !data) {
        data = 'Not set';
      }

      let itemClassName = child.className || '';
      if (itemClassName === 'VALUE') itemClassName = data;
      else {
        const classFormatter =
          this.props.fieldTypeClassFormatters && this.props.fieldTypeClassFormatters[child.field_type_code];

        if (classFormatter) {
          itemClassName = classFormatter(child, item);
        }
      }

      return data ? (
        <span key={title} className={'gridItem__detail ' + itemClassName}>
          <span>{title}</span> <span className="gridItem__detail-data">{data}</span>
        </span>
      ) : null;
    });

    return <div>{children}</div>;
  }
}
