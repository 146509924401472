import React, { Component } from 'react';
import { array, bool, func, object } from 'prop-types';
import { connect } from 'react-redux';
import classnames from 'classnames';
import BuildMinMaxByDropdown from '../../../RangeSettings/BuildMinMaxBy/BuildMinMaxByDropdown';
import MidpointAdjustmentSetting from '../../../RangeSettings/MidPointSetting/MidpointAdjustmentSetting';
import PercentageOfMid from '../../../RangeSettings/BuildMinMaxBy/PercentageOfMid';
import ProposedManualSetting from '../../../RangeSettings/ProposedManualSetting';
import ProposedRangeSetting from '../../../RangeSettings/MidPointSetting/ProposedRangeSetting';
import RangeTypeSetting from '../../../RangeSettings/RangeTypeSetting';
import TargetRangeSpreadSetting from '../../../RangeSettings/BuildMinMaxBy/TargetRangeSpreadSetting';
import * as JobDetailsSlideOutSettingsActions from '../../../actions/jobRangeDetails/JobDetailsSlideOutSettings';
import * as JobUtils from '@payscale/ps-isomorphic/dist/JobRanges/Utils';
import { applyRounding, parseNumCheckNull } from '../../../../../lib/formatters';
import {
  ANNUAL_PAY_TYPE,
  BUILD_BY_MANUAL,
  BUILD_BY_PERCENTAGE,
  BUILD_BY_TARGET,
  MANUAL_RANGE_TYPE,
  MARKET_RANGE_TYPE,
  MIDPOINT_RANGE_TYPE
} from '../../../Utils/constants';
import './JobDetailsSlideOutSettings.scss';

export class JobDetailsSlideOutSettings extends Component {
  static propTypes = {
    handleMidpointAdjustmentClick: func.isRequired,
    handleMidpointAdjustmentValueUpdate: func.isRequired,
    handleProposedMidpointValueUpdate: func.isRequired,
    handleProposedRangeSelect: func.isRequired,
    handleRangeSpreadChange: func.isRequired,
    handleRangeTypeOptionClick: func.isRequired,
    handleResetToGlobalClick: func.isRequired,
    handleToggleShowEditRangeModel: func.isRequired,
    job: object,
    model: object,
    rangeOptions: array,
    roundOptions: array,
    showEditJobRangeModel: bool
  };

  constructor() {
    super();
    this.getSettings = this.getSettings.bind(this);
  }

  getSettings() {
    const { job, model } = this.props;
    const settings = JobUtils.getJobSettings(model, job);
    if (settings.rangeType === null || settings.rangeType === undefined) {
      settings.rangeType = MARKET_RANGE_TYPE;
    }
    if (settings.proposedRangeSetting === undefined) {
      settings.proposedRangeSetting = null;
    }
    if (settings.targetRangeSpread === null || settings.targetRangeSpread === undefined) {
      settings.targetRangeSpread = 0;
    }
    if (settings.midpointAdjustment === null || settings.midpointAdjustment === undefined) {
      settings.midpointAdjustment = 0;
    }
    if (settings.proposedMidpoint === null || settings.proposedMidpoint === undefined) {
      settings.proposedMidpoint = 0;
    }
    if (settings.payType === null || settings.payType === undefined) {
      settings.payType = ANNUAL_PAY_TYPE;
    }

    return settings;
  }

  render() {
    const { handleSaveBuildMinMaxBy, handleToggleShowEditRangeModel, showEditJobRangeModel } = this.props;
    const settings = this.getSettings();

    return (
      <div className="job__expandable-header" data-test-id="jbr-job-settings">
        <div
          className={classnames('top-bar', { expandable: showEditJobRangeModel })}
          data-test-id="jbr-job-settings-expand-bar"
          onClick={handleToggleShowEditRangeModel}
        >
          <span className="span--top-bar">
            Current Target for Job: <b className="summary__text-bold">{this.getSummaryText(settings)}</b>
          </span>
          <i className={showEditJobRangeModel ? 'icon-up-open' : 'icon-down-open'} />
        </div>
        <div className="stat__group-container">
          <div className="stat__group-row">
            {this.renderRangeTypeComponent(settings)}
            {this.renderGlobalResetComponent(settings)}
          </div>
          <div className="stat__group-row">
            <BuildMinMaxByDropdown
              buildMinMaxBy={settings.buildMinMaxBy}
              saveBuildMinMaxBy={handleSaveBuildMinMaxBy}
              rangeType={settings.rangeType}
            />
          </div>
          <div className="stat__group-row">
            {settings.buildMinMaxBy === BUILD_BY_MANUAL && settings.rangeType === MANUAL_RANGE_TYPE ? (
              <React.Fragment>
                <ProposedManualSetting
                  currentProposedValue={settings.proposedMinimum}
                  handleProposedValueUpdate={this.props.handleProposedMinValueUpdate}
                  label="Minimum"
                  payType={settings.payType}
                  preferences={this.props.preferences}
                />
                <ProposedManualSetting
                  currentProposedValue={settings.proposedMidpoint}
                  handleProposedValueUpdate={this.props.handleProposedMidpointValueUpdate}
                  label="Midpoint"
                  payType={settings.payType}
                  preferences={this.props.preferences}
                />
                <ProposedManualSetting
                  currentProposedValue={settings.proposedMaximum}
                  handleProposedValueUpdate={this.props.handleProposedMaxValueUpdate}
                  label="Maximum"
                  payType={settings.payType}
                  preferences={this.props.preferences}
                />
              </React.Fragment>
            ) : (
              <React.Fragment>
                {this.renderMarketOrMidpointComponent(settings)}
                {this.renderRangeSliderComponent(settings)}
              </React.Fragment>
            )}
          </div>
        </div>
      </div>
    );
  }

  getSummaryText(settings) {
    const { model, preferences, rangeOptions } = this.props;
    let summaryText = 'Range Has Not Been Set';

    if (settings.proposedRangeSetting || settings.rangeType === MIDPOINT_RANGE_TYPE) {
      const formattedTargetRangeSpread = Math.round(settings.targetRangeSpread * 100);
      const minPercent = Math.round(settings.minPercentageOfMid * 100);
      const maxPercent = Math.round(settings.maxPercentageOfMid * 100);

      switch (settings.rangeType) {
        case MARKET_RANGE_TYPE:
          const selectedRangeOption = rangeOptions.find(opt => opt.value === settings.proposedRangeSetting);

          if (settings.buildMinMaxBy === BUILD_BY_TARGET) {
            summaryText = `${selectedRangeOption?.name}, Range Spread of ${formattedTargetRangeSpread}%`;
          } else {
            summaryText = `${selectedRangeOption?.name}, Min ${minPercent}%, Max ${maxPercent}%`;
          }
          break;
        case MIDPOINT_RANGE_TYPE:
          const midPercent = Math.round(settings.midpointAdjustment * 100);

          if (settings.buildMinMaxBy === BUILD_BY_TARGET) {
            summaryText = `Mid ${midPercent}%, Range Spread of ${formattedTargetRangeSpread}%`;
          } else {
            summaryText = `Mid ${midPercent}%, Min is ${minPercent}% of mid, Max is ${maxPercent}% of mid`;
          }
          break;
        case MANUAL_RANGE_TYPE:
          const newMidpoint = parseNumCheckNull(
            applyRounding(settings.proposedMidpoint, 'monetary', preferences, model.payType)
          );

          if (settings.buildMinMaxBy === BUILD_BY_TARGET) {
            summaryText = `${newMidpoint}, Range Spread of ${formattedTargetRangeSpread}%`;
          } else if (settings.buildMinMaxBy === BUILD_BY_PERCENTAGE) {
            summaryText = `${newMidpoint}, Min is ${minPercent}% of mid, Max is ${maxPercent}% of mid`;
          } else {
            const newMinimum = parseNumCheckNull(
              applyRounding(settings.proposedMinimum, 'monetary', preferences, model.payType)
            );
            const newMaximum = parseNumCheckNull(
              applyRounding(settings.proposedMaximum, 'monetary', preferences, model.payType)
            );
            summaryText = `${newMinimum} / ${newMidpoint} / ${newMaximum}`;
          }
          break;
      }

      return summaryText;
    }
  }

  renderRangeTypeComponent(settings) {
    return (
      <RangeTypeSetting
        className="job__range-type-container"
        handleRangeTypeOptionClick={this.onRangeTypeChange}
        rangeType={settings.rangeType}
      />
    );
  }

  onRangeTypeChange = async (id, optionValue) => {
    const { handleSaveBuildMinMaxBy, handleRangeTypeOptionClick, job } = this.props;
    await handleRangeTypeOptionClick(id, optionValue);
    if (
      job.build_min_max_by === 'manual' &&
      job.range_type === 'manual' &&
      optionValue.toLowerCase() !== job.range_type
    ) {
      handleSaveBuildMinMaxBy('target');
    }
  };

  renderGlobalResetComponent() {
    const buttonText = 'Reset to Global';
    const { handleResetToGlobalClick, job } = this.props;

    if (!JobUtils.doesJobContainSpecificRangeDetails(job)) return null;

    return (
      <button
        className="pxl-btn pxl-btn-primary options-reset-global"
        data-test-id="jbr-setting-global-reset-button"
        onClick={handleResetToGlobalClick}
      >
        {buttonText}
      </button>
    );
  }

  renderMarketOrMidpointComponent(settings) {
    let component = null;
    switch (settings.rangeType) {
      case MARKET_RANGE_TYPE:
        component = this.renderProposedRangeComponent(settings);
        break;
      case MIDPOINT_RANGE_TYPE:
        component = this.renderMidpointAdjustmentComponent(settings);
        break;
      case MANUAL_RANGE_TYPE:
        component = this.renderProposedMidpointComponent(settings);
        break;
    }

    return <div className="stat__group">{component}</div>;
  }

  renderProposedRangeComponent(settings) {
    const { handleProposedRangeSelect, rangeOptions } = this.props;

    return (
      <ProposedRangeSetting
        handleProposedRangeSelect={handleProposedRangeSelect}
        proposedRangeSetting={settings.proposedRangeSetting}
        rangeOptions={rangeOptions}
      />
    );
  }

  renderProposedMidpointComponent(settings) {
    const { handleProposedMidpointValueUpdate, preferences } = this.props;
    return (
      <ProposedManualSetting
        currentProposedValue={settings.proposedMidpoint}
        handleProposedValueUpdate={handleProposedMidpointValueUpdate}
        label="Midpoint"
        payType={settings.payType}
        preferences={preferences}
      />
    );
  }

  renderMidpointAdjustmentComponent(settings) {
    const { handleMidpointAdjustmentClick, handleMidpointAdjustmentValueUpdate } = this.props;

    return (
      <MidpointAdjustmentSetting
        className="slideout__midpoint-adjustment"
        handleMidpointAdjustmentClick={handleMidpointAdjustmentClick}
        handleMidpointAdjustmentValueUpdate={handleMidpointAdjustmentValueUpdate}
        midpointAdjustment={settings.midpointAdjustment}
      />
    );
  }

  renderRangeSliderComponent(settings) {
    const { handleMaxPercentChange, handleMinPercentChange, handleRangeSpreadChange } = this.props;

    return (
      <div className="stat__group">
        {(settings.buildMinMaxBy === BUILD_BY_TARGET ||
          (settings.buildMinMaxBy === BUILD_BY_MANUAL && settings.rangeType !== MANUAL_RANGE_TYPE)) && (
          <TargetRangeSpreadSetting
            handleRangeSpreadChange={handleRangeSpreadChange}
            isGlobal={false}
            targetRangeSpread={settings.targetRangeSpread}
            isSlideoutOpen={this.props.isSlideoutOpen}
          />
        )}
        {settings.buildMinMaxBy === BUILD_BY_PERCENTAGE && (
          <PercentageOfMid
            handleMaxPercentChange={this.props.handleMaxPercentChange}
            handleMinPercentChange={this.props.handleMinPercentChange}
            proposedMaxSelect={this.props.handleMaxElementChange}
            proposedMinSelect={this.props.handleMinElementChange}
            isGlobal={false}
            isSlideoutOpen={this.props.isSlideoutOpen}
            maxPercentageOfMid={settings.maxPercentageOfMid}
            maxPercentageMarketElement={settings.maxPercentageMarketElement}
            minPercentageOfMid={settings.minPercentageOfMid}
            minPercentageMarketElement={settings.minPercentageMarketElement}
            rangeType={settings.rangeType}
            setProposedRangeOptions={this.props.rangeOptions}
          />
        )}
      </div>
    );
  }
}

const connectState = ({ jobRanges, session, slideout }) => ({
  isSlideoutOpen: slideout.open,
  job: jobRanges.jobRangeDetails.job,
  model: jobRanges.modelDetails.jobRangeModel,
  preferences: session?.user?.preferences,
  rangeOptions: jobRanges.modelDetails.setProposedRangeOptions,
  roundOptions: jobRanges.modelDetails.roundOptions,
  showEditJobRangeModel: jobRanges.jobRangeDetails.showEditJobRangeModel
});

const actions = {
  ...JobDetailsSlideOutSettingsActions
};
export default connect(connectState, actions)(JobDetailsSlideOutSettings);
