import PropTypes from 'prop-types';
import React, { Component } from 'react';

import './LoadingIndicator.scss';

export default class LoadingIndicator extends Component {
  static propTypes = {
    loading: PropTypes.bool,
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
  };

  indicator() {
    return (
      <div className="div__container--loadingIndicator" data-test-id="loading">
        <div className="div--loadingIndicator">
          <svg className="loadingIndicator--circle" viewBox="25 25 50 50">
            <circle className="circle--path" cx="50" cy="50" r="20" fill="none" strokeWidth="2" strokeMiterlimit="10" />
          </svg>
        </div>
      </div>
    );
  }

  // IE compatible loader
  ieLoader() {
    return (
      <div className="div__container--loadingIndicator">
        <div className="loading__indicator">
          <div className="loading__indicator-wrapper dot-1">
            <div className="loading__indicator-dot" />
          </div>
          <div className="loading__indicator-wrapper dot-2">
            <div className="loading__indicator-dot" />
          </div>
          <div className="loading__indicator-wrapper dot-3">
            <div className="loading__indicator-dot" />
          </div>
          <div className="loading__indicator-wrapper dot-4">
            <div className="loading__indicator-dot" />
          </div>
          <div className="loading__indicator-wrapper dot-5">
            <div className="loading__indicator-dot" />
          </div>
        </div>
      </div>
    );
  }

  render() {
    if (!this.props.loading) return this.props.children || null;

    if (!React.Children.count(this.props.children)) {
      return !window.navigator.userAgent.includes('Windows') ? this.indicator() : this.ieLoader();
    }

    return (
      <div style={{ position: 'relative' }}>
        {!window.navigator.userAgent.includes('Windows') ? this.indicator() : this.ieLoader()}
        {this.props.children}
      </div>
    );
  }
}
