import React, { useEffect } from 'react';
import { func, number, string, bool } from 'prop-types';
import SlideoutBody from 'ps-components/src/Slideout/Body/SlideoutBody';
import SlideoutHeaderButton from 'ps-components/src/Slideout/Header/SlideoutHeaderButton';
import InPlaceIndicator from 'ps-components/src/LoadingIndicator/InPlaceIndicator/InPlaceIndicator';
import Placeholder from 'ps-components/src/Placeholder/Placeholder';
import Alert from '@payscale/design/lib/alert';
import FileViewer from 'ps-components/src/ReactFileViewer';
import DropzoneComponent from './Dropzone';

import './JobDescriptionSlideout.scss';

JobDescriptionSlideout.propTypes = {
  className: string,
  downloadJobDescription: func,
  downloadURL: string,
  fileType: string,
  handleCloseSlideout: func,
  handleDeleteJobDescription: func,
  handleEditJobDescription: func,
  handleCreateJobDescription: func,
  HRTMSEnabled: bool,
  isDropzoneEnabledForError: bool,
  isDropzoneEnabledWithoutJdx: bool,
  isLoading: bool,
  isPlaceholderEnabled: bool,
  jobDescriptionError: string,
  orgJobCode: string,
  orgJobCodeKey: string,
  orgJobId: number,
  orgJobTitle: string,
  previewURL: string,
  resetJobDescription: func,
  setJobDescriptionError: func,
  uploadJobDescription: func
};

function JobDescriptionSlideout(props) {
  useEffect(() => {
    if (props.orgJobId && props.orgJobCode)
      props.downloadJobDescription({ id: props.orgJobId, code: props.orgJobCode });
  }, [props.orgJobId, props.orgJobCode]);

  function getDropzoneComponent() {
    return (
      <DropzoneComponent
        onUpload={file => (props.uploadJobDescription ? props.uploadJobDescription(file, props.orgJobId) : null)}
        setError={props.setJobDescriptionError}
      />
    );
  }

  return (
    <div className={`job-description-slideout ${props.className ?? ''}`}>
      <div className="slideout-header title-row">
        <div className="title-container">
          <div className="title">Job Description for</div>
          <div className="title-text">{props.orgJobTitle}</div>
        </div>
        <div className="job-description-slideout__icons-container">
          {props.handleDeleteJobDescription ? (
            <div className="job-description-slideout__icon-container" onClick={props.handleDeleteJobDescription}>
              <i className="icon-trash" />
              <div className="job-description-slideout__icon-text">Delete</div>
            </div>
          ) : null}

          {props.handleEditJobDescription ? (
            <a className="job-description-slideout__icon-container" onClick={props.handleEditJobDescription}>
              <i className="icon-pencil" />
              <div className="job-description-slideout__icon-text">Edit Job Description</div>
            </a>
          ) : null}

          {props.handleCreateJobDescription && !props.isLoading ? (
            <a className="job-description-slideout__icon-container" onClick={props.handleCreateJobDescription}>
              <i className="icon-plus" />
              <div className="job-description-slideout__icon-text">Create Job Description</div>
            </a>
          ) : null}

          {props.downloadURL && (
            <a className="job-description-slideout__icon-container" href={props.downloadURL}>
              <i className="icon-download" />
              <div className="job-description-slideout__icon-text">Download</div>
            </a>
          )}

          <SlideoutHeaderButton
            buttonClass="slideout-header__button"
            iconClass="icon-cancel"
            onClick={props.handleCloseSlideout}
            testId="market-pricing__job-description-close"
          />
        </div>
      </div>

      {props.jobDescriptionError ? (
        <>
          <Alert className="pxl-margin-bottom-large" message={props.jobDescriptionError} close={true} />
          {props.isDropzoneEnabledForError ? getDropzoneComponent() : null}
        </>
      ) : props.isLoading ? (
        <InPlaceIndicator />
      ) : (
        <SlideoutBody>
          {props.previewURL && (
            <FileViewer fileType={props.fileType} url={props.previewURL} onError={props.resetJobDescription} />
          )}

          {props.isPlaceholderEnabled ? (
            <Placeholder
              className="job-description__not-found-container"
              primaryText={
                <span>
                  Job Description not available for <b>{props.orgJobTitle}</b>
                </span>
              }
              showText
            />
          ) : null}
          {props.isDropzoneEnabledWithoutJdx ? getDropzoneComponent() : null}
        </SlideoutBody>
      )}
    </div>
  );
}

export default JobDescriptionSlideout;
