import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ListPanel from '../../../../ListPanel/ListPanel';
import './JobRangesSummarySlideOut.scss';
import { getUseJobCodeKey } from '../../../../redux/stateSelectors';

const propTypes = {
  hideSlideout: PropTypes.func,
  jobClick: PropTypes.func,
  stats: PropTypes.object,
  jobRangeModel: PropTypes.object,
  searchInputSearch: PropTypes.func,
  useJobCodeKey: PropTypes.bool
};
const defaultProps = {
  stats: {
    jobsNotMoved: []
  }
};

const JobRangesSummarySlideOut = props => {
  const { hideSlideout, jobClick: jobItemClick, stats, jobRangeModel, useJobCodeKey } = props;

  return (
    <div className="jobRangesSummarySlideOut">
      <div className="contents__header">
        <div className="contents__header-title">
          <div className="titleDetails">
            <h4>Result Summary for</h4>
          </div>
          <div className="title">
            <h3>{jobRangeModel && jobRangeModel.name}</h3>
          </div>
        </div>

        <div className="contents__header-actions">
          <button className="contents__header-close" onClick={hideSlideout}>
            <i className="icon-cancel" />
          </button>
        </div>
      </div>

      <div className="contents__main">
        <h3>Summary</h3>

        <div className={'jobRanges-summary-stats-bar '}>
          <div className="stat-container">
            <span className="stat-heading">Jobs in Model</span>
            <span className="stat-number">{stats.oss_insert_count || 0}</span>
          </div>
          <div className="stat-container">
            <span className="stat-heading">Ranges Updated</span>
            <span className="stat-number">{stats.proposed_jobs_count || 0}</span>
          </div>
          <div className="stat-container">
            <span className="stat-heading">Ranges Not Updated</span>
            <span className="stat-number not-moved">{stats.jobs_not_moved_count || 0}</span>
          </div>
        </div>

        <span className="list-heading">The following jobs did not receive new ranges</span>

        <div className="summaryJobsList">
          <ListPanel
            sort={{}}
            hideCheckBox
            hideTableHeader
            pagination={{
              perPageCount: 10,
              pageNumber: 1,
              searchResultsCount: 0
            }}
            expandedMaxColumnCount={1}
            idProp="job_range_job_id"
            columns={[
              {
                title: 'Job Title',
                dataKey: 'org_job_title',
                field_type_code: 'text'
              },
              {
                title: 'Job Code Key',
                dataKey: 'org_job_code_key',
                field_type_code: 'text'
              },
              {
                title: 'Job Code',
                dataKey: 'org_job_code',
                field_type_code: 'text'
              }
            ].filter(col => col.dataKey !== 'org_job_code_key' || useJobCodeKey)}
            rowClick={jobItemClick}
            rowCheckBoxClick={jobItemClick}
            selectedListRows={[]}
            items={stats.jobsNotMoved}
          />
        </div>
      </div>
    </div>
  );
};

JobRangesSummarySlideOut.propTypes = propTypes;
JobRangesSummarySlideOut.defaultProps = defaultProps;

const connectState = state => ({
  ...state.jobRanges.moveToCurrentStats,
  jobRangeModel: state.jobRanges.modelDetails.jobRangeModel,
  useJobCodeKey: getUseJobCodeKey(state)
});
const actions = {};
export default connect(connectState, actions)(JobRangesSummarySlideOut);
