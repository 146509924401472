import { ParticipationMatchOrgJob } from '../../../../types/MatchReviewTypes';
import { arrayOf, func } from 'prop-types';
import './OrgJobMatchList.scss';
import * as MatchReviewUtils from '../../../../utils/MatchReviewUtils';
import * as RenderUtils from '../../../../utils/RenderUtils';
import React from 'react';

const propTypes = {
  duplicateMatches: arrayOf(ParticipationMatchOrgJob).isRequired,
  selection: ParticipationMatchOrgJob,
  doSelect: func.isRequired
};

const isMatchEqual = (match1, match2) => {
  return match1 && match2 && match1.orgJobCode === match2.orgJobCode && match1.orgJobCodeKey === match2.orgJobCodeKey;
};

const OrgJobMatchList = props => {
  const { duplicateMatches, selection, doSelect } = props;
  const reviewOrgJobCount = MatchReviewUtils.getNumberOfOrgJobsWithUnresolvedDuplicateMatches(duplicateMatches);

  return (
    <div className="pcenter-review-duplicates__org-job-list">
      <div className="pcenter-review-duplicates-org-job-list__header">
        <div className="pcenter-review-duplicates-org-job-list__instructions">Review jobs with duplicate matches</div>
        <h1 className="pcenter-review-duplicates-org-job-list__count">{reviewOrgJobCount}</h1>
      </div>
      <div className="pcenter-review-duplicates-org-job-list__rows">
        {duplicateMatches &&
          duplicateMatches.map(m => {
            const isSelected = isMatchEqual(selection, m);
            const isPendingResolution = !MatchReviewUtils.areOrgJobDuplicatesUnresolved(m);
            return (
              <div
                className={RenderUtils.getModifiedClassName('pcenter-review-duplicates-org-job-list__row', isSelected)}
                key={m.orgJobCode + m.orgJobCodeKey}
                onClick={() => doSelect(m)}
              >
                <div>
                  <div
                    className={RenderUtils.getModifiedClassName(
                      'pcenter-review-duplicates-org-job-list-row__title',
                      isSelected
                    )}
                  >
                    {m.orgJobTitle}
                  </div>
                  <div className="pcenter-review-duplicates-org-job-list-row__details">
                    <div className="pcenter-review-duplicates-org-job-list-row__info">
                      <label className="pcenter-review-duplicates-org-job-list-row__label">Job Code</label>
                      <div className="pcenter-review-duplicates-org-job-list-row__value">{m.orgJobCode}</div>
                    </div>
                    <div className="pcenter-review-duplicates-org-job-list-row__info">
                      <label className="pcenter-review-duplicates-org-job-list-row__label">Job Code Key</label>
                      <div className="pcenter-review-duplicates-org-job-list-row__value">{m.orgJobCodeKey}</div>
                    </div>
                  </div>
                </div>
                {isPendingResolution && (
                  <icon
                    className={
                      RenderUtils.getModifiedClassName(
                        'pcenter-review-duplicates-org-job-list-row__resolved-icon',
                        isSelected
                      ) + ' icon-check'
                    }
                  />
                )}
              </div>
            );
          })}
      </div>
    </div>
  );
};

OrgJobMatchList.propTypes = propTypes;
export default OrgJobMatchList;
