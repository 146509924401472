const EMPLOYEE_COUNT = 'EMPLOYEE_COUNT';
const EMPLOYEE_COUNT_SUCCESS = 'EMPLOYEE_COUNT_SUCCESS';
const EMPLOYEE_COUNT_FAIL = 'EMPLOYEE_COUNT_FAIL';

const initialState = {
  loading: false,
  loaded: false,
  error: null,
  numberOfEmployees: null
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case EMPLOYEE_COUNT:
      return {
        ...state,
        loading: true
      };
    case EMPLOYEE_COUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: null,
        numberOfEmployees: action.result.numberOfEmployees
      };
    case EMPLOYEE_COUNT_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        data: null,
        error: action.error
      };
    default:
      return { ...state };
  }
}

export function getHomeEmployeeCount(params = { es_query: 'viewed' }) {
  return {
    types: [EMPLOYEE_COUNT, EMPLOYEE_COUNT_SUCCESS, EMPLOYEE_COUNT_FAIL],
    promise: client => client.get('/api/hris/employees/totalEmployees', { params: params })
  };
}
