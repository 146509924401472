import SummaryStats from '../genericGrid/summaryStats';

const initialState = {
  showSummaryStats: true,
  items: []
};

export const jobSummaryStats = new SummaryStats('JOBS', initialState);

function extendReducer(state, action) {
  switch (action.type) {
    default:
      return null;
  }
}

export const reducer = jobSummaryStats.createReducer(extendReducer, {});

export const actions = jobSummaryStats.getActions();
export const selectors = jobSummaryStats.getSelectors();
