import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { withRouter } from 'react-router-dom';

import './Slideout.scss';

class Slideout extends Component {
  constructor(props) {
    super(props);
    this.closePanel = this.closePanel.bind(this);
    this.handleBrowserBack = this.handleBrowserBack.bind(this);
  }

  componentDidMount() {
    document.addEventListener('keydown', e => this.closePanel(e));
    window.addEventListener('popstate', this.handleBrowserBack);
  }

  UNSAFE_componentWillUnmount() {
    document.removeEventListener('keydown', e => this.closePanel(e));
    window.removeEventListener('popstate', this.handleBrowserBack);
  }

  handleBrowserBack() {
    this.props.hideSlideout({ browserBack: true });
  }

  closePanel = e => {
    if (
      !e ||
      (((e && e.key === 'Escape' && !this.props.disableEscape) || e.target.className === 'slideout__overlay') &&
        this.props.slideout.conditionalSlideout !== false)
    ) {
      this.props.hideSlideout();
    }
  };

  render() {
    const { open, noOverlay } = this.props;

    return (
      <div className={classnames('slideout', { toggled: open, 'no-overlay': noOverlay })} id="slideout">
        {!noOverlay && <div className="slideout__overlay" onClick={this.closePanel} />}
        <div className={`${this.props.className} slideout__container`}>{this.props.children}</div>
      </div>
    );
  }
}

Slideout.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  hideSlideout: PropTypes.func,
  slideout: PropTypes.object,
  open: PropTypes.bool,
  noOverlay: PropTypes.bool,
  disableEscape: PropTypes.bool
};

export default withRouter(Slideout);
