import React, { useEffect, useRef, useState } from 'react';
import { string, func, bool, object, node, arrayOf, oneOfType } from 'prop-types';
import classnames from 'classnames';

import './Popover.scss';

function Popover(props) {
  const [style, setStyle] = useState({});
  const { popoverText, display, togglePopover, popoverClassnames, relativeElement, triggerElement, arrowAlign } = props;
  const popoverRef = useRef(null);

  useEffect(() => {
    if (triggerElement && relativeElement) {
      //Get trigger element's location
      const triggerElementDimensions = triggerElement?.current?.getBoundingClientRect();
      //Get relative element's document location
      const relativeElementDimensions = relativeElement?.getBoundingClientRect();
      const style = {};
      //Simple math to position the element properly
      style.left = triggerElementDimensions.left - relativeElementDimensions.left;
      style.top = triggerElementDimensions.top - (relativeElementDimensions.top - 25);
      setStyle(style);
    }
  }, [triggerElement, relativeElement]);

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const handleClickOutside = event => {
    if (popoverRef.current && !popoverRef.current.contains(event.target)) {
      togglePopover();
    }
  };

  if (!display) {
    return null;
  }

  const classNames = classnames(
    {
      'ps-popover': true
    },
    popoverClassnames
  );

  return (
    <div className={classnames('ps-popover__wrapper', arrowAlign)} style={style}>
      <div ref={popoverRef} className={classNames}>
        {!props.hideClose && (
          <div className="ps-popover__close">
            <i className="icon-cancel" onClick={togglePopover} />
          </div>
        )}
        {props.children ? props.children : <div className="popover__text">{popoverText}</div>}
      </div>
    </div>
  );
}

Popover.propTypes = {
  popoverText: string,
  display: bool,
  togglePopover: func,
  popoverClassnames: string,
  relativeElement: object,
  triggerElement: object,
  arrowAlign: string,
  hideClose: bool,
  children: oneOfType([arrayOf(node), node])
};

Popover.defaultProps = {
  arrowAlign: 'left'
};
export default Popover;
