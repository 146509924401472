import apiClient from '../../../lib/apiClient';
import {
  calcProposedRanges,
  JOB_RANGE_MODEL_JOBS_CHANGE,
  RANGES_ROUND_TO_CHANGE,
  saveJobRangeModel
} from './expandableHeader';
import { slideoutActions } from '../../../Slideout/SlideoutReducer';
import { jobRangesSearchActions, searchJobsWithLimit } from '../reducers/jobRanges/addJobsToModel/jobRangesSearch';
import { getCSRFPair, getCurrencies, getSessionCurrency } from '../../../redux/stateSelectors';
import { payTypes, annualRoundOptions, hourlyRoundOptions } from '../reducers/jobRanges/modelDetails/modelDetails';
import { getDefaultRoundings } from '../../../lib/formatters';
import { ANNUAL_PAY_TYPE, BUILD_BY_TARGET, MARKET_RANGE_TYPE, MIDPOINT_RANGE_TYPE } from '../Utils/constants';

export const JOB_RANGES_RANGE_TYPE_OPTIONS = 'JOB_RANGES_RANGE_TYPE_OPTIONS';
export const JOB_RANGES_RANGE_TYPE_OPTIONS_FAIL = 'JOB_RANGES_RANGE_TYPE_OPTIONS_FAIL';
export const JOB_RANGES_UPDATE_SELECTED_JOBS = 'JOB_RANGES_UPDATE_SELECTED_JOBS';
export const JOB_RANGES_NAME_INPUT_CHANGE = 'JOB_RANGES_NAME_INPUT_CHANGE';
export const TOGGLE_EDIT_JOB_RANGES_MODEL = 'TOGGLE_EDIT_JOB_RANGES_MODEL';
export const ADD_JOBS_TO_JOB_RANGES_MODEL_CLICK = 'ADD_JOBS_TO_JOB_RANGES_MODEL_CLICK';
export const CLOSE_ADD_JOBS_TO_JOB_RANGES_MODEL = 'CLOSE_ADD_JOBS_TO_JOB_RANGES_MODEL';
export const GET_JOB_RANGE_MODELS_SUCCESS = 'GET_JOB_RANGE_MODELS_SUCCESS';
export const GET_JOB_RANGE_MODELS = 'GET_JOB_RANGE_MODELS';
export const CREATE_NEW_JOB_RANGE_MODEL = 'CREATE_NEW_JOB_RANGE_MODEL';
export const CREATE_NEW_JOB_RANGE_MODEL_SUCCESS = 'CREATE_NEW_JOB_RANGE_MODEL_SUCCESS';
export const GET_JOB_RANGE_MODELS_FAIL = 'GET_JOB_RANGE_MODELS_FAIL';
export const GET_SINGLE_JOB_RANGE_MODEL = 'GET_SINGLE_JOB_RANGE_MODEL';
export const GET_SINGLE_JOB_RANGE_MODEL_SUCCESS = 'GET_SINGLE_JOB_RANGE_MODEL_SUCCESS';
export const GET_SINGLE_JOB_RANGE_MODEL_FAIL = 'GET_SINGLE_JOB_RANGE_MODEL_FAIL';
export const TOGGLE_ALL_JOBS_VS_SELECTED_LIST = 'TOGGLE_ALL_JOBS_VS_SELECTED_LIST';
export const APPLY_JOBS_TO_RANGE = 'APPLY_JOBS_TO_RANGE';
export const APPLY_JOBS_TO_RANGE_SUCCESS = 'APPLY_JOBS_TO_RANGE_SUCCESS';
export const APPLY_JOBS_TO_RANGE_FAIL = 'APPLY_JOBS_TO_RANGE_FAIL';
export const CLEAR_JOB_RANGE_MODEL_DETAILS = 'CLEAR_JOB_RANGE_MODEL_DETAILS';
export const RANGES_PAY_TYPE_CHANGE = 'RANGES_PAY_TYPE_CHANGE';
export const JOB_RANGES_JOB_CLICK = 'JOB_RANGES_JOB_CLICK';
export const REFRESH_JOB_RANGE_MODEL_JOBS = 'REFRESH_JOB_RANGE_MODEL_JOBS';
export const REFRESH_JOB_RANGE_MODEL_JOBS_SUCCESS = 'REFRESH_JOB_RANGE_MODEL_JOBS_SUCCESS';
export const REFRESH_JOB_RANGE_MODEL_JOBS_FAIL = 'REFRESH_JOB_RANGE_MODEL_JOBS_FAIL';
export const JOB_RANGES_AVAILABLE_CHECK_ALL = 'JOB_RANGES_AVAILABLE_CHECK_ALL';
export const JOB_RANGES_SELECTED_CHECK_ALL = 'JOB_RANGES_SELECTED_CHECK_ALL';
export const JOB_RANGES_SEARCH_CLEAR_FACETS_FILTER = 'JOB_RANGES_SEARCH_CLEAR_FACETS_FILTER';
export const RANGES_CURRENCY_CHANGE = 'RANGES_CURRENCY_CHANGE';
export const JOB_RANGES_MOVE_TO_CURRENT = 'JOB_RANGES_MOVE_TO_CURRENT';
export const JOB_RANGES_MOVE_TO_CURRENT_SUCCESS = 'JOB_RANGES_MOVE_TO_CURRENT_SUCCESS';
export const JOB_RANGES_MOVE_TO_CURRENT_FAIL = 'JOB_RANGES_MOVE_TO_CURRENT_FAIL';
export const SHOW_MORE_MODEL_JOBS = 'GET_SELECTED_JOBS_WITH_DETAILS_FAIL';
export const SHOW_MORE_MODEL_JOBS_SUCCESS = 'GET_SELECTED_JOBS_WITH_DETAILS_SUCCESS';
export const SHOW_MORE_MODEL_JOBS_FAIL = 'GET_SELECTED_JOBS_WITH_DETAILS_FAIL';
export const DELETE_JOB_RANGE_MODEL_SUCCESS = 'DELETE_JOB_RANGE_MODEL_SUCCESS';
export const JOB_RANGE_MODELS_INIT = 'JOB_RANGE_MODELS_INIT';
export const JOB_RANGES_MODEL_SEARCH_INPUT_CHANGE = 'JOB_RANGES_MODEL_SEARCH_INPUT_CHANGE';
export const JOB_RANGES_JOB_SEARCH_INPUT_CHANGE = 'JOB_RANGES_JOB_SEARCH_INPUT_CHANGE';
export const JOB_RANGES_BULK_JOB_ADD_INIT = 'JOB_RANGES_BULK_JOB_ADD_INIT';
export const JOB_RANGES_BULK_JOB_ADD_SUCCESS = 'JOB_RANGES_BULK_JOB_ADD_SUCCESS';
export const JOB_RANGES_BULK_JOB_ADD_FAILURE = 'JOB_RANGES_BULK_JOB_ADD_FAILURE';
export const JOB_RANGES_BULK_JOB_CLEAR = 'JOB_RANGES_BULK_JOB_CLEAR';
export const JOB_RANGES_VIEW_SELECTED_JOBS_SHOW_MORE = 'JOB_RANGES_VIEW_SELECTED_JOBS_SHOW_MORE';
export const JOB_RANGES_SET_SORT = 'JOB_RANGES_SET_SORT';
export const JOB_RANGES_SEARCH_JOBS = 'JOB_RANGES_SEARCH_JOBS';
export const JOB_RANGES_INIT_SEARCH_JOBS = 'JOB_RANGES_INIT_SEARCH_JOBS';
export const JOB_RANGES_SEARCH_JOBS_FAIL = 'JOB_RANGES_SEARCH_JOBS_FAIL';
export const GET_JOB_SINGLE_RANGE_MODEL_SUCCESS = 'GET_JOB_SINGLE_RANGE_MODEL_SUCCESS';

//modelListPage actions
export const onModelSearchChange = event => {
  return { type: JOB_RANGES_MODEL_SEARCH_INPUT_CHANGE, searchInputValue: event?.target?.value || '' };
};

export function onJobSearchChange(event) {
  return { type: JOB_RANGES_JOB_SEARCH_INPUT_CHANGE, searchInputValue: event.target.value };
}

export const getModelList = id => async (dispatch, getState) => {
  try {
    dispatch({ type: GET_JOB_RANGE_MODELS });
    let result = await getJobRangeModels(getState, id);
    if (result.success === false) dispatch({ type: GET_JOB_RANGE_MODELS_FAIL });

    if (id) {
      dispatch({ type: GET_JOB_SINGLE_RANGE_MODEL_SUCCESS, result });
    } else {
      dispatch({ type: GET_JOB_RANGE_MODELS_SUCCESS, result });
    }
  } catch (err) {
    dispatch({ type: GET_JOB_RANGE_MODELS_FAIL });
  }
};
export const clearModelDetails = () => ({ type: CLEAR_JOB_RANGE_MODEL_DETAILS });

export const resetJobListDetails = () => async dispatch => {
  dispatch({ type: JOB_RANGES_SEARCH_CLEAR_FACETS_FILTER });
};

//ModelDetailsPage actions
export const onNameInputChange = event => {
  const action = { type: JOB_RANGES_NAME_INPUT_CHANGE, nameInputValue: event.target.value };
  return action;
};

export const toggleEditJobRangesModel = () => ({ type: TOGGLE_EDIT_JOB_RANGES_MODEL });

export const addJobsToModelClick = () => ({ type: ADD_JOBS_TO_JOB_RANGES_MODEL_CLICK });
export const closeAddJobsToModel = () => ({ type: CLOSE_ADD_JOBS_TO_JOB_RANGES_MODEL });

export const modelNameBlur = event => async (dispatch, getState) => {
  const { jobRangeModel } = getState().jobRanges.modelDetails;
  jobRangeModel.name = event.target.value;

  try {
    const csrfPair = getCSRFPair(getState());
    await apiClient.apiPost('/api/jobRanges/model/updateJobRangeModel', {
      jobRangeModel,
      ...csrfPair
    });
  } catch (err) {
    console.log('could not save job range model');
  }
};

const createDefaultJobRangeModel = (isLab, jobRangeModels, currentCurrency) => {
  const nameCountList = jobRangeModels.reduce((arr, model) => {
    if (model.name === 'New Model') arr.push(1);
    const match = model.name.match(/^New Model\((\d+)\)$/);
    if (match) arr.push(parseInt(match[1]));
    return arr;
  }, []);

  const appendName = nameCountList.length ? `(${Math.max(...nameCountList) + 1})` : '';

  const defaultSetting = isLab ? 'market_base_salary_50th' : 'target_base_salary';
  return {
    name: 'New Model' + appendName,
    status: 'new',
    facetSelection: null,
    rangeType: MARKET_RANGE_TYPE,
    payType: ANNUAL_PAY_TYPE,
    proposedRangeSetting: defaultSetting,
    targetRangeSpread: 0.5,
    midpointAdjustment: 0.03,
    roundTo: null,
    currencyId: currentCurrency.id === null ? null : currentCurrency.id,
    jobCount: 0,
    buildMinMaxBy: BUILD_BY_TARGET,
    minPercentageOfMid: 0.8,
    maxPercentageOfMid: 1.2,
    minPercentageMarketElement: defaultSetting,
    maxPercentageMarketElement: defaultSetting
  };
};

export const loadRangeTypeOptions = () => async dispatch => {
  const rangeOptionsResult = await apiClient.apiGet('/api/jobRanges/range-options/getJobRangeSettingOptions');
  if (rangeOptionsResult.success === false) dispatch({ type: JOB_RANGES_RANGE_TYPE_OPTIONS_FAIL });
  else {
    dispatch({ type: JOB_RANGES_RANGE_TYPE_OPTIONS, payload: rangeOptionsResult.data });
  }
};

export const createNewRangeModel = (isLab, history) => async (dispatch, getState) => {
  const { jobRangeModels } = getState().jobRanges.jobRanges;
  const currentCurrency = getSessionCurrency(getState());
  const model = createDefaultJobRangeModel(isLab, jobRangeModels, currentCurrency);

  dispatch({ type: CREATE_NEW_JOB_RANGE_MODEL, newModel: model });
  dispatch(jobRangesSearchActions.searchInputSearch(''));

  let result;
  try {
    const csrfPair = getCSRFPair(getState());
    result = await apiClient.apiPost('/api/jobRanges/model/createJobRangeModel', {
      ...model,
      ...csrfPair
    });

    const newModel = result.data;
    dispatch({ type: CREATE_NEW_JOB_RANGE_MODEL_SUCCESS, newModel });
    history.push(`/ranges/${newModel.id}`);
  } catch (err) {
    console.log('create job range model failed. Error: ', err);
  }
};

const getJobRangeModels = async (getState, modelIds) => {
  const id = modelIds ? [].concat(modelIds) : [];
  let result;
  try {
    result = await apiClient.apiPost('/api/jobRanges/model/getJobRangeModels', {
      id,
      ...getCSRFPair(getState())
    });

    return { jobRangeModels: result.data, success: result.data.success };
  } catch (err) {
    console.log('request for job range model list failed', err);
    return { success: false };
  }
};

export const getJobRangeModelData = modelId => async (dispatch, getState) => {
  const { modelJobsLimit } = getState().jobRanges.modelDetails;
  try {
    dispatch({ type: GET_SINGLE_JOB_RANGE_MODEL });

    const postData = { jobRangeModelId: modelId, ...getCSRFPair(getState()), limit: modelJobsLimit };
    const result = await apiClient.apiPost('/api/jobRanges/model/getJobRangeModelDetails', postData);

    if (result.success === false) {
      dispatch({ type: GET_SINGLE_JOB_RANGE_MODEL_FAIL });
    } else {
      const jobRangeModel = result.data.jobRangeModel;
      const jobRangeModelSelectedJobs = result.data.jobRangeModelSelectedJobs || [];
      const modelJobs = result.data.selectedJobsWithDetails || [];

      const sessionCurrency = getState()?.session?.currency?.code;
      let filteredModelJobs = modelJobs;

      // MPE-5029: filter jobs list based on current session currency (from top navbar)
      if (sessionCurrency && sessionCurrency !== '%') {
        filteredModelJobs = modelJobs.filter(job => {
          return job.currency === sessionCurrency || !job.currency;
        });
        jobRangeModel.jobCount = filteredModelJobs.length;
      }

      const isCorrectURL = window.location.pathname.indexOf(`/ranges/${jobRangeModel?.id}`) !== -1;

      if (!isCorrectURL) {
        dispatch({ type: GET_SINGLE_JOB_RANGE_MODEL_FAIL });
        return;
      }

      dispatch({
        type: GET_SINGLE_JOB_RANGE_MODEL_SUCCESS,
        jobRangeModel,
        jobRangeModelSelectedJobs,
        modelJobs: filteredModelJobs
      });

      //do an elastic search so that it's filtered by the currency of the model
      dispatch(jobRangesSearchActions.searchInputSearch(''));
    }
  } catch (err) {
    console.log('couldnt get jobRange details', err);
    dispatch({ type: GET_SINGLE_JOB_RANGE_MODEL_FAIL });
  }
};

// addJobsList actions

export function jobClick(job) {
  return {
    type: JOB_RANGES_JOB_CLICK,
    job
  };
}

export function doBulkClearJobs(dispatch) {
  dispatch({ type: JOB_RANGES_BULK_JOB_CLEAR });
}

export function doShowMoreSelectedJobs() {
  return (dispatch, getState) => {
    dispatch({ type: JOB_RANGES_VIEW_SELECTED_JOBS_SHOW_MORE });
  };
}

export function doCheckAllSelectedJobs(event) {
  return (dispatch, getState) => {
    const currentSelectedJobs = getState().jobRanges.addJobsList.selectedJobs;

    dispatch({ type: JOB_RANGES_SELECTED_CHECK_ALL, checked: event.target.checked });
    //  remove all selected jobs only if unchecked
    if (!event.target.checked) {
      dispatch({ type: JOB_RANGES_UPDATE_SELECTED_JOBS, jobs: [] });
      //  MPE-2653: Toggle the list back to "All Jobs" if all selected jobs clicked to be removed
      if (currentSelectedJobs && currentSelectedJobs.length > 0) {
        dispatch(addJobsListToggle());
      }
    }
  };
}

export function doCheckAllAvailableJobs(event) {
  return (dispatch, getState) => {
    const candidateJobs = getState().jobRanges.jobRangesSearch.items;
    const currentSelectedJobs = getState().jobRanges.addJobsList.selectedJobs;

    //  CASE #1: Add jobs based off selection
    //  CASE #2: Remove jobs based off selection
    let updatedSelectedJobs = null;
    if (event.target.checked) {
      const currentJobIds = currentSelectedJobs.map(job => job.id);

      //  retain existing selection
      updatedSelectedJobs = [...currentSelectedJobs];

      //  find new jobs to append
      const additionalJobs = candidateJobs.filter(job => currentJobIds.indexOf(job.id) === -1);

      //  update with new jobs where the id doesn't already exist
      updatedSelectedJobs = updatedSelectedJobs.concat(additionalJobs);
    } else {
      const unselectedJobIds = candidateJobs.map(job => job.id);

      //  retain existing jobs that do not match any of the deselected job ids
      updatedSelectedJobs = currentSelectedJobs.filter(job => unselectedJobIds.indexOf(job.id) === -1);
    }

    dispatch({ type: JOB_RANGES_AVAILABLE_CHECK_ALL, checked: event.target.checked });
    dispatch({ type: JOB_RANGES_UPDATE_SELECTED_JOBS, jobs: updatedSelectedJobs });
  };
}

export function addJobsListToggle() {
  return { type: TOGGLE_ALL_JOBS_VS_SELECTED_LIST };
}

export function applyJobsToRange() {
  return async (dispatch, getState) => {
    const { jobRangeModel } = getState().jobRanges.modelDetails;
    const { id: jobRangeModelId, proposedRangeSetting, targetRangeSpread, rangeType } = jobRangeModel;

    const { modelJobsLimit, modelJobsOffset } = getState().jobRanges.modelDetails;
    const limit = modelJobsOffset + modelJobsLimit;

    const { selectedJobs, selectedJobsSnapshot } = getState().jobRanges.addJobsList;
    const selectedJobsDiff = diffSelectedJobs(selectedJobsSnapshot, selectedJobs);

    const data = {
      jobRangeModelId,
      selectedJobsDiff: JSON.stringify(selectedJobsDiff),
      ...getCSRFPair(getState())
    };
    const config = { headers: { 'Content-Type': 'application/json' } };
    try {
      dispatch({ type: APPLY_JOBS_TO_RANGE });
      const result = await apiClient.apiPost('/api/jobRanges/model-jobs/applyJobsToModel', data, config);

      if (result.data.success !== false) {
        dispatch({
          type: APPLY_JOBS_TO_RANGE_SUCCESS,
          newSelectedJobsSnapshot: [...selectedJobs],
          newJobCount: result.data.newJobCount
        });

        //  MPE-2756: Lab wants to hide this slide out when jobs are added.
        if (getState().app && getState().app.isLab) {
          dispatch(slideoutActions.hideSlideout());
        }

        dispatch({ type: REFRESH_JOB_RANGE_MODEL_JOBS });
        const data2 = { ...data, selectedJobsDiff: undefined, limit };
        const jobsWithDetails = await apiClient.apiPost('/api/jobRanges/model-jobs/getSelectedJobsWithDetails', data2);

        if (jobsWithDetails.data.success !== false) {
          let modelJobs = jobsWithDetails.data;
          if ((proposedRangeSetting && targetRangeSpread) || rangeType === MIDPOINT_RANGE_TYPE) {
            modelJobs = calcProposedRanges(modelJobs, jobRangeModel);
          }

          dispatch({ type: REFRESH_JOB_RANGE_MODEL_JOBS_SUCCESS, modelJobs });
          dispatch(saveJobRangeModel());
        } else {
          dispatch({ type: REFRESH_JOB_RANGE_MODEL_JOBS_FAIL });
        }
      } else {
        dispatch({ type: APPLY_JOBS_TO_RANGE_FAIL });
      }
    } catch (err) {
      console.log('could not apply jobs to range: ', err);
      dispatch({ type: REFRESH_JOB_RANGE_MODEL_JOBS_FAIL });
      dispatch({ type: APPLY_JOBS_TO_RANGE_FAIL });
    }
  };
}

const getJobInfo = ({ id, org_job_code, org_job_code_key }) => ({
  id,
  org_job_code,
  org_job_code_key
});

const diffSelectedJobs = (snapshot, jobs) => {
  const jobsToBeRemoved = snapshot
    .filter(job => {
      return !jobs.find(findJob => findJob.id === job.id);
    })
    .map(job => getJobInfo(job));

  const jobsToBeAdded = jobs
    .filter(job => {
      return !snapshot.find(findJob => findJob.id === job.id);
    })
    .map(job => getJobInfo(job));

  return {
    jobsToBeRemoved,
    jobsToBeAdded
  };
};

export const payTypeChange = option => (dispatch, getState) => {
  const { modelJobs, jobRangeModel } = getState().jobRanges.modelDetails;
  const { preferences } = getState()?.session?.user || {};
  const newPayType = option.toLowerCase();
  const defaultRounding = getDefaultRoundings(preferences, newPayType).monetaryRounding;
  dispatch({ type: RANGES_ROUND_TO_CHANGE, payType: newPayType });
  //  update jobs based off new pay type and rounding
  const newModelJobs = calcProposedRanges(modelJobs, {
    ...jobRangeModel,
    payType: newPayType
  });

  dispatch({ type: JOB_RANGE_MODEL_JOBS_CHANGE, newModelJobs });
  dispatch(saveJobRangeModel());
};

export const currencyChange = option => (dispatch, getState) => {
  dispatch({
    type: RANGES_CURRENCY_CHANGE,
    currencyId: option.id === null ? null : option.id
  });
  dispatch(saveJobRangeModel());

  // do an elastic search query to filter the results by currency
  dispatch(jobRangesSearchActions.searchInputSearch(''));
};

export const moveToCurrent = () => async (dispatch, getState) => {
  dispatch({ type: JOB_RANGES_MOVE_TO_CURRENT });
  const {
    jobRanges: {
      modelDetails: { jobRangeModel }
    }
  } = getState();
  const currencies = getCurrencies(getState());
  const currency = currencies.find(curr => curr.id === jobRangeModel.currencyId);
  const currencyCode = currency ? currency.code : '';
  const data = {
    ...getCSRFPair(getState()),
    jobRangeModel,
    currencyCode
  };

  const result = await apiClient.apiPost('/api/jobRanges/model-jobs/moveProposedRangesToCurrent', data);
  const isCorrectURL = window.location.pathname.indexOf(`/ranges/${jobRangeModel.id}`) !== -1;

  if (result.data.success === false || !isCorrectURL) {
    dispatch({ type: JOB_RANGES_MOVE_TO_CURRENT_FAIL });
    return;
  }

  const stats = result.data.stats;

  dispatch({ type: JOB_RANGES_MOVE_TO_CURRENT_SUCCESS, stats });
  dispatch(getJobRangeModelData(jobRangeModel.id));
};

export const showMoreJobs = (limit, offset, searchTerm) => async (dispatch, getState) => {
  try {
    const csrfPair = getCSRFPair(getState());
    const { jobRangeModel, modelJobs } = getState().jobRanges.modelDetails;
    if (!jobRangeModel) return;
    const jobRangeModelId = jobRangeModel.jobRangeModelId;
    let data;
    if (searchTerm) {
      data = { ...csrfPair, jobRangeModelId, searchTerm };
    } else {
      data = { ...csrfPair, jobRangeModelId, offset, limit };
    }

    dispatch({ type: SHOW_MORE_MODEL_JOBS });
    const result = await apiClient.apiPost('/api/jobRanges/model-jobs/getSelectedJobsWithDetails', data);
    if (result.data.success === false) {
      dispatch({ type: SHOW_MORE_MODEL_JOBS_FAIL });
      return;
    }

    const newModelJobs = result.data;
    dispatch({ type: SHOW_MORE_MODEL_JOBS_SUCCESS, newModelJobs, offset, limit, searchTerm });
  } catch (err) {
    console.log('getSelectedJobsWithDetails failed', err);
    dispatch({ type: SHOW_MORE_MODEL_JOBS_FAIL });
  }
};

export const deleteModel = modelId => async (dispatch, getState) => {
  try {
    dispatch({ type: JOB_RANGE_MODELS_INIT });
    const postData = { jobRangeModelId: modelId, ...getCSRFPair(getState()) };
    const result = await apiClient.apiPost('/api/jobRanges/model/deleteJobRangeModel', postData);
    dispatch({ type: DELETE_JOB_RANGE_MODEL_SUCCESS, data: modelId });
  } catch (err) {
    console.log('err');
  }
};

export function setSort(column, direction) {
  return { type: JOB_RANGES_SET_SORT, column, direction };
}

export function setSearch(searchTerm) {
  return { type: JOB_RANGES_INIT_SEARCH_JOBS, searchTerm };
}

export const searchModelJobs = searchTerm => async (dispatch, getState) => {
  try {
    const csrfPair = getCSRFPair(getState());
    const { jobRangeModel } = getState().jobRanges.modelDetails;
    const jobRangeModelId = jobRangeModel.jobRangeModelId;
    const data = { ...csrfPair, jobRangeModelId, searchTerm };
    const result = await apiClient.apiPost('/api/jobRanges/model-jobs/getSelectedJobsWithDetails', data);
    if (!!result.data.success) {
      const errorMessage = 'Error searching model jobs.';

      dispatch({ type: JOB_RANGES_SEARCH_JOBS_FAIL });
      throw Error(errorMessage);

      return;
    }

    dispatch({ type: JOB_RANGES_SEARCH_JOBS, data: result?.data });
  } catch (err) {
    dispatch({ type: JOB_RANGES_SEARCH_JOBS_FAIL, err });
  }
};
