import PropTypes from 'prop-types';
import React, { Component } from 'react';
import checkIE from '../../Utils/checkForIE.js';
import classnames from 'classnames';
import './CellIndicator.scss';

export default class CellIndicator extends Component {
  static propTypes = {
    text: PropTypes.string,
    iconClass: PropTypes.string
  };

  render() {
    const { text, iconClass } = this.props;
    const isIE = checkIE();
    return (
      <div className={classnames({ 'cell-indicator__cell-container': isIE })}>
        <i className={iconClass}></i>
        <span className="cell-indicator__text">{text}</span>
      </div>
    );
  }
}
