import React from 'react';
import './SlideoutFooter.scss';
import { string, bool, func } from 'prop-types';
import { getClassNameWithOptionalClass } from '../../Utils/ClassNameUtils';

const propTypes = {
  className: string,
  text: string.isRequired,
  isNext: bool.isRequired,
  onClick: func.isRequired
};

const SlideoutFooterNavButton = props => {
  const directionText = props.isNext ? 'Next' : 'Previous';
  const iconClass = props.isNext ? 'icon-right' : 'icon-left';
  const className = getClassNameWithOptionalClass(
    `slideout-footer-navigation${props.isNext ? ' reverse' : ''}`,
    props.className
  );

  return (
    <div className={className}>
      <button className="slideout-footer-navigation__button icon-border" onClick={props.onClick}>
        <i className={`slideout-footer-navigation__button-icon ${iconClass}`} />
      </button>
      <div className="slideout-footer-navigation__text-content">
        <div className="slideout-footer-navigation__top-label">{directionText}</div>
        <div className="slideout-footer-navigation__bottom-label">{props.text}</div>
      </div>
    </div>
  );
};

SlideoutFooterNavButton.propTypes = propTypes;
export default SlideoutFooterNavButton;
