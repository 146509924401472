import {
  REPORT_FILTER_INCLUDE,
  REPORT_FILTER_TEXT,
  REPORT_FILTER_FAVORITE,
  REPORT_FILTER_FAVORITE_LOAD,
  REPORT_FILTER_POPULAR,
  REPORT_FILTER_POPULAR_LOAD,
  REPORT_FILTER_SCOPE,
  REPORT_FILTER_TYPE,
  REPORT_FILTER_CREATED_BY,
  REPORT_FILTER_GROUP_ID,
  REPORT_SORT_FIELD,
  REPORT_VIEW_PREFERENCE_SAVE,
  REPORT_VIEW_PREFERENCE_LOAD,
  REPORT_LIST_STALE,
  REPORT_VIEW_DEFAULT,
  REPORT_LIBRARY_LIST_INIT,
  REPORT_LIBRARY_LIST_SUCCESS,
  REPORT_LIBRARY_LIST_FAIL,
  REPORT_FAVORITE_INIT,
  REPORT_FAVORITE_SUCCESS,
  REPORT_FAVORITE_FAIL,
  REPORT_FAVORITE_OPTIMISTIC,
  REPORT_VISUALIZATION_INIT,
  REPORT_VISUALIZATION_SUCCESS,
  REPORT_VISUALIZATION_FAIL,
  REPORT_TYPE_INIT,
  REPORT_TYPE_SUCCESS,
  REPORT_TYPE_FAIL,
  SORT_REPORTS_BY_TYPE,
  REPORT_CLONE_INIT,
  REPORT_CLONE_SUCCESS,
  REPORT_CLONE_FAIL,
  REPORT_DELETE_INIT,
  REPORT_DELETE_SUCCESS,
  REPORT_DELETE_FAIL,
  REPORT_EDITABLE,
  SELECT_REPORT,
  SELECT_REPORT_BULK_EDIT,
  DESELECT_REPORT_BULK_EDIT,
  DESELECT_ALL_REPORT_BULK_EDIT,
  REPORT_DELETE_ALL_INIT,
  REPORT_DELETE_ALL_SUCCESS,
  REPORT_DELETE_ALL_FAIL,
  REPORT_LIBRARY_COLLECTIONS_INIT,
  REPORT_LIBRARY_COLLECTIONS_SUCCESS,
  REPORT_LIBRARY_COLLECTIONS_FAIL,
  REPORT_COLLECTION_FILTER,
  TOGGLE_COLLECTIONS,
  SELECT_POPULAR,
  DESELECT_POPULAR,
  SET_COLLECTION_TYPE_FILTER,
  CLEAR_REPORT_FILTERS
} from './ReportActions';

const initialState = {
  includeFilter: null,
  scopeFilter: null,
  typeFilter: null,
  textFilter: '',
  createdByFilter: null,
  favoriteFilter: true,
  sortField: 'name',
  sortDirection: 'ASC',
  viewType: REPORT_VIEW_DEFAULT,
  listStale: false,
  reports: null,
  reportsLoading: false,
  reportsLoaded: false,
  initialReportsLoaded: false,
  reportTypes: [],
  reportClone: null,
  activeReportQueryRequestId: 0,
  reportCloneError: '',
  selectedReport: null,
  collectionsLoading: false,
  collectionsLoaded: false,
  collections: [],
  collectionsFilter: null,
  hiddenCollections: [],
  selectedPopularReports: [],
  collectionTypeFilter: null
};

// Keeping this here for next set of work that bumps the filter/sorting toggles
// up into the report props
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case REPORT_FILTER_INCLUDE:
      return {
        ...state,
        includeFilter: action.data
      };
    case REPORT_FILTER_TEXT:
      return {
        ...state,
        textFilter: action.data,
        sortField: action.data ? 'score' : 'name',
        sortDirection: 'DESC'
      };
    case REPORT_FILTER_FAVORITE:
      return {
        ...state,
        reports: null,
        favoriteFilter: action.data
      };
    case REPORT_FILTER_FAVORITE_LOAD:
      return {
        ...state,
        favoriteFilter: action.data
      };
    case REPORT_FILTER_POPULAR:
      return {
        ...state,
        reports: null,
        popularFilter: action.data
      };
    case REPORT_FILTER_POPULAR_LOAD:
      return {
        ...state,
        popularFilter: action.data
      };
    case REPORT_FILTER_SCOPE:
      return {
        ...state,
        scopeFilter: action.data
      };
    case REPORT_FILTER_TYPE:
      return {
        ...state,
        typeFilter: action.data
      };
    case REPORT_COLLECTION_FILTER:
      return {
        ...state,
        collectionsFilter: action.data
      };
    case REPORT_SORT_FIELD:
      return {
        ...state,
        sortField: action.data.field,
        sortDirection: action.data.direction
      };
    case REPORT_FILTER_CREATED_BY:
      return {
        ...state,
        createdByFilter: action.data
      };
    case REPORT_FILTER_GROUP_ID:
      return {
        ...state,
        groupIdFilter: action.data
      };
    case REPORT_VIEW_PREFERENCE_SAVE:
    case REPORT_VIEW_PREFERENCE_LOAD:
      return {
        ...state,
        viewType: action.data || REPORT_VIEW_DEFAULT
      };
    case REPORT_LIST_STALE:
      return {
        ...state,
        listStale: action.data
      };
    case REPORT_LIBRARY_LIST_INIT:
      return {
        ...state,
        reportsLoading: true,
        reportsLoaded: false,
        activeReportQueryRequestId: action.requestId
      };
    case REPORT_LIBRARY_LIST_SUCCESS:
      if (action.requestId !== state.activeReportQueryRequestId && !action.isPopular) {
        return state;
      }

      let initialReportsLoaded = state.initialReportsLoaded;
      if (!initialReportsLoaded) {
        //  MPE-3045 if a result search returned for non-favorites, then consider the initial reports loaded
        //  If search result returned for favorites with matching reports, then consider initial reports loaded
        if (!state.favoriteFilter || action.data?.reports?.edges?.length > 0) {
          initialReportsLoaded = true;
        }
      }

      // make sure to keep any visual data inplace, so it's not reloaded
      const newReports = {
        ...action.data,
        edges: action.data?.reports?.edges?.map(report => {
          const stateReport = state.reports?.edges?.find(r => Number(r.id) === Number(report.id));
          if (stateReport) {
            return {
              ...report,
              thumbnail: stateReport.thumbnail,
              url: stateReport.url,
              thumbnailLoading: false,
              thumbnailLoaded: true
            };
          }

          return report;
        })
      };

      return {
        ...state,
        reports: newReports,
        initialReportsLoaded: initialReportsLoaded,
        reportsLoading: false,
        reportsLoaded: true,
        reportTypes: state.typeFilter || !action.data?.reportTypes ? state.reportTypes : action.data?.reportTypes
      };
    case REPORT_LIBRARY_LIST_FAIL:
      if (action.requestId !== state.activeReportQueryRequestId) {
        return state;
      }

      return {
        ...state,
        initialReportsLoaded: true, //  default to true in case of network/packet loss to avoid spinning loaders
        reportsLoading: false,
        reportsLoaded: true
      };
    case REPORT_VISUALIZATION_INIT:
      return {
        ...state,
        reports: {
          ...state.reports,
          edges: state.reports.edges.map(report => {
            if (report.id == action.data.id) {
              return { ...report, thumbnailLoading: true, thumbnailLoaded: false };
            }
            return report;
          })
        }
      };
    case REPORT_VISUALIZATION_SUCCESS:
      return {
        ...state,
        reports: {
          ...state.reports,
          edges: state.reports.edges.map(report => {
            if (report.id == action.data.id) {
              return {
                ...report,
                thumbnail: action.data.thumbnail,
                url: action.data.url,
                thumbnailLoading: false,
                thumbnailLoaded: true
              };
            }
            return report;
          })
        }
      };
    case REPORT_VISUALIZATION_FAIL:
      return {
        ...state,
        reports: {
          ...state.reports,
          edges: state.reports.edges.map(report => {
            if (report.id == action.data.id) {
              return { ...report, thumbnailLoading: false, thumbnailLoaded: true };
            }
            return report;
          })
        }
      };
    case REPORT_EDITABLE:
      const selectedReport = state.selectedReport;
      if (Number(selectedReport.id) === Number(action.data.id)) {
        selectedReport.isEditable = action.data.isEditable;
      }

      return {
        ...state,
        reports: {
          ...state.reports,
          edges: state.reports.edges.map(report => {
            if (report.id == action.data.id) {
              return { ...report, isEditable: action.data.isEditable };
            }
            return report;
          })
        },
        selectedReport
      };
    case REPORT_FAVORITE_INIT:
      return {
        ...state,
        reportFavoriteLoading: true,
        reportFavoriteLoaded: false
      };
    case REPORT_FAVORITE_OPTIMISTIC:
    case REPORT_FAVORITE_SUCCESS:
      return {
        ...state,
        reports: {
          ...state.reports,
          edges: state.reports.edges.map(report => {
            if (report.id == action.data.id) {
              return { ...report, isFavorite: action.data.isFavorite };
            }
            return report;
          })
        }
      };
    case REPORT_FAVORITE_FAIL:
      return {
        ...state,
        reportFavoriteLoading: false,
        reportFavoriteLoaded: true
      };
    case REPORT_TYPE_SUCCESS:
      return {
        ...state,
        reportTypes: action.data
      };
    case SORT_REPORTS_BY_TYPE:
      let reports = JSON.parse(JSON.stringify(state.reports));
      reports = reports.edges.sort((a, b) => {
        if (action.data === 'ASC' && a.type.name > b.type.name) return 1;
        if (action.data === 'ASC' && a.type.name < b.type.name) return -1;
        if (action.data === 'DESC' && a.type.name > b.type.name) return -1;
        if (action.data === 'DESC' && a.type.name < b.type.name) return 1;
      });

      return {
        ...state,
        reports: { totalCount: state.reports.totalCount, edges: reports },
        sortDirection: action.data,
        sortField: 'type'
      };
    case REPORT_CLONE_INIT:
      return {
        ...state,
        reportCloneLoading: true,
        reportCloneLoaded: false
      };
    case REPORT_CLONE_SUCCESS:
      return {
        ...state,
        reportCloneLoading: false,
        reportCloneLoaded: true,
        reportCloneId: action.data.id
      };
    case REPORT_CLONE_FAIL:
      return {
        ...state,
        reportCloneLoading: false,
        reportCloneLoaded: true,
        reportCloneError: action.error
      };
    case REPORT_DELETE_INIT:
      return {
        ...state,
        reportDeleteLoading: true,
        reportDeleteLoaded: false
      };
    case REPORT_DELETE_SUCCESS:
      return {
        ...state,
        reportDeleteLoading: false,
        reportDeleteLoaded: true,
        reports: {
          ...state.reports,
          edges: state.reports.edges.filter(report => {
            if (report.id === action.data.id) {
              return false;
            }
            return true;
          })
        }
      };
    case REPORT_DELETE_FAIL:
      return {
        ...state,
        reportDeleteLoading: false,
        reportDeleteLoaded: true,
        reportDeleteError: action.error
      };
    case SELECT_REPORT:
      return {
        ...state,
        selectedReport: action.data.report
      };
    case SELECT_REPORT_BULK_EDIT:
      return {
        ...state,
        reports: {
          ...state.reports,
          edges: state.reports.edges.map(report => {
            if (report.id === action.data.report.id) {
              return { ...report, selectedForEdit: true };
            }
            return report;
          })
        }
      };
    case DESELECT_REPORT_BULK_EDIT:
      return {
        ...state,
        reports: {
          ...state.reports,
          edges: state.reports.edges.map(report => {
            if (report.id === action.data.report.id) {
              return { ...report, selectedForEdit: false };
            }
            return report;
          })
        }
      };
    case DESELECT_ALL_REPORT_BULK_EDIT:
      return {
        ...state,
        reports: {
          ...state.reports,
          edges: state.reports.edges.map(report => {
            return { ...report, selectedForEdit: false };
          })
        }
      };
    case REPORT_DELETE_ALL_INIT:
      return {
        ...state,
        reportDeleteLoading: true,
        reportDeleteLoaded: false
      };
    case REPORT_DELETE_ALL_SUCCESS:
      return {
        ...state,
        reportDeleteLoading: false,
        reportDeleteLoaded: true,
        reports: {
          ...state.reports,
          edges: state.reports.edges.filter(report => {
            if (report.selectedForEdit) {
              return false;
            }
            return report;
          })
        }
      };
    case REPORT_DELETE_ALL_FAIL:
      return {
        ...state,
        reportDeleteLoading: false,
        reportDeleteLoaded: true,
        reportDeleteError: action.error
      };
    case REPORT_LIBRARY_COLLECTIONS_INIT:
      return {
        ...state,
        collectionsLoading: true,
        collectionsLoaded: false,
        collections: []
      };
    case REPORT_LIBRARY_COLLECTIONS_SUCCESS:
      return {
        ...state,
        collectionsLoading: false,
        collectionsLoaded: true,
        collections: action.data.collections
      };
    case REPORT_LIBRARY_COLLECTIONS_FAIL:
      return {
        ...state,
        collectionsLoading: false,
        collectionsLoaded: true,
        collections: []
      };
    case TOGGLE_COLLECTIONS:
      let newIds = [];
      if (!action.id && action.checked === false) {
        if (state.hiddenCollections.length !== state.collections.length) {
          newIds = state.collections.map(collection => collection.id);
        }
      } else if (action.id) {
        if (state.hiddenCollections.includes(action.id)) {
          newIds = state.hiddenCollections.filter(collection => collection !== action.id);
        } else {
          newIds = [...state.hiddenCollections, action.id];
        }
      }

      return {
        ...state,
        hiddenCollections: newIds
      };
    case SELECT_POPULAR:
      const { collectionId, reportId, checked } = action.data;
      const newSelectedReports = [...state.selectedPopularReports];

      const foundReportIndex = newSelectedReports.findIndex(
        r => r.collectionId === collectionId && r.reportId === reportId
      );
      if (foundReportIndex === -1 && checked) {
        newSelectedReports.push({
          collectionId,
          reportId
        });
      } else if (foundReportIndex > -1 && !checked) {
        newSelectedReports.splice(foundReportIndex, 1);
      }

      return {
        ...state,
        selectedPopularReports: newSelectedReports
      };
    case DESELECT_POPULAR:
      return {
        ...state,
        selectedPopularReports: []
      };
    case SET_COLLECTION_TYPE_FILTER:
      return {
        ...state,
        collectionTypeFilter: action.data === 'All' ? null : action.data
      };
    case CLEAR_REPORT_FILTERS:
      return {
        ...state,
        includeFilter: null,
        scopeFilter: null,
        typeFilter: null,
        textFilter: '',
        sortField: 'name',
        sortDirection: 'ASC',
        collectionsFilter: null,
        collectionTypeFilter: null
      };
    default:
      return state;
  }
}

export const reportsActions = {};
