import Fuse from 'fuse.js';
import sortBy from 'lodash/sortBy';
import { sortByColumnDirection } from '../../Utils/Utils';
import {
  DELETE_JOB_RANGE_MODEL_SUCCESS,
  GET_JOB_RANGE_MODELS,
  GET_JOB_RANGE_MODELS_FAIL,
  GET_JOB_RANGE_MODELS_SUCCESS,
  JOB_RANGE_MODELS_INIT,
  JOB_RANGES_MODEL_SEARCH_INPUT_CHANGE,
  JOB_RANGES_SET_SORT,
  GET_JOB_SINGLE_RANGE_MODEL_SUCCESS
} from '../../actions/JobRanges';

import { ANNUAL_PAY_TYPE, HOURLY_PAY_TYPE } from '../../Utils/constants';

const initialState = {
  fuseOptions: {
    distance: 100,
    keys: ['name', 'rangeType', 'status'],
    location: 0,
    maxPatternLength: 32,
    minMatchCharLength: 1,
    shouldSort: true,
    threshold: 0.6
  },
  jobRangeModels: [],
  jobRangeModelsLoaded: false,
  jobRangeModelSearchResults: null,
  modelListLoading: false,
  payTypeOptions: [
    { name: 'Annual', value: ANNUAL_PAY_TYPE },
    { name: 'Hourly', value: HOURLY_PAY_TYPE }
  ],
  searchInputValue: '',
  sortColumn: 'created',
  sortDirection: 'DESC'
};

let fuse, sortedModels;

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case GET_JOB_SINGLE_RANGE_MODEL_SUCCESS:
      const updatedModel = action.result.jobRangeModels[0];
      let newModelList = [...state.jobRangeModels].map(model => {
        if (model.jobRangeModelId === updatedModel.jobRangeModelId) {
          return updatedModel;
        }
        return model;
      });

      return {
        ...state,
        jobRangeModels: newModelList,
        jobRangeModelsLoaded: true,
        modelListLoading: false
      };
    case DELETE_JOB_RANGE_MODEL_SUCCESS:
      const newModels = state.jobRangeModels.filter(job => job.id !== action.data);
      fuse = new Fuse(newModels, state.fuseOptions);
      let searchResults = jobRangeModelSearch({ searchInputValue: state.searchInputValue });
      if (searchResults) {
        searchResults = sortByColumnDirection(state.sortDirection, state.sortColumn, searchResults);
      }

      return {
        ...state,
        jobRangeModels: newModels,
        jobRangeModelSearchResults: searchResults,
        modelListLoading: false
      };
    case GET_JOB_RANGE_MODELS:
      return { ...state, modelListLoading: true };
    case GET_JOB_RANGE_MODELS_FAIL:
      fuse = new Fuse(action.result.jobRangeModels, state.fuseOptions);
      return { ...state, jobRangeModels: [], modelListLoading: false };
    case GET_JOB_RANGE_MODELS_SUCCESS:
      fuse = new Fuse(action.result.jobRangeModels, state.fuseOptions);
      sortedModels = sortByColumnDirection(state.sortDirection, state.sortColumn, action.result.jobRangeModels);

      return {
        ...state,
        jobRangeModels: sortedModels,
        jobRangeModelsLoaded: true,
        modelListLoading: false
      };
    case JOB_RANGE_MODELS_INIT:
      return {
        ...state,
        modelListLoading: true
      };
    case JOB_RANGES_MODEL_SEARCH_INPUT_CHANGE:
      let results = jobRangeModelSearch(action);
      if (results) {
        results = sortByColumnDirection(state.sortDirection, state.sortColumn, results);
      }

      return {
        ...state,
        jobRangeModelSearchResults: results,
        searchInputValue: action.searchInputValue
      };
    case JOB_RANGES_SET_SORT:
      let sortedSearchResults;
      sortedModels = sortByColumnDirection(action.direction, action.column, state.jobRangeModels);

      if (state.jobRangeModelSearchResults) {
        sortedSearchResults = sortByColumnDirection(action.direction, action.column, state.jobRangeModelSearchResults);
      }

      return {
        ...state,
        jobRangeModels: sortedModels,
        jobRangeModelSearchResults: state.jobRangeModelSearchResults
          ? sortedSearchResults
          : state.jobRangeModelSearchResults,
        sortColumn: action.column,
        sortDirection: action.direction
      };
    default:
      return state;
  }
}

const jobRangeModelSearch = action => {
  if (!action.searchInputValue.length) return null;
  return fuse.search(action.searchInputValue);
};
