import React, { Component } from 'react';
import { func } from 'prop-types';
import { ParticipationMatchOrgJob, ParticipationMatch } from '../../../../types/MatchReviewTypes';
import './SurveyJobMatchCard.scss';
import * as RenderUtils from '../../../../utils/RenderUtils';
import * as MatchReviewUtils from '../../../../utils/MatchReviewUtils';
import TruncatedString from '../../../../../../TruncatedString/TruncatedString';
import capitalize from 'lodash/capitalize';

class SurveyJobMatchCard extends Component {
  static propTypes = {
    participationOrgJob: ParticipationMatchOrgJob.isRequired,
    match: ParticipationMatch.isRequired,
    doIncludeMatch: func.isRequired
  };

  constructor(props) {
    super(props);
    this.expandJobDescription = this.expandJobDescription.bind(this);
    this.renderJobDescription = this.renderJobDescription.bind(this);
    this.renderActionButton = this.renderActionButton.bind(this);
    this.handleActionButtonClick = this.handleActionButtonClick.bind(this);
    this.state = { isDescriptionExpanded: false };
  }

  handleActionButtonClick() {
    const { participationOrgJob, match } = this.props;
    this.props.doIncludeMatch(participationOrgJob.orgJobCode, participationOrgJob.orgJobCodeKey, match.matchId);
  }

  expandJobDescription() {
    this.setState({ isDescriptionExpanded: !this.state.isDescriptionExpanded });
  }

  renderActionButton(isIncluded) {
    //  NOTE: if the match is undefined then it needs to be rendered as unmodified (Include button)
    if (isIncluded) {
      return (
        <div
          className={RenderUtils.getModifiedClassName('pcenter-review-duplicates-survey-job-card__action-btn', true)}
        >
          <icon className="pcenter-review-duplicates-survey-job-card__action-icon icon-check" />
          Included
        </div>
      );
    } else {
      return (
        <button
          className="pxl-btn pxl-btn-default pcenter-review-duplicates-survey-job-card__action-btn"
          onClick={this.handleActionButtonClick}
        >
          Include
        </button>
      );
    }
  }

  renderJobDescription() {
    const { surveyJobDescription } = this.props.match;
    if (!surveyJobDescription || surveyJobDescription.length === 0) return;

    const maxLength = 200;
    if (surveyJobDescription.length < maxLength || this.state.isDescriptionExpanded) {
      return (
        <p className="pcenter-review-duplicates-survey-job-card__description">
          {surveyJobDescription + ' '}
          <a onClick={this.expandJobDescription}>less</a>
        </p>
      );
    } else {
      return (
        <p className="pcenter-review-duplicates-survey-job-card__description">
          {surveyJobDescription.substring(0, maxLength)}
          <a onClick={this.expandJobDescription}>...more</a>
        </p>
      );
    }
  }

  render() {
    const { participationOrgJob, match } = this.props;

    //  Unresolved state is needed in order to avoid render issues where a user selects to
    //  include more than 1 zero weighted entry which has duplicate matches as well. Other wise they could see
    //  more than 1 included card
    const isIncluded = !MatchReviewUtils.areOrgJobDuplicatesUnresolved(participationOrgJob) && !match.exclude;

    return (
      <div className={RenderUtils.getModifiedClassName('pcenter-review-duplicates__survey-job-card', isIncluded)}>
        <div className="pcenter-review-duplicates-survey-job-card__top-panel">
          <div className="pcenter-review-duplicates-survey-job-card__job-code">{match.surveyJobCode}</div>
          <div className="pcenter-review-duplicates-survey-job-card__title">{match.surveyJobTitle}</div>
          <div className="pcenter-review-duplicates-survey-job-card__description">
            <TruncatedString maxChar={160}>{match.surveyJobDescription}</TruncatedString>
          </div>
        </div>
        <div className="pcenter-review-duplicates-survey-job-card__bottom-panel">
          <div className="pcenter-review-duplicates-survey-job-card__match-type">
            <label className="pcenter-review-duplicates-survey-job-card__label">Type of Match</label>
            {capitalize(match.matchType)}
          </div>
          <p className="pcenter-review-duplicates-survey-job-card__notes">
            <label className="pcenter-review-duplicates-survey-job-card__label">Notes</label>
            <TruncatedString maxChar={75}>{match.notes}</TruncatedString>
          </p>
          {this.renderActionButton(isIncluded)}
        </div>
      </div>
    );
  }
}

export default SurveyJobMatchCard;
