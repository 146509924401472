import axios, { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import Raven from 'raven-js';

class FeatureFlagServiceBase {
  protected apiClient: AxiosInstance;

  constructor(apiClient: AxiosInstance) {
    this.apiClient = apiClient;
  }
}

class FlagService extends FeatureFlagServiceBase {
  constructor(apiClient: AxiosInstance) {
    super(apiClient);
  }

  public async get(): Promise<AxiosResponse<FeatureFlagService.FeatureFlags>> {
    return await this.apiClient.get<FeatureFlagService.FeatureFlags>(`/v0/flags`);
  }

  public async createOrUpdate(params: FeatureFlagService.CreateOrUpdateFlagRequest): Promise<AxiosResponse<FeatureFlagService.FeatureFlags>> {
    return await this.apiClient.post<FeatureFlagService.FeatureFlags>(`/v0/flags`, params);
  }
}

class LaunchDarklyService extends FeatureFlagServiceBase {
  constructor(apiClient: AxiosInstance) {
    super(apiClient);
  }

  public async identify(): Promise<void> {
    await this.apiClient.get<string>(`/v0/launchdarkly/identify`);
  }
}

export class FeatureFlagClient {
  public Flags: FlagService;

  public LaunchDarkly: LaunchDarklyService;

  private apiClient: AxiosInstance;

  constructor(host: string) {
    this.apiClient = axios.create({
      baseURL: `${host}/api`,
      withCredentials: true,
    });

    this.apiClient.interceptors.response.use(
      (response) => response,
      (e: AxiosError) => {
        // Capture error in Sentry and re-throw
        if (typeof Raven !== 'undefined') {
          Raven.captureException(e, {
            tags: {
              featureFlagClient: 'true'
            },
            extra: {
              code: e.code || '<Empty>',
              response: {
                data: JSON.stringify(e.response?.data) || '<Empty>',
                headers: JSON.stringify(e.response?.headers) || '<Empty>',
                status: JSON.stringify(e.response?.status) || '<Empty>',
                statusText: JSON.stringify(e.response?.statusText) || '<Empty>',
              }
            }
          });
        }

        return Promise.reject(e);
      }
    );

    this.Flags = new FlagService(this.apiClient);
    this.LaunchDarkly = new LaunchDarklyService(this.apiClient);
  }
}

export let DefaultClient: FeatureFlagClient;

export function InitDefaultClient(host: string): void {
  if (!DefaultClient) {
    DefaultClient = new FeatureFlagClient(host);
  }
}