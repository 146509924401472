import React, { Component } from 'react';
import { bool, func, string, object } from 'prop-types';
import classnames from 'classnames';
import isIE from 'ps-components/src/Utils/checkForIE';

import './FacetPanelHeader.scss';
import Searchbox from './Searchbox';

class FacetPanelHeader extends Component {
  static propTypes = {
    addButtonText: string,
    customFacetRender: func,
    handleAddItem: func,
    history: object,
    placeholderText: string,
    resetFacetText: string,
    resetSearch: func.isRequired,
    searchInputSearch: func,
    showAddButton: bool,
    showResetButton: bool,
    showSearchBox: bool,
    title: string,
    query: string,
    setSearchQuery: func,
    fromEmployeePage: bool,
    toggleFacetPanel: func,
    facetShow: bool,
    canCollapse: bool,
    collapseTooltip: string,
    renderChildren: bool,
    headerRender: object
  };

  static defaultProps = {
    showAddButton: false,
    showResetButton: true,
    showSearchbox: false,
    resetFacetText: 'Reset All'
  };

  constructor(props) {
    super(props);
    this.state = { searchboxValue: '' };

    this.resetSearch = this.resetSearch.bind(this);
    this.renderResetButton = this.renderResetButton.bind(this);
    this.renderTitle = this.renderTitle.bind(this);
    this.renderSearchbox = this.renderSearchbox.bind(this);
  }

  resetSearch() {
    const { resetSearch } = this.props;
    resetSearch();
    this.setState({ searchboxValue: '' });
  }

  renderResetButton() {
    const { resetFacetText } = this.props;

    return (
      <div className="action_btn" onClick={this.resetSearch}>
        <a className="facet__header-action">
          <i className="icon-cw facet__action-icon" />
          {resetFacetText}
        </a>
      </div>
    );
  }

  openEmployeeAddSlideout() {
    this.props.handleAddItem(this.props.history);
  }

  renderAddButton() {
    const { handleAddItem, addButtonText, fromEmployeePage } = this.props;

    if (fromEmployeePage) {
      return (
        <div className="facet__header-action" onClick={this.openEmployeeAddSlideout.bind(this)}>
          <div className="icon-border icon-circle-thin facet__action-icon">
            <i className="icon-plus" />
          </div>
          {addButtonText}
        </div>
      );
    }

    return (
      <div className="facet__header-action" onClick={handleAddItem}>
        <div className="icon-border icon-circle-thin facet__action-icon">
          <i className="icon-plus" />
        </div>
        {addButtonText}
      </div>
    );
  }

  renderTitle() {
    const { title, canCollapse } = this.props;

    return (
      <div className={classnames('pxl-header-xsmall facet__header-title', { collapse__offset: canCollapse })}>
        {title}
      </div>
    );
  }

  renderSearchbox() {
    const { placeholderText, searchInputSearch, query, setSearchQuery } = this.props;

    return (
      <Searchbox
        placeholderText={placeholderText}
        searchInputSearch={searchInputSearch}
        setSearchQuery={setSearchQuery}
        query={query}
      />
    );
  }

  render() {
    const {
      customFacetRender,
      showAddButton,
      showResetButton,
      showSearchBox,
      title,
      toggleFacetPanel,
      facetShow,
      canCollapse,
      collapseTooltip,
      renderChildren,
      headerRender
    } = this.props;
    const btnRowClass = title || showAddButton ? 'facet__header' : 'facet__header right__align';

    return (
      <div className={classnames('facet__header-container', { 'height-zero': renderChildren })}>
        <div className={btnRowClass}>
          {canCollapse && (
            <div
              title={collapseTooltip}
              className={classnames('facet__close-button', { close: !facetShow, open: facetShow, ie: isIE() })}
              onClick={toggleFacetPanel}
            >
              <i className="icon-right-open" />
            </div>
          )}
          {headerRender && (
            <span className={classnames('facet__header-render-container', { close: !facetShow, open: facetShow })}>
              {headerRender}
            </span>
          )}
          {!headerRender && !renderChildren && (
            <React.Fragment>
              {title && this.renderTitle()}
              {showAddButton && this.renderAddButton()}
              {showResetButton && this.renderResetButton()}
            </React.Fragment>
          )}
        </div>
        {showSearchBox && this.renderSearchbox()}
        {customFacetRender && customFacetRender()}
      </div>
    );
  }
}

export default FacetPanelHeader;
