import React, { Component, Fragment } from 'react';
import ReactTooltip from 'react-tooltip';
import './CustomJobIndicator.scss';
import PropTypes from 'prop-types';

const propTypes = {
  job: PropTypes.object.isRequired
};

const CustomJobIndicator = props => {
  const { job } = props;
  const id = `job-${job.id}-custom-indicator`;

  return (
    <span className="jbr__warning">
      <i className="icon-warning" data-tip data-for={id} />
      <ReactTooltip className="custom-job-tooltip" place="right" id={`job-${job.id}-custom-indicator`}>
        {`The ${job.status.toLowerCase()} range for this job has been manually entered and will be excluded from model settings actions on this page.`}
      </ReactTooltip>
    </span>
  );
};

CustomJobIndicator.propTypes = propTypes;

export default CustomJobIndicator;
