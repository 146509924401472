import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Search from '.././Search/Search.js';

import { getCompanyLogoPath } from '../../helpers/utils';

import UtilityNav from '../../components/Layout/Header/UtilityNav';
import MainNav from '../../components/Layout/Header/MainNav';
import HeaderFullSearchBar from '../../components/Layout/Header/HeaderFullSearchBar';
import AdminBar from '../../components/Layout/Header/AdminBar';
import SimpleSlideAnimation from '../../components/SimpleSlideAnimation';

import * as jobCountActions from '../../reducers/modules/home/homeJobCount';
import * as employeeCountActions from '../../reducers/modules/home/homeEmployeeCount';
import * as gradeCountActions from '../../reducers/modules/home/homeGradeCount';
import { actions as appActions } from '../../reducers/modules/app/app';
import { isFeatureEnabled } from '../../../../ps-components/src/FeatureFlags/FeatureFlagActions';
import * as subsiteActions from '../../reducers/modules/sites/sites';
import { getActions as getSiteActions } from '../../reducers/modules/sites/sites';
import * as refreshMarketReportsActions from '../../reducers/modules/app/refreshMarketReports';
import * as migrateCompanySurveyActions from '../../reducers/modules/app/migrateCompanySurvey';
import { getCompSetsCount } from 'ps-components/src/Modules/CompetitiveSets/reducers/competitiveSets';

@connect(
  state => ({
    universalSearch: state.universalSearch,
    homeEmployeeCount: state.homeEmployeeCount,
    homeJobCount: state.homeJobCount,
    homeGradeCount: state.homeGradeCount,
    competitiveSets: state.competitiveSets,
    router: state.router,
    fullName: state.app.fullName,
    email: state.app.email,
    app: state.app,
    isInfluitiveEnabled: isFeatureEnabled(state.features.flags, 'influitive').isEnabled,
    isHelpFormEnabled: isFeatureEnabled(state.features.flags, 'support-form').isEnabled,
    isFileUploadEnabled: isFeatureEnabled(state.features.flags, 'fileUpload').isEnabled,
    showHrisConnect: isFeatureEnabled(state.features.flags, 'hrisConnect').isEnabled,
    sites: state.sites,
    refreshMarketReports: state.refreshMarketReports,
    migrateCompanySurvey: state.migrateCompanySurvey,
    isNewReportLibraryEnabled: isFeatureEnabled(state.features.flags, 'reportLibrary').isEnabled,
    isJobBasedRangesEnabled: isFeatureEnabled(state.features.flags, 'jobBasedRanges').isEnabled,
    isAdvancedSurveyManagementEnabled: isFeatureEnabled(state.features.flags, 'surveyFlag').isEnabled,
    isDysonEnabled: isFeatureEnabled(state.features.flags, 'dyson').isEnabled
  }),
  {
    ...jobCountActions,
    ...employeeCountActions,
    ...gradeCountActions,
    ...appActions,
    ...subsiteActions,
    getSiteActions,
    ...refreshMarketReportsActions,
    ...migrateCompanySurveyActions,
    getCompSetsCount
  }
)
export default class HeaderNav extends Component {
  static propTypes = {
    homeJobCount: PropTypes.object,
    homeEmployeeCount: PropTypes.object,
    homeGradeCount: PropTypes.object,
    history: PropTypes.object,
    competitiveSets: PropTypes.object,
    location: PropTypes.object,
    router: PropTypes.object,
    fullName: PropTypes.string,
    email: PropTypes.string,
    headerSearchButtonClick: PropTypes.func,
    closeFullSearchBar: PropTypes.func,
    adminButtonClick: PropTypes.func,
    closeAdminBar: PropTypes.func,
    isInfluitiveEnabled: PropTypes.bool,
    isHelpFormEnabled: PropTypes.bool,
    closeUploadModal: PropTypes.func,
    showHelpForm: PropTypes.func,
    app: PropTypes.object,

    isFileUploadEnabled: PropTypes.bool,
    fileUpload: PropTypes.object,
    fileUploadOnDrop: PropTypes.func,
    cancelUpload: PropTypes.func,
    nextStepUpload: PropTypes.func,
    doneUpload: PropTypes.func,
    removeUploadedFile: PropTypes.func,
    removeRejectedFile: PropTypes.func,
    downloadFiles: PropTypes.func,
    sendFileUploadToProcess: PropTypes.func,

    onDragEnter: PropTypes.func,
    onDragLeave: PropTypes.func,
    onDropRejected: PropTypes.func,
    onChangeUploadCategory: PropTypes.func,

    sites: PropTypes.object,
    getSiteActions: PropTypes.func,

    refreshSiteMarketReports: PropTypes.func,
    refreshMarketReports: PropTypes.object,
    beginCompanySurveyMigration: PropTypes.func,
    migrateCompanySurvey: PropTypes.object,
    isNewReportLibraryEnabled: PropTypes.bool,
    isAdvancedSurveyManagementEnabled: PropTypes.bool,

    isDysonEnabled: PropTypes.bool,
    getJobCount: PropTypes.func,
    getHomeEmployeeCount: PropTypes.func,
    getGradeCount: PropTypes.func,
    getCompSetsCount: PropTypes.func
  };

  constructor(props) {
    super(props);

    this.scrollListener = this.scrollListener.bind(this);

    this.state = {
      logoFixed: true,
      headerTall: true,
      relativeLogoTransitionClass: props.router.location.pathname !== '/',
      imgUrl: null
    };
  }

  componentDidMount() {
    this.props.getJobCount();
    this.props.getHomeEmployeeCount();
    this.props.getGradeCount();

    if (this.props.isAdvancedSurveyManagementEnabled) {
      this.props.getCompSetsCount();
    }

    if (this.props.router.location.pathname === '/') document.onscroll = this.scrollListener;

    const randomVersion = Math.floor(Math.random() * 10000);

    //cache busting
    this.setState({
      imgUrl: getCompanyLogoPath(this.props.app.logo, this.props.app.subsite) + '?v=' + randomVersion
    });

    this.props.history.beforeTransition(({ currPath, nextPath }) => {
      if (nextPath === '/' && currPath !== '/') {
        document.onscroll = this.scrollListener;
        this.resetHeaderAndLogo();
      }
      if (nextPath !== '/' && currPath === '/') {
        document.onscroll = undefined;
      }
    });

    document.addEventListener('keydown', event => {
      if (event.key === 's' && event.ctrlKey) {
        if (this.props.app.showFullSearch) {
          this.props.closeFullSearchBar();
          return;
        }
        this.props.headerSearchButtonClick();
      }

      if (event.key === 'Escape') {
        this.props.closeAdminBar();
      }
    });
    if (this.props.getSiteActions().getSites()) {
      if (this.props.router.location.pathname) {
        this.props.getSiteActions().getSites(decodeURI(this.props.router.location.pathname));
      } else {
        this.props.getSiteActions().getSites();
      }
    }
  }

  UNSAFE_componentWillUnmount() {
    document.onscroll = undefined;
  }

  scrollListener() {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

    if (scrollTop >= 112 && this.state.logoFixed) this.setLogoFixed(false);
    if (scrollTop < 112 && !this.state.logoFixed) this.setLogoFixed(true);

    if (scrollTop >= 177 && this.state.headerTall) this.setHeaderTall(false);
    if (scrollTop < 177 && !this.state.headerTall) this.setHeaderTall(true);
  }

  setLogoFixed(bool) {
    if (bool === false) {
      setTimeout(() => {
        this.setState({ ...this.state, relativeLogoTransitionClass: true });
      }, 40);
      this.setState({ ...this.state, logoFixed: bool });
    } else {
      setTimeout(() => {
        this.setState({ ...this.state, logoFixed: bool });
      }, 200);
      this.setState({ ...this.state, relativeLogoTransitionClass: false });
    }
  }
  setHeaderTall(bool) {
    this.setState({
      ...this.state,
      headerTall: bool
    });
  }

  resetHeaderAndLogo() {
    window.scrollTo(0, 0);
    this.setState({
      ...this.state,
      logoFixed: true,
      headerTall: true,
      relativeLogoTransitionClass: false
    });
  }

  render() {
    const { router } = this.props;

    const route = router.location.pathname.slice(0, 6);
    if (route === '/print') {
      return <span> </span>;
    }

    let logoStatus = this.state.logoFixed ? ' logoFixed' : ' logoRelative';
    let headerStatus = this.state.headerTall ? ' headerRelative' : ' headerFixed';
    let relativeLogoTransitionClass = this.state.relativeLogoTransitionClass ? ' transition' : '';
    const divSpacer =
      router.location.pathname === '/' ? <div className={'spacerContainer--navbar' + headerStatus} /> : <div />;
    if (router.location.pathname !== '/') {
      logoStatus = ' logoRelative';
      headerStatus = ' headerFixed';
      relativeLogoTransitionClass = ' transition';
    }
    const { showFullSearch, showAdminBar, showFullSearchStyles } = this.props.app;
    const SearchComponent = (
      <Search
        history={this.props.history}
        showFullSearchStyles={showFullSearchStyles}
        showFullSearch={showFullSearch}
        closeFullSearchBar={this.props.closeFullSearchBar}
      />
    );
    const UtilityNavComp = (
      <UtilityNav
        app={this.props.app}
        searchClick={this.props.headerSearchButtonClick}
        adminClick={this.props.adminButtonClick}
        userRights={this.props.app.user_rights}
        isInfluitiveEnabled={this.props.isInfluitiveEnabled}
        isHelpFormEnabled={this.props.isHelpFormEnabled}
        showHelpForm={this.props.showHelpForm}
      />
    );

    const clientLogo = <img src={this.state.imgUrl} className="client-logo" />;

    return (
      <div>
        {divSpacer}
        <div className={'alt container--navbar' + headerStatus + logoStatus}>
          <div className={'navbar col-md-12' + logoStatus}>
            <div className={'logo-overflow-hidden' + logoStatus + relativeLogoTransitionClass}>
              <div className={'logoAnimateContainer ' + logoStatus + relativeLogoTransitionClass}>
                <Link to="/" className={'alt navbar__imgLink--brand' + logoStatus}>
                  <img
                    className={'navbar__img--brandLogo' + logoStatus}
                    src="/view/static/lab/imgs/logo_PS_Insight_Lab.svg"
                  />
                </Link>
              </div>
            </div>

            <div className="alt col-lg-6 col-md-6 navebar__contentContainer navbar__contentContainer--left center-vertical">
              <MainNav {...this.props} />
            </div>

            <div className="alt navebar__contentContainer navbar__contentContainer--right">
              <div className="navbar__contentRight--direction">{UtilityNavComp}</div>
            </div>
          </div>

          <SimpleSlideAnimation show={showFullSearch}>
            <HeaderFullSearchBar
              {...this.props}
              headerStatus={headerStatus}
              close={this.props.closeFullSearchBar}
              SearchBarComponent={SearchComponent}
              show={showFullSearch}
              logoStatusClass={logoStatus}
            />
          </SimpleSlideAnimation>
          <SimpleSlideAnimation show={showAdminBar}>
            <AdminBar
              show={showAdminBar}
              sites={this.props.sites}
              close={this.props.closeAdminBar}
              app={this.props.app}
              logoStatusClass={logoStatus}
              downloadFiles={this.props.downloadFiles}
              syncData={this.props.getSiteActions().syncData}
              syncProgress={this.props.getSiteActions().syncProgress}
              userRights={this.props.app.user_rights}
              refreshSiteMarketReports={this.props.refreshSiteMarketReports}
              refreshMarketReports={this.props.refreshMarketReports}
              migrateCompanySurvey={this.props.migrateCompanySurvey}
              beginCompanySurveyMigration={this.props.beginCompanySurveyMigration}
            />
          </SimpleSlideAnimation>

          <div className="header--username">
            <span className="header--username__span pull-right">
              Welcome back
              <span> {this.props.app.first_names || this.props.email}</span>
            </span>
          </div>
        </div>

        {clientLogo}
      </div>
    );
  }
}
