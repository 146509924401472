import React, { Component } from 'react';
import './ReviewSlideout.scss';
import { connect } from 'react-redux';
import ReviewHeader from './ReviewHeader';
import SummaryPanel from './Summary/SummaryPanel';
import LoadingIndicator from '../../../../LoadingIndicator/LoadingIndicator';
import * as matchReviewActions from '../../actions/MatchReviewActions';
import DuplicateMatchesPanel from './Duplicates/DuplicateMatchesPanel';
import ZeroWeightsPanel from './ZeroWeights/ZeroWeightsPanel';
import { withRouter } from 'react-router';
import { isLab } from '../../../../redux/stateSelectors';
import {
  REVIEW_STEP_SUMMARY,
  REVIEW_STEP_DUPLICATES,
  REVIEW_STEP_ZERO_WEIGHTS
} from '../../reducers/MatchReviewReducer';
import {
  REVIEW_STATUS_NONE,
  REVIEW_STATUS_LOADING,
  REVIEW_STATUS_IN_REVIEW,
  REVIEW_STATUS_SUBMITTING,
  REVIEW_STATUS_NO_REVIEW
} from '../../reducers/MatchReviewReducer';
import PropTypes, { bool, string, func, object, oneOf } from 'prop-types';
import ProgressStatusOverlay from '../../../../ProgressStatus/ProgressStatusOverlay';
import ReviewToolbar from './Toolbar/ReviewToolbar';
import SubmitFailure from './SubmitFailure/SubmitFailure';

export class ReviewSlideout extends Component {
  static propTypes = {
    surveyName: string.isRequired,
    reviewStatus: oneOf([
      REVIEW_STATUS_NONE,
      REVIEW_STATUS_NO_REVIEW,
      REVIEW_STATUS_LOADING,
      REVIEW_STATUS_IN_REVIEW,
      REVIEW_STATUS_SUBMITTING
    ]),
    hideSlideout: func.isRequired,
    loadParticipationMatchReviewItems: func.isRequired,
    submitChangesFailed: bool.isRequired,
    closeErrorNotification: func.isRequired,
    openContactSupport: func.isRequired,
    slideout: object,
    history: object,
    activeReviewStep: string,
    surveyCode: string,
    isLab: bool
  };

  constructor(props) {
    super(props);
    this.renderStep = this.renderStep.bind(this);
    this.openContactSupport = this.openContactSupport.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    //  NOTE: This is a hack since the slideout content is never unmounted we need to see if the
    //  Slideout was closed and will now be opened instead
    if (
      !this.props.slideout ||
      (this.props.slideout && !this.props.slideout.open && nextProps.slideout && nextProps.slideout.open)
    ) {
      // this.props.loadParticipationMatchReviewItems();
    }
  }

  openContactSupport() {
    this.props.openContactSupport(this.props.history);
  }

  renderStep() {
    switch (this.props.activeReviewStep) {
      case REVIEW_STEP_SUMMARY:
        return <SummaryPanel {...this.props} />;
      case REVIEW_STEP_DUPLICATES:
        return (
          <React.Fragment>
            <DuplicateMatchesPanel {...this.props} />
            <ReviewToolbar {...this.props} />
          </React.Fragment>
        );
      case REVIEW_STEP_ZERO_WEIGHTS:
        return (
          <React.Fragment>
            <ZeroWeightsPanel {...this.props} />
            <ReviewToolbar {...this.props} />
          </React.Fragment>
        );
      default:
        return null;
    }
  }

  render() {
    const {
      surveyName,
      surveyCode,
      hideSlideout,
      submitChangesFailed,
      closeErrorNotification,
      reviewStatus
    } = this.props;

    return (
      <div className="pcenter-match-review-slideout">
        <ReviewHeader surveyName={surveyName} surveyCode={surveyCode} doClose={hideSlideout} />
        {submitChangesFailed && <SubmitFailure doClose={closeErrorNotification} doSupport={this.openContactSupport} />}
        <LoadingIndicator loading={reviewStatus === REVIEW_STATUS_LOADING}>{this.renderStep()}</LoadingIndicator>
        {reviewStatus === REVIEW_STATUS_SUBMITTING && (
          <ProgressStatusOverlay primaryText={'Resolving Matches...'} secondaryText={'This might take a moment'} />
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    ...state.participationCenter.matchReview,
    isLab: isLab(state)
  };
};

const actions = {
  ...matchReviewActions
};

export default connect(mapStateToProps, actions)(withRouter(ReviewSlideout));
