import React, { Component } from 'react';
import { bool, func, number, string } from 'prop-types';
import Slider from 'rc-slider';
import classnames from 'classnames';
import 'rc-slider/assets/index.css';
import './TargetRangeSpreadSetting.scss';

export default class TargetRangeSpreadSetting extends Component {
  static propTypes = {
    handleRangeSpreadChange: func.isRequired,
    isGlobal: bool,
    targetRangeSpread: number
  };

  constructor(props) {
    super(props);
    this.state = { rangeValue: 25 };

    this.handleValueAfterChange = this.handleValueAfterChange.bind(this);
    this.handleValueChange = this.handleValueChange.bind(this);
  }

  componentDidMount() {
    const { targetRangeSpread } = this.props;

    this.setState({ rangeValue: Math.round(targetRangeSpread * 100) });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { targetRangeSpread, isSlideoutOpen } = this.props;
    if (isSlideoutOpen !== prevProps.isSlideoutOpen || targetRangeSpread !== prevProps.targetRangeSpread) {
      this.setState({
        rangeValue: Math.round(targetRangeSpread * 100)
      });
    }
  }

  handleValueAfterChange() {
    const { handleRangeSpreadChange } = this.props;
    const { rangeValue } = this.state;

    handleRangeSpreadChange(rangeValue || 0);
  }

  handleValueChange(event) {
    if (event.key === 'Enter') {
      return this.handleValueAfterChange();
    }

    let newValue = event?.target?.value || event;
    newValue = '' ? null : parseInt(newValue);
    this.setState({ rangeValue: newValue });
  }

  render() {
    const { rangeValue } = this.state;
    const handleStyle = { border: '1px solid #c0c0bf', height: '20px', width: '20px' };
    const railStyle = { height: '10px' };
    const testId = 'jbr-target-spread-setting';

    return (
      <React.Fragment>
        {!this.props.isGlobal && <h5 className="target-range-spread--label">Target Range Spread</h5>}
        <div className="range-slider-container" data-test-id={testId}>
          <Slider
            defaultValue={rangeValue || 0}
            handleStyle={handleStyle}
            max={100}
            min={0}
            onAfterChange={this.handleValueAfterChange}
            onChange={this.handleValueChange}
            railStyle={railStyle}
            value={rangeValue || 0}
          />
          <div className="target__range__spread-input-container">
            <input
              className="target__range__spread-input"
              data-test-id="target__range__spread-input"
              max={100}
              min={0}
              onBlur={this.handleValueAfterChange}
              onChange={this.handleValueChange}
              onKeyDown={this.handleValueChange}
              type="number"
              value={rangeValue}
            />
            <i className="target__range__spread-input-percent">%</i>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
