import { getCSRFPair } from '../../../redux/stateSelectors';
import apiClient from '../../../lib/apiClient';
import {
  CASE_CATEGORY_CONTACT_SUPPORT,
  getDefaultFeature,
  getFeatureOptions,
  getFunctionOptions,
  getUrlMappingModuleFeature
} from '../mappings';
import { getBrowser } from '../utils';
import uuid from 'uuid';
import {
  SUPPORTED_ATTACHMENT_FILE_EXTENSIONS,
  SUPPORTED_DESCRIPTION_MAX_CHARACTERS,
  SUPPORTED_TOTAL_ATTACHMENT_FILESIZE,
  SUPPORTED_TOTAL_ATTACHMENT_FILESIZE_PRETTY
} from '../rules';
export const SUPPORT_TICKET_DESCRIPTION_CHANGE = 'SUPPORT_TICKET_DESCRIPTION_CHANGE';
export const SUPPORT_TICKET_DESCRIPTION_ERROR = 'SUPPORT_TICKET_DESCRIPTION_ERROR';
export const SUPPORT_TICKET_MODULE_CHANGE = 'SUPPORT_TICKET_MODULE_CHANGE';
export const SUPPORT_TICKET_MODULE_ERROR = 'SUPPORT_TICKET_MODULE_ERROR';
export const SUPPORT_TICKET_FEATURE_CHANGE = 'SUPPORT_TICKET_FEATURE_CHANGE';
export const SUPPORT_TICKET_FEATURE_ERROR = 'SUPPORT_TICKET_FEATURE_ERROR';
export const SUPPORT_TICKET_FUNCTION_CHANGE = 'SUPPORT_TICKET_FUNCTION_CHANGE';
export const SUPPORT_TICKET_CASE_CATEGORY_CHANGE = 'SUPPORT_TICKET_CASE_CATEGORY_CHANGE';
export const SUPPORT_TICKET_OPENED = 'SUPPORT_TICKET_OPENED';
export const SUPPORT_TICKET_SUBMITTED = 'SUPPORT_TICKET_SUBMITTED';
export const SUPPORT_TICKET_SUBMIT_ATTEMPT = 'SUPPORT_TICKET_SUBMIT_ATTEMPT';
export const SUPPORT_TICKET_SUBMIT_SUCCESS = 'SUPPORT_TICKET_SUBMIT_SUCCESS';
export const SUPPORT_TICKET_SUBMIT_FAILURE = 'SUPPORT_TICKET_SUBMIT_FAILURE';
export const SUPPORT_TICKET_SUBMIT_UNEXPECTED_ERROR = 'SUPPORT_TICKET_SUBMIT_UNEXPECTED_ERROR';
export const SUPPORT_TICKET_SUBMIT_FIELD_ERRORS = 'SUPPORT_TICKET_SUBMIT_FIELD_ERRORS';
export const SUPPORT_TICKET_SUBMIT_STATUS_CLOSE = 'SUPPORT_TICKET_SUBMIT_STATUS_CLOSE';
export const SUPPORT_TICKET_ATTACHMENT_STATUS_CLOSE = 'SUPPORT_TICKET_ATTACHMENT_STATUS_CLOSE';
export const SUPPORT_TICKET_ATTACHMENT_DRAGGING = 'SUPPORT_TICKET_ATTACHMENT_DRAGGING';
export const SUPPORT_TICKET_ADD_ATTACHMENTS = 'SUPPORT_TICKET_ADD_ATTACHMENTS';
export const SUPPORT_TICKET_REMOVE_ATTACHMENT = 'SUPPORT_TICKET_REMOVE_ATTACHMENT';
export const SUPPORT_TICKET_ATTACHMENT_ERRORS = 'SUPPORT_TICKET_ATTACHMENT_ERRORS';
export const ATTACHMENT_ERROR_TYPE_MAX_SIZE = 'ATTACHMENT_ERROR_TYPE_MAX_SIZE';
export const ATTACHMENT_ERROR_TYPE_UNSUPPORTED_EXTENSION = 'ATTACHMENT_ERROR_TYPE_UNSUPPORTED_EXTENSION';

const readAttachmentFile = file => {
  const reader = new FileReader();
  return new Promise(function(resolve, reject) {
    reader.onload = function(evt) {
      resolve(evt.target.result);
    };
    reader.onerror = function(err) {
      reject(err);
    };
    reader.readAsArrayBuffer(file);
  });
};

export const initContactSupport = () => dispatch => {
  const mapping = getUrlMappingModuleFeature(window.location.href);
  dispatch({ type: SUPPORT_TICKET_OPENED, payload: CASE_CATEGORY_CONTACT_SUPPORT });
  dispatch(changeTicketModule(mapping.module));
  dispatch(changeTicketFeature(mapping.feature));
};

export const submitTicket = () => async (dispatch, getState) => {
  const { inputModule, inputFeature, inputFunction, inputDescription, inputCategory, attachments } = getState().support;

  const inputErrors = [];

  dispatch({ type: SUPPORT_TICKET_SUBMIT_ATTEMPT });

  //  verify that all of the required fields are filled out or not
  if (!inputCategory) {
    inputErrors.push('Case Category is required');
  }

  if (!inputDescription || inputDescription.length <= 0) {
    inputErrors.push('Description is required');
    dispatch({ type: SUPPORT_TICKET_DESCRIPTION_ERROR, payload: 'The field is required.' });
  } else if (inputDescription.length > SUPPORTED_DESCRIPTION_MAX_CHARACTERS) {
    inputErrors.push('Description character limit exceeded');
    dispatch({ type: SUPPORT_TICKET_DESCRIPTION_ERROR, payload: 'Character limit exceeded.' });
  }

  if (inputErrors.length > 0) {
    dispatch({ type: SUPPORT_TICKET_SUBMIT_FIELD_ERRORS, payload: inputErrors });
    return;
  }

  //  NOTE: IE11 and Edge do NOT support the File Constructor which would be cleaner
  //  Since that's the case, we need to send a blob of data instead of a File object
  const uploadFiles = [];
  if (attachments) {
    attachments.forEach(attachment => {
      const blob = new Blob([attachment.data], { type: attachment.type });
      //  this is required or the blob won't be considered a file when form is submitted
      blob.lastModifiedDate = new Date();
      uploadFiles.push(blob);
    });
  }

  uploadFiles.forEach(f => (f.uuid = uuid.v4()));

  let result;
  try {
    const csrfPair = getCSRFPair(getState());
    const formData = new FormData();
    formData.append('ticket_type', inputCategory);
    formData.append('module_type', inputModule);
    formData.append('component_type', inputFeature);
    formData.append('function_type', inputFunction);
    formData.append('description', inputDescription);

    for (let i = 0; i < uploadFiles.length; i++) {
      formData.append('theFile', uploadFiles[i], attachments[i].name);
    }

    formData.append('csrf_timestamp', csrfPair.csrf_timestamp);
    formData.append('csrf_token', csrfPair.csrf_token);

    formData.append('system', 'MarketPay');
    formData.append('browser', getBrowser());
    formData.append('mp_url', window.location.href);

    dispatch({ type: SUPPORT_TICKET_SUBMITTED });
    result = await apiClient.apiPost('/api/support/ticketing/createTicket', formData);

    if (result.data.success) {
      dispatch({ type: SUPPORT_TICKET_SUBMIT_SUCCESS });
    } else {
      dispatch({ type: SUPPORT_TICKET_SUBMIT_FAILURE, payload: result.data.message });
    }
  } catch (err) {
    dispatch({ type: SUPPORT_TICKET_SUBMIT_UNEXPECTED_ERROR, payload: err });
  }

  return { type: SUPPORT_TICKET_SUBMITTED };
};

export const addAttachments = attachments => async (dispatch, getState) => {
  let { attachmentSizeTotal } = getState().support;

  let attachmentErrors = [];
  let uploadedGoodFiles = [];
  const uploadedBadFiles = [];

  //  determine if max size is exceeded and all bad file extension types
  for (let i = 0; i < attachments.length; i++) {
    const attachment = attachments[i];
    attachment.data = await readAttachmentFile(attachment);
    attachmentSizeTotal += attachment.size;

    const fileName = attachment.name;
    const extension = fileName.substring(fileName.lastIndexOf('.'));
    if (SUPPORTED_ATTACHMENT_FILE_EXTENSIONS.indexOf(extension.toLowerCase()) < 0) {
      uploadedBadFiles.push(fileName);
    } else {
      uploadedGoodFiles.push(attachment);
    }
  }

  let errorType;
  if (attachmentSizeTotal > SUPPORTED_TOTAL_ATTACHMENT_FILESIZE) {
    errorType = ATTACHMENT_ERROR_TYPE_MAX_SIZE;
    attachmentErrors.push(`The total file size has exceeded the ${SUPPORTED_TOTAL_ATTACHMENT_FILESIZE_PRETTY} limit.`);
    uploadedGoodFiles = []; //  do not upload any files if total upload size is exceeded
  } else if (uploadedBadFiles.length > 0) {
    errorType = ATTACHMENT_ERROR_TYPE_UNSUPPORTED_EXTENSION;
    attachmentErrors = uploadedBadFiles.sort();
  }

  if (errorType) {
    dispatch({
      type: SUPPORT_TICKET_ATTACHMENT_ERRORS,
      payload: {
        errors: attachmentErrors,
        errorType: errorType
      }
    });
  }

  if (uploadedGoodFiles.length > 0) {
    dispatch({
      type: SUPPORT_TICKET_ADD_ATTACHMENTS,
      payload: uploadedGoodFiles.map(attachment => {
        return {
          name: attachment.name,
          url: attachment.preview,
          data: attachment.data,
          type: attachment.type,
          size: attachment.size
        };
      })
    });
  }
};

export function closeSubmitStatus() {
  return { type: SUPPORT_TICKET_SUBMIT_STATUS_CLOSE };
}
export function closeAttachmentStatus() {
  return { type: SUPPORT_TICKET_ATTACHMENT_STATUS_CLOSE };
}
export function removeAttachment(value) {
  return { type: SUPPORT_TICKET_REMOVE_ATTACHMENT, payload: value };
}

export const changeTicketDescription = (id, value) => (dispatch, getState) => {
  const { submitAttempted, inputDescriptionError } = getState().support;
  if (value && value.length > SUPPORTED_DESCRIPTION_MAX_CHARACTERS) {
    value = value.substring(0, SUPPORTED_DESCRIPTION_MAX_CHARACTERS);
  }

  if (submitAttempted && (!value || value.length <= 0)) {
    dispatch({ type: SUPPORT_TICKET_DESCRIPTION_ERROR, payload: 'The field is required.' });
  } else if (submitAttempted && inputDescriptionError) {
    dispatch({ type: SUPPORT_TICKET_DESCRIPTION_ERROR, payload: null });
  }

  dispatch({ type: SUPPORT_TICKET_DESCRIPTION_CHANGE, payload: value });
};

export function changeTicketModule(value) {
  const defaultFeature = getDefaultFeature(value);
  return {
    type: SUPPORT_TICKET_MODULE_CHANGE,
    payload: {
      inputModule: value,
      inputModuleError: null,
      inputFeature: defaultFeature,
      inputFeatureError: null,
      featureOptions: getFeatureOptions(value),
      inputFunction: null,
      functionOptions: getFunctionOptions(value, defaultFeature)
    }
  };
}

export function startAttachmentDrag(value) {
  return { type: SUPPORT_TICKET_ATTACHMENT_DRAGGING, payload: true };
}

export function stopAttachmentDrag(value) {
  return { type: SUPPORT_TICKET_ATTACHMENT_DRAGGING, payload: false };
}

export const changeTicketFeature = value => async (dispatch, getState) => {
  const module = getState().support.inputModule;
  dispatch({
    type: SUPPORT_TICKET_FEATURE_CHANGE,
    payload: {
      inputFeature: value,
      inputFeatureError: null,
      inputFunction: null,
      functionOptions: getFunctionOptions(module, value)
    }
  });
};

export function changeTicketFunction(value) {
  return { type: SUPPORT_TICKET_FUNCTION_CHANGE, payload: value };
}

export function changeTicketCategory(value) {
  return { type: SUPPORT_TICKET_CASE_CATEGORY_CHANGE, payload: value };
}
