import Pagination from '../genericGrid/pagination';

const initialState = {
  perPageCount: 10,
  pageNumber: 1,
  searchResultsCount: 0
};

const employeesPagination = new Pagination('JOBS', initialState);
export const reducer = employeesPagination.createReducer();
export const actions = employeesPagination.getActions();
