import React from 'react';
import './SummaryCard.scss';
import { number, bool } from 'prop-types';

const propTypes = {
  duplicateMatchesCount: number,
  zeroWeightedMatchesCount: number,
  isLab: bool
};

const getValueClass = count => {
  if (count > 0) {
    return 'pcenter-match-review-summary-card__category-value--warn';
  } else {
    return 'pcenter-match-review-summary-card__category-value--good';
  }
};

const SummaryCard = props => {
  const { zeroWeightedMatchesCount, duplicateMatchesCount, isLab } = props;
  return (
    <div className="pcenter-match-review-summary-card">
      <header className="pcenter-match-review-summary-card__title">
        <span className="pcenter-match-review-summary-card__warning-icon icon-reverse icon-circle">
          <icon className="icon-attention-alt" />
        </span>
        Matches Need Review
      </header>
      <div className="pcenter-match-review-summary-card__category-row">
        <div>
          <div>Internal jobs with duplicate matches</div>
          <div className="pcenter-match-review-summary-card__category-details">
            Include only 1 match for each internal job
          </div>
        </div>
        <h2 className={getValueClass(duplicateMatchesCount)}>{duplicateMatchesCount}</h2>
      </div>
      {!isLab ? (
        <div className="pcenter-match-review-summary-card__category-row">
          <div>
            <div>0 weighted survey job matches</div>
            <div className="pcenter-match-review-summary-card__category-details">
              Review and decide to include them or not in the report
            </div>
          </div>
          <h2 className={getValueClass(zeroWeightedMatchesCount)}>{zeroWeightedMatchesCount}</h2>
        </div>
      ) : null}
    </div>
  );
};

SummaryCard.propTypes = propTypes;
export default SummaryCard;
