//this is abstracted to make it easy to switch out ajax implementations
import axios from 'axios';

export const formatUrl = (path, useMerit = false) => {
  /* rules:
    - API calls to mp-node: <host>:<port>/<subsite>/app/api/...
    - GQL calls to mp-ui-services: /services/o/<subsite>
    - CMS calls to cms: /cms
   */

  const slash = path[0] === '/' ? '' : '/';

  const reResult = /\/view\/o\/(.+)\/(mp|lab)\/?.*/.exec(window.location.pathname);
  const subsite = reResult && reResult[1];
  // After being authenticated by Identity we return to Lab and put the 'login' as a default subsite name.
  // We try to retrieve the user's actual subsite info before the home page of the fake 'login' site gets loaded.
  // But sometimes the load of the 'login' site is faster and that will throw out a 403 error
  // If redirected to /view/o/login/mp|lab, do not send any request
  if (subsite === 'login') {
    console.debug('falsy subsite name: login');
    return undefined;
  }

  if (path.match(/^\/services/)) return '/services/o/' + subsite + '/';
  if (path.match(/^\/cms/)) return path;

  const fqdn = window.location.origin;

  const isTclRoute =
    path.includes('one-page-summary-export') || path.includes('submit-show-last-modified') || useMerit === true
      ? `/merit-${subsite}`
      : '/app';
  // any non-localhost api hosts should be modded to fit the format below
  const finalPath = fqdn + '/' + subsite + isTclRoute + slash + path;
  return finalPath;
};

axios.apiPost = function(url, options = {}, config) {
  const useMerit = config && config.useMerit === true;
  if (window && window.app) {
    if (useMerit) {
      options.append(encodeURIComponent('csrf_token'), encodeURIComponent(window.app.csrf_token));
      options.append(encodeURIComponent('csrf_timestamp'), encodeURIComponent(window.app.csrf_timestamp));
    } else {
      options.csrf_token = window.app.csrf_token;
      options.csrf_timestamp = window.app.csrf_timestamp;
    }
  }
  const formattedUrl = formatUrl(url, useMerit);
  if (formattedUrl) {
    return this.post(formatUrl(url, useMerit), options, config);
  } else {
    return undefined;
  }
};

axios.apiPatch = function(url, options = {}, config) {
  const useMerit = config && config.useMerit === true;
  if (window && window.app) {
    if (useMerit) {
      options.append(encodeURIComponent('csrf_token'), encodeURIComponent(window.app.csrf_token));
      options.append(encodeURIComponent('csrf_timestamp'), encodeURIComponent(window.app.csrf_timestamp));
    } else {
      options.csrf_token = window.app.csrf_token;
      options.csrf_timestamp = window.app.csrf_timestamp;
    }
  }
  const formattedUrl = formatUrl(url, useMerit);
  if (formattedUrl) {
    return this.patch(formatUrl(url, useMerit), options, config);
  } else {
    return undefined;
  }
};

axios.apiGet = function(url, options = {}, config) {
  if (!options.params) options.params = {};
  const useMerit = config && config.useMerit === true;
  if (window && window.app) {
    options.params.csrf_token = window.app.csrf_token;
    options.params.csrf_timestamp = window.app.csrf_timestamp;
  }
  const formattedUrl = formatUrl(url, useMerit);
  if (formattedUrl) {
    return this.get(formatUrl(url, useMerit), options);
  } else {
    return undefined;
  }
};

axios.apiPut = function(url, options = {}, config) {
  const useMerit = config && config.useMerit === true;
  if (window && window.app) {
    options.csrf_token = window.app.csrf_token;
    options.csrf_timestamp = window.app.csrf_timestamp;
  }
  const formattedUrl = formatUrl(url, useMerit);
  if (formattedUrl) {
    return this.put(formatUrl(url, useMerit), options);
  } else {
    return undefined;
  }
};

axios.apiDelete = function(url, options = {}, config) {
  const useMerit = config && config.useMerit === true;
  if (!options.data) options.data = {};
  if (window && window.app) {
    options.data.csrf_token = window.app.csrf_token;
    options.data.csrf_timestamp = window.app.csrf_timestamp;
  }
  const formattedUrl = formatUrl(url, useMerit);
  if (formattedUrl) {
    return this.delete(formatUrl(url, useMerit), options);
  } else {
    return undefined;
  }
};

export default axios;
