import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import './ModalFooter.scss';

export default class ModalFooter extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    children: PropTypes.any,
    cancelBtnTxtName: PropTypes.string,
    submitBtnTxtName: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    cancelBtnClick: PropTypes.func,
    submitBtnClick: PropTypes.func,
    hideSubmitBtn: PropTypes.bool,
    hideCancelButton: PropTypes.bool,
    saveButtonDisabled: PropTypes.bool
  };

  render() {
    return (
      <div className={`modal__footer ${this.props.className}`}>
        {this.props.children}
        {!this.props.hideCancelButton && (
          <a className="modal__footer-cancel" onClick={this.props.cancelBtnClick}>
            {this.props.cancelBtnTxtName ? this.props.cancelBtnTxtName : 'Cancel'}
          </a>
        )}
        {!this.props.hideSubmitBtn && (
          <button
            disabled={this.props.saveButtonDisabled}
            className="pxl-btn pxl-btn-primary"
            onClick={this.props.submitBtnClick}
          >
            {this.props.submitBtnTxtName ? this.props.submitBtnTxtName : 'Submit'}
          </button>
        )}
      </div>
    );
  }
}
