import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

/**
 * Collapsible wraps children in a vertically collapsible div. The collapsed state
 * must be maintained by a parent component and passed in as a prop.
 * @param {*} props
 */
const Collapsible = props => {
  let container = useRef(null);
  let expandTimeout = useRef(null);

  useEffect(() => {
    if (props.collapsed) {
      // Start from current height
      if (container.current.style?.height === 'auto') {
        container.current.style.height = container.current.scrollHeight + 'px';
      }

      // On next re-paint, animate to height 0
      window.requestAnimationFrame(() => {
        container.current.style.height = 0;
      });

      // If collapsing before transition finishes, clear timeout
      if (expandTimeout.current) {
        clearTimeout(expandTimeout.current);
      }
    } else {
      if (props.scrollHeightCutoff && container.current.scrollHeight <= props.scrollHeightCutoff) {
        container.current.style.height = 'auto';
        return;
      }
      // Animate to height of inner contents
      if (container?.current?.style) {
        container.current.style.height = container.current.scrollHeight + 'px';
      }

      // After animation completes, let container grow/shrink to child elements
      expandTimeout.current = setTimeout(() => {
        if (container?.current?.style?.height) {
          container.current.style.height = 'auto';
        }
      }, props.transitionTimeMs || 300);
    }
  }, [props.collapsed]);

  return (
    <div
      className={props.className}
      ref={container}
      style={{
        overflowY: 'hidden',
        overflowX: 'hidden',
        transition: `height ${props.transitionTimeMs || 300}ms ${props.transitionTimingFunc || 'ease'}`
      }}
    >
      {props.children}
    </div>
  );
};

Collapsible.propTypes = {
  children: PropTypes.array,
  className: PropTypes.string,
  collapsed: PropTypes.bool,
  transitionTimeMs: PropTypes.number,
  transitionTimingFunc: PropTypes.string,
  scrollHeightCutoff: PropTypes.number
};

export default Collapsible;
