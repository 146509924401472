import addAlert from 'ps-components/src/AlertToast/addAlert';
import apiClient from '../../lib/apiClient';
import template from 'lodash/template';
import templateSettings from 'lodash/templateSettings';

export const REPORT_FILTER_INCLUDE = 'REPORT_FILTER_INCLUDE';
export const REPORT_FILTER_TEXT = 'REPORT_FILTER_TEXT';
export const REPORT_FILTER_FAVORITE = 'REPORT_FILTER_FAVORITE';
export const REPORT_FILTER_FAVORITE_LOAD = 'REPORT_FILTER_FAVORITE_LOAD';
export const REPORT_FILTER_POPULAR = 'REPORT_FILTER_POPULAR';
export const REPORT_FILTER_POPULAR_LOAD = 'REPORT_FILTER_POPULAR_LOAD';
export const REPORT_FILTER_SCOPE = 'REPORT_FILTER_SCOPE';
export const REPORT_FILTER_TYPE = 'REPORT_FILTER_TYPE';
export const REPORT_SORT_FIELD = 'REPORT_SORT_FIELD';
export const REPORT_FILTER_CREATED_BY = 'REPORT_FILTER_CREATED_BY';
export const REPORT_FILTER_GROUP_ID = 'REPORT_FILTER_GROUP_ID';
export const REPORT_LIST_STALE = 'REPORT_LIST_STALE';
export const REPORT_VIEW_PREFERENCE_SAVE = 'REPORT_VIEW_PREFERENCE_SAVE';
export const REPORT_VIEW_PREFERENCE_LOAD = 'REPORT_VIEW_PREFERENCE_LOAD';
export const REPORT_VIEW_DEFAULT = 'card';
export const REPORT_LIBRARY_LIST_INIT = 'REPORT_LIBRARY_LIST_INIT';
export const REPORT_LIBRARY_LIST_SUCCESS = 'REPORT_LIBRARY_LIST_SUCCESS';
export const REPORT_LIBRARY_LIST_FAIL = 'REPORT_LIBRARY_LIST_FAIL';
export const REPORT_FAVORITE_INIT = 'REPORT_FAVORITE_INIT';
export const REPORT_FAVORITE_SUCCESS = 'REPORT_FAVORITE_SUCCESS';
export const REPORT_FAVORITE_FAIL = 'REPORT_FAVORITE_FAIL';
export const REPORT_FAVORITE_OPTIMISTIC = 'REPORT_FAVORITE_OPTIMISTIC';
export const REPORT_VISUALIZATION_INIT = 'REPORT_VISUALIZATION_INIT';
export const REPORT_VISUALIZATION_SUCCESS = 'REPORT_VISUALIZATION_SUCCESS';
export const REPORT_VISUALIZATION_FAIL = 'REPORT_VISUALIZATION_FAIL';
export const REPORT_TYPE_INIT = 'REPORT_TYPE_INIT';
export const REPORT_TYPE_SUCCESS = 'REPORT_TYPE_SUCCESS';
export const REPORT_TYPE_FAIL = 'REPORT_TYPE_FAIL';
export const SORT_REPORTS_BY_TYPE = 'SORT_REPORTS_BY_TYPE';
export const REPORT_CLONE_INIT = 'REPORT_CLONE_INIT';
export const REPORT_CLONE_SUCCESS = 'REPORT_CLONE_SUCCESS';
export const REPORT_CLONE_FAIL = 'REPORT_CLONE_FAIL';
export const REPORT_DELETE_INIT = 'REPORT_DELETE_DELETE';
export const REPORT_DELETE_SUCCESS = 'REPORT_DELETE_SUCCESS';
export const REPORT_DELETE_FAIL = 'REPORT_DELETE_FAIL';
export const REPORT_EDITABLE = 'REPORT_EDITABLE';
export const SELECT_REPORT = 'SELECT_REPORT';
export const SELECT_REPORT_BULK_EDIT = 'SELECT_REPORT_BULK_EDIT';
export const DESELECT_REPORT_BULK_EDIT = 'DESELECT_REPORT_BULK_EDIT';
export const REPORT_DELETE_ALL_INIT = 'REPORT_DELETE_ALL_INIT';
export const REPORT_DELETE_ALL_SUCCESS = 'REPORT_DELETE_ALL_SUCCESS';
export const REPORT_DELETE_ALL_FAIL = 'REPORT_DELETE_ALL_FAIL';
export const DESELECT_ALL_REPORT_BULK_EDIT = 'DESELECT_ALL_REPORT_BULK_EDIT';

export const REPORT_LIBRARY_COLLECTIONS_INIT = 'REPORT_LIBRARY_COLLECTIONS_INIT';
export const REPORT_LIBRARY_COLLECTIONS_SUCCESS = 'REPORT_LIBRARY_COLLECTIONS_SUCCESS';
export const REPORT_LIBRARY_COLLECTIONS_FAIL = 'REPORT_LIBRARY_COLLECTIONS_FAIL';
export const REPORT_COLLECTION_FILTER = 'REPORT_COLLECTION_FILTER';
export const TOGGLE_COLLECTIONS = 'TOGGLE_COLLECTIONS';
export const SELECT_POPULAR = 'SELECT_POPULAR';
export const DESELECT_POPULAR = 'DESELECT_POPULAR';
export const SET_COLLECTION_TYPE_FILTER = 'SET_COLLECTION_TYPE_FILTER';
export const CLEAR_REPORT_FILTERS = 'CLEAR_REPORT_FILTERS';

/*
 * START Local Storage and non-backend related actions for Report Library
 */

/* Update include filter */
export function setIncludeFilter(option) {
  const data = option === 'Spreadsheet' ? 'TABULAR' : option === 'Tableau' ? 'VISUALIZATION' : 'ALL';

  return {
    type: REPORT_FILTER_INCLUDE,
    data
  };
}

/* Update filter text */
export function setTextFilter(option) {
  return {
    type: REPORT_FILTER_TEXT,
    data: option
  };
}

/* Sort reports by type */
export function sortReportsByType(direction) {
  return {
    type: SORT_REPORTS_BY_TYPE,
    data: direction
  };
}

/* Get the currently saved favoriteFilter from Local Storage */
async function readFavoriteFilter() {
  let favoriteFilter = window.localStorage.getItem('favoriteFilter');

  if (favoriteFilter === null) {
    favoriteFilter = false;
  } else {
    favoriteFilter = favoriteFilter === 'true';
  }

  return favoriteFilter;
}

/* Save favoriteFilter to Local Storage */
async function saveFavoriteFilter(option) {
  window.localStorage.setItem('favoriteFilter', option);
  return option;
}

/* Save popularFilter to Local Storage */
async function savePopularFilter(option) {
  window.localStorage.setItem('popularFilter', option);
  return option;
}

/* Get the currently saved favoriteFilter from Local Storage */
export function getFavoriteFilter() {
  return function(dispatch) {
    return readFavoriteFilter()
      .then(option => {
        dispatch(getFavorite(option));
      })
      .catch(err => {
        console.error('Error reading localStorage for report favorites preference.', err);
      });
  };
}

/* Save favoriteFilter to Local Storage */
export function setFavoriteFilter(option) {
  return function(dispatch) {
    return saveFavoriteFilter(option)
      .then(option => {
        dispatch(setFavorite(option));
      })
      .catch(err => {
        console.error('Error writing to localStorage for report favorites preference.', err);
      });
  };
}

/* Save popularFilter to Local Storage */
export function setPopularFilter(option) {
  return function(dispatch) {
    return savePopularFilter(option)
      .then(option => {
        dispatch(setPopular(option));
      })
      .catch(err => {
        console.error('Error writing to localStorage for report popular preference.', err);
      });
  };
}

/* Get favorite filter */
export function getFavorite(option) {
  return {
    type: REPORT_FILTER_FAVORITE_LOAD,
    data: option
  };
}

/* Set favorite filter */
export function setFavorite(option) {
  return {
    type: REPORT_FILTER_FAVORITE,
    data: option
  };
}

/* Get favorite filter */
export function getPopular(option) {
  return {
    type: REPORT_FILTER_POPULAR_LOAD,
    data: option
  };
}

/* Set favorite filter */
export function setPopular(option) {
  return {
    type: REPORT_FILTER_POPULAR,
    data: option
  };
}

/* Set scope filter */
export function setScopeFilter(option) {
  return {
    type: REPORT_FILTER_SCOPE,
    data: option
  };
}

/* Set type filter */
export function setTypeFilter(options) {
  return {
    type: REPORT_FILTER_TYPE,
    data: options
  };
}

/* Set collection filter */
export function setCollectionsFilter(options) {
  return {
    type: REPORT_COLLECTION_FILTER,
    data: options
  };
}

/* Set sort filter */
export function setSortField(field, direction) {
  return {
    type: REPORT_SORT_FIELD,
    data: {
      field,
      direction
    }
  };
}

/* Set created by filter */
export function setCreatedByFilter(option) {
  return {
    type: REPORT_FILTER_CREATED_BY,
    data: option
  };
}

/* Set groupId filter */
export function setGroupIdFilter(option) {
  return {
    type: REPORT_FILTER_GROUP_ID,
    data: option
  };
}

/* Hide/Show one or more collections */
export function toggleCollections(id, checked) {
  return {
    type: TOGGLE_COLLECTIONS,
    id,
    checked
  };
}

/* Set a collection type filter */
export function setCollectionTypeFilter(option) {
  return {
    type: SET_COLLECTION_TYPE_FILTER,
    data: option
  };
}

/* Get the currently saved reportViewType from Local Storage */
async function readViewPreference() {
  return window.localStorage.getItem('reportViewType');
}

/* Save reportViewType to Local Storage */
async function saveViewPreference(option) {
  window.localStorage.setItem('reportViewType', option);
  return option;
}

/* Get the currently saved reportViewType from Local Storage */
export function getViewPreference() {
  return function(dispatch) {
    return readViewPreference()
      .then(option => {
        dispatch(loadView(option));
      })
      .catch(err => {
        console.error('Error reading localStorage for report view preference.', err);
      });
  };
}

/* Save reportViewType to Local Storage */
export function setViewPreference(option) {
  return function(dispatch) {
    return saveViewPreference(option)
      .then(option => {
        dispatch(setView(option));
      })
      .catch(err => {
        console.error('Error writing to localStorage for report view preference.', err);
      });
  };
}

/* Set reportListStale, no idea what that does */
export function setReportListStale(option) {
  return {
    type: REPORT_LIST_STALE,
    data: option
  };
}

/* Set view preference */
export function setView(option) {
  return {
    type: REPORT_VIEW_PREFERENCE_SAVE,
    data: option
  };
}

/* Load view preference */
export function loadView(option) {
  return {
    type: REPORT_VIEW_PREFERENCE_LOAD,
    data: option
  };
}

/* Select a report */
export function selectReport(report) {
  return {
    type: SELECT_REPORT,
    data: {
      report: report
    }
  };
}

/* Deselect a report */
export function deselectReport() {
  return {
    type: SELECT_REPORT,
    data: {
      report: null
    }
  };
}

/* Select a popular report */
export function onSelectPopular(collectionId, reportId, checked) {
  return {
    type: SELECT_POPULAR,
    data: {
      collectionId,
      reportId,
      checked
    }
  };
}

export function deselectPopular() {
  return {
    type: DESELECT_POPULAR
  };
}

/* Deselect all reports */
export function deselectAllReports() {
  return function(dispatch) {
    dispatch({
      type: DESELECT_ALL_REPORT_BULK_EDIT,
      data: {
        report: null
      }
    });
  };
}

/* Select all reports in bulk */
export function selectReportBulkEdit(report) {
  return {
    type: SELECT_REPORT_BULK_EDIT,
    data: {
      report: report
    }
  };
}

/* Deselect all reports in bulk */
export function deselectReportBulkEdit(report) {
  return {
    type: DESELECT_REPORT_BULK_EDIT,
    data: {
      report: report
    }
  };
}

/*
 * START mp-node + elasticsearch and report-writer2 actions for Report Library
 */

/*
 * Get a list of reports based on current tab (favorites or all)
 * Will get report metadata from ElasticSearch based on given filters/sorting
 */
export function getReportList(options) {
  return async (dispatch, getState) => {
    const errorMessage = `Error retrieving ${options.isFavorite ? 'favorite' : 'all'} reports`;
    const replacementTokens = getState()?.session?.replacementTokens;
    let requestId = getState().reports.activeReportQueryRequestId + 1;
    if (requestId >= Number.MAX_SAFE_INTEGER) {
      requestId = 0;
    }

    dispatch({
      type: REPORT_LIBRARY_LIST_INIT,
      requestId: requestId
    });

    try {
      const body = {
        include: options.includeFilter || 'ALL',
        scopeCode: options.scopeFilter || 'global',
        typeCode: options.typeFilter || undefined,
        textFilter: options.textFilter || undefined,
        isFavorite: options.favoriteFilter || false,
        createdBy: options.createdByFilter || undefined,
        groupId: options.groupIdFilter?.toString() || undefined,
        sortField: options.sortField || 'name',
        sortDirection: options.sortDirection || 'ASC',
        limit: options.limit || 1000,
        offset: options.offset || 0
      };

      const response = await apiClient.apiPost('api/report-library/reports/getReportList', body);
      let { success, reports } = response.data;
      if (body.typeCode) {
        const validTypesCodes = getState().reports.reportTypes.map(type => type.code);
        reports.edges = reports.edges.filter(report => validTypesCodes.includes(report.type.code));
      }

      if (!success) {
        throw Error(errorMessage);
      }

      const counts = reports.edges?.reduce((memo, report) => {
        if (!memo.hasOwnProperty(report.type.code)) {
          memo[report.type.code] = 1;
        } else {
          memo[report.type.code] += 1;
        }
        return memo;
      }, {});

      const reportTypes = getState().reports.reportTypes?.map(reportType => {
        return {
          ...reportType,
          count: counts[reportType.code] || 0
        };
      });

      dispatch({
        type: REPORT_LIBRARY_LIST_SUCCESS,
        requestId: requestId,
        data: { reports: replaceReportsNames(reports, replacementTokens), reportTypes }
      });
    } catch (error) {
      console.error(error);
      addAlert(errorMessage, 'danger');

      dispatch({
        type: REPORT_LIBRARY_LIST_FAIL,
        requestId: requestId,
        error: error.message
      });
    }
  };
}

/*
 * Get a list of collections and reports for the popular reports tab
 */
export function getCollections(options) {
  return async (dispatch, getState) => {
    const errorMessage = `Error retrieving popular reports`;
    const replacementTokens = getState()?.session?.replacementTokens;

    dispatch({
      type: REPORT_LIBRARY_COLLECTIONS_INIT
    });

    try {
      const response = await apiClient.apiGet('api/report-library/collections/getCollections', {
        params: {
          textFilter: options.textFilter || undefined,
          onlyStandard: options.onlyStandard || false,
          onlyCustom: options.onlyCustom || false
        }
      });
      const { success, collections, reports } = response.data;

      if (!success) {
        throw Error(errorMessage);
      }

      dispatch({
        type: REPORT_LIBRARY_LIST_SUCCESS,
        isPopular: true,
        data: { reports: replaceReportsNames(reports, replacementTokens) }
      });
      dispatch({
        type: REPORT_LIBRARY_COLLECTIONS_SUCCESS,
        data: { collections }
      });
    } catch (error) {
      console.error(error);
      addAlert(errorMessage, 'danger');

      dispatch({
        type: REPORT_LIBRARY_COLLECTIONS_FAIL,
        error: error.message
      });
    }
  };
}

/*
 * Create a new collection
 */
export function createCollection(options) {
  return async () => {
    const errorMessage = `Error creating new collection`;
    const duplicateMessage = 'Duplicate collection name found';
    const successMessage = 'Successfully created collection';

    try {
      const response = await apiClient.apiPost('api/report-library/collections/createCollection', {
        name: options.name || undefined,
        description: options.description || undefined,
        reportIds: options.reportIds || []
      });
      const { success, status, collection } = response.data;

      if (!success) {
        throw Error(errorMessage);
      }

      if (status === 409) {
        addAlert(duplicateMessage, 'warning');

        return 409;
      }

      addAlert(successMessage, 'success');

      return collection;
    } catch (error) {
      console.error(error);
      addAlert(errorMessage, 'danger');

      return 500;
    }
  };
}

/*
 * Add reports to a collection
 */
export function addReportsToCollections(options) {
  return async () => {
    const errorMessage = `Error adding reports to collections`;
    const duplicateMessage = 'Duplicate reports found in collections';
    const successMessage = 'Successfully added reports to collections';

    try {
      const { collectionIds, reportIds } = options;

      for (let i = 0; i < collectionIds.length; i++) {
        const collectionId = collectionIds[i];
        const response = await apiClient.apiPost('api/report-library/collections/addReports', {
          reportIds,
          collectionId
        });
        const { success, status } = response.data;

        if (!success) {
          throw Error(errorMessage);
        }

        if (status === 409) {
          addAlert(duplicateMessage, 'warning');

          return 409;
        }
      }

      addAlert(successMessage, 'success');

      return 200;
    } catch (error) {
      console.error(error);
      addAlert(errorMessage, 'danger');

      return 500;
    }
  };
}

/*
 * Remove reports from collections
 */
export function removeReportsFromCollections(options) {
  return async dispatch => {
    const errorMessage = `Error removing reports from collections`;
    const successMessage = 'Successfully removed reports from collections';

    try {
      const { collections } = options;
      // group reports by collections
      const grouped = [];
      collections.forEach(col => {
        const { collectionId, reportId } = col;
        const foundIndex = grouped.findIndex(g => g.collectionId === collectionId);
        if (foundIndex > -1) {
          grouped[foundIndex].reportIds.push(reportId);
        } else {
          grouped.push({
            collectionId,
            reportIds: [reportId]
          });
        }
      });

      for (let i = 0; i < grouped.length; i++) {
        const collection = grouped[i];
        const { collectionId, reportIds } = collection;
        const response = await apiClient.apiPost('api/report-library/collections/removeReports', {
          reportIds,
          collectionId
        });
        const { success } = response.data;

        if (!success) {
          throw Error(errorMessage);
        }
      }

      addAlert(successMessage, 'success');
      dispatch({
        type: DESELECT_POPULAR
      });

      return 200;
    } catch (error) {
      console.error(error);
      addAlert(errorMessage, 'danger');

      return 500;
    }
  };
}

/*
 * Delete a collection
 */
export function deleteCollection(options) {
  return async dispatch => {
    const errorMessage = `Error deleting collection`;
    const successMessage = 'Successfully deleted collection';

    try {
      const { collectionId } = options;
      const response = await apiClient.apiPost('api/report-library/collections/deleteCollection', {
        collectionId
      });
      const { success } = response.data;

      if (!success) {
        throw Error(errorMessage);
      }

      addAlert(successMessage, 'success');

      return 200;
    } catch (error) {
      console.error(error);
      addAlert(errorMessage, 'danger');

      return 500;
    }
  };
}

/*
 * Get a list of report types (i.e. categories from rw2)
 * Will call the rw2 library directly get types from postgres
 */
export function getReportTypeList() {
  return async (dispatch, getState) => {
    const errorMessage = 'Erroring getting report types';
    const replacementTokens = getState()?.session?.replacementTokens;
    dispatch({
      type: REPORT_TYPE_INIT
    });

    try {
      const body = {
        activeOnly: true,
        simpleList: true,
        analyticsMode: false
      };
      const response = await apiClient.apiPost('api/report-writer/category/list', body);
      const { status, data } = response;

      if (status !== 200) {
        throw Error(errorMessage);
      }

      let types = data.map(type => {
        return {
          id: type.category_pk,
          code: type.category_code,
          name: type.category_title
        };
      });

      types = replaceTypeNames(types, replacementTokens);

      dispatch({
        type: REPORT_TYPE_SUCCESS,
        data: types
      });
    } catch (error) {
      console.error(error);
      addAlert(errorMessage, 'danger');

      dispatch({
        type: REPORT_TYPE_FAIL,
        error: error.message
      });
    }
  };
}

/*
 * Get thumbnail and url for the given visualization (tableau) report
 * Will call the getAdvancedRCommand function in rw2
 */
export function getReportVisualization(reportId) {
  return async (dispatch, getState) => {
    try {
      const reports = getState()?.reports?.reports?.edges || [];
      const report = reports.find(r => Number(r.id) === Number(reportId));

      if (!report) {
        throw Error(`Error retrieving report visualization for ${reportId}`);
      }

      if (report.isVisualization && report.workbook?.id && report.sheet?.id) {
        if (!report.thumbnail && !report.url && !report.thumbnailLoaded) {
          dispatch({
            type: REPORT_VISUALIZATION_INIT,
            data: {
              id: Number(report.id)
            }
          });

          apiClient
            .apiPost('api/report-library/reports/getVisualizationData', {
              id: report.workbook?.id
            })
            .then(response => {
              if (response.data.success && response?.data?.data) {
                const serviceData = JSON.parse(response.data.data);

                if (serviceData && report.sheet?.id) {
                  const { thumbnail, url } = getVisualData(serviceData, report.sheet.id);

                  dispatch({
                    type: REPORT_VISUALIZATION_SUCCESS,
                    data: {
                      id: Number(report.id),
                      thumbnail,
                      url
                    }
                  });
                }
              }
            });
        }
      }
    } catch (error) {
      console.warn(error);

      dispatch({
        type: REPORT_VISUALIZATION_FAIL,
        error: error.message,
        data: {
          id: Number(reportId)
        }
      });
    }
  };
}

/*
 * Set report as favorite
 * Will call rw2 library and modify favorite attribute in postgres
 */
export function setReportFavorite(reportId, isFavorite) {
  return async (dispatch, getState) => {
    const errorMessage = 'Error setting favorite, please try again later';
    dispatch({
      type: REPORT_FAVORITE_INIT
    });
    dispatch({
      type: REPORT_FAVORITE_OPTIMISTIC,
      data: {
        id: Number(reportId),
        isFavorite: isFavorite
      }
    });

    try {
      const orgId = Number(getState().session?.organization?.id);
      const userId = Number(getState().session?.user?.id);

      const response = await apiClient.apiPost('api/report-writer/report/setFavorite', {
        favorite: isFavorite,
        org_id: orgId,
        user_id: userId,
        report_pk: Number(reportId)
      });
      const { success } = response.data;

      if (!success) {
        // note: no need for success dispatch, already set in optimistic call above
        throw new Error('Setting favorite failed, please try again');
      }
    } catch (error) {
      console.error(error);
      addAlert(errorMessage, 'danger');

      dispatch({
        type: REPORT_FAVORITE_FAIL,
        error: error.message
      });
    }
  };
}

/*
 * Clone an existing report as a new personal report
 * Will use the clone function from the rw2 library
 */
export function saveReportAs(reportId, name, type) {
  return async dispatch => {
    const errorMessage = 'Error saving report, please try again later';
    const duplicateMessage = 'Duplicate report name';
    const successMessage = 'Successfully saved new report';

    dispatch({
      type: REPORT_CLONE_INIT
    });

    try {
      const response = await apiClient.apiPost('api/report-writer/report/clone', {
        report_pk: Number(reportId),
        template_report_pk: Number(reportId),
        template_scope_code: null,
        new_report_name: name,
        new_report_scope_code: 'personal',
        category_pk: type,
        clone_report: true
      });
      const { success, save_message, report_pk } = response.data;

      if (success) {
        addAlert(successMessage, 'success');

        dispatch({
          type: REPORT_CLONE_SUCCESS,
          data: {
            id: report_pk
          }
        });
      } else if (save_message === 'Report name exists.') {
        addAlert(duplicateMessage, 'warning');

        dispatch({
          type: REPORT_CLONE_FAIL,
          error: duplicateMessage
        });

        return false;
      } else if (save_message) {
        throw Error(`Error saving report: ${save_message}`);
      } else {
        throw Error(errorMessage);
      }

      return true;
    } catch (error) {
      console.error(error);
      addAlert(errorMessage, 'danger');

      dispatch({
        type: REPORT_CLONE_FAIL,
        error: error.message
      });

      return false;
    }
  };
}

/*
 * Delete a given report
 * Will use the delete function from the rw2 library
 */
export function deleteReport(reportId) {
  return async dispatch => {
    const errorMessage = 'Error deleting report, please try again later';
    const successMessage = 'Successfully deleted report';

    dispatch({
      type: REPORT_DELETE_INIT
    });

    try {
      const response = await apiClient.apiPost('api/report-writer/report/deleteReport', {
        report_pk: Number(reportId)
      });
      const { success } = response.data;

      if (!success) {
        throw Error(errorMessage);
      }
      addAlert(successMessage, 'success');

      dispatch({
        type: REPORT_DELETE_SUCCESS,
        data: {
          id: Number(reportId)
        }
      });

      return true;
    } catch (error) {
      console.error(errorMessage);
      addAlert(errorMessage, 'danger');

      dispatch({
        type: REPORT_DELETE_FAIL,
        error: error.message
      });

      return false;
    }
  };
}

/*
 * Get the isEditable attribute for a given report
 * Will get report metadata from the getReport function in the rw2 library
 */
export function getReportEditable(reportId) {
  return async (dispatch, getState) => {
    if (reportId) {
      try {
        const response = await apiClient.apiPost('api/report-library/reports/getReportEditable', {
          id: Number(reportId)
        });
        const { success, isEditable } = response.data;

        if (!success) {
          throw Error('Error getting report editability');
        }

        dispatch({
          type: REPORT_EDITABLE,
          data: {
            id: Number(reportId),
            isEditable
          }
        });
      } catch (error) {
        console.warn(error);

        dispatch({
          type: REPORT_EDITABLE,
          data: {
            id: Number(reportId),
            isEditable: false
          }
        });
      }
    }
  };
}

/*
 * Delete all selected reports
 * Using the deleteReport function from the rw2 library
 */
export function deleteAllSelectedReports(reports) {
  return async dispatch => {
    const errorMessage = 'Error deleting reports, please try again later';

    dispatch({
      type: REPORT_DELETE_ALL_INIT
    });

    try {
      const deleteReportPromises = reports.edges.filter(report => {
        if (report.selectedForEdit) {
          return apiClient.apiPost('api/report-writer/report/deleteReport', {
            report_pk: Number(report.id)
          });
        }
      });
      await Promise.all(deleteReportPromises);

      const successMessage = `Successfully deleted ${deleteReportPromises?.length > 1 ? 'reports' : 'report'}`;
      addAlert(successMessage, 'success');
      dispatch({
        type: REPORT_DELETE_ALL_SUCCESS
      });
    } catch (error) {
      console.error(error);
      addAlert(errorMessage, 'danger');

      dispatch({
        type: REPORT_DELETE_ALL_FAIL,
        error: error.message
      });
    }
  };
}

/* Clear all report filters */
export function clearReportFilters() {
  return {
    type: CLEAR_REPORT_FILTERS
  };
}

/*
 * START report-library util
 */

function replaceReportsNames(reports, replacementTokens) {
  return {
    ...reports,
    edges: reports.edges.map(edge => {
      return replaceReportNames(edge, replacementTokens);
    })
  };
}

function replaceReportNames(report, replacementTokens) {
  return {
    ...report,
    name: replacementTokenTemplate(report.name, replacementTokens),
    type: {
      ...report.type,
      name: replacementTokenTemplate(report.type?.name, replacementTokens)
    }
  };
}

function replaceTypeNames(types, replacementTokens) {
  return types.map(type => {
    return {
      ...type,
      name: replacementTokenTemplate(type.name, replacementTokens)
    };
  });
}

function replacementTokenTemplate(data, replacementTokens) {
  // finds characters that match ${valuehere}
  templateSettings.interpolate = /\${([\s\S]+?)}/g;
  const templatedData = template(JSON.stringify(data));
  const tokens = replacementTokens.reduce((acc, val) => {
    acc[val.token] = val.value;
    return acc;
  }, {});

  try {
    const formattedData = templatedData(tokens);
    data = JSON.parse(formattedData);
  } catch (e) {
    if (e instanceof ReferenceError) {
      log('Unable to format value: ' + e.message);
    }
  }

  return data;
}

function getVisualData(serviceData, sheetId) {
  const meta = 'data:image/png;base64,';
  let sheet = null;
  if (serviceData[sheetId]) {
    sheet = sheetId;
  } else {
    const serviceDataKeys = Object.keys(serviceData);
    if (serviceDataKeys.length > 0) {
      sheet = serviceDataKeys[0];
    }
  }

  if (!sheet) return null;

  return {
    thumbnail: `${meta}${serviceData[sheet]['view-thumbnail']}`,
    url: serviceData[sheet]['view-url']
  };
}
