import { applyCustomFeatureRules } from '../../../ps-components/src/FeatureFlags/FeatureFlagActions';

export const featureCustomRules = function(features, featureName) {
  // TODO: this check is to be completely removed when PSP-17564 is completed.
  if (featureName === 'managerExperience') {
    return { isEnabled: true };
  }

  if (featureName === 'reportLibrary') {
    return {
      // Lab still uses the sencha report list ui not react ui
      isEnabled: false
    };
  }

  if (featureName === 'hrisConnect') {
    if (
      window.app.hostname === 'localhost' ||
      window.app.hostname === 'lab-dev.payscale.com' ||
      window.app.hostname === 'lab-test.payscale.com' ||
      window.app.subsite === 'mptest' ||
      window.app.subsite === 'unittest'
    ) {
      return { isEnabled: true };
    }
    return { isEnabled: false };
  }

  if (featureName === 'surveyFlag' || featureName === 'teamFlag' || featureName === 'jobCollabFlag') {
    if (features && featureName in features && !features[featureName]) {
      return { isEnabled: false };
    } else {
      return { isEnabled: true };
    }
  }

  // configureAddOns is only for demo sites
  if (
    featureName === 'configureAddOns' &&
    !(window.app.subsiteStatus === 'Demo' || window.app.subsiteStatus === 'Internal')
  ) {
    return { isEnabled: false };
  }
};

export const initCustomFeatureRules = function() {
  applyCustomFeatureRules(featureCustomRules);
};
