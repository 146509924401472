import { getJobSettings } from '@payscale/ps-isomorphic/dist/JobRanges/Utils';
import { getMarketReferenceField } from '../../../Utils/Utils';

export default {
  sort: {},
  allowSort: false,
  allowCheckAll: false,
  isAllChecked: false,
  hideCheckBox: true,
  pagination: {
    perPageCount: 10,
    pageNumber: 1,
    searchResultsCount: 0
  },
  expandedMaxColumnCount: 4,
  idProp: 'id',
  columns: []
};
