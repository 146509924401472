import { selectors as employeeSelectors } from '../employees/employeesSearch';
import { selectors as jobSelectors } from '../jobs/jobsSearch';

const DOWNLOAD_LIST = 'DOWNLOAD_LIST';
const DOWNLOAD_LIST_SUCCESS = 'DOWNLOAD_LIST_SUCCESS';
const DOWNLOAD_LIST_FAIL = 'DOWNLOAD_LIST_FAIL';

const OPEN_DOWNLOAD_MODAL = 'OPEN_DOWNLOAD_MODAL';
const CLOSE_DOWNLOAD_MODAL = 'CLOSE_DOWNLOAD_MODAL';

const BULK_DOWNLOAD = 'BULK_DOWNLOAD';
const BULK_DOWNLOAD_SUCCESS = 'BULK_DOWNLOAD_SUCCESS';
const BULK_DOWNLOAD_FAILURE = 'BULK_DOWNLOAD_FAILURE';
const POLL_DOWNLOAD = 'POLL_DOWNLOAD';
const POLL_DOWNLOAD_SUCCESS = 'POLL_DOWNLOAD_SUCCESS';
const POLL_DOWNLOAD_FAILURE = 'POLL_DOWNLOAD_FAILURE';

const DOWNLOAD_BULK_PDF_SUCCESS = 'DOWNLOAD_BULK_PDF_SUCCESS';

const STOP_POLLING = 'STOP_POLLING';

const initialState = {
  loading: false,
  downloadLink: '',
  error: null,
  items: [],
  polling: false,
  printRequestId: null,
  fileUrl: '',
  zipFileReady: false,
  downloadModalOpen: false,
  totalReports: 0,
  bulkDownloadFailed: false,
  currentPage: 0
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case DOWNLOAD_LIST:
      return {
        ...state,
        loading: true,
        downloadLink: ''
      };
    case DOWNLOAD_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        downloadLink: `${window.app.apiPrefix}api/generic-utils/generic-download/downloadGeneric?pretty_name=${action.result.resultFileName}&file_name=${action.result.resultFileName}.${action.result.resultFileType}&download_type=${action.result.resultFileType}`,
        items: action.result
      };
    case DOWNLOAD_LIST_FAIL:
      return {
        ...state,
        loading: false,
        data: null,
        downloadLink: '',
        error: action.error
      };
    case BULK_DOWNLOAD:
      return {
        ...state,
        loading: true,
        downloadLink: '',
        printRequestId: null,
        bulkDownloadFailed: false
      };
    case BULK_DOWNLOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        printRequestId: action.result.printRequestId,
        totalReports: action.result.totalReports,
        bulkDownloadFailed: action.result.success ? false : true
      };
    case BULK_DOWNLOAD_FAILURE:
      return {
        ...state,
        loading: false,
        data: null,
        downloadLink: '',
        printRequestId: null,
        error: action.error,
        bulkDownloadFailed: true
      };
    case POLL_DOWNLOAD:
      return {
        ...state,
        polling: true,
        error: null,
        downloadLink: '',
        zipFileReady: false,
        bulkDownloadFailed: false
      };
    case POLL_DOWNLOAD_SUCCESS:
      let curPage = 0;
      if (action.result.success && action.result.curPage) {
        curPage = action.result.curPage;
      }
      if (action.result.success && action.result.fileUrl) {
        curPage = state.totalReports;
      }

      return {
        ...state,
        polling: action.result.fileUrl ? false : true,
        fileUrl: action.result.fileUrl ? action.result.fileUrl : '',
        zipFileReady: action.result.fileUrl ? true : false,
        bulkDownloadFailed: action.result.success ? false : true,
        currentPage: curPage
      };
    case POLL_DOWNLOAD_FAILURE:
      return {
        ...state,
        polling: false,
        error: action.error,
        zipFileReady: false,
        bulkDownloadFailed: true,
        currentPage: 0
      };
    case OPEN_DOWNLOAD_MODAL:
      return {
        ...state,
        downloadModalOpen: true
      };
    case CLOSE_DOWNLOAD_MODAL:
      return {
        ...state,
        downloadModalOpen: false,
        zipFileReady: false,
        bulkDownloadFailed: false,
        currentPage: 0
      };
    case STOP_POLLING:
      return {
        ...state,
        polling: false
      };
    case DOWNLOAD_BULK_PDF_SUCCESS:
      return {
        ...state,
        polling: false,
        error: null,
        currentPage: 0
      };
    default:
      return { ...state };
  }
}

export function getDownloadList(options) {
  return {
    types: [DOWNLOAD_LIST, DOWNLOAD_LIST_SUCCESS, DOWNLOAD_LIST_FAIL],
    promise: client => client.get('/api/hris/download/downloadList', { params: options }),
    query: options
  };
}

export function bulkDownloadEmployees() {
  return (dispatch, getState) => {
    const searchParams = employeeSelectors.getSearchParameters(getState());
    const params = { ...searchParams, waitForSelector: '.employee-pdf' };
    dispatch(bulkDownload(params));
  };
}

export function bulkDownloadJobs(includedIds, excludedIds) {
  return (dispatch, getState) => {
    const searchParams = jobSelectors.getSearchParameters(getState());
    const params = { ...searchParams, includedIds, excludedIds, waitForSelector: '.jobDetails__rangeChart--overflow' };
    dispatch(bulkDownload(params));
  };
}

function bulkDownload(params) {
  return (dispatch, getState) => {
    // Only allow one bulk download at a time
    const state = getState().downloadList;
    if (state.polling) {
      return;
    }

    dispatch(openDownloadModal());
    const promise = dispatch({
      types: [BULK_DOWNLOAD, BULK_DOWNLOAD_SUCCESS, BULK_DOWNLOAD_FAILURE],
      promise: client => client.get('/api/util/report-print/bulkPrintAsync', { params: params })
    });

    promise
      .then(res => {
        if (res.success) {
          dispatch(pollDownload(params));
        }
      })
      .catch(err => {
        console.error('Error starting bulk print async', err);
      });
  };
}

function openDownloadModal() {
  return (dispatch, getState) => {
    dispatch({
      type: 'OPEN_DOWNLOAD_MODAL'
    });
  };
}

export function closeDownloadModal() {
  return (dispatch, getState) => {
    dispatch({
      type: 'CLOSE_DOWNLOAD_MODAL'
    });
  };
}

function pollDownload(searchParams) {
  return (dispatch, getState) => {
    const state = getState().downloadList;
    const promise = dispatch({
      types: [POLL_DOWNLOAD, POLL_DOWNLOAD_SUCCESS, POLL_DOWNLOAD_FAILURE],
      promise: client =>
        client.get('/api/util/report-print/pollPrintDownload', {
          params: { printRequestId: state.printRequestId, ...searchParams }
        })
    });

    promise
      .then(res => {
        if (res.success && !res.fileUrl && state.downloadModalOpen) {
          setTimeout(() => dispatch(pollDownload(searchParams)), res.retryAfterMilliseconds || 1000);
        } else {
          dispatch({
            type: 'STOP_POLLING'
          });
        }
      })
      .catch(err => {
        console.error('Error polling print download', err);
      });
  };
}

export function downloadBulkPdf() {
  return (dispatch, getState) => {
    const { downloadList, app } = getState();
    if (downloadList.fileUrl) {
      // Typically, the ListPanel would connect to this state and do this when downloadLink is set
      // However, a user may have navigated away from the ListPanel, so let's do this manually
      window.location = `${downloadList.fileUrl}&csrf_token=${app.csrf_token}&csrf_timestamp=${app.csrf_timestamp}`;
      dispatch({ type: DOWNLOAD_BULK_PDF_SUCCESS });
    }

    dispatch(closeDownloadModal());
  };
}
