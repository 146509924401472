import { getAuditHistory } from '../../../../../panther-react/src/reducers/modules/jobs/jobActions';
import { loadDefaultDataCuts } from '../../../../../panther-react/src/reducers/modules/surveys/surveyActions';
import apiClient from '../../../lib/apiClient';
import { retrieveDynamicAnswers } from './addJob';
import { JOB_DETAILS_SINGLE_START_LOAD, isActionForCurrentJob } from './jobDetails';

const SURVEY_SEARCH = 'SURVEY_SEARCH';
const SURVEY_SEARCH_SUCCESS = 'SURVEY_SEARCH_SUCCESS';
const SURVEY_SEARCH_FAILURE = 'SURVEY_SEARCH_FAILURE';
const FETCH_SURVEYS = 'FETCH_SURVEYS';
const FETCH_SURVEYS_SUCCESS = 'FETCH_SURVEYS_SUCCESS';
const FETCH_SURVEYS_FAILURE = 'FETCH_SURVEYS_FAILURE';
const UPDATE_SURVEY_DATA_CUT = 'UPDATE_SURVEY_DATA_CUT';
const UPDATE_SURVEY_DATA_CUT_SUCCESS = 'UPDATE_SURVEY_DATA_CUT_SUCESS';
const UPDATE_SURVEY_DATA_CUT_FAILURE = 'UPDATE_SURVEY_DATA_CUT_FAILURE';
const RESET_SCROLL = 'RESET_SCROLL';
const REMOVE_MATCH = 'REMOVE_MATCH';
const REMOVE_MATCH_SUCCESS = 'REMOVE_MATCH_SUCCESS';
const REMOVE_MATCH_FAILURE = 'REMOVE_MATCH_FAILURE';
const ADD_MATCH = 'ADD_MATCH';
const ADD_MATCH_SUCCESS = 'ADD_MATCH_SUCCESS';
const ADD_MATCH_FAILURE = 'ADD_MATCH_FAILURE';
const SHOW_GET_APPROVAL_MODAL = 'SHOW_GET_APPROVAL_MODAL';
const CLOSE_GET_APPROVAL_MODAL = 'CLOSE_GET_APPROVAL_MODAL';
const MORE_SURVEY_RESULTS = 'MORE_SURVEY_RESULTS';
const MORE_SURVEY_RESULTS_SUCCESS = 'MORE_SURVEY_RESULTS_SUCCESS';
const MORE_SURVEY_RESULTS_FAIL = 'MORE_SURVEY_RESULTS_FAIL';
const SURVEY_CUT_SELECT = 'SURVEY_CUT_SELECT';
const UPDATE_ADD_JOB_DETAILS = 'UPDATE_ADD_JOB_DETAILS';
const GET_MARKET_SALARY_SUCCESS = 'GET_MARKET_SALARY_SUCCESS';
const EDIT_SURVEY_MODE = 'EDIT_SURVEY_MODE';
const UPDATED_DATA_CUTS = 'UPDATED_DATA_CUTS';
const UPDATED_DATA_CUTS_SUCCESS = 'UPDATED_DATA_CUTS_SUCCESS';
const UPDATED_DATA_CUTS_FAIL = 'UPDATED_DATA_CUTS_FAIL';
const CLEAR_SURVEY_SEARCH_RESULT = 'CLEAR_SURVEY_SEARCH_RESULT';
const LOAD_DEFAULT_DATA_CUTS = 'LOAD_DEFAULT_DATA_CUTS';
const LOAD_DEFAULT_DATA_CUTS_SUCCESS = 'LOAD_DEFAULT_DATA_CUTS_SUCCESS';
const LOAD_DEFAULT_DATA_CUTS_FAIL = 'LOAD_DEFAULT_DATA_CUTS_FAIL';
const SURVEY_SEARCH_DEFAULT_CUTS = 'SURVEY_SEARCH_DEFAULT_CUTS';
const SURVEY_SEARCH_DEFAULT_CUTS_SUCCESS = 'SURVEY_SEARCH_DEFAULT_CUTS_SUCCESS';
const SURVEY_SEARCH_DEFAULT_CUTS_FAIL = 'SURVEY_SEARCH_DEFAULT_CUTS_FAIL';
const SUGGESTED_SURVEY_CUT_SELECT = 'SUGGESTED_SURVEY_CUT_SELECT';
const SUGGESTED_SURVEY_CUT_SELECT_SUCCESS = 'SUGGESTED_SURVEY_CUT_SELECT_SUCCESS';
const SUGGESTED_SURVEY_CUT_SELECT_FAIL = 'SUGGESTED_SURVEY_CUT_SELECT_FAIL';
const PROPOSE_ADJ_CHANGE = 'PROPOSE_ADJ_CHANGE';
const CLEAR_ROW_EDIT_STATE = 'CLEAR_ROW_EDIT_STATE';
const JOB_RANGE_CHART_LOAD = 'JOB_RANGE_CHART_LOAD';
const JOB_RANGE_CHART_SUCCESS = 'JOB_RANGE_CHART_SUCCESS';
const JOB_RANGE_CHART_FAIL = 'JOB_RANGE_CHART_FAIL';
const ASSIGN_COMP_SET_SUCCESS = 'ASSIGN_COMP_SET_SUCCESS';
const ASSIGN_COMP_SET_FAIL = 'ASSIGN_COMP_SET_FAIL';
const ASSIGN_PRICE_LOCATION_SUCCESS = 'ASSIGN_PRICE_LOCATION_SUCCESS';
const ASSIGN_PRICE_LOCATION_FAIL = 'ASSIGN_PRICE_LOCATION_FAIL';
const GET_SURVEY_JOBS = 'GET_SURVEY_JOBS';
const GET_SURVEY_JOBS_SUCCESS = 'GET_SURVEY_JOBS_SUCCESS';
const GET_SURVEY_JOBS_FAIL = 'GET_SURVEY_JOBS_FAIL';
const SHOW_EDIT_SURVEYS = 'SHOW_EDIT_SURVEYS';
const SHOW_EDIT_SURVEYS_SUCCESS = 'SHOW_EDIT_SURVEYS_SUCCESS';
const SHOW_EDIT_SURVEYS_FAILURE = 'SHOW_EDIT_SURVEYS_FAILURE';
const FETCH_COMPSETS_SUCCESS = 'FETCH_COMPSETS_SUCCESS';
const REJECT_SUGGESTION = 'REJECT_SUGGESTION';
const REJECT_SUGGESTION_SUCCESS = 'REJECT_SUGGESTION_SUCCESS';
const REJECT_SUGGESTION_FAIL = 'REJECT_SUGGESTION_FAIL';
const UPDATE_MATCHES = 'UPDATE_MATCHES';
const UPDATE_MATCHES_SUCCESS = 'UPDATE_MATCHES_SUCCESS';
const UPDATE_MATCHES_FAIL = 'UPDATE_MATCHES_FAIL';
const SURVEY_LIST_HOVER = 'SURVEY_LIST_HOVER';
const ASSIGN_COMP_SET = 'ASSIGN_COMP_SET';
const ASSIGN_PRICE_LOCATION = 'ASSIGN_PRICE_LOCATION';
const SAVE_COMP_SET_SUCCESS = 'SAVE_COMP_SET_SUCCESS';
const DELETE_SET_SUCCESS = 'DELETE_SET_SUCCESS';
const SURVEY_CUT_SELECT_SUCCESS = 'SURVEY_CUT_SELECT_SUCCESS';
const SURVEY_CUT_SELECT_FAIL = 'SURVEY_CUT_SELECT_FAIL';
const JOB_DETAILS = 'JOB_DETAILS';
const JOB_DETAILS_SUCCESS = 'JOB_DETAILS_SUCCESS';
const JOB_DETAILS_FAILURE = 'JOB_DETAILS_FAILURE';

const initialState = {
  items: [],
  surveyItems: [],
  loaded: false,
  loading: false,
  searchLoading: false,
  moreSurveyResultsLoading: false,
  showModal: false,
  hoverState: false,
  surveyOffset: 0,
  readOnlyItems: [],
  rejectedSuggestions: [],
  competitiveSetItems: [],
  surveySearchDefaultCuts: [],
  surveySearchDefaultCutsLoading: false,
  loadingJobId: null
};

function findSalaryRange(surveys) {
  const chartBaseSalaryScale = { min: 100000000, max: 0 };
  surveys.map(survey => {
    if (typeof survey.base_salary_25th === 'number' && typeof survey.base_salary_75th === 'number') {
      chartBaseSalaryScale.min =
        chartBaseSalaryScale.min > survey.base_salary_25th ? survey.base_salary_25th : chartBaseSalaryScale.min;
      chartBaseSalaryScale.max =
        chartBaseSalaryScale.max < survey.base_salary_75th ? survey.base_salary_75th : chartBaseSalaryScale.max;
    }
    survey.chartBaseSalaryScale = chartBaseSalaryScale;
    return survey;
  });

  const chartTotalCashScale = { min: 100000000, max: 0 };
  surveys.map(survey => {
    if (typeof survey.total_cash_paid_25th === 'number' && typeof survey.total_cash_paid_75th === 'number') {
      chartTotalCashScale.min =
        chartTotalCashScale.min > survey.total_cash_paid_25th ? survey.total_cash_paid_25th : chartTotalCashScale.min;
      chartTotalCashScale.max =
        chartTotalCashScale.max < survey.total_cash_paid_75th ? survey.total_cash_paid_75th : chartTotalCashScale.max;
    }
    survey.chartTotalCashScale = chartTotalCashScale;
    return survey;
  });

  return surveys;
}

function filterOutSuggestions(items) {
  if (items) {
    return items.filter(item => item.approved_status !== 'Suggested' && item.match_id >= 0);
  } else {
    return [];
  }
}

const makeCutSelection = (newSelectedId, currentSelectedItem, state) => {
  return state.surveyItems.map(item => {
    if (item.scopeId === currentSelectedItem.scopeId) {
      const newSelectedItem = item.cutItems.find(cutItem => cutItem.scopeId === newSelectedId);
      if (newSelectedItem) return { ...item, ...newSelectedItem, cutItems: item.cutItems };
    }
    return item;
  });
};

const applyCutSelectionResult = (resultItem, currentSelectedItem, state) => {
  return state.surveyItems.map(item => {
    if (item.scopeId === resultItem.scopeId) {
      const newCutItems = item.cutItems.map(cutItem => {
        if (cutItem.scopeId === resultItem.scopeId) return { ...resultItem, _id: resultItem.cut_id };
        return cutItem;
      });
      return { ...item, ...resultItem, cutItems: newCutItems, _id: resultItem.cut_id };
    }
    return item;
  });
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_SURVEY_JOBS:
      return {
        ...state,
        loading: true
      };
    case UPDATE_SURVEY_DATA_CUT:
      //  TODO: Should we have a please wait indicator for waiting on requests to update??
      return state;
    case UPDATE_SURVEY_DATA_CUT_SUCCESS:
      let currentSurveyItems = state.items;
      const updatedSurvey = action.result.surveyJobMatches.find(
        survey => survey.pub_job_code === action.params.job_code && survey.survey_code === action.params.survey_code
      );

      currentSurveyItems.forEach(function(item, index) {
        if (
          item.pub_job_code === action.params.job_code &&
          item.survey_code === action.params.survey_code &&
          (!item.approved_status || item.approved_status !== 'Suggested')
        ) {
          currentSurveyItems[index] = updatedSurvey;
        }
      });

      return {
        ...state,
        items: currentSurveyItems,
        readOnlyItems: filterOutSuggestions(currentSurveyItems)
      };
    case UPDATE_SURVEY_DATA_CUT_FAILURE:
      //  TODO: How should error handling occur in this case?
      return state;
    case GET_SURVEY_JOBS_SUCCESS:
      return {
        ...state,
        loaded: true,
        loading: false,
        items: action.result.surveyJobMatches,
        readOnlyItems: filterOutSuggestions(action.result.surveyJobMatches),
        rejectedSuggestions: action.result.rejectedSuggestions
      };
    case GET_SURVEY_JOBS_FAIL:
      return {
        ...state,
        loading: false
      };
    case SHOW_EDIT_SURVEYS:
      return {
        ...state,
        showEditSurveys: false,
        items: [],
        readOnlyItems: []
      };
    case SHOW_EDIT_SURVEYS_SUCCESS:
      return {
        ...state,
        showEditSurveys: true,
        items: action.result.surveyJobMatches,
        readOnlyItems: [...action.result.surveyJobMatches]
      };
    case SHOW_EDIT_SURVEYS_FAILURE:
      return {
        ...state,
        showEditSurveys: false
      };
    case FETCH_SURVEYS:
      return {
        ...state,
        items: [],
        readOnlyItems: [],
        surveyItems: [],
        loading: true,
        loaded: false
      };
    case FETCH_SURVEYS_SUCCESS:
      return {
        ...state,
        items: action.result.surveyJobMatches,
        readOnlyItems: [...action.result.surveyJobMatches],
        loading: false,
        loaded: true
      };
    case FETCH_COMPSETS_SUCCESS:
      if (!isActionForCurrentJob(action.itemId, state.loadingJobId)) {
        return {
          ...state
        };
      }

      return {
        ...state,
        competitiveSetItems: action.result
      };
    case SURVEY_SEARCH:
      return {
        ...state,
        searchLoading: true
      };
    case SURVEY_SEARCH_SUCCESS:
      const flattenedSurveyItems = action.result.data.map(survey => ({
        ...survey,
        ...survey._source,
        _source: null
      }));
      return {
        ...state,
        surveyItems: setSurveysToDefaultCut(findSalaryRange(flattenedSurveyItems)),
        surveyOffset: 100,
        searchLoading: false
      };
    case SURVEY_SEARCH_FAILURE:
      return { ...state, searchLoading: false };
    case MORE_SURVEY_RESULTS:
      return {
        ...state,
        moreSurveyResultsLoading: true
      };
    case MORE_SURVEY_RESULTS_SUCCESS:
      const flattenedSurveys = action.result.data.map(survey => ({
        ...survey,
        ...survey._source,
        _source: null
      }));
      const surveyItems = setSurveysToDefaultCut(findSalaryRange(flattenedSurveys));
      return {
        ...state,
        surveyItems: state.surveyItems.concat(surveyItems),
        surveyOffset: state.surveyOffset + 100,
        moreSurveyResultsLoading: false
      };
    case REMOVE_MATCH:
      return {
        ...state,
        items: state.items.filter(match => match.match_id !== action.matchId),
        loading: true,
        showModal: false
      };
    case REMOVE_MATCH_SUCCESS:
      let matchListRemove = action.result.surveyJobMatches.map(val => {
        const item = state.items.find(match => match.match_id === val.match_id);
        if (item) {
          return item;
        } else {
          return val;
        }
      });
      return {
        ...state,
        items: action.result.success ? matchListRemove : state.items,
        readOnlyItems: filterOutSuggestions(action.result.surveyJobMatches),
        rejectedSuggestions: action.result.rejectedSuggestions,
        loading: false
      };
    case ADD_MATCH:
      return {
        ...state,
        loading: true
      };
    case ADD_MATCH_SUCCESS:
      let matchListAdd = action.result.surveyJobMatches.map(val => {
        const item = state.items.find(match => match.match_id === val.match_id);
        if (item) {
          return item;
        } else {
          return val;
        }
      });
      return {
        ...state,
        items: action.result.success ? matchListAdd : state.items,
        readOnlyItems: filterOutSuggestions(action.result.surveyJobMatches),
        rejectedSuggestions: action.result.rejectedSuggestions,
        loading: false
      };
    case REJECT_SUGGESTION:
      return {
        ...state,
        loading: true
      };
    case REJECT_SUGGESTION_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.result.success ? action.result.surveyJobMatches : state.items,
        rejectedSuggestions: action.result.rejectedSuggestions
      };
    case SHOW_GET_APPROVAL_MODAL:
      return {
        ...state,
        showModal: true,
        modalData: { row: { ...action.row }, jobId: action.jobId }
      };
    case CLOSE_GET_APPROVAL_MODAL:
      return {
        ...state,
        showModal: false
      };
    case UPDATE_MATCHES:
      return {
        ...state,
        loading: true
      };
    case UPDATE_MATCHES_SUCCESS:
      const { result } = action;
      return {
        ...state,
        items: result.success ? result.surveyJobMatches : state.items,
        readOnlyItems: result.success ? filterOutSuggestions(action.result.surveyJobMatches) : state.items,
        loading: false
      };
    case PROPOSE_ADJ_CHANGE:
      return {
        ...state,
        items: state.items.map(row => {
          if (row.match_id === action.matchId.match_id || row.match_id === action.matchId) {
            return { ...row, ['proposed' + action.name]: action.value };
          }
          return row;
        })
      };
    case EDIT_SURVEY_MODE:
      return {
        ...state,
        items: state.items.map(row => {
          if (row.match_id === action.row.match_id) {
            row.surveyEditMode = true;
            return row;
          }
          return row;
        })
      };
    case CLEAR_ROW_EDIT_STATE:
      return {
        ...state,
        items: state.items.map(row => {
          if (row.match_id === action.row.match_id) {
            row.surveyEditMode = false;
            return {
              ...row,
              proposedAdj: undefined,
              proposedGeo: undefined,
              proposedWgt: undefined
            };
          }
          return row;
        })
      };
    case SURVEY_LIST_HOVER:
      return {
        ...state,
        hoverState: action.on
      };
    case ASSIGN_COMP_SET:
      return {
        ...state,
        loading: true
      };
    case ASSIGN_COMP_SET_SUCCESS:
      const changeCompSetAssignment = item => {
        const crowdSourcedSurveyCode = `PAY-${window.app.subsite.toUpperCase()}`;
        if (item.survey_code.includes(crowdSourcedSurveyCode))
          return { ...item, competitive_set_pk: action.competitive_set_pk };
        return { ...item };
      };
      return {
        ...state,
        items: state.items.map(changeCompSetAssignment),
        readOnlyItems: state.readOnlyItems.map(changeCompSetAssignment),
        loading: false
      };
    case ASSIGN_PRICE_LOCATION:
      return {
        ...state,
        loading: true
      };
    case ASSIGN_PRICE_LOCATION_SUCCESS:
      const changeLocationAssignment = item => {
        const crowdSourcedSurveyCode = `PAY-${window.app.subsite.toUpperCase()}`;
        if (item.survey_code.includes(crowdSourcedSurveyCode))
          return { ...item, surveyPriceLocation: action.surveyPriceLocation };
        return { ...item };
      };
      return {
        ...state,
        items: state.items.map(changeLocationAssignment),
        readOnlyItems: state.readOnlyItems.map(changeLocationAssignment)
      };
    case SAVE_COMP_SET_SUCCESS:
      if (action.result && action.result.success === false) return state;
      const exists = state.competitiveSetItems.find(
        item => item.competitive_set_pk === action.result.competitive_set_pk
      );
      if (!exists) {
        return {
          ...state,
          competitiveSetItems: [
            ...state.competitiveSetItems,
            { ...action.setData, competitive_set_pk: action.result.competitive_set_pk }
          ]
        };
      }
      return state;
    case DELETE_SET_SUCCESS:
      if (action.result && action.result.success === false) return state;
      return {
        ...state,
        competitiveSetItems: state.competitiveSetItems.filter(
          compSet => compSet.competitive_set_pk !== action.setData.competitive_set_pk
        )
      };
    case SURVEY_CUT_SELECT:
      const { newSelectedScopeId, currentSelectedItem } = action;
      return {
        ...state,
        surveyItems: makeCutSelection(newSelectedScopeId, currentSelectedItem, state)
      };
    case SURVEY_CUT_SELECT_SUCCESS:
      action.result.scopeId = action.newSelectedScopeId;
      return {
        ...state,
        surveyItems: applyCutSelectionResult(action.result, action.currentSelectedItem, state)
      };
    case UPDATED_DATA_CUTS_SUCCESS:
      const currentItems = state.items;

      const updatedItems = currentItems.map(item => {
        if (item.survey_code === action.result.survey_code && item.pub_job_code === action.result.job_code) {
          item.surveyJobDataCuts = action.result.surveyJobDataCuts;
          item.surveyJobEmptyDataCuts = action.result.surveyJobEmptyDataCuts;
        }
        return item;
      });
      return {
        ...state,
        items: updatedItems
      };
    case LOAD_DEFAULT_DATA_CUTS_SUCCESS:
      const currentJobSurveys = state.items;
      const updatedJobSurveys = currentJobSurveys.map(item => {
        if (item.source_map_id === action.itemId) {
          item.cutItems = action.result;
        }
        return item;
      });
      return {
        ...state,
        items: updatedJobSurveys
      };
    case SURVEY_SEARCH_DEFAULT_CUTS:
      return {
        ...state,
        surveySearchDefaultCutsLoading: true
      };
    case SURVEY_SEARCH_DEFAULT_CUTS_SUCCESS:
      return {
        ...state,
        surveySearchDefaultCuts: action.result,
        surveySearchDefaultCutsLoading: false
      };
    case SURVEY_SEARCH_DEFAULT_CUTS_FAIL:
      return {
        ...state,
        surveySearchDefaultCutsLoading: false
      };
    case CLEAR_SURVEY_SEARCH_RESULT:
      return {
        ...state,
        surveyItems: []
      };
    case SUGGESTED_SURVEY_CUT_SELECT_SUCCESS:
      let currentMatchedSurveys = state.items;

      currentMatchedSurveys.forEach(function(item, index) {
        if (item.pub_job_code === action.params.job_code && item.survey_code === action.params.survey_code) {
          currentMatchedSurveys[index] = { ...item, ...action.result };
        }
      });

      return {
        ...state
      };
    case JOB_DETAILS_SINGLE_START_LOAD:
      return {
        ...state,
        loadingJobId: action.id
      };
    default:
      return state;
  }
}

const surveyListActions = {
  surveySearch: function fetchSurvey(dispatch, getState, ...rest) {
    const [query, jobId] = rest;
    const params = { es_query: query, pageName: 'job surveys', job_id: jobId };
    /*dispatch({
      types: [SURVEY_SEARCH, SURVEY_SEARCH_SUCCESS, SURVEY_SEARCH_FAILURE],
      promise: (client) => client.get('/api/elasticsearch/search-surveys/queryStringSurveys', {params})
    });*/
    dispatch({
      types: [SURVEY_SEARCH, SURVEY_SEARCH_SUCCESS, SURVEY_SEARCH_FAILURE],
      promise: client =>
        client.post('/api/elasticsearch/search-surveys/queryStringSurveys', {
          params: { ...params, withSourceMapId: true, exclude_crowdsource_surveys: true }
        })
    });
  },

  moreSurveyResults: function fetchSurvey(dispatch, getState, ...rest) {
    const [query, jobId, offset] = rest;
    dispatch({
      types: [MORE_SURVEY_RESULTS, MORE_SURVEY_RESULTS_SUCCESS, MORE_SURVEY_RESULTS_FAIL],
      promise: client =>
        client.get('/api/elasticsearch/search-surveys/queryStringSurveys', {
          params: {
            es_query: query,
            pageName: 'job surveys',
            job_id: jobId,
            offset: offset,
            exclude_crowdsource_surveys: true
          }
        })
    });
  },

  resetScroll: function resetScroll(dispatch) {
    dispatch({ type: RESET_SCROLL });
  },

  approveJobMatch: function addJobMatch(dispatch, getState, row, jobId) {
    const params = {
      org_job_id: jobId,
      cut_id: [row._id || row.cut_id]
    };

    if (row.approved_status === 'Suggested') params.suggestedMatch = true;
    const ajax = dispatch({
      types: [ADD_MATCH, ADD_MATCH_SUCCESS, ADD_MATCH_FAILURE],
      promise: client => client.get('/api/market-pricing/job-matches/addMatch', { params: params })
    });
    ajax
      .then((...args) => {
        dispatch(this.loadDetailsChart(jobId));
        dispatch(getAuditHistory(jobId));
      })
      .catch(err => {
        console.error('Error adding match', err);
      });
  },

  updateJobMatchDataCut: function updateJobMatch(dispatch, getState, selection, e1, e2, items) {
    //  find the selected data cut object based off the job scope selection element text
    const dataCut = items.find(i => i.job_scope === selection);
    const { jobSurveyList, jobDetails } = getState();
    if (!jobSurveyList || !jobSurveyList.items || !jobDetails || !jobDetails.details) {
      return;
    }

    const org_job_id = jobDetails.details.org_job_id;

    //  find the company sourced match id
    let matchId;
    for (let i = 0; i < jobSurveyList.items.length; i++) {
      const survey = jobSurveyList.items[i];
      if (survey.pub_survey_publisher === 'PayScale Company Sourced') {
        matchId = survey.match_id;
        break;
      }
    }

    const params = {
      cut_id: dataCut.cut_id,
      job_scope: dataCut.job_scope,
      match_id: matchId,
      job_code: dataCut.job_code,
      survey_code: dataCut.survey_code,
      org_job_id
    };
    dispatch({
      types: [UPDATE_SURVEY_DATA_CUT, UPDATE_SURVEY_DATA_CUT_SUCCESS, UPDATE_SURVEY_DATA_CUT_FAILURE],
      promise: client => client.post('/api/market-pricing/job-matches/updateMatchDataCut', { params: params }),
      params
    });
  },

  removeJobMatch: function removeJobMatch(dispatch, getState, row, jobId) {
    const { jobSurveyList } = getState();

    if (!jobSurveyList.showModal) {
      dispatch({ type: SHOW_GET_APPROVAL_MODAL, row, jobId });
      return;
    }

    const params = {
      org_job_id: jobId,
      remove_match: [row.match_id]
    };
    const ajax = dispatch({
      types: [REMOVE_MATCH, REMOVE_MATCH_SUCCESS, REMOVE_MATCH_FAILURE],
      promise: client => client.get('/api/market-pricing/job-matches/removeMatch', { params: params }),
      matchId: row.match_id
    });
    ajax
      .then((...args) => {
        dispatch(this.loadDetailsChart(jobId));
        dispatch(getAuditHistory(jobId));
      })
      .catch(err => {
        console.error('Error removing match', err);
      });
  },

  rejectSuggestion: function rejectMatch(dispatch, getState, row, jobId) {
    const params = {
      org_job_id: jobId,
      cutId: row.cut_id
    };
    dispatch({
      types: [REJECT_SUGGESTION, REJECT_SUGGESTION_SUCCESS, REJECT_SUGGESTION_FAIL],
      promise: client => client.get('/api/market-pricing/job-matches/rejectSuggestion', { params }),
      row
    });
  },

  updateMatch: function updateMatch(dispatch, getState, row, jobId) {
    let { proposedAdj: adj, proposedGeo: geo, proposedWgt: wgt, match_id: matchId } = row;
    if ('proposedAdj' in row && !adj) {
      adj = 0;
    }
    if ('proposedGeo' in row && !geo) {
      geo = 0;
    }
    if ('proposedWgt' in row && !wgt) {
      wgt = 0;
    }
    const matches = JSON.stringify([{ adj, geo, wgt, matchId }]);
    const options = { params: { matches, org_job_id: jobId } };
    dispatch({
      types: [UPDATE_MATCHES, UPDATE_MATCHES_SUCCESS, UPDATE_MATCHES_FAIL],
      promise: client => client.get('/api/market-pricing/job-matches/updateMatches', options),
      row
    })
      .then(() => {
        dispatch(this.loadDetailsChart(jobId));
      })
      .catch(err => {
        console.error('Error updating matches', err);
      });
  },

  adjInputChange: function adjInputChange(dispatch, getState, name, matchId, event) {
    const value = event.target.value ? Math.abs(event.target.value) : event.target.value;
    dispatch({
      type: PROPOSE_ADJ_CHANGE,
      name,
      value: value,
      matchId
    });
  },

  togglePayScaleMatch: function excludePayScaleMatch(dispatch, getState, row, jobId, shouldInclude) {
    this.adjInputChange(dispatch, getState, 'Wgt', row, {
      target: { value: shouldInclude ? 1 : 0 }
    });
    const newRow = getState().jobSurveyList.items.filter(updatedRow => updatedRow.match_id === row.match_id)[0];
    if (!newRow) {
      return;
    }
    this.updateMatch(dispatch, getState, newRow, jobId);
  },

  clearEditState: function clearEditState(dispatch, getState, row) {
    dispatch({
      type: CLEAR_ROW_EDIT_STATE,
      row
    });
  },

  editSurveyMode: function editSurveyMode(dispatch, getState, row) {
    dispatch({
      type: EDIT_SURVEY_MODE,
      row
    });
  },

  closeModal: function closeModal(dispatch) {
    dispatch({
      type: CLOSE_GET_APPROVAL_MODAL
    });
  },

  loadDetailsChart: function loadDetailsChart(jobId) {
    return async dispatch => {
      const params = { params: { org_job_id: jobId } };
      try {
        dispatch({ type: JOB_RANGE_CHART_LOAD });
        const result = await apiClient.apiGet('/api/hris/job-detail-charts/jobRanges', params);

        dispatch({ type: JOB_RANGE_CHART_SUCCESS, result: result.data });
      } catch (error) {
        dispatch({ type: JOB_RANGE_CHART_FAIL, error: error });
      }
    };
  },

  hover: function hover(dispatch, getState, on) {
    dispatch({
      type: SURVEY_LIST_HOVER,
      on
    });
  },

  assignCompetitiveSet: function assignCompetitiveSet(dispatch, getState, info) {
    try {
      dispatch({ type: ASSIGN_COMP_SET, competitive_set_pk: info.competitive_set_pk });
      apiClient.apiPost('api/hris/competitive-sets/updateCompetitiveSetForJob', info).then(result => {
        dispatch({ type: ASSIGN_COMP_SET_SUCCESS, result: result.data, competitive_set_pk: info.competitive_set_pk });
        if (result.data.success) {
          dispatch(this.showEditSurveys(dispatch, info.org_job_id));
          dispatch(this.loadDetailsChart(info.org_job_id));
          dispatch(loadJobDetails(info.org_job_id)).then(jobDetails => {
            dispatch({
              type: UPDATE_ADD_JOB_DETAILS,
              jobDetails: jobDetails
            });
            dispatch({
              type: GET_MARKET_SALARY_SUCCESS,
              market_base_salary_50th: jobDetails.market_base_salary_50th,
              result: { market_base_salary_50th: jobDetails.market_base_salary_50th },
              itemId: info.org_job_id
            });
            if (jobDetails?.payscale_job_info?.Reports) {
              dispatch(retrieveDynamicAnswers(jobDetails.payscale_job_info.Reports));
            }
          });
        }
      });
    } catch (error) {
      dispatch({ type: ASSIGN_COMP_SET_FAIL, error: error, competitive_set_pk: info.competitive_set_pk });
    }
  },

  surveyPriceLocation: function surveyPriceLocation(dispatch, getState, info) {
    const options = { data: { ...info, yieldSaveReport: true } };

    dispatch({
      types: [ASSIGN_PRICE_LOCATION, ASSIGN_PRICE_LOCATION_SUCCESS, ASSIGN_PRICE_LOCATION_FAIL],
      promise: client => client.post('api/hris/survey-location/updateSurveyPriceLocationForJob', options),
      surveyPriceLocation: info.surveyPriceLocation
    }).then(data => {
      if (data.success) {
        dispatch(this.showEditSurveys(dispatch, info.org_job_id));
        dispatch(this.loadDetailsChart(info.org_job_id));
        dispatch(loadJobDetails(info.org_job_id)).then(jobDetails => {
          dispatch({
            type: UPDATE_ADD_JOB_DETAILS,
            jobDetails: jobDetails
          });
          dispatch({
            type: GET_MARKET_SALARY_SUCCESS,
            market_base_salary_50th: jobDetails.market_base_salary_50th,
            result: { market_base_salary_50th: jobDetails.market_base_salary_50th },
            itemId: info.org_job_id
          });
        });
      }
    });
  },

  showEditSurveys: function showEditSurveys(dispatch, jobId) {
    return async dispatch => {
      try {
        dispatch({ type: GET_SURVEY_JOBS });
        const result = await apiClient.apiGet('/api/market-pricing/job-matches/getSurveyJobs', {
          params: {
            org_job_id: jobId,
            showSuggestions: true
          }
        });
        dispatch({ type: GET_SURVEY_JOBS_SUCCESS, result: result.data });
      } catch (error) {
        dispatch({ type: GET_SURVEY_JOBS_FAIL, error: error });
      }
    };
  },

  getSurveyJobs: function getSurveyJobs(dispatch, getState, jobId) {
    dispatch(this.showEditSurveys(dispatch, jobId));
  },

  onCutSelect: function onCutSelect(dispatch, getState, newSelectedScopeId, event, currentSelectedItem) {
    const newSelectedItem = getNewSelectedCutItem(newSelectedScopeId, currentSelectedItem, getState().jobSurveyList);
    dispatch({
      types: [SURVEY_CUT_SELECT, SURVEY_CUT_SELECT_SUCCESS, SURVEY_CUT_SELECT_FAIL],
      promise: client =>
        client.post('/api/elasticsearch/search-surveys/getSingleDataCutDetails', {
          data: newSelectedItem
        }),
      newSelectedScopeId,
      currentSelectedItem
    });
  }
};

export function loadJobDetails(jobId) {
  const params = { params: { org_job_id: jobId } };
  const itemId = jobId;
  const skipLoad = true;

  return async dispatch => {
    try {
      dispatch({ type: JOB_DETAILS, itemId, skipLoad });
      const result = await apiClient.apiGet('/api/hris/job-details/getJobDetails', params);

      dispatch({ type: JOB_DETAILS_SUCCESS, result: result.data, itemId, skipLoad });

      return result.data;
    } catch (error) {
      dispatch({ type: JOB_DETAILS_FAILURE, error: error, itemId, skipLoad });
    }
  };
}

export function getUpdatedDataCuts(surveyCode, jobCode) {
  if (surveyCode && jobCode) {
    const params = { survey_code: surveyCode, job_code: jobCode };
    return async (dispatch, getState) => {
      try {
        dispatch({ type: UPDATED_DATA_CUTS });
        const result = await apiClient.apiGet('/api/elasticsearch/data-cuts/getSurveyJobDataCuts', { params: params });

        dispatch({ type: UPDATED_DATA_CUTS_SUCCESS, result: result.data, params });
      } catch (error) {
        dispatch({ type: UPDATED_DATA_CUTS_FAIL, error: error });
      }
    };
  }
  return null;
}

export function loadDetailsChart(jobId) {
  return async dispatch => {
    dispatch(surveyListActions.loadDetailsChart(jobId));
  };
}

export function updateSelectedDataCuts(selection, e1, e2, items, matchId, sourceMapId) {
  const dataCut = items.find(i => i.job_scope === selection);

  return (dispatch, getState) => {
    const { jobDetails } = getState();
    if (!jobDetails || !jobDetails.details) {
      return;
    }

    const org_job_id = jobDetails.details.org_job_id;

    const params = {
      cut_id: dataCut.cut_id,
      job_scope: dataCut.job_scope,
      match_id: matchId,
      job_code: dataCut.job_code,
      survey_code: dataCut.survey_code,
      org_job_id
    };

    dispatch({
      types: [UPDATE_SURVEY_DATA_CUT, UPDATE_SURVEY_DATA_CUT_SUCCESS, UPDATE_SURVEY_DATA_CUT_FAILURE],
      promise: client => client.post('/api/market-pricing/job-matches/updateMatchDataCut', { params: params }),
      params
    }).then(() => {
      dispatch(getUpdatedDataCuts(dataCut.survey_code, dataCut.job_code));
      dispatch(loadDefaultDataCuts(sourceMapId));
    });
  };
}

export function onSuggestedCutSelect(selection, e1, e2, items, matchId, sourceMapId) {
  const dataCut = items.find(i => i.job_scope === selection);
  const params = {
    survey_code: dataCut.survey_code,
    job_code: dataCut.job_code,
    job_scope: dataCut.job_scope
  };

  return async (dispatch, getState) => {
    try {
      dispatch({ type: SUGGESTED_SURVEY_CUT_SELECT });
      const result = await apiClient.apiPost('/api/elasticsearch/search-surveys/getSingleDataCutDetails', {
        ...params
      });

      dispatch({ type: SUGGESTED_SURVEY_CUT_SELECT_SUCCESS, result: result.data, params });
    } catch (error) {
      dispatch({ type: SUGGESTED_SURVEY_CUT_SELECT_FAIL, error: error });
    }
  };
}

export function getSurveySearchDefaultCuts(sourceMapPk) {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: SURVEY_SEARCH_DEFAULT_CUTS });
      const result = await apiClient.apiPost('/api/hris/surveys/getDefaultCuts', { source_map_pk: sourceMapPk });

      dispatch({ type: SURVEY_SEARCH_DEFAULT_CUTS_SUCCESS, result: result.data });
    } catch (error) {
      dispatch({ type: SURVEY_SEARCH_DEFAULT_CUTS_FAIL, error: error });
    }
  };
}

// ToDo: refactor this out in other areas in lab
export function assignCompetitiveSet(info) {
  return async dispatch => {
    try {
      dispatch({ type: ASSIGN_COMP_SET, competitive_set_pk: info.competitive_set_pk });
      apiClient.apiPost('api/hris/competitive-sets/updateCompetitiveSetForJob', info).then(result => {
        dispatch({ type: ASSIGN_COMP_SET_SUCCESS, result: result.data, competitive_set_pk: info.competitive_set_pk });

        if (result.data.success) {
          dispatch(surveyListActions.showEditSurveys(dispatch, info.org_job_id));
          dispatch(surveyListActions.loadDetailsChart(info.org_job_id));
          dispatch(loadJobDetails(info.org_job_id)).then(jobDetails => {
            dispatch({
              type: UPDATE_ADD_JOB_DETAILS,
              jobDetails: jobDetails
            });
            dispatch({
              type: GET_MARKET_SALARY_SUCCESS,
              market_base_salary_50th: jobDetails.market_base_salary_50th,
              result: { market_base_salary_50th: jobDetails.market_base_salary_50th },
              itemId: info.org_job_id
            });
            dispatch(retrieveDynamicAnswers(jobDetails.payscale_job_info?.Reports));
          });
        }
        return result;
      });
    } catch (error) {
      dispatch({ type: ASSIGN_COMP_SET_FAIL, error: error, competitive_set_pk: info.competitive_set_pk });
    }
  };
}

// ToDo: refactor this out in other areas in lab
export function showEditSurveys(jobId) {
  return async dispatch => {
    try {
      dispatch({ type: GET_SURVEY_JOBS });
      const result = await apiClient.apiGet('/api/market-pricing/job-matches/getSurveyJobs', {
        params: {
          org_job_id: jobId,
          showSuggestions: true
        }
      });

      dispatch({ type: GET_SURVEY_JOBS_SUCCESS, result: result.data });
      return result.data.surveyJobMatches;
    } catch (error) {
      dispatch({ type: GET_SURVEY_JOBS_FAIL, error: error });
    }
  };
}

// ToDo: refactor this out in other areas in lab
export function surveyPriceLocation(info) {
  const options = { ...info, yieldSaveReport: true };
  return async dispatch => {
    try {
      dispatch({ type: ASSIGN_PRICE_LOCATION, surveyPriceLocation: info.surveyPriceLocation });
      const result = await apiClient.apiPost('api/hris/survey-location/updateSurveyPriceLocationForJob', options);

      dispatch({
        type: ASSIGN_PRICE_LOCATION_SUCCESS,
        result: result.data,
        surveyPriceLocation: info.surveyPriceLocation
      });

      if (result.data.success) {
        dispatch(surveyListActions.showEditSurveys(dispatch, info.org_job_id));
        dispatch(surveyListActions.loadDetailsChart(info.org_job_id));
        dispatch(loadJobDetails(info.org_job_id)).then(jobDetails => {
          dispatch({
            type: UPDATE_ADD_JOB_DETAILS,
            jobDetails: jobDetails
          });
          dispatch({
            type: GET_MARKET_SALARY_SUCCESS,
            market_base_salary_50th: jobDetails.market_base_salary_50th,
            result: { market_base_salary_50th: jobDetails.market_base_salary_50th },
            itemId: info.org_job_id
          });
        });
      }
    } catch (error) {
      dispatch({ type: ASSIGN_PRICE_LOCATION_FAIL, error: error, surveyPriceLocation: info.surveyPriceLocation });
    }
  };
}

const getNewSelectedCutItem = (newSelectedId, currentSelectedItem, state) => {
  let newItem;
  state.surveyItems.forEach(item => {
    if (item.scopeId === currentSelectedItem.scopeId) {
      newItem = item.cutItems.find(cutItem => cutItem.scopeId === newSelectedId);
    }
  });
  return newItem;
};

/**
 * Update a list of survey properties to match its default cut.
 * @param {*} surveys - List of surveys
 */
const setSurveysToDefaultCut = surveys => {
  return surveys.map(survey => {
    if (!survey.job_scope) {
      return survey;
    }
    const defaultCut = survey.cutItems?.find(cut => cut.job_scope === survey.job_scope);
    if (defaultCut) {
      return { ...survey, ...defaultCut, cutItems: survey.cutItems };
    }
    return survey;
  });
};

export function actions(name, ...args) {
  return (dispatch, getState) => {
    if (args[0] === 'get function') {
      return (...actionArgs) => {
        surveyListActions[name](dispatch, getState, ...actionArgs);
      };
    }
    if (name === 'get action object') {
      return surveyListActions;
    }
    surveyListActions[name](dispatch, getState, ...args);
  };
}
