import Pagination from '../genericGrid/pagination';

const initialState = {
  perPageCount: 10,
  pageNumber: 1,
  searchResultsCount: 0
};

const surveysPagination = new Pagination('SURVEYS', initialState);
export const reducer = surveysPagination.createReducer();
export const actions = surveysPagination.getActions();
