import PropTypes from 'prop-types';
import React, { Component } from 'react';

export default class ListHeader extends Component {
  static propTypes = {
    items: PropTypes.array,
    columns: PropTypes.array,
    allowCheckAll: PropTypes.bool,
    isAllChecked: PropTypes.bool,
    doCheckAll: PropTypes.func,
    hideTableHeader: PropTypes.bool
  };

  getHeadings() {
    let headings = [];

    if (this.props.allowCheckAll) {
      headings.push(
        <th className="table__heading table__heading--checkbox checkbox" key={'grid-header-checkbox'}>
          <input
            className={'pointer '}
            type="checkbox"
            id={'table-heading-checkbox'}
            checked={this.props.isAllChecked}
            onChange={this.props.doCheckAll}
          />
          <label htmlFor="table-heading-checkbox" />
        </th>
      );
    } else {
      headings.push(<th className="table__heading table__heading--checkbox" key={'grid-header-checkbox'} />);
    }

    headings = headings.concat(
      this.props.columns.map((col, index) => {
        let headerClass = 'secondary';
        if (index === 0) headerClass = 'primary';
        if (index === 1) headerClass = 'critical';
        return (
          <th className={'table__heading table__heading--' + headerClass} key={'grid-header-' + col.title}>
            {this.props.hideTableHeader ? (
              <span className="table__heading--text" />
            ) : (
              <span className="table__heading--text">{col.title}</span>
            )}
          </th>
        );
      })
    );

    return headings;
  }

  render() {
    const headings = this.getHeadings();

    return (
      <thead className={'table__header'}>
        <tr>{headings}</tr>
      </thead>
    );
  }
}
