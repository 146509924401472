import localeCurrency from 'locale-currency';

const localeOverrides = {
  ca: 'CAN',
  in: 'en-IN'
};

/*
  given a value (number) and a currency (string), determines the associated locale
  and uses Intl.NumberFormat to format the given value
*/
export function formatCurrency(value, currency = 'USD') {
  return formatValue('currency', value, currency, null);
}

/*
  given a value (number), determines the associated locale
  and uses Intl.NumberFormat to format the given value
*/
export function formatNumber(value, currency = 'USD', decimals) {
  return formatValue('number', value, currency, decimals);
}

function formatValue(type, value, currency = 'USD', decimals) {
  let locale;
  let formattedNumber;
  let simplifiedCurrency;

  // == checks for both null and undefined
  if (value == null) return value;

  // Determine how many decimals to display
  const split = value?.toString()?.split('.');
  let numberOfDecimals = 0;
  if (split.length > 1) {
    numberOfDecimals = split[1]?.length;
  }

  // Determine locale based on given currency (for different comma/deicmal formatting)
  const localeList = currency ? localeCurrency.getLocales(currency) : 'USD';

  // If the locale list has a 'US' option anywhere, default to that
  if (localeList?.length > 0) {
    locale = localeList.includes('US') ? 'US' : localeList[0];
  }

  // fix for some currency formatting
  locale = localeOverrides[locale?.toLowerCase() || 'en-US'] || locale || 'en-US';
  simplifiedCurrency = currency?.startsWith('EUR') ? 'EUR' : currency;

  formattedNumber =
    type === 'currency'
      ? formatAsCurrency(value, locale, simplifiedCurrency, numberOfDecimals)
      : formatAsNumber(value, locale, decimals || numberOfDecimals);

  // if the number contains any arabic characters, reformat in en-US numbers
  const arabic = /[\u0600-\u06FF]/;
  if (arabic.test(formattedNumber)) {
    formattedNumber =
      type === 'currency'
        ? formatAsCurrency(value, 'en-US', simplifiedCurrency, numberOfDecimals)
        : formatAsNumber(value, 'en-US', decimals || numberOfDecimals);
  }

  return formattedNumber || value;
}

function formatAsCurrency(value, locale, currency, numberOfDecimals) {
  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currency,
    minimumFractionDigits: numberOfDecimals
  }).format(value);
}

function formatAsNumber(value, locale, numberOfDecimals) {
  return new Intl.NumberFormat(locale, {
    minimumFractionDigits: numberOfDecimals
  }).format(value);
}
