const PROFILE = 'PROFILE';
const PROFILE_SUCCESS = 'PROFILE_SUCCESS';
const PROFILE_FAILURE = 'PROFILE_FAIL';
const SET_DEFAULT_CURRENCY = 'SET_DEFAULT_CURRENCY';
const SET_DEFAULT_CURRENCY_SUCCESS = 'SET_DEFAULT_CURRENCY_SUCCESS';
const SET_DEFAULT_CURRENCY_FAILURE = 'SET_DEFAULT_CURRENCY_FAILURE';
const SET_DEFAULT_SITE = 'SET_DEFAULT_SITE';
const SET_DEFAULT_SITE_SUCCESS = 'SET_DEFAULT_SITE_SUCCESS';
const SET_DEFAULT_SITE_FAILURE = 'SET_DEFAULT_SITE_FAILURE';

const initialState = {
  profile: [
    {
      userId: 0,
      firstName: '',
      lastName: ''
    }
  ]
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case PROFILE:
      return {
        ...state,
        loading: true,
        query: action.query
      };
    case PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: null,
        items: action.result
      };
    case PROFILE_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        items: null,
        error: action.error
      };
    case SET_DEFAULT_CURRENCY:
      return {
        ...state,
        query: action.query
      };
    case SET_DEFAULT_CURRENCY_SUCCESS:
      window.app.default_currency = action.result.currency_name;
      return {
        ...state
      };
    case SET_DEFAULT_CURRENCY_FAILURE:
      return {
        ...state
      };
    case SET_DEFAULT_SITE:
      return {
        ...state
      };
    case SET_DEFAULT_SITE_SUCCESS:
      window.app.primary_subsite = action.result.primary_subsite;
      return {
        ...state
      };
    case SET_DEFAULT_SITE_FAILURE:
      return {
        ...state
      };
    default:
      return { ...state };
  }
}

export function updateProfile(options = { params: { id: '', fn: '', ln: '' } }) {
  return {
    types: [PROFILE, PROFILE_SUCCESS, PROFILE_FAILURE],
    promise: client => client.get('/api/util/profile/updateProfile', { params: options.params })
  };
}

export function getProfile() {
  return {
    types: [PROFILE, PROFILE_SUCCESS, PROFILE_FAILURE],
    promise: client => client.get('/api/util/profile/getProfile')
  };
}

export function setDefaultCurrency(options) {
  return {
    types: [SET_DEFAULT_CURRENCY, SET_DEFAULT_CURRENCY_SUCCESS, SET_DEFAULT_CURRENCY_FAILURE],
    promise: client =>
      client.post('/api/generic-user/users/setDefaultCurrency', { params: options }),
    query: options.params
  };
}

export function setDefaultSite(options) {
  return {
    types: [SET_DEFAULT_SITE, SET_DEFAULT_SITE_SUCCESS, SET_DEFAULT_SITE_FAILURE],
    promise: client =>
      client.post('/api/generic-user/users/setPrimarySubSite', { params: options }),
    query: options.params
  };
}
