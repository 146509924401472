import { JobActionTypes } from '../../../../typings/enums';

const COPY_JOB_LAUNCH = 'COPY_JOB_LAUNCH';
const COPY_JOB_CLOSE = 'COPY_JOB_CLOSE';
const COPY_JOB_CONFIRM = 'COPY_JOB_CONFIRM';
const COPY_JOB_CONFIRM_SUCCESS = 'COPY_JOB_CONFIRM_SUCCESS';
const COPY_JOB_CONFIRM_FAIL = 'COPY_JOB_CONFIRM_FAIL';
const JOB_COPY_CHECK_SUCCESS = 'JOB_COPY_CHECK_SUCCESS';
const JOB_COPY_CHECK_FAIL = 'JOB_COPY_CHECK_FAIL';
const JOB_COPY_CHECK = 'JOB_COPY_CHECK';
const TOGGLE_JOB_COPY_SLIDEOUT = 'TOGGLE_JOB_COPY_SLIDEOUT';
const TOGGLE_JOB_MOVE_SLIDEOUT = 'TOGGLE_JOB_MOVE_SLIDEOUT';

import * as jobActions from './jobActions';

const initialState = {
  showCopyModal: false,
  orgJobId: null,
  newOrgJobId: null,
  jobCopyCheckSuccess: null,
  isJobActionSlideoutOpen: false,
  actionType: JobActionTypes.None,
  jobsInSelectedLocation: []
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case COPY_JOB_LAUNCH:
      return {
        ...initialState,
        orgJobId: action.jobDetails.org_job_id,
        showCopyModal: true
      };
    case COPY_JOB_CLOSE:
      return {
        ...initialState,
        showCopyModal: false,
        newOrgJobId: action.newOrgJobId
      };
    case JOB_COPY_CHECK:
      return {
        ...state,
        showCopyModal: true
      };
    case JOB_COPY_CHECK_SUCCESS:
      return {
        ...state,
        showCopyModal: true,
        jobCopyCheckSuccess: action.result.success
      };
    case JOB_COPY_CHECK_FAIL:
      return {
        ...state,
        showCopyModal: true,
        orgJobId: null,
        newOrgJobId: null,
        jobCopyCheckSuccess: action.result.success
      };
    case TOGGLE_JOB_COPY_SLIDEOUT:
      return {
        ...state,
        isJobActionSlideoutOpen: action.value,
        actionType: action.actionType,
        jobsInSelectedLocation: []
      };
    case TOGGLE_JOB_MOVE_SLIDEOUT:
      return {
        ...state,
        isJobActionSlideoutOpen: action.value,
        actionType: action.actionType,
        jobsInSelectedLocation: []
      };
    default:
      return { ...state };
  }
}

let actions = {}; // eslint-disable-line prefer-const

// extend or override action creators here:
actions.loadDetailsPanel = jobActions.loadDetailsPanel;

export function toggleJobCopySlideout() {
  return (dispatch, getState) => {
    const isOpen = getState().jobCopy.isJobActionSlideoutOpen;
    dispatch({
      type: TOGGLE_JOB_COPY_SLIDEOUT,
      value: !isOpen,
      actionType: isOpen ? JobActionTypes.None : JobActionTypes.Copy
    });
    if (isOpen) {
      dispatch({ type: 'SHOW_SCROLL_BAR' });
    } else {
      dispatch({ type: 'HIDE_SCROLL_BAR' });
    }
  };
}

export function toggleJobMoveSlideout() {
  return (dispatch, getState) => {
    const isOpen = getState().jobCopy.isJobActionSlideoutOpen;
    dispatch({
      type: TOGGLE_JOB_MOVE_SLIDEOUT,
      value: !isOpen,
      actionType: isOpen ? JobActionTypes.None : JobActionTypes.Move
    });
    if (isOpen) {
      dispatch({ type: 'SHOW_SCROLL_BAR' });
    } else {
      dispatch({ type: 'HIDE_SCROLL_BAR' });
    }
  };
}

export function copyJobLaunch(jobInfo) {
  return (dispatch, getState) => {
    dispatch({
      type: 'COPY_JOB_LAUNCH',
      jobDetails: jobInfo.details
    });
  };
}

export function copyJobClose() {
  return (dispatch, getState) => {
    dispatch({
      type: 'COPY_JOB_CLOSE'
    });
  };
}

export function copyJobToNewLocation(options) {
  return (dispatch, getState) => {
    const ajax = dispatch({
      types: [JOB_COPY_CHECK, JOB_COPY_CHECK_SUCCESS, JOB_COPY_CHECK_FAIL],
      promise: client =>
        client.post('/api/hris/jobs/checkJobExists', {
          params: {
            title: options.title,
            workLocation: options.workLocation,
            jobCode: options.jobCode
          }
        })
    });
    ajax
      .then(function copyJob(result) {
        if (!result.success) {
          return;
        }

        const saveAjax = dispatch({
          types: [COPY_JOB_CONFIRM, COPY_JOB_CONFIRM_SUCCESS, COPY_JOB_CONFIRM_FAIL],
          promise: client => client.post('api/hris/jobCopy/copyJobToNewLocation', { params: options })
        });

        saveAjax
          .then(function goNewJob(saveResult) {
            if (saveResult.orgJobId && saveResult.orgJobId !== '') {
              // Will do a history.push in the willComponentUpdate of jobCopy to go to new copied job
              dispatch({
                type: 'COPY_JOB_CLOSE',
                newOrgJobId: saveResult.orgJobId
              });
            }
          })
          .catch(err => {
            console.error('Error copying job to new location', err);
          });
      })
      .catch(err => {
        console.error('Error checking job exists', err);
      });
  };
}
