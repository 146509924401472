import React from 'react';
import './ReviewHeader.scss';
import { string, func } from 'prop-types';
import SlideoutHeader from '../../../../Slideout/Header/SlideoutHeader';

const propTypes = {
  surveyName: string.isRequired,
  surveyCode: string.isRequired,
  doClose: func
};

const ReviewHeader = props => {
  return (
    <SlideoutHeader
      title={`${props.surveyName} (${props.surveyCode})`}
      subtitle="Generate Data Submission Reports for"
      doClose={props.doClose}
    />
  );
};

ReviewHeader.propTypes = propTypes;
export default ReviewHeader;
