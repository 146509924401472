/* eslint-disable */
const initialState = {
  payRangeBuckets: ['Below', 'Min', 'Mid', 'Max', 'Above'],
  performanceRating: ['one', 'two', 'three', 'four', 'five'],
  matrix: [
    [0.03, 0.03, 0.03, 0.03, 0.03],
    [0.03, 0.03, 0.03, 0.03, 0.03],
    [0.03, 0.03, 0.03, 0.03, 0.03],
    [0.03, 0.03, 0.03, 0.03, 0.03],
    [0.03, 0.03, 0.03, 0.03, 0.03]
  ],
  employees: [],
  sliderValue: 0.03,
  chartAnimation: false
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case 'SOUMINE':
      return { ...state };
    case 'calculate_increase':
      console.timeEnd('calcEmply');
      return { ...state, employeesWithRaise: action.newEmployees };
    case 'initiate':
      return {
        ...state,
        employees: generateEEData(action.employeeCount),
        highChartsData: calculateHighCharts()
      };
    case 'checkBox':
      return { ...state, chartAnimation: !state.chartAnimation };
    default:
      return state;
  }
}

function generateEEData(eeCount) {
  const data = [];
  console.time('calc to create data: ');
  for (let i = 0; i < eeCount; i++) {
    const eeId = i;
    const randomForPayRange = Math.random();
    const baseSalary = Math.floor(Math.random() * 50001) + 50000;
    const payRangeIndex = Math.floor(randomForPayRange * 5);
    const payRangeBucket = initialState.payRangeBuckets[payRangeIndex];
    const perfRatingIndex = Math.floor(Math.random() * 5);
    const perfRating = initialState.performanceRating[perfRatingIndex];
    const item = {
      eeId,
      baseSalary,
      payRangeBucket,
      payRangeIndex,
      perfRating,
      perfRatingIndex,
      payRangePercent: randomForPayRange,
      x: randomForPayRange,
      y: baseSalary
    };
    if (i === 10) data.push(item);
  }
  console.timeEnd('calc to create data: ');
  return data;
}

export function caclulateIncrease(value) {
  console.time('calcEmply');
  console.time('calcOnly');
  return function(dispatch, getState) {
    const { matrix, employees } = getState().perfTest;
    const newEmployees = [];
    //for (let i=0; i < employees.length; i++) {

    employees.forEach(empl => {
      let percent = 1 + matrix[empl.perfRatingIndex][empl.payRangeIndex];
      //override this to temporarily use value form slider
      percent = 1 + value;
      const point = [empl.payRangePercent * percent, empl.baseSalary * percent];
      //newEmpl.baseSalary = newEmpl.y = (empl.baseSalary * percent);
      //newEmpl.payRangePercent = newEmpl.x = (empl.payRangePercent * percent);

      newEmployees.push(point);
    });
    console.timeEnd('calcOnly');
    dispatch({ type: 'calculate_increase', newEmployees });
  };
}

export function onSlide({ oldValue, newValue }) {
  return caclulateIncrease(newValue);
}

function calculateHighCharts() {
  return {
    chart: {
      type: 'scatter',
      zoomType: 'xy'
    },
    title: {
      text: null
    },
    tooltip: {},
    xAxis: {
      title: {
        enabled: true,
        text: 'Height (cm)'
      },
      startOnTick: true,
      endOnTick: true,
      showLastLabel: true,
      labels: {}
    },
    yAxis: {
      title: {
        text: '$ Base Salary'
      },
      labels: {}
    },
    legend: {
      enabled: false
    },
    plotOptions: {
      scatter: {
        marker: {
          states: {
            hover: {
              enabled: true,
              lineColor: 'rgb(100,100,100)'
            }
          }
        },
        states: {
          hover: {
            marker: {
              enabled: false
            }
          }
        },
        tooltip: {
          headerFormat: '<b>{series.name}</b><br>',
          pointFormat: '{point.x} cm, {point.y} kg'
        }
      }
    },
    series: [
      {
        name: 'Employees',
        color: 'gray',
        data: [],
        marker: {
          symbol: 'circle',
          radius: 4
        },
        turboThreshold: 10000
      },
      {
        name: 'Employees With Raise',
        color: 'blue',
        data: [],
        id: 'employees-with-raise',
        marker: {
          symbol: 'circle',
          radius: 4
        }
      }
    ],
    credits: {
      enabled: false
    }
  };
}

export const initiate = () => {
  const employeeCount = parseInt(document.getElementById('num-employee').value);
  return { type: 'initiate', employeeCount };
};

export const checkBoxChange = () => {
  return { type: 'checkBox' };
};
