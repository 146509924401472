import PropTypes from 'prop-types';
import React, { Component } from 'react';

export default class HeaderFullSearchBar extends Component {
  static propTypes = {
    close: PropTypes.func,
    SearchBarComponent: PropTypes.object,
    show: PropTypes.bool,
    logoStatusClass: PropTypes.string
  };

  render() {
    return (
      <div className={'headerFullSearchBar__container ' + this.props.logoStatusClass}>
        <div className={'hfsb__close pull-right pointer'} onClick={this.props.close}>
          <i className="fa fa-times fa-lg" />
        </div>
        <div className="hfsb__SearchBarComponent__container center-vertical">
          {this.props.SearchBarComponent}
        </div>
      </div>
    );
  }
}
