import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes, { object } from 'prop-types';
import uuid from 'uuid';
import { ContactUsForm } from '@payscale/payscale-shared-ui-v3/lib/ContactUsForm';
import { SharedSlideout } from '@payscale/payscale-shared-ui-v3/lib/Slideout';
import * as appActions from 'labActions/app';
import { isFeatureEnabled } from 'ps-components/src/FeatureFlags/FeatureFlagActions';
import axios from 'axios';

import './HelpForm.scss';
import { getBrowser } from 'ps-components/src/Modules/Support/utils';
import apiClient from 'ps-components/src/lib/apiClient';

class HelpForm extends Component {
  static propTypes = {
    dispatch: PropTypes.func,
    app: object,
    isCaseDeflectionEnabled: PropTypes.bool
  };

  constructor(props) {
    super(props);

    this.getSuggestedArticles = this.getSuggestedArticles.bind(this);
    this.goToArticle = this.goToArticle.bind(this);

    this.state = {
      suggestedArticles: []
    };
  }

  close() {
    this.setState({ suggestedArticles: [] });
    this.props.dispatch(appActions.closeHelpForm());
  }

  async submitForm(formData) {
    //  NOTE: IE11 and Edge do NOT support the File Constructor which would be cleaner
    //  Since that's the case, we need to send a blob of data instead of a File object
    const uploadFiles = [];
    if (formData.attachments) {
      formData.attachments.forEach(attachment => {
        const blob = new Blob([attachment], { type: attachment.type });
        //  this is required or the blob won't be considered a file when form is submitted
        blob.lastModifiedDate = new Date();
        uploadFiles.push(blob);
      });
    }

    uploadFiles.forEach(f => (f.uuid = uuid.v4()));

    let result;
    try {
      const multiPartForm = new FormData();
      multiPartForm.append('summary', formData.subject);
      multiPartForm.append('description', formData.description);
      multiPartForm.append('priority', formData.priority);
      multiPartForm.append('ticket_type', 'Bug Report');

      for (let i = 0; i < uploadFiles.length; i++) {
        multiPartForm.append('theFile', uploadFiles[i], formData.attachments[i].name);
      }

      multiPartForm.append('csrf_timestamp', this.props.app.csrf_timestamp);
      multiPartForm.append('csrf_token', this.props.app.csrf_token);

      multiPartForm.append('system', formData.productName);
      multiPartForm.append('browser', getBrowser());
      multiPartForm.append('mp_url', formData.sourceUrl);

      result = await apiClient.apiPost('/api/support/ticketing/createTicket', multiPartForm);

      if (result.data?.success) {
        return result.data;
      } else {
        console.error('SUPPORT_TICKET_SUBMIT_FAILURE', result.data.message);
        throw 'SUPPORT_TICKET_SUBMIT_FAILURE';
      }
    } catch (err) {
      console.error('caught SUPPORT_TICKET_SUBMIT_FAILURE', err);
      throw 'SUPPORT_TICKET_SUBMIT_FAILURE';
    }
  }

  getInitialSearchTerms() {
    // logic pulled from pendo
    const path = window.location.pathname + window.location.hash;
    const terms = path
      .split('/')
      .slice(5)
      .join('%20');
    return path.length > 4 ? this.normalizeSearchTerms(terms) : '""';
  }

  normalizeSearchTerms(str) {
    // logic pulled from pendo
    return str
      .replace(/\-/g, '%20')
      .replace(/#/g, '%20')
      .replace(/([a-z\d])([A-Z])/g, '$1' + '%20' + '$2')
      .replace(/([A-Z]+)([A-Z][a-z\d]+)/g, '$1' + '%20' + '$2')
      .toLowerCase();
  }

  async getSuggestedArticles(searchTerms) {
    const baseUrl = window.location.hostname.includes('localhost')
      ? 'https://lab-test.payscale.com'
      : window.location.origin;
    const path = `${baseUrl}/pendo-salesforce-connector/api/document/?query=${searchTerms ?? '""'}`;
    const result = await axios.get(path);
    const articles = result.data.documents.length > 3 ? result.data.documents.slice(0, 2) : result.data.documents;
    this.setState({ suggestedArticles: articles });
  }

  goToArticle(id) {
    const route =
      window.location.hostname === 'lab.payscale.com'
        ? `https://team-cycle.payscale.com/help?id=${id}`
        : `https://tp.prestaging.underpaid.com/help?id=${id}`;
    window.open(route);
  }

  render() {
    // will pull logic from pendo to improve this for future case deflection
    const pageSource = window.location.pathname.split('/')[5];

    return (
      <SharedSlideout
        isOpen={this.props.app.helpFormShown}
        handleCloseSlideout={this.close.bind(this)}
        panelClassName="help-form"
        useCloseButton={true}
      >
        <ContactUsForm
          productName={this.props.app.basicProductName}
          pageSource={pageSource}
          onClose={this.close.bind(this)}
          onSubmitFn={this.submitForm.bind(this)}
          articles={this.state.suggestedArticles}
          initialSearchTerms={this.getInitialSearchTerms()}
          isVisible={this.props.app.helpFormShown}
          searchTermCallBack={this.getSuggestedArticles}
          goToArticle={this.goToArticle}
          showCaseDeflection={this.props.isCaseDeflectionEnabled}
        />
      </SharedSlideout>
    );
  }
}

const mapStateToProps = state => ({
  app: state.app,
  isCaseDeflectionEnabled: isFeatureEnabled(state.features.flags, 'case-deflection').isEnabled
});

const helpForm = connect(mapStateToProps)(HelpForm);
export default helpForm;
export { helpForm as HelpForm };
export { helpForm };
