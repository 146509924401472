import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Tooltip, OverlayTrigger, Button, DropdownButton, MenuItem } from 'react-bootstrap/lib';
import { Link } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';

export default class MainNav extends Component {
  static propTypes = {
    homeEmployeeCount: PropTypes.object,
    homeJobCount: PropTypes.object,
    homeGradeCount: PropTypes.object,
    competitiveSets: PropTypes.object,
    history: PropTypes.object,
    isNewReportLibraryEnabled: PropTypes.bool,
    isJobBasedRangesEnabled: PropTypes.bool,
    isAdvancedSurveyManagementEnabled: PropTypes.bool
  };

  workforceOnSelect(eventKey) {
    this.props.history.push(eventKey);
  }

  render() {
    const { competitiveSets, isJobBasedRangesEnabled, isAdvancedSurveyManagementEnabled } = this.props;

    const employeesBadge = (
      <span key={this.props.homeEmployeeCount.numberOfEmployees} className="listItem__heading--count">
        {this.props.homeEmployeeCount.numberOfEmployees}
      </span>
    );

    const jobsBadge = (
      <span key={this.props.homeJobCount.numberOfJobs} className="listItem__heading--count">
        {this.props.homeJobCount.numberOfJobs}
      </span>
    );

    const gradeBadge = (
      <span key={this.props.homeGradeCount.numberOfGrades} className="listItem__heading--count">
        {this.props.homeGradeCount.numberOfGrades}
      </span>
    );

    const compsetsBadge = <span className="listItem__heading--count">{competitiveSets.count}</span>;

    return (
      <ul className={'navbar__headerMenu' + ' headerFixed'} id="navbar__navigation--main">
        <li className="alt navbar__headerMenu--listItem">
          <Link to="/">
            <Button bsStyle="link">Dashboard</Button>
          </Link>
        </li>

        <li className="alt navbar__headerMenu--listItem">
          <DropdownButton title="Your Workforce" id="your-workforce-dropdown" className="navbar__dropdown-button">
            <LinkContainer to="/employees?outliers_toggle=Market">
              <MenuItem eventKey="/employees" id="nav-employees-menuitem">
                Employees
                {employeesBadge}
              </MenuItem>
            </LinkContainer>

            <LinkContainer to="/jobs">
              <MenuItem eventKey="/jobs" id="nav-jobs-menuitem">
                Jobs
                {jobsBadge}
              </MenuItem>
            </LinkContainer>

            <LinkContainer to="/structure-model">
              <MenuItem eventKey="/structure-model" id="nav-structureModel-menuitem">
                Grade Based Structures
                {gradeBadge}
              </MenuItem>
            </LinkContainer>
            {isJobBasedRangesEnabled && (
              <LinkContainer to="/ranges">
                <MenuItem eventKey="/ranges" id="nav-JBR-menuitem">
                  Job Based Ranges
                </MenuItem>
              </LinkContainer>
            )}
            <LinkContainer to="/merit-matrix">
              <MenuItem eventKey="/merit-matrix" id="nav-meritMatrix-menuitem">
                Merit Matrix
              </MenuItem>
            </LinkContainer>
          </DropdownButton>
        </li>

        {isAdvancedSurveyManagementEnabled && (
          <li className="alt navbar__headerMenu--listItem">
            <DropdownButton
              title="Market Data"
              id="market-data-dropdown"
              className="navbar__dropdown-button"
              onSelect={this.workforceOnSelect.bind(this)}
            >
              <LinkContainer to="/surveys">
                <MenuItem eventKey="/surveys" id="nav-competitive-sets-menuitem">
                  Surveys
                </MenuItem>
              </LinkContainer>

              <LinkContainer to="/competitive-sets">
                <MenuItem eventKey="/competitive-sets" id="nav-competitive-sets-menuitem">
                  Competitive Sets
                  {compsetsBadge}
                </MenuItem>
              </LinkContainer>
            </DropdownButton>
          </li>
        )}
        {!isAdvancedSurveyManagementEnabled && (
          <li className="alt navbar__headerMenu--listItem">
            <Link to="/competitive-sets">
              <Button bsStyle="link">Competitive Sets</Button>
            </Link>
          </li>
        )}

        <li className="alt navbar__headerMenu--listItem">
          <DropdownButton title="Analytics" id="your-workforce-dropdown" className="navbar__dropdown-button">
            {!this.props.isNewReportLibraryEnabled && (
              <LinkContainer to="/iframe/nodeapp/#reportWriter">
                <MenuItem eventKey="/iframe/nodeapp/#reportWriter" id="nav-competitive-sets-menuitem">
                  Report Library
                </MenuItem>
              </LinkContainer>
            )}
            {this.props.isNewReportLibraryEnabled && (
              <LinkContainer to="/reportLibrary">
                <MenuItem eventKey="/reportLibrary" id="nav-jobs-menuitem">
                  New Report Library
                </MenuItem>
              </LinkContainer>
            )}
            <LinkContainer to="/iframe/nodeapp/#dashboards">
              <MenuItem eventKey="/iframe/nodeapp/#dashboards" id="nav-competitive-sets-menuitem">
                Personal Dashboard
              </MenuItem>
            </LinkContainer>
            {this.props.app.accountMetaData.geoDifferentials && (
              <LinkContainer to="/differentials-engine">
                <MenuItem eventKey="/differentials-engine" id="nav-differentials-engine-menuitem">
                  Differentials Engine
                </MenuItem>
              </LinkContainer>
            )}
          </DropdownButton>
        </li>
      </ul>
    );
  }
}
