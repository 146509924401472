const initialState = {
  loading: true,
  loaded: false,
  flags: {},
  platformFeatureFlagLoaded: false
};

import { setPendoFeatureFlags, setPendoDarklyFlags } from '../lib/formatters.js';

export const UPDATE_PLATFORM_FEATURES = 'UPDATE_PLATFORM_FEATURES';
export const UPDATE_PLATFORM_FEATURES_SUCCESS = 'UPDATE_PLATFORM_FEATURES_SUCCESS';
export const UPDATE_PLATFORM_FEATURES_FAIL = 'UPDATE_PLATFORM_FEATURES_FAIL';
export const UPDATE_LAUNCH_DARKLY_FEATURE = 'UPDATE_LAUNCH_DARKLY_FEATURE';
export const UPDATE_LAUNCH_DARKLY_FEATURE_SUCCESS = 'UPDATE_LAUNCH_DARKLY_FEATURE_SUCCESS';
export const UPDATE_LAUNCH_DARKLY_FEATURE_FAIL = 'UPDATE_LAUNCH_DARKLY_FEATURE_FAIL';
export const GET_FEATURE_FLAGS = 'GET_FEATURE_FLAGS';
export const GET_FEATURE_FLAGS_SUCCESS = 'GET_FEATURE_FLAGS_SUCCESS';
export const GET_FEATURE_FLAGS_FAIL = 'GET_FEATURE_FLAGS_FAIL';

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case UPDATE_PLATFORM_FEATURES:
      return {
        ...state,
        loading: true,
        loaded: false
      };
    case UPDATE_PLATFORM_FEATURES_SUCCESS:
      for (let flag of action.flags) {
        state.flags[flag.name] = flag.value;
      }
      window.app.featureFlag = setPendoFeatureFlags({ ...state.flags, ...window.app.show });

      return {
        ...state,
        loading: false,
        loaded: true
      };
    case UPDATE_PLATFORM_FEATURES_FAIL:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: action.error
      };
    case UPDATE_LAUNCH_DARKLY_FEATURE:
      return {
        ...state,
        loading: true,
        loaded: false
      };
    case UPDATE_LAUNCH_DARKLY_FEATURE_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true
      };
    case UPDATE_LAUNCH_DARKLY_FEATURE_FAIL:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: action.error
      };
    case GET_FEATURE_FLAGS:
      return {
        ...state,
        loading: true,
        loaded: false
      };
    case GET_FEATURE_FLAGS_SUCCESS:
      if (action.featureFlags) {
        window.app.featureFlag = setPendoFeatureFlags({ ...action.featureFlags, ...window.app.show });
        window.app.splitTests = setPendoDarklyFlags(action.featureFlags);
      }
      return {
        ...state,
        loading: false,
        loaded: true,
        flags: action.featureFlags
      };
    case GET_FEATURE_FLAGS_FAIL:
      return {
        ...state,
        loading: false,
        flags: {},
        error: action.error
      };
    default:
      return state;
  }
}
