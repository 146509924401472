const CHART = 'CHART';
const CHART_SUCCESS = 'CHART_SUCCESS';
const CHART_FAIL = 'CHART_FAIL';

const initialState = {
  loading: false,
  loaded: false,
  error: null,
  chart: {}
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case CHART:
      return {
        ...state,
        loading: true
      };
    case CHART_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: null,
        highChartsJson: action.result
      };
    case CHART_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        data: null,
        error: action.error
      };
    default:
      return { ...state };
  }
}

export function getMarketPriceStatusChart(params = { category_pk: 2, analyticsAggMode: true }) {
  return {
    types: [CHART, CHART_SUCCESS, CHART_FAIL],
    promise: client =>
      client.get('/api/baby-panther/home-charts/jobFamilyRatio', { params: params })
  };
}
