import { mapDispatchToActions } from '../../../../../panther-react/src/helpers/utils.js';

const initialState = {
  loading: false,
  loaded: false,
  error: null,
  sftpSlideoutOpen: false
};
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case 'GET_LAST_REFRESH_DATE':
      return {
        ...state,
        loading: true,
        loaded: false
      };
    case 'GET_LAST_REFRESH_DATE_SUCCESS':
      let lastRefresh = 'N/A';
      if (action.result.lastRefreshDate && action.result.lastRefreshDate != '') {
        lastRefresh = action.result.lastRefreshDate;
      }
      return {
        ...state,
        lastRefreshDate: lastRefresh,
        loading: false,
        loaded: true
      };
    case 'GET_LAST_REFRESH_DATE_FAIL':
      return {
        ...state,
        loading: false,
        loaded: true
      };
    case 'REFRESH_ALL_REPORTS':
      return {
        ...state,
        loading: true,
        loaded: false
      };
    case 'REFRESH_ALL_REPORTS_SUCCESS':
      return {
        ...state,
        refreshBtnText: 'Refresh in Progress...',
        loading: false,
        loaded: true
      };
    case 'REFRESH_ALL_REPORTS_FAIL':
      return {
        ...state,
        loading: false,
        loaded: true
      };
    case 'OPEN_SFTP':
      return {
        ...state,
        sftpSlideoutOpen: true
      };
    case 'CLOSE_SFTP':
      return {
        ...state,
        sftpSlideoutOpen: false
      };
    default:
      return { ...state };
  }
}

export const actions = {};

actions.getLastRefreshDate = function getLastRefreshDate() {
  return (dispatch, getState) => {
    dispatch({
      types: ['GET_LAST_REFRESH_DATE', 'GET_LAST_REFRESH_DATE_SUCCESS', 'GET_LAST_REFRESH_DATE_FAIL'],
      promise: client => client.get('/api/subsites/sites/getLastCrowdsourceDate', {})
    });
  };
};

actions.refreshSiteMarketReports = function getSites() {
  return (dispatch, getState) => {
    if (!getState().refreshMarketReports.loading) {
      dispatch({
        types: ['REFRESH_ALL_REPORTS', 'REFRESH_ALL_REPORTS_SUCCESS', 'REFRESH_ALL_REPORTS_FAIL'],
        promise: client => client.get('/api/hris/jobalyzer-bulk/runSiteExistingJobsBackground', {})
      })
        .then(result => {
          dispatch(actions.getLastRefreshDate());
        })
        .catch(err => {
          console.error('Error running site existing jobs background', err);
        });
    }
  };
};

actions.refreshHRISData = function refreshHRISData() {
  return (dispatch, getState) => {
    dispatch({
      types: ['REFRESH_HRIS_DATA', 'REFRESH_HRIS_DATA_SUCCESS', 'REFRESH_HRIS_DATA_FAIL'],
      promise: client => client.get('/api/hris/jobs/refreshHRISData', {})
    })
      .then(result => {
        dispatch(actions.getLastRefreshDate());
      })
      .catch(err => {
        console.error('Error refreshing HRIS data', err);
      });
  };
};

actions.closeErrorModal = function closeErrorModal(rowInfo) {
  return {
    type: 'CLOSE_ERROR_MODAL'
  };
};

actions.showSetupSFTP = function showSetupSFTP(rowInfo) {
  return {
    type: 'OPEN_SFTP'
  };
};

actions.closeSetupSFTP = function closeSetupSFTP(rowInfo) {
  return {
    type: 'CLOSE_SFTP'
  };
};

export const dataManagementActions = mapDispatchToActions(actions);
