export const loadDetailsPanel = function loadDetailsPanel(dispatch, getStore, itemId, item) {
  dispatch({
    types: ['SURVEY_PARTICIPANTS', 'SURVEY_PARTICIPANTS_SUCCESS', 'SURVEY_PARTICIPANTS_FAILURE'],
    promise: client => client.get('/api/hris/surveys/getParticipants', { params: { source_map_pk: itemId } }),
    item
  });

  dispatch(loadDataCutsPanel(itemId));
};

export const loadDataCutsPanel = itemId => dispatch => {
  dispatch({
    types: ['SURVEY_DEFAULT_CUTS', 'SURVEY_DEFAULT_CUTS_SUCCESS', 'SURVEY_DEFAULT_CUTS_FAIL'],
    promise: client => client.get('/api/hris/surveys/getDefaultCuts', { params: { source_map_pk: itemId } }),
    itemId
  });

  dispatch({
    types: ['SURVEY_DETAILS', 'SURVEY_DETAILS_SUCCESS', 'SURVEY_DETAILS_FAILURE'],
    promise: client => client.get('/api/hris/surveys/getSurveyDetails', { params: { source_map_pk: itemId } }),
    itemId
  });

  dispatch({
    types: ['SINGLE_SURVEY_JOB', 'SINGLE_SURVEY_JOB_SUCCESS', 'SINGLE_SURVEY_JOB_FAILURE'],
    promise: client =>
      client.get('/api/elasticsearch/search-survey-jobs/querySurvey', {
        params: { source_map_pk: itemId }
      }),
    itemId
  });
};

export const loadDefaultDataCuts = itemId => dispatch => {
  dispatch({
    types: ['LOAD_DEFAULT_DATA_CUTS', 'LOAD_DEFAULT_DATA_CUTS_SUCCESS', 'LOAD_DEFAULT_DATA_CUTS_FAIL'],
    promise: client => client.get('/api/hris/surveys/getDefaultCuts', { params: { source_map_pk: itemId } }),
    itemId
  });
};
