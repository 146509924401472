import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { applyRounding } from '../../../lib/formatters';
import classnames from 'classnames';
import './ProposedManualSetting.scss';

export default class ProposedManualSetting extends Component {
  static propTypes = {
    className: PropTypes.string,
    currentProposedValue: PropTypes.number,

    handleProposedValueUpdate: PropTypes.func.isRequired,
    preferences: PropTypes.object,
    payType: PropTypes.string.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      hasChanges: false,
      proposedValue: 0
    };
    this.delayedSubmitTimer = null;
    this.doSubmit = this.doSubmit.bind(this);
    this.doDelayedSubmit = this.doDelayedSubmit.bind(this);
    this.clearDelayedSubmit = this.clearDelayedSubmit.bind(this);
    this.handleEditValueFocus = this.handleEditValueFocus.bind(this);
    this.handleEditValueOnBlur = this.handleEditValueOnBlur.bind(this);
    this.handleEditValueKeyUp = this.handleEditValueKeyUp.bind(this);
    this.handleEditValueOnChange = this.handleEditValueOnChange.bind(this);
  }

  componentDidMount() {
    const { payType, preferences, currentProposedValue } = this.props;
    const newValue = applyRounding(currentProposedValue, 'monetary', preferences, payType);
    this.setState({ proposedValue: newValue });
  }

  doDelayedSubmit() {
    this.clearDelayedSubmit();
    this.setState({ hasChanges: true });
    this.delayedSubmitTimer = setTimeout(() => {
      this.doSubmit();
    }, 1000);
  }

  doSubmit() {
    const { payType, preferences } = this.props;
    const { proposedValue } = this.state;
    this.clearDelayedSubmit();

    const annualizedValue = payType === 'hourly' ? proposedValue * 2080 : proposedValue;
    const newValue = applyRounding(annualizedValue, 'monetary', preferences);

    this.props.handleProposedValueUpdate(newValue);
    this.setState({ hasChanges: false, proposedValue: applyRounding(proposedValue, 'monetary', preferences) });
  }

  clearDelayedSubmit() {
    if (this.delayedSubmitTimer) clearTimeout(this.delayedSubmitTimer);
  }

  handleEditValueKeyUp(event) {
    const { hasChanges } = this.state;

    if (event.key === 'Enter' && hasChanges) this.doSubmit();
    else this.doDelayedSubmit();
  }

  handleEditValueOnBlur() {
    const { hasChanges } = this.state;

    if (!hasChanges) {
      return;
    }
    this.doSubmit();
  }

  handleEditValueFocus(event) {
    event.target.select();
  }

  handleEditValueOnChange(event) {
    this.setState({ proposedValue: parseFloat(event.target.value) });
  }

  render() {
    const { className, label } = this.props;
    const { proposedValue } = this.state;

    return (
      <div className={classnames('proposed-setting__container', { className })}>
        <h5 className="proposed-label">{label}</h5>
        <input
          className="proposed-input"
          data-test-id={`jbr-manual-${label.toLowerCase()}-input`}
          type="number"
          value={proposedValue}
          onKeyUp={this.handleEditValueKeyUp}
          onChange={this.handleEditValueOnChange}
          onBlur={this.handleEditValueOnBlur}
          onFocus={this.handleEditValueFocus}
        />
      </div>
    );
  }
}
