import React, { Component } from 'react';
import { bool, node, string, oneOfType, object } from 'prop-types';

import './Placeholder.scss';

class Placeholder extends Component {
  static propTypes = {
    children: node,
    className: string,
    icon: string,
    imagePath: string,
    primaryText: oneOfType([string, object]),
    secondaryText: oneOfType([string, object]),
    showText: bool
  };

  render() {
    const { children, className, icon, imagePath, primaryText, secondaryText, showText } = this.props;

    return (
      <div className={`placeholder ${className}`}>
        {imagePath && <img className="placeholder__img" src={imagePath} />}
        {icon && <i className={icon} />}
        {showText && primaryText && <h2 className="placeholder__msg-primary">{primaryText}</h2>}
        {showText && secondaryText && <div className="placeholder__msg-secondary">{secondaryText}</div>}
        {children}
      </div>
    );
  }
}

export default Placeholder;
