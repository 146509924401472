export function getColumnIndex(grid, termName) {
  if (!grid || !grid.terms) {
    return -1;
  }
  return grid.terms.findIndex(term => term.term === termName);
}

export function getColumnValue(jobPricingGrid, termName, rowIndex) {
  const rowValueIndex = rowIndex === null || rowIndex === undefined || rowIndex < 0 ? 0 : rowIndex;
  if (jobPricingGrid.data.length === 0) {
    return null;
  }
  const columnIndex = getColumnIndex(jobPricingGrid, termName);
  if (columnIndex === -1) {
    return null;
  }
  return jobPricingGrid.data[rowValueIndex][columnIndex];
}
