/**
 * Determine if a job is priced, defined by having a populated work location
 * and PayScale job title
 * @param workLocation Job's work location
 * @param payscaleJobTitle Job's PayScale job title
 * @returns true if priced, false otherwise
 */
export function IsJobPriced(workLocation?: string, payscaleJobTitle?: string): boolean {
  if (!workLocation || !payscaleJobTitle) {
    return false;
  }
  
  return workLocation.trim() !== '' && payscaleJobTitle.trim() !== '';
}