import 'core-js';
import 'regenerator-runtime/runtime';
import React from 'react';
import ApiClientSuperagent from '../../ps-components/src/lib/ApiClientSuperagent';

import { createBrowserHistory as createHistory } from 'history';

import appConfig from './helpers/appConfig';
import io from 'socket.io-client';

const apiClient = new ApiClientSuperagent();

if (process.env.NODE_ENV !== 'production') {
  window.React = React; // enable debugger
}

const config = appConfig();
window.app.appPrefix = config.appPrefix;
window.app.apiPrefix = config.apiPrefix;
window.app.nodeAppPrefix = config.nodeAppPrefix;

const history = createHistory({
  orgCode: config.orgCode,
  basename: config.baseName,
  // the custom confirmation is used so the app can get both the current and next pathname in the history.block event
  // otherwise history.listen fires after the route transition, and getting the previous url would require some refactor
  getUserConfirmation: (messageFromListener, next) => next(true)
});

// previous version of react router has a listen before event listener. the block fn only allows one listener.
history.beforeTransition = fn => {
  history.beforeListeners = history.beforeListeners || new Map();
  history.beforeListeners.set(fn, fn);
  return () => history.beforeListeners.delete(fn);
};

history.block((location, action) => {
  const nextPath = location.pathname;
  const currPath = history.location.pathname;

  const routeInfo = { currPath, nextPath, location, action };
  if (history.beforeListeners) history.beforeListeners.forEach(fn => fn(routeInfo));
});

function initSocket() {
  const socket = io({ path: config.apiPrefix + 'socket.io' });

  socket.on('msg', data => {});

  return socket;
}

global.socket = initSocket();

export default {
  apiClient,
  appConfig: config,
  history
};
