import React from 'react';
import PropTypes from 'prop-types';
import './SlideoutHeader.scss';
import { getClassNameWithOptionalClass } from '../../Utils/ClassNameUtils';
import SlideoutHeaderButton from './SlideoutHeaderButton';

const propTypes = {
  headerClassName: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  titleClassName: PropTypes.string,
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  subtitleClassName: PropTypes.string,
  closeButtonClassName: PropTypes.string,
  additionalButtons: PropTypes.object,
  testIdCloseButton: PropTypes.string,
  hideCloseButton: PropTypes.bool,
  doClose: PropTypes.func
};

const defaultProps = {
  hideCloseButton: false
};

const SlideoutHeader = props => {
  const headerClassName = getClassNameWithOptionalClass('slideout-header', props.headerClassName);
  const titleClassName = getClassNameWithOptionalClass('slideout-header__title', props.titleClassName);
  const subtitleClassName = getClassNameWithOptionalClass('slideout-header__sub-title', props.subtitleClassName);
  const closeButtonClassName = getClassNameWithOptionalClass('slideout-header__button', props.closeButtonClassName);

  const { title, subtitle, additionalButtons, doClose, hideCloseButton, testIdCloseButton } = props;
  return (
    <div className={headerClassName}>
      <section className="slideout-header__left">
        {subtitle && <label className={subtitleClassName}>{subtitle}</label>}
        <label className={titleClassName}>{title}</label>
      </section>
      <section className="slideout-header__right">
        {additionalButtons}
        {!hideCloseButton && (
          <SlideoutHeaderButton
            buttonClass={closeButtonClassName}
            iconClass="icon-cancel"
            onClick={doClose}
            testId={testIdCloseButton}
          />
        )}
      </section>
    </div>
  );
};

SlideoutHeader.propTypes = propTypes;
SlideoutHeader.defaultProps = defaultProps;

export default SlideoutHeader;
