import FacetedSearch from '../../../../../redux/facetedSearch';
import apiClient from '../../../../../lib/apiClient';
import { getCurrencies } from '../../../../../redux/stateSelectors';
import { SUPPORTED_MAX_BULK_JOB_SELECTION_COUNT } from '../../../Rules';
export const JOB_RANGES_SEARCH_AND_BULK_ADD_INIT = 'JOB_RANGES_SEARCH_AND_BULK_ADD_INIT';
export const JOB_RANGES_SEARCH_AND_BULK_ADD_SUCCESS = 'JOB_RANGES_SEARCH_AND_BULK_ADD_SUCCESS';
export const JOB_RANGES_SEARCH_AND_BULK_ADD_FAIL = 'JOB_RANGES_SEARCH_AND_BULK_ADD_FAIL';
const nameSpace = 'JOB_RANGES';

const initialState = {
  filtersAreOpen: false,
  facetSelection: {},
  facets: [],
  loading: false,
  loaded: false,
  error: null,
  items: [],
  query: '',
  showAddButton: true,
  resetFacetText: 'Reset Filters',
  filters: {},
  baseRoute: '/ranges/add',
  facetIsCheckedByDefault: true,
  showZeros: false,
  sortBy: 'label'
};

const options = {
  apiClient,
  graphql: { apiClient },
  getInstanceState: store => store.jobRanges.jobRangesSearch,
  /** This will send additional facet selection parameters with the search call to the server */
  additionalSearchParams: store => {
    const { jobRangeModel } = store.jobRanges.modelDetails;
    let params = {};

    if (jobRangeModel && jobRangeModel.currencyId) {
      const currencies = getCurrencies(store);
      const rangeModelCurrency = currencies.find(curr => curr.id === jobRangeModel.currencyId);

      params = { currency: [rangeModelCurrency.code] };
    }

    return params;
  }
};
export const jobRangesSearch = new FacetedSearch(nameSpace, initialState, options);

function extendReducer(state, action) {
  switch (action.type) {
    default:
      return null;
  }
}

export const reducer = jobRangesSearch.createReducer(extendReducer, {
  searchAPI: '/api/jobRanges/search/search'
});

///////////////////////////////////
//           ACTIONS             //
///////////////////////////////////

export const jobRangesSearchActions = jobRangesSearch.getActions();

/**
 * Searches the latest query parameters with a limit and generates an action to add all jobs to the selection list
 * @param limit number of jobs to search and bulk add to selection list
 * @returns {Function}
 */
jobRangesSearchActions.doSearchAndBulkAddJobs = () => async (dispatch, getState) => {
  const client = jobRangesSearch.options.apiClient;
  const params = jobRangesSearch.getSearchParameters(getState());

  dispatch({
    type: JOB_RANGES_SEARCH_AND_BULK_ADD_INIT,
    query: params.es_query,
    page_name: params.pageName,
    lastQueryParams: params
  });

  let ajaxResult;

  const csrfPair = {} && getState().session && getState().session.csrfPair;

  try {
    ajaxResult = await client.apiPost(jobRangesSearch.searchAPI, {
      ...params,
      ...csrfPair,
      limit: SUPPORTED_MAX_BULK_JOB_SELECTION_COUNT
    });
  } catch (error) {
    //  TODO: MPE-3123 - How should error handling occur??
    console.log('facet search and bulk all jobs with limit failed', error);
    dispatch({ type: JOB_RANGES_SEARCH_AND_BULK_ADD_FAIL, error });
  }

  const jobs = ajaxResult && ajaxResult.data && ajaxResult.data.data ? ajaxResult.data.data : [];
  dispatch({ type: JOB_RANGES_SEARCH_AND_BULK_ADD_SUCCESS, jobs });
};
