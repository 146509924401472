import React, { Fragment } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PXLAlert from '@payscale/design/lib/alert';
import { SLIDEOUTS } from 'ps-components/src/Slideout/Slideouts';
import { showSlideout } from 'ps-components/src/Slideout/SlideoutReducer';
import cn from 'classnames';

import './AlertToast.scss';

function AlertToast(props) {
  // closeToast is automatically injected into the first argument of toast
  const { closeToast, message, level, actions, hideContactSupport } = props;

  function getAlertMessage(message, level) {
    return (
      <Fragment>
        <div className="alert-toast__text">{message}</div>
        {level === 'danger' && !hideContactSupport && (
          <div className="alert-toast__contact-text">
            If the issue persists, please <a onClick={() => contactSupport()}>contact support</a>.
          </div>
        )}
      </Fragment>
    );
  }

  function contactSupport() {
    actions.showSlideout(SLIDEOUTS.CONTACT_SUPPORT_SLIDEOUT);
  }

  return (
    <PXLAlert
      className={cn('react-toast-pxl', props.className)}
      close={true}
      level={level}
      message={getAlertMessage(message, level)}
      onClickClose={closeToast}
    />
  );
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      showSlideout
    },
    dispatch
  )
});

export default connect(null, mapDispatchToProps)(AlertToast);
