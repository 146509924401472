export const getSessionCurrency = state => {
  const isLab = state.app && state.app.isLab;

  // Lab
  if (isLab) {
    let currency =
      state.my_currency === null || !state.my_currency
        ? { currency_id: null, currency: '%', symbol: null, currency_pretty: 'All Currencies' }
        : state.app.currencies.find(curr => curr.currency === state.my_currency);

    return mapCurrency(currency);
  }
  // MP
  else return state.session.currency;
};

const mapCurrency = currency => ({
  id: currency.currency_id,
  code: currency.currency,
  name: currency.currency_pretty,
  symbol: currency.symbol
});

export const getCurrencies = state => {
  if (state.app && state.app.isLab) {
    return [
      { currency_id: null, currency: '%', symbol: null, currency_pretty: 'All Currencies' },
      ...state.app.currencies
    ].map(mapCurrency);
  } else return state.currency.currencies;
};

export const getUseJobCodeKey = state => {
  if (state.app && state.app.isLab) return true;
  else return state.session.organization.useJobCodeKey;
};

export const getIsUserReadOnly = state => {
  if (state.app && state.app.isLab) return Boolean(state.app.user_rights.mp_ro_user_p);
  else return state.session.user.rights.readOnlyUser;
};

export const areCurrenciesLoaded = state => {
  if (state.app && state.app.isLab) return Boolean(state.app.currencies && state.app.currencies.length);
  else return state.currency.currenciesLoaded;
};

export const getCSRFPair = state => {
  if (state.app && state.app.isLab) {
    return { csrf_token: state.app.csrf_token, csrf_timestamp: state.app.csrf_timestamp };
  } else return state.session?.csrfPair;
};

export const getReplacementTokens = state => {
  if (!isLab(state)) {
    return state.session.replacementTokens;
  } else return [];
};

export const isLab = state => Boolean(state.app && state.app.isLab);
