import apiClient from '../../../lib/apiClient';

const SKILLS_JOB_LIST = 'SKILLS_JOB_LIST';
const SKILLS_JOB_LIST_SUCCESS = 'SKILLS_JOB_LIST_SUCCESS';
const SKILLS_JOB_LIST_FAIL = 'SKILLS_JOB_LIST_FAIL';
const SKILLS_SEARCH_LIST = 'SKILLS_SEARCH_LIST';
const SKILLS_SEARCH_LIST_SUCCESS = 'SKILLS_SEARCH_LIST_SUCCESS';
const SKILLS_SEARCH_LIST_FAIL = 'SKILLS_SEARCH_LIST_FAIL';
const RESET_SKILLS_BY_JOB_LIST = 'RESET_SKILLS_BY_JOB_LIST';

const initialState = {
  loading: false,
  loaded: false,
  error: null,
  items: [],
  skillsSearchItems: [],
  allSkills: []
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SKILLS_JOB_LIST:
      return {
        ...initialState,
        loading: true
      };
    case SKILLS_JOB_LIST_SUCCESS:
      let reportAnswers = []; // eslint-disable-line prefer-const
      let skillList = action.skillList; // eslint-disable-line prefer-const
      if (!skillList) {
        skillList = action.result;
      }
      if (skillList && skillList.length) {
        skillList.map(answer => {
          reportAnswers.push({ value: answer.answer, label: answer.answer });
        });
      }
      return {
        ...state,
        loading: false,
        loaded: true,
        error: null,
        items: reportAnswers,
        allSkills: skillList
      };
    case SKILLS_JOB_LIST_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        data: null,
        error: action.error
      };
    case SKILLS_SEARCH_LIST:
      return {
        ...state,
        loading: true
      };
    case SKILLS_SEARCH_LIST_SUCCESS:
      let searchAnswers = []; // eslint-disable-line prefer-const
      let searchSkillList = action.skillList; // eslint-disable-line prefer-const
      if (!searchSkillList) {
        searchSkillList = action.result;
      }
      if (searchSkillList && searchSkillList.length) {
        searchSkillList.map(answer => {
          searchAnswers.push(answer.answer);
        });
      }
      action.searchCallback(action.query.searchParam, searchAnswers);
      return {
        ...state,
        loading: false,
        loaded: true,
        error: null,
        skillsSearchItems: action.result
      };
    case SKILLS_SEARCH_LIST_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        data: null,
        error: action.error
      };
    case RESET_SKILLS_BY_JOB_LIST:
      return {
        ...initialState
      };
    default:
      return { ...state };
  }
}

export function getJobSkills(options) {
  return async dispatch => {
    try {
      dispatch({ type: SKILLS_JOB_LIST });
      const result = await apiClient.apiGet('/api/payscale/skills/skills', { params: options.params });

      if (!result.data.success === false) {
        dispatch({ type: SKILLS_JOB_LIST_FAIL, error: result.data.reason });
      } else {
        dispatch({
          type: SKILLS_JOB_LIST_SUCCESS,
          result: result.data,
          query: options.params
        });
      }
    } catch (error) {
      dispatch({ type: SKILLS_JOB_LIST_FAIL, error: error });
    }
  };
}

export function populateJobSkills(skillList) {
  return (dispatch, getState) => {
    dispatch({
      type: SKILLS_JOB_LIST_SUCCESS,
      skillList: skillList
    });
  };
}

export function skillsSearch(options) {
  options.params.searchParam = options.params.searchParam.trim();
  return async dispatch => {
    try {
      dispatch({ type: SKILLS_SEARCH_LIST });
      const result = await apiClient.apiGet('/api/payscale/skills/searchSkills', { params: options.params });

      dispatch({
        type: SKILLS_SEARCH_LIST_SUCCESS,
        result: result.data,
        query: options.params,
        searchCallback: options.searchCallback
      });
    } catch (error) {
      dispatch({ type: SKILLS_SEARCH_LIST_FAIL, error: error });
    }
  };
}

export function resetSkillsByJobListReducer() {
  return { type: RESET_SKILLS_BY_JOB_LIST };
}
