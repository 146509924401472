const initialState = {
  refreshBtnText: 'Refresh PS Data',
  loading: false
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case 'REFRESH_ALL_REPORTS':
      return {
        ...state
      };
    case 'REFRESH_ALL_REPORTS_SUCCESS':
      return {
        ...state,
        refreshBtnText: 'Refresh in Progress...',
        loading: true
      };
    case 'REFRESH_ALL_REPORTS_FAIL':
      return {
        ...state
      };
    default:
      return { ...state };
  }
}

//Creates a delivers job
export function refreshSiteMarketReports(params) {
  return (dispatch, getState) => {
    if (!getState().refreshMarketReports.loading) {
      dispatch({
        types: ['REFRESH_ALL_REPORTS', 'REFRESH_ALL_REPORTS_SUCCESS', 'REFRESH_ALL_REPORTS_FAIL'],
        promise: client => client.get('/api/hris/jobalyzer-bulk/runSiteExistingJobsBackground', {})
      });
    }
  };
}
