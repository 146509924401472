import { selectors as jobSelectors } from './jobsSearch';
import { actions } from './jobsSearch';

const GET_SELECTED_JOBS = 'GET_SELECTED_JOBS';
const GET_SELECTED_JOBS_SUCCESS = 'GET_SELECTED_JOBS_SUCCESS';
const GET_SELECTED_JOBS_FAIL = 'GET_SELECTED_JOBS_FAIL';

const OPEN_DELETE_JOBS_ALERT_MODAL = 'OPEN_DELETE_JOBS_ALERT_MODAL';
const CLOSE_DELETE_JOBS_ALERT_MODAL = 'CLOSE_DELETE_JOBS_ALERT_MODAL';

const DELETE_SELECTED_JOBS = 'DELETE_SELECTED_JOBS';
const DELETE_SELECTED_JOBS_SUCCESS = 'DELETE_SELECTED_JOBS_SUCCESS';
const DELETE_SELECTED_JOBS_FAIL = 'DELETE_SELECTED_JOBS_FAIL';

const POLL_DELETE = 'POLL_DELETE';
const POLL_DELETE_SUCCESS = 'POLL_DELETE_SUCCESS';
const POLL_DELETE_FAIL = 'POLL_DELETE_FAIL';

const STOP_DELETE_POLLING = 'STOP_DELETE_POLLING';

const initialState = {
  selectedJobIds: [],
  employeeCountForSelectedJobs: 0,
  isJobsDeleteAlertModalOpen: false,
  bulkDeleteJobsLoading: false,
  deleteRequestId: null,
  deleteJobPolling: false,
  deletingJobsFailed: false
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case GET_SELECTED_JOBS:
      return {
        ...state,
        selectedJobIds: [],
        employeeCountForSelectedJobs: 0
      };
    case GET_SELECTED_JOBS_SUCCESS:
      const selectedJobIds = action.result.success ? action.result.selectedJobIds : [];
      const employeeCountForSelectedJobs = action.result.success ? action.result.employeesCount : 0;
      return {
        ...state,
        selectedJobIds: selectedJobIds,
        employeeCountForSelectedJobs: employeeCountForSelectedJobs
      };
    case GET_SELECTED_JOBS_FAIL:
      return {
        ...state,
        selectedJobIds: [],
        employeeCountForSelectedJobs: 0
      };
    case OPEN_DELETE_JOBS_ALERT_MODAL:
      return {
        ...state,
        isJobsDeleteAlertModalOpen: true
      };
    case CLOSE_DELETE_JOBS_ALERT_MODAL:
      return {
        ...state,
        isJobsDeleteAlertModalOpen: false
      };
    case DELETE_SELECTED_JOBS:
      return {
        ...state,
        bulkDeleteJobsLoading: true,
        deleteRequestId: null,
        deletingJobsFailed: false
      };
    case DELETE_SELECTED_JOBS_SUCCESS:
      return {
        ...state,
        bulkDeleteJobsLoading: action.result.success,
        deleteRequestId: action.result.success ? action.result.requestId : null,
        deletingJobsFailed: !action.result.success
      };
    case DELETE_SELECTED_JOBS_FAIL:
      return {
        ...state,
        bulkDeleteJobsLoading: false,
        deleteRequestId: null,
        deletingJobsFailed: true
      };
    case POLL_DELETE:
      return {
        ...state,
        deleteJobPolling: true,
        bulkDeleteJobsLoading: true
      };
    case POLL_DELETE_SUCCESS:
      return {
        ...state,
        deleteJobPolling: !action.result.completed,
        bulkDeleteJobsLoading: !action.result.completed,
        isJobsDeleteAlertModalOpen: !action.result.completed
      };
    case POLL_DELETE_FAIL:
      return {
        ...state,
        deleteJobPolling: false,
        bulkDeleteJobsLoading: false
      };
    case STOP_DELETE_POLLING:
      return {
        ...state,
        deleteJobPolling: false,
        bulkDeleteJobsLoading: false
      };
    default:
      return { ...state };
  }
}

export function getSelectedJobs(includedIds, excludedIds) {
  return (dispatch, getState) => {
    const searchParams = jobSelectors.getSearchParameters(getState());
    const params = { ...searchParams, includedIds, excludedIds };
    dispatch({
      types: [GET_SELECTED_JOBS, GET_SELECTED_JOBS_SUCCESS, GET_SELECTED_JOBS_FAIL],
      promise: client => client.get('/api/hris/jobs/getSelectedJobs', { params })
    }).then(function(result) {
      if (result.success) {
        dispatch(openDeleteJobsAlertModal());
      }
    });
  };
}

function openDeleteJobsAlertModal() {
  return (dispatch, getState) => {
    dispatch({
      type: 'OPEN_DELETE_JOBS_ALERT_MODAL'
    });
  };
}

export function closeDeleteJobsAlertModal() {
  return (dispatch, getState) => {
    dispatch({
      type: 'CLOSE_DELETE_JOBS_ALERT_MODAL'
    });
  };
}

export function deleteSelectedJobs() {
  return async (dispatch, getState) => {
    const state = getState().bulkDeleteJobs;
    if (state.deleteJobPolling) {
      return;
    }

    dispatch({
      types: [DELETE_SELECTED_JOBS, DELETE_SELECTED_JOBS_SUCCESS, DELETE_SELECTED_JOBS_FAIL],
      promise: client =>
        client.post('/api/hris/jobs/bulkDeleteJobs', {
          params: { jobIds: JSON.stringify(getState().bulkDeleteJobs.selectedJobIds) }
        })
    })
      .then(res => {
        if (res.success) {
          dispatch(pollDeleteJobsStatus());
        }
      })
      .catch(err => {
        console.error('Error starting bulk delete', err);
      });
  };
}

function pollDeleteJobsStatus() {
  return (dispatch, getState) => {
    const state = getState().bulkDeleteJobs;
    const promise = dispatch({
      types: [POLL_DELETE, POLL_DELETE_SUCCESS, POLL_DELETE_FAIL],
      promise: client =>
        client.get('/api/hris/jobs/pollDeleteJobsStatus', {
          params: { requestId: state.deleteRequestId }
        })
    });

    promise
      .then(res => {
        if (res.success && !res.completed) {
          setTimeout(() => dispatch(pollDeleteJobsStatus()), res.retryAfterMilliseconds || 500);
        } else {
          dispatch({
            type: 'STOP_DELETE_POLLING'
          });
          // it takes just a few seconds to update es after deleting jobs
          setTimeout(() => {
            dispatch(actions.initialSearch());
          }, 3000);
        }
      })
      .catch(err => {
        console.error('Error polling delete jobs', err);
      });
  };
}
