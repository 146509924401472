import React, { Component } from 'react';
import { number, object, string } from 'prop-types';
import RangeChartList from '../../RangeChartList/RangeChartList';
import './JobDetailsSlideOutCharts.scss';
import BarChart from './BarChart/BarChart';

const CHART_NAME = 'Cost to Bring Employees to Min';

export default class JobDetailsSlideOutCharts extends Component {
  static propTypes = {
    job: object,
    jobRangeModel: object,
    payType: string,
    roundTo: number,
    summaryStats: object
  };

  render() {
    const { job, jobRangeModel, payType, summaryStats, preferences } = this.props;

    return (
      <div className="jbr_job-details-charts-container">
        <div className="chart-box">
          <span className="name">Range Spread</span>
          <RangeChartList
            instance={2}
            model={jobRangeModel}
            modelJobs={job ? [job] : []}
            payType={payType}
            roundTo={1}
            instance={2}
            width={400}
            preferences={preferences}
          />
        </div>
        <div className="chart-box">
          <span className="name">{CHART_NAME}</span>
          <BarChart summaryStats={summaryStats} payType={payType} preferences={preferences} />
        </div>
      </div>
    );
  }
}
