import { mapDispatchToActions } from '../../../../../panther-react/src/helpers/utils.js';

const initialState = {
  loading: false,
  loaded: false,
  error: null,
  compPhilosophy: ''
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case 'GET_COMP_PHILOSOPHY':
      return {
        ...state,
        loading: true,
        loaded: false
      };

    case 'GET_COMP_PHILOSOPHY_SUCCESS':
      let compPhilosophy = '';
      if (action.result) {
        compPhilosophy = action.result.comp_philosophy;
      }

      return {
        ...state,
        loading: false,
        loaded: true,
        compPhilosophy: compPhilosophy,
        compIsDirty: false
      };

    case 'GET_COMP_PHILOSOPHY_FAIL':
      return {
        ...state,
        loading: false,
        loaded: true,
        error: action.result
      };

    case 'SET_COMP_PHILOSOPHY_DIRTY_STATE':
      return {
        ...state,
        compIsDirty: action.compIsDirty,
        changesToSave: action.changesToSave.compPhilosophy
      };

    case 'RESET_COMP_PHILOSOPHY':
      let originalValues = '';
      if (action.originalValues) {
        originalValues = action.originalValues.compPhilosophy;
      }

      return {
        ...state,
        compPhilosophy: originalValues,
        compIsDirty: false,
        changesToSave: {}
      };

    case 'SAVE_COMP_PHILOSOPHY':
      return {
        ...state,
        loading: true,
        loaded: false
      };

    case 'SAVE_COMP_PHILOSOPHY_SUCCESS':
      return {
        ...state,
        loading: false,
        loaded: true,
        compIsDirty: false
      };

    case 'SAVE_COMP_PHILOSOPHY_FAIL':
      return {
        ...state,
        loading: false,
        loaded: true,
        compIsDirty: false
      };

    case 'CLOSE_ERROR_MODAL':
      return {
        ...state,
        showErrorModal: false
      };

    default:
      return { ...state };
  }
}

export const actions = {};

actions.getCompPhilosophy = function getSites(subsite) {
  const params = { url: subsite };

  return (dispatch, getState) => {
    dispatch({
      types: ['GET_COMP_PHILOSOPHY', 'GET_COMP_PHILOSOPHY_SUCCESS', 'GET_COMP_PHILOSOPHY_FAIL'],
      promise: client => client.get('/api/subsites/sites/getCompPhilosophy', { params: params })
    });
  };
};

actions.saveCompPhilosophy = function savePerformanceRatings(options) {
  return (dispatch, getState) => {
    const { app, compPhilosophy } = getState();
    const subsite = app.subsite;

    const res = dispatch({
      types: ['SAVE_COMP_PHILOSOPHY', 'SAVE_COMP_PHILOSOPHY_SUCCESS', 'SAVE_COMP_PHILOSOPHY_FAIL'],
      promise: client =>
        client.get('/api/subsites/sites/saveCompPhilosophy', {
          params: {
            compPhilosophy: compPhilosophy.changesToSave,
            url: subsite
          }
        })
    });
    if (res) {
      res
        .then(() => dispatch(actions.getCompPhilosophy(subsite)))
        .catch(err => {
          console.error('Error saving comp philosophy', err);
        });
    }
  };
};

actions.setDirtyState = function setDirtySate(compIsDirty, changesToSave) {
  return (dispatch, getState) => {
    dispatch({
      type: 'SET_COMP_PHILOSOPHY_DIRTY_STATE',
      compIsDirty,
      changesToSave
    });
  };
};

actions.resetCompPhilosophy = function resetCompPhilosophy(originalValues) {
  return (dispatch, getState) => {
    dispatch({
      type: 'RESET_COMP_PHILOSOPHY',
      originalValues
    });
  };
};

actions.closeErrorModal = function closeErrorModal(rowInfo) {
  return {
    type: 'CLOSE_ERROR_MODAL'
  };
};

export const compPhilosophyActions = mapDispatchToActions(actions);
