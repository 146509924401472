import { redirectToPdf } from '../../../helpers/utils';
import {
  GET_EMPLOYEE_PDF_SETTINGS,
  GET_EMPLOYEE_PDF_SETTINGS_FAIL,
  GET_EMPLOYEE_PDF_SETTINGS_SUCCESS,
  EMPLOYEE_PAY_HISTORY,
  EMPLOYEE_PAY_HISTORY_SUCCESS,
  EMPLOYEE_PAY_HISTORY_FAILURE
} from './employeeActions';

const defaultEmployeePdfDisplaySettings = {
  baseCompensation: true,
  rangePenetration: true,
  totalCashCompensation: true,
  performanceRating: true,
  rangeDistribution: true,
  marketTrends: true,
  marketSnapshot: true
};

const initialState = {
  loading: false,
  loaded: false,
  chartsLoading: false,
  error: null,
  details: {},
  employeeIdList: [],
  chartsFailedToLoad: false,
  loadingEEId: null,
  payRangeChart: null,
  gradeChart: null,
  rangeDistribution: null,
  printPageDownloading: false,
  employeeJobDescription: {},
  employeePdfDisplaySettings: {},
  employeePayHistory: null
};

const EMPLOYEE_DOWNLOAD_SUCCESS = 'EMPLOYEE_DOWNLOAD_SUCCESS';
const EMPLOYEE_DOWNLOAD_FAIL = 'EMPLOYEE_DOWNLOAD_FAIL';
const EMPLOYEE_DOWNLOAD = 'EMPLOYEE_DOWNLOAD';
const EMPLOYEE_JOB_TASKS_SUCCESS = 'EMPLOYEE_JOB_TASKS_SUCCESS';
const EMPLOYEE_JOB_TASKS_FAILURE = 'EMPLOYEE_JOB_TASKS_FAILURE';
const EMPLOYEE_JOB_TASKS = 'EMPLOYEE_JOB_TASKS';
const SET_PDF_DISPLAY_SETTINGS = 'SET_PDF_DISPLAY_SETTINGS';
const SET_PDF_DISPLAY_SETTINGS_FAILURE = 'SET_PDF_DISPLAY_SETTINGS_FAILURE';
const SET_PDF_DISPLAY_SETTINGS_SUCCESS = 'SET_PDF_DISPLAY_SETTINGS_SUCCESS';

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case EMPLOYEE_DOWNLOAD:
      return {
        ...state,
        printPageDownloading: true
      };
    case EMPLOYEE_DOWNLOAD_SUCCESS:
      return {
        ...state,
        printPageDownloading: false
      };
    case EMPLOYEE_DOWNLOAD_FAIL:
      return {
        ...state,
        printPageDownloading: false
      };
    case 'EMPLOYEES_REMOVE_ALL_SELECTED':
      return {
        ...state,
        employeeIdList: []
      };
    case 'EMPLOYEE_CHARTS_LOAD':
      return {
        ...state,
        chartsLoading: true,
        chartsFailedToLoad: false,
        payRangeChart: null,
        gradeChart: null,
        loadingEEId: action.itemId
      };
    case 'EMPLOYEE_CHARTS_FAIL':
      return {
        ...state,
        error: action.result,
        chartsFailedToLoad: true
      };
    case 'EMPLOYEE_CHARTS_SUCCESS':
      return {
        ...state,
        payRangeChart: action.result.payRangeChart,
        gradeChart: action.result.gradeChart,
        jobIdList: [].concat(action.result.job_id),
        chartsLoading: false,
        chartsFailedToLoad: false
      };
    case 'EMPLOYEE_DETAILS':
      return {
        ...state,
        details: action.item ? { _id: action.item._id, ...action.item._source } : {},
        loading: true,
        payRangeChart: null
      };
    case 'EMPLOYEE_DETAILS_SUCCESS':
      return {
        ...state,
        loading: false,
        loaded: true,
        details: action.result,
        employeeIdList: action.result && action.result.employeeId ? [].concat(action.result.employeeId) : []
      };
    case 'EMPLOYEE_DETAILS_FAILURE':
      console.log('EE details fail error', action.result);
      return {
        ...state,
        loading: false,
        error: action.result
      };
    case 'EMPLOYEE_SALARY_HISTORY_CHART_SUCCESS':
      return {
        ...state,
        salaryHistoryChartJson: action.result.highChartsJson,
        data: action.result
      };
    case 'EMPLOYEE_RANGE_DISTRIBUTION':
      return {
        ...state
      };
    case 'EMPLOYEE_RANGE_DISTRIBUTION_SUCCESS':
      return {
        ...state,
        rangeDistribution: action.result.rangeDistribution
      };
    case 'EMPLOYEE_RANGE_DISTRIBUTION_FAIL':
      console.log('Employee range distribution failed: ', action.result);
      return {
        ...state,
        loading: false,
        error: action.result
      };
    case EMPLOYEE_JOB_TASKS_SUCCESS:
      return {
        ...state,
        employeeJobDescription: action.result.tasks
      };
    case EMPLOYEE_JOB_TASKS_FAILURE:
      return {
        ...state,
        employeeJobDescription: null
      };
    case EMPLOYEE_JOB_TASKS:
      return {
        ...state
      };
    case EMPLOYEE_PAY_HISTORY:
      return {
        ...state
      };
    case EMPLOYEE_PAY_HISTORY_SUCCESS:
      // Handle 200 with success false
      if (action.result.success === false) {
        return {
          ...state,
          loading: false,
          error: action.result.reason
        };
      }

      return {
        ...state,
        employeePayHistory: action.result.payHistory
      };
    case EMPLOYEE_PAY_HISTORY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.result
      };
    case GET_EMPLOYEE_PDF_SETTINGS:
      return {
        ...state
      };
    case GET_EMPLOYEE_PDF_SETTINGS_FAIL:
      return {
        ...state,
        employeePdfDisplaySettings: defaultEmployeePdfDisplaySettings
      };
    case GET_EMPLOYEE_PDF_SETTINGS_SUCCESS:
      return {
        ...state,
        employeePdfDisplaySettings: getCurrentSettings(action.result.pdf_settings)
      };
    case SET_PDF_DISPLAY_SETTINGS:
      return {
        ...state
      };
    case SET_PDF_DISPLAY_SETTINGS_FAILURE:
      return {
        ...state
      };
    case SET_PDF_DISPLAY_SETTINGS_SUCCESS:
      if (!action.result.field) {
        // Only set on before the call to the server and not after
        return {
          ...state
        };
      }
      return {
        ...state,
        employeePdfDisplaySettings: {
          ...state.employeePdfDisplaySettings,
          [action.result.field]: action.result.value
        }
      };
    default:
      return { ...state };
  }
}

const getCurrentSettings = settings => {
  return Object.keys(settings).length ? settings : defaultEmployeePdfDisplaySettings;
};

export function employeeDownload(options) {
  return (dispatch, getState) => {
    if (options.orgEmployeeId) {
      dispatch({
        types: [EMPLOYEE_DOWNLOAD, EMPLOYEE_DOWNLOAD_SUCCESS, EMPLOYEE_DOWNLOAD_FAIL],
        promise: client =>
          client
            .get('/api/util/report-print/printEmployee', {
              params: {
                org_employee_id: options.orgEmployeeId,
                download_type: 'pdf'
              }
            })
            .then(redirectToPdf)
      });
    }
  };
}

export function setEmployeePdfDisplaySettings(field, value) {
  return (dispatch, getState) => {
    const params = { field, value };
    dispatch(setPdfInState(params));
    dispatch({
      types: [SET_PDF_DISPLAY_SETTINGS, SET_PDF_DISPLAY_SETTINGS_SUCCESS, SET_PDF_DISPLAY_SETTINGS_FAILURE],
      promise: client =>
        client.post('/api/generic-user/users/setPdfSettings/', {
          params: { pdf_settings: getState().employeeDetails.employeePdfDisplaySettings }
        })
    });
  };
}

export function editEmployee(employeeDetails) {
  return (dispatch, getState) => {
    dispatch({
      type: 'EDIT_EMPLOYEE_LAUNCH',
      employeeDetails: employeeDetails.details
    });

    dispatch({
      type: 'HIDE_SCROLL_BAR'
    });
  };
}

const setPdfInState = values => {
  return {
    type: SET_PDF_DISPLAY_SETTINGS_SUCCESS,
    result: values
  };
};

export function getEmployeeDetails(itemId) {
  return (dispatch, getState) => {
    dispatch({
      types: ['EMPLOYEE_DETAILS', 'EMPLOYEE_DETAILS_SUCCESS', 'EMPLOYEE_DETAILS_FAILURE'],
      promise: client => client.get('/api/market-pricing/v1/employees/' + itemId)
    })
      .then(result => {
        dispatch({
          types: [
            'EMPLOYEE_RANGE_DISTRIBUTION',
            'EMPLOYEE_RANGE_DISTRIBUTION_SUCCESS',
            'EMPLOYEE_RANGE_DISTRIBUTION_FAIL'
          ],
          promise: client => client.get('/api/market-pricing/v1/rangeDistribution/')
        });

        dispatch({
          types: [GET_EMPLOYEE_PDF_SETTINGS, GET_EMPLOYEE_PDF_SETTINGS_SUCCESS, GET_EMPLOYEE_PDF_SETTINGS_FAIL],
          promise: client => client.get('/api/generic-user/users/getPdfSettings/')
        });

        const params = { params: { org_job_id: result.orgJobId, orgJobId: result.orgJobId } };
        dispatch({
          types: [EMPLOYEE_JOB_TASKS, EMPLOYEE_JOB_TASKS_SUCCESS, EMPLOYEE_JOB_TASKS_FAILURE],
          promise: client => client.get('/api/hris/job-details/getJobTasks', params)
        });

        dispatch({
          types: [EMPLOYEE_PAY_HISTORY, EMPLOYEE_PAY_HISTORY_SUCCESS, EMPLOYEE_PAY_HISTORY_FAILURE],
          promise: client =>
            client.get('/api/hris/employee-details/payHistory', {
              params: { org_employee_id: itemId }
            })
        });
      })
      .catch(err => {
        console.error('Error getting employee details', err);
      });
  };
}
