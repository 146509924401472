import apiClient from 'ps-components/src/lib/apiClient';
import { loadFeatureFlags } from 'ps-components/src/FeatureFlags/FeatureFlagActions';

// get sessionData, handle known conditions, and throw error for unknown conditions
const sessionDispatch = async ({ store, logout }) => {
  const { dispatch } = store;
  dispatch({ type: 'GET_APP_DATA' });

  try {
    const result = await apiClient.apiGet('/api/util/session-data/sessionData');
    if (!result) {
      return;
    }

    const app = result.data.app;

    try {
      await loadFeatureFlags(store.dispatch, app.masterAccountId);
    } catch (error) {
      dispatch({ type: 'FATAL_ERROR', reason: 'Cannot load feature flags', error });
      throw error;
    }

    // If we got this far without throwing an error, the session is initialized
    dispatch({ type: 'GET_APP_DATA_SUCCESS', result: { app } });
  } catch (error) {
    if (!error.response) {
      dispatch({ type: 'GET_APP_DATA_FAIL' });
      throw error;
    }

    const responseCode = error.response.status;
    const reason = `Received response code ${responseCode} while fetching session data.`;
    console.warn(reason);

    if (responseCode === 401) {
      await logout({ message: 'Authentication required before loading session data', redirect: true });
    } else if (responseCode === 403) {
      // TODO get server to correctly differentiate 401 from 403 and display error here
      await logout({ message: 'Access forbidden while attempting to load session data', redirect: true });
    } else {
      dispatch({ type: 'GET_APP_DATA_FAIL' });
      dispatch({ type: 'FATAL_ERROR', reason, error });
      console.warn('sessionDispatch error', error);
      throw error;
    }
  }
};

export default sessionDispatch;
