import { getReportData } from '../../../../../ps-components/src/Modules/AddJob/reducers/addJob';
import apiClient from '../../../lib/apiClient';
import { JOB_DETAILS_SINGLE_START_LOAD } from './jobDetails';

export function loadDetailsPanel(itemId, item) {
  return async dispatch => {
    const url = '/api/hris/job-detail-charts/jobRanges';
    const params = buildParams(itemId);

    // Set loading job in reducer so we know exactly and immediately what job we're loading
    dispatch({
      type: JOB_DETAILS_SINGLE_START_LOAD,
      id: itemId
    });

    try {
      dispatch({ type: 'JOB_RANGE_CHART_LOAD', itemId });
      const result = await apiClient.apiGet(url, params);

      dispatch({
        type: 'JOB_RANGE_CHART_SUCCESS',
        result: result.data,
        itemId
      });
    } catch (error) {
      dispatch({
        type: 'JOB_RANGE_CHART_FAIL',
        error,
        itemId
      });
    }

    if ([].concat(itemId).length === 1) {
      try {
        dispatch({ type: 'FETCH_SURVEYS', itemId });
        const result = await apiClient.apiGet('/api/market-pricing/job-matches/getSurveyJobs', params);

        dispatch({
          type: 'FETCH_SURVEYS_SUCCESS',
          result: result.data,
          itemId
        });

        const companySourcedCompaniesByJob = [];
        dispatch({ type: 'GET_COMPANY_SOURCED_COMPANIES_BY_JOB_SUCCESS', companySourcedCompaniesByJob, itemId });
        if (result && result.data.surveyJobMatches) {
          result.data.surveyJobMatches.forEach(survey => {
            if (
              survey.pub_survey_publisher === 'PayScale Company Sourced' &&
              (!survey.approved_status || survey.approved_status.toLowerCase() !== 'suggested') &&
              survey.match_id > 0 &&
              survey.source_map_id
            ) {
              getCompanySourcedCompanies(survey.source_map_id, itemId);
            }
          });
        }
      } catch (error) {
        dispatch({
          type: 'FETCH_SURVEYS_FAILURE',
          error,
          itemId
        });
      }
    }

    try {
      dispatch({ type: 'FETCH_COMPSETS', itemId });
      const result = await apiClient.apiPost('/api/hris/competitive-sets/list', { params: {} });
      dispatch({
        type: 'FETCH_COMPSETS_SUCCESS',
        result: result.data,
        itemId
      });
    } catch (error) {
      dispatch({
        type: 'FETCH_COMPSETS_FAILURE',
        error,
        itemId
      });
    }

    try {
      dispatch({ type: 'JOB_DETAILS', item, itemId });
      const result = await apiClient.apiGet('/api/hris/job-details/getJobDetails', params);
      dispatch({
        type: 'JOB_DETAILS_SUCCESS',
        result: result.data,
        item,
        itemId
      });

      const baseSalary50th = result && result.data.market_base_salary_50th ? result.data.market_base_salary_50th : 0;
      dispatch({ type: 'GET_MARKET_SALARY_SUCCESS', market_base_salary_50th: baseSalary50th, itemId });
      if (result.data && result.data.payscale_job_info) {
        const reportData = getReportData({
          jobDetails: result.data.payscale_job_info,
          workLocation: result.data.payscale_job_info.workLocationExact
        });
        const reportType = ['companiesByJobReport', 'dataSummaryReport'];
        getCrowdSourcedSurveyData(reportData, reportType, itemId);
      }
    } catch (error) {
      dispatch({
        type: 'JOB_DETAILS_FAILURE',
        error,
        item,
        itemId
      });
    }

    getManagerReviewStatus(params, itemId, item);
  };
}

export function getManagerReviewStatus(params, itemId, item) {
  return async dispatch => {
    try {
      dispatch({ type: 'MANAGER_REVIEW_STATUS', item, itemId });
      const result = await apiClient.apiGet('/api/job-collab/jobReviewWorkflow/getReviewFromJobId', params);
      dispatch({
        type: 'MANAGER_REVIEW_STATUS_SUCCESS',
        result: result.data,
        item,
        itemId
      });
    } catch (error) {
      dispatch({
        type: 'MANAGER_REVIEW_STATUS_FAIL',
        error,
        item,
        itemId
      });
    }
  };
}

export function getCrowdSourcedSurveyData(reportData, reportType, itemId) {
  return async dispatch => {
    try {
      dispatch({ type: 'GET_CROWD_SOURCED_SURVEY_DATA', itemId });
      const result = await apiClient.apiGet('/api/hris/jobJobalyzerReports/createRetrieveReports', {
        params: {
          reportData: JSON.stringify(reportData.reportData),
          reportTypes: JSON.stringify(reportType)
        }
      });
      dispatch({
        type: 'GET_CROWD_SOURCED_SURVEY_DATA_SUCCESS',
        result: result.data,
        itemId
      });
    } catch (error) {
      dispatch({
        type: 'GET_CROWD_SOURCED_SURVEY_DATA_FAIL',
        error,
        itemId
      });
    }
  };
}

export function getCompanySourcedCompanies(sourceMapId, itemId) {
  return async dispatch => {
    try {
      dispatch({ type: 'GET_COMPANY_SOURCED_COMPANIES_BY_JOB', itemId });
      const result = await apiClient.apiGet('/api/hris/surveys/getParticipants', {
        params: { source_map_pk: sourceMapId }
      });

      dispatch({
        type: 'GET_COMPANY_SOURCED_COMPANIES_BY_JOB_SUCCESS',
        result: result.data,
        itemId
      });
    } catch (error) {
      dispatch({
        type: 'GET_COMPANY_SOURCED_COMPANIES_BY_JOB_FAIL',
        error,
        itemId
      });
    }
  };
}

export function buildParams(itemId) {
  return { params: { org_job_id: itemId, orgJobId: itemId } };
}

export function loadComparisonPanel(dispatch, getStore, selectedList) {
  return async dispatch => {
    try {
      const chartUrl = '/api/hris/job-detail-charts/jobRanges';
      const params = { params: { org_job_id: selectedList, currency: window.app.default_currency } };

      dispatch({ type: 'JOB_COMPARISON_RANGE_CHART_LOAD', selectedList });
      const result = await apiClient.apiGet(chartUrl, params);

      dispatch({
        type: 'JOB_COMPARISON_RANGE_CHART_SUCCESS',
        result: result.data,
        selectedList
      });
    } catch (error) {
      dispatch({
        type: 'JOB_COMPARISON_RANGE_CHART_FAIL',
        error,
        selectedList
      });
    }

    try {
      dispatch({ type: 'COMPARE', selectedList });
      // This had an undefined variable here, so let's just drop it
      //const result = await apiClient.apiGet('/api/hris/job-details/jobCompare', params);
      const result = await apiClient.apiGet('/api/hris/job-details/jobCompare');

      dispatch({
        type: 'COMPARE_SUCCESS',
        result: result.data,
        selectedList
      });
    } catch (error) {
      dispatch({
        type: 'COMPARE_FAIL',
        error,
        selectedList
      });
    }
  };
}

export function markDirtyJob(getStore, jobId) {
  return async dispatch => {
    let singleJobId = jobId;
    if (typeof jobId == 'object') {
      singleJobId = jobId[0];
    }

    let dirtyJobList = []; // eslint-disable-line prefer-const
    if (getStore.jobsList && getStore.jobsList.dirtyJobs) {
      dirtyJobList = getStore.jobsList.dirtyJobs;
      const index = dirtyJobList.indexOf(singleJobId);
      if (index < 0) {
        dirtyJobList.push(singleJobId);
      }
    } else {
      dirtyJobList.push(singleJobId);
    }
    dispatch({
      type: 'JOBS_MARK_DIRTY',
      dirtyJobList: dirtyJobList
    });
  };
}

export function getAuditHistory(jobId) {
  return async dispatch => {
    try {
      dispatch({ type: 'JOB_AUDIT_TRAIL' });
      const result = await apiClient.apiGet('/api/hris/audit-trail/audit-trail/getAuditHistory', {
        params: { orgJobId: jobId }
      });

      dispatch({
        type: 'JOB_AUDIT_TRAIL_SUCCESS',
        result: result.data
      });
    } catch (error) {
      dispatch({
        type: 'JOB_AUDIT_TRAIL_FAIL',
        error
      });
    }
  };
}
