import React from 'react';
import './NavigateStepButton.scss';
import { string, bool, func } from 'prop-types';

const propTypes = {
  className: string,
  stepName: string.isRequired,
  isNext: bool.isRequired,
  onClick: func.isRequired
};

const NavigateStepButton = props => {
  const directionText = props.isNext ? 'Next' : 'Previous';
  const modifierClass = props.isNext ? ' reverse' : '';
  const propsClass = props.className ? ` ${props.className}` : '';
  const iconClass = props.isNext ? 'icon-right' : 'icon-left';
  const className = `pcenter-match-review-navigate-step${modifierClass}${propsClass}`;

  return (
    <div className={className}>
      <button className="pcenter-match-review-navigate-step__action-button icon-border" onClick={props.onClick}>
        <i className={`pcenter-match-review-navigate-step__action-icon ${iconClass}`} />
      </button>
      <div className="pcenter-match-review-navigate-step__text-content">
        <div className="pcenter-match-review-navigate-step__top-label">{directionText}</div>
        <div className="pcenter-match-review-navigate-step__bottom-label">{props.stepName}</div>
      </div>
    </div>
  );
};

NavigateStepButton.propTypes = propTypes;
export default NavigateStepButton;
