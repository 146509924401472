import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classnames from 'classnames';
import isIE from '../../Utils/checkForIE';

import './InPlaceIndicator.scss';

export default class InPlaceIndicator extends Component {
  static propTypes = {
    className: PropTypes.string
  };

  loader() {
    return (
      <div className={classnames('inplace__indicator', this.props.className)}>
        <svg className="inplace__indicator--circle" viewBox="25 25 50 50">
          <circle className="circle--path" cx="50" cy="50" r="20" fill="none" strokeWidth="2" strokeMiterlimit="10" />
        </svg>
      </div>
    );
  }

  // IE compatible loader
  ieLoader() {
    return (
      <div className="div__container--loadingIndicator">
        <div className="loading__indicator">
          <div className="loading__indicator-wrapper dot-1">
            <div className="loading__indicator-dot" />
          </div>
          <div className="loading__indicator-wrapper dot-2">
            <div className="loading__indicator-dot" />
          </div>
          <div className="loading__indicator-wrapper dot-3">
            <div className="loading__indicator-dot" />
          </div>
          <div className="loading__indicator-wrapper dot-4">
            <div className="loading__indicator-dot" />
          </div>
          <div className="loading__indicator-wrapper dot-5">
            <div className="loading__indicator-dot" />
          </div>
        </div>
      </div>
    );
  }

  render() {
    if (isIE()) {
      return this.ieLoader();
    }

    return this.loader();
  }
}
