import * as DataCutUtils from '../../../helpers/DataCutUtils';
import { getJobSurveyCompanyMatchDataCuts } from '../../../../../ps-components/src/Modules/AddJob/reducers/jobDetails';
import { getUpdatedDataCuts } from '../../../../../ps-components/src/Modules/AddJob/reducers/jobSurveyList';
import { loadDefaultDataCuts } from './surveyActions';
import { actions as jobSurveyListActions } from '../../../../../ps-components/src/Modules/AddJob/reducers/jobSurveyList';
const RESET_SCROLL = 'RESET_SCROLL';

const initialState = {
  loading: false,
  loaded: false,
  canLoadGrade: false,
  error: null,
  details: {},
  cuts: {
    items: [],
    loading: false
  },
  surveyIdList: []
};

function updateDataCutItems(state, action) {
  if (!action.dataCut) return [...state.cuts.items];
  const arr = state.cuts.items.map(cut => {
    if (cut === action.dataCut) {
      return { ...cut, job_scope_checked: action.checked };
    }
    return { ...cut };
  });
  return [...arr];
}

function filterDataCutItems(state, query) {
  return DataCutUtils.filterDataCuts(state.cuts.items, query);
}

export default function reducer(state = initialState, action = {}) {
  let newState;
  switch (action.type) {
    case 'SURVEYS_REMOVE_ALL_SELECTED':
      return {
        ...state,
        surveyIdList: []
      };
    case 'SURVEY_PARTICIPANTS':
      return {
        ...state,
        participants: action.item ? { _id: action.item._id, ...action.item._source } : {},
        loading: true
      };
    case 'SURVEY_PARTICIPANTS_SUCCESS':
      return {
        ...state,
        loading: false,
        loaded: true,
        participants: action.result
      };
    case 'SURVEY_PARTICIPANTS_FAILURE':
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.result
      };
    case 'SURVEY_DEFAULT_CUTS_FILTER':
      return {
        ...state,
        cuts: {
          ...state.cuts,
          query: action.query,
          loading: false,
          items: filterDataCutItems(state, action.query)
        }
      };
    case 'SURVEY_DEFAULT_CUTS':
    case 'SURVEY_BULK_UPDATE_DEFAULT_CUTS':
      return {
        ...state,
        cuts: {
          ...state.cuts,
          loading: true,
          items: updateDataCutItems(state, action)
        }
      };
    case 'SURVEY_BULK_UPDATE_DEFAULT_CUTS_SUCCESS':
      newState = {
        ...state,
        cuts: {
          ...state.cuts,
          loading: false,
          items: action.result.newDefaultCuts
        }
      };
      filterDataCutItems(newState, state.cuts.query);
      return newState;
    case 'SURVEY_DEFAULT_CUTS_SUCCESS':
      newState = {
        ...state,
        cuts: {
          ...state.cuts,
          loading: false,
          items: action.result.success === false ? state.cuts.items : action.result
        }
      };

      filterDataCutItems(newState, state.cuts.query);
      return newState;
    case 'SURVEY_DEFAULT_CUTS_FAILURE':
    case 'SURVEY_BULK_UPDATE_DEFAULT_CUTS_FAILURE':
      return {
        ...state,
        cuts: {
          ...state.cuts,
          loading: false,
          error: action.result
        }
      };
    case 'SURVEY_DETAILS':
      return {
        ...state,
        details: action.item ? { _id: action.item._id, ...action.item._source } : {},
        surveyIdList: [action.itemId],
        loading: true
      };
    case 'SURVEY_DETAILS_SUCCESS':
      return {
        ...state,
        loading: false,
        loaded: true,
        survey_details: action.result
      };
    case 'SURVEY_DETAILS_FAILURE':
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.result
      };
    default:
      return { ...state };
  }
}

const actions = {};

actions.resetScroll = function resetScroll(dispatch, getState) {
  return { type: RESET_SCROLL };
};

actions.addDefaultCut = function addDefaultCut(dataCut, itemId) {
  return {
    types: ['SURVEY_DEFAULT_CUTS', 'SURVEY_DEFAULT_CUTS_SUCCESS', 'SURVEY_DEFAULT_CUTS_FAIL'],
    promise: client =>
      client.post('/api/hris/surveys/addDefaultCut', {
        params: { source_map_pk: itemId, dataCut }
      }),
    itemId,
    dataCut,
    checked: true
  };
};

actions.removeDefaultCut = function removeDefaultCut(dataCut, itemId) {
  return {
    types: ['SURVEY_DEFAULT_CUTS', 'SURVEY_DEFAULT_CUTS_SUCCESS', 'SURVEY_DEFAULT_CUTS_FAIL'],
    promise: client =>
      client.post('/api/hris/surveys/removeDefaultCut', {
        params: { source_map_pk: itemId, dataCut }
      }),
    itemId,
    dataCut,
    checked: false
  };
};

actions.updateDefaultCuts = (
  sourceMapId,
  surveyCode,
  addedDataCuts,
  removedDataCuts,
  jobCode,
  esSearchQuery,
  jobId
) => (dispatch, getState) => {
  const updateRequest = dispatch({
    types: [
      'SURVEY_BULK_UPDATE_DEFAULT_CUTS',
      'SURVEY_BULK_UPDATE_DEFAULT_CUTS_SUCCESS',
      'SURVEY_BULK_UPDATE_DEFAULT_CUTS_FAIL'
    ],
    promise: client =>
      client.post('/api/hris/surveys/updateDefaultCuts', {
        params: { sourceMapPK: sourceMapId, surveyCode, addedDataCuts, removedDataCuts }
      })
  });
  // once update is completed, then refresh the job survey company match data cuts
  updateRequest
    .then(() => {
      const jobSurveyCompanyDispatch = getJobSurveyCompanyMatchDataCuts(getState);
      if (jobSurveyCompanyDispatch) {
        dispatch(jobSurveyCompanyDispatch);
      }
      dispatch(getUpdatedDataCuts(surveyCode, jobCode));
      dispatch(loadDefaultDataCuts(sourceMapId));
      dispatch(jobSurveyListActions('getSurveyJobs', jobId));

      if (esSearchQuery) {
        dispatch(jobSurveyListActions('surveySearch', esSearchQuery, jobId));
      }
    })
    .catch(err => {
      console.error('Error updating default cuts', err);
    });
};

actions.filterDefaultCuts = function filterDefaultCuts(query) {
  return {
    type: 'SURVEY_DEFAULT_CUTS_FILTER',
    query: query
  };
};

function wrapActions(actionsObj, dispatch, getState) {
  const newActions = {};
  Object.keys(actionsObj).forEach(actionName => {
    newActions[actionName] = (...args) => {
      const result = actionsObj[actionName](...args);
      if (typeof result === 'function') {
        result(dispatch, getState);
      } else {
        dispatch(result);
      }
    };
  });
  return newActions;
}

export function getActions() {
  return (dispatch, getState) => {
    return wrapActions(actions, dispatch, getState);
  };
}
