import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classnames from 'classnames';
import './JobRangesSlideOutContent.scss';
import { numberWithCommas } from '../../lib/formatters';
import { SUPPORTED_MAX_BULK_JOB_SELECTION_COUNT } from './Rules';
import FacetPanel from '../../FacetPanel/FacetPanel';
import { jobRangesSearchActions } from './reducers/jobRanges/addJobsToModel/jobRangesSearch';
import {
  jobClick,
  doBulkClearJobs,
  doCheckAllAvailableJobs,
  doCheckAllSelectedJobs,
  doShowMoreSelectedJobs,
  addJobsListToggle,
  applyJobsToRange,
  onJobSearchChange
} from './actions/JobRanges';
import ListPanel from '../../ListPanel/ListPanel';
import SearchBarMP from '../../SearchBarMP/SearchBarMP';
import { FormGroup, Segmented } from '@payscale/design';
import JobRangesRemoveJobsModal from './RemoveJobsModal/RemoveJobsModal';
import { getUseJobCodeKey, getReplacementTokens, isLab } from '../../redux/stateSelectors';
import SlideoutHeader from '../../Slideout/Header/SlideoutHeader';
import ProgressStatusOverlay from '../../ProgressStatus/ProgressStatusOverlay';
import getHiddenFacets from '../../FacetPanel/getHiddenFacets';

const propTypes = {
  maxNumberOfSelectedJobs: PropTypes.number,
  hideSlideout: PropTypes.func,
  jobRangesSearch: PropTypes.object,
  jobClick: PropTypes.func,
  addJobsList: PropTypes.object,
  doCheckAllAvailableJobs: PropTypes.func,
  doCheckAllSelectedJobs: PropTypes.func,
  doSearchAndBulkAddJobs: PropTypes.func,
  doBulkClearJobs: PropTypes.func,
  doShowMoreSelectedJobs: PropTypes.func,
  isAllAvailableJobsChecked: PropTypes.bool,
  isAllSelectJobsChecked: PropTypes.bool,
  selectedJobsViewScopeLimit: PropTypes.number,
  addJobsListToggle: PropTypes.func,
  applyJobsToRange: PropTypes.func,
  jobRangeModel: PropTypes.object,
  searchInputSearch: PropTypes.func,
  useJobCodeKey: PropTypes.bool,
  employeeIdProp: PropTypes.string,
  moreResults: PropTypes.func,
  search: PropTypes.func,
  replacementTokens: PropTypes.array,
  isLab: PropTypes.bool,
  onJobSearchChange: PropTypes.func
};

const defaultProps = {
  employeeIdProp: 'id',
  maxNumberOfSelectedJobs: SUPPORTED_MAX_BULK_JOB_SELECTION_COUNT
};

class JobRangesSlideOutContent extends Component {
  constructor(props) {
    super(props);
    this.state = { isVisibleRemoveJobsModal: false };
    this.removeAllSelectedJobs = this.removeAllSelectedJobs.bind(this);
    this.showMassDeselectConfirmModal = this.showMassDeselectConfirmModal.bind(this);
    this.hideMassDeselectConfirmModal = this.hideMassDeselectConfirmModal.bind(this);
    this.renderConfirmModal = this.renderConfirmModal.bind(this);
    this.renderBulkActionContent = this.renderBulkActionContent.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.areAllFacetsChecked = this.areAllFacetsChecked.bind(this);
  }

  removeAllSelectedJobs() {
    this.props.doCheckAllSelectedJobs({ target: { checked: false } });
    this.hideMassDeselectConfirmModal();
  }

  showMassDeselectConfirmModal() {
    this.setState({ isVisibleRemoveJobsModal: true });
  }

  hideMassDeselectConfirmModal() {
    this.setState({ isVisibleRemoveJobsModal: false });
  }

  renderConfirmModal() {
    const { name } = this.props.jobRangeModel;
    return (
      <JobRangesRemoveJobsModal
        visible={true}
        modelName={name}
        onConfirm={this.removeAllSelectedJobs}
        onCancel={this.hideMassDeselectConfirmModal}
      />
    );
  }

  renderBulkActionContent(bulkAddInProgress, bulkAddSucceeded, showAllJobsVsSelected, allJobsCount, selectedJobsCount) {
    const { maxNumberOfSelectedJobs, doSearchAndBulkAddJobs } = this.props;

    const { filteredAllJobsPreviouslyAdded } = this.state;
    if (bulkAddInProgress) {
      return (
        <a className="jbr-slide-out-content__select-all-jobs-progress">
          <i className="icon-spin icon-animate-spin" />
          {`Selecting All ${numberWithCommas(allJobsCount)} Jobs...`}
        </a>
      );
    } else if (showAllJobsVsSelected && !bulkAddSucceeded && allJobsCount < maxNumberOfSelectedJobs) {
      return (
        <a className="jbr-slide-out-content__select-all-jobs-btn" onClick={doSearchAndBulkAddJobs}>
          {`Select All ${numberWithCommas(allJobsCount)} Jobs`}
        </a>
      );
    } else if (!showAllJobsVsSelected && selectedJobsCount > 0) {
      return (
        <a className="jbr-slide-out-content__select-all-jobs-btn" onClick={this.showMassDeselectConfirmModal}>
          Clear All Selections
        </a>
      );
    } else {
      return null;
    }
  }

  handleReset() {
    const { search, jobRangeModel, jobRangesSearch } = this.props;
    const { currencyId } = jobRangeModel;
    const currencyName = (window.app.currencies.find(currency => currency.id === jobRangeModel.currencyId) || {}).code;

    search({
      params: {
        es_query: '',
        pageName: jobRangesSearch.name,
        currency: currencyName,
        // WIP: Include/Exclude can be taken out?
        include: currencyId ? { currency: currencyName } : {},
        exclude: {}
      },
      clear: true
    });
  }

  areAllFacetsChecked() {
    const { facets, facetSelection } = this.props.jobRangesSearch;
    let allFacetsAreChecked = true;

    for (let idx = 0; idx < facets.length; idx++) {
      for (let itemidx = 0; itemidx < facets[idx].items.length; itemidx++) {
        const facetItem = facets[idx].items[itemidx];

        if (
          !facetSelection[facets[idx].id] ||
          !facetSelection[facets[idx].id][facetItem.key] ||
          (facets[idx].value && (facets[idx].value[0] !== facets[idx].min || facets[idx].value[1] !== facets[idx].max))
        ) {
          allFacetsAreChecked = false;
        }
      }
    }

    return !allFacetsAreChecked;
  }

  render() {
    const { props } = this;

    const {
      hideSlideout,
      jobRangesSearch,
      jobClick: jobItemClick,
      addJobsList,
      doCheckAllAvailableJobs,
      doCheckAllSelectedJobs,
      doShowMoreSelectedJobs,
      addJobsListToggle,
      applyJobsToRange,
      jobRangeModel,
      useJobCodeKey,
      ...restProps
    } = props;

    const {
      showAllJobsVsSelected,
      isAllAvailableJobsChecked,
      isAllSelectJobsChecked,
      selectedJobsViewScopeLimit,
      bulkAddInProgress,
      bulkAddSucceeded
    } = addJobsList;

    let listPanelItems, doCheckAll, isAllChecked;
    if (showAllJobsVsSelected) {
      listPanelItems = jobRangesSearch.items;
      doCheckAll = doCheckAllAvailableJobs;
      isAllChecked = isAllAvailableJobsChecked;
    } else {
      listPanelItems = addJobsList.selectedJobs.slice(0, selectedJobsViewScopeLimit);
      doCheckAll = listPanelItems.length > 0 ? this.showMassDeselectConfirmModal : doCheckAllSelectedJobs;
      isAllChecked = isAllSelectJobsChecked;
    }

    let showMoreButton;
    if (listPanelItems && listPanelItems.length < jobRangesSearch.searchResultsCount) {
      if (showAllJobsVsSelected) {
        //  CASE #1: 'All Jobs" is being viewed
        //  TODO: Clean up the onClick listener
        showMoreButton = (
          <a className="show-more-jobs__button" onClick={() => props.moreResults({ marketPay: true })}>
            Show More
          </a>
        );
      } else if (addJobsList.selectedJobs.length > selectedJobsViewScopeLimit) {
        //  CASE #2: 'Selected Jobs' is being viewed
        showMoreButton = (
          <a className="show-more-jobs__button" onClick={doShowMoreSelectedJobs}>
            Show More
          </a>
        );
      }
    }

    let allJobsCount = 0;
    if (jobRangesSearch && jobRangesSearch.searchResultsCount) allJobsCount = jobRangesSearch.searchResultsCount;

    let selectedJobsCount = 0;
    if (addJobsList && addJobsList.selectedJobs) selectedJobsCount = addJobsList.selectedJobs.length;

    let applyButtonStyle = 'pxl-btn pxl-btn-primary add-jobs-to-range-btn';
    if (bulkAddInProgress) {
      applyButtonStyle = `${applyButtonStyle} add-jobs-to-range-btn--disabled disabled`;
    }
    const orgJobCodeKey = this.props.replacementTokens.find(value => value.token === 'job_code_key_text');
    const orgJobCodeKeyLabel = orgJobCodeKey && orgJobCodeKey.value ? orgJobCodeKey.value : 'Job Code Key';
    addJobsList.columns.map(column => {
      if (column.dataKey === 'org_job_code_key') {
        column.title = orgJobCodeKeyLabel;
      }
      return column;
    });

    const facetPanelClass = classnames({
      jobRanges__facetPanel: true,
      lab: this.props.isLab
    });

    const showResetButton =
      this.areAllFacetsChecked() ||
      (jobRangesSearch.lastQueryParams && jobRangesSearch.lastQueryParams.es_query !== '');

    return (
      <div className="jobRangesSlideOut" data-test-id="jbr-job-list-slideout">
        <SlideoutHeader
          title={jobRangeModel && jobRangeModel.name}
          subtitle="Select Jobs to Add to this Model"
          additionalButtons={
            <button
              className={applyButtonStyle}
              onClick={bulkAddInProgress ? null : applyJobsToRange}
              data-test-id="jbr-apply-jobs-to-model-button"
              disabled={bulkAddInProgress}
            >
              Apply Jobs to Model
            </button>
          }
          doClose={hideSlideout}
          testIdCloseButton="jbr-close-job-list-button"
        />

        <div className="contents__main">
          <ProgressStatusOverlay inProgress={addJobsList.applyJobsLoading} primaryText="Applying jobs to model..." />
          <FacetPanel
            className={facetPanelClass}
            facetItemClick={restProps.facetItemClick}
            facetReset={restProps.facetReset}
            facets={jobRangesSearch.facets}
            facetSelection={jobRangesSearch.facetSelection}
            hiddenFacets={getHiddenFacets('jobRangesSlideOut', useJobCodeKey, jobRangeModel?.currencyId)}
            isDisabled={!showAllJobsVsSelected}
            isLoaded={jobRangesSearch.loaded}
            lastFacetFilteredID={jobRangesSearch.lastFacetFilteredID}
            limit={5}
            resetSearch={this.handleReset}
            showFacetSectionSearch={!this.props.isLab}
            showResetButton={showResetButton}
            showZeros={jobRangesSearch.showZeros}
          />
          <div className="chooseJobsPanel" data-test-id="jbr-modify-jobs-panel">
            <FormGroup id="jbr-modify-jobs-form-group">
              <div className="jbr-slide-out-content__jobs-list-tab-header">
                <Segmented
                  className="pxl-segmented-secondary listToggle"
                  value={showAllJobsVsSelected}
                  id="segmentedId"
                  onChange={addJobsListToggle}
                  options={[
                    {
                      text: `All Jobs (${numberWithCommas(allJobsCount)})`,
                      value: true
                    },
                    {
                      text: `Selected (${numberWithCommas(selectedJobsCount)})`,
                      value: false
                    }
                  ]}
                />
                {this.renderBulkActionContent(
                  bulkAddInProgress,
                  bulkAddSucceeded,
                  showAllJobsVsSelected,
                  allJobsCount,
                  selectedJobsCount
                )}
              </div>
            </FormGroup>
            <SearchBarMP
              className="chooseJobsPanel__search"
              testId="jbr-modify-jobs-search-box"
              value={showAllJobsVsSelected ? props.addJobsList.searchText : ''}
              onChange={props.onJobSearchChange}
              onSearch={event => {
                props.searchInputSearch(event.target.value);
              }}
              isDisabled={!showAllJobsVsSelected}
            />
            <ListPanel
              {...addJobsList}
              allowCheckAll={showAllJobsVsSelected}
              idProp={props.employeeIdProp}
              isDisabled={bulkAddInProgress}
              columns={addJobsList.columns.filter(col => col.dataKey !== 'org_job_code_key' || useJobCodeKey)}
              doCheckAll={doCheckAll}
              isAllChecked={isAllChecked}
              rowCheckBoxClick={jobItemClick}
              selectedListRows={addJobsList.selectedJobs.map(job => job.id)}
              pagination={{
                perPageCount: 10,
                pageNumber: 1,
                searchResultsCount: 0
              }}
              items={listPanelItems}
              testIdProp={props.employeeIdProp}
            />
            {showMoreButton}
            {this.state.isVisibleRemoveJobsModal && this.renderConfirmModal()}
          </div>
        </div>
      </div>
    );
  }
}

JobRangesSlideOutContent.propTypes = propTypes;
JobRangesSlideOutContent.defaultProps = defaultProps;

const connectState = state => {
  const {
    jobRanges: { jobRangesSearch, addJobsList, modelDetails }
  } = state;
  return {
    jobRangesSearch,
    addJobsList,
    jobRangeModel: modelDetails.jobRangeModel,
    useJobCodeKey: getUseJobCodeKey(state),
    replacementTokens: getReplacementTokens(state),
    isLab: isLab(state)
  };
};
const actions = {
  ...jobRangesSearchActions,
  onJobSearchChange,
  jobClick,
  doBulkClearJobs,
  doCheckAllAvailableJobs,
  doCheckAllSelectedJobs,
  doShowMoreSelectedJobs,
  addJobsListToggle,
  applyJobsToRange
};
export default connect(connectState, actions)(JobRangesSlideOutContent);
