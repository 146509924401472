import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import { ReactReduxContext } from 'react-redux';
import classnames from 'classnames';
import 'font-awesome/css/font-awesome.css';

import config from '../../config';
import LoadingIndicator from '../../../../ps-components/src/LoadingIndicator/LoadingIndicator';
import FatalError from '../../components/Common/FatalError';
import HeaderNav from '../Nav/HeaderNav';
import GoogleTagManager from '../../../../ps-components/src/ThirdPartyWrappers/GoogleTagManager';
import IdentityLogin from '../../../../ps-components/src/ThirdPartyWrappers/IdentityLogin';
import SentryLogging from '../../../../ps-components/src/ThirdPartyWrappers/SentryLogging';
import { getActions, actions } from '../../reducers/modules/app/app';
import { repeatCurrentSearch } from '../../reducers/modules/jobs/jobsSearch';
import { jobsListPanel } from '../../reducers/modules/jobs/jobsList';
import VersionCheck from '../../../../ps-components/src/Modules/Messaging/VersionCheck/VersionCheck';
import Routes from '../../routes';
import HelpForm from '../HelpForm/HelpForm';
import { setCookie } from '../../helpers/utils';
import IdleTimeWatcher from '../../../../ps-components/src/Utils/IdleTimeWatcher';
import SlideoutRoot from '../../../../ps-components/src/Slideout/SlideoutRoot';
import { getElapsedMsSinceRumInitialized } from '../../helpers/datadogRumHelpers';

@connect(
  state => ({
    router: state.router,
    app: state.app,
    featureFlags: state.features.flags
  }),
  {
    getActions,
    closeAdminBar: actions.closeAdminBar,
    repeatCurrentSearch,
    removeAllSelected: jobsListPanel.removeAllSelected
  }
)
export default class App extends Component {
  static propTypes = {
    children: PropTypes.object,
    history: PropTypes.object,
    app: PropTypes.object,
    featureFlags: PropTypes.object,
    getActions: PropTypes.func,
    router: PropTypes.object,
    closeAdminBar: PropTypes.func,
    repeatCurrentSearch: PropTypes.func,
    removeAllSelected: PropTypes.func
  };

  static contextType = ReactReduxContext;

  componentDidMount() {
    this.setLabCookie();
    this.sendAppLoadRumEvent();
  }

  sendAppLoadRumEvent() {
    const datadogRum = window.DD_RUM;
    if (!datadogRum) return;
    const appLoadElapsed = getElapsedMsSinceRumInitialized();
    if (!appLoadElapsed) return;
    const { router } = this.props;
    // Uniquely identify the dashboard load time since it's the non-deep-link route
    const actionName = router.location.pathname === '/' ? 'appLoadDashboardElapsedMs' : 'appLoadElapsedMs';
    datadogRum.addUserAction(actionName, appLoadElapsed);
  }

  // Set cookie to identify user as a Lab user
  setLabCookie() {
    let domain = 'payscale.com';
    if (window.location.origin.indexOf('localhost.com') !== -1) {
      domain = 'localhost.com';
    }
    setCookie('hasLabAccount', true, 365, domain);
  }

  checkId = element => {
    const isContainer = element.id === 'adminBar-container';
    if (isContainer) return true;
    if (element.parentElement) return this.checkId(element.parentElement);
    return false;
  };

  onClick = event => {
    if (this.props.app.showAdminBar) {
      const isAdminBarClick = this.checkId(event.target);
      if (!isAdminBarClick) this.props.closeAdminBar();
    }
  };

  render() {
    const { app, featureFlags, router, history } = this.props;

    if (app.overall === 'fatal') return <FatalError fatalReason={app.fatalReason}></FatalError>;

    const appClasses = classnames({
      app: true,
      homeHeight: router.location.pathname === '/',
      hideScrollBar: !app.showScrollBar,
      blur: app.overall !== 'loaded'
    });

    const route = router.location.pathname.slice(0, 6);

    return (
      <>
        {app.overall === 'loading' && <LoadingIndicator loading={true} />}
        <div className={appClasses} {...(route !== '/print' && { onClick: this.onClick })}>
          {route !== '/print' && (
            <>
              <Helmet {...config.app.head} />
              <HeaderNav location={location} router={router} history={history} />
            </>
          )}
          <Routes app={app} featureFlags={featureFlags} />
          {route !== '/print' && (
            <>
              <IdleTimeWatcher
                enabled={featureFlags['session-idle-timer'] && app.overall === 'loaded'}
                identityEnabled={true}
              />
              <SlideoutRoot history={history} />
              <HelpForm />
              <VersionCheck />
              <GoogleTagManager appLoadFinalProps={app.appLoadFinalProps} isLab={true} />
              {/* TODO remove this after AUTH_MODE is always identityServer */}
              {config.identityMode === 'legacy2019' && <IdentityLogin />}
              <SentryLogging />
            </>
          )}
        </div>
      </>
    );
  }
}
