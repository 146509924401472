import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './Row.scss';

class Row extends Component {
  static propTypes = {
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    dataRowKey: PropTypes.string,
    className: PropTypes.string,
    rowType: PropTypes.string,
    onClick: PropTypes.func,
    getRowProps: PropTypes.func,
    getHeaderGroupProps: PropTypes.func
  };

  static defaultProps = {
    onClick: () => {}
  };

  render() {
    const { children, className, rowType, onClick, dataRowKey, getRowProps, getHeaderGroupProps } = this.props;
    const rowClass = rowType !== 'header' ? `table__row ${className}` : `table__header-row ${className}`;

    return (
      <tr
        {...(getHeaderGroupProps && getHeaderGroupProps())}
        {...(getRowProps && getRowProps())}
        data-row-key={dataRowKey}
        onClick={onClick}
        className={rowClass}
      >
        {children}
      </tr>
    );
  }
}

export default Row;
