import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Cell from '../Table/Cell/Cell';
import classnames from 'classnames';
import './DefaultCell/DefaultCell.scss';

class AdvancedCell extends Component {
  static propTypes = {
    cellType: PropTypes.string,
    children: PropTypes.node,
    className: PropTypes.string,
    colSpan: PropTypes.string,
    onClick: PropTypes.func,
    maxWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    width: PropTypes.string,
    minWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    getCellProps: PropTypes.func,
    stickyLeft: PropTypes.bool,
    left: PropTypes.number,
    stickyRight: PropTypes.bool,
    right: PropTypes.number,
    zIndex: PropTypes.number
  };

  render() {
    const {
      cellType,
      children,
      className,
      colSpan,
      onClick,
      maxWidth,
      width,
      minWidth,
      getCellProps,
      stickyLeft,
      left,
      stickyRight,
      right,
      zIndex
    } = this.props;

    return (
      <Cell
        cellType={cellType}
        className={classnames('advanced-table__body-cell', className)}
        colSpan={colSpan}
        onClick={onClick}
        maxWidth={maxWidth}
        width={width}
        minWidth={minWidth}
        getCellProps={getCellProps}
        stickyLeft={stickyLeft}
        left={left}
        stickyRight={stickyRight}
        right={right}
        zIndex={zIndex}
      >
        {children}
      </Cell>
    );
  }
}

export default AdvancedCell;
