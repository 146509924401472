const EMPLOYEE_LIST = 'EMPLOYEE_LIST';
const EMPLOYEE_LIST_SUCCESS = 'EMPLOYEE_LIST_SUCCESS';
const EMPLOYEE_LIST_FAIL = 'EMPLOYEE_LIST_FAIL';

const initialState = {
  loading: false,
  loaded: false,
  error: null,
  items: []
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case EMPLOYEE_LIST:
      return {
        ...state,
        loading: true
      };
    case EMPLOYEE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: null,
        items: action.result
      };
    case EMPLOYEE_LIST_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        data: null,
        error: action.error
      };
    default:
      return { ...state };
  }
}

export function getEmployeeList(params = { es_query: 'viewed' }) {
  return {
    types: [EMPLOYEE_LIST, EMPLOYEE_LIST_SUCCESS, EMPLOYEE_LIST_FAIL],
    promise: client => client.get('/api/hris/employees/recentEmployees', { params: params })
  };
}
