import { calculateMatrixModel, saveMatrixModel } from './matrixGroupModel';

const initialState = {
  highChartsDataPerf: null,
  highChartsDataRange: null, //getHighChartsData(['Below', 'Min', 'Mid', 'Max', 'Above'], [75, 60, 30, 20, 10]),
  performanceAllocationPerc: 50,
  rangeAllocationPerc: 50
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case 'GET_MATRIX_GROUP_SUCCESS':
      const {
        performanceBudgetAllocation,
        payBudgetAllocation,
        performanceBuckets: perfBuckets,
        payBuckets
      } = action.result.groupModel;

      return {
        ...state,
        performanceAllocationPerc: performanceBudgetAllocation * 100,
        rangeAllocationPerc: payBudgetAllocation * 100,
        highChartsDataPerf: getHighChartsData(
          perfBuckets.map(b => b.name),
          perfBuckets.map(b => b.rawSliderValue)
        ),
        highChartsDataRange: getHighChartsData(
          payBuckets.map(b => b.name),
          payBuckets.map(b => b.rawSliderValue)
        )
      };
    case 'MM_INPUT_CHANGE_PERF':
      return {
        ...state,
        performanceAllocationPerc: cleanInputValue(state.performanceAllocationPerc, action.newValue)
      };
    case 'MM_INPUT_CHANGE_RNG':
      return {
        ...state,
        rangeAllocationPerc: cleanInputValue(state.rangeAllocationPerc, action.newValue)
      };
    case 'MM_ON_INPUT_BLUR':
      return { ...state, ...action.allocation };
    case 'MATRIX_CALC':
      if (action.isPreset) {
        const perfnBucketVals = action.performanceBuckets.map(bucket => bucket.rawSliderValue);
        const perfnBucketNames = action.performanceBuckets.map(bucket => bucket.name);

        const payBucketsVals = action.payBuckets.map(bucket => bucket.rawSliderValue);
        return {
          ...state,
          highChartsDataPerf: getHighChartsData(perfnBucketNames, perfnBucketVals),
          highChartsDataRange: getHighChartsData(['Below', 'Min', 'Mid', 'Max', 'Above'], payBucketsVals)
        };
      }
      return state;
    default:
      return state;
  }
}

export const someAction = () => ({ type: 'SOME_ACTION' });

function getHighChartsData(buckets, rawValues) {
  const data = {
    animation: false,
    credits: { enabled: false },
    title: { text: null },
    chart: {
      type: 'spline',
      inverted: false,
      // plotBorderWidth: 1,
      // borderWidth: 1,
      borderRadius: 5,
      marginBottom: 7,
      marginTop: 7,
      height: 110,
      width: 600,
      spacingBottom: 0,
      spacingTop: 0
    },
    tooltip: { enabled: false },
    xAxis: {
      reversed: false,
      lineWidth: 0,
      gridLineWidth: 4,
      gridLineColor: '#009AD7',
      labels: {
        style: {
          fontSize: '14px',
          fontWeight: 300,
          color: '#80807F'
        },
        distance: 50
      },
      categories: buckets,
      tickmarkPlacement: 'on',
      tickLength: 0,
      offset: 6
    },
    yAxis: {
      title: {
        text: null
      },
      gridLineWidth: 0,
      labels: {
        enabled: false
      },
      min: 0,
      max: 500
    },
    legend: {
      enabled: false
    },
    plotOptions: {
      series: {
        // dragPrecisionY: 1
        animation: false
        // point: {events: {}}
        // }
        // spline: {
        //   animation: false,
        // },
        // line: {
        //   animation: false
      }
    },
    series: [
      {
        name: 'Temperature',
        // animation: false,
        draggableY: true,
        dragMaxY: 500,
        dragMinY: 0,
        updateDataPointProp: 'y',
        cursor: 'ns-resize',
        color: '#E6E6E6',
        lineWidth: 5,
        marker: {
          symbol: 'url(/view/static/lab/imgs/merit-matrix-knob-arrows.png)',
          width: 30,
          height: 30
        },
        data: rawValues
      }
    ]
  };
  return data;
}

export const performanceInputChange = event => ({
  type: 'MM_INPUT_CHANGE_PERF',
  newValue: event.target.value
});

export const rangeInputChange = event => ({
  type: 'MM_INPUT_CHANGE_RNG',
  newValue: event.target.value
});

export const onInputBlur = event => (dispatch, getState) => {
  const allocation = equalizeAllocation(event.target.id, getState().meritMatrix.allocation);
  dispatch({ type: 'MM_ON_INPUT_BLUR', allocation });
  dispatch(calculateMatrixModel(event));
  dispatch(saveMatrixModel());
};

const cleanInputValue = (currValue, newValue) => {
  if (newValue === '') return newValue;

  const isNum = /^\d+$/.test(newValue);
  if (!isNum) return currValue;

  const newValAsNum = parseInt(newValue);
  const isValid = newValAsNum >= 0 && newValAsNum <= 100;
  return isValid ? newValAsNum : currValue;
};

const equalizeAllocation = (id, state) => {
  const newValProp = id === 'mm-performance-input' ? 'performanceAllocationPerc' : 'rangeAllocationPerc';
  const valToChangeProp = id === 'mm-performance-input' ? 'rangeAllocationPerc' : 'performanceAllocationPerc';

  const { [newValProp]: newValue } = state;
  return { [newValProp]: newValue, [valToChangeProp]: 100 - newValue };
};
