import React from 'react';
import { useMeasureMountTime } from '../helpers/datadogRumHelpers';

import LoadingIndicator from '../../../ps-components/src/LoadingIndicator/LoadingIndicator';

export default function InstrumentedLoadingIndicator({ loading, ddRumUserAction }) {
  if (ddRumUserAction) {
    // 30s is a common timeout for nginx proxy etc. so use a timeout just past that for the action
    // timeout
    useMeasureMountTime(ddRumUserAction, 35000);
  }

  return <LoadingIndicator {...{ loading }} />;
}
