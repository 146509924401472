const REPORT_LIST = 'REPORT_LIST';
const REPORT_LIST_SUCCESS = 'REPORT_LIST_SUCCESS';
const REPORT_LIST_FAIL = 'REPORT_LIST_FAIL';

const initialState = {
  loading: false,
  loaded: false,
  error: null,
  items: []
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case REPORT_LIST:
      return {
        ...state,
        loading: true
      };
    case REPORT_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: null,
        items: action.result
      };
    case REPORT_LIST_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        data: null,
        error: action.error
      };
    default:
      return { ...state };
  }
}

export function getReports(params = { es_query: 'viewed' }) {
  return {
    types: [REPORT_LIST, REPORT_LIST_SUCCESS, REPORT_LIST_FAIL],
    promise: client => client.get('/api/report-writer/report-list/recentViewed', { params: params })
  };
}
