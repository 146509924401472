import { mapDispatchToActions } from '../../../../../panther-react/src/helpers/utils.js';

const initialState = {
  loading: false,
  loaded: false,
  error: null
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case 'GET_PERFORMANCE_RATINGS':
      return {
        ...state,
        loading: true,
        loaded: false
      };

    case 'GET_PERFORMANCE_RATINGS_SUCCESS':
      let performanceRatings = '';
      if (action.result) {
        performanceRatings = action.result;
      }

      return {
        ...state,
        loading: false,
        loaded: true,
        performanceRatings: performanceRatings,
        tmpPerformanceRatings: [...performanceRatings],
        ratingsIsDirty: false,
        error: null
      };

    case 'GET_PERFORMANCE_RATINGS_FAIL':
      return {
        ...state,
        loading: false,
        loaded: true,
        error: action.result
      };

    case 'SET_PERFORMANCE_RATINGS_DIRTY_STATE':
      return {
        ...state,
        ratingsIsDirty: action.ratingsIsDirty
      };

    case 'UPDATE_PERFORMANCE_RATING_ORDER':
      return {
        ...state,
        tmpPerformanceRatings: action.ratings
      };

    case 'SAVE_PERFORMANCE_RATING':
      return {
        ...state,
        loading: true,
        loaded: false
      };

    case 'SAVE_PERFORMANCE_RATING_SUCCESS':
      let newPerformanceRatings = '';
      if (action.result) {
        newPerformanceRatings = state.tmpPerformanceRatings;
      }

      return {
        ...state,
        loading: false,
        loaded: true,
        performanceRatings: newPerformanceRatings,
        tmpPerformanceRatings: [...newPerformanceRatings],
        ratingsIsDirty: false,
        error: null
      };

    case 'SAVE_PERFORMANCE_RATING_FAIL':
      return {
        ...state,
        loading: false,
        loaded: true,
        error: action.result
      };

    case 'RESET_PERFORMANCE_RATINGS':
      let originalValues = '';
      if (action.originalValues.performanceRatings) {
        originalValues = action.originalValues.performanceRatings;
      }

      return {
        ...state,
        loading: false,
        loaded: true,
        performanceRatings: originalValues,
        tmpPerformanceRatings: [...originalValues],
        ratingsIsDirty: false,
        error: null
      };

    case 'CLOSE_ERROR_MODAL':
      return {
        ...state,
        showErrorModal: false
      };

    default:
      return { ...state };
  }
}

export const actions = {};

actions.getPerformanceRatings = function getSites(subsite) {
  const params = { url: subsite };

  return (dispatch, getState) => {
    dispatch({
      types: ['GET_PERFORMANCE_RATINGS', 'GET_PERFORMANCE_RATINGS_SUCCESS', 'GET_PERFORMANCE_RATINGS_FAIL'],
      promise: client => client.get('/api/matrix-ratings/getOrCreatePerformanceRatings', { params: params })
    });
  };
};

actions.updatePerformanceRatingOrder = function updatePerformanceRatingOrder(ratings) {
  return {
    type: 'UPDATE_PERFORMANCE_RATING_ORDER',
    ratings
  };
};

actions.setDirtyState = function setDirtySate(ratingsIsDirty) {
  return (dispatch, getState) => {
    dispatch({
      type: 'SET_PERFORMANCE_RATINGS_DIRTY_STATE',
      ratingsIsDirty
    });
  };
};

actions.resetPerformanceRatings = function resetPerformanceRatings(originalValues) {
  return (dispatch, getState) => {
    dispatch({
      type: 'RESET_PERFORMANCE_RATINGS',
      originalValues
    });
  };
};

actions.savePerformanceRatings = function savePerformanceRatings(orgId) {
  return (dispatch, getState) => {
    const { performanceRatings } = getState();
    dispatch({
      types: ['SAVE_PERFORMANCE_RATING', 'SAVE_PERFORMANCE_RATING_SUCCESS', 'SAVE_PERFORMANCE_RATING_FAIL'],
      promise: client =>
        client.get('/api/matrix-ratings/savePerformanceRatings', {
          params: {
            performanceRatings: JSON.stringify(performanceRatings.tmpPerformanceRatings),
            organizationId: orgId
          }
        })
    });
  };
};

actions.closeErrorModal = function closeErrorModal(rowInfo) {
  return {
    type: 'CLOSE_ERROR_MODAL'
  };
};

export const performanceRatingActions = mapDispatchToActions(actions);
