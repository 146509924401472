import { ParticipationMatchOrgJob } from '../../../../types/MatchReviewTypes';
import SurveyJobMatchCard from '../SurveyJobMatchCard/SurveyJobMatchCard';
import './SurveyJobMatchList.scss';
import { func } from 'prop-types';
import React from 'react';

const propTypes = {
  participationOrgJob: ParticipationMatchOrgJob,
  includeDuplicateMatch: func.isRequired
};

const renderSurveyMatchCards = (participationOrgJob, action) => {
  if (participationOrgJob && participationOrgJob.matches) {
    return participationOrgJob.matches.map(sj => {
      return (
        <SurveyJobMatchCard
          key={sj.orgJobCode + sj.orgJobCodeKey + sj.surveyJobCode}
          participationOrgJob={participationOrgJob}
          match={sj}
          doIncludeMatch={action}
        />
      );
    });
  }
  return null;
};

const SurveyJobMatchList = props => {
  const { participationOrgJob, includeDuplicateMatch } = props;
  return (
    <div className="pcenter-review-duplicates__survey-job-list">
      <div className="pcenter-review-duplicates-survey-job-list__header">
        <div className="pcenter-review-duplicates-survey-job-list-header__title">
          {participationOrgJob && participationOrgJob.orgJobTitle}
        </div>
        <div className="pcenter-review-duplicates-survey-job-list-header__details">
          <label className="pcenter-review-duplicates-survey-job-list-header__label">Job Code</label>
          <div className="pcenter-review-duplicates-survey-job-list-header__value">
            {participationOrgJob && participationOrgJob.orgJobCode}
          </div>
          <label className="pcenter-review-duplicates-survey-job-list-header__label">Job Code Key</label>
          <div className="pcenter-review-duplicates-survey-job-list-header__value">
            {participationOrgJob && participationOrgJob.orgJobCodeKey}
          </div>
        </div>
        <div className="pcenter-review-duplicates-survey-job-list-header__instructions">Include only 1 match</div>
      </div>
      <div className="pcenter-review-duplicates-survey-job-list__rows">
        {renderSurveyMatchCards(participationOrgJob, includeDuplicateMatch)}
      </div>
    </div>
  );
};

SurveyJobMatchList.propTypes = propTypes;
export default SurveyJobMatchList;
