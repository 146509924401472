import apiClient from '../../../lib/apiClient';
import { getCSRFPair } from '../../../redux/stateSelectors';
import { getRecalculatedJobData } from '@payscale/ps-isomorphic/dist/JobRanges/JobCalculationUtils';
import { doesJobContainSpecificRangeDetails, getJobSettings } from '@payscale/ps-isomorphic/dist/JobRanges/Utils';
import { MARKET_RANGE_TYPE } from '../Utils/constants';
import { getModelList } from './JobRanges';
import addAlert from '../../../AlertToast/addAlert';
export const JOB_RANGE_MODEL_JOBS_CHANGE = 'JOB_RANGE_MODEL_JOBS_CHANGE';
export const JOB_RANGE_MODEL_MIDPOINT_VALUE_CHANGE = 'JOB_RANGE_MODEL_MIDPOINT_VALUE_CHANGE';
export const PROPOSED_RANGE_SELECT = 'PROPOSED_RANGE_SELECT';
export const RANGE_SPREAD_CHANGE = 'RANGE_SPREAD_CHANGE';
export const RANGE_TYPE_CHANGE = 'RANGE_TYPE_CHANGE';
export const MIDPOINT_ADJUSTMENT_CHANGE = 'MIDPOINT_ADJUSTMENT_CHANGE';
export const RANGES_ROUND_TO_CHANGE = 'RANGES_ROUND_TO_CHANGE';
export const SAVE_JOB_RANGE_MODEL_SUCCESS = 'SAVE_JOB_RANGE_MODEL_SUCCESS';
export const SAVE_JOB_RANGE_MODEL_FAIL = 'SAVE_JOB_RANGE_MODEL_FAIL';
export const MODEL_CHANGE_MIN_OF_MID_INIT = 'MODEL_CHANGE_MIN_OF_MID_INIT';
export const MODEL_CHANGE_MAX_OF_MID_INIT = 'MODEL_CHANGE_MAX_OF_MID_INIT';
export const MODEL_SAVE_BUILD_MIN_MAX_BY_INIT = 'MODEL_SAVE_BUILD_MIN_MAX_BY_INIT';
export const MODEL_SAVE_BUILD_MIN_MAX_BY_SUCCESS = 'MODEL_SAVE_BUILD_MIN_MAX_BY_SUCCESS';
export const MODEL_SAVE_BUILD_MIN_MAX_BY_FAIL = 'MODEL_SAVE_BUILD_MIN_MAX_BY_FAIL';
export const MODEL_PROPOSED_MAX_SELECT_INIT = 'MODEL_PROPOSED_MAX_SELECT_INIT';
export const MODEL_PROPOSED_MIN_SELECT_INIT = 'MODEL_PROPOSED_MIN_SELECT_INIT';

const errorMessage = 'Error updating job range';

export const proposedRangeSelect = option => (dispatch, getState) => {
  const { modelJobs, jobRangeModel } = getState().jobRanges.modelDetails;
  const newModelJobs = calcProposedRanges(modelJobs, {
    ...jobRangeModel,
    proposedRangeSetting: option.value
  });

  dispatch({ type: PROPOSED_RANGE_SELECT, newModelJobs, optionValue: option.value });
  dispatch(saveJobRangeModel());
};

export const proposedMaxSelect = option => (dispatch, getState) => {
  try {
    const { modelJobs, jobRangeModel } = getState().jobRanges.modelDetails;
    dispatch({ type: MODEL_PROPOSED_MAX_SELECT_INIT, optionValue: option.value });
    const newModelJobs = calcProposedRanges(modelJobs, {
      ...jobRangeModel,
      maxPercentageMarketElement: option.value
    });

    dispatch({ type: MODEL_SAVE_BUILD_MIN_MAX_BY_SUCCESS, newModelJobs });
    dispatch(saveJobRangeModel());
  } catch (error) {
    addAlert(errorMessage, 'danger');
    dispatch({ type: MODEL_SAVE_BUILD_MIN_MAX_BY_FAIL, error: `proposedMaxSelect(model): ${error}` });
  }
};

export const proposedMinSelect = option => (dispatch, getState) => {
  try {
    dispatch({ type: MODEL_PROPOSED_MIN_SELECT_INIT, optionValue: option.value });
    const { modelJobs, jobRangeModel } = getState().jobRanges.modelDetails;
    const newModelJobs = calcProposedRanges(modelJobs, {
      ...jobRangeModel,
      minPercentageMarketElement: option.value
    });

    dispatch({ type: MODEL_SAVE_BUILD_MIN_MAX_BY_SUCCESS, newModelJobs });
    dispatch(saveJobRangeModel());
  } catch (error) {
    addAlert(errorMessage, 'danger');
    dispatch({ type: MODEL_SAVE_BUILD_MIN_MAX_BY_FAIL, error: `proposedMinSelect(model): ${error}` });
  }
};

export const calcProposedRanges = (modelJobs, modelSettings) => {
  const newModelJobs = [];
  for (let i = 0; i < modelJobs.length; i++) {
    const job = modelJobs[i];

    if (doesJobContainSpecificRangeDetails(job)) {
      const newJobSettings = getJobSettings(modelSettings, job);
      newModelJobs.push(getRecalculatedJobData({ ...newJobSettings, job }));
    } else {
      newModelJobs.push(getRecalculatedJobData({ ...modelSettings, job }));
    }
  }
  return newModelJobs;
};

export const saveJobRangeModel = (skipRangeCalc = false) => (dispatch, getState) => {
  const jobRangeModel = getState().jobRanges.modelDetails.jobRangeModel;
  let modelJobs = getState().jobRanges.modelDetails.modelJobs;
  let calcRanges;
  if (modelJobs.length < jobRangeModel.jobCount) {
    modelJobs = undefined;
    calcRanges = true;
  }

  if (skipRangeCalc === true) {
    modelJobs = undefined;
    calcRanges = false;
  }
  const csrfPair = getCSRFPair(getState());
  apiClient
    .apiPost('/api/jobRanges/model/updateJobRangeModel', {
      jobRangeModel,
      modelJobs,
      calcRanges,
      ...csrfPair
    })
    .then(response => {
      const id = getState().jobRanges.modelDetails.jobRangeModel.jobRangeModelId;
      dispatch(getModelList(id));
      dispatch({ type: SAVE_JOB_RANGE_MODEL_SUCCESS, response });
    })
    .catch(err => {
      dispatch({ type: SAVE_JOB_RANGE_MODEL_FAIL, error: err });
    });
};

export const rangeSpreadChange = value => (dispatch, getState) => {
  const { modelJobs, jobRangeModel } = getState().jobRanges.modelDetails;
  if (!jobRangeModel) return;

  const { proposedRangeSetting, rangeType } = jobRangeModel;
  const newTargetRangeSpread = value / 100;

  dispatch({ type: RANGE_SPREAD_CHANGE, value: newTargetRangeSpread });

  if (rangeType === MARKET_RANGE_TYPE && (!proposedRangeSetting || proposedRangeSetting === '')) {
    dispatch(saveJobRangeModel(jobRangeModel));
    return;
  }

  const newModelJobs = calcProposedRanges(modelJobs, {
    ...jobRangeModel,
    targetRangeSpread: newTargetRangeSpread
  });

  dispatch({ type: MODEL_SAVE_BUILD_MIN_MAX_BY_SUCCESS, newModelJobs });
  dispatch(saveJobRangeModel(jobRangeModel));
};

export const minPercentChange = value => (dispatch, getState) => {
  try {
    const { modelJobs, jobRangeModel } = getState().jobRanges.modelDetails;
    if (!jobRangeModel) return;

    const { proposedRangeSetting, rangeType } = jobRangeModel;
    const newMinValue = value / 100;

    dispatch({ type: MODEL_CHANGE_MIN_OF_MID_INIT, value: newMinValue });

    const newModelJobs = calcProposedRanges(modelJobs, {
      ...jobRangeModel,
      minPercentageOfMid: newMinValue
    });

    dispatch({ type: MODEL_SAVE_BUILD_MIN_MAX_BY_SUCCESS, newModelJobs });
    dispatch(saveJobRangeModel(jobRangeModel));
  } catch (error) {
    dispatch({ type: MODEL_SAVE_BUILD_MIN_MAX_BY_FAIL, error: `minPercentChange(model): ${error}` });
  }
};

export const maxPercentChange = value => (dispatch, getState) => {
  try {
    const { modelJobs, jobRangeModel } = getState().jobRanges.modelDetails;
    if (!jobRangeModel) return;

    const { proposedRangeSetting, rangeType } = jobRangeModel;
    const newMaxValue = value / 100;

    dispatch({ type: MODEL_CHANGE_MAX_OF_MID_INIT, value: newMaxValue });

    const newModelJobs = calcProposedRanges(modelJobs, {
      ...jobRangeModel,
      maxPercentageOfMid: newMaxValue
    });

    dispatch({ type: MODEL_SAVE_BUILD_MIN_MAX_BY_SUCCESS, newModelJobs });
    dispatch(saveJobRangeModel(jobRangeModel));
  } catch (error) {
    dispatch({ type: MODEL_SAVE_BUILD_MIN_MAX_BY_FAIL, error: `maxPercentChange(model): ${error}` });
  }
};

export const saveBuildMinMaxBy = value => (dispatch, getState) => {
  try {
    const { modelJobs, jobRangeModel } = getState().jobRanges.modelDetails;
    if (!jobRangeModel) return;

    dispatch({ type: MODEL_SAVE_BUILD_MIN_MAX_BY_INIT, value: value });

    const newModelJobs = calcProposedRanges(modelJobs, {
      ...jobRangeModel,
      buildMinMaxBy: value
    });

    dispatch({ type: MODEL_SAVE_BUILD_MIN_MAX_BY_SUCCESS, newModelJobs });
    dispatch(saveJobRangeModel(jobRangeModel));
  } catch (error) {
    dispatch({ type: MODEL_SAVE_BUILD_MIN_MAX_BY_FAIL, error: `saveBuildMinMaxBy(model): ${error}` });
  }
};

export const rangeTypeClick = (id, optionValue) => (dispatch, getState) => {
  const { modelJobs, jobRangeModel } = getState().jobRanges.modelDetails;
  if (!jobRangeModel) return;

  const newRangeType = optionValue.toLowerCase();

  const newModelJobs = calcProposedRanges(modelJobs, {
    ...jobRangeModel,
    rangeType: newRangeType
  });

  dispatch({ type: RANGE_TYPE_CHANGE, newRangeType, newModelJobs });
  dispatch(saveJobRangeModel());
};

export const handleMidpointAdjustmentValueUpdate = value => (dispatch, getState) => {
  const { jobRangeModel, modelJobs } = getState().jobRanges.modelDetails;
  if (!jobRangeModel) return;

  const newMidpointAdjustment = value / 100;
  dispatch({ type: JOB_RANGE_MODEL_MIDPOINT_VALUE_CHANGE, newMidpointAdjustment });

  const newModelJobs = calcProposedRanges(modelJobs, {
    ...jobRangeModel,
    midpointAdjustment: newMidpointAdjustment
  });
  dispatch({ type: JOB_RANGE_MODEL_JOBS_CHANGE, newModelJobs });
  dispatch(saveJobRangeModel());
};

export const adjustCurrentMidpointClick = direction => (dispatch, getState) => {
  const jobRangeModel = getState().jobRanges.modelDetails.jobRangeModel;
  if (!jobRangeModel) return;
  const magnitude = 1;
  const newMidpointAdjustment = jobRangeModel.midpointAdjustment + Math.round(magnitude * direction) / 100;

  const { modelJobs } = getState().jobRanges.modelDetails;

  const newModelJobs = calcProposedRanges(modelJobs, {
    ...jobRangeModel,
    midpointAdjustment: newMidpointAdjustment
  });
  dispatch({ type: MIDPOINT_ADJUSTMENT_CHANGE, newMidpointAdjustment, newModelJobs });
  dispatch(saveJobRangeModel());
};

export const roundToChange = option => (dispatch, getState) => {
  const { modelJobs, jobRangeModel } = getState().jobRanges.modelDetails;
  if (!jobRangeModel) return;

  dispatch({ type: RANGES_ROUND_TO_CHANGE, roundTo: option.value });

  const newModelJobs = calcProposedRanges(modelJobs, {
    ...jobRangeModel,
    roundTo: option.value
  });
  dispatch({ type: JOB_RANGE_MODEL_JOBS_CHANGE, newModelJobs });
  dispatch(saveJobRangeModel());
};
