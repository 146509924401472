import { SAVE_STRUCTURE, SAVE_COPIED_STRUCTURE_SUCCESS } from './currentStructure';
const STRUCTURE_COPY_MODAL_CLOSE = 'STRUCTURE_COPY_MODAL_CLOSE';
const STRUCTURE_COPY_MODAL_OPEN = 'STRUCTURE_COPY_MODAL_OPEN';
const INCREASE_PERCENT_CHANGE = 'INCREASE_PERCENT_CHANGE';
const STRUCTURE_COPY_NAME_CHANGE = 'STRUCTURE_COPY_NAME_CHANGE';

const initialState = {
  isOpen: false,
  inputName: '',
  increasePercent: 3,
  copyValid: null,
  copyDuplicate: false
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case STRUCTURE_COPY_MODAL_CLOSE:
      return { ...state, isOpen: false };
    case SAVE_STRUCTURE:
      return { ...state, isOpen: false };
    case STRUCTURE_COPY_MODAL_OPEN:
      return { ...state, isOpen: true, inputName: 'Copy of ' + action.structureCode };
    case INCREASE_PERCENT_CHANGE:
      return { ...state, increasePercent: action.value };
    case STRUCTURE_COPY_NAME_CHANGE:
      return {
        ...state,
        inputName: action.newName,
        copyValid: action.inputValid,
        copyDuplicate: action.inputDuplicate
      };
    case SAVE_COPIED_STRUCTURE_SUCCESS:
      return action.result.success === false && action.result.error_message === 'Duplicate Name'
        ? { ...state, copyValid: 'error', copyDuplicate: true }
        : state;
    default:
      return state;
  }
}

export const copyModalClose = () => ({ type: STRUCTURE_COPY_MODAL_CLOSE });

export const copyModalOpen = structureCode => ({ type: STRUCTURE_COPY_MODAL_OPEN, structureCode });

// export const increasePercentChange = (event) => ({
//   type: INCREASE_PERCENT_CHANGE,
//   value: parseFloat(event && event.target && event.target.value) || 0
// });

export function increasePercentChange(options) {
  return (dispatch, getState) => {
    dispatch({
      type: 'INCREASE_PERCENT_CHANGE',
      value: options.increasePct
    });
  };
}

export const nameChange = event => (dispatch, getState) => {
  const { value: newName } = event.target;
  const modelStructureList = getState().modelStructureList.salaryStructureItems
    .modelAndStructureList;
  const nameValidation = validateInputName(newName, modelStructureList);
  dispatch({ type: STRUCTURE_COPY_NAME_CHANGE, newName, ...nameValidation });
};

export function validateInputName(name, modelStructureList = []) {
  if (!name || !name.length) return { inputValid: 'error', inputDuplicate: false };
  if (modelStructureList.map(item => item.label).includes(name)) {
    return { inputValid: 'error', inputDuplicate: true };
  }
  return { inputValid: null, inputDuplicate: false };
}
