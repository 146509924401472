export default function initList(nameSpace, initialState, actions) {
  const SEARCH = `${nameSpace}_SEARCH`;
  const SEARCH_SUCCESS = `${nameSpace}_SEARCH_SUCCESS`;
  const SEARCH_FAIL = `${nameSpace}_SEARCH_FAIL`;
  const SORT = `${nameSpace}_LIST_SORT`;
  const ROW_CHECK = `${nameSpace}_LIST_ROW_CHECK`;
  const ROW_CLICK = `${nameSpace}_LIST_ROW_CLICK`;
  const MULTI_ROW_CHECK = `${nameSpace}_LIST_MULTI_ROW_CHECK`;
  const REMOVE_ALL_SELECTED = `${nameSpace}_REMOVE_ALL_SELECTED`;
  const LIST_ROW_SELECTION = `${nameSpace}_LIST_ROW_SELECTION`;
  const SET_SELECTED_ROWS = `${nameSpace}_SET_SELECTED_ROWS`;
  const DISABLE_DETAILS_LOAD = `${nameSpace}_DISABLE_DETAILS_LOAD`;
  const ENABLE_DETAILS_LOAD = `${nameSpace}_ENABLE_DETAILS_LOAD`;
  const TOGGLE_SELECT_ALL_CHECK = `${nameSpace}_TOGGLE_SELECT_ALL_CHECK`;
  const EXCLUDE_ROW = `${nameSpace}_EXCLUDE_ROW`;

  const list = {};
  list.nameSpace = nameSpace.toLowerCase();

  const defaultInitialState = {
    sort: {},
    selectedListRows: [],
    multiSelectedRows: [],
    multiExcludedRows: [],
    skipDetailsLoad: false,
    isSelectAllChecked: false
  };

  list.rowCheckReducer = function rowCheckReducer(itemId, selectedRows) {
    const index = selectedRows.indexOf(itemId);
    if (index >= 0) {
      return selectedRows.filter(id => id !== itemId);
    }
    return [...selectedRows, itemId];
  };

  list.reducer = function reducer(state = initialState || defaultInitialState, action = {}) {
    if (typeof list.extendReducer === 'function') {
      const newState = list.extendReducer(state, action);
      if (newState) return newState;
    }

    switch (action.type) {
      case SEARCH:
        return {
          ...state
        };
      case SEARCH_SUCCESS:
        return {
          ...state,
          dirtyJobs: []
        };
      case SEARCH_FAIL:
        return {
          ...state
        };
      case SORT:
        return { ...state, sort: { colName: action.columnName, direction: action.direction } };
      case ROW_CHECK:
        const selectedListRows = list.rowCheckReducer(action.itemId, state.selectedListRows);
        return {
          ...state,
          selectedListRows: selectedListRows
        };
      case MULTI_ROW_CHECK:
        let multiExcludedRows = state.multiExcludedRows;
        let isSelectAllChecked = state.isSelectAllChecked;
        let multiSelectedRows = state.multiSelectedRows;

        if (state.multiExcludedRows.length > 0) {
          multiExcludedRows = list.rowCheckReducer(action.selectedRow, multiExcludedRows);
          isSelectAllChecked = multiExcludedRows.length === 0;
        } else {
          multiSelectedRows = list.rowCheckReducer(action.selectedRow, multiSelectedRows);
        }

        return {
          ...state,
          multiSelectedRows: multiSelectedRows,
          multiExcludedRows: multiExcludedRows,
          isSelectAllChecked: isSelectAllChecked
        };
      case ROW_CLICK:
        if (state.selectedListRows.length === 1 && state.selectedListRows[0] === action.itemId) {
          return { ...state, selectedListRows: [] };
        }
        return { ...state, selectedListRows: [action.itemId] };
      case SET_SELECTED_ROWS:
        return { ...state, selectedListRows: [...action.idList] };
      case REMOVE_ALL_SELECTED:
        return { ...state, selectedListRows: [] };
      case DISABLE_DETAILS_LOAD:
        return {
          ...state,
          skipDetailsLoad: true
        };
      case ENABLE_DETAILS_LOAD:
        return {
          ...state,
          skipDetailsLoad: false
        };
      case TOGGLE_SELECT_ALL_CHECK:
        const checkState = typeof action.checkState === 'boolean' ? action.checkState : !state.isSelectAllChecked;
        return {
          ...state,
          isSelectAllChecked: checkState,
          multiExcludedRows: [],
          multiSelectedRows: []
        };
      case EXCLUDE_ROW:
        let excludedRows = state.multiExcludedRows;
        if (!excludedRows.includes(action.selectedRow)) {
          excludedRows.push(action.selectedRow);
        }

        return {
          ...state,
          multiExcludedRows: excludedRows,
          isSelectAllChecked: false
        };
      default:
        return { ...state };
    }
  };

  list.toggleSort = function toggleSort(info) {
    return dispatch => {
      dispatch({
        type: SORT,
        ...info
      });
    };
  };

  list.rowClick = function rowClick(itemId, item, urlInfo) {
    return (dispatch, getStore) => {
      const { [list.nameSpace + 'List']: listStore } = getStore();
      const AlreadyTheOnlyRowSelected =
        listStore.selectedListRows.length === 1 && listStore.selectedListRows[0] === itemId;

      if (!AlreadyTheOnlyRowSelected) {
        dispatch({
          type: ROW_CLICK,
          itemId
        });

        if (urlInfo) urlInfo.history.push(urlInfo.baseRoute + '/detail/' + itemId);

        if (list.handleRowSelect) {
          list.handleRowSelect(dispatch, getStore, itemId);
        }
      }
    };
  };

  list.rowCheckBoxClick = function rowCheckBoxClick(itemId, item, urlInfo) {
    return (dispatch, getStore) => {
      const { [list.nameSpace + 'List']: listStore } = getStore();
      const newSelectedListRows = list.rowCheckReducer(itemId, listStore.selectedListRows);

      dispatch({
        type: ROW_CHECK,
        itemId
      });

      if (urlInfo) {
        const selectedLength = newSelectedListRows.length;
        if (selectedLength === 1) urlInfo.history.push(urlInfo.baseRoute + '/detail/' + newSelectedListRows[0]);
        if (selectedLength > 1) {
          urlInfo.history.push(urlInfo.baseRoute + '/compare/' + newSelectedListRows.join('-'));
        }
        if (!selectedLength) urlInfo.history.push(urlInfo.baseRoute);
      }

      if (list.handleRowSelect) {
        list.handleRowSelect(dispatch, getStore, newSelectedListRows);
      }
    };
  };

  list.rowCheckBoxMultiSelect = function rowCheckBoxMultiSelect(
    selectedRow,
    isSelectAllChecked,
    includedRows,
    ExcludedRows
  ) {
    return (dispatch, getState) => {
      if (isSelectAllChecked) {
        dispatch({ type: EXCLUDE_ROW, selectedRow });
      } else {
        dispatch({
          type: MULTI_ROW_CHECK,
          selectedRow
        });
      }
    };
  };

  list.setSelectedRows = function setSelectedRows(idList) {
    return (dispatch, getStore) => {
      dispatch({ type: SET_SELECTED_ROWS, idList });

      if (list.handleRowSelect) {
        list.handleRowSelect(dispatch, getStore, idList);
      }
    };
  };

  list.removeAllSelected = function removeAllSelected() {
    return (dispatch, getStore) => {
      dispatch({ type: REMOVE_ALL_SELECTED });
    };
  };

  list.toggleSelectAllCheck = function toggleSelectAllCheck(checkState) {
    return (dispatch, getState) => {
      dispatch({
        type: TOGGLE_SELECT_ALL_CHECK,
        checkState
      });
    };
  };

  if (actions) {
    Object.keys(actions).forEach(key => {
      list[key] = actions[key];
    });
  }

  return list;
}
