import React from 'react';
import './SlideoutBody.scss';

const SlideoutBody = React.forwardRef((props, ref) => {
  return (
    <div ref={ref} className="slideout-body">
      {props.children}
    </div>
  );
});

SlideoutBody.displayName = 'SlideoutBody';
export default SlideoutBody;
