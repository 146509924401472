import PropTypes from 'prop-types';
import React, { Component } from 'react';
import './JobDetailsEmployeeTable.scss';
import AdvancedTable, { formatSubHeader } from '../../../../../AdvancedTable/AdvancedTable';
import JobDetailsEmployeeTableRow from './JobDetailsEmployeeTableRow';

const propTypes = {
  tableColumns: PropTypes.array,
  tableItems: PropTypes.array,
  currentSalaryMinimum: PropTypes.number,
  currentSalaryMidPoint: PropTypes.number,
  currentSalaryMaximum: PropTypes.number,
  proposedSalaryMinimum: PropTypes.number,
  proposedSalaryMidPoint: PropTypes.number,
  proposedSalaryMaximum: PropTypes.number,
  showMoreEmployees: PropTypes.func
};

const defaultProps = { tableItems: [] };
const JobDetailsEmployeeTable = props => {
  let paginationFooter = null;
  if (props.showMoreEmployees) {
    paginationFooter = <a onClick={props.showMoreEmployees}>Show more</a>;
  }
  const columnHeaders = [
    {
      Header: formatSubHeader,
      HeaderText: 'Name',
      id: 'name',
      SubHeaderText: 'Employee ID',
      width: 100
    },
    {
      Header: 'Date in Position',
      width: 65
    },
    {
      centerAlign: true,
      Header: 'Base Salary',
      width: 50
    },
    {
      centerAlign: true,
      Header: formatSubHeader,
      HeaderText: 'Midpoint',
      id: 'midpoint',
      SubHeaderText: 'Current/Proposed',
      width: 60
    },
    {
      centerAlign: true,
      Header: formatSubHeader,
      HeaderText: 'Avg Compa Ratio',
      id: 'compaRatio',
      SubHeaderText: 'Current/Proposed',
      width: 70
    },
    {
      centerAlign: true,
      Header: formatSubHeader,
      HeaderText: 'Avg Position in Range',
      id: 'rangePen',
      SubHeaderText: 'Current/Proposed',
      width: 85
    },
    {
      centerAlign: true,
      Header: formatSubHeader,
      HeaderText: 'Cost to Min',
      id: 'costToMin',
      SubHeaderText: 'Current/Proposed',
      width: 60
    },
    {
      centerAlign: true,
      Header: formatSubHeader,
      HeaderText: 'Amount Above Max',
      id: 'costAboveMax',
      SubHeaderText: 'Current/Proposed',
      width: 80
    }
  ];

  const getTableRow = item => {
    return (
      <JobDetailsEmployeeTableRow
        baseSalary={item.original.base_salary}
        cells={item.cells}
        costToMin={item.original.cost_to_min}
        currentSalaryMaximum={props.currentSalaryMaximum}
        currentSalaryMidpoint={props.currentSalaryMidPoint}
        currentSalaryMinimum={props.currentSalaryMinimum}
        dateInPosition={item.original.dateInPosition}
        employeeNumber={item.original.employee_number}
        fullName={item.original.full_name}
        key={item.original.org_employee_id}
        payType={props.payType}
        preferences={props.preferences}
        proposedSalaryMaximum={props.proposedSalaryMaximum}
        proposedSalaryMidpoint={props.proposedSalaryMidPoint}
        proposedSalaryMinimum={props.proposedSalaryMinimum}
      />
    );
  };

  return (
    <div className="job-details-employee--table--container">
      <AdvancedTable
        allowSorting={true}
        className="job-details-employee--table"
        columnHeaders={columnHeaders}
        getDataRow={getTableRow}
        isBasic={true}
        rowData={props.tableItems}
        staticTable={false}
        stickyHeader
      />
      <div className="job-details-employee--footer">{paginationFooter}</div>
    </div>
  );
};

JobDetailsEmployeeTable.propTypes = propTypes;
JobDetailsEmployeeTable.defaultProps = defaultProps;

export default JobDetailsEmployeeTable;
