import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import { reducer as jobsSearch } from './jobs/jobsSearch';
import { reducer as jobsList } from './jobs/jobsList';
import { reducer as jobsPagination } from './jobs/jobsPagination';
import { reducer as jobSummaryStats } from './jobs/jobSummaryStats';
import { reducer as employeesSearch } from './employees/employeesSearch';
import { reducer as employeesList } from './employees/employeesList';
import { reducer as employeesPagination } from './employees/employeesPagination';
import { reducer as employeeSummary } from './employees/employeeSummary';
import { reducer as surveysSearch } from './surveys/surveysSearch';
import { reducer as surveysList } from './surveys/surveysList';
import { reducer as surveysPagination } from './surveys/surveysPagination';
import reportList from './home/reportList';
import homeEmployeeList from './home/homeEmployeeList.js';
import homeEmployeeCount from './home/homeEmployeeCount.js';
import homeJobList from './home/homeJobList';
import homeJobCount from './home/homeJobCount';
import homeGradeCount from './home/homeGradeCount';
import homeStatsRow from './home/homeStatsRow';
import jobPriceChart from './charts/jobPriceChart.js';
import marketPriceChart from './charts/marketPriceChart.js';
import marketRateChart from './charts/marketRateChart.js';
import universalSearch from './universalSearch/universalSearch';
import addJob from '../../../../ps-components/src/Modules/AddJob/reducers/addJob';
import jobCopy from './jobs/jobCopy';
import jobDetails from '../../../../ps-components/src/Modules/AddJob/reducers/jobDetails';
import jobAudit from './jobs/jobAudit';
import jobSurveyList from '../../../../ps-components/src/Modules/AddJob/reducers/jobSurveyList';
import jobPropagate from './jobs/jobPropagate';
import printJob from './jobs/printJob';
import jobSalaryTrend from './jobs/jobSalaryTrend';
import employeeDetails from './employees/employeeDetails';
import employeeEdit from './employees/employeeEdit';
import employeeAdd from './employees/employeeAdd';
import employeeComparison from './employees/employeeComparison';
import surveyDetails from './surveys/surveyDetails';
import skillsByJobList from '../../../../ps-components/src/Modules/AddJob/reducers/skillsByJobList';
import app from './app/app';
import resetPassword from './profiles/resetPassword';
import profile from './profiles/profile';
import jobComparison from './jobs/jobComparison';
import jobCertifications from './jobs/jobCertifications';
import jobFamilies from '../../../../ps-components/src/Modules/AddJob/reducers/jobFamilies';
import addJobUpdatedDetails from '../../../../ps-components/src/Modules/AddJob/reducers/addJobUpdatedDetails';
import suggestFamily from '../../../../ps-components/src/Modules/AddJob/reducers/suggestFamily';
import careerLevels from '../../../../ps-components/src/Modules/AddJob/reducers/careerLevels';
import suggestCareer from '../../../../ps-components/src/Modules/AddJob/reducers/suggestCareer';
import downloadList from './genericGrid/downloadList';
import structureChart from './structureModel/structureChart.js';
import competitiveSets from '../../../../ps-components/src/Modules/CompetitiveSets/reducers/competitiveSets';
import currentStructure from './structureModel/currentStructure.js';
import saveScenarioAsStructure from './structureModel/saveScenarioAsStructure.js';
import createStructure from './structureModel/createStructure.js';
import structureList from './structureModel/structureList.js';
import addJobStructureList from '../../../../ps-components/src/Modules/AddJob/reducers/addJobStructureList.js';
import modelStructureList from './structureModel/modelStructureList.js';
import { reducer as structuresSearch } from './structureModel/structureFilters';
import createStructureModal from './structureModel/createStructureModal';
import bulkDeleteJobs from './jobs/bulkDeleteJobs';
import bulkCopyJobs from './jobs/bulkCopyJobs';
import jobalyzerRefresh from '../../../../ps-components/src/Modules/AdminUtils/BulkRefresh/BulkRefreshReducer';
import insightLabMigrations from '../../../../ps-components/src/Modules/AdminUtils/InsightLabMigrations/InsightLabMigrationsReducer';
import siteList from '../../../../ps-components/src/Modules/AdminUtils/SiteList/SiteListReducer';
import sites from './sites/sites.js';
import singleSurveyJobsList from './surveys/singleSurveyJobsList';
import meritMatrix from './meritMatrix/meritMatrix';
import basePayCalc from './meritMatrix/basePayCalc';
import perfTest from './meritMatrix/perfTest';
import mmGroupSelection from './meritMatrix/mmGroupSelection';
import matrixGroupModel from './meritMatrix/matrixGroupModel';
import headStats from './meritMatrix/headStats';
import matrixSetExclusions from './meritMatrix/matrixSetExclusions';
import { default as matrixSearch } from './meritMatrix/matrixFacets';
import jobApproval from './jobCollab/jobApproval';
import refreshMarketReports from './app/refreshMarketReports';
import migrateCompanySurvey from './app/migrateCompanySurvey';
import reports from '../../../../ps-components/src/Modules/ReportLibrary/ReportReducer';
import confirmLocations from './confirmLocations/confirmLocations';
import jobRanges from '../../../../ps-components/src/Modules/JobRanges/reducers/jobRanges/index';
import siteBasics from '../../../../ps-components/src/Modules/SiteAdmin/SiteBasics/SiteBasicsReducer';
import userAdmin from '../../../../ps-components/src/Modules/SiteAdmin/UserAdmin/UserReducer';
import compPhilosophy from '../../../../ps-components/src/Modules/SiteAdmin/CompPhilosophy/CompPhilosophyReducer';
import performanceRatings from '../../../../ps-components/src/Modules/SiteAdmin/PerformanceRatings/PerformanceRatingsReducer';
import dataManagement from '../../../../ps-components/src/Modules/SiteAdmin/DataManagement/DataManagementReducer';
import features from '../../../../ps-components/src/FeatureFlags/FeatureFlagReducer';
import versionCheck from '../../../../ps-components/src/Modules/Messaging/VersionCheck/VersionCheckReducer';
import mergeJob from './jobs/mergeJob';
import employeesToMarket from './dashboard/employeesToMarket';
import slideout from '../../../../ps-components/src/Slideout/SlideoutReducer';
import participationCenter from '../../../../ps-components/src/Modules/ParticipationCenter/reducers/ParticipationCenterReducer';
import geoDifferential from '../../../../ps-components/src/Modules/PayScalePulse/GeoDifferential/GeoDifferentialReducer';

const rootReducer = history =>
  combineReducers({
    router: connectRouter(history),
    app,
    jobsSearch,
    jobsList,
    jobsPagination,
    jobSummaryStats,
    jobDetails,
    bulkDeleteJobs,
    bulkCopyJobs,
    jobAudit,
    jobSurveyList,
    printJob,
    jobSalaryTrend,
    employeesSearch,
    employeesList,
    employeesPagination,
    employeeSummary,
    employeeDetails,
    employeeEdit,
    employeeComparison,
    surveysSearch,
    surveysList,
    surveysPagination,
    surveyDetails,
    reportList,
    homeEmployeeList,
    homeEmployeeCount,
    homeJobList,
    homeJobCount,
    homeGradeCount,
    homeStatsRow,
    jobPriceChart,
    marketPriceChart,
    marketRateChart,
    universalSearch,
    addJob,
    skillsByJobList,
    resetPassword,
    profile,
    jobCopy,
    jobComparison,
    jobCertifications,
    jobFamilies,
    addJobUpdatedDetails,
    suggestFamily,
    careerLevels,
    suggestCareer,
    jobPropagate,
    downloadList,
    structureChart,
    structureList,
    addJobStructureList,
    modelStructureList,
    structuresSearch,
    competitiveSets,
    currentStructure,
    saveScenarioAsStructure,
    createStructure,
    createStructureModal,
    jobalyzerRefresh,
    insightLabMigrations,
    siteList,
    sites,
    features,
    singleSurveyJobsList,
    meritMatrix,
    basePayCalc,
    perfTest,
    mmGroupSelection,
    matrixGroupModel,
    headStats,
    matrixSetExclusions,
    matrixSearch,
    jobApproval,
    refreshMarketReports,
    migrateCompanySurvey,
    reports,
    confirmLocations,
    jobRanges,
    siteBasics,
    userAdmin,
    compPhilosophy,
    performanceRatings,
    dataManagement,
    versionCheck,
    mergeJob,
    employeesToMarket,
    employeeAdd,
    slideout,
    participationCenter,
    geoDifferential
  });

export default rootReducer;
