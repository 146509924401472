import React from 'react';
import { bool, func } from 'prop-types';

const propTypes = {
  onClick: func.isRequired
};

const GenerateReportButton = props => {
  return (
    <button className="pxl-btn pxl-btn-primary" onClick={props.onClick}>
      Generate Report
    </button>
  );
};

GenerateReportButton.propTypes = propTypes;
export default GenerateReportButton;
