import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './Cell.scss';

class Cell extends Component {
  static propTypes = {
    cellType: PropTypes.string,
    children: PropTypes.node,
    className: PropTypes.string,
    colSpan: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onClick: PropTypes.func,
    maxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    top: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    minWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    getCellProps: PropTypes.func,
    tableHeadProps: PropTypes.object,
    stickyLeft: PropTypes.bool,
    left: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    stickyRight: PropTypes.bool,
    right: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    zIndex: PropTypes.number,
    tooltip: PropTypes.string,
    position: PropTypes.string
  };

  static defaultProps = {
    onClick: () => {}
  };

  render() {
    const {
      cellType,
      children,
      className,
      colSpan,
      maxWidth,
      minWidth,
      onClick,
      width,
      top,
      getCellProps,
      tableHeadProps,
      stickyLeft,
      left,
      stickyRight,
      right,
      zIndex,
      tooltip,
      position
    } = this.props;

    const numberOfColumnsToSpan = colSpan ? colSpan : '1';

    const cellStyles = {};

    if (minWidth) {
      cellStyles.minWidth = parseInt(minWidth, 10) + 'px';
      if (tableHeadProps?.style) {
        tableHeadProps.style.minWidth = parseInt(minWidth, 10) + 'px';
      }
    }

    if (width) {
      cellStyles.width = width;
      if (tableHeadProps?.style) {
        tableHeadProps.style.width = width;
      }
    }

    if (top) {
      cellStyles.top = top;
      if (tableHeadProps?.style) {
        tableHeadProps.style.top = top;
      }
    }

    let cellProps = getCellProps && getCellProps();

    if (stickyLeft) {
      cellStyles.left = left;
      if (tableHeadProps?.style) {
        tableHeadProps.style.left = left;
      }
      if (cellProps?.style) {
        cellProps.style.left = left;
      }
    }

    if (stickyRight) {
      cellStyles.right = right;
      if (tableHeadProps?.style) {
        tableHeadProps.style.right = right;
      }
      if (cellProps?.style) {
        cellProps.style.right = right;
      }
    }

    if (zIndex) {
      cellStyles.zIndex = zIndex;
      if (tableHeadProps?.style) {
        tableHeadProps.style.zIndex = zIndex;
      }
      if (cellProps?.style) {
        cellProps.style.zIndex = zIndex;
      }
    }

    if (maxWidth) {
      cellStyles.maxWidth = parseInt(maxWidth, 10) + 'px';
      if (cellProps?.style) {
        cellProps.style.maxWidth = parseInt(maxWidth, 10) + 'px';
      }

      if (tableHeadProps?.style) {
        tableHeadProps.style.maxWidth = parseInt(maxWidth, 10) + 'px';
      }
    }

    let titleText;
    if (tooltip && typeof tooltip === 'string') {
      titleText = tooltip;
    }

    if (position || position === '') {
      if (tableHeadProps?.style) {
        tableHeadProps.style.position = position;
      }
    }

    return cellType === 'header' ? (
      <th
        {...(tableHeadProps && tableHeadProps)}
        className={`${className} ${stickyLeft || stickyRight ? 'table__cell-sticky-header' : ''}`}
        onClick={tableHeadProps ? tableHeadProps.onClick : onClick}
        title={titleText}
        style={tableHeadProps ? tableHeadProps.style : cellStyles}
      >
        {children}
      </th>
    ) : (
      <td
        {...cellProps}
        className={`table__cell ${className} ${stickyLeft || stickyRight ? 'table__cell-sticky' : ''}`}
        onClick={onClick}
        title={titleText}
        colSpan={numberOfColumnsToSpan}
        style={cellProps ? cellProps.style : cellStyles}
      >
        {children}
      </td>
    );
  }
}

export default Cell;
