import React, { Component } from 'react';
import PropTypes from 'prop-types';
import RangeChart from '../../../../RangeChart/RangeChart';
import './RangeChartList.scss';
import { applyRounding, parseNumCheckNull, getDefaultRoundings } from '../../../../lib/formatters';
import { currencyFormatter, getMarketReferenceValue } from '../../Utils/Utils';

const propTypes = {
  modelJobs: PropTypes.array,
  model: PropTypes.object,
  payType: PropTypes.string,
  instance: PropTypes.number,
  width: PropTypes.number,
  colors: PropTypes.array
};

const defaultProps = {
  width: 450,
  colors: ['#2D67B9', '#5389D5'] // $c4, $c4-light
};

export default class RangeChartList extends Component {
  static propTypes = propTypes;
  static defaultProps = defaultProps;

  constructor(props) {
    super(props);
    this.row = this.row.bind(this);
  }

  bottomLabelFormatter = (val, index, context) => {
    const { preferences } = this.props;
    if (index === 1) return '';
    return typeof val === 'number'
      ? parseNumCheckNull(applyRounding(val, 'monetary', preferences, context.payType))
      : val;
  };

  row(job) {
    let rangeValues = [job.salary_minimum, job.salary_midpoint, job.salary_maximum];
    let proposedRangeValues = [job.proposed_minimum, job.proposed_midpoint, job.proposed_maximum];
    if (!job.salary_minimum || !job.salary_midpoint || !job.salary_maximum) rangeValues = [];
    if (!job.proposed_midpoint) proposedRangeValues = [];

    let scaleMax = Math.max(...rangeValues, ...proposedRangeValues);
    let scaleMin = Math.min(...rangeValues, ...proposedRangeValues);

    const { model, colors, instance, width, payType } = this.props;

    const referenceValue = getMarketReferenceValue(model, job);
    if (referenceValue && job.salary_midpoint) {
      const scaleAvg = (scaleMin + scaleMax) / 2;
      const diff = referenceValue - scaleAvg;
      if (diff > 0) scaleMax = 2 * referenceValue - scaleMin;
      else scaleMin = 2 * referenceValue - scaleMax;
    }

    let instanceNum = '-instance' + (instance || '1');
    return (
      <div className={'chart-pair'} key={job.id}>
        <RangeChart
          className="chart1"
          labelsVisible
          data={rangeValues}
          height={20}
          width={width}
          scaleMax={scaleMax}
          scaleMin={scaleMin}
          barHeight={15}
          bottomLabelFormatter={this.bottomLabelFormatter}
          hasPointer={false}
          hideTopLabels
          labelToBarSpacingTop={5}
          labelToLabelMinSpacing={75}
          labelTextAnchor={['start', 'middle', 'end']}
          invertLabels
          colors={colors}
          roundToNearest={getDefaultRoundings(this.props.preferences, this.props.payType)?.monetaryRounding}
          payType={payType}
          id={job.id + instanceNum}
        />
        <RangeChart
          className="chart2"
          labelsVisible
          data={proposedRangeValues}
          height={20}
          width={width}
          scaleMax={scaleMax}
          scaleMin={scaleMin}
          barHeight={15}
          bottomLabelFormatter={this.bottomLabelFormatter}
          hasPointer={false}
          hideTopLabels
          labelToBarSpacingBottom={15}
          labelToLabelMinSpacing={75}
          labelTextAnchor={['start', 'middle', 'end']}
          colors={['#22D6DD', '#A7EFF1']} // $c1, $c1-xlight
          roundToNearest={getDefaultRoundings(this.props.preferences, this.props.payType)?.monetaryRounding}
          payType={payType}
          id={job.id + instanceNum + '-chart2'}
        />
      </div>
    );
  }

  render() {
    if (!this.props.modelJobs || !this.props.modelJobs.length) return null;

    const list = this.props.modelJobs.map(job => this.row(job));
    if (this.props.hasOwnProperty('instance')) {
      return (
        <div className="chart-container jbr__chart-container2">
          <div className="chart-list-header">Market Reference Point</div>
          <div className="market-average-line2" />
          {list}
        </div>
      );
    } else {
      return (
        <div className="chart-container jbr__chart-container">
          <div className="chart-list-header">Market Reference Point</div>
          <div className="market-average-line" />
          {list}
        </div>
      );
    }
  }
}
