import React, { Component } from 'react';
import { number, string } from 'prop-types';

import './TruncatedString.scss';

export default class TruncatedString extends Component {
  static propTypes = {
    children: string,
    className: string,
    maxChar: number
  };

  constructor(props) {
    super(props);

    this.state = {
      linkTextFull: '...less',
      linkTextShort: '...more',
      show: false
    };
  }

  _onClick = event => {
    event.stopPropagation();
    this.setState({ show: !this.state.show });
  };

  _shortenText() {
    const { children, maxChar } = this.props;
    const maxIndex = children.lastIndexOf(' ', maxChar - 1);
    return children.slice(0, maxIndex);
  }

  render() {
    const { linkTextFull, linkTextShort, show } = this.state;
    const { children, className, maxChar } = this.props;

    if (!children) {
      return null;
    }

    if (maxChar >= children.length || !maxChar) {
      return <span>{children}</span>;
    }

    if (show) {
      return (
        <span className={className}>
          {children}&nbsp;
          <span className="text__link" onClick={e => this._onClick(e)}>
            {linkTextFull}
          </span>
        </span>
      );
    }

    return (
      <span className={className}>
        {this._shortenText()}&nbsp;
        <span className="text__link" onClick={e => this._onClick(e)}>
          {linkTextShort}
        </span>
      </span>
    );
  }
}
