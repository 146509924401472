const BUDGET_VAL_CALC = 'BUDGET_VAL_CALC';
const BUDGET_PCT_CALC = 'BUDGET_PCT_CALC';

const TEAM_DOWNLOAD = 'TEAM_DOWNLOAD';
const TEAM_DOWNLOAD_SUCCESS = 'TEAM_DOWNLOAD_SUCCESS';
const TEAM_DOWNLOAD_FAIL = 'TEAM_DOWNLOAD_FAIL';

const MATRIX_CHANGE_STATUS = 'MATRIX_CHANGE_STATUS';
const MATRIX_CHANGE_STATUS_SUCCESS = 'MATRIX_CHANGE_STATUS_SUCCESS';
const MATRIX_CHANGE_STATUS_FAIL = 'MATRIX_CHANGE_STATUS_FAIL';

const PERFORMANCE_CHANGE_CLICK = 'PERFORMANCE_CHANGE_CLICK';
const CHANGE_MATRIX_RANGE_TYPE = 'CHANGE_MATRIX_RANGE_TYPE';

const EMPLOYEE_GROUP_DOWNLOAD = 'EMPLOYEE_GROUP_DOWNLOAD';
const EMPLOYEE_GROUP_DOWNLOAD_SUCCESS = 'EMPLOYEE_GROUP_DOWNLOAD_SUCCESS';
const EMPLOYEE_GROUP_DOWNLOAD_FAIL = 'EMPLOYEE_GROUP_DOWNLOAD_FAIL';

import { saveMatrixModel, getGroupModelDataFromState, calculateMatrixModel } from './matrixGroupModel';
import { calcSummaryStats } from './groupList';

const initialState = {
  loading: false,
  loaded: false,
  error: null,
  currentBasePay: 1800000,
  budgetIncreasePct: 3,
  budgetIncreaseVal: 54000,
  newBasePay: 1854000,
  department: 'Administration',
  status: 'STARTED',
  downloadLoading: false,
  sendToTeamLoading: false,
  performanceChangeVisible: false,
  selectedPerformanceItem: null,
  mmDownloading: false
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case 'GET_MATRIX_GROUP_SUCCESS':
      const {
        workflowStatus,
        currentBasePay,
        budgetIncreasePercent,
        fieldValues,
        matrixRangeType
      } = action.result.groupModel;
      let rangeType = {};
      if (matrixRangeType == 'market') {
        rangeType = { value: 'market', label: 'Market + Performance' };
      } else {
        rangeType = { value: 'range', label: 'Range + Performance' };
      }
      return {
        ...state,
        status: workflowStatus,
        currentBasePay,
        budgetIncreasePct: Math.round(budgetIncreasePercent * 1000) / 10,
        budgetIncreaseVal: Math.round(currentBasePay * budgetIncreasePercent),
        newBasePay: currentBasePay + currentBasePay * budgetIncreasePercent,
        department: fieldValues.map(field => field.fieldValue).join(', '),
        selectedPerformanceItem: rangeType
      };
    case BUDGET_VAL_CALC:
      //
      return {
        ...state,
        budgetIncreasePct: action.isInputUndefined ? undefined : action.budgetIncreasePct,
        budgetIncreaseVal: action.budgetIncreaseVal,
        newBasePay: action.newBasePay
      };
    case BUDGET_PCT_CALC:
      return {
        ...state,
        budgetIncreaseVal: action.isInputUndefined ? undefined : action.budgetIncreaseVal,
        budgetIncreasePct: action.budgetIncreasePct,
        newBasePay: action.newBasePay
      };
    case 'MATRIX_INPUT_BLUR':
      return {
        ...state,
        budgetIncreaseVal: action.budgetIncreaseVal,
        budgetIncreasePct: action.budgetIncreasePct
      };
    case TEAM_DOWNLOAD:
      return {
        ...state,
        downloadLoading: true
      };
    case TEAM_DOWNLOAD_SUCCESS:
      return {
        ...state,
        downloadLoading: false
      };
    case TEAM_DOWNLOAD_FAIL:
      return {
        ...state,
        downloadLoading: false
      };
    case MATRIX_CHANGE_STATUS:
      return {
        ...state,
        downloadLoading: true,
        status: action.isCurrentGroup ? action.newStatus : state.status
      };
    case MATRIX_CHANGE_STATUS_SUCCESS:
      return {
        ...state,
        downloadLoading: false
      };
    case MATRIX_CHANGE_STATUS_FAIL:
      return {
        ...state,
        downloadLoading: false
      };
    case PERFORMANCE_CHANGE_CLICK:
      return {
        ...state,
        performanceChangeVisible: !state.performanceChangeVisible
      };
    case CHANGE_MATRIX_RANGE_TYPE:
      return {
        ...state,
        performanceChangeVisible: !state.performanceChangeVisible,
        selectedPerformanceItem: action.selectedPerformanceItem
      };
    case EMPLOYEE_GROUP_DOWNLOAD:
      return {
        ...state,
        mmDownloading: true
      };
    case EMPLOYEE_GROUP_DOWNLOAD_SUCCESS:
      const prettyName = action.result.resultFileName;
      const downloadType = action.result.resultFileType;
      const fileName = prettyName + '.' + downloadType;
      const downloadLocation =
        window.app.apiPrefix +
        'api/generic-utils/generic-download/downloadGeneric?pretty_name=' +
        prettyName +
        '&file_name=' +
        fileName +
        '&download_type=' +
        downloadType;
      window.location = downloadLocation;
      return {
        ...state,
        mmDownloading: false
      };
    case EMPLOYEE_GROUP_DOWNLOAD_FAIL:
      return {
        ...state,
        mmDownloading: false
      };
    default:
      return state;
  }
}

export function changeStatus(info) {
  return (dispatch, getState) => {
    const isCurrentGroup = getState().matrixGroupModel.matrixGroupId === info.id;
    const { newStatus } = info;
    const ajaxPromise = dispatch({
      types: ['MATRIX_CHANGE_STATUS', 'MATRIX_CHANGE_STATUS_SUCCESS', 'MATRIX_CHANGE_STATUS_FAIL'],
      promise: client => client.get('/api/matrix-model/changeStatus', { params: info }),
      newStatus,
      matrixGroupId: info.id,
      isCurrentGroup
    });
    ajaxPromise
      .then(() => {
        // dispatch(getAuditHistory(jobId));
      })
      .catch(err => {
        console.error('Error changing matrix status', err);
      });
  };
}

export function calcGlobalBudget(groupItems, budgetIncreaseVal, matrixGroupId) {
  let totalIncreaseAmt = 0;
  let totalBasePay = 0;
  for (let i = 0; i < groupItems.length; i++) {
    // calculation from group hasn't been applied to the list yet
    totalIncreaseAmt +=
      groupItems[i].matrixGroupId === matrixGroupId ? budgetIncreaseVal : groupItems[i].budgetIncreaseAmt;
    totalBasePay += groupItems[i].currentBasePay;
  }

  const globalPct = (totalIncreaseAmt / totalBasePay) * 100;
  return { globalPct, globalAmt: totalIncreaseAmt };
}

export function calcBudgetVal(options) {
  let newBasePay;
  let budgetVal;
  let isInputUndefined;

  let budgetIncreasePct = options.budgetIncreasePct
    .replace(/[^0-9-.]/g, '')
    .replace(/-/g, '')
    .replace(/([^.]*\.[^.]*)\./g, '$1');
  const budgetIncreasePctNum = parseFloat(budgetIncreasePct);

  if (!budgetIncreasePctNum && budgetIncreasePctNum !== 0) {
    newBasePay = options.currentBasePay;
    budgetVal = 0;
    budgetIncreasePct = 0;
    isInputUndefined = true;
  } else {
    budgetVal = Math.round(options.currentBasePay * (budgetIncreasePctNum / 100));
    newBasePay = parseFloat(budgetVal + options.currentBasePay);
  }

  return (dispatch, getState) => {
    const matrixGroupId = getState().matrixGroupModel.matrixGroupId;
    const globalBudget = calcGlobalBudget(
      getState().meritMatrix.groupList.groupItems,
      parseFloat(budgetVal),
      matrixGroupId
    );

    dispatch({
      type: 'BUDGET_VAL_CALC',
      budgetIncreasePct: budgetIncreasePct,
      budgetIncreaseVal: budgetVal,
      newBasePay: newBasePay,
      globalPct: globalBudget.globalPct,
      globalAmt: globalBudget.globalAmt,
      matrixGroupId,
      isInputUndefined
    });

    if (budgetIncreasePctNum || budgetIncreasePctNum === 0) {
      dispatch(calculateMatrixModel({}));
      dispatch(saveMatrixModel());
    }
  };
}

const cleanBudgetValInput = value => {
  const replaceNonDigits = value.replace(/,|\D/gi, '');
  if (value === '') return undefined;
  return parseFloat(replaceNonDigits);
};

export function calcBudgetPct(options) {
  let newBasePay;
  let budgetPct;
  let isInputUndefined;
  let budgetIncreaseVal = cleanBudgetValInput(options.value);

  if (!budgetIncreaseVal && budgetIncreaseVal !== 0) {
    newBasePay = options.currentBasePay;
    budgetPct = 0;
    budgetIncreaseVal = 0;
    isInputUndefined = true;
  } else {
    budgetPct = (budgetIncreaseVal / options.currentBasePay) * 100;
    budgetPct = Math.round(budgetPct * 10) / 10;
    newBasePay = Math.round(budgetIncreaseVal + options.currentBasePay);
  }

  return (dispatch, getState) => {
    const matrixGroupId = getState().matrixGroupModel.matrixGroupId;
    const globalBudget = calcGlobalBudget(
      getState().meritMatrix.groupList.groupItems,
      budgetIncreaseVal,
      matrixGroupId
    );
    dispatch({
      type: 'BUDGET_PCT_CALC',
      budgetIncreaseVal: budgetIncreaseVal,
      budgetIncreasePct: budgetPct,
      newBasePay: newBasePay,
      globalPct: globalBudget.globalPct,
      globalAmt: globalBudget.globalAmt,
      matrixGroupId,
      isInputUndefined
    });
    dispatch(calculateMatrixModel({}));
    dispatch(saveMatrixModel());
  };
}

export const matrixGroupInputBlur = () => (dispatch, getState) => {
  const { budgetIncreaseVal, budgetIncreasePct } = getState().basePayCalc;
  dispatch({
    type: 'MATRIX_INPUT_BLUR',
    budgetIncreasePct: budgetIncreasePct || 0,
    budgetIncreaseVal: budgetIncreaseVal || 0
  });
};

export function downloadTeam(options) {
  return dispatch => {
    dispatch({
      types: [TEAM_DOWNLOAD, TEAM_DOWNLOAD_SUCCESS, TEAM_DOWNLOAD_FAIL],
      promise: client => client.post('api/hris/employees/sendAllEmployeeData')
    });
  };
}

export function performanceChangeClick() {
  return dispatch => {
    dispatch({
      type: 'PERFORMANCE_CHANGE_CLICK'
    });
  };
}

export const changeMatrixRangeType = selectedPerformanceItem => {
  return (dispatch, getState) => {
    const data = getGroupModelDataFromState(getState());
    data.groupModel.matrixRangeType = selectedPerformanceItem.value;
    const promise = dispatch({
      types: ['CHANGE_MATRIX_RANGE_TYPE', 'CHANGE_MATRIX_RANGE_TYPE_SUCCESS', 'CHANGE_MATRIX_RANGE_TYPE_FAIL'],
      promise: client => client.post('/api/matrix-cells/changeMatrixRangeType', { data }),
      selectedPerformanceItem
    });
    promise
      .then((res, err) => {
        if (!err && res.success !== false) {
          if (data.matrixSetId) dispatch(calcSummaryStats(data.matrixSetId));
        }
      })
      .catch(err => {
        console.error('Error changing matrix range type', err);
      });
  };
};

export function matrixDownload(matrixSetId, matrixGroupId, currency) {
  return dispatch => {
    dispatch({
      types: [EMPLOYEE_GROUP_DOWNLOAD, EMPLOYEE_GROUP_DOWNLOAD_SUCCESS, EMPLOYEE_GROUP_DOWNLOAD_FAIL],
      promise: client =>
        client.post('api/matrix-download/downloadMatrix', {
          params: {
            matrixSetId: matrixSetId,
            matrixGroupId: Number(matrixGroupId),
            currency: currency
          }
        })
    });
  };
}
