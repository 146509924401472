import React, { useEffect, useState } from 'react';
import { useIdentity } from '@payscale/identity-auth-react-client';
import InstrumentedLoadingIndicator from '../components/InstrumentedLoadingIndicator';
import sessionDispatch from '../reducers/session';
import apiClient from '../../../ps-components/src/lib/apiClient';
import Raven from 'raven-js';

async function manageRouteChange({ logout, currPath, nextPath, history, reject, recordUserActivity }) {
  try {
    await apiClient.apiGet('/api/hris/job-details/isLoggedIn');
    recordUserActivity();
  } catch (err) {
    if (err.response) {
      const responseCode = err.response.status;
      console.error('non-200 response for isLoggedIn', responseCode, err);
      // TODO differentiate between these conditions
      if (responseCode === 401 || responseCode === 403) {
        console.warn(`isLoggedIn returned code ${responseCode} on navigating from ${currPath} to ${nextPath}`);
        logout({ message: 'Attempted navigation when no longer logged in' });
      } else if (typeof Raven !== 'undefined') {
        Raven.captureException(err);
      }
    } else {
      console.error('error checking isLoggedIn', err); // XXX Raven?
    }
  }
}

function createRouteChangeListener({ history, store, logout, recordUserActivity }) {
  const { dispatch, getState } = store;
  let pathOfFailedAuth = '';

  // NOTE consider saving destination so that redirect works after login
  // NOTE consider modal login instead of full redirect
  // XXX prevent components from loading if API server login is no longer valid
  const unListen = history.beforeTransition(({ currPath, nextPath }) => {
    if (nextPath === '/logout') return; // no need to check session timeout when trying to logout

    if (nextPath !== pathOfFailedAuth) {
      manageRouteChange({
        dispatch,
        getState,
        logout,
        currPath,
        nextPath,
        history,
        reject: path => {
          pathOfFailedAuth = path;
        },
        recordUserActivity
      });
    }
    if (nextPath === pathOfFailedAuth) pathOfFailedAuth = '';
  });

  return unListen;
}

export default function SessionProvider({ children, history, store }) {
  const { user, isAuthenticated, logout, recordUserActivity } = useIdentity();
  const [isReady, setReady] = useState(false);

  useEffect(() => {
    if (!isReady) return;

    createRouteChangeListener({ history, store, logout, recordUserActivity });
  }, [isReady]); // XXX is store going stale?

  useEffect(() => {
    if (isAuthenticated) {
      console.debug('new login detected, calling sessionDispatch', user.profile);
      sessionDispatch({ store, logout })
        .then(() => setReady(true))
        .catch(error => {
          // TODO display message via UI
          console.error('Fatal error retrieving session from API server', error);
        });
    } else {
      console.debug('User is not authenticated, clearing locally stored user data');
      store.dispatch({ type: 'USER_LOGGED_OUT' });
    }
  }, [isAuthenticated]);

  if (isReady) return children;
  else return <InstrumentedLoadingIndicator loading={true} ddRumUserAction="SessionProviderLoadingIndicatorMs" />;
}
