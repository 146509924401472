import React, { Component } from 'react';
import PropTypes, { arrayOf, bool, func, object, oneOfType, number, shape, string } from 'prop-types';
import classnames from 'classnames';
import FacetGroup from './FacetGroup';
import FacetPanelHeader from './FacetPanelHeader';
import './FacetPanel.scss';

class FacetPanel extends Component {
  static propTypes = {
    addButtonText: string,
    className: string,
    customFacetRender: func,
    facetItemClick: func.isRequired,
    facetReset: func.isRequired,
    facetSelection: object.isRequired,
    facets: arrayOf(
      shape({
        facetSort: number,
        id: string,
        items: arrayOf(
          shape({
            key: oneOfType([number, string]),
            doc_count: number
          })
        ),
        max: number,
        min: number,
        step: number,
        title: string,
        type: string,
        value: arrayOf(number),
        minLabel: string,
        maxLabel: string,
        minLabelInBoundary: string,
        maxLabelInBoundary: string,
        collapsible: bool,
        collapsedDefault: bool
      })
    ).isRequired,
    facetDistinctItemClick: func,
    fromEmployeePage: bool,
    getLabels: func,
    handleAddItem: func,
    hiddenFacets: arrayOf(string),
    history: object,
    isDisabled: bool,
    isLoaded: bool.isRequired,
    isLoading: bool,
    lastFacetFilteredID: string,
    limit: number,
    placeholderText: string,
    query: string,
    rangeChange: func,
    rangeHandleStyle: PropTypes.array,
    resetFacetText: string,
    rangeTrackStyle: PropTypes.array,
    resetSearch: func.isRequired,
    searchInputSearch: func,
    setSearchQuery: func,
    showAddButton: bool,
    shouldShowFacet: func,
    showFacetSectionSearch: bool,
    showResetButton: bool,
    showSearchbox: bool,
    showZeros: bool,
    title: string,
    innerClassName: string,
    isUncheckedInitially: bool,
    canCollapse: bool,
    id: string,
    collapseCallback: func,
    collapseTooltip: string,
    children: object,
    headerRender: object,
    renderChildren: bool,
    lockOtherFacets: bool,
    scrollHeightCutoff: number
  };

  static defaultProps = {
    canCollapse: false,
    collapseTooltip: 'Toggle facet',
    hiddenFacets: ['org_job_id'],
    showFacetSectionSearch: false,
    isUncheckedInitially: false
  };

  constructor(props) {
    super(props);
    const initialOpenState = localStorage.getItem(props.id + '_facet_open');
    if (initialOpenState && this.props.collapseCallback) this.props.collapseCallback(initialOpenState === 'true');
    this.state = { resetAllTriggered: false, open: initialOpenState ? initialOpenState === 'true' : true };
    this.filterFacet = this.filterFacet.bind(this);
    this.renderFacetGroups = this.renderFacetGroups.bind(this);
    this.renderHeader = this.renderHeader.bind(this);
    this.resetSearch = this.resetSearch.bind(this);
    this.resetState = this.resetState.bind(this);
    this.toggleFacetPanel = this.toggleFacetPanel.bind(this);
  }

  filterFacet(facet) {
    return !this.props.hiddenFacets.includes(facet.id);
  }

  renderFacetGroups() {
    const {
      facetDistinctItemClick,
      facetItemClick,
      facetReset,
      facets,
      facetSelection,
      getLabels,
      hiddenFacets,
      isDisabled,
      isLoaded,
      isLoading,
      lastFacetFilteredID,
      limit,
      rangeChange,
      rangeHandleStyle,
      rangeTrackStyle,
      shouldShowFacet,
      showFacetSectionSearch,
      showZeros,
      isUncheckedInitially,
      lockOtherFacets,
      scrollHeightCutoff
    } = this.props;
    const { resetAllTriggered } = this.state;

    if (isLoaded && !isLoading) {
      let facetList = facets;

      if (hiddenFacets) {
        facetList = facets.filter(this.filterFacet);
      }

      facetList = facetList.map(facet => {
        if (shouldShowFacet && !shouldShowFacet(facet)) {
          return;
        }

        return (
          <FacetGroup
            facet={facet}
            facetDistinctItemClick={facetDistinctItemClick}
            facetItemClick={facetItemClick}
            facetReset={facetReset}
            facetSelection={facetSelection}
            getLabels={getLabels}
            isDisabled={isDisabled}
            key={facet.id}
            lastFacetFilteredID={lastFacetFilteredID}
            limit={limit}
            rangeChange={rangeChange}
            rangeHandleStyle={rangeHandleStyle}
            rangeTrackStyle={rangeTrackStyle}
            resetAllTriggered={resetAllTriggered}
            resetState={this.resetState}
            showFacetSectionSearch={showFacetSectionSearch}
            showZeros={showZeros}
            isUncheckedInitially={isUncheckedInitially}
            lockOtherFacets={lockOtherFacets}
            scrollHeightCutoff={scrollHeightCutoff}
          />
        );
      });
      return facetList;
    }
  }

  toggleFacetPanel() {
    const newOpenState = !this.state.open;
    localStorage.setItem(this.props.id + '_facet_open', newOpenState);
    this.setState({ open: newOpenState }, () => {
      this.props.collapseCallback && this.props.collapseCallback(newOpenState);
    });
  }

  renderHeader() {
    const {
      addButtonText,
      customFacetRender,
      handleAddItem,
      placeholderText,
      resetFacetText,
      searchInputSearch,
      showAddButton,
      showResetButton,
      showSearchbox,
      title,
      query,
      setSearchQuery,
      history,
      fromEmployeePage,
      canCollapse,
      collapseTooltip,
      renderChildren,
      headerRender
    } = this.props;
    return (
      <FacetPanelHeader
        addButtonText={addButtonText}
        canCollapse={canCollapse}
        collapseTooltip={collapseTooltip}
        toggleFacetPanel={this.toggleFacetPanel}
        facetShow={this.state.open}
        customFacetRender={customFacetRender}
        handleAddItem={handleAddItem}
        placeholderText={placeholderText}
        searchInputSearch={searchInputSearch}
        showAddButton={showAddButton}
        showResetButton={showResetButton}
        showSearchBox={showSearchbox}
        resetFacetText={resetFacetText}
        resetSearch={this.resetSearch}
        title={title}
        query={query}
        setSearchQuery={setSearchQuery}
        history={history}
        fromEmployeePage={fromEmployeePage}
        renderChildren={renderChildren}
        headerRender={headerRender}
      />
    );
  }

  resetSearch() {
    const { resetSearch } = this.props;

    resetSearch();
    this.setState({ resetAllTriggered: true });
  }

  resetState() {
    this.setState({ resetAllTriggered: false });
  }

  render() {
    const { className, innerClassName, canCollapse, children, renderChildren } = this.props;
    const facetPanelClass = classnames({
      'facet__panel-container': true,
      open: this.state.open,
      closed: !this.state.open && canCollapse,
      [className]: className
    });

    return (
      <div className={facetPanelClass}>
        {this.renderHeader()}
        {(renderChildren && children) || (
          <div className={classnames('facets', innerClassName)}>{this.renderFacetGroups()}</div>
        )}
      </div>
    );
  }
}

export default FacetPanel;
