import React, { Component } from 'react';
import { arrayOf, shape, string, oneOfType, number } from 'prop-types';
import classnames from 'classnames';
import './SubLabelList.scss';

class SubLabelList extends Component {
  static propTypes = {
    className: string,
    subLabels: arrayOf(
      shape({
        label: string,
        text: oneOfType[(string, number)]
      })
    )
  };

  render() {
    const { className, subLabels } = this.props;

    return (
      <div className={classnames(className, 'sublabel-list')}>
        {subLabels.map((el, idx) => {
          return (
            <div key={'sublabel-list-' + idx} className={'sublabel-list__label-container'}>
              <span className={'sublabel-list__label'}>{el.label}</span>
              <span className={'sublabel-list__text'} title={el.text}>
                {el.text}
              </span>
            </div>
          );
        })}
      </div>
    );
  }
}

export default SubLabelList;
