import React, { Component } from 'react';
import { array, number, object, string } from 'prop-types';
import AdvancedCell from '../../../../../AdvancedTable/AdvancedCell';
import { applyRounding, parseNumCheckNull } from '../../../../../lib/formatters';
import './JobDetailsEmployeeTableRow.scss';

const addZero = num => (num < 10 ? `0${num}` : `${num}`);

class JobDetailsEmployeeTableRow extends Component {
  static propTypes = {
    baseSalary: number,
    cells: array,
    costToMin: number,
    currentSalaryMaximum: number,
    currentSalaryMidpoint: number,
    currentSalaryMinimum: number,
    dateInPosition: string,
    employeeNumber: string,
    fullName: string,
    payType: string,
    preferences: object,
    proposedSalaryMaximum: number,
    proposedSalaryMidpoint: number,
    proposedSalaryMinimum: number
  };

  formatDate(dateString) {
    if (dateString === null || dateString === undefined) return '';

    const jsDate = new Date(dateString);
    const monthPretty = addZero(jsDate.getMonth() + 1);
    const dayPretty = addZero(jsDate.getDate());
    const yearPretty = addZero(jsDate.getFullYear());

    return `${monthPretty}-${dayPretty}-${yearPretty}`;
  }

  getAboveMaximum(baseSalary, salaryMaximum) {
    const { payType, preferences } = this.props;

    if (salaryMaximum === undefined || salaryMaximum === null) {
      return null;
    } else {
      const value = baseSalary - salaryMaximum;
      //  do not show if value is negative or 0
      if (value > 0) return parseNumCheckNull(applyRounding(value, 'monetary', preferences));
      else return null;
    }
  }

  getCostToMin(baseSalary, salaryMinimum) {
    const { payType, preferences } = this.props;

    if (salaryMinimum === undefined || salaryMinimum === null) {
      return null;
    } else {
      const value = salaryMinimum - baseSalary;
      //  do not show if value is negative or 0
      if (value > 0) return parseNumCheckNull(applyRounding(value, 'monetary', preferences));
      else return null;
    }
  }
  getRangePenetration(baseSalary, salaryMinimum, salaryMaximum) {
    const { preferences } = this.props;
    const x = baseSalary - salaryMinimum;
    const y = salaryMaximum - salaryMinimum;
    const rangePen = (x / y) * 100;

    return y === 0 ? null : applyRounding(rangePen, 'percent', preferences);
  }

  getCompaRatio(baseSalary, salaryMidpoint) {
    const { preferences } = this.props;
    if (!salaryMidpoint) return null;
    else {
      const compaRatio = (baseSalary / salaryMidpoint) * 100;
      return applyRounding(compaRatio, 'percent', preferences);
    }
  }

  getMidpoint(salaryMidpoint) {
    const { payType, preferences } = this.props;
    if (!salaryMidpoint) return null;
    else return parseNumCheckNull(applyRounding(salaryMidpoint, 'monetary', preferences, payType));
  }

  render() {
    const {
      baseSalary,
      cells,
      costToMin,
      currentSalaryMaximum,
      currentSalaryMidpoint,
      currentSalaryMinimum,
      dateInPosition,
      employeeNumber,
      fullName,
      payType,
      preferences,
      proposedSalaryMaximum,
      proposedSalaryMidpoint,
      proposedSalaryMinimum
    } = this.props;
    const datePretty = this.formatDate(dateInPosition);

    //  COST TO MIN CALCULATION
    const currentCostToMin = this.getCostToMin(baseSalary, currentSalaryMinimum);
    const proposedCostToMin = this.getCostToMin(baseSalary, proposedSalaryMinimum);

    //  ABOVE MAX CALCULATION
    const currentAboveMax = this.getAboveMaximum(baseSalary, currentSalaryMaximum);
    const proposedAboveMax = this.getAboveMaximum(baseSalary, proposedSalaryMaximum);

    //  MIDPOINT CALCULATION
    const currentMidPoint = this.getMidpoint(currentSalaryMidpoint);
    const proposedMidpoint = this.getMidpoint(proposedSalaryMidpoint);

    //  RANGE PENETRATION CALCULATION
    const currentRangePenetration = this.getRangePenetration(baseSalary, currentSalaryMinimum, currentSalaryMaximum);
    const proposedRangePenetration = this.getRangePenetration(baseSalary, proposedSalaryMinimum, proposedSalaryMaximum);

    //  COMPA RATIO CALCULATION
    const currentCompaRatio = this.getCompaRatio(baseSalary, currentSalaryMidpoint);
    const proposedCompaRatio = this.getCompaRatio(baseSalary, proposedSalaryMidpoint);

    return (
      <React.Fragment>
        <AdvancedCell className="employee-cell" getCellProps={cells[0].getCellProps}>
          <div>{fullName}</div>
          <div className="employee-id">{employeeNumber}</div>
        </AdvancedCell>
        <AdvancedCell getCellProps={cells[1].getCellProps}>{datePretty}</AdvancedCell>
        <AdvancedCell className="center-align" getCellProps={cells[2].getCellProps}>
          {parseNumCheckNull(applyRounding(baseSalary, 'monetary', preferences, payType))}
        </AdvancedCell>
        <AdvancedCell className="center-align" getCellProps={cells[3].getCellProps}>
          <div className="current-salary-data-content">{currentMidPoint}</div>
          <div className="proposed-salary-data-content">{proposedMidpoint}</div>
        </AdvancedCell>
        <AdvancedCell className="center-align" getCellProps={cells[4].getCellProps}>
          <div className="current-salary-data-content">{currentCompaRatio}</div>
          <div className="proposed-salary-data-content">{proposedCompaRatio}</div>
        </AdvancedCell>
        <AdvancedCell className="center-align" getCellProps={cells[5].getCellProps}>
          <div className="current-salary-data-content">{currentRangePenetration}</div>
          <div className="proposed-salary-data-content">{proposedRangePenetration}</div>
        </AdvancedCell>
        <AdvancedCell className="center-align" getCellProps={cells[6].getCellProps}>
          <div className="current-salary-data-content">{currentCostToMin}</div>
          <div className="proposed-salary-data-content">{proposedCostToMin}</div>
        </AdvancedCell>
        <AdvancedCell className="center-align" getCellProps={cells[7].getCellProps}>
          <div className="current-salary-data-content">{currentAboveMax}</div>
          <div className="proposed-salary-data-content">{proposedAboveMax}</div>
        </AdvancedCell>
      </React.Fragment>
    );
  }
}

export default JobDetailsEmployeeTableRow;
