/* eslint-disable no-case-declarations */
import qs from 'qs';
import { genericGridSuffix } from './../AdvancedTable/GenericGrid/GenericGridCreator';
import initList from '../../../panther-react/src/reducers/modules/genericGrid/listPanel';

const defaultInitialState = {
  offset: 0,
  moreResultsLoading: false,
  facetIsCheckedByDefault: true,
  facetSelection: {},
  facets: [],
  additionalFacets: [],
  loading: false,
  loaded: false,
  error: null,
  items: [],
  query: '',
  hideFacets: [],
  showZeros: true,
  include: {},
  exclude: {},
  limit: 50
};

const ES_QUERY_PARAM_KEY = 'q';

export default class SearchPanel {
  constructor(nameSpace, initialState, options = {}) {
    this.PAGE_LIST = `${nameSpace}_PAGE_LIST`;
    this.SEARCH = options.useCMS ? `${nameSpace + genericGridSuffix.SEARCH}` : `${nameSpace}_SEARCH`;
    this.SEARCH_SUCCESS = options.useCMS
      ? `${nameSpace + genericGridSuffix.SEARCH_SUCCESS}`
      : `${nameSpace}_SEARCH_SUCCESS`;
    this.SEARCH_FAIL = options.useCMS ? `${nameSpace + genericGridSuffix.SEARCH_FAIL}` : `${nameSpace}_SEARCH_FAIL`;
    this.MORE_RESULTS = `${nameSpace}_MORE_RESULTS`;
    this.MORE_RESULTS_SUCCESS = `${nameSpace}_MORE_RESULTS_SUCCESS`;
    this.MORE_RESULTS_FAIL = `${nameSpace}_MORE_RESULTS_FAIL`;
    this.LOOKUP = `${nameSpace}_LOOKUP`;
    this.LOOKUP_SUCCESS = `${nameSpace}_LOOKUP_SUCCESS`;
    this.LOOKUP_FAIL = `${nameSpace}_LOOKUP_FAIL`;
    this.TOGGLE_FACET = `${nameSpace}_TOGGLE_FACET`;
    this.ONLY_FACET = `${nameSpace}_ONLY_FACET`;
    this.PAGINATION_SUCCESS = `${nameSpace}_SEARCH_SUCCESS`;
    this.RANGE_CHANGE = `${nameSpace}_RANGE_CHANGE`;
    this.DISTINCT_FACET_CHANGE = `${nameSpace}_DISTINCT_FACET_CHANGE`;
    this.FACET_RESET = `${nameSpace}_FACET_RESET`;
    this.SEARCH_CLEAR_FACETS_FILTER = `${nameSpace}_SEARCH_CLEAR_FACETS_FILTER`;
    this.RESET_ALL_FACETS_FILTERS = `${nameSpace}_RESET_ALL_FACETS_FILTERS`;
    this.SET_QUERY = `${nameSpace}_SET_QUERY`;
    this.SYNC_QUERY_PARAMS_TO_FACETS = `${nameSpace}_SYNC_QUERY_PARAMS_TO_FACETS`;
    this.SYNC_FACETS_TO_QUERY_PARAMS = `${nameSpace}_SYNC_FACETS_TO_QUERY_PARAMS`;

    this.options = options;
    this.initialState = { ...defaultInitialState, ...initialState };
    this.initialState.baseRoute = initialState.baseRoute || '/' + nameSpace.toLowerCase();
    //  NAME_SPACE  --->   nameSpace
    this.nameSpace = this.initialState.name = nameSpace
      .split('')
      .reduce((a, b) => (a[a.length - 1] === '_' ? a.slice(0, -1) + b.toUpperCase() : a + b.toLowerCase()), '');
    this.getInstanceState = options.getInstanceState || (state => state[this.nameSpace + 'Search']);

    // Callbacks
    this.onSearchSuccess = null;
  }

  getInitialStateCopy(nameSpace, initialState) {
    const returnState = { ...defaultInitialState, ...initialState };
    returnState.baseRoute = initialState.baseRoute || '/' + nameSpace.toLowerCase();
    //  NAME_SPACE  --->   nameSpace
    returnState.name = nameSpace;
    return returnState;
  }

  createReducer(extendReducerFn, reduceInstanceParams) {
    if (reduceInstanceParams) {
      Object.assign(this, reduceInstanceParams);
    }

    return (state = this.initialState, action = {}) => {
      if (typeof extendReducerFn === 'function') {
        const newState = extendReducerFn(state, action);
        if (newState) return newState;
      }

      switch (action.type) {
        case this.PAGE_LIST:
          return {
            ...state,
            limit: action.limit,
            offset: action.offset > 0 ? action.offset : 0
          };
        case this.SEARCH:
          return {
            ...state,
            loading: action.dontAffectLoadingState ? state.loading : true,
            query: action.query,
            lastQueryParams: action.lastQueryParams
          };
        case this.SEARCH_CLEAR_FACETS_FILTER:
          const facetInformation = this.resetAllFacets(
            state.facets,
            state.facetSelection,
            state.facetIsCheckedByDefault
          );
          return {
            ...state,
            loading: true,
            query: action.query,
            filters: {},
            lastFacetFilteredID: null,
            lastQueryParams: action.lastQueryParams,
            items: [],
            ...facetInformation
          };
        case this.SEARCH_SUCCESS:
          if (action.result?.success === false || (this.options?.useCMS && action.result?.status !== 200)) {
            return { ...state };
          }

          let loading = false,
            loaded = true;

          if (action.dontAffectLoadingState) {
            loading = state.loading;
            loaded = state.loaded;
          }

          const firstPageLoad = action.firstPageLoad;
          if (this.options?.useCMS) {
            action.result = action.result?.data?.searchData;
          }

          const { facets, facetSelection, query } = this.applyQueryFacets(
            state,
            action.result?.facets,
            action.result?.facetSelection,
            firstPageLoad,
            action.onlyFacet,
            action.isTextSearch
          );

          return {
            ...state,
            loading: action.dontAffectLoadingState ? state.loading : loading,
            loaded: action.dontAffectLoadingState ? state.loaded : loaded,
            facets,
            facetSelection,
            query,
            searchId: action.result.id,
            offset: action.result.offset >= 0 ? action.result.offset : 0,
            items: action.result.data || [],
            //todo facetSelection needs to be transformed from arrays of keys to objects
            initialFacetSelection: firstPageLoad ? facetSelection : action.result.facetSelection,
            searchResultsCount: action.result.searchResultsCount,
            reportPk: action.result.reportPk
          };
        case this.SEARCH_FAIL:
          return {
            ...state,
            loading: false,
            loaded: false,
            data: null,
            error: action.error
          };
        case this.MORE_RESULTS:
          return {
            ...state,
            loading: true,
            query: action.query,
            moreResultsLoading: true,
            lastQueryParams: action.lastQueryParams
          };
        case this.MORE_RESULTS_SUCCESS:
          return {
            ...state,
            loading: false,
            loaded: true,
            error: null,
            searchId: action.result.id,
            offset: state.offset + state.limit,
            moreResultsLoading: false,
            items: state.items.concat(action.result.data)
          };
        case this.MORE_RESULTS_FAIL:
          return {
            ...state,
            loading: false,
            loaded: false,
            data: null,
            moreResultsLoading: false,
            error: action.error
          };
        case this.LOOKUP:
          return {
            ...state,
            lastQueryParams: action.lastQueryParams
          };
        case this.LOOKUP_SUCCESS:
          if (action.result && !action.result.success) {
            return { ...state };
          }
          return {
            ...state,
            // facetSelection: {},
            items: action.result.data
          };
        case this.LOOKUP_FAIL:
          return { ...state };
        case this.PAGINATION_SUCCESS:
          return {
            ...state,
            items: state.items.concat(action.result.data)
          };
        case this.TOGGLE_FACET:
          const { facet } = action;

          const newToggleResponse = this.facetClickSelection({
            anyFacetChecked: state.facetIsCheckedByDefault === false || state.anyFacetChecked,
            facetSelection: state.facetSelection,
            facets: state.facets,
            facet: action.facet,
            onlyFacetItem: action.facetItem,
            include: state.include,
            exclude: state.exclude,
            offset: 0
          });

          return {
            ...state,
            facetSelection: newToggleResponse.newFacetSelection,
            anyFacetChecked: newToggleResponse.anyFacetChecked,
            lastFacetFilteredID: facet.lastFacetFilteredID || facet.id,
            offset: 0
          };
        case this.ONLY_FACET:
          const newOnlyFacetResponse = this.onlyFacetSelection({
            anyFacetChecked: state.facetIsCheckedByDefault === false || state.anyFacetChecked,
            facetSelection: state.facetSelection,
            facets: state.facets,
            facet: action.facet,
            onlyFacetItem: action.facetItem,
            include: state.include,
            exclude: state.exclude,
            offset: 0
          });

          return {
            ...state,
            facetSelection: newOnlyFacetResponse.newFacetSelection,
            anyFacetChecked: newOnlyFacetResponse.anyFacetChecked,
            lastFacetFilteredID: action.facet.lastFacetFilteredID || action.facet.id,
            offset: 0
          };
        case this.RANGE_CHANGE:
          const newFacets = state.facets.map(sFacet => {
            if (sFacet.id === action.sliderFacet.id) {
              return { ...action.sliderFacet, value: [...action.value] };
            }

            return sFacet;
          });
          return {
            ...state,
            facets: newFacets,
            offset: 0
          };
        case this.DISTINCT_FACET_CHANGE:
          const newFacetSelection = state.facetSelection;
          newFacetSelection[action.facetId] = action.value;
          return {
            ...state,
            facetSelection: newFacetSelection,
            offset: 0
          };
        case this.FACET_RESET:
          const resetSelection = this.applyFacetReset({
            facet: action.facet,
            facetSelection: { ...action.facetSelection },
            checkedState: state.facetIsCheckedByDefault,
            initialFacetSelection: state.initialFacetSelection,
            include: state.include,
            exclude: state.exclude
          });

          return {
            ...state,
            facetSelection: resetSelection.facetSelection,
            lastFacetFilteredID: undefined,
            include: resetSelection.include,
            exclude: resetSelection.exclude,
            offset: 0
          };
        case this.RESET_ALL_FACETS_FILTERS:
          const facetInfo = this.resetAllFacets(state.facets, state.facetSelection, state.facetIsCheckedByDefault);
          return {
            ...state,
            ...facetInfo,
            filters: {},
            lastFacetFilteredID: null
          };
        case this.SET_QUERY:
          return {
            ...state,
            query: action.query
          };
        case this.SYNC_QUERY_PARAMS_TO_FACETS:
          this.addQueryParamsToFacets(state.facets, state.facetSelection);
          return {
            ...state,
            query: this.queryParamsMap()[ES_QUERY_PARAM_KEY]
          };
        case this.SYNC_FACETS_TO_QUERY_PARAMS:
          this.addFacetsToQueryParams(state.facets, state.facetSelection, state.query);
          return {
            ...state
          };
        default:
          return state;
      }
    };
  }

  pageList(limit, offset) {
    return (dispatch, getStore) => {
      const state = getStore();
      const searchParams = this.getSearchParameters(state);
      const { searchResultsCount } = this.getInstanceState(state);
      const newLimit = limit;
      const params = { ...searchParams, offset, limit: newLimit };
      dispatch({
        type: this.PAGE_LIST,
        offset,
        limit: newLimit
      });
      dispatch(this.search({ params }));
    };
  }

  getCMSParameters(facetSelections, facets, query = '', searchPriorities, offset, limit, addedFilters) {
    let filterClause = {
      filter: {
        where: {
          and:
            addedFilters?.map(filter => {
              return { term: filter.field.value.term, filter: { [filter.operator.value]: filter.value } };
            }) || []
        },
        fullTextSearch: query,
        fullTextTerms: searchPriorities
      },
      offset: offset,
      limit: limit
    };

    Object.keys(facetSelections).forEach((term, idx) => {
      const facetOptions = facets
        .find(facet => facet.id === term)
        .items.filter(facetOpt => {
          return facetOpt.doc_count > 0;
        })
        .map(facetOpt => facetOpt.key);
      const facetKeys = Object.keys(facetSelections[term]);
      const checkedSelections = facetKeys.filter(termKey => {
        return facetSelections[term][termKey] === true;
      });

      const checkDocCount = facetOptions.filter(facetOpt => {
        return checkedSelections.includes(facetOpt) || checkedSelections.includes(facetOpt.toString());
      }).length;
      checkedSelections.length > 0 &&
        checkDocCount > 0 &&
        checkedSelections.length !== facetKeys.length &&
        filterClause.filter.where.and.push({
          term: term,
          filter: {
            in: checkedSelections
          }
        });
    });

    return filterClause;
  }

  search({ params, clear, skipSummaryChart }) {
    const { SEARCH, SEARCH_SUCCESS, SEARCH_FAIL, SEARCH_CLEAR_FACETS_FILTER } = this;
    const types = [clear ? SEARCH_CLEAR_FACETS_FILTER : SEARCH, SEARCH_SUCCESS, SEARCH_FAIL];
    // TODO: Need to rework the search function to work with both MP and Lab and not have two functions
    if (this.options.graphql) return this.gqlSearch(params, types, clear);

    return (dispatch, getStore) => {
      // Lab doesn't need an offset on searching. It should be reset.
      params.offset = 0;
      const masterAccountId = getStore().session?.organization?.masterAccountId;
      const searchAPI = this.searchAPI?.replace('{{masterAccountId}}', masterAccountId);

      // in lab reset all the selected jobs when facets are changed
      dispatch(initList('JOBS').toggleSelectAllCheck(false));

      return dispatch({
        types,
        promise: client => client.post(searchAPI, { params }),
        query: params.es_query,
        page_name: params.pageName,
        lastQueryParams: params
      })
        .then(() => {
          if (this.onSearchSuccess) {
            this.onSearchSuccess(dispatch, skipSummaryChart);
          }
        })
        .catch(err => {
          console.error('Error searching', err);
        });
    };
  }

  moreResults(options) {
    const { MORE_RESULTS, MORE_RESULTS_SUCCESS, MORE_RESULTS_FAIL } = this;

    const isMarketPay = options && options.marketPay;
    return (dispatch, getStore) => {
      const state = getStore();
      const searchParams = this.getSearchParameters(state);
      let { offset, limit } = this.getInstanceState(state);
      offset = offset + limit;

      const masterAccountId = state.session?.organization?.masterAccountId;
      const searchAPI = this.searchAPI?.replace('{{masterAccountId}}', masterAccountId);
      // console.log('searchAPI moreResults: ', searchAPI);

      //  NOTE: Market pay does not support the "types" conversion middleware
      if (isMarketPay) {
        dispatch(this.searchMore({ ...searchParams, offset }));
      } else {
        const params = { ...searchParams, ...options };
        dispatch({
          types: [MORE_RESULTS, MORE_RESULTS_SUCCESS, MORE_RESULTS_FAIL],
          promise: client => client.post(searchAPI, { params }),
          query: searchParams.es_query,
          page_name: params.pageName,
          offset: params.offset,
          lastQueryParams: searchParams
        });
      }
    };
  }

  applyQueryFacets(
    state,
    queryResultFacets,
    queryFacetSelection,
    firstPageLoad,
    onlyFacet = false,
    isTextSearch = false
  ) {
    const defaultChecked = state.facetIsCheckedByDefault;
    const newFacetSelection = { ...state.facetSelection };
    let newFacets = [...state.facets];
    if (firstPageLoad) {
      newFacets = [...queryResultFacets];
    }
    let query = state.query;

    if (!queryResultFacets) return { facets: newFacets, facetSelection: newFacetSelection, query };

    let facetsReplaced = false;
    if (!state.facets.length) {
      newFacets = queryResultFacets.concat(state.additionalFacets);
      facetsReplaced = true;
    }

    for (let idx = 0; idx < newFacets.length; idx++) {
      let currFacet = newFacets[idx]; // eslint-disable-line prefer-const
      if (!currFacet.items) currFacet.items = [];

      // update counts if new facets are filtered but the list is 'sticky'
      // facetsReplaced = true;
      if (!facetsReplaced) {
        // do not update doc counts for the facet that was just modified
        if (
          !isTextSearch &&
          !onlyFacet &&
          currFacet.id === state.lastFacetFilteredID &&
          (!defaultChecked || state.showZeros)
        ) {
          continue;
        }

        for (let queryIdx = 0; queryIdx < queryResultFacets.length; queryIdx++) {
          const queryFacet = queryResultFacets[queryIdx];
          if (queryFacet.id === currFacet.id) {
            for (let facetItemIdx = 0; facetItemIdx < currFacet.items.length; facetItemIdx++) {
              let currFacetItem = currFacet.items[facetItemIdx]; // eslint-disable-line prefer-const

              let facetItemFound = false;

              if (!queryFacet.items) queryFacet.items = [];
              for (let queryItmIdx = 0; queryItmIdx < queryFacet.items.length; queryItmIdx++) {
                const queryFacetItem = queryFacet.items[queryItmIdx];

                if (queryFacetItem.key === currFacetItem.key) {
                  facetItemFound = true;
                  currFacetItem.doc_count = queryFacetItem.doc_count;
                  currFacetItem.partialDocCount = queryFacetItem.partialDocCount;
                }
              }

              // if the search did not return this facet item, was not on body
              if (!facetItemFound) {
                currFacetItem.doc_count = 0;
              }
            }
          }
        }
      } else {
        switch (currFacet.type) {
          case 'FacetCheckboxItems':
            // rule is all items are selected when initially loaded; but maintain previous selections
            newFacetSelection[currFacet.id] = {};
            for (let facetItemIdx = 0; facetItemIdx < currFacet.items.length; facetItemIdx++) {
              const currFacetItem = currFacet.items[facetItemIdx];
              newFacetSelection[currFacet.id][currFacetItem.key] = defaultChecked;
            }

            //right now loading a facet selection for defaulted checked==false is not supported. If there is a selection
            // that needs to be loaded, all the facets need to be switched to checked=true by default
            if (queryFacetSelection && defaultChecked === false) {
              if (queryFacetSelection && Object.keys(queryFacetSelection).length) {
                SearchPanel.applyQueryFacetSelection(currFacet, newFacetSelection, queryFacetSelection);
              }
            }

            const currentSelection = newFacetSelection[currFacet.id];
            if (currentSelection) {
              const needToAddDefaults = Object.keys(currentSelection).filter(key => currentSelection[key] === false)
                .length;

              if (needToAddDefaults) {
                newFacetSelection[currFacet.id] = {
                  ...newFacetSelection[currFacet.id],
                  ...currentSelection
                };
              } else {
                newFacetSelection[currFacet.id] = { ...currentSelection };
              }
            }
            break;
          case 'FacetDistinctItems':
            // Default to the first item
            if (currFacet.items && currFacet.items.length) {
              newFacetSelection[currFacet.id] = currFacet.items[0].key;
            }
            break;
        }
      }
    }

    // Reset selected facets to match all facets on first page load
    if (firstPageLoad) {
      for (let idx = 0; idx < newFacets.length; idx++) {
        let currFacet = newFacets[idx]; // eslint-disable-line prefer-const
        newFacetSelection[currFacet.id] = {};
        for (let facetItemIdx = 0; facetItemIdx < currFacet.items.length; facetItemIdx++) {
          const currFacetItem = currFacet.items[facetItemIdx];
          newFacetSelection[currFacet.id][currFacetItem.key] = defaultChecked;
        }
      }
    }

    if (facetsReplaced) {
      // If facets were just hydrated, let query params be the source of truth for facet state
      this.addQueryParamsToFacets(newFacets, newFacetSelection);
      const queryParams = this.queryParamsMap();
      if (queryParams[ES_QUERY_PARAM_KEY]) {
        query = queryParams[ES_QUERY_PARAM_KEY];
      }

      // Sync local storage to facet state
      for (let facet of newFacets) {
        // Currently, only support distinct item facet
        if (facet.type !== 'FacetDistinctItems' || !facet.localStorage) {
          continue;
        }
        const selection = window.localStorage.getItem(this.localStorageKey(facet.id));
        if (selection) {
          newFacetSelection[facet.id] = selection;
        }
      }

      // Sort
      newFacets = newFacets.sort((a, b) => a.facetSort - b.facetSort);
    } else {
      // Otherwise, let facets be the source of truth for query param state
      this.addFacetsToQueryParams(newFacets, newFacetSelection, query);

      // Sync facet state to local storage
      for (let facet of newFacets) {
        // Currently, only support distinct item facet
        if (facet.type !== 'FacetDistinctItems' || !facet.localStorage) {
          continue;
        }
        const selection = newFacetSelection[facet.id];
        if (!selection) {
          window.localStorage.removeItem(this.localStorageKey(facet.id));
        } else {
          window.localStorage.setItem(this.localStorageKey(facet.id), selection);
        }
      }
    }

    return { facets: newFacets, facetSelection: newFacetSelection, query };
  }

  static applyQueryFacetSelection = (currFacet, newFacetSelection, queryFacetSelection) => {
    const selectionData = queryFacetSelection[currFacet.id];
    const selectionArrayForFacet = selectionData ? [].concat(selectionData) : [];
    if (selectionArrayForFacet.length) {
      if (currFacet.type === 'RangeSlider') {
        currFacet.value = selectionArrayForFacet.map(possibleString => parseFloat(possibleString));
      } else {
        //reset the selection first
        newFacetSelection[currFacet.id] = {};
        //then apply the facet selection received from the api
        selectionArrayForFacet.forEach(facetKey => {
          newFacetSelection[currFacet.id][facetKey] = true;
        });
      }
    }
    //if no items are selected for the facet, make them all selected
    //this is only needed if defaultChecked === false
    if (!selectionArrayForFacet.length) {
      Object.keys(newFacetSelection[currFacet.id]).forEach(key => {
        newFacetSelection[currFacet.id][key] = true;
      });
    }
  };

  getFacets(state) {
    const { facets } = this.getInstanceState(state);
    return facets.map(facet => {
      if (facet.type === 'RangeSlider') facet.items = [];
      return facet;
    });
  }

  getSearchParameters(state) {
    const {
      facets,
      query,
      facetSelection,
      filters: filterParams,
      lastFacetFilteredID,
      loaded,
      include,
      exclude,
      offset,
      limit
    } = this.getInstanceState(state);

    if (this.options.useCMS) {
      const genericGridParams = this.options.genericGridParams(state);
      return {
        ...genericGridParams,
        ...this.getCMSParameters(facetSelection, facets, query, [], offset, limit, genericGridParams?.addedFilters)
      };
    }

    let facetParams = {};
    let sortParam;

    for (let idx = 0; idx < facets.length; idx++) {
      const facet = facets[idx];

      switch (facet.type) {
        case 'RangeSlider':
          if (facet.value[0] !== facet.min || facet.value[1] !== facet.max) {
            facetParams[facet.id] = facet.value;
          }
          break;
        case 'FacetCheckboxItems':
          const allFacetsChecked = SearchPanel.areAllFacetsChecked(facet, facetSelection);
          const allFacetItemsUnchecked = SearchPanel.areAllFacetItemsUnchecked(facet, facetSelection);

          if (allFacetsChecked || allFacetItemsUnchecked) continue;
          facetParams[facet.id] = facet.items
            .filter(item => facetSelection[facet.id][item.key]) // eslint-disable-line no-loop-func
            .map(item => item.key);

          facetParams[facet.id + '_partials'] = facet.items
            .filter(item => !facetSelection[facet.id][item.key] && item.partialDocCount !== undefined) // eslint-disable-line no-loop-func
            .map(item => item.key);
          break;
        case 'FacetDistinctItems':
          const selection = facetSelection[facet.id];
          if (selection) {
            const selectedItem = facet.items.find(i => i.key === selection);
            if (!selectedItem || selectedItem.val === null) {
              continue;
            }
            facetParams[facet.queryParam || facet.id] = selectedItem.val || selection;
          }
          break;
      }
    }

    facetParams = !facets.length ? this.getParamsFromFacetSelection(facetSelection) : facetParams;

    const listPanelState = state[this.nameSpace + 'List'];
    if (listPanelState && listPanelState.sort.colName) {
      sortParam = JSON.stringify(state[this.nameSpace + 'List'].sort);
    }

    let additionalParams = {};
    if (typeof this.options.additionalSearchParams === 'function') {
      additionalParams = this.options.additionalSearchParams(state);
    } else if (typeof this.additionalSearchParams === 'function') {
      additionalParams = this.additionalSearchParams(state);
    }

    let currency = null;

    if (additionalParams.currency && include.currency && !!include.currency.length) {
      currency = [...include.currency, additionalParams.currency];
    } else if (additionalParams.currency) {
      currency = additionalParams.currency;
    } else if (include.currency && !!include.currency.length) {
      currency = [...include.currency];
    }

    return {
      ...facetParams,
      ...filterParams,
      ...additionalParams,
      sort: sortParam,
      lastFacetFilteredID,
      facetsLoaded: loaded,
      es_query: query,
      include: {
        ...include,
        currency: currency
      },
      exclude: exclude,
      offset,
      limit
    };
  }

  static areAllFacetsChecked(facet, selection) {
    if (facet.type !== 'FacetCheckboxItems') return true;

    for (let idxj = 0; idxj < facet.items.length; idxj++) {
      const item = facet.items[idxj];
      if (!selection[facet.id] || !selection[facet.id][item.key]) {
        return false;
      }
    }
    return true;
  }

  static areAllFacetItemsUnchecked(facet, selection) {
    if (facet.type !== 'FacetCheckboxItems') return true;

    for (let idxj = 0; idxj < facet.items.length; idxj++) {
      const item = facet.items[idxj];
      if (selection[facet.id] && selection[facet.id][item.key]) {
        return false;
      }
    }
    return true;
  }

  getParamsFromFacetSelection(selection) {
    const newParam = {};
    for (const id in selection) {
      if (Object.prototype.hasOwnProperty.call(selection, id)) {
        newParam[id] = [];

        for (const key in selection[id]) {
          if (Object.prototype.hasOwnProperty.call(selection[id], key) && selection[id][key]) {
            newParam[id].push(key);
          }
        }
      }
    }
    return newParam;
  }

  facetClickSelection(options) {
    let newFacetSelection = options.facetSelection; // eslint-disable-line prefer-const
    const existingState = options.facetSelection[options.facet.id][options.onlyFacetItem.key];

    // if all facets are unchecked, handle that special case
    const handleFirstFacetSelectionResponse = this.handleFirstFacetSelection(
      options.anyFacetChecked,
      options.facetSelection,
      options.facet,
      options.onlyFacetItem,
      newFacetSelection
    );

    const anyFacetChecked = handleFirstFacetSelectionResponse.anyFacetChecked;

    // mark the actual checkbox that was clicked as checked
    newFacetSelection[options.facet.id][options.onlyFacetItem.key] = !existingState;
    const currentFacet = options.facet.id;
    const newItem = options.onlyFacetItem.key;
    options.exclude[currentFacet]
      ? options.exclude[currentFacet].push(newItem)
      : Object.assign(options.exclude, { [currentFacet]: [newItem] });
    return {
      // eslint-disable-line prefer-const
      newFacetSelection: newFacetSelection,
      anyFacetChecked: anyFacetChecked,
      exclude: { ...options.exclude }
    };
  }

  onlyFacetSelection(options) {
    let newFacetSelection = options.facetSelection; // eslint-disable-line prefer-const
    const { onlyFacetItem, facet } = options;

    // for each facet item in a facet section, uncheck if not the one clicked "only".  Otherwise check it.
    let found;
    for (let idx = 0; idx < facet.items.length; idx++) {
      const facetItem = facet.items[idx];
      newFacetSelection[options.facet.id][facetItem.key] = facetItem.key === onlyFacetItem.key;
      if (facetItem.key === onlyFacetItem.key) found = true;
    }

    // make sure the one clicked is checked regardless of whether it was found in the facet items
    if (!found) {
      // mark the actual checkbox that was clicked as checked
      newFacetSelection[options.facet.id][options.onlyFacetItem.key] = true;
    }

    // if all facets are unchecked, handle that special case
    const handleFirstFacetSelectionResponse = this.handleFirstFacetSelection(
      options.anyFacetChecked,
      options.facetSelection,
      options.facet,
      options.onlyFacetItem,
      newFacetSelection
    );

    const anyFacetChecked = handleFirstFacetSelectionResponse.anyFacetChecked;
    newFacetSelection = handleFirstFacetSelectionResponse.newFacetSelection;
    const currentFacet = facet.id;
    const newItem = onlyFacetItem.key;

    if (!options.include[currentFacet]) {
      Object.assign(options.include, { [currentFacet]: [newItem] });
    } else if (options.include[currentFacet] && !options.include[currentFacet].includes(newItem)) {
      options.include[currentFacet].push(newItem);
    }

    delete options.exclude[currentFacet];
    return {
      // eslint-disable-line prefer-const
      newFacetSelection: newFacetSelection,
      anyFacetChecked: anyFacetChecked,
      include: { ...options.include },
      exclude: { ...options.exclude }
    };
  }

  // if making a first facet selection when all facets are unchecked, populate other facet groups as checked
  handleFirstFacetSelection(
    anyFacetCheckedExistingState,
    facetSelection,
    facetSectionClicked,
    facetClicked,
    newFacetSelection
  ) {
    // see if no facets are checked
    let anyFacetChecked = false;
    if (anyFacetCheckedExistingState === true) {
      anyFacetChecked = true;
    } else {
      const facetGroupings = Object.getOwnPropertyNames(facetSelection);
      for (let groupCnt = 0; groupCnt < facetGroupings.length; groupCnt++) {
        const currGrouping = facetGroupings[groupCnt];
        const facetGroupingItems = Object.getOwnPropertyNames(facetSelection[currGrouping]);
        for (let itemCnt = 0; itemCnt < facetGroupingItems.length; itemCnt++) {
          const currItem = facetGroupingItems[itemCnt];
          // if checked and not the one just selected
          if (facetSelection[currGrouping][currItem] === true && currItem !== facetClicked.key) {
            anyFacetChecked = true;
          }
        }
      }
    }

    // if no facets are checked, upon the first selection we want to check all facets not in that facet's grouping
    if (anyFacetChecked === false) {
      const facetGroupings = Object.getOwnPropertyNames(facetSelection);
      for (let groupCnt = 0; groupCnt < facetGroupings.length; groupCnt++) {
        const currGrouping = facetGroupings[groupCnt];
        // ignore current facet grouping
        if (currGrouping !== facetSectionClicked.id) {
          const facetGroupingItems = Object.getOwnPropertyNames(facetSelection[currGrouping]);
          for (let itemCnt = 0; itemCnt < facetGroupingItems.length; itemCnt++) {
            const currItem = facetGroupingItems[itemCnt];
            if (newFacetSelection[currGrouping][currItem] === false) {
              newFacetSelection[currGrouping][currItem] = true; // mark checked
            }
          }
        }
      }
    }

    return {
      anyFacetChecked,
      newFacetSelection
    };
  }

  // CMS only function
  searchColumnsOnly() {
    return (dispatch, getStore) => {
      const params = this.getSearchParameters(getStore());
      params.returnOnlyColumns = true;
      params.returnResults = true;
      dispatch(this.search({ params }));
    };
  }

  searchInputSearch(query = '', options = {}) {
    return (dispatch, getStore) => {
      const params = this.getSearchParameters(getStore());
      if (!this.options.useCMS) {
        params.es_query = query;
        // WIP: Include/Exclude can be taken out?
        params.include = { ...params.include, currency: params.currency };
      } else {
        if (!params.filter.fullTextSearch) params.filter.fullTextSearch = query;
        params.offset = options.offset || 0;
        params.firstPageLoad = options?.firstPageLoad;
      }
      params.isTextSearch = true;
      dispatch(this.search({ params }));
    };
  }

  handleReset() {
    return (dispatch, getStore) => {
      let additionalParams = {},
        state = getStore();
      if (typeof this.options.additionalSearchParams === 'function') {
        additionalParams = this.options.additionalSearchParams(state);
      } else if (typeof this.additionalSearchParams === 'function') {
        additionalParams = this.additionalSearchParams(state);
      }
      const genericGridParams = this.options.genericGridParams && this.options.genericGridParams(state);
      return dispatch(
        this.search({
          params: this.options.useCMS
            ? {
                ...genericGridParams
              }
            : {
                ...additionalParams,
                es_query: '',
                // WIP: Include/Exclude can be taken out?
                include: {},
                exclude: {}
              },
          clear: true
        })
      );
    };
  }

  searchMore(params) {
    const { MORE_RESULTS, MORE_RESULTS_SUCCESS, MORE_RESULTS_FAIL } = this;
    const client = this.options.apiClient;

    return async (dispatch, getState) => {
      dispatch({
        type: MORE_RESULTS,
        query: params.es_query,
        page_name: params.pageName,
        lastQueryParams: params
      });

      let ajaxResult;
      const masterAccountId = getState().session?.organization?.masterAccountId;
      const searchAPI = this.searchAPI?.replace('{{masterAccountId}}', masterAccountId);
      // console.log('searchAPI searchMore: ', searchAPI);

      const csrfPair = {} && getState().session && getState().session.csrfPair;
      try {
        ajaxResult = await client.apiPost(searchAPI, {
          ...params,
          ...csrfPair
        });
      } catch (error) {
        console.log('facet search more fail', error);
        dispatch({ type: MORE_RESULTS_FAIL, error });
      }
      dispatch({ type: MORE_RESULTS_SUCCESS, result: ajaxResult.data });
    };
  }

  gqlSearch(params, types, clear) {
    const [SEARCH, SUCCESS, FAIL] = types;
    const client = this.options.graphql.apiClient;

    return async (dispatch, getState) => {
      const state = this.getSearchParameters(getState());
      const dontAffectLoadingState = params.returnOnlyColumns;
      dispatch({
        type: SEARCH,
        query: this.options.useCMS ? params?.filter?.fullTextSearch : params?.es_query,
        page_name: params.pageName,
        offset: params.offset,
        limit: params.limit,
        firstPageLoad: params.firstPageLoad,
        lastQueryParams: params,
        dontAffectLoadingState
      });
      let ajaxResult;
      const masterAccountId = getState().session?.organization?.masterAccountId;
      const searchAPI = this.searchAPI?.replace('{{masterAccountId}}', masterAccountId);

      const csrfPair = {} && state.session && state.session.csrfPair;

      try {
        const addedIncludes = {};
        this.options.requiredIncludes?.forEach(include => {
          addedIncludes[include] = params[include];
        });
        params.include = { ...params.include, ...addedIncludes };
        ajaxResult = await client.apiPost(searchAPI, {
          ...params,
          ...csrfPair,
          currency: params.currency
        });
        if (!ajaxResult) {
          throw Error('Could not retrieve data');
        }
        dispatch({
          type: SUCCESS,
          result: ajaxResult.data,
          firstPageLoad: params.firstPageLoad,
          dontAffectLoadingState,
          onlyFacet: params.onlyFacet,
          isTextSearch: params.isTextSearch
        });
      } catch (error) {
        dispatch({ type: FAIL, error });
        console.log('facet gql search fail', error);
      }
    };
  }

  initialSearch(extraParams) {
    return (dispatch, getStore) => {
      let params = Object.assign({}, this.getSearchParameters(getStore()), extraParams);
      const searchRes = dispatch(this.search({ params, clear: null, skipSummaryChart: true }));

      if (searchRes) {
        searchRes
          .then(() => {
            // Facet data is returned on every search call.
            // We first need to trigger a search without any request params to hydrate
            // facet data and get unfiltered counts.
            // Afterwards, if any query params exist, they are now synced to facet state,
            // so let's re-search to get the new result set.
            // (DistinctItem)Facets are now synced from local storage so check those
            // as well.
            const queryKeys = Object.keys(this.queryParamsMap());
            let localStorageFacets = false;
            const { facets } = this.getInstanceState(getStore());
            for (let facet of facets) {
              if (facet.localStorage && window.localStorage.getItem(this.localStorageKey(facet.id))) {
                localStorageFacets = true;
                break;
              }
            }
            if (queryKeys.length > 0 || localStorageFacets) {
              params = Object.assign({}, this.getSearchParameters(getStore()), extraParams);
              dispatch(this.search({ params, clear: null, skipSummaryChart: false }));
            }
          })
          .catch(err => {
            console.error('Error searching after initial search', err);
          });
      }
    };
  }

  repeatCurrentSearch() {
    return (dispatch, getStore) => {
      const params = this.getSearchParameters(getStore());
      dispatch(this.search({ params }));
    };
  }

  facetItemClick(facet, facetItem, only) {
    const { TOGGLE_FACET, ONLY_FACET } = this;
    return (dispatch, getStore) => {
      dispatch({
        type: only ? ONLY_FACET : TOGGLE_FACET,
        facet,
        facetItem
      });

      const params = this.getSearchParameters(getStore());
      params.onlyFacet = only;
      dispatch(this.search({ params }));
    };
  }

  resetAllFacets(facets, oldFacetSelection, checkedState) {
    let facetSelection = { ...oldFacetSelection };

    const newFacets = facets.map(facet => {
      facetSelection = this.applyFacetReset({ facet, facetSelection, checkedState }).facetSelection;

      if (facet.type === 'RangeSlider') {
        return { ...facet, value: [facet.min, facet.max] };
      }
      return { ...facet };
    });

    return { facetSelection, facets: newFacets, include: {}, exclude: {} };
  }

  applyFacetReset({ facet, facetSelection, checkedState, initialFacetSelection, include, exclude }) {
    const newFacetSelection = { ...facetSelection };
    if (typeof checkedState !== 'boolean') checkedState = true; // eslint-disable-line

    switch (facet.type) {
      case 'FacetCheckboxItems':
        newFacetSelection[facet.id] = {};
        for (let facetItem of facet.items) {
          newFacetSelection[facet.id][facetItem.key] = checkedState;
        }
        break;
    }

    //not using this for now. but leave this b/c it will be implemented
    // if (initialFacetSelection && initialFacetSelection[facet.id]) {
    //   newFacetSelection[facet.id] = {...initialFacetSelection[facet.id]};
    // }
    if (include && exclude) {
      if (include[facet.id]) delete include[facet.id];
      if (exclude[facet.id]) delete exclude[facet.id];

      return { facetSelection: newFacetSelection, include, exclude };
    }

    return { facetSelection: newFacetSelection };
  }

  facetReset(facet, facetSelection) {
    const { FACET_RESET, RANGE_CHANGE } = this;

    switch (facet.type) {
      case 'RangeSlider':
        return (dispatch, getStore) => {
          dispatch({
            type: RANGE_CHANGE,
            value: [facet.min, facet.max],
            sliderFacet: facet
          });
          const params = this.getSearchParameters(getStore());
          dispatch(this.search({ params }));
        };
      case 'FacetCheckboxItems':
        return (dispatch, getStore) => {
          dispatch({
            type: FACET_RESET,
            facet,
            facetSelection
          });
          const params = this.getSearchParameters(getStore());
          dispatch(this.search({ params }));
        };
    }
  }

  rangeChange(sliderFacet, value) {
    const { RANGE_CHANGE } = this;
    return (dispatch, getStore) => {
      dispatch({
        type: RANGE_CHANGE,
        value,
        sliderFacet
      });

      const params = this.getSearchParameters(getStore());
      dispatch(this.search({ params }));
    };
  }

  facetDistinctItemClick(facet, value) {
    return (dispatch, getStore) => {
      dispatch({
        type: this.DISTINCT_FACET_CHANGE,
        facetId: facet.id,
        value
      });

      const params = this.getSearchParameters(getStore());
      dispatch(this.search({ params }));
    };
  }

  lookup(options) {
    const { LOOKUP, LOOKUP_SUCCESS, LOOKUP_FAIL } = this;
    const params = { id: options.id, type: options.type };
    return {
      types: [LOOKUP, LOOKUP_SUCCESS, LOOKUP_FAIL],
      promise: client => client.get('/api/elasticsearch/lookup/byId', { params: params }),
      lastQueryParams: params
    };
  }

  syncFacetQueryParams() {
    return (dispatch, getStore) => {
      if (Object.keys(this.queryParamsMap()).length === 0) {
        // If there are no query params, sync facet state to query params
        dispatch({ type: this.SYNC_FACETS_TO_QUERY_PARAMS });
      } else {
        // If query params exist, sync query params to facet state,
        // starting from a default facet state
        dispatch({ type: this.RESET_ALL_FACETS_FILTERS });
        dispatch({ type: this.SYNC_QUERY_PARAMS_TO_FACETS });

        const params = this.getSearchParameters(getStore());
        dispatch(this.search({ params }));
      }
    };
  }

  setSearchQuery(query) {
    return (dispatch, getStore) => {
      dispatch({ type: this.SET_QUERY, query });
    };
  }

  addFacetsToQueryParams(facets, facetSelection, query) {
    const queryParamStr = this.queryParamStringFromFacets(facets, facetSelection, query);
    const hash = window.location.hash;
    history.replaceState({}, '', window.location.origin + window.location.pathname + queryParamStr + hash);
  }

  addQueryParamsToFacets(facets, facetSelection) {
    const queryParams = this.queryParamsMap();

    if (Object.keys(queryParams).length === 0) {
      return;
    }

    for (let i = 0; i < facets.length; i++) {
      const facet = facets[i];
      const queryParamByFacet = queryParams[facet.id];
      if (!queryParamByFacet) {
        continue;
      }

      switch (facet.type) {
        case 'RangeSlider':
          if (queryParamByFacet.length === 2) {
            facet.value = [parseInt(queryParamByFacet[0]), parseInt(queryParamByFacet[1])];
          }
          break;
        case 'FacetCheckboxItems':
          for (let j = 0; j < facet.items.length; j++) {
            const facetItemKey = facet.items[j].key.toString();
            facetSelection[facet.id][facetItemKey] = queryParamByFacet.length
              ? queryParamByFacet.indexOf(facetItemKey) !== -1
              : queryParamByFacet[j] !== undefined;
          }
          break;
        case 'FacetDistinctItems':
          facetSelection[facet.id] = queryParamByFacet;
          break;
      }
    }
  }

  queryParamStringFromFacets(facets, facetSelection, query) {
    let queryParams = {};

    for (let i = 0; i < facets.length; i++) {
      const facet = facets[i];

      switch (facet.type) {
        case 'RangeSlider':
          const [lowerVal, higherVal] = facet.value;
          if (lowerVal > facet.min || higherVal < facet.max) {
            queryParams[facet.id] = facet.value;
          }
          break;
        case 'FacetCheckboxItems':
          // Set values at key to array of facet items that are checked
          for (let j = 0; j < facet.items.length; j++) {
            if (facetSelection[facet.id] && facetSelection[facet.id][facet.items[j].key]) {
              if (!queryParams[facet.id]) {
                queryParams[facet.id] = [];
              }
              queryParams[facet.id].push(facet.items[j].key);
            }
          }

          // If they're all checked, don't include in query params
          if (queryParams[facet.id] && queryParams[facet.id].length === facet.items.length) {
            delete queryParams[facet.id];
          }
          break;
        case 'FacetDistinctItems':
          queryParams[facet.id] = facetSelection[facet.id];
          break;
      }
    }

    if (query && query.length) {
      queryParams[ES_QUERY_PARAM_KEY] = query;
    }

    return qs.stringify(queryParams, { addQueryPrefix: true });
  }

  getQueryParamString(state) {
    const { facets, facetSelection, query } = this.getInstanceState(state);
    return this.queryParamStringFromFacets(facets, facetSelection, query);
  }

  queryParamsMap() {
    return qs.parse(window.location.search.substr(1));
  }

  localStorageKey(key) {
    return `${this.nameSpace}-facets-${key}`;
  }

  getActions() {
    if (!this.actions) {
      this.actions = {
        pageList: this.pageList.bind(this),
        search: this.search.bind(this),
        moreResults: this.moreResults.bind(this),
        repeatCurrentSearch: this.repeatCurrentSearch.bind(this),
        facetItemClick: this.facetItemClick.bind(this),
        facetReset: this.facetReset.bind(this),
        rangeChange: this.rangeChange.bind(this),
        facetDistinctItemClick: this.facetDistinctItemClick.bind(this),
        lookup: this.lookup.bind(this),
        initialSearch: this.initialSearch.bind(this),
        searchInputSearch: this.searchInputSearch.bind(this),
        searchColumnsOnly: this.searchColumnsOnly.bind(this),
        syncFacetQueryParams: this.syncFacetQueryParams.bind(this),
        setSearchQuery: this.setSearchQuery.bind(this),
        handleReset: this.handleReset.bind(this)
      };
    }
    return this.actions;
  }

  updateDefaultActions(actions) {
    Object.keys(actions).forEach(name => {
      this[name] = actions[name];
      this.actions[name] = this[name].bind(this);
    });
  }

  getSelectors() {
    return {
      getSearchParameters: this.getSearchParameters.bind(this),
      isLoaded: state => this.getInstanceState(state).loaded,
      getQueryParamString: this.getQueryParamString.bind(this)
    };
  }
}
