import superagent from 'superagent';

const INTERFACE_VERSION_HASH = 'INTERFACE_VERSION_HASH';
const START_CHECK_INTERFACE_UPDATE = 'START_CHECK_INTERFACE_UPDATE';
const STOP_CHECK_INTERFACE_UPDATE = 'STOP_CHECK_INTERFACE_UPDATE';

const initialState = {
  uiSourceVersion: null
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case INTERFACE_VERSION_HASH:
      return {
        ...state,
        uiSourceVersion: action.uiSourceVersion
      };
    case START_CHECK_INTERFACE_UPDATE:
      return {
        ...state,
        checkInterfaceVersionInterval: action.checkInterfaceVersionInterval
      };
    case STOP_CHECK_INTERFACE_UPDATE:
      return {
        ...state,
        checkInterfaceVersionInterval: null
      };
    default:
      return state;
  }
}

export const actions = {};

actions.startCheckInterfaceVersionUpdate = () => dispatch => {
  const checkInterfaceVersionInterval = setInterval(() => dispatch(actions.checkInterfaceVersionUpdate()), 1000 * 60);
  dispatch({
    type: START_CHECK_INTERFACE_UPDATE,
    checkInterfaceVersionInterval: checkInterfaceVersionInterval
  });
};

actions.checkInterfaceVersionUpdate = () => dispatch => {
  superagent.get(window.location.pathname).end((err, res) => {
    if (!err) {
      if (res && res.text) {
        const userInterfaceBody = res.text;
        const bundleRegex = /main.bundle.(.*?).js/;
        const bundleHash = bundleRegex.exec(userInterfaceBody);
        if (bundleHash && bundleHash.length > 1) {
          const newHash = bundleHash[1];
          dispatch({
            type: INTERFACE_VERSION_HASH,
            uiSourceVersion: newHash
          });
        }
      }
    }
  });
};

actions.stopCheckInterfaceVersionUpdate = () => (dispatch, getStore) => {
  const checkInterfaceVersionInterval = getStore().checkInterfaceVersionInterval;
  clearInterval(checkInterfaceVersionInterval);
  dispatch({
    type: STOP_CHECK_INTERFACE_UPDATE
  });
};
