import React from 'react';
import PropTypes from 'prop-types';
import './Select.scss';

export default class Select extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      defaultText: 'Select a value'
    };

    this.buttonRef = React.createRef();
    this.dropdownRef = React.createRef();
    this.toggle = this.toggle.bind(this);
    this.handleMouseEvent = this.handleMouseEvent.bind(this);
  }

  UNSAFE_componentWillReceiveProps(props) {
    //  NOTE: This is a workaround for MPE-2536 since this component lives forever in the slide-out panel
    //  Once the lifecycle of the component is fixed, this can be removed
    if (props.defaultOption) {
      this.setState({ selectedOption: props.defaultOption });
    }
  }

  componentDidMount() {
    document.addEventListener('mouseup', this.handleMouseEvent);
  }
  UNSAFE_componentWillUnmount() {
    document.removeEventListener('mouseup', this.handleMouseEvent);
  }

  handleMouseEvent(event) {
    if (!this.state.open) return;

    const buttonClicked = this.buttonRef.current === event.path[0];
    const menuItemClicked = event.path.includes(this.dropdownRef.current);

    //  MPE-3619 - Avoid button click causing the selection dropdown to close then immediately reopen
    if (!menuItemClicked && !buttonClicked) {
      this.setState({ ...this.state, open: false });
    }
  }

  toggle() {
    this.setState({
      ...this.state,
      open: !this.state.open
    });
  }

  itemClick(option) {
    this.setState({ ...this.state, open: false, selectedOption: option });
    if (this.props.onSelect) this.props.onSelect(option);
  }

  render() {
    let { selectedOption } = this.state;
    if (!selectedOption && this.props.defaultOption) selectedOption = this.props.defaultOption;

    return (
      <div
        className={`pxl-dropdown ${this.props.dropdownClass} ${this.state.open ? 'open' : ''}`}
        data-test-id={this.props.testId}
      >
        <button
          onClick={this.toggle}
          className={`pxl-btn ${this.props.btnClass} pxl-dropdown__toggle`}
          type="button"
          id={this.props.id}
          data-test-id={this.props.testId === null ? null : this.props.testId + '-button'}
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded={this.state.open}
          ref={this.buttonRef}
        >
          {this.props.iconClass && <span className={this.props.iconClass} />}
          {(selectedOption && selectedOption.name) || this.state.defaultText}
        </button>
        <div
          className="pxl-dropdown__menu"
          aria-labelledby={this.props.id}
          data-test-id={this.props.testId === null ? null : this.props.testId + '-options'}
          ref={this.dropdownRef}
        >
          {this.props.options.map(option => {
            return (
              <div
                key={option.value}
                data-test-id={this.props.testId === null ? null : this.props.testId + '-item-' + option.name}
                className="pxl-dropdown__item"
                onClick={this.itemClick.bind(this, option)}
              >
                {option.name}
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

Select.propTypes = {
  options: PropTypes.array,
  defaultOption: PropTypes.object,
  onSelect: PropTypes.func,
  btnClass: PropTypes.string,
  btnText: PropTypes.string,
  children: PropTypes.array.isRequired,
  className: PropTypes.string,
  dropdownClass: PropTypes.string,
  iconClass: PropTypes.string,
  testId: PropTypes.string,
  id: PropTypes.string.isRequired
};

Select.defaultProps = {
  btnClass: 'pxl-btn-default',
  btnText: 'Menu',
  children: [],
  className: '',
  dropdownClass: '',
  iconClass: '',
  testId: null,
  id: ''
};
