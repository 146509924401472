import React, { Component } from 'react';
import { arrayOf, bool, func, object, oneOfType, number, shape, string, array } from 'prop-types';
import take from 'lodash/take';
import isEqual from 'lodash/isEqual';
import classnames from 'classnames';
import CheckboxFacet from './Facet';
import Collapsible from '../Collapsible/Collapsible';
import RadioButtonFacet from '@payscale/payscale-shared-ui-v3/lib/FacetPanel/RadioButtonFacet';
import RangeFacet from './Slider';
import Search from '../Search/Search';
import './FacetGroup.scss';

class FacetGroup extends Component {
  static propTypes = {
    collapsible: bool,
    facet: shape({
      collapsible: bool,
      collapsedDefault: bool,
      facetSort: number,
      id: string.isRequired,
      items: arrayOf(
        shape({
          key: oneOfType([number, string]).isRequired,
          doc_count: number.isRequired
        })
      ),
      max: number,
      min: number,
      maxLabel: string,
      minLabel: string,
      maxLabelInBoundary: string,
      minLabelInBoundary: string,
      step: number,
      title: string.isRequired,
      type: string,
      value: arrayOf(number)
    }).isRequired,
    facetDistinctItemClick: func,
    facetItemClick: func.isRequired,
    facetReset: func.isRequired,
    facetSelection: object.isRequired,
    getLabels: func,
    isDisabled: bool,
    lastFacetFilteredID: string,
    limit: number,
    rangeChange: func,
    rangeHandleStyle: array,
    rangeTrackStyle: array,
    showFacetSectionSearch: bool,
    showZeros: bool,
    isUncheckedInitially: bool,
    lockOtherFacets: bool,
    scrollHeightCutoff: number
  };

  static defaultProps = {
    isDisabled: false,
    limit: 8,
    showZeros: true,
    isUncheckedInitially: false
  };

  constructor(props) {
    super(props);

    let collapsed = false;
    if (props.facet.collapsible) {
      if (window.localStorage.getItem(this.localStorageKey()) !== null) {
        collapsed = this.getCollapsedFromLocalStorage();
      } else {
        collapsed = props.facet.collapsedDefault;
        this.setCollapsedState(props.facet.collapsedDefault);
      }
    }

    this.state = {
      collapsed: collapsed,
      currentLimit: props.limit,
      facetItems: [],
      hasSearchValue: false,
      searchValue: '',
      facet: {}
    };

    this.createFacetItems = this.createFacetItems.bind(this);
    this.facetReset = this.facetReset.bind(this);
    this.filterZeroCounts = this.filterZeroCounts.bind(this);
    this.getFacetsToRender = this.getFacetsToRender.bind(this);
    this.renderPagination = this.renderPagination.bind(this);
    this.renderResetButton = this.renderResetButton.bind(this);
    this.setLimit = this.setLimit.bind(this);
    this.toggleCollapsed = this.toggleCollapsed.bind(this);
  }

  componentDidMount() {
    const { items, type } = this.props.facet;

    if (type === 'FacetCheckboxItems') this.setState({ facetItems: items });
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      lastFacetFilteredID,
      resetAllTriggered,
      resetState,
      showFacetSectionSearch,
      facet,
      isUncheckedInitially
    } = this.props;
    if (
      (!lastFacetFilteredID && prevProps.lastFacetFilteredID && showFacetSectionSearch && resetAllTriggered) ||
      !isEqual(prevProps?.facet?.items, facet?.items)
    ) {
      this.getFacetsToRender();
      if (resetState) {
        resetState();
      }
    }
  }

  createFacetItems() {
    const {
      facet,
      facetDistinctItemClick,
      facetSelection,
      facetItemClick,
      getLabels,
      isDisabled,
      rangeChange,
      rangeHandleStyle,
      rangeTrackStyle,
      showZeros,
      isUncheckedInitially,
      lastFacetFilteredID,
      lockOtherFacets
    } = this.props;
    const { currentLimit, facetItems } = this.state;
    switch (facet.type) {
      case 'FacetCheckboxItems':
        const facetsToRender = showZeros
          ? take(facetItems, currentLimit)
          : take(this.filterZeroCounts(facetSelection[facet.id]), currentLimit);
        return facetsToRender.map(facetItem => {
          if (
            !showZeros &&
            ((!isUncheckedInitially && facetItem.doc_count === 0) ||
              (!isUncheckedInitially && !facetSelection[facet.id][facetItem.key]))
          ) {
            return;
          } else {
            return (
              <CheckboxFacet
                facet={facet}
                facetItem={facetItem}
                facetItemClick={facetItemClick}
                isChecked={facetSelection[facet.id][facetItem.key]}
                isDisabled={
                  facetItem.isDisabled ||
                  isDisabled ||
                  (lockOtherFacets && lastFacetFilteredID && lastFacetFilteredID !== facet.id)
                }
                key={facetItem.key}
              />
            );
          }
        });
      case 'RangeSlider':
        const labels = getLabels(facet);
        return (
          <RangeFacet
            facet={facet}
            labels={labels}
            rangeChange={rangeChange}
            rangeHandleStyle={rangeHandleStyle}
            rangeTrackStyle={rangeTrackStyle}
          />
        );
      case 'FacetDistinctItems':
        return (
          <RadioButtonFacet
            facet={facet}
            facetDistinctItemClick={facetDistinctItemClick}
            selection={facetSelection[facet.id]}
          />
        );
      default:
        return '';
    }
  }

  facetReset() {
    const { facet, facetReset, facetSelection, showFacetSectionSearch } = this.props;

    if (showFacetSectionSearch) {
      const inputElement = document.getElementById(`facet__search-input-${facet.id}`);

      if (inputElement) {
        document.getElementById(`facet__search-input-${facet.id}`).value = '';
        this.getFacetsToRender();
      }
    }

    facetReset(facet, facetSelection);
  }

  filterZeroCounts(facet) {
    const { facetItems } = this.state;
    return facetItems.filter(item => item.doc_count !== 0 || (facet && facet[item.key]));
  }

  getCollapsedFromLocalStorage() {
    return window.localStorage.getItem(this.localStorageKey()) === 'true';
  }

  getFacetsToRender(e) {
    const { facet } = this.props;
    const hasTextSeachValue = typeof e?.target?.value === 'string';
    const eventText = hasTextSeachValue ? e.target.value : '';
    const hasSearchValue = hasTextSeachValue && eventText.length > 0;

    this.setState({
      facetItems: facet.items.filter(item =>
        item.key
          ?.toString()
          .toLowerCase()
          .includes(eventText.toLowerCase())
      ),
      hasSearchValue,
      searchValue: eventText,
      facet
    });
  }

  localStorageKey() {
    return `facets_${this.props.facet.id}_collapsed`;
  }

  renderPagination() {
    const { currentLimit, facetItems } = this.state;
    const { isDisabled, facet, showZeros, limit } = this.props;
    const { type, items } = facet;

    // Only render pagination for checkbox items
    if (type !== 'FacetCheckboxItems') {
      return '';
    }

    const zeroCount = facetItems.filter(item => item.doc_count !== 0).length;
    const shouldRenderPagination = showZeros ? facetItems.length > limit : zeroCount > limit;

    if (!shouldRenderPagination) {
      return '';
    }

    const linkClass = classnames({
      'pagination__link-disabled': isDisabled,
      pagination__link: !isDisabled
    });

    return (
      <div className="pagination__link-containter">
        <a className={linkClass} onClick={isDisabled ? null : this.setLimit}>
          {currentLimit < facetItems.length ? 'View more' : 'View less'}
        </a>
      </div>
    );
  }

  renderResetButton() {
    const { facetItems } = this.state;
    const { facet, facetSelection, isUncheckedInitially } = this.props;
    const { max, min, type, value } = facet;

    let shouldShowResetBtn = false;

    if (type === 'FacetCheckboxItems') {
      if (facetItems.length === 1 && facetItems[0].isDisabled) {
        shouldShowResetBtn = false;
      } else {
        for (let index = 0; index < facetItems.length; index++) {
          if (Object.values(facetSelection[facet.id]).includes(isUncheckedInitially)) {
            shouldShowResetBtn = true;
            break;
          }
        }
      }
    } else if (type === 'RangeSlider') {
      shouldShowResetBtn = value[0] !== min || value[1] !== max;
    }

    if (!shouldShowResetBtn) {
      return null;
    }

    return (
      <a className="facets__facet-reset" onClick={this.facetReset}>
        reset
      </a>
    );
  }

  setCollapsedState(state) {
    window.localStorage.setItem(this.localStorageKey(), state);
  }

  setLimit() {
    const { currentLimit, facetItems } = this.state;
    const { facet, limit } = this.props;

    this.setState({ currentLimit: currentLimit < facetItems.length ? facetItems.length : limit });
  }

  toggleCollapsed(e) {
    if (!this.props.facet.collapsible || (e && e.target.className === 'facets__facet-reset')) {
      return;
    }

    const newCollapsed = !this.state.collapsed;
    this.setCollapsedState(newCollapsed);
    this.setState({ collapsed: newCollapsed });
  }

  render() {
    const { facet, showFacetSectionSearch, showZeros, scrollHeightCutoff } = this.props;
    const { title, collapsible } = facet;
    const { collapsed, facetItems } = this.state;

    return (
      <div
        className={classnames({
          facets__facet: true,
          'facets__facet--collapsible': collapsible,
          'facets__facet--collapsed': collapsed
        })}
        key={title}
      >
        <div className="facets__facet-group">
          <span className="facets__facet-group__title" onClick={this.toggleCollapsed}>
            <span>
              {title}
              {this.renderResetButton()}
            </span>
            {collapsible && <span className="facets__facet__caret" />}
          </span>
        </div>
        <Collapsible className="facets__facet-items" scrollHeightCutoff={scrollHeightCutoff} collapsed={collapsed}>
          {showFacetSectionSearch &&
            facet.type === 'FacetCheckboxItems' &&
            (showZeros ? facetItems.length > 10 : this.filterZeroCounts().length > 10 || this.state.hasSearchValue) && (
              <Search
                className="facets__facet-search"
                id={`facet__search-input-${facet.id}`}
                onChange={this.getFacetsToRender}
                onClick={this.getFacetsToRender}
                value={this.state.searchValue}
              />
            )}
          {this.createFacetItems()}
          {facet.type === 'FacetCheckboxItems' && facetItems.length === 0 && (
            <div className="facet__items-empty">There are no results for your search term.</div>
          )}
          {this.renderPagination()}
        </Collapsible>
      </div>
    );
  }
}

export default FacetGroup;
