import { getUpdatedMarketSalary } from '../../../../../ps-components/src/Modules/AddJob/reducers/addJobUpdatedDetails';
import { actions as jobSurveyListActions } from '../../../../../ps-components/src/Modules/AddJob/reducers/jobSurveyList';
import { selectJob, updatePayReport } from '../../../../../ps-components/src/Modules/AddJob/reducers/addJob';
import { certificationsSearch } from '../../../../../ps-components/src/Modules/AddJob/reducers/jobCertifications';
import { skillsSearch } from '../../../../../ps-components/src/Modules/AddJob/reducers/skillsByJobList';
import { getCareerLevels } from '../../../../../ps-components/src/Modules/AddJob/reducers/careerLevels';

const GET_WORKLOCATIONS = 'GET_WORKLOCATIONS';
const GET_WORKLOCATIONS_SUCCESS = 'GET_WORKLOCATIONS_SUCCESS';
const GET_WORKLOCATIONS_FAILURE = 'GET_WORKLOCATIONS_FAILURE';

const APPLY_JOBS = 'APPLY_JOBS';
const APPLY_JOBS_SUCCESS = 'APPLY_JOBS_SUCCESS';
const APPLY_JOBS_FAILURE = 'APPLY_JOBS_FAILURE';

const GET_WORKLOCATIONS_COUNT = 'GET_WORKLOCATIONS_COUNT';
const GET_WORKLOCATIONS_COUNT_SUCCESS = 'GET_WORKLOCATIONS_COUNT_SUCCESS';
const GET_WORKLOCATIONS_COUNT_FAIL = 'GET_WORKLOCATIONS_COUNT';

const GET_JOB_DETAILS = 'GET_JOB_DETAILS';

const QUESTION_OPTIONS_SUCCESS = 'QUESTION_OPTIONS_SUCCESS';
const CAREER_LIST_SUCCESS = 'CAREER_LIST_SUCCESS';

const UPDATE_CAREER_LEVEL = 'UPDATE_CAREER_LEVEL';
const UPDATE_EDUCATION = 'UPDATE_EDUCATION';
const UPDATE_ROLE = 'UPDATE_ROLE';
const UPDATE_YEARS_EXP = 'UPDATE_YEARS_EXP';
const UPDATE_YEARS_MIN = 'UPDATE_YEARS_MIN';
const UPDATE_SKILLS_CERTS = 'UPDATE_SKILLS_CERTS';

const GET_JOB_UPDATE = 'GET_JOB_UPDATE';
const GET_JOB_UPDATE_SUCCESS = 'GET_JOB_UPDATE_SUCCESS';
const GET_JOB_UPDATE_FAIL = 'GET_JOB_UPDATE_FAIL';

const REMOVE_UPDATE_JOB = 'REMOVE_UPDATE_JOB';

const initialState = {
  loading: false,
  workLocations: [],
  careerLevels: [],
  educationList: [],
  unpricedJobs: undefined,
  updateJobs: [],
  confirmBtnDisabled: false,
  submitBtnDisabled: false,
  unpricedJobsLoaded: false,
  workLocationsCount: 0,
  automatch: true
};

export default function reducer(state = initialState, action = {}) {
  let unpricedJobs = [];
  let updateJobs = [];
  let hasJob = false;
  let jobIdx = undefined;

  switch (action.type) {
    case GET_WORKLOCATIONS:
      return {
        ...state
      };
    case GET_WORKLOCATIONS_SUCCESS:
      return {
        ...state,
        workLocations: action.result
      };
    case GET_WORKLOCATIONS_FAILURE:
      return {
        ...state,
        sucess: false,
        error: action.result
      };
    case APPLY_JOBS:
      return {
        ...state,
        confirmBtnDisabled: true,
        unpricedJobsLoading: true
      };
    case APPLY_JOBS_SUCCESS:
      action.result.unpricedJobs.searchLoading = false;
      action.result.unpricedJobs.searchLoaded = true;

      let selectedPayscaleJob = {};
      if (
        action.result.unpricedJobs &&
        action.result.unpricedJobs.internalJobs &&
        action.result.unpricedJobs.internalJobs.length > 0
      ) {
        const jobs = action.result.unpricedJobs.internalJobs;

        let job = [];
        for (let x = 0; x < jobs.length; x++) {
          if (jobs[x].payscaleJobTitles && jobs[x].payscaleJobTitles.length > 0) {
            let allSkills = jobs[x].payscaleJobTitles[0].skillSuggestions;
            let allCerts = jobs[x].payscaleJobTitles[0].certSuggestions;
            job = {
              selectedJobRow: x,
              selectedCrowdSource: 0,
              orgJobId: jobs[x].orgJobId,
              orgJobCode: jobs[x].orgJobCode,
              orgJobCodeKey: jobs[x].orgJobCodeKey,
              crowdSourceTitle: jobs[x].payscaleJobTitles[0].JobTitle,
              defaultEducation: jobs[x].dataScienceResponse.results.education.value,
              supervisoryRole: jobs[x].dataScienceResponse.results.supervisoryRole.value,
              careerLevel: jobs[x].payscaleJobTitles[0].careerLevel,
              skillsByJobList: allSkills,
              jobCertifications: allCerts,
              workLocation: jobs[x].payscaleJobInfo.workLocation,
              workLocationExact: jobs[x].payscaleJobInfo.workLocationExact,
              skillsAndCerts: {},
              yearsOfExperience: jobs[x].dataScienceResponse.results.yearsOfExperienceTypical.value,
              yearsMinimum: ''
            };
            updateJobs.push(job);
          } else {
            delete action.result.unpricedJobs.internalJobs[x];
            action.result.unpricedJobs.internalJobs.length--;
          }
        }

        selectedPayscaleJob = updateJobs[0];
      }

      return {
        ...state,
        success: true,
        unpricedJobs: action.result.unpricedJobs,
        selectedPayscaleJob: selectedPayscaleJob,
        updateJobs: updateJobs,
        unpricedJobsLoading: false,
        unpricedJobsLoaded: true,
        confirmBtnDisabled: false,
        submitBtnDisabled: false
      };
    case APPLY_JOBS_FAILURE:
      return {
        ...state,
        success: false,
        error: action.result,
        unpricedJobsLoading: false,
        confirmBtnDisabled: false,
        submitBtnDisabled: false
      };
    case GET_WORKLOCATIONS_COUNT:
      return {
        ...state
      };
    case GET_WORKLOCATIONS_COUNT_SUCCESS:
      return {
        ...state,
        workLocationsCount: action.result.locationCount
      };
    case GET_WORKLOCATIONS_COUNT_FAIL:
      return {
        ...state
      };
    case GET_JOB_DETAILS:
      updateJobs = state.updateJobs;

      hasJob = false;
      jobIdx = undefined;
      for (let x = 0; x < updateJobs.length; x++) {
        if (updateJobs[x].orgJobId === action.jobDetails.orgJobId) {
          hasJob = true;
          jobIdx = x;
        }
      }

      if (hasJob) {
        updateJobs[jobIdx] = action.jobDetails;
      } else {
        updateJobs.push(action.jobDetails);
      }

      return {
        ...state,
        selectedPayscaleJob: action.jobDetails,
        updateJobs: updateJobs,
        submitBtnDisabled: false
      };
    case CAREER_LIST_SUCCESS:
      let careerList = [];
      if (action.result && action.result.length) {
        careerList = action.result.map(row => {
          return { value: row.career, label: row.career };
        });
      }
      return {
        ...state,
        careerLevels: careerList
      };
    case QUESTION_OPTIONS_SUCCESS:
      let HighestDegreeEarnedList = []; // eslint-disable-line prefer-const

      // jobalyzer must not be returning a result here
      if (!action.result) {
        return {
          ...state,
          error: 'No action.result in QUESTION_OPTIONS_SUCCESS'
        };
      }

      for (let mIdx = 0; mIdx < action.result.length; mIdx++) {
        const resultName = action.result[mIdx];
        if (resultName.HighestDegreeEarned && resultName.HighestDegreeEarned.length) {
          HighestDegreeEarnedList.push(resultName.HighestDegreeEarned);
        }
      }

      return {
        ...state,
        educationList: HighestDegreeEarnedList[0]
      };
    case UPDATE_CAREER_LEVEL:
      updateJobs = state.updateJobs;
      unpricedJobs = state.unpricedJobs;
      unpricedJobs.internalJobs[action.jobDetails.selectedPayscaleJob.selectedJobRow].payscaleJobTitles[
        action.jobDetails.selectedPayscaleJob.selectedCrowdSource
      ].careerLevel = action.jobDetails.career;

      action.jobDetails.selectedPayscaleJob.careerLevel = action.jobDetails.career;

      hasJob = false;
      jobIdx = undefined;
      for (let x = 0; x < updateJobs.length; x++) {
        if (updateJobs[x].orgJobId === action.jobDetails.selectedPayscaleJob.orgJobId) {
          hasJob = true;
          jobIdx = x;
        }
      }

      if (hasJob) {
        updateJobs[jobIdx] = action.jobDetails.selectedPayscaleJob;
      } else {
        updateJobs.push(action.jobDetails.selectedPayscaleJob);
      }

      return {
        ...state,
        selectedPayscaleJob: action.jobDetails.selectedPayscaleJob,
        unpricedJobs: unpricedJobs,
        updateJobs: updateJobs
      };
    case UPDATE_EDUCATION:
      updateJobs = state.updateJobs;
      unpricedJobs = state.unpricedJobs;
      unpricedJobs.internalJobs[
        action.jobDetails.selectedPayscaleJob.selectedJobRow
      ].dataScienceResponse.results.education.value = action.highestDegreeEarned.HighestDegreeEarned;

      action.jobDetails.selectedPayscaleJob.defaultEducation = action.highestDegreeEarned.HighestDegreeEarned;

      hasJob = false;
      jobIdx = undefined;
      for (let x = 0; x < updateJobs.length; x++) {
        if (updateJobs[x].orgJobId === action.jobDetails.selectedPayscaleJob.orgJobId) {
          hasJob = true;
          jobIdx = x;
        }
      }

      if (hasJob) {
        updateJobs[jobIdx] = action.jobDetails.selectedPayscaleJob;
      } else {
        updateJobs.push(action.jobDetails.selectedPayscaleJob);
      }

      return {
        ...state,
        selectedPayscaleJob: action.jobDetails.selectedPayscaleJob,
        unpricedJobs: unpricedJobs,
        updateJobs: updateJobs
      };
    case UPDATE_ROLE:
      updateJobs = state.updateJobs;
      unpricedJobs = state.unpricedJobs;
      unpricedJobs.internalJobs[
        action.jobDetails.selectedPayscaleJob.selectedJobRow
      ].dataScienceResponse.results.supervisoryRole.value = action.jobDetails.supervisoryRole.value;

      action.jobDetails.selectedPayscaleJob.supervisoryRole = action.jobDetails.supervisoryRole.value;
      hasJob = false;
      jobIdx = undefined;
      for (let x = 0; x < updateJobs.length; x++) {
        if (updateJobs[x].orgJobId === action.jobDetails.selectedPayscaleJob.orgJobId) {
          hasJob = true;
          jobIdx = x;
        }
      }

      if (hasJob) {
        updateJobs[jobIdx] = action.jobDetails.selectedPayscaleJob;
      } else {
        updateJobs.push(action.jobDetails.selectedPayscaleJob);
      }

      return {
        ...state,
        selectedPayscaleJob: action.jobDetails.selectedPayscaleJob,
        unpricedJobs: unpricedJobs,
        updateJobs: updateJobs
      };
    case UPDATE_YEARS_EXP:
      updateJobs = state.updateJobs;
      unpricedJobs = state.unpricedJobs;
      unpricedJobs.internalJobs[
        action.jobDetails.selectedPayscaleJob.selectedJobRow
      ].dataScienceResponse.results.yearsOfExperienceTypical.value = action.jobDetails.yearsOfExperience;

      action.jobDetails.selectedPayscaleJob.yearsOfExperience = action.jobDetails.yearsOfExperience;
      hasJob = false;
      jobIdx = undefined;
      for (let x = 0; x < updateJobs.length; x++) {
        if (updateJobs[x].orgJobId === action.jobDetails.selectedPayscaleJob.orgJobId) {
          hasJob = true;
          jobIdx = x;
        }
      }

      if (hasJob) {
        updateJobs[jobIdx] = action.jobDetails.selectedPayscaleJob;
      } else {
        updateJobs.push(action.jobDetails.selectedPayscaleJob);
      }

      return {
        ...state,
        selectedPayscaleJob: action.jobDetails.selectedPayscaleJob,
        unpricedJobs: unpricedJobs,
        updateJobs: updateJobs
      };
    case UPDATE_YEARS_MIN:
      updateJobs = state.updateJobs;
      unpricedJobs = state.unpricedJobs;
      unpricedJobs.internalJobs[action.jobDetails.selectedPayscaleJob.selectedJobRow].payscaleJobTitles[
        action.jobDetails.selectedPayscaleJob.selectedCrowdSource
      ].yearsMinimum = action.jobDetails.yearsMinimum;

      action.jobDetails.selectedPayscaleJob.yearsMinimum = action.jobDetails.yearsMinimum;
      hasJob = false;
      jobIdx = undefined;
      for (let x = 0; x < updateJobs.length; x++) {
        if (updateJobs[x].orgJobId === action.jobDetails.selectedPayscaleJob.orgJobId) {
          hasJob = true;
          jobIdx = x;
        }
      }

      if (hasJob) {
        updateJobs[jobIdx] = action.jobDetails.selectedPayscaleJob;
      } else {
        updateJobs.push(action.jobDetails.selectedPayscaleJob);
      }

      return {
        ...state,
        selectedPayscaleJob: action.jobDetails.selectedPayscaleJob,
        unpricedJobs: unpricedJobs,
        updateJobs: updateJobs
      };
    case GET_JOB_UPDATE:
      return {
        ...state,
        submitBtnDisabled: true
      };
    case GET_JOB_UPDATE_SUCCESS:
      return {
        ...state,
        submitBtnDisabled: false,
        unpricedJobs: action.result.unpricedJobs
      };
    case GET_JOB_UPDATE_FAIL:
      return {
        ...state,
        submitBtnDisabled: false
      };
    case UPDATE_SKILLS_CERTS:
      updateJobs = state.updateJobs;
      const row = action.jobDetails.selectedPayscaleJob.selectedJobRow;
      const selectedJob = state.selectedPayscaleJob;
      selectedJob.jobDetails = [];

      if (action.jobDetails.skillsAndCerts) {
        if (action.jobDetails.skillsAndCerts.Skills && action.jobDetails.skillsAndCerts.Skills.length > 0) {
          updateJobs[row].skills = action.jobDetails.skillsAndCerts.Skills;
        }

        if (action.jobDetails.skillsAndCerts.SkillNameList) {
          updateJobs[row].skillNameList = action.jobDetails.skillsAndCerts.SkillNameList;
        }

        if (
          action.jobDetails.skillsAndCerts.Certifications &&
          action.jobDetails.skillsAndCerts.Certifications.length > 0
        ) {
          updateJobs[row].certifications = action.jobDetails.skillsAndCerts.Certifications;
        }

        if (action.jobDetails.skillsAndCerts.CertNameList) {
          updateJobs[row].certNameList = action.jobDetails.skillsAndCerts.CertNameList;
        }
      }

      if (!updateJobs[row].skills) {
        updateJobs[row].skills = updateJobs[row].skillNameList;
      }

      if (!updateJobs[row].certifications) {
        updateJobs[row].certifications = updateJobs[row].certNameList;
      }

      selectedJob.skills = updateJobs[row].skills;
      selectedJob.skillNameList = updateJobs[row].skillNameList;
      selectedJob.certifications = updateJobs[row].certifications;
      selectedJob.certNameList = updateJobs[row].certNameList;
      selectedJob.jobDetails.SkillNameList = updateJobs[row].skillNameList;
      selectedJob.jobDetails.CertNameList = updateJobs[row].certNameList;

      return {
        ...state,
        updateJobs: updateJobs,
        selectedPayscaleJob: selectedJob
      };
    case REMOVE_UPDATE_JOB:
      updateJobs = state.updateJobs;
      let submitBtnDisabled = false;
      if (updateJobs.length > 1) {
        updateJobs.splice(action.jobDetails.selectedJobRow, 1);
      } else {
        updateJobs = [];
        submitBtnDisabled = true;
      }

      return {
        ...state,
        updateJobs: updateJobs,
        submitBtnDisabled: submitBtnDisabled,
        selectedPayscaleJob: {}
      };
    default:
      return { ...state };
  }
}

export function getMissingWorkLocations(orgJobCodeKey, payscaleLocation) {
  return (dispatch, getState) => {
    dispatch({
      types: ['GET_WORKLOCATIONS', 'GET_WORKLOCATIONS_SUCCESS', 'GET_WORKLOCATIONS_FAILURE'],
      promise: client => client.get('/api/hris/work-location-mapping/getMissingWorkLocations', {})
    });
  };
}

export function applyMappedWorkLocations(automatch) {
  return (dispatch, getState) => {
    dispatch(selectJob());
    dispatch(getCareerLevels());

    const workLocations = getState().jobPropagate.selectedLocations;

    dispatch({
      types: ['APPLY_JOBS', 'APPLY_JOBS_SUCCESS', 'APPLY_JOBS_FAILURE'],
      promise: client =>
        client.post('/api/hris/work-location-mapping/applyMappedWorkLocations', {
          params: {
            locations: workLocations,
            automatch: automatch
          }
        })
    });
  };
}

export function getMissingWorkLocationsCount() {
  return (dispatch, getState) => {
    dispatch({
      types: ['GET_WORKLOCATIONS_COUNT', 'GET_WORKLOCATIONS_COUNT_SUCCESS', 'GET_WORKLOCATIONS_COUNT_FAIL'],
      promise: client => client.get('/api/hris/work-location-mapping/countMissingWorkLocations', {})
    });
  };
}

export function submitUnpricedJob() {
  return (dispatch, getState) => {
    const updateJobs = getState().confirmLocations.updateJobs;
    dispatch({
      types: ['GET_JOB_UPDATE', 'GET_JOB_UPDATE_SUCCESS', 'GET_JOB_UPDATE_FAIL'],
      promise: client =>
        client.post('/api/hris/work-location-mapping/saveUnpricedJobs', {
          params: {
            jobDetails: JSON.stringify(updateJobs)
          }
        }),
      updateJobs
    });
  };
}

export function handleJobClick(jobDetails) {
  return (dispatch, getState) => {
    dispatch({
      type: 'GET_JOB_DETAILS',
      jobDetails
    });
  };
}

export function updateEducationLevel(jobDetails, highestDegreeEarned) {
  return (dispatch, getState) => {
    dispatch({
      type: 'UPDATE_EDUCATION',
      jobDetails,
      highestDegreeEarned
    });
  };
}

export function updateSupervisoryRole(jobDetails) {
  return (dispatch, getState) => {
    dispatch({
      type: 'UPDATE_ROLE',
      jobDetails: jobDetails
    });
  };
}

export function updateCareerLevel(jobDetails) {
  return (dispatch, getState) => {
    dispatch({
      type: 'UPDATE_CAREER_LEVEL',
      jobDetails: jobDetails
    });
  };
}

export function updateYearsExp(jobDetails) {
  return (dispatch, getState) => {
    dispatch({
      type: 'UPDATE_YEARS_EXP',
      jobDetails: jobDetails
    });
  };
}

export function updateMinYears(jobDetails) {
  return (dispatch, getState) => {
    dispatch({
      type: 'UPDATE_YEARS_MIN',
      jobDetails: jobDetails
    });
  };
}

export function updateSkillCertItems(jobDetails) {
  return (dispatch, getState) => {
    dispatch({
      type: 'UPDATE_SKILLS_CERTS',
      jobDetails: jobDetails
    });
  };
}

export function removeUpdateJobs(jobDetails) {
  return (dispatch, getState) => {
    dispatch({
      type: 'REMOVE_UPDATE_JOB',
      jobDetails: jobDetails
    });
  };
}

export function searchSkills(options) {
  return (dispatch, getState) => {
    dispatch(skillsSearch(options));
  };
}

export function searchCerts(options) {
  return (dispatch, getState) => {
    dispatch(certificationsSearch(options));
  };
}
