import moment from 'moment';

const SALARY_TREND_RANGE_BUTTON_CLICK = 'SALARY_TREND_RANGE_BUTTON_CLICK';
const CHANGE_DATE_RANGE = 'SALARY_TREND_CHANGE_DATE_RANGE';

const initialState = {
  reportData: [],
  nationalReportData: [],
  highChartsData: undefined,
  range: { start: '', end: '' },
  rangeButtonText: ['Last 2 yrs', 'Last 1 yr', 'Last 6 mos', 'Last 3 mos'],
  selectedButtonId: '',
  defaultButtonId: 'Last2yrs'
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case 'JOB_DETAILS_SUCCESS':
      const workLocation =
        action.result && action.result.payscale_job_info && action.result.payscale_job_info.workLocation;
      const reportData = getTrendReportData(action.result);
      const nationalReportData = getTrendReportData(action.result, 'national');
      if (!reportData.length) return { ...state, reportData, nationalReportData };
      const defaultRange = getNewRangeFromButtonId(state.defaultButtonId, reportData);
      const highChartsData = calcHighChartsData(reportData, nationalReportData, workLocation, defaultRange);
      const deltaForRange = calcRangeDelta(reportData, defaultRange);
      return {
        ...state,
        reportData,
        nationalReportData,
        highChartsData,
        workLocation,
        range: defaultRange,
        rangeDelta: deltaForRange,
        selectedButtonId: state.defaultButtonId,
        payScaleJobTitle: action.result.payscale_job_title
      };
    case SALARY_TREND_RANGE_BUTTON_CLICK:
      const newRange = getNewRangeFromButtonId(action.buttonId, state.reportData);
      const newHighChartsData = calcHighChartsData(
        state.reportData,
        state.nationalReportData,
        state.workLocation,
        newRange,
        false
      );
      const delta = calcRangeDelta(state.reportData, newRange);
      return {
        ...state,
        range: newRange,
        rangeDelta: delta,
        highChartsData: newHighChartsData,
        selectedButtonId: action.buttonId
      };
    case CHANGE_DATE_RANGE:
      const newRangeFromSlider = getRangeFromSliderChange(action.fromIndex, action.toIndex, state);
      return {
        ...state,
        range: newRangeFromSlider,
        highChartsData: calcHighChartsData(
          state.reportData,
          state.nationalReportData,
          state.workLocation,
          newRangeFromSlider,
          false
        ),
        rangeDelta: calcRangeDelta(state.reportData, newRangeFromSlider),
        selectedButtonId: ''
      };
    default:
      return state;
  }
}

const getTrendReportData = (result, name) => {
  let data;
  const reportName = name === 'national' ? 'nationalMonthlyTrendsReport' : 'monthlyTrendsReport';
  try {
    data =
      result.payscale_job_info.Reports[reportName] ||
      // (!name && result.payscale_job_info.Reports.trendsReport.TrendReport.MarketTrends) ||
      [];
  } catch (err) {
    data = [];
  }
  return addDateAndChangeInfo(data);
};

const addDateAndChangeInfo = data => {
  if (!data) {
    return [];
  }
  if (!Array.isArray(data)) {
    return [];
  }

  data.splice(25);
  data.reverse();
  if (!validateReportData(data)) return [];
  data.forEach((item, index) => {
    item.datePretty = moment(item.EndDateUtc).format('MM/YYYY');
    item.index = index;
    if (index !== 0) {
      item.change = calcDelta(data[0].MedianAnnualBasePay, item.MedianAnnualBasePay);
    } else {
      item.change = 0;
    }
  });
  return data;
};

const validateReportData = data => {
  const allItemsAreValid = data.every(
    item => item && typeof item.EndDateUtc === 'string' && typeof item.MedianAnnualBasePay === 'number'
  );
  const lengthIsValid = data.length === 25;
  return allItemsAreValid && lengthIsValid;
};

const calcDelta = (arg1, arg2) => {
  const value1 = typeof arg1 === 'object' ? arg1.MedianAnnualBasePay : arg1;
  const value2 = typeof arg2 === 'object' ? arg2.MedianAnnualBasePay : arg2;
  const diff = value2 - value1;
  return diff / value1 * 100;
};

const getSelectionEndPoints = (reportData, range) => {
  let prevInRange = false;
  const endPoints = [];

  reportData.forEach((point, index) => {
    const inRange = isPointWithinRange(point, range);
    const isLastPoint = index === reportData.length - 1;

    if (index === 0 || isLastPoint) {
      if (inRange) endPoints.push(point);
      else if (isLastPoint && prevInRange) endPoints.push(reportData[index - 1]);
    } else {
      // if it's the end point push the point before the out of range occurred to get true endpoint;
      const indexToPush = endPoints.length ? index - 1 : index;
      if (inRange !== prevInRange) endPoints.push(reportData[indexToPush]);
    }
    prevInRange = inRange;
  });

  return endPoints;
};

const calcRangeDelta = (reportData, range) => {
  const endPoints = getSelectionEndPoints(reportData, range);
  if (endPoints.length < 2) return 0;
  return calcDelta(endPoints[0], endPoints[1]);
};

const calcYAxisRange = reportData => {
  const values = reportData.map(point => point.change);
  const min = Math.min(...values);
  const max = Math.max(...values);

  const bufferPercentage = 0.18;
  const range = max - min;
  const buffer = range * bufferPercentage;

  const yAxisRange = {
    min: min - buffer,
    max: max + buffer
  };
  yAxisRange.threshold = 0;

  return yAxisRange;
};

const isPointWithinRange = (point, range) => {
  const isEqualOrAfterStart = moment(point.EndDateUtc).isSameOrAfter(range.start, 'month');
  const isEqualOrBeforeEnd = moment(point.EndDateUtc).isSameOrBefore(range.end, 'month');
  return isEqualOrAfterStart && isEqualOrBeforeEnd;
};

const rangeCount = (reportData, range) => {
  const endPoints = getSelectionEndPoints(reportData, range);
  if (endPoints.length > 1) {
    return Math.abs(endPoints[1].index - endPoints[0].index);
  }

  return 0;
};

const createSeriesSet = (reportData, range, options) => {
  if (!reportData.length) return [];
  const { seriesName, lineColor, fillColor, yAxisRange } = options;

  const fullLengthLineSeries = {
    name: seriesName,
    type: 'spline',
    data: reportData.map(point => point.change),
    color: lineColor,
    showInLegend: true,
    zIndex: 1
  };

  let transparentSeries = [
    {
      name: seriesName,
      type: 'areaspline',
      data: reportData.map((point, index) => {
        if (index && index < reportData.length - 1) {
          const isInRange = isPointWithinRange(point, range);
          const isPrevInRange = isPointWithinRange(reportData[index - 1], range);
          const isNextInRange = isPointWithinRange(reportData[index + 1], range);

          if (isInRange && !isNextInRange) return yAxisRange.max; //start of second unselected area
          if (isInRange && isPrevInRange) return null; // end of first unselected area
        }
        return yAxisRange.max;
      }),
      color: 'rgba(255, 255, 255, 0.5)',
      threshold: yAxisRange.min,
      showInLegend: false,
      zIndex: 3
    }
  ];

  if (rangeCount(reportData, range) === 1) {
    //Create two series when the range is only 1 month otherwise the transparent series will cover the 1 month range
    const firstSeriesData = [];
    const secondSeriesData = [];
    let currentSeries = 1;
    const addToFirstSeries = () => {
      firstSeriesData.push(yAxisRange.max);
      secondSeriesData.push(null);
    };
    const addToSecondSeries = () => {
      firstSeriesData.push(null);
      secondSeriesData.push(yAxisRange.max);
    };

    reportData.forEach((point, index) => {
      const isInRange = isPointWithinRange(point, range);
      const isPrevInRange = !index ? false : isPointWithinRange(reportData[index - 1], range);

      if (isInRange && isPrevInRange) currentSeries += 1;
      return currentSeries === 1 ? addToFirstSeries() : addToSecondSeries();
    });

    transparentSeries = [
      {
        ...transparentSeries[0],
        data: firstSeriesData
      },
      {
        ...transparentSeries[0],
        data: secondSeriesData
      }
    ];
  }

  const areaSeries = {
    name: seriesName,
    type: 'areaspline',
    data: reportData.map(point => (isPointWithinRange(point, range) ? point.change : null)),
    lineColor: 'rgba(0, 0, 0, 0)',
    fillColor: fillColor,
    threshold: yAxisRange.threshold,
    showInLegend: false
  };

  return [fullLengthLineSeries, ...transparentSeries, areaSeries];
};

const createDottedLineSeries = reportData => {
  return {
    name: 'dotted line',
    type: 'line',
    dashStyle: 'Dot',
    lineWidth: 2,
    data: reportData.map(point => 0),
    showInLegend: false,
    marker: { enabled: false },
    color: '#80807f',
    states: { hover: { enabled: false, marker: { enabled: false } } },
    enableMouseTracking: false,
    zIndex: 7
  };
};

const createDraggableSeries = (reportData, range, yAxisRange) => {
  const selectionEndPoints = getSelectionEndPoints(reportData, range);

  const draggableSeries = {
    name: 'draggable series',
    type: 'line',
    draggableX: true,
    dragMaxX: 24,
    dragMinX: 0,
    data: reportData.map(point => {
      if (point === selectionEndPoints[0] || point === selectionEndPoints[1]) return yAxisRange.min;
      return null;
    }),
    color: '#c0c0bf',
    showInLegend: false,
    connectNulls: true,
    lineWidth: 4,
    marker: {
      fillColor: '#FFFFFF',
      lineColor: '#606060',
      lineWidth: 2,
      radius: 5,
      symbol: 'circle'
    },
    cursor: 'ew-resize',
    zIndex: 6
  };
  return draggableSeries;
};

const calcHighChartsData = (reportData, nationalReportData, workLocation, range, animate = true) => {
  const localSeriesName = workLocation;
  if (!localSeriesName) {
    return;
  }
  const yAxisRange = calcYAxisRange(reportData.concat(nationalReportData));

  const localSeriesOptions = {
    seriesName: localSeriesName,
    lineColor: 'rgb(45, 103, 185)',
    fillColor: 'rgba(45, 103, 185, 0.25)',
    yAxisRange
  };
  const localSeriesSet = createSeriesSet(reportData, range, localSeriesOptions);

  const nationalSeriesOptions = {
    seriesName: 'National',
    lineColor: 'rgba(132, 171, 225, 0.6)',
    fillColor: 'rgba(132, 171, 225, 0.15)',
    yAxisRange
  };
  const nationalSeriesSet = createSeriesSet(nationalReportData, range, nationalSeriesOptions);
  const dottedLineSeries = createDottedLineSeries(reportData);
  const draggableLineSeries = createDraggableSeries(reportData, range, yAxisRange);

  const allSeries = [].concat(localSeriesSet, nationalSeriesSet, dottedLineSeries, draggableLineSeries);
  const categories = reportData.map(item => item.datePretty);

  return {
    // eslint-disable-line
    chart: {
      type: 'areaspline',
      spacingBottom: 0,
      height: 180,
      events: {}
    },
    title: {
      text: null
    },
    legend: {
      layout: 'horizontal',
      align: 'center',
      verticalAlign: 'top',
      squareSymbol: false,
      symbolHeight: 3,
      symbolWidth: 9
    },
    xAxis: {
      categories: categories,
      labels: {
        step: 4
      }
    },
    yAxis: {
      title: {
        text: null
      },
      labels: {},
      gridLineWidth: 0,
      min: yAxisRange.min,
      max: yAxisRange.max,
      maxPadding: 0
    },
    tooltip: {
      borderWidth: 5,
      borderColor: '#2d67b9',
      shadow: false,
      backgroundColor: '#2d67b9',
      borderRadius: 3,
      useHTML: true,
      style: {
        color: 'white'
      },
      snap: 0
    },
    plotOptions: {
      series: {
        stickyTracking: false,
        animation: animate
      },
      areaspline: {
        marker: {
          enabled: false
        }
      },
      spline: {
        marker: {
          enabled: false
        }
      },
      line: { point: { events: {} } }
    },
    credits: {
      enabled: false
    },
    series: allSeries
  };
};

const getNewRangeFromButtonId = (buttonId, reportData) => {
  const endMoment = moment(reportData[reportData.length - 1].EndDateUtc);
  const end = endMoment.toJSON();

  const getStart = {
    Last3mos: () => endMoment.subtract(3, 'months'),
    Last6mos: () => endMoment.subtract(6, 'months'),
    Last1yr: () => endMoment.subtract(12, 'months'),
    Last2yrs: () => endMoment.subtract(24, 'months')
  };
  return {
    start: getStart[buttonId]().toJSON(),
    end
  };
};

const getRangeFromSliderChange = (fromCategoryIndex, toCategoryIndex, salaryTrendState) => {
  const { range: currRange, reportData } = salaryTrendState;

  if (toCategoryIndex < 0) toCategoryIndex = 0; // eslint-disable-line
  if (toCategoryIndex >= reportData.length) toCategoryIndex = reportData.length - 1; // eslint-disable-line

  const endPoints = getSelectionEndPoints(reportData, currRange);
  const newRange = { ...currRange };
  const newEndPoint = reportData[toCategoryIndex];

  let willNewEndPointsEqual = false;
  endPoints.forEach((point, idx) => {
    if (point.index === fromCategoryIndex) {
      newRange[idx === 0 ? 'start' : 'end'] = moment(newEndPoint.EndDateUtc).toJSON();
    } else {
      willNewEndPointsEqual = point.index === newEndPoint.index;
    }
  });

  if (willNewEndPointsEqual) return { ...currRange };

  if (moment(newRange.start).isAfter(moment(newRange.end))) {
    return { start: newRange.end, end: newRange.start };
  }
  return newRange;
};

export const trendRangeButtonClick = event => {
  return {
    type: SALARY_TREND_RANGE_BUTTON_CLICK,
    buttonId: event.currentTarget.id
  };
};

export const setDateRange = event => {
  const toIndex = Math.round(event.x);
  const fromIndex = event.dragStart.x;

  return {
    type: CHANGE_DATE_RANGE,
    toIndex,
    fromIndex
  };
};
