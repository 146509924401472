import axios from 'axios';

import { DefaultClient as FeatureFlagClient } from 'ps-components/src/lib/FeatureFlag/Client';
import {
  UPDATE_PLATFORM_FEATURES,
  UPDATE_PLATFORM_FEATURES_SUCCESS,
  UPDATE_PLATFORM_FEATURES_FAIL,
  UPDATE_LAUNCH_DARKLY_FEATURE,
  UPDATE_LAUNCH_DARKLY_FEATURE_SUCCESS,
  UPDATE_LAUNCH_DARKLY_FEATURE_FAIL,
  GET_FEATURE_FLAGS,
  GET_FEATURE_FLAGS_SUCCESS,
  GET_FEATURE_FLAGS_FAIL
} from './FeatureFlagReducer';

export const loadFeatureFlags = async (dispatch, masterAccountId) => {
  // Make sure current user is identified in LD
  try {
    await FeatureFlagClient.LaunchDarkly.identify();
  } catch (e) {
    console.error('Failed to identify current user in LaunchDarkly');
  }

  // Get feature flags from feature flag service
  try {
    dispatch({ type: GET_FEATURE_FLAGS });
    const featureFlagsRes = await FeatureFlagClient.Flags.get(masterAccountId);
    dispatch({ type: GET_FEATURE_FLAGS_SUCCESS, featureFlags: featureFlagsRes.data });

    dispatch({
      type: 'UPDATE_APP_DATA',
      copyFromWindow: 'featureFlag',
      appLoadFinalProps: true
    });
  } catch (e) {
    console.error('Failed to get feature flag:', e);
    dispatch({ type: GET_FEATURE_FLAGS_FAIL, error: e });
  }
};

// singleton so only one set of custom rules per app so far
let customFeatureRules;
export function applyCustomFeatureRules(rules) {
  customFeatureRules = rules;

  return true;
}

export function isFeatureEnabled(features, featureName) {
  // run custom app-specific rules first
  if (customFeatureRules) {
    const customFeatureResult = customFeatureRules(features, featureName);
    if (customFeatureResult) {
      return customFeatureResult;
    }
  }

  // search existing rules
  if (features && (features[featureName] || (Array.isArray(features) && features.indexOf(featureName) > -1))) {
    return { isEnabled: true };
  }

  // final default state is the feature is off
  return { isEnabled: false };
}

export function updatePlatformFeatureFlags(_masterAccountId, featureFlagName, featureFlagValue) {
  return async dispatch => {
    const updateFlags = [
      {
        name: featureFlagName,
        value: featureFlagValue
      }
    ];

    // When updating teamFlag, also update jobCollabFlag
    if (featureFlagName === 'teamFlag') {
      updateFlags.push({
        name: 'jobCollabFlag',
        value: featureFlagValue
      });
    }

    try {
      dispatch({ type: UPDATE_PLATFORM_FEATURES });
      await Promise.all(updateFlags.map(flag => FeatureFlagClient.Flags.createOrUpdate(flag)));
      dispatch({ type: UPDATE_PLATFORM_FEATURES_SUCCESS, flags: updateFlags });
    } catch (e) {
      console.error('Failed to create or update feature flag:', e);
      dispatch({ type: UPDATE_PLATFORM_FEATURES_FAIL });
    }
  };
}

export function updateLaunchDarklyFeatureFlagRuleForMasterAccount(
  launchDarklyAPIUrl,
  launchDarklyAPIToken,
  projectKey,
  environmentKey,
  flagKey,
  enableFlag,
  masterAccountId
) {
  return dispatch => {
    // First we need to get the feature flag and iterate through it's rules
    // If the rule exists then we either need to remove the clause value for the master account id if it's off
    // or add a clause value if the rule is on

    // If the feature flag does not have a rule and the flag is on, create the new rule
    getLaunchDarklyFlag(launchDarklyAPIUrl, launchDarklyAPIToken, projectKey, environmentKey, flagKey).then(result => {
      if (result && result.data && result.data.key === flagKey && result.data.environments) {
        let ruleIndex = undefined;
        let lastRuleIndex = -1;
        let lastValueIndex = -1;
        let foundMasterAccountIndex = undefined;
        const environment = result.data.environments[environmentKey];
        if (environment.rules && environment.rules.length > 0) {
          lastRuleIndex = environment.rules.length - 1;
          environment.rules.forEach((rule, index) => {
            rule.clauses.forEach(clause => {
              if (clause.attribute === 'masterAccountId' && clause.op === 'startsWith') {
                lastValueIndex = clause.values.length - 1;
                clause.values.forEach((value, valIndex) => {
                  if (value === masterAccountId) {
                    ruleIndex = index;
                    foundMasterAccountIndex = valIndex;
                  }
                });
              }
            });
          });
        }

        let flagUpdatePatch = undefined;

        if (enableFlag && ruleIndex === undefined) {
          flagUpdatePatch = {
            op: 'add',
            path: `/environments/${environmentKey}/rules/${lastRuleIndex + 1}`,
            value: {
              _id: null,
              variation: 0,
              clauses: [
                {
                  negate: false,
                  attribute: 'masterAccountId',
                  op: 'startsWith',
                  values: [masterAccountId]
                }
              ]
            }
          };
        } else if (ruleIndex !== undefined) {
          // if we're removing a value from the clause
          // simply perform a remove up with the index of the value
          // otherwise use last index + 1 to add a new value
          const op = enableFlag ? 'add' : 'remove';
          let valueIndex = lastValueIndex + 1;
          if (!enableFlag && foundMasterAccountIndex !== undefined) {
            valueIndex = foundMasterAccountIndex;
          }
          flagUpdatePatch = {
            op,
            path: `/environments/${environmentKey}/rules/${ruleIndex}/clauses/0/values/${valueIndex}`
          };
          if (enableFlag) {
            flagUpdatePatch.value = masterAccountId;
          }
        }

        if (flagUpdatePatch) {
          const updateData = [flagUpdatePatch];

          const options = {
            method: 'PATCH',
            headers: { Authorization: launchDarklyAPIToken },
            data: updateData,
            crossdomain: true,
            url: `${launchDarklyAPIUrl}flags/${projectKey}/${flagKey}`
          };
          dispatch({ type: UPDATE_LAUNCH_DARKLY_FEATURE });
          axios(options)
            .then(() => {
              dispatch({ type: UPDATE_LAUNCH_DARKLY_FEATURE_SUCCESS });
            })
            .catch(err => {
              dispatch({ type: UPDATE_LAUNCH_DARKLY_FEATURE_FAIL, error: err });
            });
        }
      }
    });
  };
}

function getLaunchDarklyFlag(launchDarklyAPIUrl, launchDarklyAPIToken, projectKey, environmentKey, featureFlag) {
  return axios.get(`${launchDarklyAPIUrl}flags/${projectKey}/${featureFlag}?env=${environmentKey}`, {
    crossdomain: true,
    headers: {
      Authorization: launchDarklyAPIToken
    }
  });
}
