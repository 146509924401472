import React, { Component } from 'react';
import { connect } from 'react-redux';
import uuid from 'uuid';
import { ContactUsForm } from '@payscale/payscale-shared-ui-v3/lib/ContactUsForm';
import './ContactSupportFormWrapper.scss';
import { getBrowser } from '../../utils';
import apiClient from '../../../../lib/apiClient';
import { initContactSupport } from '../../actions/contactSupport';

interface ContactSupportFormWrapperProps {
  hideSlideout: () => void;
  slideout: Slideout;
  launchDarklyFeatureFlags: LaunchDarklyFlag;
  initContactSupport: () => void;
  session: SessionState;
  inputCategory: string;
  inputModule: string;
  inputFeature: string;
  inputFunction: string;
}

interface AppState {
  hideSlideout: () => void;
  initContactSupport: () => void;
  support: SupportState;
  launchDarklyFeatureFlags: LaunchDarklyFlag;
  session: SessionState;
}

const wrapped = connect(
  (state: AppState) => ({
    inputModule: state.support.inputModule,
    inputFeature: state.support.inputFeature,
    inputFunction: state.support.inputFunction,
    inputCategory: state.support.inputCategory,
    session: state.session
  }),
  {
    initContactSupport
  }
);

class ContactSupportFormWrapper extends Component<ContactSupportFormWrapperProps> {
  constructor(props: ContactSupportFormWrapperProps) {
    super(props);
    this.close = this.close.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.getSuggestedArticles = this.getSuggestedArticles.bind(this);
    this.goToArticle = this.goToArticle.bind(this);
    this.props.initContactSupport();
  }

  UNSAFE_componentWillReceiveProps(nextProps: ContactSupportFormWrapperProps): void {
    //  note: This is a hack since the slideout content is never unmounted we need to see if the
    //  slideout was closed and will now be opened instead
    if (this.props.slideout && !this.props.slideout.open && nextProps.slideout && nextProps.slideout.open) {
      this.props.initContactSupport();
    }
  }

  close(): void {
    this.props.hideSlideout();
  }

  async getSuggestedArticles(): Promise<void> {
     // empty call back function since we don't care about case deflection now
  }

  goToArticle(): void {
     // empty call back function since we don't care about case deflection now
  }


  async submitForm(formData: MpContactFormData): Promise<MpTicketSubmissionResult> {
    //  nOTE: IE11 and Edge do NOT support the File Constructor which would be cleaner
    //  since that's the case, we need to send a blob of data instead of a File object
    const uploadFiles: Blob[] = [];

    if (!formData.attachments) {
      formData.attachments = [];
    }

    if (formData.attachments) {
      formData.attachments.forEach(attachment => {
        const blob: Blob = new Blob([attachment], { type: attachment.type });
        //  this is required or the blob won't be considered a file when form is submitted
        blob.lastModifiedDate = new Date();
        uploadFiles.push(blob);
      });
    }

    uploadFiles.forEach(f => (f.uuid = uuid.v4()));

    let result: MpTicketSubmissionResult;
    try {
      const multiPartForm: FormData = new FormData();
      multiPartForm.append('ticket_type', this.props.inputCategory ?? 'Bug Report');
      multiPartForm.append('module_type', this.props.inputModule ?? '');
      multiPartForm.append('component_type', this.props.inputFeature?? '');
      multiPartForm.append('function_type', this.props.inputFunction ?? '');

      multiPartForm.append('summary', formData.subject ?? '');
      multiPartForm.append('description', formData.description ?? '');
      multiPartForm.append('priority', formData.priority ?? '');

      for (let i = 0; i < uploadFiles.length; i++) {
        multiPartForm.append('theFile', uploadFiles[i], formData.attachments[i]?.name ?? '');
      }

      multiPartForm.append('csrf_timestamp', this.props.session.csrfPair.csrf_timestamp.toString());
      multiPartForm.append('csrf_token', this.props.session.csrfPair.csrf_token);

      multiPartForm.append('system', formData.productName ?? 'MarketPay');
      multiPartForm.append('browser', getBrowser());
      multiPartForm.append('mp_url', window.location.href);

      result = await apiClient.apiPost('/api/support/ticketing/createTicket', multiPartForm);

      if (result.data?.success) {
        return result.data;
      } else {
        console.error('SUPPORT_TICKET_SUBMIT_FAILURE', result.data?.message);
        throw 'SUPPORT_TICKET_SUBMIT_FAILURE';
      }
    } catch (err) {
      console.error('caught SUPPORT_TICKET_SUBMIT_UNEXPECTED_ERROR', err);
      throw 'SUPPORT_TICKET_SUBMIT_UNEXPECTED_ERROR';
    }
  }

  render(): React.ReactNode {
    const pageSource: string = window.location.pathname.split('/')[6] != null ?  window.location.pathname.split('/')[5] : 'homepage';
    const isCaseDeflectionEnabled: boolean = this.props.launchDarklyFeatureFlags['case-deflection'];
    return (
     <>
      <span className="contact-support-form-wrapper__close icon-cancel" onClick={this.close} />
      <ContactUsForm
        productName="MarketPay"
        pageSource={pageSource}
        onClose={this.close}
        onSubmitFn={this.submitForm}
        showCaseDeflection={isCaseDeflectionEnabled}
        goToArticle={this.goToArticle}
        articles={[]}
        searchTermCallBack={this.getSuggestedArticles}
      />
      </>
    );
  }
}

export default wrapped(ContactSupportFormWrapper);
