import { bindActionCreators } from 'redux';
import { calculateFte } from './calculateFte';

String.prototype.trimLeft = function() {
  return this.replace(/^\s+/, '');
};

// Generic function for validating emails from the client side. When
// changing the logic for this function, make sure you run the unit tests
// for the corresponding file and keep those passing!
export function validateEmail(email) {
  if (!email || email === null) {
    return false;
  }

  if (email.indexOf('@') !== email.lastIndexOf('@')) {
    // Require an @ symbol somewhere, and only one.
    return false;
  }

  if (email.indexOf('@') === -1) {
    return false;
  }

  if (email.indexOf('@') > 65) {
    // Too many characters before the @ symbol
    return false;
  }

  if (email.indexOf(`"`) > -1) {
    // Quotes not valid in email
    return false;
  }

  return true;
}

export function splitWorkLocation(workLocation) {
  let country = '';
  let state = '';
  let city = '';
  if (workLocation && workLocation !== '' && workLocation.split(';').length > 1 && workLocation.split(',').length > 1) {
    country = workLocation.split(';', 2)[1].trimLeft();
    state = workLocation
      .split(';', 2)[0]
      .split(',', 2)[1]
      .trimLeft();
    city = workLocation.split(';', 2)[0].split(',', 1)[0];
  } else if (workLocation && workLocation != '' && workLocation.split(';').length > 1) {
    country = workLocation.split(';', 2)[1].trimLeft();
    city = workLocation.split(';', 1)[0].split(',', 1)[0];
  } else if (workLocation && workLocation != '') {
    country = workLocation;
  } else if (window.app.default_currency === 'CAD' || workLocation === 'Canada (Natl)') {
    country = 'Canada';
  } else if (window.app.default_currency === 'GBP' || workLocation === 'United Kingdom (Natl)') {
    country = 'United Kingdom';
  } else {
    country = 'United States';
  }
  return {
    country: country,
    state: state,
    city: city
  };
}

export function formatCurrency(value, noDecimals, excludeDollarSign) {
  const parsedValue = parseFloat(value);
  if (value - parsedValue + 1 >= 0) {
    // jking: test for isNan????

    // add commas as thousands separators
    let formatted = `$${parsedValue.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')}`;
    if (excludeDollarSign) {
      formatted = formatted.slice(1);
    }
    return noDecimals ? formatted.slice(0, -3) : formatted;
  }

  return value;
}

export function roundToPrecision(value, numDecimalPlaces) {
  if (!value || numDecimalPlaces === null || numDecimalPlaces < 0) {
    return value;
  }

  const factor = Math.pow(10, numDecimalPlaces);
  return Math.round(value * factor) / factor;
}

export function getDesiredDecimalPlaces(app, monetaryDivisor, currencyMatch, intl) {
  let numDecimalPlaces = 0; // default to annual/thousands

  // Round the value if monetary rounding is not set or is equal to 0, before other manipulations
  if (app) {
    if (app.monetaryDivisor === 2080 || monetaryDivisor === 2080) {
      // If we have a currency match, use the rounding for the currency, otherwise default to two

      return currencyMatch && currencyMatch.hourly_decimals ? currencyMatch.hourly_decimals : 2;
    }

    // Or if this is annual, round to nearest dollar
    if (
      app.monetary_display === 'thousands' ||
      app.monetary_display === 'annual' ||
      app.monetaryDivisor === 1 ||
      (intl && intl.useThousands)
    ) {
      return 0;
    }

    // Least priority, default to app.field_formatting
    if (app && app.field_formatting && app.field_formatting.monetary_rounding) {
      return app.field_formatting.monetary_rounding;
    }
  }

  // If we have no extra formatting info information, return the plain rounded value
  return 0;
}

/**
 * Abbreviate a number by rounding an using symbols
 * @param {number} value number to abbreviate
 * @param {number} decimals number of decimal places to round to
 * @param {boolean} keepZeroDecimal keep all decimals even if they're zero?
 */
export function abbreviateLargeNumber(value, decimals, keepZeroDecimal) {
  if (typeof value !== 'number') {
    if (typeof value == 'string') {
      value = parseInt(value);
    } else {
      return '';
    }
  }

  //default to 0
  decimals = typeof decimals !== 'undefined' ? decimals : 0;

  let output = value;

  let useEndingValue = '';
  if (value >= 1000) {
    value /= 1000;
    useEndingValue = 'k';
  }
  if (value >= 1000) {
    value /= 1000;
    useEndingValue = 'm';
  }
  if (value >= 1000) {
    value /= 1000;
    useEndingValue = 'b';
  }
  if (value >= 1000) {
    value /= 1000;
    useEndingValue = 't';
  }

  value = value.toFixed(decimals);
  if (keepZeroDecimal === false && parseFloat(value) % 1 == 0) {
    value = value.split('.')[0];
  }

  return value + useEndingValue;
}

export function formatLargeCurrencyBySize(value, threshold) {
  // value takes the raw full number
  // threshold takes in the total number of digits, ignoring commas, periods, and ending values
  // ***For values less than 1000, it will always return the full value because there is no way to truncate
  // ***without losing precision. If this becomes necessary, add another parameter to force it via .toFixed
  // ex formatLargeCurrencyBySize(12345, 4) will return $12.35k))

  if (typeof value !== 'number') {
    if (typeof value == 'string') {
      value = parseInt(value);
    } else {
      return '';
    }
  }

  if (!threshold) {
    threshold = 0;
  }
  if (value < 1000) {
    threshold = 3;
  }

  let output = value;

  let useEndingValue = '';
  if (value >= 1000) {
    value /= 1000;
    useEndingValue = 'k';
  }
  if (value >= 1000) {
    value /= 1000;
    useEndingValue = 'm';
  }
  if (value >= 1000) {
    value /= 1000;
    useEndingValue = 'b';
  }
  if (value >= 1000) {
    value /= 1000;
    useEndingValue = 't';
  }

  let len = value.toString().split('.')[0].length;
  let decimals = threshold - len;
  if (decimals < 0) {
    decimals = 0;
  }

  output = `${value.toFixed(decimals)}${useEndingValue}`;

  return output;
}

export function formatCurrencyIntl(value, intl, app, metadata) {
  // available options to pass into intl
  // intl: {
  //   currency:            specific currency to use, overriding default
  //   country_code:
  //   preferOriginalValue,
  //   preferHourlyRate,
  //   removeDecimals:      whether to remove any fractional component
  //   useThousands:        whether to round/display as thousands
  //   useHourly:           force display to two decimal digits
  //   isChart:             whether value is to be displayed in a chart
  //  }

  // an object can be passed into metadata for additional data.
  // currently used for passing in fte value for individual employee {fte: 1, employeeWorkHours: 40}

  if (typeof value !== 'number') {
    return value;
  }

  const currentCurrency = (intl && intl.currency) || app.default_currency;
  // Transform value based on hourly, fte, etc
  const preferOriginalValue = intl && intl.preferOriginalValue;
  const preferHourlyRate = intl && intl.preferHourlyRate;
  const monetaryDivisor = getMonetaryDivisor(app.monetary_display, intl);
  if (!preferHourlyRate && !preferOriginalValue) {
    // If we don't prefer hourly rate, apply divisor
    let fte = 1.0;

    if (metadata && metadata.employeeWorkHours) {
      fte = calculateFte(metadata.employeeWorkHours, currentCurrency, intl.country_code, app);
    }

    if (metadata && metadata.fte) {
      // an object can be passed into metadata for additional data.
      // currently used for passing in fte value for individual employee {fte: 1}
      fte = metadata.fte;
    }

    value /= monetaryDivisor * fte;
  }

  // Figure out number of decimal places to display based on currency and/or app settings
  const currencies = app.currencies ? app.currencies : window.app.currencies;
  const currencyMatch = currencies.find(currency => currency.currency === currentCurrency);
  let decimalPlaces = getDesiredDecimalPlaces(app, monetaryDivisor, currencyMatch, intl);

  if (!intl) {
    return formatCurrency(roundToPrecision(value, 0));
  }

  // set a max of two decimal places
  decimalPlaces = intl.removeDecimals ? 0 : Math.min(decimalPlaces, 2);

  let intlVal;

  // useThousands is currently used both in the context of a large value being passed in for division, like 50000
  // being formatted as $50.0K, as well as a small value just for formatting, like 50 => $50.0K. This logic should be
  // split up to reduce complexity when possible
  if (intl.useThousands) {
    let useEndingValue = 'K';
    if (value >= 1000) {
      value /= 1000;
    }
    if (value >= 1000) {
      value /= 1000;
      useEndingValue = 'M';
    }
    if (value >= 1000) {
      value /= 1000;
      useEndingValue = 'B';
    }
    value = intl.removeDecimals ? Math.round(value) : value.toFixed(1);
    intlVal = `${value}${useEndingValue}`;
  } else if (intl.useHourly) {
    // commas for thousands and 2 digits for hourly
    intlVal = roundToPrecision(value, decimalPlaces).toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    });
  } else {
    intlVal = roundToPrecision(value, decimalPlaces).toLocaleString();
  }

  // Skip the match based returns if match fails
  if (!currencyMatch) {
    return `${intl.currency} ${intlVal}`;
  }

  // no need to show currency symbol in current currency or for any charts
  if (currentCurrency === app.default_currency || intl.isChart || currencyMatch.symbol) {
    return `${currencyMatch.symbol}${intlVal}`;
  }

  return `${currencyMatch.currency_pretty} ${intlVal}`;
}

export function getMonetaryDivisor(monetaryDisplay, intl) {
  if (intl && intl.useHourly === true) {
    // if passed in, override monetary display
    return 2080;
  }

  let monetaryDivisor = 1;
  if (monetaryDisplay) {
    switch (monetaryDisplay) {
      case 'thousands':
        monetaryDivisor = 1000;
        break;
      case 'annual':
        // divide by 1, so do nothing
        break;
      case 'monthly':
        monetaryDivisor = 12;
        break;
      case 'hourly':
        monetaryDivisor = 2080;
        break;
      case 'millions':
        monetaryDivisor = 1000000;
        break;
      default:
        break;
    }
  }

  return monetaryDivisor;
}

// will be deprecated. Do not use.
export function mapDispatchToActions(actions) {
  function wrapActions(actionsObj, dispatch, getState) {
    const newActions = {};
    Object.keys(actionsObj).forEach(actionName => {
      newActions[actionName] = (...args) => {
        const result = actionsObj[actionName](...args);
        if (typeof result === 'function') {
          result(dispatch, getState);
        } else {
          dispatch(result);
        }
      };
    });
    return newActions;
  }

  return function getActions() {
    return (dispatch, getState) => {
      return wrapActions(actions, dispatch, getState);
    };
  };
}

// use this one. Not 'mapDispatchToActions'.
export const mapDispatchToProps = modules => {
  return dispatch => {
    const boundActions = {};
    Object.keys(modules).forEach(name => {
      const actionCreators = modules[name];
      if (typeof actionCreators === 'string') return;
      boundActions[name] = bindActionCreators(actionCreators, dispatch);
    });
    return boundActions;
  };
};

export function prependTypeConstants(list, name) {
  const types = {};
  list.forEach(type => {
    types[type] = `${name}_${type}`;
    if (type.slice(0, 3) === 'API') {
      const modType = type.slice(4);
      types[modType] = `${name}_${modType}`;
      types[modType + '_SUCCESS'] = `${name}_${modType}_SUCCESS`;
      types[modType + '_FAIL'] = `${name}_${modType}_FAIL`;
      //just as a convenience
      types[type] = [types[modType], types[modType + '_SUCCESS'], types[modType + '_FAIL']];
    }
  });
  return types;
}

export function getChartColorSchemeByScheme(scheme) {
  const colorsByScheme = {
    'Base/TCC': ['#22d6dd', '#a7eff1'],
    'TGP/TCOE': ['#2d67b9', '#afe0f9'],
    Grade: ['#22d6dd', '#f0ad4e']
  };

  if (!colorsByScheme[scheme]) {
    return [];
  }

  return colorsByScheme[scheme];
}

export function getChartColorByCurrencyScheme(currencyScheme, payDisplayType) {
  if (currencyScheme) {
    if (
      (currencyScheme === 'TGP/TCOE' && payDisplayType === 'Base Salary') ||
      (currencyScheme === 'TGP/TCOE' && payDisplayType === 'Total Guaranteed Pay')
    ) {
      return getChartColorSchemeByScheme('Base/TCC');
    } else if (
      (currencyScheme === 'TGP/TCOE' && payDisplayType === 'Total Cost of Employment') ||
      payDisplayType === 'TCC'
    ) {
      return getChartColorSchemeByScheme('TGP/TCOE');
    }
  }

  return getChartColorSchemeByScheme('Base/TCC');
}

/*
 Given state, which contains job info that has a country code, then find the currency/market field

 This is Base Salary and Total Cash for US and CA, and TGP/TCOE for many other countries.
 */
export function getCurrencySchemeName(currencies, countryCode) {
  let currencySchemeName = 'Base/TCC';
  let showMarketRateField = false;

  if (countryCode) {
    let currencySchemes = null;
    if (currencies && currencies.length > 0) {
      currencySchemes = currencies;

      let currencyScheme = currencySchemes.find(o => o.country_code === countryCode);
      if (currencyScheme && currencyScheme.currency_scheme === 'TGP/TCOE') {
        showMarketRateField = true;
        currencySchemeName = currencyScheme.currency_scheme;
      }
    }
  }

  return {
    currencySchemeName,
    showMarketRateField
  };
}

export const addNestedChildrenReducers = (parentReducer, childrenReducers) => {
  return (state, action) => {
    let newParentState = parentReducer(state, action);
    Object.keys(childrenReducers).forEach(key => {
      const newChildState = childrenReducers[key](state && state[key], action);
      if (newChildState !== newParentState[key]) {
        newParentState = { ...newParentState, [key]: newChildState };
      }
      // just in case there is a purposeful change that mutates state
      newParentState[key] = newChildState;
    });
    return newParentState;
  };
};

export function getDefaultMarketRateField(schema, marketRateField) {
  if (marketRateField && marketRateField.length > 0) {
    return marketRateField;
  }

  let marketRateFieldObj = [];
  if ((schema === 'TGP/TCOE' && !marketRateField) || schema === 'tgp') {
    marketRateFieldObj = ['totalGuaranteedPay', 'totalCostOfEmployment'];
  } else {
    marketRateFieldObj = ['baseSalary', 'totalCashPaid'];
  }

  return marketRateFieldObj;
}

export function getUniqueElements(array, valueFunction) {
  return [...new Set(array.map(valueFunction))];
}

export function getTotalProfilesAnalyzed(row) {
  let totalProfilesAnalyzed = 0;

  if (
    row &&
    row.payscale_job_info &&
    row.payscale_job_info.Reports &&
    row.payscale_job_info.Reports.payReport &&
    row.payscale_job_info.Reports.payReport.reports &&
    row.payscale_job_info.Reports.payReport.reports.totalProfilesAnalyzed
  ) {
    totalProfilesAnalyzed = row.payscale_job_info.Reports.payReport.reports.totalProfilesAnalyzed;
  }

  if (totalProfilesAnalyzed >= 1000) {
    totalProfilesAnalyzed /= 1000;
    totalProfilesAnalyzed = `${totalProfilesAnalyzed.toFixed(1)}K`;
  } else {
    totalProfilesAnalyzed = `${totalProfilesAnalyzed.toFixed(0)}`;
  }

  return totalProfilesAnalyzed;
}

export function getFullName(firstName, lastName, displayName) {
  if (firstName && lastName) {
    return `${firstName} ${lastName}`;
  } else if (lastName || firstName) {
    return lastName || firstName;
  } else if (displayName) {
    return displayName;
  } else {
    return '';
  }
}

const downloadUrlKeyWords = ['pretty_name', 'file_name', 'download_type', 'csrf_token', 'csrf_timestamp'];

const downloadType = {
  download_type: 'pdf'
};

export function buildUrl(baseUrl, ...dictionaries) {
  if (!dictionaries.length) {
    return baseUrl;
  }

  downloadUrlKeyWords.forEach((key, i) => {
    let value = '';

    dictionaries.forEach(dict => {
      if (!dict) {
        return;
      }

      const foundValue = dict[key];
      if (foundValue) {
        value = foundValue;
        return;
      }
    });

    baseUrl = `${baseUrl}${i ? '&' : '?'}${key}=${value}`;
  });

  return baseUrl;
}

export function redirectToPdf(val) {
  let downloadLocation = '/api/generic-utils/generic-download/downloadGeneric';
  if (window.app.hostname !== 'localhost') {
    downloadLocation = `/${window.app.subsite}${downloadLocation}`;
  }
  downloadLocation = buildUrl(downloadLocation, val, window.app, downloadType);

  if (!downloadLocation) {
    return;
  }

  window.location = downloadLocation;
}

export function getCompanyLogoPath(fileName, subsite) {
  if (fileName && fileName !== '' && subsite) {
    let basePath = '';
    if (window.location.hostname !== 'localhost') {
      basePath = `/${subsite}/app`;
    }

    return `${basePath}/logo/${fileName}`;
  }
}

export function isHourly(annualOrHourly) {
  if (annualOrHourly && annualOrHourly.toLowerCase().match('hourly')) {
    return true;
  }

  return false;
}

export function translateSurveyStatus(status) {
  if (status) {
    return 'Included';
  }
  return 'Excluded';
}

// The account actually needs team:pay activated *and* the feature flag set.  The only reason you’d have team:pay
// and not the feature flag set is if you’re trying to demo w/o T:P even if the demo account has one linked, or
// we are just turning it off for a customer as they didn’t renew but salesforce isn’t updated yet
export function hasTeamEnabled(masterAccountInfo, isTeamEnabled) {
  if (masterAccountInfo && masterAccountInfo.hasTeamPay === true && isTeamEnabled) {
    return true;
  }

  return false;
}

export function isAdmin(app) {
  if (app && app.user_rights) {
    return (
      app.user_rights.global_admin ||
      app.user_rights.group_admin ||
      app.user_rights.site_admin ||
      app.user_rights.super_admin
    );
  } else {
    return false;
  }
}

// Returns a value constrained to the minimum and maximum parameters
export function constrainValue(value, minimum, maximum) {
  let val = value;
  // If the value is a string, attempt to parse it
  // otherwise just pass back the value
  if (typeof value === 'string') {
    val = parseFloat(value);
    if (isNaN(val)) {
      return value;
    }
  } else if (typeof value !== 'number') {
    return value;
  }
  const constrainedValue = Math.min(Math.max(value, minimum), maximum);
  return constrainedValue;
}

export function setCookie(name, value, expirationDays, domain) {
  let d = new Date();
  d.setTime(d.getTime() + expirationDays * 24 * 60 * 60 * 1000);
  document.cookie = `${name}=${value}; expires=${d.toUTCString()}; path=/; domain=${domain}; Secure; SameSite=Strict;`;
}
