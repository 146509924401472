const initialState = {
  mergeJobMatchList: [],
  mergeJobSearchLoading: false,
  selectedJobToMerge: '',
  showJobMergeWarningModal: false,
  showJobMergeModal: false,
  showJobMergeErrorModal: false,
  mergeJobErrorMessage: '',
  mergingJobLoading: false
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case 'MERGE_JOB_SEARCH':
      return {
        ...state,
        mergeJobSearchLoading: true
      };
    case 'MERGE_JOB_SEARCH_SUCCESS':
      // remove already selected job from the list
      let jobList = action.result.data.slice(0);
      const jobIndex = jobList.findIndex(job => parseInt(job._id) === action.jobId);
      if (jobIndex > -1) {
        jobList.splice(jobIndex, 1);
      }

      // remove jobs with different currency
      const finalJobList = [];
      for (let i = 0; i < jobList.length; i++) {
        if (jobList[i]._source.currency_none === action.currency && !jobList[i]._source.no_job_code_default) {
          finalJobList.push(jobList[i]);
        }
      }

      return {
        ...state,
        mergeJobSearchLoading: false,
        loaded: true,
        mergeJobMatchList: finalJobList
      };
    case 'MERGE_JOB_SEARCH_FAIL':
      return {
        ...state,
        mergeJobSearchLoading: false,
        loaded: true,
        error: action
      };
    case 'SELECTED_JOB_TO_MERGE':
      return {
        ...state,
        selectedJobToMerge: action.selectedJobToMerge
      };
    case 'OPEN_JOB_MERGE_WARNING_MODAL': {
      return {
        ...state,
        showJobMergeWarningModal: true
      };
    }
    case 'CLOSE_JOB_MERGE_WARNING_MODAL': {
      return {
        ...state,
        showJobMergeWarningModal: false
      };
    }
    case 'OPEN_JOB_MERGE_MODAL': {
      return {
        ...state,
        showJobMergeModal: true
      };
    }
    case 'CLOSE_JOB_MERGE_MODAL': {
      return {
        ...state,
        showJobMergeModal: false
      };
    }
    case 'JOB_MERGE': {
      return {
        ...state,
        loading: true,
        mergingJobLoading: true
      };
    }
    case 'JOB_MERGE_SUCCESS': {
      return {
        ...state,
        loading: false,
        showJobMergeModal: action.result.success ? false : true,
        showJobMergeWarningModal: false,
        showJobMergeErrorModal: action.result.success ? false : true,
        mergeJobErrorMessage: action.result.reason ? action.result.reason : 'Job Merge Failed',
        mergingJobLoading: false
      };
    }
    case 'JOB_MERGE_FAILURE': {
      return {
        ...state,
        loading: false,
        error: action.result.reason,
        mergeJobErrorMessage: action.result.reason ? action.result.reason : 'Job Merge Failed',
        showJobMergeErrorModal: true,
        mergingJobLoading: false
      };
    }
    case 'CLOSE_JOB_MERGE_ERROR_MODAL': {
      return {
        ...state,
        showJobMergeErrorModal: false
      };
    }
    default:
      return { ...state };
  }
}

export function mergeJobSearch(params) {
  return dispatch => {
    dispatch({
      types: ['MERGE_JOB_SEARCH', 'MERGE_JOB_SEARCH_SUCCESS', 'MERGE_JOB_SEARCH_FAIL'],
      promise: client => client.get('/api/elasticsearch/search/queryStringJobs', params),
      jobId: params.jobId,
      currency: params.currency
    })
      .then(() => {
        // clear previously selected job
        dispatch(selectJobToMerge(''));
      })
      .catch(err => {
        console.error('Error in merge job search', err);
      });
  };
}

export function selectJobToMerge(params) {
  return {
    type: 'SELECTED_JOB_TO_MERGE',
    selectedJobToMerge: params
  };
}

export function openJobMergeWarningModal() {
  return {
    type: 'OPEN_JOB_MERGE_WARNING_MODAL'
  };
}

export function closeJobMergeWarningModal() {
  return {
    type: 'CLOSE_JOB_MERGE_WARNING_MODAL'
  };
}

export function closeJobMergeErrorModal() {
  return {
    type: 'CLOSE_JOB_MERGE_ERROR_MODAL'
  };
}

export function openJobMergeModal() {
  return dispatch => {
    dispatch({ type: 'OPEN_JOB_MERGE_MODAL' });
    dispatch({ type: 'HIDE_SCROLL_BAR' });
  };
}

export function closeJobMergeModal() {
  return dispatch => {
    dispatch({ type: 'CLOSE_JOB_MERGE_MODAL' });
    dispatch({ type: 'SHOW_SCROLL_BAR' });
  };
}

export function mergeJobFn(sourceJobId) {
  return (dispatch, getState) => {
    const destinationJobId = parseInt(getState().mergeJob.selectedJobToMerge);
    dispatch({
      types: ['JOB_MERGE', 'JOB_MERGE_SUCCESS', 'JOB_MERGE_FAILURE'],
      promise: client =>
        client.post('/api/hris/jobs/merge', {
          params: { source_org_job_id: sourceJobId, destination_org_job_id: destinationJobId }
        })
    })
      .then(function(result) {
        if (result.success) {
          window.location = './' + destinationJobId;
        }
      })
      .catch(err => {
        console.error('Error merging job', err);
      });
  };
}
