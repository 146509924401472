import { string, number, func, shape, arrayOf, bool, oneOf } from 'prop-types';

export const ParticipationMatch = shape({
  matchId: number.isRequired,
  matchType: string,
  notes: string,
  orgJobCode: string.isRequired,
  orgJobCodeKey: string.isRequired,
  orgJobDescription: string,
  orgJobId: number,
  orgJobTitle: string.isRequired,
  surveyCode: string,
  surveyJobCode: string,
  surveyJobTitle: string,
  surveyJobDescription: string,
  exclude: bool
});

export const ParticipationMatchOrgJob = shape({
  orgJobCode: string.isRequired,
  orgJobCodeKey: string.isRequired,
  orgJobTitle: string.isRequired,
  matches: arrayOf(ParticipationMatch)
});
