import Raven from 'raven-js';
import createRavenMiddleware from 'raven-for-redux';
import { getSentryApiKey } from '../../Utils/sentryApiKey';
import { jsonSize } from '../../Utils/jsonUtils';

const MAX_SIZE = 150 * 1024;

function truncateBreadcrumbs(data) {
  if (jsonSize(data) > MAX_SIZE && data.breadcrumbs && data.breadcrumbs.values && data.breadcrumbs.values.length) {
    data.breadcrumbs.values = data.breadcrumbs.values.slice(0, -1);
    return truncateBreadcrumbs(data);
  }

  return data;
}

function removeReduxState(data) {
  if (jsonSize(data) > MAX_SIZE && data.extra && data.extra.state) {
    data.extra.state = {};
  }

  return data;
}

export function validateJsonSize(data) {
  data = removeReduxState(data);
  data = truncateBreadcrumbs(data);
  return data;
}

export default function createMiddleware() {
  const publicDSN = getSentryApiKey();
  if (process.env.NODE_ENV === 'production' && publicDSN) {
    Raven.config(publicDSN, {
      dataCallback: function(data) {
        return validateJsonSize(data);
      }
    }).install();
    return createRavenMiddleware(Raven, {
      // Optionally pass some options here.
    });
  }

  return () => {
    return next => action => {
      return next(action);
    };
  };
}
