/* eslint-disable */
import Fuse from 'fuse.js';
import { changeStatus } from './basePayCalc';
import { matrixFacetActions } from './matrixFacets';

const SEND_TEAM_DATA = 'SEND_TEAM_DATA';
const SEND_TEAM_DATA_SUCCESS = 'SEND_TEAM_DATA_SUCCESS';
const SEND_TEAM_DATA_FAIL = 'SEND_TEAM_DATA_FAIL';

const initialState = {
  groupItems: [],
  groupItemsSearchResult: null,
  groupStatusKey: [null, 'New', 'In Process', 'Sent'],
  matrixGroupings: [],
  matrixSetId: null,
  loading: false,
  loaded: false
};

//temporarily for unit tests. When hooked up to backend, the mock data should be somewhere else
export const getInitialState = () => initialState;

const fuseOptions = {
  shouldSort: true,
  threshold: 0.3,
  location: 0,
  distance: 100,
  maxPatternLength: 32,
  minMatchCharLength: 1,
  keys: ['workflowStatus', 'fieldValue']
};
let fuse;

export function initiateFuse(groupItems) {
  fuse = new Fuse(groupItems, fuseOptions); // eslint-disable-line new-cap
}

function filterEmployeeGroupFn(state, query) {
  //make a copy in case fuse mutates this array
  initiateFuse([...state.groupItems]);

  if (!query || query === '') {
    return null;
  }

  return fuse.search(query);
}

const applyNewMatrixSet = (state, action) => {
  const groupItems = action.result.matrixList.map(group => {
    try {
      group.budgetIncreaseAmt = group.currentBasePay * group.budgetIncreasePercent;
    } catch (e) {}
    return group;
  });

  return {
    ...state,
    groupItems,
    matrixSetId: action.result.matrixSetId,
    matrixGroupings: action.result.matrixGroupings,
    loading: false,
    loaded: true
  };
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case 'MATRIX_CHANGE_STATUS':
      return {
        ...state,
        groupItems: state.groupItems.map(group => {
          if (group.matrixGroupId === action.matrixGroupId) {
            group.workflowStatus = action.newStatus;
          }
          return { ...group };
        })
      };
    case 'GET_MATRIX_SET':
      return {
        ...state,
        loading: true
      };
    case 'GET_MATRIX_SET_SUCCESS':
      return applyNewMatrixSet(state, action);
    case 'MATRIX_APPLY_FILTERS_SUCCESS':
      return applyNewMatrixSet(state, action);
    case 'GET_MATRIX_SET_FAIL':
      return {
        ...state,
        loading: false,
        loaded: false
      };
    case 'CREATE_MATRIX_SET':
      return {
        ...state,
        loading: true
      };
    case 'CREATE_MATRIX_SET_SUCCESS':
      return {
        ...state
      };
    case 'CREATE_MATRIX_SET_FAIL':
      return {
        ...state,
        loading: false,
        loaded: false
      };
    case 'EMPLOYEE_GROUP_FILTER':
      return {
        ...state,
        loading: false,
        loaded: true,
        groupItemsSearchResult: filterEmployeeGroupFn(state, action.query)
      };
    case 'BUDGET_VAL_CALC':
      return applyCalcToGroupList(state, action);
    case 'BUDGET_PCT_CALC':
      return applyCalcToGroupList(state, action);
    default:
      return state;
  }
}

const applyCalcToGroupList = (state, action) => {
  return {
    ...state,
    groupItems: state.groupItems.map(group => {
      if (group.matrixGroupId === action.matrixGroupId) {
        group.budgetIncreasePercent = action.budgetIncreasePct / 100;
        group.budgetIncreaseAmt = Math.round(action.budgetIncreaseVal);
      }
      return { ...group };
    })
  };
};

export const rowClick = (history, event) => (dispatch, getState) => {
  const matrixGroupId = parseInt(event.currentTarget.id);

  history.push('/merit-matrix/' + matrixGroupId);
  dispatch({ type: 'MATRIX_LIST_ROW_CLICK', matrixGroupId: matrixGroupId });

  const group = getState().meritMatrix.groupList.groupItems.find(item => item.matrixGroupId === matrixGroupId);
  const status = group && group.workflowStatus;

  if (status === 'New') {
    dispatch(changeStatus({ newStatus: 'In Process', id: matrixGroupId }));
  }
};

export const getDefaultMatrixSet = options => {
  return (dispatch, getState) => {
    const matrixDefaultAjax = dispatch({
      types: ['GET_MATRIX_SET', 'GET_MATRIX_SET_SUCCESS', 'GET_MATRIX_SET_FAIL'],
      promise: client => client.get('/api/matrix-list/getMatrixSet', { params: { options } })
    });
    matrixDefaultAjax
      .then(result => {
        if (result.success) {
          const matrixSetId = result.matrixSetId;
          dispatch(calcSummaryStats(matrixSetId));
          dispatch(matrixFacetActions.initialSearch());
        }
      })
      .catch(err => {
        console.error('Error getting matrix set', err);
      });
  };
};

export const calcSummaryStats = matrixSetId => ({
  types: ['CALC_SUMMARY_STATS', 'CALC_SUMMARY_STATS_SUCCESS', 'CALC_SUMMARY_STATS_FAIL'],
  promise: client => client.post('/api/summary-stats/calculate', { params: { matrixSetId } })
});

export const getMatrixGroupDetails = matrixGroupId => {
  if (!matrixGroupId) return;
  return {
    types: ['GET_MATRIX_GROUP', 'GET_MATRIX_GROUP_SUCCESS', 'GET_MATRIX_GROUP_FAIL'],
    promise: client => client.get('/api/matrix-model/matrixModelInfo', { params: { matrixGroupId } })
  };
};

export function sendTeamData(groupId) {
  return (dispatch, getState) => {
    const createAjax = dispatch({
      types: [SEND_TEAM_DATA, SEND_TEAM_DATA_SUCCESS, SEND_TEAM_DATA_FAIL],
      promise: client => client.post('api/team/matrix-sync/sendToTeam', { params: { groupId: Number(groupId) } })
    });
  };
}

export const createInitialMatrixSet = (options, history) => {
  return (dispatch, getState) => {
    const ajaxPromise = dispatch({
      types: ['CREATE_MATRIX_SET', 'CREATE_MATRIX_SET_SUCCESS', 'CREATE_MATRIX_SET_FAIL'],
      promise: client => client.get('/api/matrix-set/createInitialMatrix', { params: options })
    });
    ajaxPromise
      .then(result => {
        if (result.success) {
          if (history) {
            history.push('/merit-matrix/');
          }
          dispatch(getDefaultMatrixSet());
        }
      })
      .catch(err => {
        console.error('Error creating initial matrix', err);
      });
  };
};

export const filterEmplyeeGroup = query => {
  return {
    type: 'EMPLOYEE_GROUP_FILTER',
    query: query
  };
};
