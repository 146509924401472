const MARKET_RATE_CHART = 'MARKET_RATE_CHART';
const MARKET_RATE_CHART_SUCCESS = 'MARKET_RATE_CHART_SUCCESS';
const MARKET_RATE_CHART_FAIL = 'MARKET_RATE_CHART_FAIL';
const MARKET_RATE_CHART_FILTER = 'MARKET_RATE_CHART_FILTER';
const MARKET_RATE_CHART_FILTER_SUCCESS = 'MARKET_RATE_CHART_FILTER_SUCCESS';
const MARKET_RATE_CHART_FILTER_FAIL = 'MARKET_RATE_CHART_FILTER_FAIL';
const GET_RECOMMEND_GRADE_SUCCESS = 'GET_RECOMMEND_GRADE_SUCCESS';
const CREATE_RETRIEVE_REPORT_SUCCESS = 'CREATE_RETRIEVE_REPORT_SUCCESS';
const RESET_MARKET_RATE_CHART = 'RESET_MARKET_RATE_CHART';

const initialState = {
  loading: false,
  loaded: false,
  error: null,
  chart: {}
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case MARKET_RATE_CHART:
      return {
        ...state,
        loading: true
      };
    case MARKET_RATE_CHART_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: null,
        highChartsJson: action.result
      };
    case MARKET_RATE_CHART_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        data: null,
        error: action.error
      };
    case RESET_MARKET_RATE_CHART:
      return {
        ...initialState
      };
    default:
      return { ...state };
  }
}

export function getMarketRateChart(options) {
  let filter = '';
  if (options) {
    filter = options.structure;
  }
  return (dispatch, getState) => {
    if (filter != 'N/A' && filter != '') {
      dispatch({
        types: [MARKET_RATE_CHART, MARKET_RATE_CHART_SUCCESS, MARKET_RATE_CHART_FAIL],
        promise: client =>
          client.get('/api/structures/structure-graph/marketRateByGrade', {
            params: { category_pk: 5, analyticsAggMode: true, structure_code: filter }
          })
      });
    } else {
      dispatch({
        type: RESET_MARKET_RATE_CHART
      });
    }
  };
}
