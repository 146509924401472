import superagent from 'superagent';

const methods = ['get', 'post', 'put', 'patch', 'del'];

// target format: <host>:<port>/<subsite>/app/api/...
function formatUrl(path) {
  const slash = path[0] === '/' ? '' : '/';
  const reResult = /\/view\/o\/(.+)\/lab\/?.*/.exec(window.location.pathname);
  const subsite = reResult && reResult[1];
  const fqdn = window.location.origin;

  return fqdn + '/' + subsite + '/app' + slash + path;
}

export default class ApiClientSuperagent {
  constructor(req) {
    this.socket = {};

    methods.forEach(
      method =>
        (this[method] = (path, { params = {}, data, files } = {}) => {
          return new Promise((resolve, reject) => {
            const request = superagent[method](formatUrl(path));
            // a handler to the last request is useful for cancelling file uploads
            this.lastRequest = request;
            this.lastRequest.uploadProgress = 0;

            if (params || files) {
              //
              if (window && window.app) {
                params.csrf_token = window.app.csrf_token;
                params.csrf_timestamp = window.app.csrf_timestamp;
              }

              // Prevent caching AJAX requests to prevent stale data
              // IE11 and Edge do not honor cache-control headers: https://developer.microsoft.com/en-us/microsoft-edge/platform/issues/107207/
              params.ts = Date.now();

              if (method.toLowerCase() === 'post') {
                //
                if (files && files.length > 0) {
                  let uuidList = []; // eslint-disable-line prefer-const
                  for (let fileCnt = 0; fileCnt < files.length; fileCnt++) {
                    request.attach('theFile', files[fileCnt]);
                    if (files[fileCnt].uuid) {
                      uuidList.push(files[fileCnt].uuid);
                    }
                  }

                  request.field('uuidList[]', uuidList);

                  request.field('csrf_token', params.csrf_token);
                  request.field('csrf_timestamp', params.csrf_timestamp);

                  request.on('progress', e => {
                    this.lastRequest.uploadProgress = e.percent;
                    //
                  });
                } else {
                  request.send(params);
                }
              } else {
                request.query(params);
              }
            }

            if (data) {
              if (window && window.app) {
                data.csrf_token = window.app.csrf_token;
                data.csrf_timestamp = window.app.csrf_timestamp;
              }
              request.send(data);
            }

            request.end((err, res = {}) => {
              if (err && res.statusCode && !res.statusText && res.error) {
                err = res.error;
              }

              return err ? reject(res.body || err) : resolve(res.body);
            });
          });
        })
    );
  }
  /*
   * There's a V8 bug where, when using Babel, exporting classes with only
   * constructors sometimes fails. Until it's patched, this is a solution to
   * "ApiClientSuperagent is not defined" from issue #14.
   * https://github.com/erikras/react-redux-universal-hot-example/issues/14
   *
   * Relevant Babel bug (but they claim it's V8): https://phabricator.babeljs.io/T2455
   *
   * Remove it at your own risk.
   */
  empty() {}
}
