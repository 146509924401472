import {
  PARTICIPATION_CENTER_MATCH_REVIEW_LOADING,
  PARTICIPATION_CENTER_MATCH_REVIEW_STEP_NEXT,
  PARTICIPATION_CENTER_MATCH_REVIEW_STEP_PREVIOUS,
  PARTICIPATION_CENTER_MATCH_REVIEW_SUBMIT_CHANGES,
  PARTICIPATION_CENTER_MATCH_REVIEW_SUBMIT_CHANGES_SUCCESS,
  PARTICIPATION_CENTER_MATCH_REVIEW_SUBMIT_CHANGES_FAILURE,
  PARTICIPATION_CENTER_MATCH_REVIEW_UPDATE_MATCH,
  PARTICIPATION_CENTER_MATCH_REVIEW_UPDATE_ZERO_WEIGHTED_MATCHES,
  PARTICIPATION_CENTER_MATCH_REVIEW_LOAD_REVIEW_ITEMS,
  PARTICIPATION_CENTER_MATCH_REVIEW_LOAD_REVIEW_ITEMS_SUCCESS,
  PARTICIPATION_CENTER_MATCH_REVIEW_LOAD_REVIEW_ITEMS_FAILURE,
  PARTICIPATION_CENTER_MATCH_REVIEW_INIT,
  PARTICIPATION_CENTER_MATCH_REVIEW_DUPLICATE_JOBS_INCLUDE_MATCH,
  PARTICIPATION_CENTER_MATCH_REVIEW_RESET,
  PARTICIPATION_CENTER_MATCH_REVIEW_RESET_ERRORS,
  CREATE_SURVEY_MATCHES,
  CREATE_SURVEY_MATCHES_SUCCESS,
  CREATE_SURVEY_MATCHES_FAIL,
  UPDATE_PARTICIPATION_STATUS,
  UPDATE_PARTICIPATION_STATUS_SUCCESS,
  UPDATE_PARTICIPATION_STATUS_FAIL
} from '../actions/MatchReviewActions';
import * as ParticipationMatchReviewUtils from '../utils/MatchReviewUtils';

export const REVIEW_STEP_SUMMARY = 'SUMMARY';
export const REVIEW_STEP_DUPLICATES = 'DUPLICATES';
export const REVIEW_STEP_ZERO_WEIGHTS = 'ZERO_WEIGHTS';

export const REVIEW_STATUS_NONE = 'NONE';
export const REVIEW_STATUS_LOADING = 'LOADING';
export const REVIEW_STATUS_LOAD_ERROR = 'LOAD ERROR';
export const REVIEW_STATUS_NO_REVIEW = 'NO REVIEW';
export const REVIEW_STATUS_IN_REVIEW = 'IN REVIEW';
export const REVIEW_STATUS_SUBMITTING = 'SUBMITTING';
export const REVIEW_STATUS_COMPLETE = 'COMPLETE';

export const initialState = {
  initialized: false,
  reviewStatus: REVIEW_STATUS_NONE,
  loading: false,
  submitting: false,
  reportURL: null,
  supportURL: null,
  openReportInNewTab: false,
  surveyName: null,
  surveyCode: null,
  surveyId: null,
  activeReviewStep: REVIEW_STEP_SUMMARY,
  previousReviewStep: null,
  nextReviewStep: null,
  dataLoadFailed: false,
  submitChangesFailed: false,
  duplicateMatches: [],
  zeroWeightedMatches: [],
  generateReportLoading: false
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case PARTICIPATION_CENTER_MATCH_REVIEW_INIT:
      return {
        ...initialState,
        ...action.payload,
        initialized: true
      };
    case PARTICIPATION_CENTER_MATCH_REVIEW_RESET:
      return {
        ...initialState
      };
    case PARTICIPATION_CENTER_MATCH_REVIEW_RESET_ERRORS:
      return {
        ...state,
        dataLoadFailed: false,
        submitChangesFailed: false
      };
    case PARTICIPATION_CENTER_MATCH_REVIEW_LOAD_REVIEW_ITEMS:
      return {
        ...state,
        reviewStatus: REVIEW_STATUS_LOADING,
        dataLoadFailed: false
      };
    case PARTICIPATION_CENTER_MATCH_REVIEW_LOAD_REVIEW_ITEMS_SUCCESS:
      return {
        ...state,
        ...action.payload,
        reviewStatus: getLoadedItemsReviewStatus(action.payload.zeroWeightedMatches, action.payload.duplicateMatches),
        ...getUpdatedReviewSteps(state, undefined, action.payload.zeroWeightedMatches, action.payload.duplicateMatches)
      };
    case PARTICIPATION_CENTER_MATCH_REVIEW_LOAD_REVIEW_ITEMS_FAILURE:
      return {
        ...state,
        reviewStatus: REVIEW_STATUS_IN_REVIEW,
        dataLoadFailed: true
      };
    case PARTICIPATION_CENTER_MATCH_REVIEW_SUBMIT_CHANGES:
      return {
        ...state,
        reviewStatus: REVIEW_STATUS_SUBMITTING,
        submitChangesFailed: false
      };
    case PARTICIPATION_CENTER_MATCH_REVIEW_SUBMIT_CHANGES_SUCCESS:
      return {
        ...state,
        reviewStatus: REVIEW_STATUS_COMPLETE
      };
    case PARTICIPATION_CENTER_MATCH_REVIEW_SUBMIT_CHANGES_FAILURE:
      return {
        ...state,
        reviewStatus: REVIEW_STATUS_IN_REVIEW,
        submitChangesFailed: true
      };
    case PARTICIPATION_CENTER_MATCH_REVIEW_UPDATE_MATCH:
      return {
        ...state,
        zeroWeightedMatches: getUpdatedZeroWeightedMatches(state, action.payload.matchId, action.payload.exclude),
        duplicateMatches: getUpdatedDuplicateMatches(state, action.payload.matchId, action.payload.exclude)
      };
    case PARTICIPATION_CENTER_MATCH_REVIEW_UPDATE_ZERO_WEIGHTED_MATCHES:
      return {
        ...state,
        zeroWeightedMatches: getUpdatedZeroWeightedMatches(state, null, action.payload.exclude),
        duplicateMatches: getUpdatedDuplicateMatchesWithZeroWeights(state, action.payload.exclude)
      };
    case PARTICIPATION_CENTER_MATCH_REVIEW_DUPLICATE_JOBS_INCLUDE_MATCH:
      return {
        ...state,
        ...getUpdatedMatchesFromIncludeDuplicateMatch(state, action.payload)
      };
    case PARTICIPATION_CENTER_MATCH_REVIEW_LOADING:
      return {
        ...state,
        loading: action.payload
      };
    case PARTICIPATION_CENTER_MATCH_REVIEW_STEP_PREVIOUS:
      return {
        ...state,
        ...getUpdatedReviewSteps(state, false)
      };
    case PARTICIPATION_CENTER_MATCH_REVIEW_STEP_NEXT:
      return {
        ...state,
        ...getUpdatedReviewSteps(state, true)
      };
    case CREATE_SURVEY_MATCHES:
      return {
        ...state,
        generateReportLoading: true
      };
    case CREATE_SURVEY_MATCHES_SUCCESS:
      return {
        ...state,
        generateReportLoading: false
      };
    case CREATE_SURVEY_MATCHES_FAIL:
      return {
        ...state,
        generateReportLoading: false
      };
    case UPDATE_PARTICIPATION_STATUS:
      return {
        ...state
      };
    case UPDATE_PARTICIPATION_STATUS_SUCCESS:
      return {
        ...state
      };
    case UPDATE_PARTICIPATION_STATUS_FAIL:
      return {
        ...state
      };
    default:
      return state;
  }
}

const getUpdatedMatchesFromIncludeDuplicateMatch = (state, payload) => {
  const { matchId, orgJobCode, orgJobCodeKey } = payload;
  //  STEP #1: Update duplicate matches and find updated match ids in order to update any possible
  //  zero weighted matches that are associated

  const includedMatchIds = [];
  const excludedMatchIds = [];
  const duplicateMatches = state.duplicateMatches.map(oj => {
    if (oj.orgJobCodeKey !== orgJobCodeKey || oj.orgJobCode !== orgJobCode) {
      return oj;
    } else {
      const newMatch = { ...oj };
      newMatch.matches = oj.matches.map(m => {
        const excludeFlag = m.matchId === matchId ? false : true;
        if (excludeFlag) excludedMatchIds.push(m.matchId);
        else includedMatchIds.push(m.matchId);

        return {
          ...m,
          //  update all matches to be excluded if the match id doesn't match the include action request
          exclude: excludeFlag
        };
      });
      return newMatch;
    }
  });

  const zeroWeightedMatches = state.zeroWeightedMatches.map(m => {
    if (includedMatchIds.includes(m.matchId)) {
      return {
        ...m,
        exclude: false
      };
    } else if (excludedMatchIds.includes(m.matchId)) {
      return {
        ...m,
        exclude: true
      };
    } else {
      return m;
    }
  });

  return {
    duplicateMatches,
    zeroWeightedMatches
  };
};

/**
 * Gets the updated duplicate matches list with updated duplicate matches that have a zero weighted match.
 * @param state previous reducer state
 * @param exclude the new match exclude state
 */
const getUpdatedDuplicateMatchesWithZeroWeights = (state, exclude) => {
  if (!state.duplicateMatches || !state.zeroWeightedMatches) {
    return state.duplicateMatches;
  }
  const { duplicateMatches, zeroWeightedMatches } = state;
  return duplicateMatches.map(orgJob => {
    return {
      ...orgJob,
      matches: orgJob.matches.map(dmatch => {
        if (
          zeroWeightedMatches.find(zmatch => {
            return zmatch.matchId === dmatch.matchId;
          })
        ) {
          return {
            ...dmatch,
            exclude
          };
        } else {
          return dmatch;
        }
      })
    };
  });
};

/**
 * Gets the updated zero weighted matches list based off an update request
 * @param state previous reducer state
 * @param matchId match id (if null, will apply update to all matches)
 * @param exclude the new match exclude state
 * @returns {*}
 */
const getUpdatedZeroWeightedMatches = (state, matchId, exclude) => {
  if (!state.zeroWeightedMatches) {
    return state.zeroWeightedMatches;
  }

  return state.zeroWeightedMatches.map(match => {
    if (matchId !== null && match.matchId !== matchId) {
      return match;
    } else {
      return {
        ...match,
        exclude
      };
    }
  });
};

/**
 * Gets the updated duplicate matches list based off an update request
 * @param state previous state
 * @param matchId match id
 * @param exclude the new match exclude state
 * @returns {*}
 */
const getUpdatedDuplicateMatches = (state, matchId, exclude) => {
  if (!state.duplicateMatches || !state.duplicateMatches.map) {
    return state.duplicateMatches;
  }

  return state.duplicateMatches.map(orgJob => {
    return {
      ...orgJob,
      matches: orgJob.matches.map(match => {
        if (match.matchId !== matchId) {
          return match;
        } else {
          return {
            ...match,
            exclude
          };
        }
      })
    };
  });
};

/**
 * Retrieves the updated previous, active, and next steps based on state and if its a next or previous action
 * @param state current state
 * @param moveNext true if moving to next step, false if moving to previous step
 * @param zeroWeightedMatches zero weighted matches
 * @param duplicateMatches duplicate matches
 * @returns {{previousReviewStep: *, activeReviewStep: null, nextReviewStep: *}}
 */
const getUpdatedReviewSteps = (
  state,
  moveNext = undefined,
  zeroWeightedMatches = state.zeroWeightedMatches,
  duplicateMatches = state.duplicateMatches
) => {
  let newActiveStep, newPreviousStep, newNextStep;

  if (moveNext === false) {
    newActiveStep = state.previousReviewStep;
  } else if (moveNext === true) {
    newActiveStep = state.nextReviewStep;
  } else {
    newActiveStep = state.activeReviewStep;
  }

  switch (newActiveStep) {
    case REVIEW_STEP_DUPLICATES:
      if (zeroWeightedMatches.length > 0) {
        newNextStep = REVIEW_STEP_ZERO_WEIGHTS;
      }
      newPreviousStep = REVIEW_STEP_SUMMARY;
      break;
    case REVIEW_STEP_ZERO_WEIGHTS:
      newNextStep = null;
      if (duplicateMatches.length > 0) {
        newPreviousStep = REVIEW_STEP_DUPLICATES;
      } else {
        newPreviousStep = REVIEW_STEP_SUMMARY;
      }
      break;
    case REVIEW_STEP_SUMMARY:
      newPreviousStep = null;
      if (duplicateMatches.length > 0) {
        newNextStep = REVIEW_STEP_DUPLICATES;
      } else if (zeroWeightedMatches.length > 0) {
        newNextStep = REVIEW_STEP_ZERO_WEIGHTS;
      } else {
        newNextStep = null;
      }
      break;
  }

  return {
    previousReviewStep: newPreviousStep,
    activeReviewStep: newActiveStep,
    nextReviewStep: newNextStep
  };
};

const getLoadedItemsReviewStatus = (zeroWeightedMatches, duplicateMatches) => {
  if (ParticipationMatchReviewUtils.containsUnresolvedMatches(duplicateMatches, zeroWeightedMatches)) {
    return REVIEW_STATUS_IN_REVIEW;
  } else {
    return REVIEW_STATUS_NO_REVIEW;
  }
};
