import SearchPanel from '../../../../../ps-components/src/redux/facetedSearch';

const nameSpace = 'STRUCTURES';
const initialState = {
  filtersAreOpen: false,
  facetSelection: {},
  facets: [],
  loading: false,
  loaded: false,
  error: null,
  items: [],
  query: '',
  showAddButton: true,
  resetFacetText: 'Reset Job Filters',
  filters: {},
  baseRoute: '/structure-model',
  facetIsCheckedByDefault: false
};

export const structureFilters = new SearchPanel(nameSpace, initialState);

const TOGGLE_FACET_PANEL = nameSpace + '_TOGGLR_FACET_PANEL';
const CLOSE_FACET_PANEL = nameSpace + '_CLOSE_FACET_PANEL';

function extendReducer(state, action) {
  switch (action.type) {
    case TOGGLE_FACET_PANEL:
      return {
        ...state,
        filtersAreOpen: !state.filtersAreOpen
      };
    case CLOSE_FACET_PANEL:
      return {
        ...state,
        filtersAreOpen: false
      };
    default:
      return null;
  }
}

export const reducer = structureFilters.createReducer(extendReducer, {
  searchAPI: '/api/assign-jobs/assign-jobs/assignJobsByFacets'
});

export const structureFilterActions = structureFilters.getActions();
export const selectors = structureFilters.getSelectors();

//Actions
structureFilterActions.toggleFacetPanel = () => ({ type: TOGGLE_FACET_PANEL });
structureFilterActions.closeFacetPanel = () => ({ type: CLOSE_FACET_PANEL });

//Update default action
const search = function({ params, clear }) {
  return (dispatch, getState) => {
    const { SEARCH, SEARCH_SUCCESS, SEARCH_FAIL, SEARCH_CLEAR_FACETS_FILTER } = this;

    const modelId = getState().currentStructure.modelId;
    if (!modelId) {
      return;
    }

    const searchParameters = {
      ...params,
      ...getState().currentStructure.editFormData,
      salary_display_code: getState().currentStructure.items[0].salary_display_code,
      modelId: modelId
    };
    const facetSearchAjax = dispatch({
      types: [clear ? SEARCH_CLEAR_FACETS_FILTER : SEARCH, SEARCH_SUCCESS, SEARCH_FAIL],
      promise: client => client.post(this.searchAPI, { params: searchParameters }),
      lastQueryParams: params,
      searchParameters
    });

    // update the chart and grid after saving/facet loading performed
    facetSearchAjax
      .then(function loadChartAndGrid(facetResults) {
        if (searchParameters.facetsLoaded === true) {
          // need to dispatch this event to signal loading/loaded state has changed for re-render
          dispatch({
            type: 'STRUCTURE_CHART'
          });
          dispatch({
            type: 'CURRENT_STRUCTURE'
          });

          // get rid of the locally modified chart so we can replace with one from server
          dispatch({
            type: 'REMOVE_MODEL_CHART_JSON'
          });

          // redraw the chart
          dispatch({
            type: 'STRUCTURE_CHART_SUCCESS',
            result: facetResults.chartData,
            setModelChartJson: true
          });

          // redraw the grid
          dispatch({
            type: 'CURRENT_STRUCTURE_GRID_DRAW',
            modelId: modelId,
            result: facetResults.gridData
          });
        }
      })
      .catch(err => {
        console.error('Error searching structures', err);
      });
  };
};

structureFilters.updateDefaultActions({ search });

//register hooks - Hooks have the same characteristics as action creators -> return an object or function
// todo: delete all hooks related code
/*structureFilters.hooks.onFacetChange = ({params: searchParameters}) => {
  return (dispatch, getState) => {
    
    dispatch({
      types: [SOME_API, SOME_API_SUCCESS, SOME_API_FAIL],
      promise: client => client.get('/api/some-api', {params: searchParameters})
    });
  };
};*/
