const CREATE_STRUCTURE = 'CREATE_STRUCTURE';
export const CREATE_STRUCTURE_SUCCESS = 'CREATE_STRUCTURE_SUCCESS';
const CREATE_STRUCTURE_FAIL = 'CREATE_STRUCTURE_FAIL';

const STRUCTURE_VALIDATION = 'STRUCTURE_VALIDATION';
const CREATE_STRUCTURE_VALIDATION = 'CREATE_STRUCTURE_VALIDATION';

import { NUMBER_INPUT_CHANGE } from './createStructureModal';
import { NAME_CHANGE } from './createStructureModal';
import { getNewlyCreatedStructure } from './currentStructure';

const initialState = {
  loading: false,
  loaded: false,
  error: null,
  nameValid: null,
  lowMidValid: null,
  highMidValid: null,
  ProgressionValid: null,
  viewScenarioLoad: false
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case CREATE_STRUCTURE:
      return {
        ...state,
        loading: true,
        viewScenarioLoad: true
      };
    case CREATE_STRUCTURE_SUCCESS:
      let nameValid = null;
      if (!action.result.success && action.result.error_message && action.result.error_message === 'Duplicate Name') {
        nameValid = 'error';
      }
      return {
        ...state,
        loading: false,
        loaded: true,
        error: null,
        viewScenarioLoad: false,
        nameValid: nameValid
      };
    case CREATE_STRUCTURE_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        data: null,
        viewScenarioLoad: false,
        error: action.error
      };
    case STRUCTURE_VALIDATION:
      return {
        ...state,
        nameValid: action.validations.nameValid,
        highMidValid: action.validations.highMidValid,
        lowMidValid: action.validations.lowMidValid,
        ProgressionValid: action.validations.ProgressionValid
      };
    case CREATE_STRUCTURE_VALIDATION:
      return {
        ...state,
        nameValid: action.nameValid,
        highMidValid: action.highMidValid,
        lowMidValid: action.lowMidValid,
        ProgressionValid: action.ProgressionValid
      };
    case NUMBER_INPUT_CHANGE:
      const formValuesValidations = structureValidations(action.newFormValues);
      return { ...state, ...formValuesValidations };
    case NAME_CHANGE:
      const nameValidation = structureValidations(action.newFormValues);
      return {
        ...state,
        ...nameValidation
      };
    default:
      return state;
  }
}

export function createNewStructure(options, history) {
  const validations = structureValidations(options);
  return (dispatch, getState) => {
    dispatch({
      type: 'STRUCTURE_VALIDATION',
      validations: validations
    });

    if (
      validations.nameValid != 'error' &&
      validations.highMidValid != 'error' &&
      validations.lowMidValid != 'error' &&
      validations.ProgressionValid != 'error'
    ) {
      const createAjax = dispatch({
        types: [CREATE_STRUCTURE, CREATE_STRUCTURE_SUCCESS, CREATE_STRUCTURE_FAIL],
        promise: client =>
          client.post('api/structures/grades/saveNewStructure', {
            params: {
              model_name: options.structureName,
              high_midpoint: options.highMidpoint,
              low_midpoint: options.lowMidpoint,
              progression: options.progression,
              num_levels: options.numRanges,
              mode: 'new',
              structure_code: options.structureName,
              model_currency: options.currency.value,
              model_salary_display_code: options.typeToggleValue
            }
          })
      });
      createAjax
        .then(function saveStructureAfterCreate(results) {
          if (results.success) {
            const saveStructureOptions = {
              model_name: options.structureName,
              name: options.structureName,
              low_mid: options.lowMidpoint,
              model_id: results.model_id,
              high_mid: options.highMidpoint,
              structure_code_basis: options.structureName,
              structure_code: options.structureName,
              progression: options.progression,
              grades: options.numRanges,
              range_spread: 50,
              mode: 'new',
              newStructure: true
            };
            dispatch(getNewlyCreatedStructure(saveStructureOptions, history));
          } else {
            // Fail, Call validation
          }
        })
        .catch(err => {
          console.error('Error saving new structure', err);
        });
    }
  };
}

export function structureValidations(options) {
  let nameValid;
  let lowMidValid;
  let highMidValid;
  let ProgressionValid;

  if (options.structureName === '') {
    nameValid = 'error';
  } else {
    nameValid = null;
  }
  if (
    isNaN(options.highMidpoint) ||
    options.highMidpoint == '' ||
    parseFloat(options.highMidpoint) <= parseFloat(options.lowMidpoint)
  ) {
    highMidValid = 'error';
  } else {
    highMidValid = null;
  }
  if (
    isNaN(options.lowMidpoint) ||
    options.lowMidpoint == '' ||
    parseFloat(options.lowMidpoint) >= parseFloat(options.highMidpoint)
  ) {
    lowMidValid = 'error';
  } else {
    lowMidValid = null;
  }
  if (isNaN(options.progression) || options.progression == '' || options.progression <= 0) {
    ProgressionValid = 'error';
  } else {
    ProgressionValid = null;
  }

  return {
    nameValid: nameValid,
    highMidValid: highMidValid,
    lowMidValid: lowMidValid,
    ProgressionValid: ProgressionValid
  };
}

export function createStructureValidations(options) {
  const validations = structureValidations(options);

  return (dispatch, getState) => {
    dispatch({
      type: 'CREATE_STRUCTURE_VALIDATION',
      nameValid: validations.nameValid,
      highMidValid: validations.highMidValid,
      lowMidValid: validations.lowMidValid,
      ProgressionValid: validations.ProgressionValid
    });
  };
}
