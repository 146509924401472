export const GET_EMPLOYEE_PDF_SETTINGS = 'EMPLOYEE_PDF_SETTINGS';
export const GET_EMPLOYEE_PDF_SETTINGS_SUCCESS = 'EMPLOYEE_PDF_SETTINGS_SUCCESS';
export const GET_EMPLOYEE_PDF_SETTINGS_FAIL = 'EMPLOYEE_PDF_SETTINGS_FAIL';
export const EMPLOYEE_PAY_HISTORY = 'EMPLOYEE_PAY_HISTORY';
export const EMPLOYEE_PAY_HISTORY_SUCCESS = 'EMPLOYEE_PAY_HISTORY_SUCCESS';
export const EMPLOYEE_PAY_HISTORY_FAILURE = 'EMPLOYEE_PAY_HISTORY_FAILURE';

export const loadDetailsPanel = function loadDetailsPanel(dispatch, getStore, itemId, item) {
  dispatch({
    types: ['EMPLOYEE_DETAILS', 'EMPLOYEE_DETAILS_SUCCESS', 'EMPLOYEE_DETAILS_FAILURE'],
    promise: client => client.get('/api/market-pricing/v1/employees/' + itemId)
  })
    .then(result => {
      if (!result) {
        return;
      }

      dispatch({
        types: ['EMPLOYEE_CHARTS_LOAD', 'EMPLOYEE_CHARTS_SUCCESS', 'EMPLOYEE_CHARTS_FAIL'],
        promise: client =>
          client.get('/api/hris/employee-details/payRangeAndGrade', {
            params: { org_job_id: result.orgJobId }
          }),
        itemId
      });

      dispatch({
        types: [GET_EMPLOYEE_PDF_SETTINGS, GET_EMPLOYEE_PDF_SETTINGS_SUCCESS, GET_EMPLOYEE_PDF_SETTINGS_FAIL],
        promise: client => client.get('/api/generic-user/users/getPdfSettings/')
      });

      dispatch({
        types: [
          'EMPLOYEE_RANGE_DISTRIBUTION',
          'EMPLOYEE_RANGE_DISTRIBUTION_SUCCESS',
          'EMPLOYEE_RANGE_DISTRIBUTION_FAIL'
        ],
        promise: client => client.get('/api/market-pricing/v1/rangeDistribution/')
      });

      dispatch({
        types: [EMPLOYEE_PAY_HISTORY, EMPLOYEE_PAY_HISTORY_SUCCESS, EMPLOYEE_PAY_HISTORY_FAILURE],
        promise: client =>
          client.get('/api/hris/employee-details/payHistory', {
            params: { org_employee_id: itemId }
          })
      });
    })
    .catch(err => {
      console.error('Error loading details panel', err);
    });
};

export const loadComparisonPanel = function loadComparisonPanel(dispatch, getStore, selectedList) {
  const options = { org_employee_id: selectedList, currency: window.app.default_currency };
  const params = {
    params: { org_employee_id: selectedList, currency: window.app.default_currency }
  };

  dispatch({
    types: ['EMPLOYEE_COMPARE', 'EMPLOYEE_COMPARE_SUCCESS', 'EMPLOYEE_COMPARE_FAIL'],
    promise: client => client.get('/api/hris/employee-details/employeeCompare', params),
    options
  });
};
