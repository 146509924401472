import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';
import { AllHtmlEntities } from 'html-entities';
import classnames from 'classnames';
import isIE from 'ps-components/src/Utils/checkForIE';
import isURL from 'is-url';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';

import { formatCurrency, formatNumber } from '../../Utils/currencyUtils';
import { numberWithCommas } from './../../lib/formatters';
import Popover from '../../Popover/Popover';
import SubLabelList from './../../SubLabelList/SubLabelList';

const htmlEntities = new AllHtmlEntities();

/*
Custom renderers for cells for generic grids
should be placed in this file and imported in as necessary.
See 'CUSTOM RENDERERS' tag below
*/

export function formatDate(date) {
  return moment(date).format('YYYY-MM-DD');
}

function parseNumCheckNull(str) {
  if (str === undefined || str === null) return '-';
  if (str) return numberWithCommas(parseFloat(str));
  return '0';
}

// List of possible ReportWriter types
export const TEXT_TYPE = 'text',
  NUMBER_TYPE = 'number',
  TIMESTAMP_TYPE = 'timestamp',
  PERCENT_TYPE = 'percent',
  MONETARY_TYPE = 'monetary',
  UNKNOWN_TYPE = 'unknown',
  INTEGER_TYPE = 'integer',
  BOOLEAN_TYPE = 'boolean',
  DATE_TYPE = 'date',
  TS_INTERVAL_TYPE = 'ts_interval',
  PRIMARY_KEY_TYPE = 'primary_key',
  JSON_TYPE = 'jsonb',
  DATE_RANGE_TYPE = 'daterange';

export const DefaultRender = type => {
  switch (type) {
    case NUMBER_TYPE:
      return NumberRender;
    case MONETARY_TYPE:
      return MonetaryRender;
    case PERCENT_TYPE:
      return PercentRender;
    default:
      return UnknownRender;
  }
};

export const TextRender = (rowData, key) => {
  const renderTextRender = () => {
    return <div className="text">{rowData[key] || ''}</div>;
  };
  return renderTextRender();
};

const UnknownRender = (rowData, key) => {
  const renderUnknownRender = () => {
    if (isURL(rowData[key])) {
      return (
        <Fragment>
          <a className="unknown-link link" href={rowData[key]} data-tip={rowData[key]} target="_blank" rel="noreferrer">
            View Link
          </a>
          <ReactTooltip place="bottom" />
        </Fragment>
      );
    }

    return <div className="unknown">{rowData[key] || ''}</div>;
  };
  return renderUnknownRender();
};

const MonetaryRender = (rowData, key, options) => {
  let currency;
  let formattedNumber;
  const number = parseNumCheckNull(rowData[key]);
  const rowCurrency = getDefaultOrField(rowData, 'currency') || options?.currency;
  const currencyList = rowCurrency?.split(' ');
  currency = currencyList?.length > 0 ? currencyList[0] : 'USD';

  if (rowData[key] && currency && !options.isPrint) {
    formattedNumber = formatCurrency(rowData[key], currency);
  }

  return <div className="monetary">{formattedNumber ? formattedNumber : number}</div>;
};

const NumberRender = (rowData, key, options) => {
  let currency;
  let formattedNumber;
  const number = parseNumCheckNull(rowData[key]);

  let numberOfDecimals;
  if (options.getNumberOfDecimals) {
    numberOfDecimals = options.getNumberOfDecimals(key, number);
  } else {
    numberOfDecimals = options.numericRounding || 1;
  }

  const rowCurrency = getDefaultOrField(rowData, 'currency') || options?.currency;
  const currencyList = rowCurrency?.split(' ');
  currency = currencyList?.length > 0 ? currencyList[0] : 'USD';

  if (rowData[key] && currency) {
    formattedNumber = formatNumber(rowData[key], currency, numberOfDecimals);
  }

  return <div className="number">{formattedNumber ? formattedNumber : number}</div>;
};

const PercentRender = (rowData, key, options) => {
  let currency;
  let formattedNumber;
  const numberOfDecimals = options.percentRounding || 2;
  const number = rowData[key] ? `${parseNumCheckNull(rowData[key])}%` : '-';
  const rowCurrency = getDefaultOrField(rowData, 'currency') || options?.currency;
  const currencyList = rowCurrency?.split(' ');
  currency = currencyList?.length > 0 ? currencyList[0] : 'USD';

  if (rowData[key] && currency) {
    formattedNumber = formatNumber(rowData[key], currency, numberOfDecimals);
  }

  const renderPercentRender = () => {
    return <div className="percent">{formattedNumber ? `${formattedNumber}%` : number}</div>;
  };
  return renderPercentRender();
};

/* CUSTOM RENDERERS */

export const JobDetails = (rowData, key, options) => {
  const {
    isSummary,
    useJobCodeKey = true,
    jobCodeKeyText = 'Job Code Key',
    isRowLoading,
    isRowDeleting,
    loadingText,
    deletingText,
    addedMatches,
    excludeLoadingText,
    isMarketComparison
  } = options;

  const org_job_id = getDefaultOrField(rowData, 'org_job_id');
  const org_job_code = getDefaultOrField(rowData, 'org_job_code');
  const org_job_code_key = getDefaultOrField(rowData, 'org_job_code_key');
  const org_job_description = getDefaultOrField(rowData, 'org_job_description');
  const org_job_title = getDefaultOrField(rowData, 'org_job_title');

  if (isSummary) return <div>Composite</div>;
  if (isMarketComparison) return null;

  const subLabels = [];
  if (org_job_code && org_job_code !== '' && org_job_code !== ' ') {
    subLabels.push({ label: 'Job Code', text: org_job_code });
  }

  if (useJobCodeKey) {
    if (org_job_code_key && org_job_code_key !== '' && org_job_code_key !== ' ') {
      subLabels.push({ label: jobCodeKeyText, text: org_job_code_key });
    } else {
      subLabels.push({ label: jobCodeKeyText, text: 'Not Set' });
    }
  }

  return (
    <Fragment>
      {!excludeLoadingText && (
        <div className="loading-text">
          {isRowLoading
            ? isRowDeleting
              ? deletingText
              : loadingText
            : addedMatches?.includes(org_job_id) && <span className="data__cell-added-match">Added to Pricing</span>}
        </div>
      )}

      <div className="default-job-cell__title">
        {org_job_description ? (
          <span
            data-row-key={`title-${org_job_id}`}
            key={`title-${org_job_id}`}
            data-tip={htmlEntities.decode(org_job_description)}
          >
            {org_job_title}
          </span>
        ) : (
          org_job_title
        )}
      </div>
      <div className="default-job-cell__list">
        <SubLabelList subLabels={subLabels} />
      </div>

      <ReactTooltip data-row-key={`desc-${org_job_id}`} key={`desc-${org_job_id}`} place="bottom" />
    </Fragment>
  );
};

function getPurchasedButton(rowData, onClick) {
  const data = [];
  const {
    field_availableForPurchase,
    field_purchasePrice,
    field_participationPurchasePrice,
    field_contractLanguageURL
  } = rowData;

  if (
    field_availableForPurchase &&
    (field_purchasePrice != null || field_participationPurchasePrice != null) &&
    field_contractLanguageURL
  ) {
    data.push(<div className="marketplace-matches__available-for-purchase">Available for purchase</div>);
  }

  data.push(
    <div
      className="pxl-btn marketplace-matches__learn-more"
      onClick={e => {
        if (onClick) {
          onClick(rowData);
        }
      }}
    >
      Learn More
    </div>
  );

  return <div className={classnames('marketplace-matches__right-data', { ie: isIE() })}>{data}</div>;
}

export const GenericSurveyDetails = (rowData, key, options) => {
  const {
    onClick,
    renderPopover,
    togglePopover,
    selectedRowId,
    isRowLoading,
    isRowDeleting,
    loadingText,
    deletingText,
    addedMatches,
    getSurveyJobDetailsPageHref,
    isPrint
  } = options;
  const isDataCutReadOnly = rowData.field_hasData === false || isPrint;
  const dataCutLabel = getDataCutLabel(rowData, options, ',', 'top', isDataCutReadOnly);
  const jobDescriptionLinkRef = React.createRef();
  const surveyJobDetailsHref = getSurveyJobDetailsPageHref ? getSurveyJobDetailsPageHref(rowData.field_cutId) : '';

  return (
    <Fragment>
      <div className="row-data">
        <div className="data__cell-tertiary">
          {isRowLoading ? (
            <div className="loading-text">{isRowDeleting ? deletingText : loadingText}</div>
          ) : (
            <div className="matched-text">
              {rowData.field_confidence >= 0.3 && <span className="data__cell-survey-match">Frequently Matched</span>}
              {addedMatches?.includes(rowData.field_cutId) && (
                <span className="data__cell-added-match">Added to Pricing</span>
              )}
            </div>
          )}
        </div>
        <div className="data__cell-primary">
          {(rowData.field_purchased || rowData.field_hasData) && !isPrint ? (
            <a className="data__cell-title" href={surveyJobDetailsHref} target="_blank" rel="noreferrer">
              {rowData.field_surveyJobTitle || rowData.job_title_default || rowData.field_jobTitle || 'No Title'}
            </a>
          ) : (
            <div
              className="data__cell-title normal"
              className={classnames('data__cell-title normal', { print: isPrint })}
            >
              {rowData.field_surveyJobTitle || rowData.job_title_default || rowData.field_jobTitle || 'No Title'}
            </div>
          )}

          <span className="data__cell-survey-job-code">{rowData.field_surveyJobCode}</span>
        </div>
        <div className="data__cell-tertiary">
          <span className="data__cell-survey-code">{rowData.field_pubSurveyCode}</span>
          <span className="data__cell-survey-title">{rowData.field_pubSurveyTitle || rowData.field_surveyTitle}</span>
        </div>
        <div className="data__cell-tertiary">
          {dataCutLabel === '' ? null : <Fragment>{dataCutLabel} </Fragment>}
          {rowData.field_companyCount && ` ${rowData.field_companyCount} cos, `}
          {rowData.field_employeeCount && `${rowData.field_employeeCount} ees, `}
          {rowData.field_surveyCurrency && `${rowData.field_surveyCurrency}`}
          {rowData.field_pubSurveyEffectiveDate && `, eff ${formatDate(rowData.field_pubSurveyEffectiveDate)}`}
        </div>
        <div className="data__cell-tertiary">
          {rowData.field_description && !isPrint && (
            <a ref={jobDescriptionLinkRef} onClick={() => togglePopover(rowData.field_cutId)}>
              View Job Description
            </a>
          )}
        </div>
        {rowData.field_cutId === selectedRowId &&
          ReactDOM.createPortal(
            <Popover
              triggerElement={jobDescriptionLinkRef}
              relativeElement={document.querySelector('div#advanced-table__popover')}
              popoverText={rowData.field_description}
              display={renderPopover}
              togglePopover={togglePopover}
            />,
            document.getElementById('advanced-table__popover')
          )}
      </div>

      {rowData.field_purchased === false && getPurchasedButton(rowData, onClick)}
    </Fragment>
  );
};

export function getDataCutLabel(rowData, options, separator = '', display = 'top', readOnly = false) {
  const dataCut = getDefaultOrField(rowData, 'job_scope') || rowData.field_jobScope;
  if (!dataCut) {
    return '';
  }
  if (!options.onDataCutClick) {
    return `${dataCut}${separator} `;
  }
  return (
    <Fragment>
      <a
        className={readOnly ? 'data-cut__read-only' : ''}
        data-tip={readOnly ? '' : 'Click to show all data cuts'}
        data-effect="solid"
        data-delay-show="250"
        onClick={readOnly ? null : options.onDataCutClick.bind(this, rowData)}
      >
        {`${dataCut}${separator} `}
      </a>
      <ReactTooltip place={display} />
    </Fragment>
  );
}

export const SurveyDetails = (rowData, key, options) => {
  const {
    isRowLoading,
    isRowDeleting,
    loadingText,
    deletingText,
    addedMatches,
    excludeLoadingText,
    getSurveyJobDetailsPageHref
  } = options;
  const cutId = getDefaultOrField(rowData, 'cut_id') || rowData.cutId;
  const effDate =
    getDefaultOrField(rowData, 'pub_survey_effective_date') || getDefaultOrField(rowData, 'effective_date');
  const { renderPopover, togglePopover, selectedRowId } = options;
  const jobDescriptionLinkRef = React.createRef();
  const surveyJobDetailsHref = getSurveyJobDetailsPageHref ? getSurveyJobDetailsPageHref(cutId) : '';
  const surveyShortName = getDefaultOrField(rowData, 'survey_short_name') || '';

  return (
    <div className="default-cell__survey">
      {!excludeLoadingText && (
        <div className="loading-text">
          {isRowLoading
            ? isRowDeleting
              ? deletingText
              : loadingText
            : addedMatches?.includes(cutId) && <span className="data__cell-added-match">Added to Pricing</span>}
        </div>
      )}

      <div className="default-cell__title">
        <a className="default-cell__title" href={surveyJobDetailsHref} target="_blank" rel="noreferrer">
          {getDefaultOrField(rowData, 'job_title') || 'No title'}
        </a>
        <span className="default-cell__code">{getDefaultOrField(rowData, 'job_code') || ''}</span>
      </div>

      <div className="default-cell__sub-text">
        <div>{`${getDefaultOrField(rowData, 'survey_code') || ''}${
          surveyShortName ? `, ${surveyShortName}` : ''
        }`}</div>
        <div>
          {getDataCutLabel(rowData, options, ',')}
          {`${getDefaultOrField(rowData, 'total_companies') || '-'} cos,
          ${getDefaultOrField(rowData, 'total_incumbents') || '-'} ees,
          ${getDefaultOrField(rowData, 'currency') || 'currency'}, eff ${formatDate(effDate)}`}
          <br />
          {getDefaultOrField(rowData, 'job_description') && (
            <a ref={jobDescriptionLinkRef} onClick={() => togglePopover(cutId)}>
              View Job Description
            </a>
          )}
          {cutId === selectedRowId &&
            ReactDOM.createPortal(
              <Popover
                triggerElement={jobDescriptionLinkRef}
                relativeElement={document.querySelector('div#advanced-table__popover')}
                popoverText={getDefaultOrField(rowData, 'job_description')}
                display={renderPopover}
                togglePopover={togglePopover}
              />,
              document.getElementById('advanced-table__popover')
            )}
        </div>
      </div>
    </div>
  );
};

export const DataCutDetails = (rowData, key, options) => {
  const defaultDataCutClassNames = classnames('default-cell__data-cut', { default: rowData.isDefault });
  return (
    <div className={defaultDataCutClassNames}>
      {rowData.isDefault && <div className="default-cell__default">Default</div>}
      <div className="default-cell__scope">{rowData.jobScope}</div>
    </div>
  );
};

// Check if the data object has a property for term_default
// Else, check if data has the field_term property
// Else, check if data has the term property
// Else, return null
export function getDefaultOrField(data, term) {
  if (data && term) {
    const fieldTerm = `field_${term}`;
    const defaultTerm = `${term}_default`;

    if (data.hasOwnProperty(defaultTerm)) {
      return data[defaultTerm];
    }

    if (data.hasOwnProperty(fieldTerm)) {
      return data[fieldTerm];
    }

    if (data.hasOwnProperty(term)) {
      return data[term];
    }
  }

  return null;
}
