import apiClient from '../../../lib/apiClient';

const SUGGEST_CAREER_LIST = 'SUGGEST_CAREER_LIST';
const SUGGEST_CAREER_LIST_SUCCESS = 'SUGGEST_CAREER_LIST_SUCCESS';
const SUGGEST_CAREER_LIST_FAIL = 'SUGGEST_CAREER_LIST_FAIL';

const initialState = {
  loading: false,
  loaded: false,
  error: null,
  items: []
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SUGGEST_CAREER_LIST:
      return {
        ...initialState,
        loading: true
      };
    case SUGGEST_CAREER_LIST_SUCCESS:
      let careerList = [];
      if (action.result) {
        careerList = action.result.map(row => {
          return row.career;
        });
      }
      return {
        ...state,
        loading: false,
        loaded: true,
        error: null,
        items: careerList
      };
    case SUGGEST_CAREER_LIST_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error
      };
    default:
      return { ...state };
  }
}

export function suggestCareerLevel(options) {
  return async (dispatch, getState) => {
    const query = {
      basePay50th: options.basePay50th,
      jobFamily: getState().addJob.jobFamily
    };

    try {
      dispatch({ type: SUGGEST_CAREER_LIST });
      const result = await apiClient.apiGet('/api/hris/career-level/suggestCareerLevelForTitle', { params: query });

      dispatch({
        type: SUGGEST_CAREER_LIST_SUCCESS,
        result: result.data,
        query: query
      });
    } catch (error) {
      dispatch({ type: SUGGEST_CAREER_LIST_FAIL, error: error });
    }
  };
}
