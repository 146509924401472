import Modal from '@payscale/design/lib/modal';

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withRouter } from 'react-router';

import updateRoute from '../Utils/updateRoute';

export class ModalComponent extends Component {
  static propTypes = {
    className: PropTypes.string,
    id: PropTypes.string,
    children: PropTypes.any,
    displayCloseButton: PropTypes.bool,
    show: PropTypes.bool,
    allowClose: PropTypes.bool,
    endpoint: PropTypes.string,
    history: PropTypes.object,
    location: PropTypes.object,
    onClose: PropTypes.func
  };

  constructor(props) {
    super(props);

    this.onModalClose = this.onModalClose.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (!this.modal) {
      return;
    }
    if (prevProps.show !== this.props.show) {
      if (this.props.endpoint) {
        updateRoute(this.props.location.pathname, this.props.endpoint, this.props.history, this.props.show);
      }
      if (this.props.show) {
        this.modal.show();
      } else {
        this.modal.hide();
      }
    }
  }

  componentDidMount() {
    if (this.props.show) {
      if (this.props.endpoint) {
        updateRoute(this.props.location.pathname, this.props.endpoint, this.props.history, true);
      }
      this.modal.show();
    }
  }

  componentWillUnmount() {
    this.modal?.hide();
  }

  onModalClose() {
    // ensure the underlying modal isn't closed to prevent double callbacks
    // only if the onClose does not already exist
    if (!this.props.onClose && this.props.endpoint && this.props.show && this.modal && !this.modal.state.hide) {
      updateRoute(this.props.location.pathname, this.props.endpoint, this.props.history, false);
    }
    if (this.props.onClose) {
      this.props.onClose();
    }
  }

  render() {
    return (
      <Modal
        className={this.props.className}
        id={this.props.id}
        allowClose={this.props.allowClose}
        displayCloseButton={this.props.displayCloseButton}
        ref={modal => {
          this.modal = modal;
        }}
        onClose={this.onModalClose}
      >
        {this.props.children}
      </Modal>
    );
  }
}

export default withRouter(ModalComponent);
