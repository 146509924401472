/* eslint-disable */
import moment from 'moment';
import matrixCalc from '@payscale/isomorphic-merit-matrix-calc';
const matrixCalcCore = matrixCalc();

const SEND_TEAM_DATA = 'SEND_TEAM_DATA';
const SEND_TEAM_DATA_SUCCESS = 'SEND_TEAM_DATA_SUCCESS';
const SEND_TEAM_DATA_FAIL = 'SEND_TEAM_DATA_FAIL';

const initialState = {
  matrixGroupId: null,
  matrixRangeType: 'market',
  employeeCount: 0,
  preset: undefined,
  sentToTeamTime: '',
  sendToTeamLoading: false
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case 'MATRIX_CALC':
      return { ...state, preset: action.preset };
    case 'GET_MATRIX_GROUP_SUCCESS':
      const {
        sentToTeamTime,
        matrixRangeType,
        employeeCount,
        preset,
        matrixGroupId,
        fieldValues
      } = action.result.groupModel;
      return {
        ...state,
        matrixGroupId,
        sentToTeamTime: sentToTeamTime ? moment.unix(sentToTeamTime).format('lll') : '',
        matrixRangeType,
        employeeCount, // this is state duplication. employee count is in the list. try not to use this before it's refactored
        preset,
        fieldValues,
        includedEmployeeCount: action.result.cellData.includedEmployeeCount,
        sendToTeamLoading: false
      };
    case 'CHANGE_MATRIX_RANGE_TYPE_SUCCESS':
      return {
        ...state,
        matrixRangeType: action.result.matrixRangeType,
        includedEmployeeCount: action.result.cellData.includedEmployeeCount
      };
    case 'SEND_TEAM_DATA':
      return {
        ...state,
        loading: true,
        sendToTeamLoading: true
      };
    case 'SEND_TEAM_DATA_SUCCESS':
      return {
        ...state,
        loading: false,
        loaded: true,
        error: null,
        sendToTeamLoading: false,
        sentToTeamTime: action.result.sentToTeamTime ? moment.unix(action.result.sentToTeamTime).format('lll') : ''
      };
    case 'SEND_TEAM_DATA_FAIL':
      return {
        ...state,
        loading: false,
        loaded: false,
        data: null,
        sendToTeamLoading: false,
        error: action.error
      };
    default:
      return state;
  }
}

export function calculateMatrixModel({ preset, ...event }) {
  return (dispatch, getState) => {
    const state = getState();
    const isPreset = Boolean(preset);
    const isSliderChange = Boolean(event.y);

    let performanceBuckets, payBuckets;
    //if a preset button was clicked, an array of numbers will be passed in to apply to the buckets
    //otherwise use the y value from the slider event to change the corresponding bucket raw val
    if (isSliderChange) {
      const category = event.target.category;
      //mutate the existing state to limit the updates to the chart series data. A new array will animate the slider knobs
      const applySlideVal = bucket =>
        (bucket.rawSliderValue = bucket.name === category ? parseInt(event.y) : bucket.rawSliderValue);
      state.meritMatrix.performanceBuckets.forEach(applySlideVal);
      state.meritMatrix.payBuckets.forEach(applySlideVal);

      performanceBuckets = state.meritMatrix.performanceBuckets;
      payBuckets = state.meritMatrix.payBuckets;
    } else if (isPreset) {
      const buckets = {
        performanceBuckets: state.meritMatrix.performanceBuckets,
        payBuckets: state.meritMatrix.payBuckets
      };
      const newBuckets = matrixCalcCore.applyPresetValues(buckets, preset);
      performanceBuckets = newBuckets.performanceBuckets;
      payBuckets = newBuckets.payBuckets;
    } else {
      // is allocation change or group budget change. Any Change is already reflected in the state

      performanceBuckets = state.meritMatrix.performanceBuckets;
      payBuckets = state.meritMatrix.payBuckets;
    }

    //matrix info
    const {
      currentBasePay,
      budgetIncreasePct,
      budgetIncreaseVal: targetIncreaseDollars,
      newBasePay: proposedPayrollDollars
    } = state.basePayCalc;
    const { employeeCount, includedEmployeeCount } = state.matrixGroupModel;
    const { performanceAllocationPerc, rangeAllocationPerc } = state.meritMatrix.allocation;

    const matrixGroupInfo = {
      currentBasePay,
      budgetIncreasePercent: budgetIncreasePct / 100,
      targetIncreaseDollars,
      proposedPayrollDollars,
      employeeCount: includedEmployeeCount || employeeCount, //for the purpose of calculations the emplCount should only be empl included in the matrix
      performanceBudgetAllocation: performanceAllocationPerc / 100,
      payBudgetAllocation: rangeAllocationPerc / 100
    };

    const matrixCells = [...state.meritMatrix.matrixCells];
    // const persist = obj => JSON.parse(JSON.stringify(obj));
    //
    //
    //
    //
    //

    matrixCalcCore.calculateIncreaseMultipliers(matrixGroupInfo, performanceBuckets, payBuckets);

    matrixCalcCore.calculate(matrixGroupInfo, matrixCells, performanceBuckets, payBuckets);

    dispatch({
      type: 'MATRIX_CALC',
      matrixCells,
      payBuckets,
      performanceBuckets,
      isPreset,
      preset
    });
  };
}

export const presetClick = event => (dispatch, getState) => {
  const preset = event.target.dataset.preset;

  const presetLookup = {
    conservative: { payBucketsVals: [60, 55, 50, 45, 40], eeEvaluationBucketsVals: [55, 50, 45] },
    moderate: { payBucketsVals: [70, 56, 46, 37, 30], eeEvaluationBucketsVals: [75, 40, 25] },
    aggressive: { payBucketsVals: [95, 60, 35, 15, 5], eeEvaluationBucketsVals: [95, 40, 5] }
  };
  const params = presetLookup[preset];
  params.preset = preset;
  dispatch(calculateMatrixModel({ preset }));
  dispatch(saveMatrixModel());
};

export const saveMatrixModel = () => (dispatch, getState) => {
  const postData = getGroupModelDataFromState(getState());
  //
  //Saves and returns summary stats
  dispatch({
    types: ['SAVE_MATRIX_GROUP_MODEL', 'SAVE_MATRIX_GROUP_MODEL_SUCCESS', 'SAVE_MATRIX_GROUP_MODEL_FAIL'],
    promise: client => client.post('/api/matrix-model/saveMatrixModel', { data: postData })
  });
};

export const onSliderDrop = event => (dispatch, getState) => {
  dispatch(calculateMatrixModel(event));
  dispatch(saveMatrixModel());
};

export const getGroupModelDataFromState = state => {
  const { performanceBuckets, payBuckets, matrixCells } = state.meritMatrix;
  const { matrixSetId } = state.meritMatrix.groupList;
  const { matrixRangeType, preset, matrixGroupId, fieldValues, employeeCount } = state.matrixGroupModel;
  let { budgetIncreasePct } = state.basePayCalc;
  if (!budgetIncreasePct) budgetIncreasePct = 0;
  const { totalBudgetPct } = state.headStats;
  const { performanceAllocationPerc, rangeAllocationPerc } = state.meritMatrix.allocation;

  const groupModel = {
    performanceBuckets,
    payBuckets,
    matrixRangeType,
    preset,
    fieldValues,
    employeeCount,
    budgetIncreasePercent: budgetIncreasePct / 100,
    performanceBudgetAllocation: performanceAllocationPerc / 100,
    payBudgetAllocation: rangeAllocationPerc / 100
  };

  return {
    matrixSetId,
    matrixGroupId,
    groupModel,
    cells: matrixCells,
    defaultBudgetIncreasePercent: totalBudgetPct / 100
  };
};
