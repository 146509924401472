export const closeHelpForm = (): ActionCreator<void> => {
    return (dispatch: Function, getState: Function): void => {
        dispatch({type: CLOSE_HELP_FORM});
        dispatch({type: SHOW_SCROLL_BAR});
    }
};

export const showHelpForm = (): ActionCreator<void> => {
    return (dispatch: Function, getState: Function): void => {
        dispatch({type: HIDE_SCROLL_BAR});
        dispatch({type: SHOW_HELP_FORM});
    }
};

export const closeUploadModal = (): DataAction =>{
  return {
    type: CLOSE_UPLOAD_MODAL
  };
};

export const showUploadModal = (): DataAction => {
  return {
    type: SHOW_UPLOAD_MODAL
  };
};

export const headerSearchButtonClick = (): DataAction => {
  return {
    type: SHOW_FULL_SEARCH_BAR
  };
};

export const closeFullSearchBar = (): ActionCreator<void> => {
    return (dispatch: Function, getState: Function): void => {
        dispatch({
            type: HIDE_FULL_SEARCH_BAR
        });
        setTimeout(() => dispatch({ type: REMOVE_FULL_SEARCH_STYLES }), 400);
    }
};

export const adminButtonClick = (): DataAction => {
  return {
    type: SHOW_ADMIN_BAR
  };
};

export const closeAdminBar = (): DataAction => {
  return {
    type: HIDE_ADMIN_BAR
  };
};

export const hideScrollBar = (): DataAction => {
  return {
    type: HIDE_SCROLL_BAR
  };
};

export const showScrollBar = (): DataAction => {
  return {
    type: SHOW_SCROLL_BAR
  };
};

export const downloadFiles = (): ActionCreator<void> => {
    return (dispatch: Function, getState: Function): void => {
        dispatch({
            type: FILE_DOWNLOAD
          });
          const csrf_token = getState().app.csrf_token;
          const csrf_timestamp = getState().app.csrf_timestamp;
          // TODO PSP-23861 evaluate usage of csrf_token on address bar
          // App is technicalliy a global variable
          // eslint-disable-next-line no-undef
          window.location.href = `${window.app.apiPrefix}api/etl/downloads/downloadFiles?csrf_token=${csrf_token}&csrf_timestamp=${csrf_timestamp}`;
    }
};

export const CLOSE_HELP_FORM = 'CLOSE_HELP_FORM';
export const SHOW_SCROLL_BAR = 'SHOW_SCROLL_BAR';
export const HIDE_SCROLL_BAR = 'HIDE_SCROLL_BAR';
export const SHOW_HELP_FORM = 'SHOW_HELP_FORM';
export const CLOSE_UPLOAD_MODAL = 'CLOSE_UPLOAD_MODAL';
export const SHOW_UPLOAD_MODAL = 'SHOW_UPLOAD_MODAL';
export const SHOW_FULL_SEARCH_BAR = 'SHOW_FULL_SEARCH_BAR';
export const HIDE_FULL_SEARCH_BAR = 'HIDE_FULL_SEARCH_BAR';
export const REMOVE_FULL_SEARCH_STYLES = 'REMOVE_FULL_SEARCH_STYLES';
export const SHOW_ADMIN_BAR = 'SHOW_ADMIN_BAR';
export const HIDE_ADMIN_BAR = 'HIDE_ADMIN_BAR';
export const FILE_DOWNLOAD = 'FILE_DOWNLOAD';