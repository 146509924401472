import * as d3 from 'd3';

export default function drawChart(config) {
  const {
    width,
    height,
    barHeight,
    labelToBarSpacingBottom: labelSpaceBottom,
    labelToBarSpacingTop: labelSpaceTop,
    id,
    labelToLabelMinSpacing,
    labelsVisible,
    highVariance,
    topLabelFormatter,
    bottomLabelFormatter,
    hideTopLabels,
    hideBottomLabels,
    invertLabels,
    labelTextAnchor,
    roundToNearest,
    payType,
    topLabelFontWeight,
    bottomLabelFontWeight,
    hasPointer,
    hasLine,
    lineStartingPosition,
    lineHeight,
    textFillColor,
    alwaysShowLabels
  } = config;

  let colors = ['#22d6dd', '#a7eff1', '#00aaa4', '#7e4ab2']; // eslint-disable-line prefer-const
  if (config.colors) {
    colors = config.colors;
  }

  let animateDuration = 1000;
  let textAnimationDuration = 400;
  if (config.animate === false) {
    animateDuration = 0;
    textAnimationDuration = 0;
  }

  const sidePadding = 10;
  const contentWidth = width - 2 * sidePadding;
  const centerLineWidth = contentWidth * 0.01;
  const barY = height / 2 - barHeight / 2;

  //support array of data
  if (!config.data[1]) {
    d3.select('.survey-range-chart-' + id)
      .attr('width', width)
      .attr('height', height)
      .selectAll('*')
      .remove();
    return function empty() {};
  }

  let no25th = false;
  if (!config.data[0] && config.data[1]) {
    config.data[0] = config.data[1] * 0.85;
    no25th = true;

    // if the fake 25th is the lowest point, scale it down
    if (config.data[0] < config.scaleMin) {
      config.data[0] = config.data[1] * 0.95;
      //  finally adjust the min
      if (config.data[0] < config.scaleMin) {
        config.scaleMin = Math.round(config.data[0]);
      }
    }
  }

  let no75th = false;
  if (!config.data[2] && config.data[1]) {
    config.data[2] = config.data[1] * 1.15;
    no75th = true;

    // if the fake 75th is the highest point, scale it down
    if (config.data[2] > config.scaleMax) {
      config.data[2] = config.data[1] * 1.05;
      //  finally adjust the max
      if (config.data[2] > config.scaleMax) {
        config.scaleMax = Math.round(config.data[2]);
      }
    }
  }

  const pixPerValRatio = contentWidth / (config.scaleMax - config.scaleMin);

  const xData = config.data.map(value => (value - config.scaleMin) * pixPerValRatio + sidePadding);
  //find x value of market avg.
  let data = [
    {
      low: config.data[0],
      high: config.data[1]
    },
    {
      low: config.data[1],
      high: config.data[2]
    }
  ];

  const topLabelY = height / 2 - barHeight / 2 - labelSpaceTop;
  const bottomLabelY = height / 2 + barHeight / 2 + labelSpaceBottom;

  let topLabelData = [];

  if (!hideTopLabels) {
    topLabelData = config.data.map((val, index, arr) => {
      return {
        val,
        labelText: topLabelFormatter(val, index, { array: arr, roundToNearest, payType }),
        x: xData[index],
        y: invertLabels ? bottomLabelY : topLabelY,
        fontWeight: config.topLabelFontWeight,
        textAnchor: labelTextAnchor[index]
      };
    });
  }

  let bottomLabelData = [];

  if (hideBottomLabels !== true) {
    bottomLabelData = config.data.reduce((labelArr, val, idx, arr) => {
      // Apply the N/A tags if we're missing data.
      switch (idx) {
        case 0:
          // 25th
          val = no25th ? 'N/A' : val;
          break;
        case 1:
          // 50th
          break;
        case 2:
          // 75th
          val = no75th ? 'N/A' : val;
          break;
      }

      const label = {
        val: val,
        labelText: bottomLabelFormatter(val, idx, { array: arr, roundToNearest, payType }),
        x: xData[idx],
        y: invertLabels ? topLabelY : bottomLabelY,
        fontWeight: config.bottomLabelFontWeight,
        textAnchor: labelTextAnchor[idx]
      };
      if (idx) {
        //properly space labels that are too close together
        const prevLabel = labelArr[idx - 1];
        const minimumXValue = prevLabel.x + labelToLabelMinSpacing;
        label.x = Math.max(minimumXValue, xData[idx]);
      }
      labelArr.push(label);
      return labelArr;
    }, []);
  }

  const labelData = topLabelData.concat(bottomLabelData);

  data.forEach((item, index) => {
    item.x = (item.low - config.scaleMin) * pixPerValRatio + sidePadding;
    item.width = (item.high - config.scaleMin) * pixPerValRatio - item.x + sidePadding;
    // make skinny white line in the middle
    item.x += !index ? 0 : centerLineWidth / 2;
    item.width -= centerLineWidth / 2;
    item.width = item.width >= 0 ? item.width : 0;
  });

  if (hasLine) {
    const startPosition = lineStartingPosition ? lineStartingPosition : 0;
    let lineData = [
      {
        x: startPosition,
        width: data[0].x + -startPosition,
        height: lineHeight ? lineHeight : 3,
        color: '#E6E6E6',
        y: height / 2 - 1.5
      },
      {
        x: data[data.length - 1].x + data[data.length - 1].width,
        width: width - data[data.length - 1].x - data[data.length - 1].width,
        height: lineHeight ? lineHeight : 3,
        color: '#E6E6E6',
        y: height / 2 - 1.5
      }
    ];

    data = data.concat(lineData);
  }

  // set any widths to 0 if they are negative
  data.forEach(item => {
    if (item.width < 0) item.width = 0;
  });

  const svg = d3
    .select('.survey-range-chart-' + id)
    .attr('width', width)
    .attr('height', height);

  const nodes = svg.selectAll('rect').data(data);
  nodes
    .enter()
    .append('rect')
    .classed('pointer', config.hasPointer);

  if (highVariance) {
    svg
      .append('defs')
      .append('pattern')
      .attr({
        id: 'hash4_4',
        width: '8',
        height: '8',
        patternUnits: 'userSpaceOnUse',
        patternTransform: 'rotate(45)'
      })
      .append('rect')
      .attr({ width: '4', height: '8', transform: 'translate(0,0)', fill: '#FFF' });

    svg
      .append('rect')
      .attr('x', 0)
      .attr('y', 0)
      .attr('width', width)
      .attr('height', height)
      .attr('fill', 'url(#hash4_4)');
  }

  nodes
    .attr('width', 0)
    .style({
      fill: function colorsFunc(d, i) {
        let currentColor = d.color ? d.color : colors[i];
        if (no25th) {
          currentColor = '#e6e6e6';
        }
        if (no75th) {
          currentColor = '#EFEFEF';
        }

        return currentColor;
      }
    })
    .attr('x', (d, i) => d.x)
    .attr('y', (d, i) => (d.y ? d.y : barY))
    .transition()
    .duration(animateDuration)
    .attr('width', (d, i) => d.width)
    .attr('height', (d, i) => (d.height ? d.height : config.barHeight));

  function toggleLabels(show, width) {
    if (show) {
      appendLabels(true, width);
    } else {
      const labels = svg.selectAll('text').data([]);
      labels
        .exit()
        .transition()
        .duration(textAnimationDuration)
        .style({ 'fill-opacity': 0.0 })
        .remove();
    }
  }

  function appendLabels(showAnimation, width) {
    const labels = svg
      .selectAll('text')
      .interrupt()
      .data(labelData);
    labels.enter().append('text');

    if (showAnimation) {
      labels.style({ 'fill-opacity': 0.0 });
    }
    labels
      .attr('text-anchor', d => d.textAnchor)
      .attr('x', (d, i) => {
        if (d.x > width) return width;
        return d.x < 0 ? 0 : d.x;
      })
      .attr('y', (d, i) => {
        return d.y;
      })
      // .attr('fill', 'gray')
      .attr({
        'font-family': 'Roboto, Helvetica Neue, sans-serif',
        'font-size': config.labelFontSize + 'px',
        'font-weight': d => d.fontWeight,
        fill: config.textFillColor
      })
      .text(d => {
        return d.labelText;
      });

    if (showAnimation) {
      labels.transition().duration(textAnimationDuration);
    }

    labels.style({ 'fill-opacity': 1 });
  }

  if (alwaysShowLabels) appendLabels(false, width);

  if (!alwaysShowLabels && labelsVisible) toggleLabels(labelsVisible, width);

  return toggleLabels;
}
