import { buildParams, getAuditHistory } from '../../../../../panther-react/src/reducers/modules/jobs/jobActions';
import {
  getDefaultMarketRateField,
  getChartColorByCurrencyScheme,
  getCurrencySchemeName,
  redirectToPdf
} from '../../../../../panther-react/src/helpers/utils';
import apiClient from '../../../lib/apiClient';

const RESET_SCROLL = 'RESET_SCROLL';
const SURVEY_SEARCH_SUCCESS = 'SURVEY_SEARCH_SUCCESS';
const JOB_DOWNLOAD_SUCCESS = 'JOB_DOWNLOAD_SUCCESS';
const JOB_DOWNLOAD_FAIL = 'JOB_DOWNLOAD_FAIL';
const JOB_DOWNLOAD = 'JOB_DOWNLOAD';
const JOB_DETAIL_PAY_CHANGE = 'JOB_DETAIL_PAY_CHANGE';
const LOAD_JOB_SURVEY_MATCH_DATA_CUTS = 'LOAD_JOB_SURVEY_MATCH_DATA_CUTS';
const LOAD_JOB_SURVEY_MATCH_DATA_CUTS_SUCCESS = 'LOAD_JOB_SURVEY_MATCH_DATA_CUTS_SUCCESS';
const LOAD_JOB_SURVEY_MATCH_DATA_CUTS_FAIL = 'LOAD_JOB_SURVEY_MATCH_DATA_CUTS_FAIL';
const GET_CROWD_SOURCED_SURVEY_DATA = 'GET_CROWD_SOURCED_SURVEY_DATA';
const GET_CROWD_SOURCED_SURVEY_DATA_SUCCESS = 'GET_CROWD_SOURCED_SURVEY_DATA_SUCCESS';
const GET_CROWD_SOURCED_SURVEY_DATA_FAIL = 'GET_CROWD_SOURCED_SURVEY_DATA_FAIL';
const GET_COMPANY_SOURCED_COMPANIES_BY_JOB = 'GET_COMPANY_SOURCED_COMPANIES_BY_JOB';
const GET_COMPANY_SOURCED_COMPANIES_BY_JOB_SUCCESS = 'GET_COMPANY_SOURCED_COMPANIES_BY_JOB_SUCCESS';
const GET_COMPANY_SOURCED_COMPANIES_BY_JOB_FAIL = 'GET_COMPANY_SOURCED_COMPANIES_BY_JOB_FAIL';
const JOBS_REMOVE_ALL_SELECTED = 'JOBS_REMOVE_ALL_SELECTED';
const JOB_RANGE_CHART_LOAD = 'JOB_RANGE_CHART_LOAD';
const JOB_RANGE_CHART_FAIL = 'JOB_RANGE_CHART_FAIL';
const JOB_RANGE_CHART_SUCCESS = 'JOB_RANGE_CHART_SUCCESS';
const JOB_DETAILS = 'JOB_DETAILS';
const JOB_DETAILS_SUCCESS = 'JOB_DETAILS_SUCCESS';
const JOB_DETAILS_FAILURE = 'JOB_DETAILS_FAILURE';
const SURVEY_SEARCH = 'SURVEY_SEARCH';
const JOB_CHANGE_STATUS = 'JOB_CHANGE_STATUS';
const JOB_CHANGE_STATUS_SUCCESS = 'JOB_CHANGE_STATUS_SUCCESS';
const JOB_CHANGE_STATUS_FAIL = 'JOB_CHANGE_STATUS_FAIL';
const SHOW_EDIT_SURVEYS = 'SHOW_EDIT_SURVEYS';
const SHOW_EDIT_SURVEYS_SUCCESS = 'SHOW_EDIT_SURVEYS_SUCCESS';
const SHOW_EDIT_SURVEYS_FAILURE = 'SHOW_EDIT_SURVEYS_FAILURE';
const CLOSE_EDIT_SURVEYS = 'CLOSE_EDIT_SURVEYS';
const SURVEY_LIST_HOVER = 'SURVEY_LIST_HOVER';
const FETCH_SURVEYS = 'FETCH_SURVEYS';
const FETCH_SURVEYS_SUCCESS = 'FETCH_SURVEYS_SUCCESS';
const ADD_MATCH_SUCCESS = 'ADD_MATCH_SUCCESS';
const REMOVE_MATCH_SUCCESS = 'REMOVE_MATCH_SUCCESS';
const SAVE_PROFILES_ANALYZED_SUCCESS = 'SAVE_PROFILES_ANALYZED_SUCCESS';
const SAVE_PROFILES_ANALYZED_FAIL = 'SAVE_PROFILES_ANALYZED_FAIL';
const FETCH_SURVEYS_FAILURE = 'FETCH_SURVEYS_FAILURE';
const HIDE_SCROLL_BAR = 'HIDE_SCROLL_BAR';
const SHOW_SCROLL_BAR = 'SHOW_SCROLL_BAR';
const JOBS_SHOW_DELETE_MODAL = 'JOBS_SHOW_DELETE_MODAL';
const CLEAR_SURVEY_SEARCH_RESULT = 'CLEAR_SURVEY_SEARCH_RESULT';
const SAVE_PROFILES_ANALYZED_LOAD = 'SAVE_PROFILES_ANALYZED_LOAD';
export const JOB_DETAILS_SINGLE_START_LOAD = 'JOB_DETAILS_SINGLE_START_LOAD';

import * as jobActions from '../../../../../panther-react/src/reducers/modules/jobs/jobActions';

const initialState = {
  loading: false,
  loaded: false,
  chartLoading: true,
  error: null,
  details: {},
  jobIdList: [],
  showEditSurveys: false,
  surveyHoverState: false,
  loadingJobId: null,
  tccIncludedOnAllSurveys: true,
  totalSurveyCount: 0,
  tccIncludedSurveyCount: 0,
  payDisplayType: 'Base Salary',
  defaultActiveKey: 'baseSalary',
  saveProfilesAnalyzed: 'profilesAnalyzed', // default to profiles analyzed, can also be companyEmployeeData
  companyMatchDataCutsLoading: false,
  companyMatchDataCuts: null,
  crowdSourcedCompaniesByJob: [],
  companySourcedCompaniesByJob: [],
  crowdSourcedReportsLoading: false,
  companySourcedCompaniesListLoading: false,
  surveyDataSummary: [],
  showJobMergeModal: false
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case JOBS_REMOVE_ALL_SELECTED:
      return {
        ...state,
        jobIdList: []
      };
    case JOB_RANGE_CHART_LOAD:
      if (!isActionForCurrentJob(action.itemId, state.loadingJobId)) {
        return {
          ...state
        };
      }

      return {
        ...state,
        chartLoading: true,
        baseChartData: null,
        tccChartData: null,
        gradeChartData: null,
        error: null
      };
    case JOB_RANGE_CHART_FAIL:
      if (!isActionForCurrentJob(action.itemId, state.loadingJobId)) {
        return {
          ...state
        };
      }

      return {
        ...state,
        chartLoading: false,
        error: action.result
      };
    case JOB_RANGE_CHART_SUCCESS:
      if (typeof action.result.job_id === 'number') {
        action.result.job_id = [action.result.job_id];
      }

      if (
        (action.result.job_id &&
          state.loadingJobId &&
          action.result.job_id.toString() !== state.loadingJobId.toString()) ||
        state.loadingJobId === null
      ) {
        return {
          ...state,
          chartLoading: false
        };
      }

      let paramsAnnualOrHourly = null;
      if (action.params) {
        paramsAnnualOrHourly = action.params.params.annual_or_hourly;
      }

      let jobRangeSuccessJobDetails = undefined;
      if (state.details && state.details.payscale_job_info) {
        jobRangeSuccessJobDetails = state.details.payscale_job_info;
      }

      let payscaleAnnualOrHourly = null;
      if (jobRangeSuccessJobDetails) {
        payscaleAnnualOrHourly = jobRangeSuccessJobDetails.annualOrHourly;
      }

      let annualOrHourly = null;
      annualOrHourly = paramsAnnualOrHourly || payscaleAnnualOrHourly;

      let chartResMarketFields = undefined;
      if (
        state.details &&
        state.details.payscale_job_info &&
        state.details.payscale_job_info.countryCode &&
        window.app?.currencies
      ) {
        chartResMarketFields = getCurrencySchemeName(
          window.app.currencies,
          state.details.payscale_job_info.countryCode
        );
      }

      let returnObj = {
        ...state,
        baseChartData: action.result.baseChartData,
        tccChartData: action.result.tccChartData,
        tgpChartData: action.result.tgpChartData,
        tcoeChartData: action.result.tcoeChartData,
        gradeChartData: action.result.gradeChartData,
        jobIdList: action.result.job_id,
        chartLoading: false,
        details: {
          ...state.details,
          payscale_job_info: {
            ...jobRangeSuccessJobDetails,
            annualOrHourly: annualOrHourly || 'annual'
          }
        }
      };
      if (!chartResMarketFields) {
        return returnObj;
      }

      let jobMarketRateField = undefined;
      if (Array.isArray(action.result.jobTitles) && action.result.jobTitles.length) {
        jobMarketRateField = action.result.jobTitles[0].market_rate_field;
      }

      const marketRateField = getDefaultMarketRateField(chartResMarketFields.currencySchemeName, jobMarketRateField);

      const chartColors = getChartColorByCurrencyScheme(chartResMarketFields.currencySchemeName, state.payDisplayType);

      returnObj.details.payscale_job_info.showMarketRateField = chartResMarketFields.showMarketRateField;
      returnObj.details.payscale_job_info.currencyScheme = chartResMarketFields.currencySchemeName;
      returnObj.chartColors = chartColors;
      returnObj.details.payscale_job_info.marketRateField = marketRateField;
      return returnObj;

    case JOB_DETAILS: {
      if (!isActionForCurrentJob(action.itemId, state.loadingJobId)) {
        return {
          ...state
        };
      }

      const jobId = action.itemId || action.item._id;

      return {
        ...state,
        details: action.item ? { _id: action.item._id, ...action.item._source } : {},
        loading: action.skipLoad ? false : true,
        jobIdList: [parseInt(jobId, 10)],
        payDisplayType: 'Base Salary'
      };
    }
    case JOB_DETAILS_SUCCESS: {
      if (!isActionForCurrentJob(action.itemId, state.loadingJobId)) {
        return {
          ...state
        };
      }

      let countryCode = null;
      if (!action.result || (action.result && action.result.success === false)) {
        console.log('JOB_DETAILS_SUCCESS fail- item: ', action.item, 'itemId', action.itemId);
      }
      if (action.result.payscale_job_info && action.result.payscale_job_info.countryCode) {
        countryCode = action.result.payscale_job_info.countryCode;
      }

      let jobDetailResMarketFields = null;
      if (countryCode && window.app?.currencies) {
        jobDetailResMarketFields = getCurrencySchemeName(window.app.currencies, countryCode);
      } else {
        // No scheme, can't get market rate field, try to return whatever is left without crashing
        return {
          ...state,
          loading: false,
          loaded: true,
          details: {
            ...state.details,
            ...action.result.payscale_job_info,
            ...action.result,
            payscale_job_info: {
              ...action.result.payscale_job_info
            }
          }
        };
      }

      let rateField = '';
      if (action.result.payscale_job_info && !action.result.payscale_job_info.marketRateField) {
        rateField = getDefaultMarketRateField(jobDetailResMarketFields.currencySchemeName, null);
      } else {
        rateField = action.result.payscale_job_info.marketRateField;
      }

      return {
        ...state,
        loading: false,
        loaded: true,
        details: {
          ...state.details,
          ...action.result.payscale_job_info,
          ...action.result,
          payscale_job_info: {
            ...action.result.payscale_job_info,
            showMarketRateField: jobDetailResMarketFields.showMarketRateField || null,
            marketRateField: rateField,
            currencyScheme: jobDetailResMarketFields.currencySchemeName || null
          }
        }
      };
    }
    case JOB_DETAILS_FAILURE: {
      if (!isActionForCurrentJob(action.itemId, state.loadingJobId)) {
        return {
          ...state
        };
      }

      return {
        ...state,
        details: {
          ...state.details,
          prevStatus: null,
          workflow_status: state.details.prevStatus
        },
        error: action.error
      };
    }
    case SURVEY_SEARCH_SUCCESS:
      return {
        ...state,
        setScroll: true
      };
    case SURVEY_SEARCH:
      return {
        ...state,
        setScroll: true
      };
    case RESET_SCROLL:
      return { ...state, setScroll: false };
    case JOB_CHANGE_STATUS:
      return {
        ...state,
        details: {
          ...state.details,
          prevStatus: state.details.workflow_status,
          workflow_status: action.newStatus
        }
      };
    case JOB_CHANGE_STATUS_SUCCESS:
      return {
        ...state,
        details: {
          ...state.details,
          prevStatus: null,
          workflow_status: action.result.success ? action.result.workflowStatus : state.details.prevStatus
        }
      };
    case JOB_CHANGE_STATUS_FAIL:
      return {
        ...state,
        details: {
          ...state.details,
          prevStatus: null,
          workflow_status: state.details.prevStatus
        }
      };
    case SHOW_EDIT_SURVEYS:
      return {
        ...state,
        showEditSurveys: false
      };
    case SHOW_EDIT_SURVEYS_SUCCESS:
      return {
        ...state,
        showEditSurveys: true
      };
    case SHOW_EDIT_SURVEYS_FAILURE:
      return {
        ...state,
        showEditSurveys: false
      };
    case CLOSE_EDIT_SURVEYS:
      return {
        ...state,
        showEditSurveys: false
      };
    case SURVEY_LIST_HOVER:
      return {
        ...state,
        surveyHoverState: action.on
      };
    case JOB_DOWNLOAD:
      return {
        ...state,
        printPageDownloading: true
      };
    case JOB_DOWNLOAD_SUCCESS:
      return {
        ...state,
        printPageDownloading: false
      };
    case JOB_DOWNLOAD_FAIL:
      return {
        ...state,
        printPageDownloading: false
      };
    case LOAD_JOB_SURVEY_MATCH_DATA_CUTS: {
      return {
        ...state,
        companyMatchDataCutsLoading: true
      };
    }
    case LOAD_JOB_SURVEY_MATCH_DATA_CUTS_SUCCESS:
      //  TODO: What should happen if data fails to be retrieved ???
      const data = action.result && action.result.success !== false ? action.result : null;

      return {
        ...state,
        companyMatchDataCutsLoading: false,
        companyMatchDataCuts: data
      };
    case LOAD_JOB_SURVEY_MATCH_DATA_CUTS_FAIL:
      //  TODO: What should happen if data fails to be retrieved ???
      return {
        ...state,
        companyMatchDataCutsLoading: false,
        companyMatchDataCuts: null
      };
    case FETCH_SURVEYS:
      if (!isActionForCurrentJob(action.itemId, state.loadingJobId)) {
        return {
          ...state
        };
      }

      return {
        ...state,
        tccIncludedOnAllSurveys: true
      };
    case FETCH_SURVEYS_SUCCESS: {
      if (!isActionForCurrentJob(action.itemId, state.loadingJobId)) {
        return {
          ...state
        };
      }

      const surveyTCCStats = getSurveyTCCStats(action);

      return {
        ...state,
        ...surveyTCCStats
      };
    }
    case ADD_MATCH_SUCCESS: {
      const surveyAddMatchTCCStats = getSurveyTCCStats(action);

      return {
        ...state,
        ...surveyAddMatchTCCStats
      };
    }
    case REMOVE_MATCH_SUCCESS: {
      const surveyRemoveMatchTCCStats = getSurveyTCCStats(action);

      return {
        ...state,
        ...surveyRemoveMatchTCCStats
      };
    }
    case SAVE_PROFILES_ANALYZED_SUCCESS: {
      return {
        ...state,
        details: {
          ...state.details,
          payscale_job_info: {
            ...state.details.payscale_job_info,
            saveProfilesAnalyzed: action.result.saveProfilesAnalyzed
          }
        }
      };
    }
    case SAVE_PROFILES_ANALYZED_FAIL: {
      return {
        ...state,
        details: {
          ...state.details,
          payscale_job_info: {
            ...state.details.payscale_job_info,
            saveProfilesAnalyzed: 'profilesAnalyzed'
          }
        },
        error: action.error
      };
    }
    case FETCH_SURVEYS_FAILURE:
      if (!isActionForCurrentJob(action.itemId, state.loadingJobId)) {
        return {
          ...state
        };
      }

      return {
        ...state,
        tccIncludedOnAllSurveys: true
      };
    case GET_CROWD_SOURCED_SURVEY_DATA:
      if (!isActionForCurrentJob(action.itemId, state.loadingJobId)) {
        return {
          ...state
        };
      }

      return {
        ...state,
        crowdSourcedReportsLoading: true
      };
    case GET_CROWD_SOURCED_SURVEY_DATA_SUCCESS:
      if (!isActionForCurrentJob(action.itemId, state.loadingJobId)) {
        return {
          ...state
        };
      }

      let crowdSourcedCompaniesByJob = [];
      let surveyDataSummary = [];
      if (action.result) {
        crowdSourcedCompaniesByJob =
          action.result.companiesByJobReport &&
          action.result.companiesByJobReport.report &&
          action.result.companiesByJobReport.report.companies
            ? action.result.companiesByJobReport.report.companies
            : [];

        surveyDataSummary =
          action.result.dataSummaryReport &&
          action.result.dataSummaryReport.report &&
          action.result.dataSummaryReport.report.subReports
            ? action.result.dataSummaryReport.report.subReports
            : [];
      }
      return {
        ...state,
        crowdSourcedCompaniesByJob: crowdSourcedCompaniesByJob,
        crowdSourcedReportsLoading: false,
        surveyDataSummary: surveyDataSummary
      };
    case GET_CROWD_SOURCED_SURVEY_DATA_FAIL:
      if (!isActionForCurrentJob(action.itemId, state.loadingJobId)) {
        return {
          ...state
        };
      }

      return {
        ...state,
        crowdSourcedCompaniesByJob: [],
        crowdSourcedReportsLoading: false
      };
    case GET_COMPANY_SOURCED_COMPANIES_BY_JOB:
      if (!isActionForCurrentJob(action.itemId, state.loadingJobId)) {
        return {
          ...state
        };
      }

      return {
        ...state,
        companySourcedCompaniesListLoading: true
      };
    case GET_COMPANY_SOURCED_COMPANIES_BY_JOB_SUCCESS:
      if (!isActionForCurrentJob(action.itemId, state.loadingJobId)) {
        return {
          ...state
        };
      }

      const companySourcedCompaniesByJob =
        action.result && action.result.success !== false ? action.result : action.companySourcedCompaniesByJob || [];
      return {
        ...state,
        companySourcedCompaniesByJob: companySourcedCompaniesByJob,
        companySourcedCompaniesListLoading: false
      };
    case GET_COMPANY_SOURCED_COMPANIES_BY_JOB_FAIL:
      if (!isActionForCurrentJob(action.itemId, state.loadingJobId)) {
        return {
          ...state
        };
      }

      return {
        ...state,
        companySourcedCompaniesByJob: [],
        companySourcedCompaniesListLoading: false
      };
    case JOB_DETAIL_PAY_CHANGE: {
      let payDisplayType = 'Base Salary';
      if (action.tab === 'totalCashPaid') {
        payDisplayType = 'TCC';
      } else if (action.tab === 'totalGuaranteedPay') {
        payDisplayType = 'Total Guaranteed Pay';
      } else if (action.tab === 'totalCostOfEmployment') {
        payDisplayType = 'Total Cost of Employment';
      }

      let currencySchema;
      if (state.details && state.details.payscale_job_info && state.details.payscale_job_info.currencyScheme) {
        currencySchema = state.details.payscale_job_info.currencyScheme;
      }
      const colors = getChartColorByCurrencyScheme(currencySchema, payDisplayType);

      return {
        ...state,
        payDisplayType,
        chartColors: colors,
        defaultActiveKey: action.tab
      };
    }
    case JOB_DETAILS_SINGLE_START_LOAD:
      return {
        ...state,
        loadingJobId: action.id,
        loading: true
      };
    default:
      return { ...state };
  }
}

export function resetScroll() {
  return { type: RESET_SCROLL };
}

const getSurveyTCCStats = function(action) {
  let totalSurveyCount = 0; // eslint-disable-line prefer-const
  let tccIncludedSurveyCount = 0; // eslint-disable-line prefer-const

  if (action.result && action.result.surveyJobMatches) {
    totalSurveyCount = action.result.surveyJobMatches.length;
    for (let surveyCnt = 0; surveyCnt < totalSurveyCount; surveyCnt++) {
      if (action.result.surveyJobMatches[surveyCnt].total_cash_paid_50th) {
        tccIncludedSurveyCount++;
      }
    }
  }

  // simple boolean to avoid logic in component
  const tccIncludedOnAllSurveys = totalSurveyCount === tccIncludedSurveyCount;

  return {
    totalSurveyCount,
    tccIncludedSurveyCount,
    tccIncludedOnAllSurveys
  };
};

export function changeStatus(info) {
  return async (dispatch, getState) => {
    const { newStatus, id } = info;

    try {
      dispatch({ type: JOB_CHANGE_STATUS });
      const result = await apiClient.apiGet('/api/hris/job-details/changeStatus', { params: info });

      dispatch({ type: JOB_CHANGE_STATUS_SUCCESS, result: result.data, newStatus });

      dispatch(getAuditHistory(id));
    } catch (error) {
      dispatch({ type: JOB_CHANGE_STATUS_FAIL, error: error });
    }

    jobActions.markDirtyJob(dispatch, getState(), id);
  };
}

export function showEditSurveysFn(jobId) {
  return async (dispatch, getState) => {
    if (jobId) {
      try {
        dispatch({ type: SHOW_EDIT_SURVEYS });
        const result = await apiClient.apiGet('/api/market-pricing/job-matches/getSurveyJobs', {
          params: {
            org_job_id: jobId,
            orgJobId: jobId,
            showSuggestions: true
          }
        });
        dispatch({ type: SHOW_EDIT_SURVEYS_SUCCESS, result: result.data });
        dispatch({ type: HIDE_SCROLL_BAR });
      } catch (error) {
        dispatch({ type: SHOW_EDIT_SURVEYS_FAILURE, error: error });
      }
    }
  };
}

export function closeEditSurveysFn(jobId) {
  const params = buildParams(jobId);

  return async dispatch => {
    dispatch(getAuditHistory(jobId));

    try {
      dispatch({ type: FETCH_SURVEYS });
      const result = await apiClient.apiGet('/api/market-pricing/job-matches/getSurveyJobs', params);

      dispatch({ type: FETCH_SURVEYS_SUCCESS, result: result.data });
    } catch (error) {
      dispatch({ type: FETCH_SURVEYS_FAILURE, error: error });
    }

    dispatch({ type: CLOSE_EDIT_SURVEYS });
    dispatch({ type: SHOW_SCROLL_BAR });
    dispatch({ type: CLEAR_SURVEY_SEARCH_RESULT });
  };
}

export function jobDelete(options) {
  return {
    type: JOBS_SHOW_DELETE_MODAL,
    jobId: options.orgJobId
  };
}
export function jobDownload(options) {
  return (dispatch, getState) => {
    if (options.orgJobId) {
      dispatch({
        types: [JOB_DOWNLOAD, JOB_DOWNLOAD_SUCCESS, JOB_DOWNLOAD_FAIL],
        promise: client =>
          client
            .get('/api/util/report-print/printPage', {
              params: {
                org_job_id: options.orgJobId,
                download_type: 'pdf'
              }
            })
            .then(redirectToPdf)
            .catch(err => {
              console.error('Error downloading job', err);
            })
      });
    } else {
      // dispatch({type: 'SHOW_EDIT_SURVEYS'});
    }
  };
}

export function descriptionDownload(options) {
  let downloadLocation = '';
  if (window.app.hostname !== 'localhost') {
    downloadLocation = '/' + window.app.subsite;
  }

  downloadLocation += '/api/hris/job-details/downloadJobDescriptionFile?orgJobId=' + options.orgJobId;
  downloadLocation += '&csrf_token=' + window.app.csrf_token;
  downloadLocation += '&csrf_timestamp=' + window.app.csrf_timestamp;

  window.location = downloadLocation;
}

export function hover(on) {
  return {
    type: SURVEY_LIST_HOVER,
    on
  };
}

export function loadDetailsChart(jobId, annualOrHourly, marketRateField, tab) {
  const params = {
    params: {
      org_job_id: jobId,
      annual_or_hourly: annualOrHourly,
      market_rate_field: marketRateField
    }
  };
  return async (dispatch, getState) => {
    try {
      dispatch({ type: JOB_RANGE_CHART_LOAD });
      const result = await apiClient.apiGet('/api/hris/job-detail-charts/jobRanges', params);

      dispatch({ type: JOB_RANGE_CHART_SUCCESS, result: result.data, params });
      dispatch(jobDetailPayTabChange(tab));
    } catch (error) {
      dispatch({ type: JOB_RANGE_CHART_FAIL, error: error });
    }
  };
}

export function saveProfilesAnalyzed(orgJobId, saveProfilesAnalyzed) {
  const params = {
    params: {
      orgJobId: orgJobId,
      saveProfilesAnalyzed: saveProfilesAnalyzed
    }
  };

  return async (dispatch, getState) => {
    try {
      dispatch({ type: SAVE_PROFILES_ANALYZED_LOAD });
      const result = await apiClient.apiGet('/api/hris/jobs/saveProfilesAnalyzed', params);

      dispatch({ type: SAVE_PROFILES_ANALYZED_SUCCESS, result: result.data, params });
    } catch (error) {
      dispatch({ type: SAVE_PROFILES_ANALYZED_FAIL, error: error });
    }
  };
}

export function jobDetailPayTabChange(tab) {
  return {
    type: JOB_DETAIL_PAY_CHANGE,
    tab
  };
}

export function getJobSurveyCompanyMatchDataCuts(getState, surveyJobs) {
  let { jobSurveyList } = getState();
  if (surveyJobs && surveyJobs.length) {
    jobSurveyList.items = surveyJobs;
  }
  if (!jobSurveyList || !jobSurveyList.items) {
    return;
  }

  //  TODO: Is there a better way to find the company sourced match???
  for (let i = 0; i < jobSurveyList.items.length; i++) {
    const survey = jobSurveyList.items[i];
    if (survey.pub_survey_publisher === 'PayScale Company Sourced') {
      const params = { survey_code: survey.survey_code, job_code: survey.pub_job_code };
      return async dispatch => {
        try {
          dispatch({ type: LOAD_JOB_SURVEY_MATCH_DATA_CUTS });
          const result = await apiClient.apiGet('/api/elasticsearch/data-cuts/getSurveyJobDataCuts', {
            params: params
          });

          dispatch({ type: LOAD_JOB_SURVEY_MATCH_DATA_CUTS_SUCCESS, result: result.data, params });
        } catch (error) {
          dispatch({ type: LOAD_JOB_SURVEY_MATCH_DATA_CUTS_FAIL, error: error });
        }
      };
    }
  }
}

export function isActionForCurrentJob(jobId, loadingJobId) {
  return jobId !== null && loadingJobId !== null && parseInt(jobId) === parseInt(loadingJobId);
}
