/* eslint-disable no-case-declarations */
import { getGenericRow, getTransformedColumns } from './GenericGridFunctions';
import apiClient from '../../lib/apiClient';
import { getColumnIndex } from './GenericGridHelper';
import { formatCurrency } from '../../Utils/currencyUtils';

export const genericGridSuffix = {
  SEARCH_SUCCESS: '_GG_SEARCH_SUCCESS',
  SEARCH: '_GG_SEARCH',
  SEARCH_ERROR: '_GG_SEARCH_ERROR',
  CLEAR_RESULTS: '_GG_CLEAR_RESULTS'
};

const defaultInitialState = {
  processInitialLoad: true,
  loading: false,
  loaded: false,
  error: null,
  defaultLeftColumns: [],
  defaultRightColumns: [],
  tableProps: {
    columnHeaders: [],
    rowData: [],
    getDataRow: getGenericRow,
    noDataText: 'No Data',
    loadingText: 'Loading Grid...',
    dataLoading: false,
    totalCount: 0
  },
  hiddenTerms: [],
  // Request
  offset: 0,
  limit: 50,
  includeSummaryData: false,
  queryParams: {},
  orderBy: [],
  filter: {},
  // Response
  termGroups: [],
  terms: [],
  data: [],
  status: null,
  summaryData: [],
  searchData: {},
  metadata: {},
  marketComparisonRows: []
};

export default class GenericGridCreator {
  constructor(nameSpace, queryParams, initialState, options = {}) {
    this.GG_PAGINATION = `${nameSpace}_GG_PAGINATION`;
    this.GG_FILTER = `${nameSpace}_GG_FILTER`;
    this.GG_ORDER_BY = `${nameSpace}_GG_ORDER_BY`;
    this.GG_RESET = `${nameSpace}_GG_RESET`;
    this.GG_UPDATE_SPECIFIC = `${nameSpace}_GG_UPDATE_SPECIFIC`;
    this.GG_SEARCH = `${nameSpace + genericGridSuffix.SEARCH}`;
    this.GG_SEARCH_SUCCESS = `${nameSpace + genericGridSuffix.SEARCH_SUCCESS}`;
    this.GG_SEARCH_ERROR = `${nameSpace + genericGridSuffix.SEARCH_ERROR}`;
    this.GG_CLEAR_RESULTS = `${nameSpace + genericGridSuffix.CLEAR_RESULTS}`;

    this.nameSpace = nameSpace.toLowerCase();

    this.options = options;
    this.initialState = {
      ...defaultInitialState,
      ...initialState,
      tableProps: {
        ...defaultInitialState.tableProps,
        ...initialState.tableProps,
        ...options.tableProps,
        className: this.nameSpace + '__table',
        headerClassName: this.nameSpace + '__header',
        headerRowClassName: this.nameSpace + '__header-row',
        bodyClassName: this.nameSpace + '__body',
        rowClassName: this.nameSpace + '__row',
        hiddenColumns: initialState.hiddenTerms || defaultInitialState.hiddenTerms
      }
    };
    this.initialState.name = this.nameSpace;
    this.initialState.queryParams = queryParams;
    this.getInstanceState = options.getInstanceState;
  }

  getActions() {
    if (!this.actions) {
      this.actions = {
        paginate: this.paginate.bind(this),
        search: this.search.bind(this),
        clearResultsOnly: this.clearResultsOnly.bind(this),
        // Once sorting is supported,
        onSortChange: this.onSortChange.bind(this),
        reset: this.reset.bind(this),
        updateSpecificRowData: this.updateSpecificRowData.bind(this)
      };
    }
    return this.actions;
  }

  getInitialState() {
    return this.initialState;
  }

  createReducer(extendReducerFn, reduceInstanceParams) {
    if (reduceInstanceParams) {
      Object.assign(this, reduceInstanceParams);
    }

    return (state = this.initialState, action = {}) => {
      if (typeof extendReducerFn === 'function') {
        const newState = extendReducerFn(state, action);
        if (newState) return newState;
      }

      switch (action.type) {
        case this.GG_PAGINATION:
          return {
            ...state,
            limit: action.limit,
            offset: action.offset > 0 ? action.offset : 0
          };
        case this.GG_FILTER:
          return {
            ...state,
            filter: action.filter
          };
        case this.GG_ORDER_BY:
          // TODO: Implement logic to support multi-ordering
          return {
            ...state,
            orderBy: action.orderBy
          };
        case this.GG_SEARCH:
          return {
            ...state,
            loading: true,
            loaded: false,
            tableProps: {
              ...state.tableProps,
              dataLoading: true
            }
          };
        case this.GG_SEARCH_SUCCESS:
          const returnColumnsOnlySpecified = action.dontAffectLoadingState !== undefined;
          const isLoading = returnColumnsOnlySpecified ? action.dontAffectLoadingState : false;
          const isLoaded = returnColumnsOnlySpecified ? !action.dontAffectLoadingState : true;
          if (action?.result?.data) {
            action = { ...action.result.data };
          }

          const marketComparisonRows = [];
          const baseSalary50thIndex = getColumnIndex(action, 'base_salary_50th');
          const baseSalaryAvgIndex = getColumnIndex(action, 'base_salary_avg');
          const totalCash50thIndex = getColumnIndex(action, 'total_cash_paid_50th');
          const totalCashAvgIndex = getColumnIndex(action, 'total_cash_paid_avg');
          const baseSalaryIndex = getColumnIndex(action, 'base_salary');
          const totalCashAmtIndex = getColumnIndex(action, 'total_paid_cash_amt');

          const hasRequiredData =
            baseSalaryIndex !== -1 && totalCashAmtIndex !== -1 && baseSalaryAvgIndex !== -1 && totalCashAvgIndex !== -1;
          const hasMarketComparisonColumns = baseSalary50thIndex !== -1 || totalCash50thIndex !== -1;
          if (
            hasRequiredData &&
            hasMarketComparisonColumns &&
            action?.data?.length > 0 &&
            action?.summaryData?.length > 0
          ) {
            const currencyIndex = getColumnIndex(action, 'currency');
            const currency = currencyIndex !== -1 ? action.data[0][currencyIndex] : 'USD';
            marketComparisonRows.push(
              action.terms.map((term, idx) => {
                if (idx === baseSalary50thIndex || idx === totalCash50thIndex) {
                  return 'Internal Avg';
                } else if (idx === baseSalaryAvgIndex || idx === totalCashAvgIndex) {
                  return 'Mkt Ratio (50th)';
                }
                return null;
              })
            );

            const baseSalary = action.data[0][baseSalaryIndex];
            const totalCashAmt = action.data[0][totalCashAmtIndex];
            const percentRounding = action.metadata?.defaultRoundings?.percentRounding || 2;
            const basePayRatio =
              baseSalaryIndex !== -1 && baseSalary50thIndex !== -1
                ? ((baseSalary / action.summaryData[0][baseSalary50thIndex]) * 100).toFixed(percentRounding)
                : null;
            const totalCashRatio =
              totalCashAmtIndex !== -1 && totalCash50thIndex !== -1
                ? ((totalCashAmt / action.summaryData[0][totalCash50thIndex]) * 100).toFixed(percentRounding)
                : null;

            marketComparisonRows.push(
              action.terms.map((term, idx) => {
                if (idx === baseSalary50thIndex && baseSalary !== null) {
                  return formatCurrency(baseSalary, currency);
                } else if (idx === baseSalaryAvgIndex && basePayRatio !== null) {
                  return basePayRatio;
                } else if (idx === totalCash50thIndex && totalCashAmt !== null) {
                  return formatCurrency(totalCashAmt, currency);
                } else if (idx === totalCashAvgIndex && totalCashRatio !== null) {
                  return totalCashRatio;
                }
                return null;
              })
            );
          }

          return {
            ...state,
            processInitialLoad: false,
            loading: isLoading,
            loaded: isLoaded,
            termGroups: action.termGroups,
            terms: action.terms,
            data: action.data,
            status: action.status,
            summaryData: action.summaryData,
            searchData: action.searchData,
            metadata: action.metadata,
            tableProps: {
              ...state.tableProps,
              columnHeaders: getTransformedColumns(
                action.termGroups,
                action.terms,
                action.data,
                state.defaultLeftColumns,
                state.defaultRightColumns,
                state.termsColumnWidthOverride
              ),
              tfootHeaders:
                action.summaryData &&
                getTransformedColumns(
                  action.termGroups,
                  action.terms,
                  action.summaryData.concat(marketComparisonRows),
                  state.defaultLeftColumns,
                  state.defaultRightColumns,
                  state.termsColumnWidthOverride
                ),
              rowData: action.data,
              summaryData: action.summaryData,
              getDataRow: getGenericRow,
              dataLoading: isLoading,
              totalCount: action.metadata?.totalCount || action.data?.length,
              marketComparisonRows
            }
          };
        case this.GG_UPDATE_SPECIFIC:
          return {
            ...state,
            data: action.data,
            tableProps: {
              ...state.tableProps,
              rowData: action.data
            }
          };
        case this.GG_SEARCH_ERROR:
          return {
            ...state,
            processInitialLoad: false,
            loading: false,
            loaded: true,
            data: null,
            status: action.status,
            error: action.error
          };
        case this.GG_RESET:
          return {
            ...this.initialState
          };
        case this.GG_CLEAR_RESULTS:
          return {
            ...state,
            data: [],
            tableProps: {
              ...state.tableProps,
              rowData: []
            }
          };
        default:
          return state;
      }
    };
  }

  reset() {
    return (dispatch, getStore) => {
      dispatch({
        type: this.GG_RESET
      });
    };
  }

  onSortChange(sort) {
    return (dispatch, getStore) => {
      return dispatch({
        type: this.GG_ORDER_BY,
        orderBy: sort.map(sortEl => {
          let sortId = sortEl.id;
          if (sortId.startsWith('field_')) {
            sortId = sortId.replace('field_', '');
          } else if (sortId.startsWith('formula_')) {
            sortId = sortId.replace('formula_', '');
          }
          return {
            term: sortId,
            sort: sortEl.desc ? 'desc' : 'asc'
          };
        })
      });
    };
  }

  clearResultsOnly() {
    return dispatch => {
      dispatch({
        type: this.GG_CLEAR_RESULTS
      });
    };
  }

  paginate(limit, offset) {
    return dispatch => {
      const newLimit = limit;
      const params = { offset, limit: newLimit };
      dispatch({
        type: this.GG_PAGINATION,
        offset,
        limit: newLimit
      });
      dispatch(this.search({ params }));
    };
  }

  updateSpecificRowData(rowIndex, cellIdxs, newValues) {
    return (dispatch, getStore) => {
      let currentData = this.getInstanceState(getStore()).data;
      currentData = [...currentData];
      cellIdxs.forEach((cellIndex, indexTrack) => {
        if (currentData[rowIndex]) {
          currentData[rowIndex][cellIndex] = newValues[indexTrack];
        }
      });
      dispatch({ type: this.GG_UPDATE_SPECIFIC, data: currentData });
    };
  }

  search(url, options = {}, type = 'get') {
    const { GG_SEARCH_SUCCESS, GG_SEARCH_ERROR, GG_SEARCH } = this;

    return async (dispatch, getStore) => {
      dispatch({ type: GG_SEARCH });
      // instanceState gets the current reducer state; use to compose request body
      // const instanceState = this.getInstanceState(getStore());
      let result;

      if (type === 'get') {
        result = await apiClient.apiGet(url, options).catch(error => {
          console.log('generic grid search error: ', error);
          return dispatch({
            type: GG_SEARCH_ERROR,
            status: 500,
            error: 'error in generic grid search'
          });
        });
      } else {
        result = await apiClient.apiPost(url, options).catch(error => {
          console.log('generic grid search error: ', error);
          return dispatch({
            type: GG_SEARCH_ERROR,
            status: 500,
            error: 'error in generic grid search'
          });
        });
      }

      if (result.status < 300) {
        return dispatch({
          type: GG_SEARCH_SUCCESS,
          result: result?.data
        });
      } else {
        return dispatch({
          type: GG_SEARCH_ERROR,
          status: result.status,
          error: result.body?.error ? result.body.error : result.body
        });
      }
    };
  }
}
