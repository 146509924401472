import React, { Component } from 'react';
import { bool, func, string } from 'prop-types';
import classnames from 'classnames';

import './Search.scss';

class Search extends Component {
  static propTypes = {
    className: string,
    disabled: bool,
    id: string,
    onChange: func,
    onKeyDown: func,
    onClick: func,
    onSearch: func,
    placeholder: string,
    value: string,
    autoComplete: bool,
    searchName: string,
    useInternalState: bool,
    autoFocus: bool
  };

  static defaultProps = {
    autoComplete: false,
    disabled: false
  };

  constructor(props) {
    super(props);
    this.state = {
      searchValue: ''
    };

    this.onChangeValue = this.onChangeValue.bind(this);
    this.clearSearchValue = this.clearSearchValue.bind(this);
  }

  onChangeValue(e) {
    this.setState({ searchValue: e?.target?.value });
  }

  clearSearchValue() {
    this.setState({ searchValue: '' });
    this.props.onClick();
  }

  setSearchValue(newValue) {
    this.setState({ searchValue: newValue });
  }

  render() {
    let {
      className,
      disabled,
      id,
      onChange,
      onKeyDown,
      onClick,
      onSearch,
      placeholder,
      value,
      searchName,
      autoComplete,
      useInternalState,
      autoFocus
    } = this.props;

    if (disabled) {
      onChange = null;
      onKeyDown = null;
      onClick = null;
      onSearch = null;
    }

    return (
      <div className={classnames('search', className)}>
        <span
          className="icon-search-container"
          onClick={() => (useInternalState ? onSearch(this.state.searchValue) : onSearch)}
        >
          <i className="icon-search" />
        </span>

        <input
          name={searchName}
          autoComplete={autoComplete ? 'on' : 'off'}
          className="search-input"
          disabled={disabled}
          id={id}
          onKeyDown={onKeyDown}
          onChange={useInternalState ? this.onChangeValue : onChange}
          placeholder={placeholder}
          type="textbox"
          value={useInternalState ? this.state.searchValue : value}
          autoFocus={autoFocus}
        />

        {useInternalState
          ? this.state.searchValue &&
            this.state.searchValue.length !== 0 && <i className="icon-cancel" onClick={this.clearSearchValue} />
          : value && value.length !== 0 && <i className="icon-cancel" onClick={onClick} />}
      </div>
    );
  }
}

export default Search;
