import addAlert from 'ps-components/src/AlertToast/addAlert';
import apiClient from 'ps-components/src/lib/apiClient';
import partition from 'lodash/partition';
import {
  getCompanyLocationGrouping,
  getFormattedColumnsAndData,
  getLocationWithFullState,
  getPayScaleLocationGrouping,
  groupedDataByFamily,
  NATIONAL_LOCATION,
  setLocationMappingData,
  toCompareWithLocationValue,
  toPayScaleLocationDisplay
} from './helpers';

export const CLEAR_ALERT = 'CLEAR_ALERT';
export const GET_PAYSCALE_LOCATIONS = 'GET_PAYSCALE_LOCATIONS';
export const GET_PAYSCALE_LOCATIONS_ERROR = 'GET_PAYSCALE_LOCATIONS_ERROR';
export const GET_PAYSCALE_LOCATIONS_SUCCESS = 'GET_PAYSCALE_LOCATIONS_SUCCESS';

export const GET_LOCATIONS = 'GET_LOCATIONS';
export const GET_LOCATIONS_ERROR = 'GET_LOCATIONS_ERROR';
export const GET_LOCATIONS_SUCCESS = 'GET_LOCATIONS_SUCCESS';
export const MAP_LOCATION = 'MAP_LOCATION';
export const MAP_LOCATION_SUCCESS = 'MAP_LOCATION_SUCCESS';
export const MAP_LOCATION_ERROR = 'MAP_LOCATION_ERROR';

export const GET_TREND_SETTINGS = 'GET_TREND_SETTINGS';
export const GET_TREND_SETTINGS_ERROR = 'GET_TREND_SETTINGS_ERROR';
export const GET_TREND_SETTINGS_SUCCESS = 'GET_TREND_SETTINGS_SUCCESS';

export const SAVE_TREND_SETTINGS = 'SAVE_TREND_SETTINGS';
export const SAVE_TREND_SETTINGS_ERROR = 'SAVE_TREND_SETTINGS_ERROR';
export const SAVE_TREND_SETTINGS_SUCCESS = 'SAVE_TREND_SETTINGS_SUCCESS';

export const ADD_FAVORITE_LOCATION_SUCCESS = 'ADD_FAVORITE_LOCATION_SUCCESS';
export const ADD_FAVORITE_LOCATION_ERROR = 'ADD_FAVORITE_LOCATION_ERROR';
export const GET_FAVORITE_LOCATIONS = 'GET_FAVORITE_LOCATIONS';
export const GET_FAVORITE_LOCATIONS_SUCCESS = 'GET_FAVORITE_LOCATIONS_SUCCESS';
export const GET_FAVORITE_LOCATIONS_ERROR = 'GET_FAVORITE_LOCATIONS_ERROR';
export const REMOVE_FAVORITE_LOCATION_SUCCESS = 'REMOVE_FAVORITE_LOCATION_SUCCESS';
export const REMOVE_FAVORITE_LOCATION_ERROR = 'REMOVE_FAVORITE_LOCATION_ERROR';
export const SEARCH_PAYSCALE_LOCATIONS_START = 'SEARCH_PAYSCALE_LOCATION_START';
export const SEARCH_PAYSCALE_LOCATIONS_SUCCESS = 'SEARCH_PAYSCALE_LOCATION_SUCCESS';
export const SEARCH_PAYSCALE_LOCATIONS_ERROR = 'SEARCH_PAYSCALE_LOCATION_ERROR';

export const GET_SUPPORTED_PAYSCALE_LOCATIONS = 'GET_SUPPORTED_PAYSCALE_LOCATIONS';
export const GET_SUPPORTED_PAYSCALE_LOCATIONS_SUCCESS = 'GET_SUPPORTED_PAYSCALE_LOCATIONS_SUCCESS';
export const GET_SUPPORTED_PAYSCALE_LOCATIONS_ERROR = 'GET_SUPPORTED_PAYSCALE_LOCATIONS_ERROR';
export const SAVE_GEO_DIFFERENTIALS = 'SAVE_GEO_DIFFERENTIALS';
export const SAVE_GEO_DIFFERENTIALS_SUCCESS = 'SAVE_GEO_DIFFERENTIALS_SUCCESS';
export const SAVE_GEO_DIFFERENTIALS_ERROR = 'SAVE_GEO_DIFFERENTIALS_ERROR';

export const SET_TREND_METADATA = 'SET_TREND_METADATA';
export const CLEAR_ORG_LOCATIONS = 'CLEAR_ORG_LOCATIONS';

export const UPDATE_EMPLOYEE_FILTERS = 'UPDATE_EMPLOYEE_FILTERS';
export const GET_EF_CATEGORY_ELEMENTS_START = 'GET_EF_CATEGORY_ELEMENTS_START';
export const GET_EF_CATEGORY_ELEMENTS_SUCCESS = 'GET_EF_CATEGORY_ELEMENTS_SUCCESS';
export const GET_EF_CATEGORY_ELEMENTS_ERROR = 'GET_EF_CATEGORY_ELEMENTS_ERROR';

export const DOWNLOAD_GEO_CSV_START = 'DOWNLOAD_GEO_CSV_START';
export const DOWNLOAD_GEO_CSV_SUCCESS = 'DOWNLOAD_GEO_CSV_SUCCESS';
export const DOWNLOAD_GEO_CSV_ERROR = 'DOWNLOAD_GEO_CSV_ERROR';

export const CLEAR_EMPLOYEE_FILTER = 'CLEAR_EMPLOYEE_FILTER';

export function clearAlert(errMessage) {
  return { type: CLEAR_ALERT, errMessage };
}

export function saveGeoDifferentials(locations) {
  return async (dispatch, getState) => {
    const errorMessage = 'Error saving Geo Differentials';
    const successMessage = 'Geo Differentials have been successfully applied';

    try {
      dispatch({ type: SAVE_GEO_DIFFERENTIALS });

      const { settings } = getState().geoDifferential;
      const { trendRangeType, compareWithLocation } = settings;
      const masterAccountId = window.app.masterAccountId;
      const employeeFilters = getState().geoDifferential?.employeeFilters;
      let companyLocations = getState().geoDifferential?.companyLocations;
      const locationType = settings.internalData === 'workLocation' ? 'work-location' : 'city-state';

      const locationsWithNoOverride = locations.filter(data => data.differential == null);
      let allLocations = locations.filter(data => data.differential != null);
      const locationsToFetch = companyLocations
        .filter(location => locationsWithNoOverride.includes(location.formattedLocation))
        .map(location => location.payScaleLocation);

      if (locationsToFetch.length) {
        const params = {
          compareWithLocation: compareWithLocation === NATIONAL_LOCATION ? 'National' : compareWithLocation,
          groupByJobFamily: false,
          locations: locationsToFetch,
          trendRange: 1,
          trendRangeType: trendRangeType === 'Quarters' ? 0 : 1
        };
        const result = await apiClient.apiPost(`/cms/api/accounts/${masterAccountId}/locations/trends`, params);

        if (result.status < 300) {
          if (result.data.body.Answers) {
            const cityStates = Object.keys(result.data.body.Answers).map(location => location.split('; ')[0]);
            const impacts = Object.values(result.data.body.Answers);
            if (impacts?.length) {
              const impactKey = Object.keys(impacts[0].All.Impacts)[0];
              const trends = impacts.map((impact, index) => impact.All.Impacts[impactKey].PayDifferentialPercentage);

              companyLocations = companyLocations
                .filter(location => {
                  return cityStates.includes(`${location.city}, ${location.state}`);
                })
                .map(location => location.formattedLocation);

              const newLocations = companyLocations.map((location, index) => {
                return { location, differential: trends[index]?.toString() || 0 };
              });

              allLocations = allLocations.concat(newLocations);
            }
          }
        }
      }

      const formattedLocations = allLocations.map(adjustment => {
        const diffDecimal = adjustment.differential / 100;
        const differential = 1 + diffDecimal;

        return { location: adjustment.location, differential };
      });

      const saveResult = await apiClient.apiPatch(`/cms/api/accounts/${masterAccountId}/differential/geo`, {
        employeeFilters,
        locations: formattedLocations,
        locationType
      });

      if (saveResult.status < 300) {
        addAlert(successMessage, 'success');
        dispatch({ type: SAVE_GEO_DIFFERENTIALS_SUCCESS });
      } else {
        throw Error(errorMessage);
      }
    } catch (error) {
      addAlert(errorMessage, 'danger');
      console.error('Catch Error: Saving Geo Differentials: ', error);
      dispatch({ type: SAVE_GEO_DIFFERENTIALS_ERROR });
    }
  };
}

export function getPayScaleLocations(locations) {
  return async dispatch => {
    try {
      dispatch({ type: GET_PAYSCALE_LOCATIONS });
      let result = await dispatch(getLocationTrends(false, false, locations, true));
      dispatch({ type: GET_PAYSCALE_LOCATIONS_SUCCESS, data: result });
      return result;
    } catch (error) {
      console.log('Catch Error: Getting PayScale Locations: ', error);
      dispatch({ type: GET_PAYSCALE_LOCATIONS_ERROR });
    }
  };
}

export function getSupportedPayScaleLocations() {
  return async (dispatch, getState) => {
    const errorMessage = 'Error retrieving PayScale Locations';
    try {
      dispatch({ type: GET_SUPPORTED_PAYSCALE_LOCATIONS });
      const masterAccountId = window.app.masterAccountId;
      const result = await apiClient.apiGet(`cms/api/accounts/${masterAccountId}/locations/supported`);

      if (result.status < 300) {
        dispatch({ type: GET_SUPPORTED_PAYSCALE_LOCATIONS_SUCCESS, data: result.data.data.body.Answers });
      } else {
        throw Error(errorMessage);
      }
    } catch (error) {
      console.log('Catch Error: Getting Supported PayScale Locations: ', error);
      dispatch({ type: GET_SUPPORTED_PAYSCALE_LOCATIONS_ERROR, errorMessage, errorType: 'danger' });
    }
  };
}

export function downloadToCSV(
  locationData,
  columns,
  compareWithLocation,
  sortParams,
  isEditGeo,
  isReviewImpact,
  geoDiffs
) {
  return async (dispatch, getState) => {
    if (columns.length) {
      const errorMessage = 'Error downloading differential CSV';

      try {
        dispatch({ type: DOWNLOAD_GEO_CSV_START });
        const { settings, employeeFilters } = getState().geoDifferential;

        const masterAccountId = window.app.masterAccountId;
        const { status, data } = await apiClient.apiPost(`/cms/api/accounts/${masterAccountId}/differential/geo/csv`, {
          columns,
          compareWithLocation,
          locationData: locationData.map(location => location.original),
          sortParams,
          isEditGeo,
          isReviewImpact,
          geoDiffs,
          employeeFilters,
          locationType: settings.internalData === 'workLocation' ? 'work-location' : 'city-state'
        });

        if (status < 300) {
          downloadFile(data.csvFileName, data.csvData);
          dispatch({ type: DOWNLOAD_GEO_CSV_SUCCESS });
        } else {
          throw Error(errorMessage);
        }
      } catch (error) {
        addAlert(errorMessage, 'danger');
        console.log('Catch Error: Download Geo CSV: ', error);
        dispatch({ type: DOWNLOAD_GEO_CSV_ERROR });
      }
    }
  };
}

function downloadFile(fileName, data) {
  const aLink = document.createElement('a');
  aLink.href = 'data:attachment/csv,' + encodeURIComponent(data);
  aLink.download = fileName;

  document.body.appendChild(aLink);
  aLink.click();

  aLink.remove();
}

export function getCompanyLocations(isApplyGeo) {
  return async (dispatch, getState) => {
    const { settings, employeeFilters } = getState().geoDifferential;
    const { internalData } = settings;
    const errorMessage = 'Error retrieving company location data';
    try {
      dispatch({ type: GET_LOCATIONS });

      const masterAccountId = window.app.masterAccountId;
      const { status, data } = await apiClient.apiPost(`/cms/api/accounts/${masterAccountId}/locations/distinct`, {
        locationType: internalData,
        employeeFilters,
        isApplyGeo
      });

      if (status < 300) {
        if (data?.data?.value?.length === 0) {
          dispatch({ type: GET_LOCATIONS_ERROR });
        } else {
          const formattedCompanyLocationData = setLocationMappingData(data?.data?.value);
          const locations = formattedCompanyLocationData.map(datum => datum.payScaleLocation);
          const companyLocations = await dispatch(
            getLocationTrends(false, true, locations, false, formattedCompanyLocationData)
          );
          const applyLocations = await dispatch(
            getLocationTrends(true, true, locations, false, formattedCompanyLocationData)
          );

          dispatch({ type: GET_LOCATIONS_SUCCESS, data: { companyLocations, applyLocations } });

          return { companyLocations, applyLocations };
        }
      } else {
        throw Error(errorMessage);
      }
    } catch (error) {
      console.log('Catch Error: Get Distinct Locations: ', error);
      dispatch({ type: GET_LOCATIONS_ERROR, errorMessage, errorType: 'danger' });
    }
  };
}

export function getLocationTrends(
  showApplyGeo,
  showCompanyLocations,
  locations,
  showPayScaleLocations,
  distinctLocations
) {
  return async (dispatch, getState) => {
    const { settings } = getState().geoDifferential;
    const { showGrouped, groupByJobFamily, trendRange, trendRangeType, compareWithLocation } = settings;
    const errorMessage = 'Error retrieving location trend';

    const params = {
      compareWithLocation: compareWithLocation === NATIONAL_LOCATION ? 'National' : compareWithLocation,
      groupByJobFamily: showApplyGeo ? false : showGrouped,
      locations,
      trendRange,
      trendRangeType: trendRangeType === 'Quarters' ? 0 : 1
    };
    const masterAccountId = window.app.masterAccountId;
    const result = await apiClient.apiPost(`cms/api/accounts/${masterAccountId}/locations/trends`, params);

    if (result.status < 300) {
      let alert = null;
      let formattedLocations;
      const { Answers, Warnings } = result?.data?.body;
      const unmatchedResults = Warnings?.length;
      const { alertType } = getState().geoDifferential;
      if (showPayScaleLocations) {
        if (Answers) {
          formattedLocations = getPayScaleLocationGrouping(Answers);
        } else {
          formattedLocations = [];
        }
      } else {
        formattedLocations = getCompanyLocationGrouping(Answers, distinctLocations);
      }

      if (showGrouped && !groupByJobFamily && !showApplyGeo) {
        formattedLocations = groupedDataByFamily(formattedLocations, trendRangeType);
      }

      const showedMatchingAlert = window.localStorage.getItem('showedMatchingAlert');

      if (showedMatchingAlert !== 'true' && showCompanyLocations) {
        if (unmatchedResults === distinctLocations?.length) alert = 'failure';
        else if (unmatchedResults) alert = 'warning';
        else alert = 'success';
      }

      let columns, data;
      if (formattedLocations.length > 0) {
        const formattedData = getFormattedColumnsAndData(
          formattedLocations,
          showPayScaleLocations,
          settings,
          showApplyGeo,
          showCompanyLocations
        );
        columns = formattedData.columns;
        data = formattedData.data;
      } else {
        if (showPayScaleLocations) {
          columns = getState().geoDifferential.payScaleLocationColumns;
        } else if (showApplyLocations) {
          columns = getState().geoDifferential.applyLocationColumns;
        } else {
          columns = getState().geoDifferential.companyLocationColumns;
        }
        data = formattedLocations;
      }

      dispatch({
        type: SET_TREND_METADATA,
        alertType: alertType ? alertType : alert,
        showPayScaleLocations,
        showApplyGeo,
        columns
      });

      return data;
    } else {
      throw Error(errorMessage);
    }
  };
}

export function getTrendSettings() {
  return async (dispatch, getState) => {
    const errorMessage = 'Error getting trend settings';

    try {
      dispatch({ type: GET_TREND_SETTINGS });

      const masterAccountId = window.app.masterAccountId;
      const { status, data } = await apiClient.apiGet(
        `cms/api/accounts/${masterAccountId}/differential/geo/preferences`
      );
      dispatch({ type: GET_TREND_SETTINGS_SUCCESS, data: data?.data?.value });

      return data?.data?.value;
    } catch (error) {
      addAlert(errorMessage, 'danger');
      console.log('Catch Error: getting trend settings: ', error);
      dispatch({ type: GET_TREND_SETTINGS_ERROR });
    }
  };
}

export function saveTrendSettings(settings) {
  return async (dispatch, getState) => {
    const errorMessage = 'Error saving trend settings';

    try {
      dispatch({ type: SAVE_TREND_SETTINGS });
      const masterAccountId = window.app.masterAccountId;
      const { status, data } = await apiClient.apiPost(
        `/cms/api/accounts/${masterAccountId}/differential/geo/preferences`,
        {
          ...settings
        }
      );

      if (status < 300) {
        dispatch({ type: SAVE_TREND_SETTINGS_SUCCESS, data: settings });
      } else {
        throw Error(errorMessage);
      }
    } catch (error) {
      addAlert(errorMessage, 'danger');
      console.log('Catch Error: save trend settings: ', error);
      dispatch({ type: SAVE_TREND_SETTINGS_ERROR });
    }
  };
}

export function mapCompanyLocationToPayScaleLocation(companyLocation, locationType, payScaleLocation) {
  return async (dispatch, getState) => {
    const errorMessage = 'Error mapping location company location to PayScale location';

    try {
      dispatch({ type: MAP_LOCATION });
      const masterAccountId = window.app.masterAccountId;
      const { status } = await apiClient.apiPut(`/cms/api/accounts/${masterAccountId}/locations/mapping`, {
        location: companyLocation,
        locationType,
        differentialLocation: payScaleLocation
      });

      if (status < 300) {
        dispatch({ type: MAP_LOCATION_SUCCESS, location: companyLocation, locationType, payScaleLocation });
      } else {
        throw Error(errorMessage);
      }
    } catch (error) {
      addAlert(errorMessage, 'danger');
      console.log('Catch Error: Map Locations: ', error);
      dispatch({ type: MAP_LOCATION_ERROR });
    }
  };
}

export function getFavoriteLocations() {
  return async (dispatch, getState) => {
    const errorMessage = 'Error retrieving favorite locations';
    try {
      dispatch({ type: GET_FAVORITE_LOCATIONS });
      const masterAccountId = window.app.masterAccountId;
      const { status, data } = await apiClient.apiGet(`cms/api/accounts/${masterAccountId}/locations/favorites`);

      if (status < 300) {
        const locations = data?.data?.value.map(location => toCompareWithLocationValue(location));
        const favoriteLocations = await dispatch(getLocationTrends(false, false, locations, true));
        dispatch({ type: GET_FAVORITE_LOCATIONS_SUCCESS, locations: favoriteLocations });
        return favoriteLocations;
      } else {
        throw Error(errorMessage);
      }
    } catch (error) {
      console.log('Catch Error: Get Favorite Locations: ', error);
      dispatch({ type: GET_FAVORITE_LOCATIONS_ERROR, errorMessage, errorType: 'danger' });
    }
  };
}

export function addFavoriteLocation(location) {
  return async (dispatch, getState) => {
    const errorMessage = 'Error adding favorite locations';
    try {
      const masterAccountId = window.app.masterAccountId;
      const { status } = await apiClient.apiPost(`cms/api/accounts/${masterAccountId}/locations/favorites`, {
        location
      });

      if (status < 300) {
        dispatch({ type: ADD_FAVORITE_LOCATION_SUCCESS });
        dispatch(getFavoriteLocations());
      } else {
        throw Error(errorMessage);
      }
    } catch (error) {
      console.log('Catch Error: Add Favorite Location: ', error);
      dispatch({ type: ADD_FAVORITE_LOCATION_ERROR });
      throw error;
    }
  };
}

export function removeFavoriteLocation(location) {
  return async (dispatch, getState) => {
    const errorMessage = 'Error removing favorite locations';
    try {
      const masterAccountId = window.app.masterAccountId;
      const { status } = await apiClient.apiDelete(`cms/api/accounts/${masterAccountId}/locations/favorites`, {
        data: {
          location
        }
      });

      if (status < 300) {
        dispatch({ type: REMOVE_FAVORITE_LOCATION_SUCCESS });
        dispatch(getFavoriteLocations());
      } else {
        throw Error(errorMessage);
      }
    } catch (error) {
      console.log('Catch Error: Remove Favorite Location: ', error);
      dispatch({ type: REMOVE_FAVORITE_LOCATION_ERROR });
      throw error;
    }
  };
}

export function searchPayScaleLocations(supportedLocations, options) {
  return async dispatch => {
    try {
      dispatch({ type: SEARCH_PAYSCALE_LOCATIONS_START });
      const result = await apiClient.apiGet('/api/payscale/locations/locationSearch', {
        params: options.params
      });

      const data = supportedLocations
        ? result.data.filter(item => supportedLocations.has(item.label) || item.label === NATIONAL_LOCATION)
        : result.data;

      // remove "; United States" from end of label
      data.forEach(item => (item.label = toPayScaleLocationDisplay(item.label)));
      const lowerCasedLocationsList = data.map(location => location.label.toLowerCase());
      const search = options.params.workLocation;
      const locationWithFullState = getLocationWithFullState(search).toLowerCase();
      if (lowerCasedLocationsList.includes(locationWithFullState)) {
        const locationIndex = lowerCasedLocationsList.indexOf(locationWithFullState);
        const locationToMove = data.splice(locationIndex, 1)[0];
        data.unshift(locationToMove);
      }

      dispatch({ type: SEARCH_PAYSCALE_LOCATIONS_SUCCESS, result: data });
      if (options.searchCallback) {
        options.searchCallback(data);
      }
      return data;
    } catch (error) {
      dispatch({ type: SEARCH_PAYSCALE_LOCATIONS_ERROR, error: error });
    }
  };
}

export function updateEmployeeFilters(filters) {
  return { type: UPDATE_EMPLOYEE_FILTERS, filters };
}

export function clearEmployeeFilter() {
  return { type: CLEAR_EMPLOYEE_FILTER };
}
