import React, { Component } from 'react';
import { arrayOf, bool, func, number, object, oneOfType, string, element, node } from 'prop-types';
import classnames from 'classnames';
import checkForIE from '../Utils/checkForIE.js';
import './Dropdown.scss';

class Dropdown extends Component {
  static propTypes = {
    className: string,
    disabled: bool,
    disabledOptions: arrayOf(oneOfType([string, object, number])),
    dropUp: bool,
    dropdownItems: arrayOf(oneOfType([string, object, number])),
    dropdownClass: string,
    icon: string,
    id: string,
    itemOnClick: func,
    text: oneOfType([number, string]),
    rightBound: bool,
    leftBound: bool,
    type: string,
    staticMessage: string,
    parentTriggerFunc: func,
    customRenderComponent: oneOfType([element, node, func]),
    customRenderProps: object,
    customDropdownTrigger: node,
    disableBlur: bool
  };

  static defaultProps = {
    type: 'button'
  };

  state = { showDropdown: false };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { disabled } = this.props;
    const { showDropdown } = this.state;

    if (disabled !== nextProps.disabled && showDropdown) {
      this.setState({ showDropdown: false });
    }
  }

  _closeDropdown = () => {
    const { showDropdown } = this.state;
    if (showDropdown) this.setState({ showDropdown: false });
  };

  _itemOnClick = e => {
    const { showDropdown } = this.state;
    const { itemOnClick } = this.props;

    if (itemOnClick) itemOnClick(e.target.innerText);

    this.setState({ showDropdown: !showDropdown });
  };

  _toggleDropdownItems = () => {
    const { showDropdown } = this.state;
    const { parentTriggerFunc } = this.props;

    if (parentTriggerFunc) {
      parentTriggerFunc();
    } else {
      this.setState({ showDropdown: !showDropdown });
    }
  };

  render() {
    const {
      className,
      disabled,
      dropdownItems,
      icon,
      id,
      itemOnClick,
      text,
      dropUp,
      rightBound,
      leftBound,
      type,
      staticMessage,
      parentTriggerFunc,
      customRenderComponent: CustomComponent,
      customRenderProps,
      customDropdownTrigger,
      disabledOptions
    } = this.props;
    const { showDropdown } = this.state;
    const dropdownClass =
      (parentTriggerFunc || showDropdown) && !disabled
        ? `dropdown__visible ${this.props.dropdownClass}`
        : 'dropdown__hidden';
    const iconClass = classnames(
      {
        'icon-sort-down': !dropUp && type === 'button',
        'icon-sort-up': dropUp && type === 'button',
        'icon-down-open': !dropUp && type === 'input',
        'icon-up-open': dropUp && type === 'input'
      },
      'dropdown__icon'
    );

    // if user is using IE, don't close on blur
    const disableBlur = checkForIE() || this.props.disableBlur;

    return (
      <div
        className={classnames(className, 'dropdown-container')}
        disabled={disabled}
        id={id}
        onBlur={disableBlur ? null : this._closeDropdown}
        onClick={this._toggleDropdownItems}
        tabIndex="0"
      >
        {icon && <i className={`${icon}`} />}
        {<span className="dropdown__selected-text">{text}</span>}
        {!disabled && !customDropdownTrigger && <i className={iconClass} />}
        {!disabled && customDropdownTrigger && <div>{customDropdownTrigger}</div>}

        {CustomComponent ? (
          <CustomComponent className={dropdownClass} {...customRenderProps} />
        ) : (
          <ul
            className={classnames(dropdownClass, {
              'dropdown__drop-up': dropUp,
              'right-bound': rightBound || rightBound === undefined,
              'left-bound': leftBound
            })}
          >
            {staticMessage ? (
              <React.Fragment>
                <li className="dropdown__message">{staticMessage}</li>
                <li>
                  <ul className="dropdown__list">
                    {dropdownItems.map((item, index) => (
                      <li className="dropdown__item" key={index} onClick={this._itemOnClick}>
                        {item}
                      </li>
                    ))}
                  </ul>
                </li>
              </React.Fragment>
            ) : (
              <React.Fragment>
                {dropdownItems.map((item, index) => (
                  <li
                    className={classnames('dropdown__item', { disabled: disabledOptions?.includes(item) })}
                    key={index}
                    onClick={this._itemOnClick}
                  >
                    {item}
                  </li>
                ))}
              </React.Fragment>
            )}
          </ul>
        )}
      </div>
    );
  }
}

export default Dropdown;
