import { AxiosInstance, AxiosResponse } from 'axios';

export interface MigrateCompanyState {
  migrateBtnText: string | null;
  loading: boolean;
}

/* eslint-disable @typescript-eslint/class-name-casing */
interface MIGRATE_COMPANY_SURVEY {
  type: 'MIGRATE_COMPANY_SURVEY';
  loading: true;
}

interface MIGRATE_COMPANY_SURVEY_SUCCESS {
  type: 'MIGRATE_COMPANY_SURVEY_SUCCESS';
  loading: false;
  migrateBtnText: 'Migration Started...';
}

interface MIGRATE_COMPANY_SURVEY_FAIL {
  type: 'MIGRATE_COMPANY_SURVEY_FAIL';
  loading: false;
}
/* eslint-enable @typescript-eslint/class-name-casing */

const initialState: MigrateCompanyState = {
  migrateBtnText: 'Mig PS Co Survey',
  loading: false
};

type MigrateCompanyAction = MIGRATE_COMPANY_SURVEY | MIGRATE_COMPANY_SURVEY_SUCCESS | 
  MIGRATE_COMPANY_SURVEY_FAIL;

//Creates a delivers job
export function beginCompanySurveyMigration() {
  return (dispatch: Function, getState: Function): void => {
    if (!getState().migrateCompanySurvey.loading) {
      const ajaxPromise = dispatch({
        types: ['MIGRATE_COMPANY_SURVEY', 'MIGRATE_COMPANY_SURVEY_SUCCESS', 'MIGRATE_COMPANY_SURVEY_FAIL'],
        promise: (client: AxiosInstance) => 
          client.get('/api/hris/jobalyzer-bulk/beginBulkCompanySourcedMigration', {})
          .then((val: AxiosResponse<MIGRATE_COMPANY_SURVEY_SUCCESS> & { success: boolean }) => {
            if (val && !val.success) {
              throw 'Failed to begin Company Sourced Survey Migration. Please try again.';
            }
            return val;
          })
      });
      ajaxPromise.catch((err: object) => {
        console.error('Error creating manager review', err);
      });
    }
  };
}

export default function reducer(state = initialState, action: MigrateCompanyAction): MigrateCompanyState {
  switch (action.type) {
    case 'MIGRATE_COMPANY_SURVEY':
      return {
        ...state
      };
    case 'MIGRATE_COMPANY_SURVEY_SUCCESS':
      return {
        ...state,
        migrateBtnText: 'Migration started...',
        loading: true
      };
    case 'MIGRATE_COMPANY_SURVEY_FAIL':
      return {
        ...state
      };
    default:
      return { ...state };
  }
}