import React, { Component } from 'react';
import { arrayOf, func, string } from 'prop-types';
import classnames from 'classnames';
import { Options } from '@payscale/design';
import './RangeTypeSetting.scss';

const propTypes = {
  className: string,
  handleRangeTypeOptionClick: func.isRequired,
  optionsClassName: string,
  rangeType: string,
  rangeTypeOptions: arrayOf(string)
};
const defaultProps = { rangeTypeOptions: ['Market', 'Midpoint', 'Manual'] };
const labelText = 'Select One to Build Range';

const RangeTypeSetting = props => {
  const { className, handleRangeTypeOptionClick, optionsClassName, rangeType, rangeTypeOptions } = props;
  let newRangeType = rangeType;
  if (newRangeType) newRangeType = rangeType[0].toUpperCase() + rangeType.substring(1);
  else newRangeType = 'Market';

  return (
    <div className="stat__group">
      <div className={classnames('range-type-setting', className)} data-test-id="jbr-range-type-setting">
        <h5 className="job__label-text">{labelText}</h5>
        <Options
          className={classnames('pxl-option-primary options__radio-btn', optionsClassName)}
          loadMore={true}
          multiValue={false}
          onChange={handleRangeTypeOptionClick}
          options={rangeTypeOptions}
          syncOptions={false}
          type="radio"
          value={newRangeType}
        />
      </div>
    </div>
  );
};

RangeTypeSetting.propTypes = propTypes;
RangeTypeSetting.defaultProps = defaultProps;
export default RangeTypeSetting;
