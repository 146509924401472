import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './BarChart.scss';
import { applyRounding, parseNumCheckNull } from '../../../../../../lib/formatters';

const propTypes = {
  summaryStats: PropTypes.object,
  payType: PropTypes.string,
  preferences: PropTypes.object
};

export default class BarChart extends Component {
  static propTypes = propTypes;

  getBarHeights(currentCostToMin, proposedCostToMin) {
    if (currentCostToMin === 0 && proposedCostToMin === 0) {
      return { currHeight: 5, proposedHeight: 5 };
    }
    const maxValue = Math.max(currentCostToMin, proposedCostToMin);
    const currHeight = (currentCostToMin / maxValue) * 0.75 * 100 || 5;
    const proposedHeight = (proposedCostToMin / maxValue) * 0.75 * 100 || 5;
    return { currHeight, proposedHeight };
  }

  render() {
    const { summaryStats, payType, preferences } = this.props;
    const currentCostToMin = (summaryStats && summaryStats.current_total_cost_to_min) || 0;
    const costToMin = (summaryStats && summaryStats.total_cost_to_min) || 0;
    const heights = this.getBarHeights(currentCostToMin, costToMin);

    return (
      <div className="bar-chart-container">
        <div className={'column '} style={{ height: heights.currHeight + '%' }}>
          <span className="label bar-label">
            {parseNumCheckNull(applyRounding(currentCostToMin, 'monetary', preferences))}
          </span>
          <span className="label xAxis-label">Current Cost</span>
        </div>

        <div className={'column '} style={{ height: heights.proposedHeight + '%' }}>
          <span className="label bar-label">
            {parseNumCheckNull(applyRounding(costToMin, 'monetary', preferences))}
          </span>
          <span className="label xAxis-label">Proposed Cost</span>
        </div>
      </div>
    );
  }
}
