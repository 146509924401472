import * as Fuse from 'fuse.js';

const options = {
  shouldSort: true,
  threshold: 0.6,
  location: 0,
  distance: 100,
  maxPatternLength: 32,
  minMatchCharLength: 1,
  keys: ['currency', 'job_scope']
};

export const filterDataCuts = (items, query) => {
  // Used for resetting the state to an unfiltered one
  if (!query || query === '') {
    if (query === '') {
      for (let i = 0; i < items.length; i++) {
        items[i].match = undefined;
      }
    }

    // Once all of the item.match props are set to undefined, return the array of those items
    const arr = items.map(item => {
      return { ...item };
    });
    return [...arr];
  }

  // Returns array of filtered items
  const fuse = new Fuse(items, options); // eslint-disable-line new-cap

  const filteredItems = fuse.search(query);

  for (let i = 0; i < items.length; i++) {
    // Loop through all items returned by the API, and set item.match to false - used for rerendering.
    const item = items[i];
    item.match = false;
    for (let j = 0; j < filteredItems.length; j++) {
      // Loop through all filtered items for each item in the base list, compare unique ids, and set match
      if (item.unique_identifier === filteredItems[j].unique_identifier) {
        item.match = true;
      }
    }
  }

  const arr = items.map(item => {
    return { ...item };
  });
  return [...arr];
};
