import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Tooltip, OverlayTrigger, DropdownButton, MenuItem } from 'react-bootstrap/lib';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';

class UtilityNav extends Component {
  static propTypes = {
    app: PropTypes.object,
    searchClick: PropTypes.func,
    adminClick: PropTypes.func,
    userRights: PropTypes.object,
    isInfluitiveEnabled: PropTypes.bool,
    isHelpFormEnabled: PropTypes.bool,
    showHelpForm: PropTypes.func,
    history: PropTypes.object
  };

  constructor(props) {
    super(props);
  }

  searchButtonClick = (...args) => {
    this.props.searchClick(...args);
  };

  showAdminLink() {
    const userRights = this.props.userRights;
    const showAdminLinks =
      userRights &&
      (userRights.super_admin_p || userRights.mp_admin_p || userRights.admin_p || userRights.group_admin_p);
    let result = null;

    if (showAdminLinks) {
      result = (
        <li className="alt navbar__headerMenu--listItem">
          <div onClick={this.props.adminClick} className="navbar__listItem--link rightUtil">
            <i className="fa fa-star-o navbar__icon"> </i> Admin
          </div>
        </li>
      );
    }

    return result;
  }

  showUploadBtn() {
    const tooltipUpload = (
      <Tooltip id="ttUpload">
        <strong>Upload</strong>
      </Tooltip>
    );

    const userRights = this.props.userRights;
    const showUploadBtn =
      userRights &&
      (userRights.super_admin_p ||
        userRights.admin_p ||
        userRights.group_admin_p ||
        userRights.site_admin ||
        userRights.global_admin);

    let result = null;

    if (showUploadBtn) {
      result = (
        <li className="alt navbar__headerMenu--listItem">
          <div className="navbar__listItem--link rightUtil">
            <OverlayTrigger placement="bottom" overlay={tooltipUpload}>
              <i
                className="icon-border icon-circle-thin navbar__icon-circle"
                onClick={() => this.props.history.push('/upload')}
              >
                <i className="icon-up navbar__icon" />
              </i>
            </OverlayTrigger>
          </div>
        </li>
      );
    }

    return result;
  }

  render() {
    const tooltipProfile = (
      <Tooltip id="ttProfile">
        <strong>Profile</strong>
      </Tooltip>
    );
    const tooltipLogout = (
      <Tooltip id="ttLogout">
        <strong>Logout</strong>
      </Tooltip>
    );
    const communityItem = (
      <form type="post" className="influitive--form" action={window.app.apiPrefix + 'views/influitive'}>
        <button type="submit" className="influitive--btn">
          Community
        </button>
      </form>
    );

    return (
      <ul className="navbar__headerMenu utilityNav navbar__headerMenu-right" id="navbar__navigation--rightUtil">
        <li className="alt navbar__headerMenu--listItem">
          <div onClick={this.searchButtonClick} className="navbar__listItem--link rightUtil">
            <i className="fa fa-search navbar__icon"> </i> Search
          </div>
        </li>

        {this.showAdminLink()}

        {this.props.isInfluitiveEnabled && (
          <li className="alt navbar__headerMenu--listItem">
            <div className="navbar__listItem--link rightUtil" id="navbar__help">
              <DropdownButton
                title={<i className="fa fa-question-circle navbar__icon" />}
                id="dropdown-size-small"
                className="navbar__dropdown-button navbar__help--btn"
              >
                {communityItem}
              </DropdownButton>
            </div>
          </li>
        )}

        {this.showUploadBtn()}
        <li className="alt navbar__headerMenu--listItem">
          <div className="navbar__listItem--link rightUtil">
            <OverlayTrigger placement="bottom" overlay={tooltipProfile}>
              <Link to="/profile">
                <i className="fa fa-user navbar__icon" />
              </Link>
            </OverlayTrigger>
          </div>
        </li>

        <li className="alt navbar__headerMenu--listItem">
          <div className="navbar__listItem--link rightUtil">
            <OverlayTrigger placement="bottom" overlay={tooltipLogout}>
              <Link className="navbar__utility--logout" to="/logout">
                <i className="fa fa-sign-out navbar__icon" />
              </Link>
            </OverlayTrigger>
          </div>
        </li>
      </ul>
    );
  }
}

export default withRouter(UtilityNav);
