import SearchPanel from '../../../../../ps-components/src/redux/facetedSearch';

const SET_SELECTED_FACET_ONLY = 'JOBS_SET_SELECTED_FACET_ONLY';
const SET_FACET_SELECTION = 'JOBS_SET_FACET_SELECTION';

const initialState = {
  facetSelection: {
    // es_pay_range: [0, 1000000]
  },
  facets: [],
  loading: false,
  loaded: false,
  error: null,
  items: [],
  query: '',
  showAddButton: true,
  addButtonText: 'Add Job',
  resetFacetText: 'Reset Job Filters',
  searchText: 'Find Jobs',
  pageCode: 'price',
  filters: {},
  limit: 100
};

export const jobsSearchPanel = new SearchPanel('JOBS', initialState);

function extendReducer(state, action) {
  switch (action.type) {
    case SET_SELECTED_FACET_ONLY:
      return {
        ...state,
        facetSelection: {
          ...state.facetSelection,
          [action.facetId]: { [action.facetItem.key]: true }
        }
      };
    case SET_FACET_SELECTION:
      return {
        ...state,
        facetSelection: {
          ...state.facetSelection,
          ...action.newFacetSelection
        }
      };
    case 'JOB_DELETE':
      return {
        ...state,
        loading: true
      };
    case 'JOB_DELETE_SUCCESS':
      const orgJobId = action.result.orgJobId[0];
      return {
        ...state,
        loading: false,
        loaded: true,
        error: action.result.success ? null : action.result.message,
        items: action.result.success ? state.items.filter(item => orgJobId != parseInt(item._id, 10)) : [...state.items]
      };
    case 'JOB_DELETE_FAIL':
      return {
        ...state,
        loading: false,
        loaded: true,
        error: action
      };
    default:
      return null;
  }
}
export const reducer = jobsSearchPanel.createReducer(extendReducer, {
  searchAPI: '/api/elasticsearch/search/queryStringJobs',
  additionalSearchParams: state => {
    return { pageCode: 'price' };
  }
});

export const actions = jobsSearchPanel.getActions();
export const repeatCurrentSearch = actions.repeatCurrentSearch;
export const selectors = jobsSearchPanel.getSelectors();

export function getFacetById(state, id) {
  return state.jobsSearch.facets.filter(facet => facet.id === id)[0];
}

actions.viewFacet = function viewFacet({ facetId, facetItem, only, resetQuery, newFacetSelection }) {
  return (dispatch, getStore) => {
    const { facetSelection, loaded } = getStore().jobsSearch;

    dispatch({ type: 'JOBS_REMOVE_ALL_SELECTED' });
    if (resetQuery) {
      dispatch({ type: 'JOBS_SET_QUERY', query: '' });
      dispatch({ type: 'JOBS_RESET_ALL_FACETS_FILTERS' });
    }
    if (loaded) {
      const facet = getFacetById(getStore(), facetId);
      facet.lastFacetFilteredID = resetQuery ? 'reset' : facetId;
      actions.facetItemClick(facet, facetItem, only)(dispatch, getStore);
    } else {
      dispatch({
        type: only ? SET_SELECTED_FACET_ONLY : SET_FACET_SELECTION,
        facetId,
        facet: { id: facetId },
        facetItem,
        newFacetSelection
      });
    }
  };
};

actions.setInitialFilter = function setInitialFilter({ params, clear }) {
  return dispatch => {
    if (clear) {
      dispatch({ type: 'SEARCH_CLEAR_FACETS_FILTER' });
    }
    dispatch({ type: 'JOBS_SET_QUERY', query: params.query });
  };
};
