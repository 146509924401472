import { CLEAR_JOB_RANGE_MODEL_DETAILS, JOB_RANGES_MOVE_TO_CURRENT_SUCCESS } from '../../actions/JobRanges';
import { JOB_RANGES_HIDE_SUMMARY_STATS } from '../../actions/moveToCurrentStats';

const initialState = {
  stats: undefined,
  statsMock: {
    jobsNotMoved: [
      {
        range_model_job_id: 4,
        org_job_code: 'job code',
        org_job_code_key: 'job code key',
        org_job_title: 'job title'
      },
      {
        range_model_job_id: 5,
        org_job_code: 'job code',
        org_job_code_key: 'job code key',
        org_job_title: 'job title'
      }
    ],
    jobs_not_moved_count: 12
  },
  summaryVisible: false
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case JOB_RANGES_MOVE_TO_CURRENT_SUCCESS:
      return { ...state, stats: action.stats, summaryVisible: true };
    case JOB_RANGES_HIDE_SUMMARY_STATS:
      return { ...state, summaryVisible: false };
    case CLEAR_JOB_RANGE_MODEL_DETAILS:
      return { ...initialState };
    default:
      return state;
  }
}
