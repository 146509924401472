export default function updateRoute(currentPath, newEndPoint, history, addNewEndPoint) {
  if (!currentPath || !newEndPoint || !history) {
    return null;
  }
  // does the existing path end with the passed in endpoint
  // checks for "endpoint" and "endpoint/"
  let endsWithEndpoint = false;
  if (currentPath.endsWith(newEndPoint) || currentPath.endsWith(newEndPoint + '/')) {
    endsWithEndpoint = true;
  }

  if (addNewEndPoint) {
    let path = currentPath;

    // don't add the same endpoint again
    if (endsWithEndpoint) {
      return null;
    }

    if (!path.endsWith('/')) {
      path += '/';
    }

    if (newEndPoint && newEndPoint.indexOf('/') === 0) {
      path += newEndPoint.subString(1);
    } else if (newEndPoint) {
      path += newEndPoint;
    }

    // TODO: PSP-24771
    // Pushing to history and then going back when a route is removed sometimes causes the app to hard refresh
    // After ^ is fixed, remove history replace and uncomment push
    // history.push(path);
    history.replace(path);
  } else {
    // don't go back if the path doesn't have the passed in route
    if (!endsWithEndpoint) {
      return null;
    }

    // TODO: PSP-24771
    // Pushing to history and then going back when a route is removed sometimes causes the app to hard refresh
    // After ^ is fixed, remove history replace and uncomment goBack
    // history.goBack();
    history.replace(currentPath.slice(0, -newEndPoint.length));
  }
}
