const HOME_STATS = 'HOME_STATS';
const HOME_STATS_SUCCESS = 'HOME_STATS_SUCCESS';
const HOME_STATS_FAIL = 'HOME_STATS_FAIL';

const initialState = {
  loading: false,
  loaded: false,
  error: null,
  items: []
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case HOME_STATS:
      return {
        ...state,
        loading: true
      };
    case HOME_STATS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: null,
        items: action.result
      };
    case HOME_STATS_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        data: null,
        error: action.error
      };
    default:
      return { ...state };
  }
}

export function getStats(params = { es_query: 'viewed' }) {
  return {
    types: [HOME_STATS, HOME_STATS_SUCCESS, HOME_STATS_FAIL],
    promise: client => client.get('/api/subsites/site-metrics/getMetrics', { params: params })
  };
}
