import 'core-js';
import 'regenerator-runtime/runtime';
import 'whatwg-fetch';

import React, { useEffect, useState } from 'react';
import { render } from 'react-dom';
import { Provider as StateProvider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { Route } from 'react-router';
import configureStore from './reducers';
import client from './client';
import App from './containers/App/App';
import SessionProvider from './components/SessionProvider';
import IdentityProvider, { useIdentity } from '@payscale/identity-auth-react-client';
import InstrumentedLoadingIndicator from './components/InstrumentedLoadingIndicator';
import { postAuthCallback } from './helpers/auth';
import FatalError from './components/Common/FatalError';
import { InitDefaultClient } from 'ps-components/src/lib/FeatureFlag/Client';
import appConfig from './helpers/appConfig';

function AppProvider() {
  const { apiClient, history } = client;
  const { logout } = useIdentity();
  const [store, setStore] = useState(false);

  useEffect(() => {
    // XXX consider watching isAuthenticated so store doesn't get created until after
    function forbiddenHandler(dispatch, getState) {
      console.warn('apiClient called forbiddenHandler from index.js');
      // OPTIMIZE call logout only once, even if parallel API calls fail
      logout({ message: 'Access forbidden on attempted API call' });
    }

    const store = configureStore({ apiClient, forbiddenHandler });
    setStore(store);

    const { featureFlagServiceHost } = appConfig();
    InitDefaultClient(featureFlagServiceHost);
  }, []);

  if (!store) {
    return <InstrumentedLoadingIndicator loading={true} ddRumUserAction="AppProviderLoadingIndicatorMs" />;
  }

  return (
    <SessionProvider store={store} history={history}>
      <StateProvider store={store} key="provider">
        <ConnectedRouter history={history}>
          <Route path="/" component={App} />
        </ConnectedRouter>
      </StateProvider>
    </SessionProvider>
  );
}

function renderFatalError(reason, error) {
  return <FatalError fatalReason={reason} fatalError={error} />;
}

render(
  <IdentityProvider
    renderChildrenAfterLogout={true}
    history={client.history}
    authority={client.appConfig.identityAuthority}
    client_id={client.appConfig.identityClientId}
    mountpoint={client.appConfig.identityMountpoint}
    postAuthCallback={postAuthCallback}
    mode={client.appConfig.identityMode}
    loadingIndicator={<InstrumentedLoadingIndicator loading={true} ddRumUserAction="AppProviderLoadingIndicatorMs" />}
    fatalErrorFunc={renderFatalError}
    scopes={client.appConfig.identityScopes}
  >
    <AppProvider />
  </IdentityProvider>,
  document.getElementById('app')
);
