import React from 'react';
import PropTypes from 'prop-types';
import './SlideoutHeader.scss';
import { getClassNameWithOptionalClass } from '../../Utils/ClassNameUtils';

const propTypes = {
  buttonClass: PropTypes.string,
  iconClass: PropTypes.string,
  onClick: PropTypes.func,
  tooltip: PropTypes.string,
  testId: PropTypes.string
};

export const SlideoutHeaderButton = props => {
  const buttonClass = getClassNameWithOptionalClass('slideout-header__button', props.buttonClass);
  const iconClass = getClassNameWithOptionalClass('slideout-header-button__icon', props.iconClass);

  const { onClick, testId, tooltip } = props;
  return (
    <button className={buttonClass} title={tooltip} onClick={onClick} data-test-id={testId}>
      <i className={iconClass} />
      {props.children}
    </button>
  );
};

SlideoutHeaderButton.propTypes = propTypes;
export default SlideoutHeaderButton;
