import React, { Component } from 'react';
import { any, array, bool, func, object, oneOfType, string } from 'prop-types';
import classnames from 'classnames';
import Button from '../Button/Button';
import LoadingIndicator from '../LoadingIndicator/LoadingIndicator';
import Modal from '../Modal/Modal';
import ModalBody from '../Modal/ModalBody/ModalBody';
import ModalHeader from '../Modal/ModalHeader/ModalHeader';
import './AlertMessage.scss';

export default class AlertMessage extends Component {
  static propTypes = {
    additionalClass: any,
    additionalBodyClass: any,
    actions: object,
    confirmActionLoading: bool,
    confirmButtonText: string,
    data: object,
    hideCancelButton: bool,
    hideXButton: bool,
    message: oneOfType([string, object]),
    show: bool,
    title: string,
    hideTitle: bool,
    disableConfirmButton: bool,
    allowClose: bool
  };

  constructor(props) {
    super(props);

    this.confirm = this.confirm.bind(this);
  }

  confirm() {
    const { actions, data } = this.props;

    actions.confirm(data);
  }

  getModal() {
    const {
      actions,
      additionalClass,
      additionalBodyClass,
      confirmActionLoading,
      confirmButtonText,
      hideCancelButton,
      hideXButton,
      message,
      show,
      title,
      hideTitle,
      disableConfirmButton,
      allowClose
    } = this.props;

    const modalBodyClass = classnames('alert__modal-body', additionalBodyClass);
    const modalClass = classnames('alert__modal-container', 'container--pageroute', additionalClass);

    return (
      <Modal className={modalClass} onClose={actions.cancel} show={show} allowClose={allowClose}>
        <ModalHeader>{!hideXButton && <i className="icon-cancel pull-right" onClick={actions.cancel} />}</ModalHeader>
        {confirmActionLoading ? (
          <LoadingIndicator loading={confirmActionLoading} />
        ) : (
          <ModalBody className={modalBodyClass}>
            <div className="row">
              <div className="col-xs-12">
                {hideTitle ? '' : <h4 className="alert__modal-title text-center">{title}</h4>}
                <div className="alert__modal-content">{message}</div>
                <br />
                <div className="button__container text-right">
                  {hideCancelButton ? (
                    ''
                  ) : (
                    <Button className="pxl-btn alert-message__cancel" onClick={actions.cancel}>
                      Cancel
                    </Button>
                  )}
                  {confirm ? (
                    <Button
                      className="pxl-btn pxl-btn-primary save alert-message__confirm"
                      onClick={this.confirm}
                      disabled={disableConfirmButton}
                    >
                      {confirmButtonText ? confirmButtonText : 'Continue'}
                    </Button>
                  ) : null}
                </div>
              </div>
            </div>
          </ModalBody>
        )}
      </Modal>
    );
  }

  render() {
    return <div style={{ position: 'relative' }}>{this.getModal()}</div>;
  }
}
