/*
  Define facets to be hidden for different features; will override the 'hiddenFacets' prop in FacetPanel
*/
function getHiddenFacetArray(feature, currencyId) {
  switch (feature) {
    case 'marketPricingLandingPage':
      return ['org_job_id', 'org_job_flsa_exempt_p'];
    case 'slottedSearch':
      return ['org_job_id', 'org_job_flsa_exempt_p', 'job_price_status'];
    case 'globalJobMatching':
      return ['org_job_id', 'org_salary_grade', 'org_salary_structure'];
    case 'jobMatching':
      return ['org_job_id', 'org_salary_grade', 'org_salary_structure'];
    case 'jobMatchingSlider':
      return ['org_job_id', 'org_salary_grade', 'org_salary_structure'];
    case 'jobRangesSlideOut':
      return ['org_job_id', 'org_salary_grade', 'org_salary_structure', currencyId && 'currency'];
    case 'jobsInGrade':
      return [
        'org_salary_grade',
        'currency',
        'org_job_code',
        'org_job_id',
        'job_price_status',
        'org_job_flsa_exempt_p',
        'org_salary_structure'
      ];
    default:
      return ['org_job_id'];
  }
}

export default function(feature = '', useJobCodeKey = true, currencyId = null) {
  const hiddenFacetsArray = getHiddenFacetArray(feature, currencyId);

  if (!useJobCodeKey) {
    hiddenFacetsArray.push('org_job_code_key');
  }

  return hiddenFacetsArray;
}
