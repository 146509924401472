import { Modal } from '@payscale/design';

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Route, Switch } from 'react-router';

import './ModalHeader.scss';

export default class ModalHeader extends Component {
  static propTypes = {
    className: PropTypes.string,
    children: PropTypes.any
  };

  render() {
    return <div className={`modal__header ${this.props.className}`}>{this.props.children}</div>;
  }
}
