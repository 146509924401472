import { createStore, applyMiddleware, compose } from 'redux';
import { middleware as reduxPackMiddleware } from 'redux-pack';
import createApiClientMiddleware from '../../../ps-components/src/redux/middleware/clientMiddleware';
import thunk from 'redux-thunk';
import rootReducer from './modules/reducer';
import { routerMiddleware } from 'connected-react-router';
import createRavenMiddleware from '../../../ps-components/src/redux/middleware/ravenMiddleware';
import { initCustomFeatureRules } from '../helpers/features';
import client from '../client';

let puppeteer = false;
if (
  typeof window === 'object' &&
  window &&
  window.clientInformation &&
  window.clientInformation.userAgent === 'puppeteer'
) {
  puppeteer = true;
}

let composeEnhancers = compose;
if (puppeteer === false && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) {
  composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
}

const configureStore = ({ preloadedState = {}, apiClient, forbiddenHandler }) => {
  // Lab's version of reduxPackMiddleware
  //   too much code written in each pattern to drop usage immediately (and bundle size is trivial)
  const apiClientMiddleware = createApiClientMiddleware(apiClient, forbiddenHandler);

  const middleware = [
    routerMiddleware(client.history),
    thunk,
    reduxPackMiddleware,
    apiClientMiddleware,
    createRavenMiddleware()
  ];

  const store = createStore(
    rootReducer(client.history),
    preloadedState,
    composeEnhancers(applyMiddleware(...middleware))
  );

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('./modules/reducer', () => {
      const nextRootReducer = require('./modules/reducer');
      store.replaceReducer(nextRootReducer);
    });
  }

  // inject lab-specific rules into the feature flag module by just importing this lib
  initCustomFeatureRules();

  return store;
};

export default configureStore;
