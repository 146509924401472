import React from 'react';
import PropTypes from 'prop-types';
import './styles/main.scss';

import { PDFViewer, UnsupportedViewer } from './drivers';

function FileViewer(props) {
  const Driver = getDriver();

  function getDriver() {
    const type = props.fileType;

    if (type.includes('pdf')) {
      return PDFViewer;
    } else {
      return UnsupportedViewer;
    }
  }

  return (
    <div className="file-viewer">
      <Driver {...props} />
    </div>
  );
}

FileViewer.propTypes = {
  url: PropTypes.string.isRequired,
  onError: PropTypes.func,
  errorComponent: PropTypes.element,
  unsupportedComponent: PropTypes.element
};

FileViewer.defaultProps = {
  onError: () => null,
  errorComponent: null,
  unsupportedComponent: null
};

export default FileViewer;
