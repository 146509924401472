import React, { useRef } from 'react';

function PDFDriver(props) {
  return (
    <div className="file-viewer__pdf-viewer">
      <object data={props.url} type="application/pdf" width={props.width} height={props.height}>
        <embed src={props.url} type="application/pdf" />
      </object>
    </div>
  );
}

export default PDFDriver;
