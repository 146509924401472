import React, { lazy, Suspense } from 'react';
import { Route, Switch } from 'react-router';
import PropTypes from 'prop-types';
import { isFeatureEnabled } from '../../ps-components/src/FeatureFlags/FeatureFlagActions';
import { useIdentity } from '@payscale/identity-auth-react-client';
import LoadingIndicator from './components/Common/LoadingIndicator';
import { hrisGroup, surveyGroup, jobDescriptionsGroup, otherGroup } from './constants/uploadGroup';

const AddEditJobSlideout = lazy(() =>
  import(/* webpackChunkName: "editjob" */ 'ps-components/src/Modules/AddJob/AddEditJobSlideout')
);
const AdminUtils = lazy(() => import(/* webpackChunkName: "adminutils" */ './containers/AdminUtils/AdminUtils'));
const CompetitiveSets = lazy(() =>
  import(/* webpackChunkName: "competitive" */ 'ps-components/src/Modules/CompetitiveSets/CompetitiveSets')
);
const ConfirmLocations = lazy(() =>
  import(/* webpackChunkName: "confirmlocations" */ './containers/ConfirmLocations/ConfirmLocations')
);
const CurrencyPreview = lazy(() =>
  import(/* webpackChunkName: "currencypreview" */ './containers/Preview/CurrencyPreview')
);
const EmployeeAnalyticsPdf = lazy(() =>
  import(/* webpackChunkName: "employeeanalyticspdf" */ './containers/EmployeeAnalyticsPdf/EmployeeAnalyticsPdf')
);
const EmployeePdf = lazy(() => import(/* webpackChunkName: "employeepdf" */ './containers/EmployeePdf/EmployeePdf'));
const Employees = lazy(() => import(/* webpackChunkName: "employees" */ './containers/Employees/Employees'));
const GeoDifferential = lazy(() =>
  import(/* webpackChunkName: "geoDifferential" */ './containers/PayScalePulse/GeoDifferential')
);
const Home = lazy(() => import(/* webpackChunkName: "home" */ './containers/Home/Home'));
const Jobs = lazy(() => import(/* webpackChunkName: "jobs" */ './containers/Jobs/Jobs'));
const MeritMatrix = lazy(() => import(/* webpackChunkName: "merit" */ './containers/MeritMatrix/MeritMatrix'));
const PayScalePulse = lazy(() => import(/* webpackChunkName: "pulse" */ './containers/PayScalePulse/PayScalePulse'));
const PrintJob = lazy(() => import(/* webpackChunkName: "printjob" */ './containers/Jobs/PrintJob'));
const Ranges = lazy(() => import(/* webpackChunkName: "ranges" */ 'ps-components/src/Modules/JobRanges/JobRanges'));
const ReportLibrary = lazy(() =>
  import(/* webpackChunkName: "reportlibrary" */ './containers/ReportLibrary/ReportLibrary')
);
const Search = lazy(() => import(/* webpackChunkName: "search" */ './containers/Search/Search'));
const SiteAdministration = lazy(() =>
  import(/* webpackChunkName: "admin" */ './containers/SiteAdministration/SiteAdministration')
);
const StructureModelContainer = lazy(() =>
  import(/* webpackChunkName: "structuremodelcontainer" */ './containers/StructureModel/StructureModelContainer')
);
const Surveys = lazy(() => import(/* webpackChunkName: "surveys" */ './containers/Surveys/Surveys'));

const Iframe = lazy(() => import(/* webpackChunkName: "misc" */ './containers/Iframe/Iframe'));
const Logout = lazy(() => import(/* webpackChunkName: "misc" */ './components/Logout'));
const NotFound = lazy(() => import(/* webpackChunkName: "misc" */ './containers/NotFound/NotFound'));
const OverrideAuthMode = lazy(() => import(/* webpackChunkName: "misc" */ './components/OverrideAuthMode'));
const Profile = lazy(() => import(/* webpackChunkName: "misc" */ './containers/Profile/Profile'));

const Data = lazy(() =>
  import(/* webpackChunkName: "upload" */ '@payscale/upload').then(module => ({ default: module.Data }))
);
const Upload = lazy(() =>
  import(/* webpackChunkName: "upload" */ '@payscale/upload').then(module => ({ default: module.Upload }))
);

const Routes = ({ app, featureFlags }) => {
  const { logout } = useIdentity();
  const handleUnauthorized = () => {
    logout({ message: 'Access forbidden on attempted API call', redirect: true });
  };

  /**
   * Please keep routes in alphabetical order
   */
  return (
    <Suspense fallback={<LoadingIndicator />}>
      <Switch>
        {/* Home (main) route */}
        <Route exact path="/" component={Home} />
        {/* Routes */}
        <Route path="/addJob" component={AddEditJobSlideout} />
        <Route path="/override/auth/mode/:mode" component={OverrideAuthMode} />
        <Route exact path="/employees" component={Employees} />
        <Route path="/employees/add" component={Employees} />
        <Route path="/employees/print" component={Employees} />
        <Route path="/employees/:detailType/:itemId" component={Employees} />
        <Route path="/employees/:detailType/:itemId/edit" component={Employees} />
        <Route
          path={'/data'}
          render={() => (
            <Data
              app={app}
              isEnabled={isFeatureEnabled(featureFlags, 'dyson').isEnabled}
              onUnauthorized={handleUnauthorized}
            />
          )}
        />
        <Route
          path={'/upload'}
          render={() => (
            <Upload
              app={app}
              isEnabled={isFeatureEnabled(featureFlags, 'dyson').isEnabled}
              onUnauthorized={handleUnauthorized}
              hrisGroup={hrisGroup}
              surveyGroup={surveyGroup}
              jobDescriptionsGroup={jobDescriptionsGroup}
              otherGroup={otherGroup}
            />
          )}
        />
        <Route exact path="/jobs" component={Jobs} />
        <Route path="/jobs/add" component={Jobs} />
        <Route path="/jobs/:detailType/:itemId" component={Jobs} />
        <Route exact path="/printJob" component={PrintJob} />
        <Route path="/printJob/:jobId" component={PrintJob} />
        <Route exact path="/print/employee" component={EmployeeAnalyticsPdf} />
        <Route path="/print/employee/:itemId" component={EmployeePdf} />
        <Route path="search" component={Search} />
        <Route exact path="/profile" component={Profile} />
        <Route path="/profile/:profileId" component={Profile} />
        <Route exact path="/admin-utils" component={AdminUtils} />
        <Route exact path="/site-administration" component={SiteAdministration} />
        <Route path="/site-administration/:userId?" component={SiteAdministration} />
        <Route path="/site-administration/users/:userId?" component={SiteAdministration} />
        <Route exact path="/confirm-locations" component={ConfirmLocations} />
        <Route exact path="/structure-model" component={StructureModelContainer} />
        <Route path="/structure-model/:structureCode" component={StructureModelContainer} />
        <Route exact path="/surveys" component={Surveys} />
        <Route path="/surveys/:detailType/:itemId" component={Surveys} />
        <Route path="/competitive-sets" component={CompetitiveSets} />
        <Route path="/differentials-engine" component={PayScalePulse} />
        <Route path="/geo-differential" component={GeoDifferential} />
        <Route path="/merit-Matrix/:matrixGroupId" component={MeritMatrix} />
        <Route path="/merit-Matrix" component={MeritMatrix} />
        <Route path="/ranges" component={Ranges} />
        <Route path="/currencyPreview" component={CurrencyPreview} />
        {/* New Report Library */}
        <Route path="/reportLibrary" component={ReportLibrary} />
        {/* New Iframe Container */}
        <Route path="/iframe/:approute?/*" component={Iframe} />
        {/* Logout Container */}
        <Route path="/logout" component={Logout} />
        {/* Catch all route */}
        <Route path="*" component={NotFound} status={404} />
      </Switch>
    </Suspense>
  );
};

Routes.propTypes = {
  app: PropTypes.object,
  featureFlags: PropTypes.object
};

export default Routes;
