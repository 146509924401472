import React from 'react';
import { func } from 'prop-types';
import Placeholder from 'ps-components/src/Placeholder/Placeholder';
import Dropzone from 'react-dropzone';

const MAX_FILE_SIZE_MB = 25;

// has to be a comma separated string for dropzone v3, not an array
const acceptedFileTypes = `
  application/pdf,
  application/rtf,
  application/x-rtf,
  application/vnd.openxmlformats-officedocument.wordprocessingml.document,
  application/msword,
  text/richtext,
  text/plain,
  text/vnd.rn-realtext,
  .rtf,
  .txt,
  .text,
  .doc,
  .docx
`;

DropzoneComponent.propTypes = {
  onUpload: func,
  setError: func
};

function DropzoneComponent(props) {
  const maxFileSize = MAX_FILE_SIZE_MB * 1048576;

  function onDrop(acceptedFiles, rejectedFiles) {
    if (rejectedFiles?.length > 0) {
      const file = rejectedFiles[0];

      if (file.size > maxFileSize) {
        props.setError(`${file?.name} exceeds the ${MAX_FILE_SIZE_MB}MB file size limit`);
      } else {
        props.setError('Invalid file type; Allowed formats: DOC, DOCX, PDF, TXT, RTF');
      }
    } else if (acceptedFiles?.length > 0) {
      const file = acceptedFiles[0];
      const reader = new FileReader();

      reader.onabort = () => {
        console.log('file reading was aborted');
        props.setError('Error uploading file; please contact support if the issue persists.');
      };
      reader.onerror = () => {
        console.log('file reading has failed');
        props.setError('Error uploading file; please contact support if the issue persists.');
      };

      reader.onload = () => {
        props.setError(undefined);
        props.onUpload(file);
      };
      reader.readAsArrayBuffer(file);
    }
  }

  return (
    <div className="market-pricing__dropzone-container">
      <Dropzone
        onDrop={onDrop}
        multiple={false}
        accept={acceptedFileTypes}
        tabIndex={0}
        maxSize={maxFileSize}
        className="market-pricing__dropzone"
        activeClassName="active"
      >
        <React.Fragment>
          <div className="market-pricing__dropzone-upload-default">
            <Placeholder
              className="pxl-text-small pxl-text-muted"
              imagePath="/view/static/mp/images/cloud_image.svg"
              showText={false}
            />

            <div className="dropzone-text">
              <p className="drag-text">{'Drag & drop file or'}</p>
              <p className="description-link">select file</p>
              <div className="total-file-size">{`Total size limit: ${MAX_FILE_SIZE_MB}MB`}</div>
            </div>
          </div>

          <div className="market-pricing__dropzone-upload">
            <Placeholder
              className="pxl-text-small pxl-text-muted"
              imagePath="/view/static/mp/images/document-add.svg"
              showText={false}
            />

            <div className="dropzone-text">
              <p className="drag-text">Drop to upload file</p>
            </div>
          </div>
        </React.Fragment>
      </Dropzone>
    </div>
  );
}

export default DropzoneComponent;
