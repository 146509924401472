import React from 'react';
import { oneOfType, object, arrayOf, string } from 'prop-types';

import Table from './../Table/Table/Table';
import TableHeader from './../Table/TableHeader/TableHeader';
import TableBody from './../Table/TableBody/TableBody';
import Cell from './../Table/Cell/Cell';
import Row from './../Table/Row/Row';
import classnames from 'classnames';

import './TableInfoList.scss';

class TableInfoList extends React.Component {
  static propTypes = {
    /** Data to match up with headers specified, can be an element or text */
    data: arrayOf(oneOfType([string, object])).isRequired,
    /** List of headers to match up with data, should typically be just text */
    headers: arrayOf(oneOfType([string, object])).isRequired,
    tableClass: string,
    headerClass: string,
    bodyClass: string,
    cellClass: string,
    rowClass: string
  };

  getTableRows(data) {
    const { cellClass, rowClass } = this.props;
    if (!data) return <Row className={classnames('table-info-list__row', rowClass)}></Row>;
    const cells = data.map((cell, index) => {
      return (
        <Cell className={classnames('pxl-text-body table-info-list__cell', cellClass)} key={index}>
          {cell}
        </Cell>
      );
    });

    return <Row className={classnames('table-info-list__row', rowClass)}>{cells}</Row>;
  }

  render() {
    const { data, headers, headerClass, bodyClass, tableClass, rowClass } = this.props;

    return (
      <Table className={classnames('table-info-list', tableClass)}>
        <TableHeader
          cellClass={classnames('pxl-text-xsmall table-info-list__header-cell', headerClass)}
          columns={headers}
          rowClass={classnames('table-info-list__header', rowClass)}
        />
        <TableBody className={classnames('table-info-list__tbody ', bodyClass)}>{this.getTableRows(data)}</TableBody>
      </Table>
    );
  }
}

export default TableInfoList;
