import { mapDispatchToActions } from '../../../helpers/utils.js';

const SINGLE_SURVEY_JOB = 'SINGLE_SURVEY_JOB';
const SINGLE_SURVEY_JOB_SUCCESS = 'SINGLE_SURVEY_JOB_SUCCESS';
const SINGLE_SURVEY_JOB_FAIL = 'SINGLE_SURVEY_JOB_FAIL';
const FIND_SINGLE_SURVEY_JOB = 'FIND_SINGLE_SURVEY_JOB';
const FIND_SINGLE_SURVEY_JOB_SUCCESS = 'FIND_SINGLE_SURVEY_JOB_SUCCESS';
const FIND_SINGLE_SURVEY_JOB_FAIL = 'FIND_SINGLE_SURVEY_JOB_FAIL';

const initialState = {
  loading: false,
  loaded: false,
  error: null,
  items: [],
  query: '',
  sourceMapPk: null,
  limit: 100
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SINGLE_SURVEY_JOB:
      return {
        ...state,
        loading: true,
        loaded: false,
        error: null,
        items: [],
        query: '',
        limit: 100,
        sourceMapPk: action.itemId
      };
    case SINGLE_SURVEY_JOB_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
        items: []
      };
    case SINGLE_SURVEY_JOB_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: null,
        items: action.result,
        showMore: shouldShowMore(state, action.result)
      };
    case FIND_SINGLE_SURVEY_JOB:
      // if paginating do not reset items which causes scrolling to top of the list
      if (action.newLimit !== 100) {
        return {
          ...state,
          loading: true,
          loaded: false,
          limit: action.newLimit
        };
      }

      return {
        ...state,
        loading: true,
        loaded: false,
        error: null,
        items: [],
        limit: action.newLimit
      };
    case FIND_SINGLE_SURVEY_JOB_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
        items: []
      };
    case FIND_SINGLE_SURVEY_JOB_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: null,
        items: action.result,
        showMore: shouldShowMore(state, action.result)
      };
    default:
      return { ...state };
  }
}

const shouldShowMore = function(state, results) {
  if (state.limit > results.rowCount) {
    return false;
  }
  return true;
};

export const actions = {};

actions.filterSurveyJobs = function filterUsers(query) {
  return (dispatch, getState) => {
    const sourceMapPk = getState().singleSurveyJobsList.sourceMapPk;
    const newLimit = 100;

    dispatch({
      types: [
        'FIND_SINGLE_SURVEY_JOB',
        'FIND_SINGLE_SURVEY_JOB_SUCCESS',
        'FIND_SINGLE_SURVEY_JOB_FAIL'
      ],
      promise: client =>
        client.get('/api/elasticsearch/search-survey-jobs/querySurvey', {
          params: { source_map_pk: sourceMapPk, es_query: query }
        }),
      newLimit
    });
  };
};

actions.paginateSurveyJobs = function filterUsers(query) {
  return (dispatch, getState) => {
    const sourceMapPk = getState().singleSurveyJobsList.sourceMapPk;
    const newLimit = getState().singleSurveyJobsList.limit + 100;

    dispatch({
      types: [
        'FIND_SINGLE_SURVEY_JOB',
        'FIND_SINGLE_SURVEY_JOB_SUCCESS',
        'FIND_SINGLE_SURVEY_JOB_FAIL'
      ],
      promise: client =>
        client.get('/api/elasticsearch/search-survey-jobs/querySurvey', {
          params: {
            source_map_pk: sourceMapPk,
            es_query: getState().singleSurveyJobsList.query,
            limit: newLimit
          }
        }),
      newLimit
    });
  };
};

export const getActions = mapDispatchToActions(actions);
