import Raven from 'raven-js';
import { getErrorFromFailedResponse } from '../../Utils/errorUtils';

export default function createMiddleware(client, forbiddenHandler) {
  return ({ dispatch, getState }) => {
    return next => async action => {
      if (typeof action === 'function') {
        return action(dispatch, getState);
      }

      const { promise, types, type, emit, message, ...rest } = action; // eslint-disable-line no-redeclare

      if (emit) {
        socket.emit(emit, message);

        if (!client.socket[emit]) {
          socket.on(emit, function doSocketDispatch(data) {
            dispatch({ type: type, result: data });
          });
          client.socket[emit] = true;
        }

        return true;
      }

      if (!promise) {
        return next(action);
      }

      const [REQUEST, SUCCESS, FAILURE, PROGRESS] = types;

      const actionPromise = promise(client);

      next({ ...rest, type: REQUEST, actionPromise: actionPromise, ajaxClient: client });

      if (client.lastRequest) {
        // large multi-part uploads
        client.lastRequest.on('progress', e => {
          //
          if (PROGRESS) {
            next({ ...rest, event: e, type: PROGRESS });
          }
        });
      }

      try {
        const result = await actionPromise;
        next({ ...rest, result, type: SUCCESS });
      } catch (error) {
        if (error.message === 'Forbidden' || error.status === 401 || error.status === 403) {
          if (typeof forbiddenHandler === 'function') {
            forbiddenHandler(dispatch, getState);
          }

          console.log('client ', error);
        } else if (typeof Raven !== 'undefined') {
          Raven.captureException(getErrorFromFailedResponse(error));
        }

        next({ ...rest, error, type: FAILURE });
      }

      return actionPromise;
    };
  };
}
