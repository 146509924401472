import Fuse from 'fuse.js';
import { mapDispatchToActions } from '../../../helpers/utils.js';
const GET_SITES = 'GET_SITES';
const GET_SITES_SUCCESS = 'GET_SITES_SUCCESS';
const GET_SITES_FAIL = 'GET_SITES_FAIL';
const OPEN_SITE_STATUS_MODAL = 'OPEN_SITE_STATUS_MODAL';
const CLOSE_SITE_STATUS_MODAL = 'CLOSE_SITE_STATUS_MODAL';
const SAVE_SITE = 'SAVE_SITE';

const initialState = {
  loading: false,
  loaded: false,
  error: null,
  sites: {},
  fullActiveSiteList: [],
  managerEmailList: [],
  showAddEditModal: false,
  showPerformanceRatingModal: false,
  showErrorModal: false,
  showSyncing: false,
  showSyncComplete: false,
  showInactive: false,
  syncPercent: 0,
  deactivateModal: false,
  editSite: {},
  performanceRating: {},
  deactivateSite: {}
};

const fuseOptions = {
  shouldSort: true,
  threshold: 0.3,
  location: 0,
  distance: 100,
  maxPatternLength: 32,
  minMatchCharLength: 1,
  keys: [
    'url',
    'org_name',
    'subsite_status',
    'survey_year',
    'currency',
    'show_monetary',
    'defaultAgingFactor',
    'primary_first_name',
    'primary_last_name',
    'primary_job_title',
    'client_manager',
    'requires_whitelist'
  ]
};

function setMatchTrue(sites) {
  for (let i = 0; i < sites.length; i++) {
    sites[i].match = true;
  }
  return sites;
}

function filterSitesFn(state, query) {
  if (!query || query === '') {
    if (query === '') {
      for (let i = 0; i < state.sites.length; i++) {
        state.sites[i].match = true;
      }
    }
    return state.sites;
  }

  // Returns array of filtered items
  const fuse = new Fuse(state.sites, fuseOptions); // eslint-disable-line new-cap

  const filteredItems = fuse.search(query);
  for (let i = 0; i < state.sites.length; i++) {
    // Loop through all items returned by the API, and set item.match to false - used for rerendering.
    const item = state.sites[i];
    item.match = false;
    for (let j = 0; j < filteredItems.length; j++) {
      // Loop through all filtered items for each item in the base list, compare unique ids, and set match
      if (item.organization_id === filteredItems[j].organization_id) {
        item.match = true;
      }
    }
  }

  const arr = state.sites.map(cut => {
    return { ...cut };
  });
  return [...arr];
}

function filterInactiveSites(sites) {
  // only super admins gets the full list
  if (sites.success === false) {
    return [];
  }

  const filtered = sites.filter(site => {
    return site.subsite_status != 'Inactive';
  });
  return filtered;
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case 'GET_SITES':
      return {
        ...state,
        loading: true,
        loaded: false
      };
    case 'GET_SITES_SUCCESS':
      return {
        ...state,
        loading: false,
        loaded: true,
        fullActiveSiteList: filterInactiveSites(action.result),
        sites: setMatchTrue(action.result)
      };
    case 'GET_SITES_FAIL':
      return {
        ...state,
        loading: false,
        loaded: true,
        error: action.result
        // sites: {}
      };
    case 'GET_MANAGER_EMAILS':
      return {
        ...state,
        loading: true,
        loaded: false
      };
    case 'GET_MANAGER_EMAILS_SUCCESS':
      return {
        ...state,
        loading: false,
        loaded: true,
        managerEmailList: action.result
      };
    case 'GET_MANAGER_EMAILS_FAIL':
      return {
        ...state,
        loading: false,
        loaded: true,
        error: action.result
      };

    case 'SITES_FILTER':
      return {
        ...state,
        loading: false,
        loaded: true,
        sites: filterSitesFn(state, action.query)
      };

    case 'SAVE_SITE':
      return {
        ...state,
        loading: true,
        loaded: false
      };
    case 'SAVE_SITE_SUCCESS':
      let showErrorModal = false;
      let errorMessage = 'There was an error creating site.';
      if (!action.result.success) {
        showErrorModal = true;
        if (action.result.reason) {
          errorMessage = action.result.reason;
        }
      }
      if (action.result.subsites && action.result.subsites.length > 0) {
        window.app.subsites = action.result.subsites;
      }

      return {
        ...state,
        loading: false,
        loaded: true,
        fullActiveSiteList: window.app.subsites,
        showErrorModal: showErrorModal,
        errorMessage: errorMessage
      };
    case 'SAVE_SITE_FAIL':
      return {
        ...state,
        loading: false,
        loaded: true,
        error: action.result
      };
    case 'SITE_STATUS':
      return {
        ...state
      };
    case 'SITE_STATUS_SUCCESS':
      return {
        ...state
      };
    case 'SITE_STATUS_FAIL':
      return {
        ...state,
        error: action.result
      };
    case 'SHOW_INACTIVE':
      return {
        ...state,
        showInactive: action.showInactive
      };
    case 'OPEN_SITE_ADD_EDIT_MODAL':
      const date = new Date();
      const month = date.getMonth() + 1;
      const day = date.getDate();
      let defaultPricingYear = date.getFullYear();
      if (month <= 8 && day <= 30) {
        defaultPricingYear = date.getFullYear() - 1;
      }
      let contactFirstName = '';
      let contactLastName = '';
      let url = '';
      let crewGuid = '';
      let organizationId = '';
      let orgGroupId = '';
      let organizationName = '';
      let siteStatus = 'Active';
      let defaultCurrency = 'USD';
      let showMonetaryValue = 'thousands';
      let defaultAgingFactor = '';
      let contactJobTitle = '';
      let contactEmail = '';
      let clientManagerEmail = '';
      let implementationManagerEmail = '';
      let userId = '';
      let primarySubsite = '';
      let editingSite = null;
      let requireWhitelist = false;

      if (action.rowInfo && action.rowInfo.organizationId) {
        url = action.rowInfo.url;
        crewGuid = action.rowInfo.crewGuid;
        organizationId = action.rowInfo.organizationId;
        orgGroupId = action.rowInfo.orgGroupId;
        organizationName = action.rowInfo.organizationName;
        siteStatus = action.rowInfo.siteStatus;
        defaultPricingYear = action.rowInfo.defaultPricingYear;
        defaultCurrency = action.rowInfo.defaultCurrency;
        showMonetaryValue = action.rowInfo.showMonetaryValue;
        defaultAgingFactor = '';
        contactJobTitle = action.rowInfo.contactJobTitle;
        contactFirstName = action.rowInfo.contactFirstName;
        contactLastName = action.rowInfo.contactLastName;
        userId = action.rowInfo[7];
        contactEmail = action.rowInfo.contactEmail;
        clientManagerEmail = action.rowInfo.clientManagerEmail;
        implementationManagerEmail = action.rowInfo.implementationManagerEmail;
        primarySubsite = action.rowInfo[8];
        requireWhitelist = action.rowInfo.requireWhitelist;
        if (action.rowInfo.editingSite) {
          editingSite = action.rowInfo.editingSite;
        }
      }

      return {
        ...state,
        showAddEditModal: true,
        managerEmailList: state.managerEmailList,
        editSite: {
          url: url,
          crewGuid: crewGuid,
          organizationId: organizationId,
          orgGroupId: orgGroupId,
          organizationName: organizationName,
          siteStatus: siteStatus,
          defaultPricingYear: defaultPricingYear,
          defaultCurrency: defaultCurrency,
          showMonetaryValue: showMonetaryValue,
          defaultAgingFactor: defaultAgingFactor,
          contactFirstName: contactFirstName,
          contactLastName: contactLastName,
          contactJobTitle: contactJobTitle,
          contactEmail: contactEmail,
          clientManagerEmail: clientManagerEmail,
          implementationManagerEmail: implementationManagerEmail,
          userId: userId,
          primarySubsite: primarySubsite,
          editingSite: editingSite,
          requireWhitelist: requireWhitelist
        }
      };
    case 'CLOSE_SITE_ADD_EDIT_MODAL':
      return {
        ...state,
        showAddEditModal: false,
        showErrorModal: false
        // validation: {isValid: false}
      };
    case 'GET_PERFORMANCE_RATING':
      return {
        ...state,
        loading: true,
        loaded: false
      };
    case 'GET_PERFORMANCE_RATING_SUCCESS':
      return {
        ...state,
        loading: false,
        loaded: true
      };
    case 'GET_PERFORMANCE_RATING_FAIL':
      return {
        ...state,
        loading: false,
        loaded: true,
        error: action.result
      };
    case 'OPEN_PERFORMANCE_RATING_MODAL':
      let performanceRatings = '';
      organizationId = '';
      if (action.rowInfo) {
        performanceRatings = action.rowInfo;
        organizationId = action.orgId;
      }

      return {
        ...state,
        showPerformanceRatingModal: true,
        performanceRatings: performanceRatings,
        tmpPerformanceRatings: [...performanceRatings],
        editSite: {
          organizationId: organizationId
        }
      };
    case 'CLOSE_PERFORMANCE_RATING_MODAL':
      return {
        ...state,
        showPerformanceRatingModal: false,
        showErrorModal: false
      };
    case 'UPDATE_PERFORMANCE_RATING_ORDER':
      return {
        ...state,
        tmpPerformanceRatings: action.ratings
      };
    case 'MERGE_PERFORMANCE_RATING_ORDER':
      return {
        ...state,
        performanceRatings: state.tmpPerformanceRatings
      };
    case 'OPEN_SITE_STATUS_MODAL':
      return {
        ...state,
        deactivateSite: action.rowInfo,
        showErrorModal: false,
        deactivateModal: true
      };
    case 'CLOSE_SITE_STATUS_MODAL':
      return {
        ...state,
        showErrorModal: false,
        deactivateModal: false
      };
    case 'CLOSE_ERROR_MODAL':
      return {
        ...state,
        showErrorModal: false
      };
    case 'SYNC_DATA':
      return {
        ...state,
        syncPercent: 0,
        showSyncing: true,
        showSyncComplete: false
      };
    case 'SYNC_DATA_SUCCESS':
      return {
        ...state,
        syncPercent: 100,
        showSyncing: false,
        showSyncComplete: true
      };
    case 'SYNC_DATA_FAIL':
      return {
        ...state,
        syncPercent: 0,
        showSyncing: false,
        showSyncComplete: false
      };
    case 'SYNC_PROGRESS':
      if (state.syncPercent < 97) {
        state.syncPercent += 3;
      }
      return {
        ...state,
        syncPercent: state.syncPercent
      };
    default:
      return { ...state };
  }
}

export const actions = {};

actions.syncData = function syncData(options) {
  return (dispatch, getstate) => {
    dispatch({
      types: ['SYNC_DATA', 'SYNC_DATA_SUCCESS', 'SYNC_DATA_FAIL'],
      promise: client => client.post('/api/subsites/site-clone/cloneSiteData', { params: options })
    });
  };
};

actions.syncProgress = function syncProgress() {
  return {
    type: 'SYNC_PROGRESS'
  };
};

actions.openSiteAddEditModal = function openSiteAddEditModal(rowInfo) {
  return {
    type: 'OPEN_SITE_ADD_EDIT_MODAL',
    rowInfo
  };
};

actions.closeSiteAddEditModal = function closeSiteAddEditModal() {
  return {
    type: 'CLOSE_SITE_ADD_EDIT_MODAL'
  };
};

actions.openPerformanceRatingModal = function openPerformanceRatingModal(rowInfo, orgId) {
  return {
    type: 'OPEN_PERFORMANCE_RATING_MODAL',
    rowInfo,
    orgId
  };
};

actions.closePerformanceRatingModal = function closePerformanceRatingModal() {
  return {
    type: 'CLOSE_PERFORMANCE_RATING_MODAL'
  };
};

actions.getPerformanceRating = function getSites(options) {
  const organizationId = options.organizationId;

  return (dispatch, getState) => {
    dispatch({
      types: ['GET_PERFORMANCE_RATING', 'GET_PERFORMANCE_RATING_SUCCESS', 'GET_PERFORMANCE_RATING_FAIL'],
      promise: client => client.get('/api/matrix-ratings/getOrCreatePerformanceRatings', { params: options })
    })
      .then(result => {
        dispatch(actions.openPerformanceRatingModal(result, organizationId));
      })
      .catch(err => {
        console.error('Error getting or creating performance ratings', err);
      });
  };
};

actions.updatePerformanceRatingOrder = function updatePerformanceRatingOrder(ratings) {
  return {
    type: 'UPDATE_PERFORMANCE_RATING_ORDER',
    ratings
  };
};

actions.savePerformanceRatings = function savePerformanceRatings(options) {
  return (dispatch, getState) => {
    dispatch({
      type: 'MERGE_PERFORMANCE_RATING_ORDER'
    });

    const { performanceRatings, editSite } = getState().sites;
    dispatch({
      types: ['SAVE_PERFORMANCE_RATING', 'SAVE_PERFORMANCE_RATING_SUCCESS', 'SAVE_PERFORMANCE_RATING_FAIL'],
      promise: client =>
        client.get('/api/matrix-ratings/savePerformanceRatings', {
          params: {
            performanceRatings: JSON.stringify(performanceRatings),
            organizationId: editSite.organizationId
          }
        })
    })
      .then(result => {
        if (result.success) {
          dispatch(actions.closePerformanceRatingModal());
          dispatch(actions.getSites());
        }
      })
      .catch(err => {
        console.error('Error saving performance ratings', err);
      });
  };
};

actions.changeSiteStatus = function changeSiteStatus(options) {
  return (dispatch, getState) => {
    dispatch({
      types: ['SITE_STATUS', 'SITE_STATUS_SUCCESS', 'SITE_STATUS_FAIL'],
      promise: client => client.get('/api/subsites/sites/changeSiteStatus', { params: options })
    })
      .then(result => {
        dispatch(actions.getSites());
        dispatch(actions.closeDeactivateModal());
      })
      .catch(err => {
        console.error('Error changing site status', err);
      });
  };
};

actions.showHideInactive = function showHideInactive(showInactive) {
  return (dispatch, getState) => {
    dispatch({
      type: 'SHOW_INACTIVE',
      showInactive
    });
  };
};

actions.getSites = function getSites(query) {
  let showInactive = false;

  if (query) {
    return (dispatch, getState) => {
      showInactive = getState().sites.showInactive;
      dispatch({
        types: ['GET_SITES', 'GET_SITES_SUCCESS', 'GET_SITES_FAIL'],
        promise: client => client.get('/api/subsites/sites/list', { params: { showInactive: showInactive } })
      })
        .then(result => {
          dispatch({
            type: 'SITES_FILTER',
            query: query
          });
        })
        .catch(err => {
          console.error('Error getting sites', err);
        });
    };
  }

  return (dispatch, getState) => {
    showInactive = getState().sites.showInactive;
    dispatch({
      types: ['GET_SITES', 'GET_SITES_SUCCESS', 'GET_SITES_FAIL'],
      promise: client => client.get('/api/subsites/sites/list', { params: { showInactive: showInactive } })
    });
  };
};

actions.getManagerEmails = function getManagerEmails() {
  return (dispatch, getState) => {
    dispatch({
      types: ['GET_MANAGER_EMAILS', 'GET_MANAGER_EMAILS_SUCCESS', 'GET_MANAGER_EMAILS_FAIL'],
      promise: client => client.get('/api/subsites/sites/internalClientManagerEmails')
    });
  };
};

actions.changeSiteStatusConfirmModal = function changeSiteStatusConfirmModal(rowInfo) {
  return {
    type: 'OPEN_SITE_STATUS_MODAL',
    rowInfo
  };
};

actions.closeDeactivateModal = function closeDeleteModal(rowInfo) {
  return {
    type: 'CLOSE_SITE_STATUS_MODAL'
  };
};

actions.closeErrorModal = function closeErrorModal(rowInfo) {
  return {
    type: 'CLOSE_ERROR_MODAL'
  };
};

actions.saveSite = function saveUser(options) {
  let saveApiUrl = '/api/subsites/sites/update';

  if (!options.editingSite) {
    saveApiUrl = '/api/subsites/site-creation/createSite';
  }

  return (dispatch, getState) => {
    const editSite = getState().sites.editSite;
    dispatch({
      types: ['SAVE_SITE', 'SAVE_SITE_SUCCESS', 'SAVE_SITE_FAIL'],
      promise: client => client.post(saveApiUrl, { params: options })
    })
      .then(result => {
        if (result.success) {
          dispatch(actions.closeSiteAddEditModal());
          dispatch(actions.getSites());
        }
      })
      .catch(err => {
        console.error('Error saving site', err);
      });
  };
};

actions.filterSites = function filterSites(query) {
  return {
    type: 'SITES_FILTER',
    query: query
  };
};

export const getActions = mapDispatchToActions(actions);
