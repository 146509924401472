export function calculateFte(employeeWorkHours, currentCurrency, countryCode, app) {
  if (!employeeWorkHours) {
    return 1;
  }

  let workHours = 40;
  let matchCurrency = undefined;

  if (countryCode) {
    matchCurrency = app.currencies.find(currency => currency.country_code === countryCode);
  } else {
    matchCurrency = app.currencies.find(currency => currency.currency === currentCurrency);
  }

  if (matchCurrency && matchCurrency.std_work_hours) {
    workHours = matchCurrency.std_work_hours;
  }

  const FTE = employeeWorkHours / workHours;
  return FTE;
}
