const actionMap = {};

/**
 * Enqueue a redux action to be dispatched synchronously with other actions of its type
 * @param {Function} action Redux action or thunk
 * @param {string} key Grouping of redux actions to execute sequentially
 * @param {Function} dispatch Redux dispatch func
 * @param {Object} options Object containing options
 * @param {boolean} options.lastActionOnly When true, execute only the last action enqueued
 */
export function throttleActionSync(action, key, dispatch, options = {}) {
  let actionMapVal = actionMap[key];
  if (!actionMapVal) {
    actionMapVal = {
      queue: [],
      executor: null
    };
    actionMap[key] = actionMapVal;
  }

  if (options.lastActionOnly === true) {
    actionMapVal.queue = [action];
  } else {
    actionMapVal.queue.push(action);
  }

  if (!actionMapVal.executor) {
    actionMapVal.executor = actionsExecutor(actionMapVal, dispatch);
  }
}

async function actionsExecutor(actionMapVal, dispatch) {
  try {
    while (actionMapVal.queue.length > 0) {
      await dispatch(actionMapVal.queue.shift());
    }
  } catch (err) {
    console.error(err);
  }

  actionMapVal.executor = null;
}
