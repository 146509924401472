import { getReportData } from '../../../../../ps-components/src/Modules/AddJob/reducers/addJob';

export const loadDetailsPanel = function loadDetailsPanel(dispatch, getStore, itemId, item) {
  const params = buildParams(itemId);

  // Set loading job in reducer so we know exactly and immediately what job we're loading
  dispatch({
    type: 'JOB_DETAILS_SINGLE_START_LOAD',
    id: itemId
  });

  dispatch({
    types: ['JOB_RANGE_CHART_LOAD', 'JOB_RANGE_CHART_SUCCESS', 'JOB_RANGE_CHART_FAIL'],
    promise: client => client.get('/api/hris/job-detail-charts/jobRanges', params),
    itemId
  });

  if ([].concat(itemId).length === 1) {
    dispatch({
      types: ['FETCH_SURVEYS', 'FETCH_SURVEYS_SUCCESS', 'FETCH_SURVEYS_FAILURE'],
      promise: client => client.get('/api/market-pricing/job-matches/getSurveyJobs', params),
      itemId
    })
      .then(result => {
        const companySourcedCompaniesByJob = [];
        dispatch({ type: 'GET_COMPANY_SOURCED_COMPANIES_BY_JOB_SUCCESS', companySourcedCompaniesByJob, itemId });
        if (result && result.surveyJobMatches) {
          result.surveyJobMatches.forEach(survey => {
            if (
              survey.pub_survey_publisher === 'PayScale Company Sourced' &&
              (!survey.approved_status || survey.approved_status.toLowerCase() !== 'suggested') &&
              survey.match_id > 0 &&
              survey.source_map_id
            ) {
              getCompanySourcedCompanies(dispatch, survey.source_map_id, itemId);
            }
          });
        }
      })
      .catch(err => {
        console.error('Error getting survey jobs', err);
      });
  }

  dispatch({
    types: ['FETCH_COMPSETS', 'FETCH_COMPSETS_SUCCESS', 'FETCH_COMPSETS_FAILURE'],
    promise: client => client.post('/api/hris/competitive-sets/list', { params: {} }),
    itemId
  });

  dispatch({
    types: ['JOB_DETAILS', 'JOB_DETAILS_SUCCESS', 'JOB_DETAILS_FAILURE'],
    promise: client => client.get('/api/hris/job-details/getJobDetails', params),
    item,
    itemId
  })
    .then(result => {
      const baseSalary50th = result && result.market_base_salary_50th ? result.market_base_salary_50th : 0;
      dispatch({ type: 'GET_MARKET_SALARY_SUCCESS', market_base_salary_50th: baseSalary50th, itemId });
      if (result && result.payscale_job_info) {
        const reportData = getReportData({
          jobDetails: result.payscale_job_info,
          workLocation: result.payscale_job_info.workLocationExact
        });
        const reportType = ['companiesByJobReport', 'dataSummaryReport'];
        getCrowdSourcedSurveyData(dispatch, reportData, reportType, itemId);
      }
    })
    .catch(err => {
      console.error('Error getting job details', err);
    });

  getManagerReviewStatus(dispatch, params, itemId, item);
};

export const getManagerReviewStatus = (dispatch, params, itemId, item) => {
  dispatch({
    types: ['MANAGER_REVIEW_STATUS', 'MANAGER_REVIEW_STATUS_SUCCESS', 'MANAGER_REVIEW_STATUS_FAIL'],
    promise: client => client.get('/api/job-collab/jobReviewWorkflow/getReviewFromJobId', params),
    item,
    itemId
  });
};

export const getCrowdSourcedSurveyData = (dispatch, reportData, reportType, itemId) => {
  dispatch({
    types: [
      'GET_CROWD_SOURCED_SURVEY_DATA',
      'GET_CROWD_SOURCED_SURVEY_DATA_SUCCESS',
      'GET_CROWD_SOURCED_SURVEY_DATA_FAIL'
    ],
    promise: client =>
      client.get('/api/hris/jobJobalyzerReports/createRetrieveReports', {
        params: { reportData: JSON.stringify(reportData.reportData), reportTypes: JSON.stringify(reportType) }
      }),
    itemId
  });
};

export const getCompanySourcedCompanies = (dispatch, sourceMapId, itemId) => {
  dispatch({
    types: [
      'GET_COMPANY_SOURCED_COMPANIES_BY_JOB',
      'GET_COMPANY_SOURCED_COMPANIES_BY_JOB_SUCCESS',
      'GET_COMPANY_SOURCED_COMPANIES_BY_JOB_FAIL'
    ],
    promise: client => client.get('/api/hris/surveys/getParticipants', { params: { source_map_pk: sourceMapId } }),
    itemId
  });
};

export const buildParams = itemId => {
  return { params: { org_job_id: itemId, orgJobId: itemId } };
};

export const loadComparisonPanel = function loadComparisonPanel(dispatch, getStore, selectedList) {
  const chartUrl = '/api/hris/job-detail-charts/jobRanges';
  const params = { params: { org_job_id: selectedList, currency: window.app.default_currency } };
  dispatch({
    types: ['JOB_COMPARISON_RANGE_CHART_LOAD', 'JOB_COMPARISON_RANGE_CHART_SUCCESS', 'JOB_COMPARISON_RANGE_CHART_FAIL'],
    promise: client => client.get(chartUrl, params),
    selectedList
  });

  dispatch({
    types: ['COMPARE', 'COMPARE_SUCCESS', 'COMPARE_FAIL'],
    promise: client => client.get('/api/hris/job-details/jobCompare', params),
    selectedList
  });
};

export const markDirtyJob = function markDirtyJob(dispatch, getStore, jobId) {
  let singleJobId = jobId;
  if (typeof jobId == 'object') {
    singleJobId = jobId[0];
  }
  let dirtyJobList = []; // eslint-disable-line prefer-const
  if (getStore.jobsList && getStore.jobsList.dirtyJobs) {
    dirtyJobList = getStore.jobsList.dirtyJobs;
    const index = dirtyJobList.indexOf(singleJobId);
    if (index < 0) {
      dirtyJobList.push(singleJobId);
    }
  } else {
    dirtyJobList.push(singleJobId);
  }
  dispatch({
    type: 'JOBS_MARK_DIRTY',
    dirtyJobList: dirtyJobList
  });
};

export const getAuditHistory = jobId => ({
  types: ['JOB_AUDIT_TRAIL', 'JOB_AUDIT_TRAIL_SUCCESS', 'JOB_AUDIT_TRAIL_FAIL'],
  promise: client => client.get('/api/hris/audit-trail/audit-trail/getAuditHistory', { params: { orgJobId: jobId } })
});
