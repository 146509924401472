const initialState = {
  calculatedPrintTables: false,
  calculatedPrintTablesSurvey: false,
  surveyJobsPerPage: [
    [{ title: 'survey job 1' }, { title: 'survey job 2' }],
    [{ title: 'survey job 3' }, { title: 'survey job 4' }]
  ],
  detailsJobsPerPage: [
    [{ title: 'survey job 1' }, { title: 'survey job 2' }],
    [{ title: 'survey job 3' }, { title: 'survey job 4' }]
  ]
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case 'UPDATE_JOBS_PER_PAGE':
      return {
        ...state,
        calculatedPrintTables: true,
        detailsJobsPerPage: action.detailsJobsPerPage
      };
    case 'UPDATE_JOBS_PER_PAGE_SURVEY':
      return {
        ...state,
        calculatedPrintTablesSurvey: true,
        surveyJobsPerPage: action.surveyJobsPerPage
      };
    default:
      return { ...state };
  }
}

export function applyPrintJobTables(detailsJobsPerPage) {
  return {
    type: 'UPDATE_JOBS_PER_PAGE',
    detailsJobsPerPage: detailsJobsPerPage
  };
}

export function applyPrintJobTablesSurvey(surveyJobsPerPage) {
  return {
    type: 'UPDATE_JOBS_PER_PAGE_SURVEY',
    surveyJobsPerPage: surveyJobsPerPage
  };
}
