import Pagination from '../genericGrid/pagination';

const initialState = {
  perPageCount: 10,
  pageNumber: 1,
  searchResultsCount: 0
};

const jobsPagination = new Pagination('JOBS', initialState);
export const reducer = jobsPagination.createReducer();
export const actions = jobsPagination.getActions();
