import React, { Component } from 'react';
import { func, string } from 'prop-types';
import './Searchbox.scss';

class Searchbox extends Component {
  static propTypes = {
    placeholderText: string,
    searchInputSearch: func,
    query: string.isRequired,
    className: string,
    setSearchQuery: func.isRequired
  };

  constructor(props) {
    super(props);

    this.handleSearch = this.handleSearch.bind(this);
    this.searchbarKeyPress = this.searchbarKeyPress.bind(this);
    this.searchboxChange = this.searchboxChange.bind(this);
  }

  handleSearch() {
    const { searchInputSearch } = this.props;
    const query = this.refs.searchInput.value;
    searchInputSearch(query);
  }

  searchbarKeyPress(event) {
    if (event.key === 'Enter') {
      this.handleSearch();
    }
  }

  searchboxChange(event) {
    this.props.setSearchQuery(event.target.value);
  }

  render() {
    const { placeholderText, query, className } = this.props;

    return (
      <div className={'search ' + className}>
        <input
          aria-describedby={placeholderText}
          className="search-input"
          onChange={this.searchboxChange}
          onKeyPress={this.searchbarKeyPress}
          placeholder={placeholderText}
          ref="searchInput"
          type="text"
          value={query}
        />
        <i className="icon-search" />
      </div>
    );
  }
}

export default Searchbox;
