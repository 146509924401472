const MODEL_STRUCTURE_LIST = 'MODEL_STRUCTURE_LIST';
const MODEL_STRUCTURE_LIST_SUCCESS = 'MODEL_STRUCTURE_LIST_SUCCESS';
const MODEL_STRUCTURE_LIST_FAIL = 'MODEL_STRUCTURE_LIST_FAIL';

const initialState = {
  loading: false,
  loaded: false,
  error: null,
  initialLoad: true,
  salaryStructureItems: {
    modelAndStructureList: []
  }
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case MODEL_STRUCTURE_LIST:
      return {
        ...state,
        loading: true
      };
    case MODEL_STRUCTURE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        initialLoad: false,
        error: null,
        salaryStructureItems: action.result
      };
    case MODEL_STRUCTURE_LIST_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        data: null,
        error: action.error
      };
    default:
      return { ...state };
  }
}

export function getModelAndStructure() {
  return {
    types: [MODEL_STRUCTURE_LIST, MODEL_STRUCTURE_LIST_SUCCESS, MODEL_STRUCTURE_LIST_FAIL],
    promise: client => client.get('api/structures/grades/getModelAndStructure')
  };
}
