import listPanel from '../genericGrid/listPanel';
import * as surveyActions from './surveyActions';
import { getSearchItemById } from './selectors';

const initialState = {
  pageName: 'subscribedSurveys',
  sort: {},
  allowSort: false,
  allowCheckAll: false,
  expandedMaxColumnCount: 1,
  selectedListRows: [],
  // These define what is displayed in the list on surveys page. Any changes here will not take effect until
  // mp-node is restarted
  columns: [
    {
      title: 'Surveys',
      dataKey: 'pub_survey_short_name_standard',
      field_type_code: 'text'
    },
    {
      title: 'Survey Code',
      dataKey: 'pub_survey_code_none',
      field_type_code: 'text'
    },
    {
      title: 'Publisher',
      dataKey: 'pub_survey_publisher_standard',
      field_type_code: 'text'
    },
    {
      title: 'Year',
      dataKey: 'survey_year_default',
      field_type_code: 'text'
    }
  ]
};

let actions = {}; // eslint-disable-line prefer-const

// extend or override action creators here:
actions.loadDetailsPanel = surveyActions.loadDetailsPanel;
actions.handleRowSelect = function handleRowSelect(...args) {
  const [, getStore, selectedRows] = args;
  const selected = [].concat(selectedRows);
  const length = selected.length;
  if (!length) return;
  if (length > 0) {
    const item = getSearchItemById(getStore(), selected[0]);
    actions.loadDetailsPanel(...args, item);
  }
};

export const surveysListPanel = listPanel('SURVEYS', initialState, actions);
export const reducer = surveysListPanel.reducer;

// extend reducer here:
surveysListPanel.extendReducer = function extendReducer(state, action) {
  switch (action.type) {
    default:
      return null;
  }
};
