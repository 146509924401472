const defaultInitialState = {
  perPageCount: 10,
  pageNumber: 1,
  searchResultsCount: 0
};

export default class Pagination {
  constructor(nameSpace, initialState = defaultInitialState) {
    this.SEARCH_SUCCESS = `${nameSpace}_SEARCH_SUCCESS`;
    this.PAGINATION_FETCH = `${nameSpace}_PAGINATION_FETCH`;
    this.PAGINATION_FAIL = `${nameSpace}_PAGINATION_FAIL`;
    this.PAGE_SELECTION = `${nameSpace}_PAGE_SELECTION`;
    this.PER_PAGE_COUNT = `${nameSpace}_PER_PAGE_COUNT`;

    this.initialState = initialState || defaultInitialState;
  }

  createReducer(extendReducerFn) {
    return (state = this.initialState, action = {}) => {
      if (typeof extendReducerFn === 'function') {
        const newState = extendReducerFn(state, action);
        if (newState) return newState;
      }

      switch (action.type) {
        case this.SEARCH_SUCCESS:
          return {
            ...state,
            searchResultsCount: action.result.searchResultsCount
          };

        case this.PAGE_SELECTION:
          return {
            ...state,
            pageNumber: action.newPageNumber
          };

        case this.PER_PAGE_COUNT:
          return {
            ...state,
            perPageCount: action.newCount,
            pageNumber: 1
          };

        default:
          return { ...state };
      }
    };
  }

  fetchRows() {
    return {
      types: [this.PAGINATION_FETCH, this.SEARCH_SUCCESS, this.PAGINATION_FAIL],
      promise: client =>
        client.get('/api/elasticsearch/search/queryStringSurveys?es_query=sales', { params: null })
    };
  }

  pageNumberChange(event, state) {
    const newPageNum = event.newPageNumber || event.target.value;
    const { perPageCount, itemsCount, searchResultsCount } = state;
    const val = newPageNum * perPageCount;

    if (val > itemsCount && itemsCount < searchResultsCount) {
    } else {
      return {
        type: this.PAGE_SELECTION,
        newPageNumber: newPageNum
      };
    }
  }

  perPageCountChange(value) {
    return {
      type: this.PER_PAGE_COUNT,
      newCount: value
    };
  }

  getActions() {
    return {
      fetchRows: this.fetchRows.bind(this),
      pageNumberChange: this.pageNumberChange.bind(this),
      perPageCountChange: this.perPageCountChange.bind(this)
    };
  }
}
