import { createInitialMatrixSet } from './groupList';

const initialState = {
  groupOptions: [],
  selectedGroup: null,
  selectVisible: false,
  employeeGroupChangeAlert: false,
  saveSelectedGroup: null
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case 'GROUP_CHANGE':
      return { ...state, selectedGroup: action.selectedGroup, employeeGroupChangeAlert: false };
    case 'SELECT_VISIBLE_CHANGE':
      return { ...state, selectVisible: !state.selectVisible };
    case 'CANCEL_EMPLOYEE_GROUP_CHANGE':
      return {
        ...state,
        employeeGroupChangeAlert: false
      };
    case 'SHOW_EMPLOYEE_GROUP_CHANGE_ALERT':
      return {
        ...state,
        employeeGroupChangeAlert: true,
        saveSelectedGroup: action.saveSelectedGroup
      };
    case 'GROUP_LIST':
      return {
        ...initialState
      };
    case 'GROUP_LIST_SUCCESS':
      let selectedGroup = null; // eslint-disable-line prefer-const
      if (action.result.matrixGroupings) {
        action.result.matrixGroupings.forEach(grouping => {
          if (grouping.fieldGrouping != 'Currency') {
            selectedGroup = { value: grouping.fieldPk, label: grouping.fieldGrouping };
          }
        });
      }

      let groupList = []; // eslint-disable-line prefer-const

      if (action.result.rows) {
        action.result.rows.map(answer => {
          groupList.push({ value: answer.field_pk, label: answer.default_title });
        });
      }
      return {
        ...state,
        error: null,
        groupOptions: groupList,
        selectedGroup: selectedGroup
      };
    case 'GROUP_LIST_FAIL':
      return {
        ...state,
        error: action.error
      };
    default:
      return state;
  }
}

export const onGroupChange = (selectedGroup, history) => {
  return (dispatch, getState) => {
    dispatch({
      type: 'GROUP_CHANGE',
      selectedGroup
    });
    dispatch(
      createInitialMatrixSet(
        {
          selectedGroupFieldPk: selectedGroup && selectedGroup.value ? selectedGroup.value : undefined,
          selectedGroupText: selectedGroup && selectedGroup.label ? selectedGroup.label : undefined
        },
        history
      )
    );
  };
};

export function changeSelectVisibility() {
  return {
    type: 'SELECT_VISIBLE_CHANGE'
  };
}

export function cancelEmployeeGroupChange() {
  return {
    type: 'CANCEL_EMPLOYEE_GROUP_CHANGE'
  };
}

export const showEmployeeGroupChangeAlert = saveSelectedGroup => {
  return {
    type: 'SHOW_EMPLOYEE_GROUP_CHANGE_ALERT',
    saveSelectedGroup
  };
};

export function getGroupSelection(options) {
  const query = {
    category_pk: 1
  };
  return {
    types: ['GROUP_LIST', 'GROUP_LIST_SUCCESS', 'GROUP_LIST_FAIL'],
    promise: client => client.get('/api/matrix-groupings/groupList', { params: query }),
    query: query
  };
}
