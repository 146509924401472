import { selectors as jobSearchSelectors } from '../jobs/selectors';
const SEARCH = 'UNIVERSAL_SEARCH';
const SEARCH_SUCCESS = 'UNIVERSAL_SEARCH_SUCCESS';
const SEARCH_FAIL = 'UNIVERSAL_SEARCH_FAIL';
const VIEW_RESULTS = 'UNIVERSAL_SEARCH_VIEW_RESULTS';
const SEARCH_RESET = 'SEARCH_RESET';
const FOCUS_ROW = 'UNIVERSAL_SEARCH_FOCUS_ROW';
const FOCUS_LINK = 'UNIVERSAL_SEARCH_FOCUS_LINK';
const SET_SEARCH_QUERY = 'UNIVERSAL_SEARCH_SET_SEARCH_QUERY';

let subsite = '';
if (typeof window !== 'undefined') {
  subsite = window.location.pathname.split('/')[1];
}

const initialState = {
  loading: false,
  loaded: false,
  error: null,
  items: {
    category: {},
    rowCount: 0
  },
  query: '',
  showSearchResults: false,
  rowCnt: 0,
  focusRowLink: '',
  searchTypes: [
    {
      doc_type: 'jobs',
      base_url: 'jobs',
      icon: 'fa fa-briefcase icon__searchCategory'
    },
    {
      doc_type: 'employees',
      base_url: 'employees',
      icon: 'icon-group icon__searchCategory'
    },
    {
      doc_type: 'reports',
      base_url: 'iframe/nodeapp/#reportWriter',
      icon: 'fa fa-table icon__searchCategory'
    },
    {
      doc_type: 'dashboard',
      base_url: 'iframe/nodeapp/#dashboards',
      icon: 'fa fa-line-chart icon__searchCategory',
      pretty_name: 'Analytics'
    },
    {
      doc_type: 'users',
      base_url: 'users',
      pretty_name: 'System Users',
      icon: 'fa fa-desktop icon__searchCategory'
    }
  ]
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SEARCH:
      return {
        ...state,
        loading: true
      };
    case SEARCH_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: null,
        items: action.result
      };
    case SEARCH_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        items: { category: {} },
        error: action.error
      };
    case VIEW_RESULTS:
      return {
        ...state,
        showSearchResults: action.visible
      };
    case SEARCH_RESET:
      return {
        ...state,
        query: '',
        focusRowIdx: null,
        items: { category: {} },
        showSearchResults: false
      };
    case FOCUS_ROW:
      return {
        ...state,
        focusRowIdx: action.rowIdx
      };
    case FOCUS_LINK:
      return {
        ...state,
        focusRowLink: action.rowLink,
        focusRowDocTitle: action.docTitle
      };
    case SET_SEARCH_QUERY:
      return {
        ...state,
        query: action.query
      };
    default:
      return { ...state };
  }
}

export function doUniversalSearchAjax(options = { params: { es_query: '', category: '' } }) {
  return {
    types: [SEARCH, SEARCH_SUCCESS, SEARCH_FAIL],
    promise: client => client.get('/api/elasticsearch/generic-search/search', { params: options.params }),
    query: options.params.es_query
  };
}

export function doUniversalSearch(options = { params: { es_query: '', category: '' } }) {
  return {
    type: SEARCH_SUCCESS,
    emit: '/api/elasticsearch/generic-search/search',
    message: options.params
  };
}

export function setSearchResultVisibility(visible) {
  return {
    type: VIEW_RESULTS,
    visible: visible
  };
}

export function setSearchValue(val) {
  return {
    type: SET_SEARCH_QUERY,
    query: val
  };
}

export function resetSearchResults() {
  return {
    type: SEARCH_RESET
  };
}

export function searchResultsFocusRow(rowIdx) {
  return {
    type: FOCUS_ROW,
    rowIdx: rowIdx
  };
}

export function setFocusLink(rowLink, docTitle) {
  return {
    type: FOCUS_LINK,
    rowLink: rowLink,
    docTitle
  };
}
