export const CASE_CATEGORY_CONTACT_SUPPORT = 'Bug - Issue';

export const MODULE_FEATURE_FUNCTION_OPTIONS = [
  {
    module: 'Market Analysis',
    defaultFeature: 'Market Price a Job',
    features: [
      {
        feature: 'Market Price a Job',
        functions: [
          'Add/New',
          'Ad-Hoc Pricing',
          'Aging Factors',
          'Copy',
          'Crowd-Sourced',
          'Data Cuts',
          'Differentials',
          'Downloads',
          'Filters',
          'Job Description',
          'Pricing Details',
          'Pricing Results',
          'Pricing/Slotting',
          'Propagate',
          'Reports',
          'Search',
          'Survey Library Search',
          'Views',
          'Year Over Year'
        ]
      },
      {
        feature: 'Browse Survey Library',
        functions: [
          'Active Surveys',
          'Add/New',
          'Archived Surveys',
          'Default Data Cuts',
          'Downloads',
          'Not Yet Active Surveys',
          'Reports',
          'Save',
          'Search',
          'Survey Detail Links',
          'Uploads'
        ]
      },
      {
        feature: 'Survey Participation',
        functions: ['Downloads', 'Matches', 'Not Yet Ready', 'Ready for Participation', 'Reports', 'Search', 'Uploads']
      },
      {
        feature: 'Survey Library Update',
        functions: [
          'Add/New',
          'Downloads',
          'Edit',
          'Manage Survey Subscriptions',
          'Refresh',
          'Reports',
          'Search',
          'Sign & Submit',
          'Subscribe SLU'
        ]
      },
      {
        feature: 'Private Survey Sources',
        functions: ['Add/New', 'Delete', 'Downloads', 'Edit', 'Search', 'Views']
      },
      {
        feature: 'Private Survey Jobs',
        functions: ['Add/New', 'Delete', 'Downloads', 'Edit', 'Search', 'Views']
      },
      {
        feature: 'Job Matching',
        functions: ['Filters', 'Matching', 'Search']
      },
      {
        feature: 'Rules Based Pricing',
        functions: [
          'Add/New',
          'Apply to Jobs',
          'Data Cuts',
          'Delete',
          'Edit',
          'Filters',
          'Search',
          'Select Jobs',
          'Settings'
        ]
      },
      {
        feature: 'Competitive Sets',
        functions: ['Add/New', 'Delete', 'Edit', 'Save']
      }
    ]
  },
  {
    module: 'Reports',
    defaultFeature: 'Report Library',
    features: [
      {
        feature: 'Report Library',
        functions: [
          'Add/New',
          'Advanced Reporting',
          'All Reports',
          'Charts',
          'Data Elements',
          'Delete',
          'Downloads',
          'Favorites',
          'Filters',
          'Formula Builder',
          'Parameters',
          'Report Results',
          'Save',
          'Scope',
          'Search',
          'Tableau'
        ]
      },
      {
        feature: 'Data Mining',
        functions: [
          /* no options */
        ]
      },
      {
        feature: 'Data Analytics',
        functions: [
          'Add/New',
          'Charts',
          'Delete',
          'Downloads',
          'Favorites',
          'Filters',
          'Parameters',
          'Raw Data',
          'Save',
          'Scope',
          'Search'
        ]
      }
    ]
  },
  {
    module: 'Modeling',
    defaultFeature: 'Model a Structure',
    features: [
      {
        feature: 'Model a Structure',
        functions: [
          'Add/New',
          'Assign Jobs',
          'Cost Analysis',
          'Delete',
          'Downloads',
          'Filters',
          'Parameters',
          'Regression',
          'Reports',
          'Save',
          'Search',
          'Set Proposed Ranges',
          'Views'
        ]
      },
      {
        feature: 'Model a Merit Matrix',
        functions: [
          'Add/New',
          'Delete',
          'Downloads',
          'Existing Model',
          'Filters',
          'Parameters',
          'Reports',
          'Save',
          'Search',
          'Set Merit Model'
        ]
      },
      {
        feature: 'Job Based Ranges',
        functions: ['Add/New', 'Current Target for Jobs', 'Delete', 'Edit', 'Search']
      }
    ]
  },
  {
    module: 'Our Data',
    defaultFeature: 'Jobs',
    features: [
      {
        feature: 'Employees',
        functions: ['Add/New', 'Delete', 'Downloads', 'Edit', 'Save', 'Search', 'Views']
      },
      {
        feature: 'Jobs',
        functions: ['Add/New', 'Delete', 'Downloads', 'Edit', 'Job Description', 'Save', 'Search', 'Views']
      },
      {
        feature: 'Salary Range',
        functions: ['Add/New', 'Delete', 'Downloads', 'Edit', 'Save', 'Search', 'Views']
      }
    ]
  },
  {
    module: 'Admin',
    defaultFeature: 'User Administration',
    features: [
      {
        feature: 'Group Administration',
        functions: [
          'Create Group',
          'Reports',
          'Delete',
          'Parameters',
          'Group Configuration',
          'Filter Criteria',
          'User Administration',
          'Subscribe Surveys',
          'Set Defaults Data Cuts',
          'Search',
          'MarketPay Super Users Only'
        ]
      },
      {
        feature: 'System Configuration',
        functions: [
          'Save',
          'Security Information',
          'Site Defaults',
          'Market Pricing Defaults',
          'Aging Factors',
          'Report and View Permissions',
          'Numeric Formatting',
          'Contact Information',
          'Logo',
          'Theme',
          'Module Activation',
          'MarketPay Super Users Only',
          'Data Elements',
          'Unique Salary Range Information'
        ]
      },
      {
        feature: 'Group Configuration',
        functions: [
          'Save',
          'Security Information',
          'Group Defaults',
          'Aging Factors',
          'Report and View Permissions',
          'Numeric Formatting',
          'Logo'
        ]
      },
      {
        feature: 'User Administration',
        functions: [
          'Access Role',
          'Add/New',
          'Ban',
          'Become This User',
          'Connect Existing User',
          'Delete',
          'Group Access',
          'Reports',
          'Reset Password',
          'Search',
          'Unlock User',
          'Update User Profile'
        ]
      },
      {
        feature: 'Database Utilities',
        functions: [
          'General Utilities',
          'Manage your Survey Library',
          'Import Site Data',
          'Export Site Data',
          'Export Structure Models',
          'Migration Status'
        ]
      },
      {
        feature: 'Upload HRIS Data',
        functions: ['Choose File', 'Employees', 'Jobs', 'Other', 'Salary ranges', 'Survey Matches']
      },
      {
        feature: 'Upload Survey Data',
        functions: [
          'Active Surveys',
          'Add a Private Survey',
          'Archived Surveys',
          'Default Data Cuts',
          'Downloads',
          'Not Yet Active Surveys',
          'Reports',
          'Save',
          'Search',
          'Survey Detail Links',
          'Upload'
        ]
      },
      {
        feature: 'MarketPay Utilities',
        functions: [
          'General Utilities',
          'Create Summaries',
          'Technical Support',
          'Manage Survey Library',
          'Migration',
          'Survey Administration',
          'Download Survey Data',
          'Import Survey Data',
          'Report Writer Administration',
          'HRIS Currency Mismatch Reports',
          'Pricing Wizard',
          'Pricing Wizard Downloads',
          'Pricing Wizard Imports'
        ]
      }
    ]
  },
  {
    module: 'Dashboard',
    defaultFeature: 'Other',
    features: [
      {
        feature: 'Header',
        functions: ['Aging Factors', 'Currency', 'Group', 'Product Logo', 'Profile', 'Site Logo']
      },
      {
        feature: 'Navigation Menu',
        functions: ['Icons', 'Links', 'Resources', 'Scrolling']
      },
      {
        feature: 'Infographics',
        functions: ['Calculations', 'Data', 'Graphs', 'Links']
      },
      {
        feature: 'Survey Utilization',
        functions: ['Data', 'Graphs', 'Links']
      },
      {
        feature: 'Reports',
        functions: ['Data', 'Links']
      }
    ]
  },
  {
    module: 'Services',
    defaultFeature: 'HRIS Upload Request',
    features: [
      {
        feature: 'Applied Analytics',
        functions: ['Custom Visualizations and Reporting', 'Pay Equity Reporting']
      },
      {
        feature: 'Benchmarking Team',
        functions: ['Market Pricing']
      },
      {
        feature: 'Compensation Professionals',
        functions: ['Manager Training', 'Stakeholder Alignment', 'Structure and Range Creation or Review']
      },
      {
        feature: 'Contract/Billing/Renewal',
        functions: ['Contract', 'Invoice']
      },
      {
        feature: 'Data Ops - HRIS',
        functions: ['Delete', 'Extended Data Loading', 'General Inquiry', 'Match Import', 'Refreshes', 'Updates']
      },
      {
        feature: 'Data Ops - Surveys',
        functions: ['General Inquiry', 'Historical Survey Loading']
      },
      {
        feature: 'Education',
        functions: ['Content', 'Training']
      },
      {
        feature: 'Sales/Revenue',
        functions: ['Lead', 'Upsell Opportunity']
      },
      {
        feature: 'Site Ops',
        functions: [
          'Migration - Annual Match Update',
          'Migration - Company Sourced',
          'Migration - On Demand',
          'Survey Library Change',
          'Survey Participation',
          'Vendor Verification'
        ]
      }
    ]
  },
  {
    module: 'System',
    defaultFeature: 'Site Slowness',
    features: [
      {
        feature: 'Login Screen',
        functions: [
          'Contact Support',
          'Forgot Password',
          'Incorrect Site/URL',
          'Security Violation',
          'Single Sign On (SSO)',
          'Submit',
          'Username'
        ]
      },
      {
        feature: 'SFTP',
        functions: ['Edit', 'Issue', 'Scheduler', 'Setup']
      },
      {
        feature: 'Site Slowness',
        functions: ['Admin', 'All Modules', 'Dashboard', 'Help', 'Market Analysis', 'Modeling', 'Our Data', 'Reports']
      },
      {
        feature: 'Site Unavailable',
        functions: ['New Site', 'Outage', 'Sunset Site', 'System Maintenance']
      },
      {
        feature: 'Internal',
        functions: ['Non-PayScale Products']
      }
    ]
  }
];

//// MPE-6564 - Dashboardand unmapped pages - should have mapped module and feature
export const DEFAULT_MODULE_FEATURE = { module: 'Dashboard', feature: 'Navigation Menu' };

export const MODULE_FEATURE_URL_MAPPINGS = [
  /**
   * ==================================
   *        MODULE = MARKET ANALYSIS
   * ==================================
   **/
  {
    url: 'iframe/merit/pricing2/price',
    module: 'Market Analysis',
    feature: 'Market Price a Job'
  },
  {
    url: 'market-analysis/market-pricing',
    module: 'Market Analysis',
    feature: 'Market Price a Job'
  },
  {
    url: 'iframe/merit/merit-admin/survey_lib',
    module: 'Market Analysis',
    feature: 'Browse Survey Library'
  },
  {
    url: 'iframe/merit/pcenter/pcenter',
    module: 'Market Analysis',
    feature: 'Survey Participation'
  },
  {
    url: 'iframe/merit/merit-admin/slu/slu',
    module: 'Market Analysis',
    feature: 'Survey Library Update'
  },
  {
    url: 'iframe/merit/merit-admin/sources',
    module: 'Market Analysis',
    feature: 'Private Survey Sources'
  },
  {
    url: 'iframe/merit/merit-admin/survey_jobs',
    module: 'Market Analysis',
    feature: 'Private Survey Jobs'
  },
  {
    url: 'market-analysis/job-matching',
    module: 'Market Analysis',
    feature: 'Job Matching'
  },
  {
    url: 'market-analysis/rules-based-pricing',
    module: 'Market Analysis',
    feature: 'Rules Based Pricing'
  },
  /**
   * ==================================
   *        MODULE = REPORTS
   * ==================================
   **/
  {
    url: 'reports/library',
    module: 'Reports',
    feature: 'Report Library'
  },
  //  Legacy Report Writer
  {
    url: 'iframe/app/#reportWriter',
    module: 'Reports',
    feature: 'Report Library'
  },
  {
    url: 'iframe/merit/report-writer/report-select?report_writer_mode=data_mining',
    module: 'Reports',
    feature: 'Data Mining'
  },
  {
    url: 'iframe/module/#dashboards',
    module: 'Reports',
    feature: 'Data Analytics'
  },
  /**
   * ==================================
   *        MODULE = MODELING
   * ==================================
   **/
  {
    url: 'iframe/merit/structures/strucselect/structure-select',
    module: 'Modeling',
    feature: 'Model a Structure'
  },
  {
    url: 'iframe/merit/matrix2/matrix-select',
    module: 'Modeling',
    feature: 'Model a Merit Matrix'
  },
  {
    url: 'ranges',
    module: 'Modeling',
    feature: 'Job Based Ranges'
  },
  /**
   * ==================================
   *        MODULE = OUR DATA
   * ==================================
   **/
  {
    url: 'iframe/merit/hris/employee/employees',
    module: 'Our Data',
    feature: 'Employees'
  },
  {
    url: 'iframe/merit/hris/job/jobs',
    module: 'Our Data',
    feature: 'Jobs'
  },
  {
    url: 'iframe/merit/hris/structures/structures',
    module: 'Our Data',
    feature: 'Salary Range'
  },
  /**
   * ==================================
   *        MODULE = ADMIN
   * ==================================
   **/
  {
    url: 'iframe/merit/permissions/group-admin',
    module: 'Admin',
    feature: 'Group Administration'
  },
  {
    url: '/iframe/merit/permissions/group-admin-edit?initial_tab=configuration',
    module: 'Admin',
    feature: 'System Configuration'
  },
  {
    url: 'iframe/merit/permissions/group-admin-edit?initial_tab=users',
    module: 'Admin',
    feature: 'User Administration'
  },
  {
    url: 'iframe/merit/merit-admin',
    module: 'Admin',
    feature: 'Database Utilities'
  },
  {
    url: 'iframe/merit/spooler/feed-pre-upload',
    module: 'Admin',
    feature: 'Upload HRIS Data'
  },
  {
    url: 'iframe/merit/merit-admin/survey_lib?active_tab=1',
    module: 'Admin',
    feature: 'Upload Survey Data'
  },
  {
    url: 'iframe/merit/merit-admin/admin-tools',
    module: 'Admin',
    feature: 'MarketPay Utilities'
  }
];

export function getModuleOptions() {
  return MODULE_FEATURE_FUNCTION_OPTIONS.map(m => m.module);
}

export function getUrlMappingModuleFeature(url) {
  if (!url) return DEFAULT_MODULE_FEATURE;

  let bestMatch = null;
  for (const mapping of MODULE_FEATURE_URL_MAPPINGS) {
    //  if exact match of url postfix, return this entry
    if (url.endsWith(mapping.url) || url.endsWith(mapping.url + '/')) {
      return {
        module: mapping.module,
        feature: mapping.feature
      };
    }

    //  if not exact match, find a best match (if any)
    if (!mapping.isEndURL && url.includes(mapping.url)) {
      if (bestMatch && bestMatch.url.length > mapping.url.length) {
        continue;
      }
      bestMatch = mapping;
    }
  }

  if (bestMatch) {
    return {
      module: bestMatch.module,
      feature: bestMatch.feature
    };
  } else {
    return DEFAULT_MODULE_FEATURE;
  }
}

export function getDefaultFeature(module) {
  const moduleData = MODULE_FEATURE_FUNCTION_OPTIONS.find(m => m.module === module);
  if (!moduleData || !moduleData.defaultFeature) return null;

  return moduleData.defaultFeature;
}

export function getFeatureOptions(module) {
  const moduleData = MODULE_FEATURE_FUNCTION_OPTIONS.find(m => m.module === module);
  if (!moduleData || !moduleData.features) return null;

  return moduleData.features.map(c => c.feature);
}

export function getFunctionOptions(module, feature) {
  const moduleData = MODULE_FEATURE_FUNCTION_OPTIONS.find(m => m.module === module);
  if (!moduleData || !moduleData.features) return null;

  const featureData = moduleData.features.find(c => c.feature === feature);
  if (!featureData || !featureData.functions) return null;

  return featureData.functions;
}
