const GRADE_COUNT = 'GRADE_COUNT';
const GRADE_COUNT_SUCCESS = 'GRADE_COUNT_SUCCESS';
const GRADE_COUNT_FAIL = 'GRADE_COUNT_FAIL';

const initialState = {
  loading: false,
  loaded: false,
  error: null,
  numberOfGrades: null
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case GRADE_COUNT:
      return {
        ...state,
        loading: true
      };
    case GRADE_COUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: null,
        numberOfGrades: action.result.numberOfGrades
      };
    case GRADE_COUNT_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        data: null,
        error: action.error
      };
    default:
      return { ...state };
  }
}

export function getGradeCount(params = { es_query: 'viewed' }) {
  return {
    types: [GRADE_COUNT, GRADE_COUNT_SUCCESS, GRADE_COUNT_FAIL],
    promise: client => client.get('/api/structures/grades/totalGrades', { params: params })
  };
}
