/*eslint-disable*/

/*
 Overall this file is not ideal, but environment variables only work at compile time.
 A better strategy is to inject content into the html file with node server-side rendering or query nginx,
 but out of scope for Lab+Identity MVP and both have downsides too.
 */
module.exports = function normalizeUrl() {
  const labReResult = /\/view\/o\/(.+)\/lab\/?.*/.exec(window.location.pathname);
  const mpReResult = /\/view\/o\/(.+)\/lab\/?.*/.exec(window.location.pathname);

  let productCode = 'lab';
  let reResult = labReResult;
  if (!labReResult && mpReResult) {
    productCode = 'marketpay';
    reResult = mpReResult;
  }

  const defaultIdentityScopes = ['openid', 'profile', 'jobservice'];

  const hostnameConfigMap = {
    'lab.payscale.com': {
      reactAppDeployEnv: 'production',
      identityAuthority: 'https://accounts.payscale.com',
      identityClientId: 'lab',
      identityScopes: defaultIdentityScopes,
      featureFlagServiceHost: 'https://features.payscale.com'
    },
    'marketpay.payscale.com': {
      reactAppDeployEnv: 'production',
      identityAuthority: 'https://accounts.payscale.com',
      identityClientId: 'lab',
      identityScopes: defaultIdentityScopes,
      featureFlagServiceHost: 'https://features.payscale.com'
    },
    'dev.localhost.com': {
      reactAppDeployEnv: 'localhost',
      identityAuthority: 'https://accounts.prestaging.underpaid.com',
      identityClientId: 'lab-test',
      identityScopes: defaultIdentityScopes,
      featureFlagServiceHost: 'https://dev.localhost.com:7023'
    },
    'lab-dev.payscale.com': {
      reactAppDeployEnv: 'development',
      identityAuthority: 'https://accounts.prestaging.underpaid.com',
      identityClientId: 'lab-test',
      identityScopes: defaultIdentityScopes,
      featureFlagServiceHost: 'https://features-dev.payscale.com'
    },
    'lab-ci-identity.payscale.com': {
      reactAppDeployEnv: 'development',
      identityAuthority: 'https://accounts.prestaging.underpaid.com',
      identityClientId: 'lab-test',
      identityScopes: defaultIdentityScopes,
      featureFlagServiceHost: 'https://lab-ci-identity.payscale.com:7020'
    },
    'lab-test.payscale.com': {
      reactAppDeployEnv: 'test',
      identityAuthority: 'https://accounts.prestaging.underpaid.com',
      identityClientId: 'lab-test',
      identityScopes: defaultIdentityScopes,
      featureFlagServiceHost: 'https://features-pre.payscale.com'
    },
    'lab-dyson.payscale.com': {
      reactAppDeployEnv: 'development',
      identityAuthority: 'https://accounts.prestaging.underpaid.com',
      identityClientId: 'lab-test',
      identityScopes: defaultIdentityScopes,
      featureFlagServiceHost: 'https://lab-dyson.payscale.com:7020'
    },
    'lab-ci.payscale.com': {
      reactAppDeployEnv: 'development',
      identityAuthority: 'https://accounts.ci.payscale.com',
      identityClientId: 'lab',
      identityScopes: defaultIdentityScopes,
      featureFlagServiceHost: 'https://lab-ci.payscale.com:7020'
    },
    'mp-ci.payscale.com': {
      reactAppDeployEnv: 'development',
      identityAuthority: 'https://accounts.ci.payscale.com',
      identityClientId: 'lab',
      identityScopes: defaultIdentityScopes,
      featureFlagServiceHost: 'https://lab-ci.payscale.com:7020'
    },
    'lab-collab.payscale.com': {
      reactAppDeployEnv: 'development',
      identityAuthority: 'https://accounts.prestaging.underpaid.com',
      identityClientId: 'lab-test',
      identityScopes: defaultIdentityScopes,
      featureFlagServiceHost: 'https://lab-collab.payscale.com:7020'
    },
    'lab-feature.payscale.com': {
      reactAppDeployEnv: 'development',
      identityAuthority: 'https://accounts.prestaging.underpaid.com',
      identityClientId: 'lab-test',
      identityScopes: defaultIdentityScopes,
      featureFlagServiceHost: 'https://lab-feature.payscale.com:7020'
    }
  };

  const configByHostname = hostnameConfigMap[window.location.hostname];

  // MP rc environments
  if (window.location.hostname.match('rc3')) {
    configByHostname.reactAppDeployEnv = 'test';
  } else if (window.location.hostname.match('rc*.marketpay')) {
    configByHostname.reactAppDeployEnv = 'development';
  }

  let identityMode = process.env.AUTH_MODE || 'identityServer';
  const authModeOverride = localStorage.getItem('authModeOverride');
  if (authModeOverride && identityMode !== authModeOverride) {
    console.warn(
      `app would normally run identityMode=${identityMode}, overriding with authModeOverride=${authModeOverride} from your localStorage`
    );
    identityMode = authModeOverride;
  }

  const orgCode = reResult && reResult[1];

  return {
    appPrefix: '/',
    apiPrefix: `/${orgCode}/app/`,
    baseName: `/view/o/${orgCode}/lab/`,
    orgCode,
    nodeAppPrefix: `/${orgCode}/app/`,
    reactAppDeployEnv: configByHostname.reactAppDeployEnv,
    productCode,
    identityMode,
    identityAuthority: configByHostname.identityAuthority,
    identityClientId: configByHostname.identityClientId,
    identityMountpoint: `${window.location.origin}/view/o/login/lab`,
    identityScopes: configByHostname.identityScopes,
    featureFlagServiceHost: configByHostname.featureFlagServiceHost
  };
};
