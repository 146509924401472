import React, { Component } from 'react';
import { array, bool, func, string } from 'prop-types';
import classnames from 'classnames';
import './ProposedRangeSetting.scss';
import Select from '../../Select/Select';

const propTypes = {
  className: string,
  handleProposedRangeSelect: func.isRequired,
  isBuildMinMaxBy: bool,
  proposedRangeSetting: string,
  rangeOptions: array
};

const defaultProps = {
  isBuildMinMaxBy: false,
  rangeOptions: []
};

const ProposedRangeSetting = props => {
  const { className, handleProposedRangeSelect, isBuildMinMaxBy, proposedRangeSetting, rangeOptions } = props;
  const proposedRangeDefaultOption = rangeOptions.find(opt => opt.value === proposedRangeSetting);
  const testId = 'jbr-proposed-setting';
  return (
    <div className={classnames('proposed-range-setting--container', className)} data-test-id={testId}>
      {!isBuildMinMaxBy && <h5 className="proposed__range-setting-title">Set Proposed Ranges To</h5>}
      <Select
        testId={testId}
        btnClass="btn-dark"
        dropdownClass="dropdown-dark"
        options={rangeOptions}
        onSelect={handleProposedRangeSelect}
        defaultOption={proposedRangeDefaultOption}
      />
    </div>
  );
};

ProposedRangeSetting.defaultProps = defaultProps;
ProposedRangeSetting.propTypes = propTypes;
export default ProposedRangeSetting;
