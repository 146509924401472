import classnames from 'classnames';

/**
 * Builds the class name string with a static class name with an optional class
 * @param className static class name
 * @param optionalClassName optional class name
 * @returns {*} full class name string
 */
export const getClassNameWithOptionalClass = (className, optionalClassName) => {
  return classnames({
    [className]: true,
    [optionalClassName]: optionalClassName
  });
};
