import {
  JOB_RANGES_MODEL_JOB_ROW_CLICK,
  JOB_RANGES_RUN_COST_ANALYSIS,
  JOB_RANGES_RUN_COST_ANALYSIS_FAIL,
  JOB_RANGES_RUN_COST_ANALYSIS_SUCCESS,
  JOB_RANGES_RUN_COST_ANALYSIS_EMPLOYEES_SUCCESS,
  JOB_RANGES_RUN_COST_ANALYSIS_EMPLOYEES_FAIL,
  JOB_RANGES_NAVIGATE_JOB,
  GET_JBR_AUDIT_ITEMS_SUCCESS,
  JBR_AUDIT_MODAL_OPEN,
  JBR_AUDIT_MODAL_CLOSE,
  SEARCH_JBR_AUDIT_ITEMS,
  GET_JOB_LAST_MODIFIED_DATE_ERROR,
  GET_JOB_LAST_MODIFIED_DATE_SUCCESS
} from '../../actions/jobRangeDetails';
import {
  JOB_RANGES_JOB_DETAILS_TOGGLE_EDIT_RANGE_MODEL,
  JOB_RANGES_JOB_DETAILS_UPDATE_JOB,
  SAVE_JOB_RANGE_MODEL_JOBS_SUCCESS,
  JOB_RANGES_JOB_DETAILS_UPDATE_JOB_FAIL
} from '../../actions/jobRangeDetails/JobDetailsSlideOutSettings';
import { HIDE_SLIDEOUT } from '../../../../Slideout/SlideoutReducer';

const initialState = {
  job: null,
  index: null,
  loading: false,
  summaryStats: null,
  showEditJobRangeModel: true,
  employeesLimit: 20,
  employeesOffset: 0,
  employees: [],
  auditItems: [],
  auditModalIsOpen: false,
  error: null,
  lastModifiedDate: null
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case GET_JOB_LAST_MODIFIED_DATE_SUCCESS:
      return {
        ...state,
        lastModifiedDate: action.lastModifiedDate || 0
      };
    case GET_JOB_LAST_MODIFIED_DATE_ERROR:
      return {
        ...state,
        lastModifiedDate: null
      };
    case JOB_RANGES_MODEL_JOB_ROW_CLICK:
      return {
        ...state,
        ...initialState,
        job: action.job,
        index: action.index
      };
    case JOB_RANGES_JOB_DETAILS_UPDATE_JOB:
      return {
        ...state,
        job: action.newJob
      };
    case JOB_RANGES_JOB_DETAILS_UPDATE_JOB_FAIL:
      return {
        ...state,
        error: action.error
      };
    case JOB_RANGES_RUN_COST_ANALYSIS:
      return { ...state, loading: true };
    case JOB_RANGES_RUN_COST_ANALYSIS_FAIL:
      return { ...state, loading: false };
    case JOB_RANGES_RUN_COST_ANALYSIS_EMPLOYEES_FAIL:
      return { ...state, loading: false };
    case JOB_RANGES_RUN_COST_ANALYSIS_SUCCESS:
      const currJobId = state.job && state.job.job_range_job_id;
      if (currJobId !== action.job.job_range_job_id) return { ...state, loading: false };
      return {
        ...state,
        loading: false,
        employees: action.employees,
        summaryStats: action.summaryStats
      };
    case JOB_RANGES_NAVIGATE_JOB:
      return {
        ...state,
        ...initialState,
        job: action.newJob,
        index: action.newIndex
      };
    case HIDE_SLIDEOUT:
      return { ...state, ...initialState };
    case JOB_RANGES_RUN_COST_ANALYSIS_EMPLOYEES_SUCCESS:
      return {
        ...state,
        loading: false,
        employeesLimit: action.limit,
        employeesOffset: action.offset,
        employees: state.employees.concat(action.result)
      };
    case JOB_RANGES_JOB_DETAILS_TOGGLE_EDIT_RANGE_MODEL:
      return {
        ...state,
        showEditJobRangeModel: action.value
      };
    case GET_JBR_AUDIT_ITEMS_SUCCESS:
      return { ...state, auditItems: action.auditItems };
    case JBR_AUDIT_MODAL_OPEN:
      return { ...state, auditModalIsOpen: true };
    case JBR_AUDIT_MODAL_CLOSE:
      return { ...state, auditModalIsOpen: false };
    case SEARCH_JBR_AUDIT_ITEMS:
      return { ...state, auditItems: action.auditItems };
    default:
      return state;
  }
}
