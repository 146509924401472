import unionBy from 'lodash/unionBy';

import {
  TOGGLE_ALL_JOBS_VS_SELECTED_LIST,
  GET_SINGLE_JOB_RANGE_MODEL_SUCCESS,
  APPLY_JOBS_TO_RANGE,
  APPLY_JOBS_TO_RANGE_SUCCESS,
  APPLY_JOBS_TO_RANGE_FAIL,
  CREATE_NEW_JOB_RANGE_MODEL,
  JOB_RANGES_JOB_CLICK,
  JOB_RANGES_AVAILABLE_CHECK_ALL,
  JOB_RANGES_SELECTED_CHECK_ALL,
  JOB_RANGES_UPDATE_SELECTED_JOBS,
  JOB_RANGES_JOB_SEARCH_INPUT_CHANGE,
  JOB_RANGES_SEARCH_CLEAR_FACETS_FILTER,
  JOB_RANGES_BULK_JOB_CLEAR,
  JOB_RANGES_VIEW_SELECTED_JOBS_SHOW_MORE
} from '../../../actions/JobRanges';

import {
  JOB_RANGES_SEARCH_AND_BULK_ADD_INIT,
  JOB_RANGES_SEARCH_AND_BULK_ADD_SUCCESS,
  JOB_RANGES_SEARCH_AND_BULK_ADD_FAIL
} from './jobRangesSearch';

const SHOW_SLIDEOUT = 'JOB_RANGES_SHOW_SLIDEOUT';

const initialState = {
  selectedJobs: [],
  selectedJobsSnapshot: [],
  bulkAddSucceeded: false,
  bulkAddInProgress: false,
  applyJobsLoading: false,
  sort: {},
  allowSort: false,
  allowCheckAll: true,
  searchText: '',
  isAllAvailableJobsChecked: false,
  isAllSelectJobsChecked: true,
  expandedMaxColumnCount: 1,
  showAllJobsVsSelected: true,
  selectedJobsViewScopeLimit: 100,
  columns: [
    {
      title: 'Job Title',
      dataKey: 'org_job_title',
      field_type_code: 'text'
    },
    {
      title: 'Job Code',
      dataKey: 'org_job_code',
      field_type_code: 'text'
    },
    {
      title: 'Job Code Key',
      dataKey: 'org_job_code_key',
      field_type_code: 'text'
    }
  ]
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case JOB_RANGES_JOB_CLICK:
      const selectedJobs = jobClickReducer(state, action);
      return {
        ...state,
        selectedJobs: selectedJobs,
        isAllSelectJobsChecked: selectedJobs && selectedJobs.length > 0
      };
    case JOB_RANGES_SELECTED_CHECK_ALL:
      return {
        ...state,
        isAllSelectJobsChecked: action.checked
      };
    case JOB_RANGES_AVAILABLE_CHECK_ALL:
      return { ...state, isAllAvailableJobsChecked: action.checked };
    case TOGGLE_ALL_JOBS_VS_SELECTED_LIST:
      return { ...state, showAllJobsVsSelected: !state.showAllJobsVsSelected };
    case JOB_RANGES_VIEW_SELECTED_JOBS_SHOW_MORE:
      return {
        ...state,
        selectedJobsViewScopeLimit: state.selectedJobsViewScopeLimit + 100
      };
    case JOB_RANGES_SEARCH_AND_BULK_ADD_INIT:
      return {
        ...state,
        bulkAddInProgress: true
      };
    case JOB_RANGES_SEARCH_AND_BULK_ADD_SUCCESS: {
      return {
        ...state,
        bulkAddInProgress: false,
        bulkAddSucceeded: true,
        selectedJobs: mergeSelectedJobs(state.selectedJobs, action.jobs),
        isAllAvailableJobsChecked: !state.isAllAvailableJobsChecked
      };
    }
    case JOB_RANGES_SEARCH_AND_BULK_ADD_FAIL:
      return {
        ...state,
        bulkAddInProgress: false
      };
    case JOB_RANGES_BULK_JOB_CLEAR:
      return {
        ...state,
        selectedJobs: [],
        isAllSelectJobsChecked: false
      };
    case 'JOB_RANGES_SEARCH_SUCCESS':
      return {
        ...state,
        bulkAddSucceeded: false
      };
    case JOB_RANGES_UPDATE_SELECTED_JOBS:
      return {
        ...state,
        bulkAddSucceeded: false,
        selectedJobs: action.jobs,
        isAllSelectJobsChecked: action.jobs && action.jobs.length > 0,
        isAllAvailableJobsChecked: action.jobs && action.jobs.length > 0
      };
    case JOB_RANGES_JOB_SEARCH_INPUT_CHANGE:
      return {
        ...state,
        searchText: action.searchInputValue
      };
    case JOB_RANGES_SEARCH_CLEAR_FACETS_FILTER:
      return {
        ...state,
        searchText: ''
      };
    case SHOW_SLIDEOUT:
      return {
        ...state,
        showAllJobsVsSelected: true
      };
    case GET_SINGLE_JOB_RANGE_MODEL_SUCCESS:
      return {
        ...state,
        selectedJobs: action.jobRangeModelSelectedJobs,
        selectedJobsSnapshot: [...action.jobRangeModelSelectedJobs],
        isAllSelectJobsChecked: action.jobRangeModelSelectedJobs && action.jobRangeModelSelectedJobs.length > 0
      };
    case APPLY_JOBS_TO_RANGE:
      return { ...state, applyJobsLoading: true };
    case APPLY_JOBS_TO_RANGE_SUCCESS:
      return {
        ...state,
        selectedJobsSnapshot: action.newSelectedJobsSnapshot,
        applyJobsLoading: false
      };
    case APPLY_JOBS_TO_RANGE_FAIL:
      return { ...state, applyJobsLoading: false };
    case CREATE_NEW_JOB_RANGE_MODEL:
      return {
        ...state,
        selectedJobs: [],
        selectedJobsSnapshot: [],
        isAllSelectJobsChecked: false
      };
    default:
      return state;
  }
}

const jobClickReducer = (state, action) => {
  //adds job to selected list if not already there. Otherwise, it's removed;
  const newSelectedJobs = state.selectedJobs.filter(selectedJob => selectedJob.id !== action.job.id);
  if (state.selectedJobs.length === newSelectedJobs.length) newSelectedJobs.push(action.job);
  return newSelectedJobs;
};

const mergeSelectedJobs = (currentJobs, addedJobs) => {
  const newJobs = unionBy(currentJobs, addedJobs, 'id');
  return newJobs;
};
