import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

class TableBody extends Component {
  static propTypes = {
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    className: PropTypes.string,
    style: PropTypes.object,
    refBody: PropTypes.object
  };

  render() {
    const { children, className, style, refBody } = this.props;

    return (
      <tbody ref={refBody} className={classnames('table__body', className)} style={style}>
        {children}
      </tbody>
    );
  }
}

export default TableBody;
