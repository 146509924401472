import React from 'react';
import { toast } from 'react-toastify';
import AlertToast from './AlertToast';

function isLink(msg) {
  if (typeof msg === 'object' && msg !== null && msg.link) {
    return msg;
  }

  return null;
}

const messages = {
  success: 'Success!',
  danger: 'Error!',
  warning: 'Warning!'
};

// slideoutType = 'top-center', 'top-right', 'top-left'
// level = 'success', 'danger', 'info', or 'warning'
export default function(message, level = 'success', timeout = 3000, position = 'top-center', hideContactSupport) {
  const defaultTimeout = level === 'danger' ? 5000 : 3000;
  const autoClose = timeout === 'manual' ? false : timeout || defaultTimeout;

  // allow 'warn' and 'error'
  let newLevel = level === 'warn' ? 'warning' : level;
  newLevel = level === 'error' ? 'danger' : newLevel;

  if (!message) {
    message = messages[newLevel] || '';
  }

  toast(
    <AlertToast
      message={
        Array.isArray(message) ? (
          <span>
            {message.map(msg => {
              if (isLink(msg)) {
                return (
                  <a className="alert-toast-link" href={msg.link} target="_blank">
                    {msg.text}
                  </a>
                );
              } else {
                return <div>{msg}</div>;
              }
            })}
          </span>
        ) : (
          message
        )
      }
      level={newLevel}
      hideContactSupport={hideContactSupport}
    />,
    {
      autoClose,
      position,
      className: newLevel
    }
  );
}
