import JobRangesSlideOutContent from '../../src/Modules/JobRanges/JobRangesSlideOutContent';
import JobRangesSummarySlideOut from '../../src/Modules/JobRanges/ModelDetailsPage/JobRangesSummarySlideOut/JobRangesSummarySlideOut';
import JobRangesJobDetailsSlideOut from '../../src/Modules/JobRanges/ModelDetailsPage/JobRangesJobDetailsSlideOut/JobRangesJobDetailsSlideOut';
import ContactSupportFormWrapper from '../Modules/Support/containers/ContactSupport/ContactSupportFormWrapper';
import ReviewSlideout from '../../src/Modules/ParticipationCenter/containers/MatchReview/ReviewSlideout';
import JobDescriptionSlideout from '../../src/JobDescriptionSlideout/JobDescriptionSlideout';

import {
  LINK_HASH_JOB_RANGES,
  LINK_HASH_JOB_RANGES_JOB_DETAILS,
  LINK_HASH_JOB_RANGES_SUMMARY,
  LINK_HASH_PCENTER_MATCH_REVIEW,
  LINK_HASH_SUPPORT_CONTACT,
  LINK_GEO_DIFFERENTIAL_SETTINGS
} from './SlideoutAddresses';

export const SLIDEOUTS = {
  CONTACT_SUPPORT_SLIDEOUT: {
    component: ContactSupportFormWrapper,
    hash: LINK_HASH_SUPPORT_CONTACT,
    containerClassName: 'contact-support-form-wrapper',
    defaultProps: {
      addAttachmentImage: '/view/static/mp/images/document-add.svg',
      enableWindowStateChangeClose: false
    }
  },
  PARTICIPATION_CENTER_MATCH_REVIEW_SLIDEOUT: {
    component: ReviewSlideout,
    containerClassName: 'pcenter-match-review-slideout',
    hash: LINK_HASH_PCENTER_MATCH_REVIEW
  },
  JOB_RANGES_SLIDEOUT: {
    component: JobRangesSlideOutContent,
    hash: LINK_HASH_JOB_RANGES
  },
  JOB_RANGES_SUMMARY: {
    component: JobRangesSummarySlideOut,
    hash: LINK_HASH_JOB_RANGES_SUMMARY
  },
  JOB_RANGES_JOB_DETAILS: {
    component: JobRangesJobDetailsSlideOut,
    hash: LINK_HASH_JOB_RANGES_JOB_DETAILS
  },
  JOB_DESCRIPTION_SLIDEOUT: {
    component: JobDescriptionSlideout,
    containerClassName: 'job-detail-panel'
  }
};
