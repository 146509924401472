import PropTypes from 'prop-types';
import React, { Component } from 'react';
import TableInfoList from './../../../../../TableInfoList/TableInfoList';
import './JobDetailsEmployeeSummary.scss';
import { numberWithCommas, applyRounding, parseNumCheckNull } from '../../../../../lib/formatters';

const propTypes = {
  total_employees: PropTypes.string,
  below_min: PropTypes.string,
  above_max: PropTypes.string,
  total_cost_to_min: PropTypes.number,
  preferences: PropTypes.object
};

const JobDetailsEmployeeSummary = props => {
  const totalEmployees =
    !props.total_employees && props.total_employees !== 0 ? '' : numberWithCommas(props.total_employees);

  const belowMin = !props.below_min && props.below_min !== 0 ? '' : numberWithCommas(props.below_min);

  const aboveMax = !props.above_max && props.above_max !== 0 ? '' : numberWithCommas(props.above_max);

  const costToMin =
    !props.total_cost_to_min && props.total_cost_to_min !== 0
      ? '0'
      : parseNumCheckNull(applyRounding(props.total_cost_to_min, 'monetary', props.preferences));

  const headers = ['Total Employees', '# Below Min', '# Above Max', 'Cost to Min'],
    rows = [totalEmployees, belowMin, aboveMax, costToMin];
  return (
    <TableInfoList
      tableClass="job-details-employee-summary__content"
      cellClass="job-details-employee-summary__field-value"
      headerClass="job-details-employee-summary__field-name"
      rowClass="job-details-employee-summary__field"
      bodyClass="job-details-employee-summary__tbody"
      headers={headers}
      data={rows}
    />
  );
};

JobDetailsEmployeeSummary.propTypes = propTypes;

export default JobDetailsEmployeeSummary;
