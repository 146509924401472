import apiClient from '../../../lib/apiClient';
import { JOB_DETAILS_SINGLE_START_LOAD, isActionForCurrentJob } from './jobDetails';

const GET_MARKET_SALARY = 'GET_MARKET_SALARY';
const GET_MARKET_SALARY_SUCCESS = 'GET_MARKET_SALARY_SUCCESS';
const GET_MARKET_SALARY_FAIL = 'GET_MARKET_SALARY_FAIL';

const initialState = {
  marketBaseSalary25th: null,
  marketBaseSalary50th: 0,
  marketBaseSalary75th: null,
  loadingJobId: null
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case GET_MARKET_SALARY:
      return {
        ...state
      };
    case GET_MARKET_SALARY_SUCCESS:
      if (!isActionForCurrentJob(action.itemId, state.loadingJobId)) {
        return {
          ...state
        };
      }

      let marketBaseSalary50th, marketBaseSalary25th, marketBaseSalary75th;
      if (action && action.result && action.result.market_base_salary_25th) {
        marketBaseSalary25th = action.result.market_base_salary_25th;
      } else if (action && action.market_base_salary_25th) {
        marketBaseSalary25th = action.market_base_salary_25th;
      }
      if (action && action.result && action.result.market_base_salary_75th) {
        marketBaseSalary75th = action.result.market_base_salary_75th;
      } else if (action && action.market_base_salary_75th) {
        marketBaseSalary75th = action.market_base_salary_75th;
      }
      if (action && action.result && action.result.market_base_salary_50th) {
        marketBaseSalary50th = action.result.market_base_salary_50th;
      } else if (action && action.market_base_salary_50th) {
        marketBaseSalary50th = action.market_base_salary_50th;
      }
      return {
        ...state,
        marketBaseSalary25th: marketBaseSalary25th,
        marketBaseSalary50th: marketBaseSalary50th,
        marketBaseSalary75th: marketBaseSalary75th,
        error: null
      };
    case GET_MARKET_SALARY_FAIL:
      return {
        ...state,
        error: action.error
      };
    case JOB_DETAILS_SINGLE_START_LOAD:
      return {
        ...state,
        loadingJobId: action.id
      };
    default:
      return { ...state };
  }
}

export function getUpdatedMarketSalary(options) {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: GET_MARKET_SALARY });
      const result = await apiClient.apiGet('/api/hris/job-details/getJobDetails', {
        params: {
          org_job_id: options.orgJobId
        }
      });
      dispatch({ type: GET_MARKET_SALARY_SUCCESS, result: result.data, itemId: options.orgJobId });
    } catch (error) {
      dispatch({ type: GET_MARKET_SALARY_FAIL, error: error });
    }
  };
}

export function getUpdatedMarketData(options) {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: GET_MARKET_SALARY });
      const result = await apiClient.apiGet('/api/hris/job-details/getJobMarketData', {
        params: {
          org_job_id: options.orgJobId
        }
      });
      if (result.data.success && result.data.orgJobMarketData) {
        dispatch({ type: GET_MARKET_SALARY_SUCCESS, result: result.data.orgJobMarketData, itemId: options.orgJobId });
      } else {
        dispatch({ type: GET_MARKET_SALARY_FAIL, error: 'No market data' });
      }
    } catch (error) {
      dispatch({ type: GET_MARKET_SALARY_FAIL, error: error });
    }
  };
}
