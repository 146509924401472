import React, { Component } from 'react';
import { getSentryApiKey } from '../Utils/sentryApiKey';

export default class SentryLogging extends Component {
  componentDidMount() {
    const libScript = document.createElement('script');
    libScript.type = 'text/javascript';
    libScript.async = true;
    libScript.crossOrigin = 'anonymous';
    libScript.src = 'https://cdn.ravenjs.com/3.25.2/raven.min.js';
    libScript.id = 'raven';

    const activateScript = document.createElement('script');
    activateScript.type = 'text/javascript';

    const publicDSN = getSentryApiKey();
    if (publicDSN) {
      this.instance.appendChild(libScript);

      activateScript.innerHTML = `(function(b,e,c,d){b.onerror=function(a,b,d,f,g){c||e.push([a,b,d,f,g])};b.onunhandledrejection=function(a){c||e.push([a.reason.reason||a.reason.message,a.type,JSON.stringify(a.reason)])};d.onreadystatechange=d.onload=function(){c||(c=!0,Raven.config("${publicDSN}").install(),b.onunhandledrejection=function(a){Raven.captureException(Error(a.reason.reason||a.reason.message),{extra:{type:a.type,reason:JSON.stringify(a.reason)}})},e.forEach(function(a){Raven.captureException(a[4]||Error(a[0]),{extra:{file:a[1],line:a[2],col:a[3]}})}))}})(window,[],!1,document.getElementById("raven"));`;
      this.instance.appendChild(activateScript);
    }
  }

  render() {
    return <div ref={el => (this.instance = el)} />;
  }
}
