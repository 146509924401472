import { mapDispatchToActions } from '../../../../../panther-react/src/helpers/utils.js';

const JOBALYZER_LIST = 'JOBALYZER_LIST';
const JOBALYZER_LIST_SUCCESS = 'JOBALYZER_LIST_SUCCESS';
const JOBALYZER_LIST_FAILURE = 'JOBALYZER_LIST_FAILURE';
const COMPANY_MIGRATION_LIST = 'COMPANY_MIGRATION_LIST';
const COMPANY_MIGRATION_LIST_SUCCESS = 'COMPANY_MIGRATION_LIST_SUCCESS';
const COMPANY_MIGRATION_LIST_FAILURE = 'COMPANY_MIGRATION_LIST_FAILURE';

export const initialState = {
  jobalyzerListLoading: false,
  companySourceListLoading: false,
  auditRefreshList: [],
  existingRefreshList: [],
  companySourceMigrationList: [],
  companySourceAuditList: [],
  error: null
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case JOBALYZER_LIST:
      return {
        ...state,
        jobalyzerListLoading: true
      };
    case JOBALYZER_LIST_SUCCESS:
      return {
        ...state,
        jobalyzerListLoading: false,
        error: null,
        auditRefreshList: action.result.auditRefreshList,
        existingRefreshList: action.result.existingRefreshList
      };
    case JOBALYZER_LIST_FAILURE:
      return {
        ...state,
        jobalyzerListLoading: false,
        auditRefreshList: [],
        existingRefreshList: [],
        error: action.error
      };
    case COMPANY_MIGRATION_LIST:
      return {
        ...state,
        companySourceListLoading: true
      };
    case COMPANY_MIGRATION_LIST_SUCCESS:
      return {
        ...state,
        companySourceMigrationList: action.result.companySourceMigrationList,
        companySourceAuditList: action.result.companySourceAuditList,
        error: null,
        companySourceListLoading: false
      };
    case COMPANY_MIGRATION_LIST_FAILURE:
      return {
        ...state,
        companySourceMigrationList: [],
        companySourceAuditList: [],
        error: action.error,
        companySourceListLoading: false
      };
    default:
      return { ...state };
  }
}

export const actions = {};

actions.getJobalyzerList = function getjobalyzerList(options) {
  return (dispatch, getState) => {
    dispatch({
      types: [JOBALYZER_LIST, JOBALYZER_LIST_SUCCESS, JOBALYZER_LIST_FAILURE],
      promise: client => client.get('/api/hris/jobalyzer-bulk/jobalyzerRefreshList', { params: options })
    });
  };
};

actions.getCompanySourceMigrationList = function getjobalyzerList(options) {
  return (dispatch, getState) => {
    dispatch({
      types: [COMPANY_MIGRATION_LIST, COMPANY_MIGRATION_LIST_SUCCESS, COMPANY_MIGRATION_LIST_FAILURE],
      promise: client => client.get('/api/hris/jobalyzer-bulk/companySourcedMigrationList', { params: options })
    });
  };
};

export const bulkActions = mapDispatchToActions(actions);
