const EDIT_BUDGET_PCT = 'EDIT_BUDGET_PCT';
const SHOW_BUDGET_CHANGE_MSG = 'SHOW_BUDGET_CHANGE_MSG';
const CANCEL_BUDGET_PCT_EDIT = 'CANCEL_BUDGET_PCT_EDIT';
const SAVE_BUDGET_PCT = 'SAVE_BUDGET_PCT';
const CHANGE_BUDGET_INPUT = 'CHANGE_BUDGET_INPUT';
const SHOW_HEAD_STAT_MSG = 'SHOW_HEAD_STAT_MSG';
const GET_MATRIX_SET_SUCCESS = 'GET_MATRIX_SET_SUCCESS';
const BUDGET_VAL_CALC = 'BUDGET_VAL_CALC';
const BUDGET_PCT_CALC = 'BUDGET_PCT_CALC';
import { createInitialMatrixSet } from './groupList';

const initialState = {
  loading: false,
  loaded: false,
  error: null,
  totalBudgetPct: 0,
  totalBudgetPctTemp: 0,
  changeBudgetPctInput: false,
  totalBudgetDollar: 0,
  outliers90Current: 0,
  outliers10Current: 0,
  outliers90Proposed: 0,
  outliers10Proposed: 0,
  compaRatioCurrent: 0,
  compaRatioProposed: 0,
  marketRatioCurrent: 0,
  marketRatioProposed: 0,
  totalEmployeesIncluded: 0,
  totalEmployeesExcluded: 0,
  budgetChangeMsg: false
};
const roundToHudreths = value => {
  if (!value) return value;
  return Math.round(value * 100) / 100;
};
const roundStats = stats => {
  return {
    compaRatioCurrent: roundToHudreths(stats.avgCompaRatio),
    compaRatioProposed: roundToHudreths(stats.proposedCompaRatio),
    marketRatioCurrent: roundToHudreths(stats.avgMarketRatio),
    marketRatioProposed: roundToHudreths(stats.proposedMarketRatio),
    outliers90Current: stats.outliers90Current,
    outliers10Current: stats.outliers10Current,
    outliers90Proposed: stats.outliers90Proposed,
    outliers10Proposed: stats.outliers10Proposed
  };
};
const receiveNewMatrixSet = (state, action) => {
  let totalEmployeesIncluded = 0;
  let totalBasePay = 0;
  if (action.result.matrixList.length == 0) {
    return { ...state };
  }
  for (let i = 0; i < action.result.matrixList.length; i++) {
    totalEmployeesIncluded += action.result.matrixList[i].employeeCount;
    totalBasePay += action.result.matrixList[i].currentBasePay;
  }
  const defaultBudgetIncreasePercent = action.result.matrixList[0].defaultBudgetIncreasePercent;
  let totalBudgetDollar = totalBasePay * defaultBudgetIncreasePercent;
  if (action.result.totalBasePayConverted) {
    totalBudgetDollar = action.result.totalBasePayConverted;
  }
  const totalOrgEmployeeCount = action.result.totalOrgEmployeeCount;
  return {
    ...state,
    totalEmployeesIncluded: totalEmployeesIncluded,
    totalEmployeesExcluded: totalOrgEmployeeCount - totalEmployeesIncluded,
    totalBudgetPct: parseFloat(
      action.result.matrixList[0].defaultBudgetIncreasePercent * 100
    ).toFixed(1),
    totalBudgetPctTemp: parseFloat(
      action.result.matrixList[0].defaultBudgetIncreasePercent * 100
    ).toFixed(1),
    totalBudgetDollar
  };
};
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case 'SAVE_MATRIX_GROUP_MODEL_SUCCESS':
      const stats = action.result.summaryStats;

      return {
        ...state,
        ...roundStats(stats),
        totalBudgetDollar: stats.totalBasePayConverted
      };
    case 'CALC_SUMMARY_STATS_SUCCESS':
      const sumStats = action.result.summaryStats;
      return {
        ...state,
        ...roundStats(sumStats)
      };
    case EDIT_BUDGET_PCT:
      return {
        ...state,
        totalBudgetPctTemp: action.totalBudgetPctTemp
      };
    case SHOW_BUDGET_CHANGE_MSG:
      return {
        ...state,
        budgetChangeMsg: true
      };
    case CANCEL_BUDGET_PCT_EDIT:
      return {
        ...state,
        budgetChangeMsg: false,
        changeBudgetPctInput: false,
        totalBudgetPctTemp: action.totalBudgetPctTemp
      };
    case SAVE_BUDGET_PCT:
      return {
        ...state,
        budgetChangeMsg: false,
        totalBudgetPct: action.newBudgetPct,
        changeBudgetPctInput: false
      };
    case CHANGE_BUDGET_INPUT:
      return {
        ...state,
        changeBudgetPctInput: true
      };
    case GET_MATRIX_SET_SUCCESS:
      return receiveNewMatrixSet(state, action);
    case 'MATRIX_APPLY_FILTERS_SUCCESS':
      return receiveNewMatrixSet(state, action);
    case BUDGET_VAL_CALC:
      return {
        ...state,
        totalBudgetPct: action.globalPct,
        totalBudgetPctTemp: action.globalPct
      };
    case BUDGET_PCT_CALC:
      return {
        ...state,
        totalBudgetPct: action.globalPct,
        totalBudgetPctTemp: action.globalPct
      };
    default:
      return state;
  }
}

export function editBudgetPct(options) {
  return (dispatch, getState) => {
    dispatch({
      type: 'EDIT_BUDGET_PCT',
      totalBudgetPctTemp: options.totalBudgetPctTemp
    });
  };
}

export function cancelEditBudgetPct() {
  return (dispatch, getState) => {
    dispatch({
      type: 'CANCEL_BUDGET_PCT_EDIT',
      totalBudgetPctTemp: getState().headStats.totalBudgetPct
    });
  };
}

export function showBudgetChangeMsg() {
  return (dispatch, getState) => {
    if (getState().headStats.totalBudgetPctTemp == getState().headStats.totalBudgetPct) {
      dispatch({
        type: 'CANCEL_BUDGET_PCT_EDIT',
        totalBudgetPctTemp: getState().headStats.totalBudgetPct
      });
    } else {
      dispatch({
        type: 'SHOW_BUDGET_CHANGE_MSG'
      });
    }
  };
}

export function saveNewBudgetPct(history, defaultBudgetIncreasePercent) {
  history.push('/merit-matrix/');
  return (dispatch, getState) => {
    dispatch({
      type: 'SAVE_BUDGET_PCT',
      newBudgetPct: defaultBudgetIncreasePercent
    });
    let selectedGroupFieldPk = null;
    let selectedGroupText = null;
    getState().meritMatrix.groupList.matrixGroupings.forEach(grouping => {
      if (grouping.fieldGrouping !== 'Currency') {
        selectedGroupText = grouping.fieldGrouping;
        selectedGroupFieldPk = grouping.fieldPk;
      }
    });
    dispatch(
      createInitialMatrixSet({
        selectedGroupFieldPk: selectedGroupFieldPk,
        selectedGroupText: selectedGroupText,
        defaultBudgetIncreasePercent: defaultBudgetIncreasePercent / 100
      })
    );
  };
}

export function showBudgetInput(options) {
  return dispatch => {
    dispatch({
      type: 'CHANGE_BUDGET_INPUT'
    });
  };
}
