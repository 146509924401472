import React from 'react';
import classnames from 'classnames';
import AdvancedCell from './../AdvancedCell';
import { DefaultRender, TextRender } from './GenericGridCellRenderers';

/*
  Function passed to AdvancedTable for rendering GenericGrids
*/
export function getGenericRow(row, idx, options = {}) {
  return (
    <React.Fragment>
      {row.cells.map((cell, cellIdx) => {
        const getClassNamesResult = cell.column?.getClassNames ? cell.column.getClassNames(row) : '';
        const getClassName = cell?.column?.getClassName;

        const cellClassNames = classnames(getClassName ? getClassName(options) : '', {
          'right-align': cell.column?.rightAlign,
          'center-align': cell.column?.centerAlign,
          'sticky-left__border': cell.column?.lastStickyLeft,
          'sticky-right__border': cell.column?.lastStickyRight,
          [getClassNamesResult]: !!getClassNamesResult
        });

        if (!cell.column?.hide) {
          let renderer = cell.column.customRender || DefaultRender(cell.column.type);
          if (row.isMarketComparisonRow || (row.isMarketComparison && !cell.value)) {
            renderer = TextRender;
          }
          const defaultRender = renderer(row.values, cell.column.id || cell.column.Header, {
            isSummary: row.isSummary,
            isMarketComparison: row.isMarketComparison,
            ...options
          });

          if (defaultRender) {
            return (
              <AdvancedCell
                key={`generic-grid-row-${idx}-cell-${cellIdx}`}
                className={cellClassNames}
                stickyLeft={cell.column?.stickyLeft || cell.column?.parent?.stickyLeft}
                left={cell.column?.leftAlignOffset || 0}
                stickyRight={cell.column?.stickyRight || cell.column?.parent?.stickyRight}
                right={cell.column?.rightAlignOffset || 0}
                maxWidth={cell.column?.maxWidth}
                zIndex={cell.column?.onTop}
                getCellProps={cell.getCellProps}
              >
                {defaultRender}
              </AdvancedCell>
            );
          }
        }

        return null;
      })}
    </React.Fragment>
  );
}

export function getGenericSurveyRow(row, idx, options = {}) {
  return row.cells.map((cell, cellIdx) => {
    let render = DefaultRender(cell.column.type);
    if (cell.column.customRender) {
      render = cell.column.customRender;
    }
    if (row.isMarketComparisonRow || (row.isMarketComparison && !cell.value)) {
      render = TextRender;
    }
    return (
      !cell.column?.hide && (
        <AdvancedCell
          key={`generic-grid-row-${idx}-cell-${cellIdx}`}
          className={classnames({
            'right-align': cell.column?.rightAlign,
            'center-align': cell.column?.centerAlign,
            'sticky-left__border': cell.column?.lastStickyLeft,
            'sticky-right__border': cell.column?.lastStickyRight
          })}
          stickyLeft={cell.column?.stickyLeft || cell.column?.parent?.stickyLeft}
          left={cell.column?.leftAlignOffset || 0}
          stickyRight={cell.column?.stickyRight || cell.column?.parent?.stickyRight}
          right={cell.column?.rightAlignOffset || 0}
          maxWidth={cell.column?.maxWidth}
          getCellProps={cell.getCellProps}
        >
          {render(row.values, cell.column.id || cell.column.Header, {
            isSummary: row.isSummary,
            isMarketComparison: row.isMarketComparison,
            ...options
          })}
        </AdvancedCell>
      )
    );
  });
}

export function didSortChange(sort, sortState) {
  return (
    sort.length !== sortState?.length ||
    sort?.every((key, idx) => key.id !== sortState[idx].id || key.desc !== sortState[idx].desc)
  );
}

export function genericGridAccessor(terms, key) {
  const foundTerm = terms?.findIndex(term => term.fieldName === key);
  if (foundTerm) return rowData => rowData[foundTerm];
}

/*
  Function used to transform CMS response body to columns for use in AdvancedTable
*/
export function getTransformedColumns(
  termGroups,
  terms,
  rows,
  defaultLeftColumns = [],
  defaultRightColumns = [],
  columnWidthOverride
) {
  let columns = [],
    foundGroup,
    foundExistingGroup;

  const idTrack = [];
  // Add specified left columns at the start
  defaultLeftColumns?.forEach(col => {
    // Allows access to terms for sorting for data in an array format
    // Make sure to set 'accessTerms' to true to allow this
    if (typeof col.accessor === 'function' && col.accessTerms) {
      col.accessTerms = false;
      col.accessor = col.accessor(terms);
    }

    columns.push(col);
  });

  terms?.forEach((term, termIdx) => {
    if (term) {
      const origId = term.term;
      let termId = term.term?.endsWith('_default') ? term.term : (`${term?.termType}_` || '') + `${term.term}`;
      termId = idTrack.includes(termId) ? termId + `_${termIdx}` : termId;
      idTrack.push(termId);

      const headerText = term?.displayName || term?.name || '';
      const tooltipOverrideText =
        term?.name?.includes('${') || term?.name?.includes('User Defined Field')
          ? term?.displayName
          : term?.name || term?.displayName || '';

      // Render column group
      if (term.termGroupId !== undefined) {
        foundGroup = termGroups.filter(termGroup => termGroup.id === term.termGroupId)[0];
        foundExistingGroup = columns.filter(col => col.groupId === foundGroup?.id)[0];

        // Find existing group already created
        if (foundExistingGroup) {
          // isRightGrouped determines if the column is the last column to render in the grouping from left to right
          // Used for styling separation of columns
          const index = foundExistingGroup.columns?.length - 1;
          if (index) {
            if (foundExistingGroup.columns[index].isRightGrouped) {
              foundExistingGroup.columns[index].isRightGrouped = false;
            }
          }

          foundExistingGroup.columns?.push({
            Header: headerText,
            tooltipOverride: tooltipOverrideText,
            id: termId,
            origId,
            accessor: (col, idx) => {
              if (rows[idx]) {
                return rows[idx][termIdx];
              }
            },
            type: term.dataType,
            centerAlign: true,
            isRightGrouped: true,
            isGrouped: true,
            width: columnWidthOverride || term.metadata?.width,
            show: false
          });
        } else {
          columns.push({
            groupId: foundGroup?.id,
            Header: foundGroup?.name,
            columns: [
              {
                Header: headerText,
                tooltipOverride: tooltipOverrideText,
                id: termId,
                origId,
                accessor: (col, idx) => {
                  if (rows[idx]) {
                    return rows[idx][termIdx];
                  }
                },
                type: term.dataType,
                centerAlign: true,
                // isLeftGrouped determines if the column is the first column to render left to right
                // Used for styling separation of columns
                isLeftGrouped: true,
                isGrouped: true,
                width: columnWidthOverride || term.metadata?.width,
                show: false
              }
            ]
          });
        }
        foundGroup = null;
        foundExistingGroup = null;
      } else {
        // If it is not a column grouping, add the column
        columns.push({
          Header: headerText,
          tooltipOverride: tooltipOverrideText,
          id: termId,
          origId,
          accessor: (col, idx) => {
            if (rows[idx]) {
              return rows[idx][termIdx];
            }
          },
          type: term.dataType,
          centerAlign: true,
          width: columnWidthOverride || term.metadata?.width,
          show: false
        });
      }
    }
  });
  // Add specified right columns at the end
  defaultRightColumns?.forEach(col => {
    columns.push(col);
  });
  return columns;
}
