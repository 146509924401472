import moment from 'moment';
import { getAuditHistory, getManagerReviewStatus, buildParams } from '../jobs/jobActions';
import { AxiosInstance, AxiosResponse } from 'axios';

export interface JobReviewAggregate {
  jobReviewIds: string[];
  reviewers: {[id: string]: JobCollabUserInfo};
  pendingReviews: Set<string>;
  completedReviews: {[id: string]: ReviewerFeedback};
  comments: ReviewerFeedback[];
}

const skills = 'skills';
const certifications = 'certifications';
const yearsExperience = 'yearsExperience';
const yearsMinimum = 'yearsMinimum';
const managementRole = 'managementRole';
const highestDegreeEarned = 'highestDegreeEarned';

const questionMap = {
  skills,
  certifications,
  yearsExperience,
  yearsMinimum,
  managementRole,
  highestDegreeEarned
};

export interface JobApprovalState {
  showMgrReviewModal: boolean;
  mgrEmailList: string[];
  showMgrReviewSuccessBox: boolean;
  reviewBtnText: string;
  sendEmailResponse: EmailResponses;
  sendEmailError: boolean;
  reviewerName: string | null;
  managerMsg: string | null;
  dueDateUtc: string | null;
  reviewId: string | null;
  reviewResponse?: JobReviewAggregate;
  workflowStatus: string | null;
  createdDateTime: string | null;
  closeReviewDisable: boolean;
  sendReviewDisable: boolean;
  showCloseReviewModal: boolean;
  checkingForReviews: boolean;
  loadingJobId: number | null;
  error?: string | null;
}

const initialState: JobApprovalState = {
  showMgrReviewModal: false,
  mgrEmailList: [],
  showMgrReviewSuccessBox: false,
  reviewBtnText: 'Close Feedback',
  sendEmailResponse: [],
  sendEmailError: false,
  reviewerName: null,
  managerMsg: null,
  dueDateUtc: null,
  reviewId: null,
  workflowStatus: null,
  createdDateTime: null,
  closeReviewDisable: false,
  sendReviewDisable: false,
  showCloseReviewModal: false,
  checkingForReviews: false,
  loadingJobId: null
};

// I'm disabling this just because I want the types to
// match the action name. If someone else feels strong about this
// I can change it.
/* eslint-disable @typescript-eslint/class-name-casing */
interface JOBS_MGR_TOGGLE_REVIEW_MODAL {
  type: 'JOBS_MGR_TOGGLE_REVIEW_MODAL';
  showMgrReviewModal: boolean;
}

interface MANAGER_SEARCH {
  type: 'MANAGER_SEARCH';
}

interface MANAGER_SEARCH_SUCCESS {
  type: 'MANAGER_SEARCH_SUCCESS';
  result: string[];
  searchCallback: (a: string | null, b: { options: string[]}) => void;
  //action.searchCallback(null, { options: action.result });
}

interface MANAGER_SEARCH_FAIL {
  type: 'MANAGER_SEARCH_FAIL';
  error: string;
}

interface SEND_MANAGER_REVIEW {
  type: 'SEND_MANAGER_REVIEW';
}

interface SEND_MANAGER_REVIEW_SUCCESS {
  type: 'SEND_MANAGER_REVIEW_SUCCESS';
  result: {
    sendEmailResponse: EmailResponses;
    reviewId: string;
  };
  dueDateUtc: string;
  createdDateTime: string;
}

interface SEND_MANAGER_REVIEW_FAIL {
  type: 'SEND_MANAGER_REVIEW_FAIL';
  error: string;
}

interface MANAGER_REVIEW_CLOSE_SUCCESS_MSG {
  type: 'MANAGER_REVIEW_CLOSE_SUCCESS_MSG';
}

interface CHOOSE_ALTERNATE_MANAGER {
  type: 'CHOOSE_ALTERNATE_MANAGER';
}

interface MANAGER_CLOSE_REVIEW {
  type: 'MANAGER_CLOSE_REVIEW';
}

interface MANAGER_CLOSE_REVIEW_SUCCESS {
  type: 'MANAGER_CLOSE_REVIEW_SUCCESS';
}

interface CLEAR_REVIEW_RESPONSE {
  type: 'CLEAR_REVIEW_RESPONSE';
}

interface MANAGER_CLOSE_REVIEW_FAIL {
  type: 'MANAGER_CLOSE_REVIEW_FAIL';
  error: string;
}

interface MANAGER_REVIEW_STATUS {
  type: 'MANAGER_REVIEW_STATUS';
  itemId: number;
}

interface MANAGER_REVIEW_STATUS_SUCCESS {
  type: 'MANAGER_REVIEW_STATUS_SUCCESS';
  result: JobReviewEventResult;
  itemId: number;
}

interface MANAGER_REVIEW_STATUS_FAIL {
  type: 'MANAGER_REVIEW_STATUS_FAIL';
  error: string;
  itemId: number;
}

interface TOGGLE_CLOSE_REVIEW_MODAL {
  type: 'TOGGLE_CLOSE_REVIEW_MODAL';
}

interface JOB_DETAILS_SINGLE_START_LOAD {
  type: 'JOB_DETAILS_SINGLE_START_LOAD';
  id: number;
}
/* eslint-enable @typescript-eslint/class-name-casing */

type JobCollabAction = JOBS_MGR_TOGGLE_REVIEW_MODAL | MANAGER_SEARCH |
  MANAGER_SEARCH_SUCCESS | MANAGER_SEARCH_FAIL | SEND_MANAGER_REVIEW |
  SEND_MANAGER_REVIEW_SUCCESS | SEND_MANAGER_REVIEW_FAIL | MANAGER_REVIEW_CLOSE_SUCCESS_MSG |
  CHOOSE_ALTERNATE_MANAGER | MANAGER_CLOSE_REVIEW | MANAGER_CLOSE_REVIEW_SUCCESS | CLEAR_REVIEW_RESPONSE |
  MANAGER_CLOSE_REVIEW_FAIL | MANAGER_REVIEW_STATUS | MANAGER_REVIEW_STATUS_SUCCESS | MANAGER_REVIEW_STATUS_FAIL | TOGGLE_CLOSE_REVIEW_MODAL |
  JOB_DETAILS_SINGLE_START_LOAD;

type JobCollabActionDispatch = 
  ((p: JobCollabAction) => void);

type EmailResponses = Array<{isSuccess: boolean; email: string}>;



export function toggleMgrReviewModal() {
  return (dispatch: JobCollabActionDispatch, getState: Function): void => {
    const toggleMgrReviewModal = getState().jobApproval.showMgrReviewModal ? false : true;
    dispatch({
      type: 'JOBS_MGR_TOGGLE_REVIEW_MODAL',
      showMgrReviewModal: toggleMgrReviewModal
    });
  };
}

export function toggleCloseReviewModal() {
  return (dispatch: JobCollabActionDispatch): void => {
    dispatch({
      type: 'TOGGLE_CLOSE_REVIEW_MODAL'
    });
  };
}

export function managerSearch(options: ManagerSearchQuery): {} {
  return {
    types: ['MANAGER_SEARCH', 'MANAGER_SEARCH_SUCCESS', 'MANAGER_SEARCH_FAIL'],
    promise: (client: AxiosInstance): {} => client.get('/api/job-collab/managerSearch/search', { params: options.params }),
    query: options.params,
    searchCallback: options.searchCallback
  };
}

function checkEmailSendError(emailResponses: EmailResponses): boolean {
  if (!Array.isArray(emailResponses) || !emailResponses.length) {
    return false;
  }

  return !!emailResponses.filter(response => !response.isSuccess).length;
}

export function sendToMgrForReview(options: CreateJobForReviewRequest) {
  return (dispatch: Function, getState: Function): void => {
    const requestBody = {
      reviewers: options.reviewers,
      dueDateUtc: options.dueDateUtc,
      orgJobCode: options.orgJobCode,
      orgJobId: options.orgJobId,
      orgJobCodeKey: options.orgJobCodeKey
    };

    const jobId = getState().jobsList.selectedListRows[0];
    const ajaxPromise = dispatch({
      types: ['SEND_MANAGER_REVIEW', 'SEND_MANAGER_REVIEW_SUCCESS', 'SEND_MANAGER_REVIEW_FAIL'],
      promise: (client: AxiosInstance) =>
        client.post('/api/job-collab/jobReview/createReview', { data: requestBody })
        .then((val: AxiosResponse<SEND_MANAGER_REVIEW_SUCCESS> & { success: boolean}) => {
          // If the call doesn't return a success, we'll throw an exception so that the
          // promise is rejected and we go down the error flow
          if (!val.success) {
            throw 'Failed to send job for feedback. Please try again.';
          }
          return val;
        }),
      dueDateUtc: options.dueDateUtc,
      createdDateTime: moment.utc().format()
    });
    ajaxPromise
      .then(() => {
        dispatch(getAuditHistory(jobId));
        getManagerReviewStatus(dispatch, buildParams(jobId), jobId);
      })
      .catch((err: object) => {
        console.error('Error creating manager review', err);
      });
  };
}

export function closeMgrReviewSuccessMsg(): JobCollabAction {
  return {
    type: 'MANAGER_REVIEW_CLOSE_SUCCESS_MSG'
  };
}

export function clearReviewResponse(): JobCollabAction {
  return {
    type: 'CLEAR_REVIEW_RESPONSE'
  };
}

// using the job code we're going to close all reviews
// for the given job code
export function closeMgrReviewByJobCode(orgJobCode: string, toggleCloseReviewModal: () => void) {
  return (dispatch: Function, getState: Function): void => {
    const query = {
      orgJobCode
    };
    const jobId = getState().jobsList.selectedListRows[0];
    const ajaxPromise = dispatch({
      types: ['MANAGER_CLOSE_REVIEW', 'MANAGER_CLOSE_REVIEW_SUCCESS', 'MANAGER_CLOSE_REVIEW_FAIL'],
      promise: (client: AxiosInstance) => client.get('/api/job-collab/jobReviewWorkflow/completeReview', { params: query }),
      query: query
    });
    ajaxPromise
      .then(() => {
        dispatch(getAuditHistory(jobId));
        dispatch(toggleCloseReviewModal());
      })
      .catch((err: object) => {
        console.error('Error completing manager review', err);
      });
  };
}

export function sendToAnotherMgr(): JobCollabAction {
  return {
    type: 'CHOOSE_ALTERNATE_MANAGER'
  };
}

function extractUniqueAnswers(val?: Array<{answer: string}> | null | string | number): Set<string> {
  if (!val) {
    return new Set<string>();
  }

  if (typeof val === 'string') {
    return new Set<string>([val]);
  }
  else if (typeof val === 'number'){
    return new Set<string>([val.toString()]);
  }



  return new Set<string>(val.filter(f => !!f).map(v => v.answer));
}

// The following functions are exported for testing
// Finds the differences between the current and the suggested and
// creates sets of what was added or removed
export function diffAnswerSuggestions(answers: AnswerSuggestion<string[]>): AnswerDiffs {
  const { currentValue, suggestedValue } = answers;
  const cv = extractUniqueAnswers(currentValue);
  const sv = extractUniqueAnswers(suggestedValue);
  return {
    additions: new Set<string>([...sv].filter(s => !cv.has(s))),
    subtractions: new Set<string>([...cv].filter(c => !sv.has(c)))
  };
}

// Merges two sets of diffs that were produced from the diffAnswerSuggestions
// function
export function mergeAnswerSuggestionDiffs(a: AnswerDiffs, b: AnswerDiffs): AnswerDiffs {
  return {
    additions: new Set([...a.additions, ...b.additions]),
    subtractions: new Set([...a.subtractions, ...b.subtractions])
  };
}

// Updates the answerSuggestions portion of the completedReview object
// based on the new values in the feedback object. Works almost the same
// as buildAnswerSuggestionResult except that this is doing an aggregation.
// questionId is 'skills' or 'certifications'
export function calculateAnswerDiffs(aggregation: ReviewerFeedback, currentFeedback: ReviewerFeedback, questionId: string): void {
  aggregation.answerSuggestions = aggregation.answerSuggestions || {};
  const caseInsensitiveQuestionId: string = Object.keys(aggregation.answerSuggestions).find(key => key.toLowerCase() === questionId.toLowerCase()) || '';
  const completedQuestion: AnswerSuggestion<string[]> = (aggregation.answerSuggestions[caseInsensitiveQuestionId] || {}) as AnswerSuggestion<string[]>;
  const feedbackQuestion = ((currentFeedback.answerSuggestions && currentFeedback.answerSuggestions[caseInsensitiveQuestionId]) || {
    suggestedValue: [],
    currentValue: []
  }) as AnswerSuggestion<string[]>;

  const feedbackDiff = diffAnswerSuggestions(feedbackQuestion);
  const completedDiff = completedQuestion.diff || { additions: new Set(), subtractions: new Set() };

  completedQuestion.diff = mergeAnswerSuggestionDiffs(completedDiff, feedbackDiff);
  aggregation.answerSuggestions[questionId] = completedQuestion;
}

// Updates completedReview with the answer for the questionId it's
// passed. If there's no answer does nothing!
function updateAnswerForQuestionId(completedReview: ReviewerFeedback, feedback: ReviewerFeedback, questionId: string): void {
  const caseInsensitiveQuestionId: string = Object.keys(feedback.answerSuggestions || {}).find(key => key.toLowerCase() === questionId.toLowerCase()) || '';
  const answer = feedback.answerSuggestions && feedback.answerSuggestions[caseInsensitiveQuestionId];
  if (answer && completedReview && completedReview.answerSuggestions) {
    completedReview.answerSuggestions[caseInsensitiveQuestionId] = answer;
  }
}

// A function we'll use in the reducer to take the array of jobs that we get back
// from mp-node and transform them into one big useful object for generating the
// manager review summaries. We only export it here so that we can test it!
export function transformJobReviewArray(jobReviews: JobReviewEvent[]): JobReviewAggregate {
  const result: JobReviewAggregate = {
    jobReviewIds: [],
    reviewers: {},
    pendingReviews: new Set(),
    completedReviews: {},
    comments: []
  };

  if (!Array.isArray(jobReviews) || !jobReviews.length) {
    return result;
  }

  jobReviews.forEach(review => {
    result.jobReviewIds.push(review.jobReviewId);
    (review.reviewers || [] as JobCollabUserInfo[]).forEach(reviewer => {
      result.reviewers[reviewer.userId] = reviewer;
    });

    Object.keys(review.reviewerFeedback || {}).forEach(userId => {
      const feedback = review.reviewerFeedback[userId];
      if (feedback.reviewerStatus === 'Complete') {
        result.pendingReviews.delete(userId);
        let aggregation = result.completedReviews[userId];
        const comment: ReviewerFeedback = {
          jobLevelComment: feedback.jobLevelComment,
          jobLevelApproval: feedback.jobLevelApproval,
          reviewerStatus: feedback.reviewerStatus,
          submittedDate: feedback.submittedDate,
          answerSuggestions: feedback.answerSuggestions
        };

        if (!aggregation) {
          aggregation = feedback;
          result.completedReviews[userId] = feedback;
          aggregation.comments = [comment];
          delete aggregation.jobLevelApproval;
          delete aggregation.jobLevelComment;
          delete aggregation.reviewerStatus;
        } else {
          // Combine coments
          aggregation?.comments?.push(comment);
        }
        calculateAnswerDiffs(aggregation, feedback, questionMap.skills);
        calculateAnswerDiffs(aggregation, feedback, questionMap.certifications);
        updateAnswerForQuestionId(aggregation, feedback, questionMap.yearsExperience);
        updateAnswerForQuestionId(aggregation, feedback, questionMap.yearsMinimum);
        updateAnswerForQuestionId(aggregation, feedback, questionMap.highestDegreeEarned);
        updateAnswerForQuestionId(aggregation, feedback, questionMap.managementRole);
      } else {
        result.pendingReviews.add(userId);
      }
    });
  });

  return result;
}

export default function reducer(state = initialState, action: JobCollabAction): JobApprovalState {
  let emailFailed;
  switch (action.type) {
    case 'JOBS_MGR_TOGGLE_REVIEW_MODAL':
      return {
        ...state,
        showMgrReviewModal: action.showMgrReviewModal,
        sendEmailError: false,
        sendEmailResponse: [],
        error: null
      };
    case 'MANAGER_SEARCH':
      return {
        ...state
      };
    case 'MANAGER_SEARCH_SUCCESS':
      action.searchCallback(null, { options: action.result });
      return {
        ...state,
        mgrEmailList: action.result,
        // searchCallback: searchCallback(null, {options: action.result}),
        error: null
      };
    case 'MANAGER_SEARCH_FAIL':
      return {
        ...state,
        error: action.error
      };
    case 'SEND_MANAGER_REVIEW':
      return {
        ...state,
        sendReviewDisable: true
      };
    case 'SEND_MANAGER_REVIEW_SUCCESS':
      emailFailed = checkEmailSendError(action.result.sendEmailResponse);
      return {
        ...state,
        showMgrReviewModal: emailFailed,
        workflowStatus: 'Incomplete',
        sendEmailResponse: action.result.sendEmailResponse,
        sendEmailError: emailFailed,
        reviewId: action.result.reviewId,
        dueDateUtc: action.dueDateUtc,
        createdDateTime: action.createdDateTime,
        sendReviewDisable: false
      };
    case 'SEND_MANAGER_REVIEW_FAIL':
      return {
        ...state,
        error: action.error,
        sendReviewDisable: false
      };
    case 'MANAGER_REVIEW_CLOSE_SUCCESS_MSG':
      return {
        ...state,
        showMgrReviewSuccessBox: false,
        workflowStatus: null
      };
    case 'CHOOSE_ALTERNATE_MANAGER':
      return {
        ...state,
        showMgrReviewModal: true
      };
    case 'MANAGER_CLOSE_REVIEW':
      return {
        ...state,
        closeReviewDisable: true
      };
    case 'MANAGER_CLOSE_REVIEW_SUCCESS':
      return {
        ...state,
        reviewResponse: undefined,
        showMgrReviewSuccessBox: true,
        closeReviewDisable: false,
        workflowStatus: 'Complete'
      };
    case 'CLEAR_REVIEW_RESPONSE':
      return {
        ...state,
        reviewResponse: undefined
      };
    case 'MANAGER_CLOSE_REVIEW_FAIL':
      return {
        ...state,
        closeReviewDisable: false,
        error: action.error
      };
    case 'MANAGER_REVIEW_STATUS':
      if (action.itemId && state.loadingJobId && action.itemId !== state.loadingJobId) {
        return {
          ...state
        };
      }

      return {
        ...state,
        checkingForReviews: true
      };
    case 'MANAGER_REVIEW_STATUS_SUCCESS':
      if (action.itemId && state.loadingJobId && action.itemId !== state.loadingJobId) {
        return {
          ...state
        };
      }

      return {
        ...state,
        workflowStatus: action.result.workflowStatus,
        reviewResponse: transformJobReviewArray(action.result),
        dueDateUtc: action.result.dueDateUtc,
        createdDateTime: action.result.createdDateTime,
        checkingForReviews: false
      };
    case 'MANAGER_REVIEW_STATUS_FAIL':
      if (action.itemId && state.loadingJobId && action.itemId !== state.loadingJobId) {
        return {
          ...state
        };
      }
      
      return {
        ...state,
        error: typeof action.error === 'string' ? action.error : JSON.stringify(action.error),
        checkingForReviews: false
      };
    case 'TOGGLE_CLOSE_REVIEW_MODAL':
      return {
        ...state,
        showCloseReviewModal: !state.showCloseReviewModal
      };
    case 'JOB_DETAILS_SINGLE_START_LOAD':
      return {
        ...state,
        loadingJobId: action.id
      };
    default:
      return state;
  }
}