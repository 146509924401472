const initialState = {
  facetPanelIsOpen: false,
  loading: false
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case 'OPEN_FACET_PANEL':
      return { ...state, facetPanelIsOpen: true };
    case 'CLOSE_FACET_PANEL':
      return { ...state, facetPanelIsOpen: false };
    case 'MATRIX_APPLY_FILTERS':
      return { ...state, loading: true };
    case 'MATRIX_APPLY_FILTERS_SUCCESS':
      return { ...state, facetPanelIsOpen: false, loading: false };
    case 'MATRIX_APPLY_FILTERS_FAIL':
      return {
        ...state,
        facetPanelIsOpen: true,
        loading: false
      };
    default:
      return state;
  }
}

export const openFacetPanel = () => ({ type: 'OPEN_FACET_PANEL' });
export const closeFacetPanel = () => ({ type: 'CLOSE_FACET_PANEL' });
