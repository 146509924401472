import capitalize from 'lodash/capitalize';
import groupBy from 'lodash/groupBy';
import sortBy from 'lodash/sortBy';
import { states } from '../constants';

export const APPLY_DIFF_TAB = 1;
export const COMPANY_LOADING_TEXT = 'We are matching your company locations with geo differential data...';
export const COMPANY_LOCATIONS_TAB = 1;
export const EXPLORE_DIFF_TAB = 0;
export const FAVORITE_IMG_PATH = '/view/static/mp/images/favorite-locations-empty-state.svg';
export const FAVORITE_LOADING_TEXT = 'We are matching your favorite locations with geo differential data...';
export const FAVORITE_LOCATIONS_TAB = 2;
export const FAVORITE_PRIMARY_TEXT = "You don't have any favorite locations";
export const FAVORITE_SECONDARY_TEXT = 'In all locations, click the star icon to save a favorite location';
export const GENERIC_LOCATION_LOADING_TEXT = 'Loading Locations...';
export const GEO_TREND_COLUMN_HEADER = 'Geo Differential Trend';
export const LOADING_IMG_PATH = '/view/static/mp/images/loading_image.svg';
export const NATIONAL_LOCATION = 'United States (Natl)';
export const NOT_SET_LOCATION_KEY = 'not-set';
export const PAYSCALE_IMG_PATH = '/view/static/mp/images/search_image.svg';
export const PAYSCALE_LOADING_TEXT = 'We are retrieving PayScale locations with geo differential data...';
export const PAYSCALE_LOCATIONS_TAB = 0;
export const PAYSCALE_PRIMARY_TEXT = 'To see geographic differential data search for a location';
export const RESULTS_LIMIT = 50;

export function mergeDiffs(first, second) {
  const newDiffs = [...first];
  const secondDiffs = [...second];
  secondDiffs.forEach(s => {
    const firstIndex = newDiffs.findIndex(f => f.location === s.location);
    if (firstIndex > -1) {
      newDiffs[firstIndex].differential = s.differential;
    } else {
      newDiffs.push(s);
    }
  });

  return newDiffs;
}

export const formatAdjustment = (value, isPercent) => {
  let options = {
    minimumFractionDigits: 2
  };
  if (isPercent) {
    options = {
      ...options,
      style: 'percent'
    };
  }

  return new Intl.NumberFormat('en-US', options).format(value);
};

export function transformDiffValues(appliedGeoDiffs) {
  return appliedGeoDiffs.map(adjustment => {
    return { location: adjustment.location, differential: Number((adjustment.differential / 100).toFixed(4)) };
  });
}

export function getDefaultAppliedGeoDiffs(applyLocations) {
  const defaultAppliedGeoDiffs = [];
  applyLocations?.forEach(orgLocation => {
    const trends = orgLocation?.trendData[0]?.trends;
    let defaultDiff = trends ? trends[trends.length - 1]?.geoDifferential : 0;
    defaultDiff = Number(defaultDiff.toFixed(2));

    defaultAppliedGeoDiffs.push({
      location: orgLocation?.formattedLocation,
      differential: defaultDiff
    });
  });

  return defaultAppliedGeoDiffs;
}

export const removePercent = value => {
  return value.replace(/\% ?/g, '');
};

export const getFormattedColumns = (data, showPayScaleLocations, settings, showApplyGeo, showCompanyLocations) => {
  const { showGrouped, trendRange, trendRangeType, groupByJobFamily } = settings;
  const trendColumnWidth = 50 / trendRange;
  const trendColumns = getTrendColumns(
    trendColumnWidth,
    data,
    trendRangeType,
    showGrouped,
    groupByJobFamily,
    trendRange
  );
  let dataColumns = [];

  dataColumns.push({
    Header: '',
    id: 'favorite-location',
    width: '2%'
  });

  const defaultDataColumns = [
    {
      Header: getLocationHeader(settings, showPayScaleLocations, showApplyGeo),
      id: 'formattedLocation',
      width: dataColumns.length ? '28%' : '30%',
      accessor: 'formattedLocation'
    },
    {
      Header: 'Compare with',
      width: '13%'
    },
    {
      Header: 'Geo Differential Trend',
      columns: trendColumns
    }
  ];

  dataColumns.push(...defaultDataColumns);

  if ((showApplyGeo || showCompanyLocations) && ((showGrouped && groupByJobFamily) || !showGrouped)) {
    dataColumns.splice(2, 0, {
      Header: 'Employees',
      centerAlign: true,
      width: '12%',
      id: 'totalEmployees',
      accessor: 'totalEmployees'
    });
  }
  if (!showApplyGeo && showGrouped) dataColumns.push({ Header: '', id: 'toggle', width: '2%' });
  if (showApplyGeo) dataColumns.push({ Header: 'Applied Geo Differential', id: 'appliedGeoDiff', width: '15%' });
  return dataColumns;
};

export const getFormattedColumnsAndData = (
  data,
  showPayScaleLocations,
  settings,
  showApplyGeo,
  showCompanyLocations
) => {
  const columns = getFormattedColumns(data, showPayScaleLocations, settings, showApplyGeo, showCompanyLocations);
  const geoTrendColumnIndex = columns.findIndex(column => column.Header === GEO_TREND_COLUMN_HEADER);
  const geoTrendColumns = columns[geoTrendColumnIndex].columns;
  const newData = data.map(location => {
    let newRow;

    if (location.trendData.length > 0) {
      location?.trendData.map((row, rowIndex) => {
        const formattedLocation = getFormattedLocation(location);
        const trendData = getFormattedTrendData(location, geoTrendColumns);
        newRow = {
          ...location,
          formattedLocation,
          trendData
        };

        geoTrendColumns.forEach((column, columnIndex) => {
          const aggData = trendData.find(trend => trend.jobFamily === 'All');
          if (aggData) {
            newRow[column.Header] = aggData[column.Header];
          } else {
            newRow[column.Header] = row.trends.length ? row.trends[columnIndex].geoDifferential : null;
          }
        });
      });
    } else {
      const formattedLocation = getFormattedLocation(location);

      newRow = {
        ...location,
        formattedLocation
      };

      geoTrendColumns.forEach(column => {
        newRow[column.Header] = null;
      });
    }

    return newRow;
  });

  return { columns, data: newData };
};

export const getCompanyLocationGrouping = (payScaleData, companyLocations) => {
  return companyLocations.map(companyLocation => setLocationGroupings(payScaleData, companyLocation));
};

export const getPayScaleLocationGrouping = data => {
  const locations = Object.keys(data);
  return locations.map(location => {
    const formattedLocation = location.split('; ')[0];
    const cityState = splitCityState(formattedLocation);
    const trendData = getGroupedTrends(data[location]);
    return {
      city: cityState[0].trim(),
      formattedLocation,
      payScaleLocation: location,
      state: cityState[1].trim(),
      trendData
    };
  });
};

export const groupedDataByFamily = data => {
  const formattedData = data
    .map(row => {
      return row.trendData.map(trend => {
        return { ...trend, formattedLocation: row.formattedLocation };
      });
    })
    .flat(1);
  const groupedTrendRows = groupBy(formattedData, 'jobFamily');
  const formattedFamilies = getFormattedFamilies(groupedTrendRows);

  return sortBy(formattedFamilies, ['family']).filter(group => group.family !== 'All');
};

const getGroupedTrends = trendGroups => {
  const trends = Object.entries(trendGroups);
  return trends.map(family => {
    const newFamily = { jobFamily: family[0], trends: [] };
    const familyData = Object.entries(family[1].Impacts);
    newFamily.trends = familyData.map((trend, index) => {
      const { PayDifferentialPercentage, ProfileCount } = trend[1];
      newFamily[trend[0]] = PayDifferentialPercentage;
      return {
        geoDifferential: PayDifferentialPercentage,
        sampleSize: ProfileCount,
        label: trend[0]
      };
    });
    return newFamily;
  });
};

const getFormattedFamilies = trends => {
  const families = Object.keys(trends);
  return families.map(family => {
    return { family, trendData: trends[family] };
  });
};

const getFormattedLocation = location => {
  const { city, family, formattedLocation, state, work_location } = location;

  if (family) return family;
  else if (formattedLocation) return formattedLocation;
  else if (work_location) return work_location;
  else if (city && state) return `${city}, ${state}`;
  else if (city) return city;
  else if (state) return state;
  else return 'Not Set';
};

const getFormattedTrendData = (location, columns) => {
  return location.trendData.map(row => {
    let newRow = {
      ...row
    };

    columns.forEach((column, columnIndex) => {
      newRow[column.Header] = row.trends.length ? row.trends[columnIndex].geoDifferential : null;
    });
    return newRow;
  });
};

const getLocationHeader = (settings, showPayScaleLocations, showApplyGeo) => {
  const { groupByJobFamily, internalData, showGrouped } = settings;
  if (!showApplyGeo && showGrouped && !groupByJobFamily) {
    return 'Job Families';
  } else if (!showPayScaleLocations || showApplyGeo) {
    return internalData === 'workLocation' ? 'Work Location' : 'City/State';
  }

  return 'Locations';
};

const getTrendColumns = (trendColumnWidth, data, trendRangeType, showGrouped, groupByJobFamily, trendRange) => {
  const showQuarters = trendRangeType === 'Quarters';
  const trendData = data?.find(row => row?.trendData?.length)?.trendData[0];
  const trendLabels = trendData?.trends?.map(trend => trend.label);
  let trends = [];
  if (trendLabels) {
    trends = trendLabels?.map(trend => {
      if (trend.includes('-')) {
        const trendLabel = trend.split('-');
        const currentQuarter = getQuarter(parseInt(trendLabel[1]));
        const currentYear = trendLabel[0];
        trend = `Q${currentQuarter}/${currentYear}`;
      }
      return {
        Header: trend,
        id: trend,
        width: `${trendColumnWidth}%`,
        isGrouped: true,
        centerAlign: true,
        accessor: trend,
        sortType: (rowA, rowB, columnId, desc) => {
          if (showGrouped && !groupByJobFamily) return;
          else {
            if (rowA.original[columnId] < rowB.original[columnId]) return -1;
            if (rowA.original[columnId] > rowB.original[columnId]) return 1;
            if (desc === false) {
              if (rowA.original.formattedLocation < rowB.original.formattedLocation) return -1;
              if (rowA.original.formattedLocation > rowB.original.formattedLocation) return 1;
            } else {
              if (rowA.original.formattedLocation < rowB.original.formattedLocation) return 1;
              if (rowA.original.formattedLocation > rowB.original.formattedLocation) return -1;
            }
            return 0;
          }
        }
      };
    });
  }

  if (showQuarters) {
    data?.forEach(row => {
      if (row?.trendData?.length) {
        row.trendData.forEach(data => data?.trends.reverse());
      }
    });
    trends.reverse();
  }

  if (trends.length === 0) {
    const placholderTrends = Array.apply(null, Array(trendRange));
    trends = placholderTrends.map((el, index) => {
      return { Header: '', id: `trend-${index + 1}` };
    });
  }

  return trends;
};

const setLocationGroupings = (payScaleData, companyLocation) => {
  const payScaleLocations = Object.keys(payScaleData);
  let payScaleCityState = null;
  let abbreviation, city, state;

  const payScaleLocation = payScaleLocations.find(location => {
    payScaleCityState = trimValues(splitPayScaleLocation(location));
    if (companyLocation.work_location) {
      const companyCityState = trimValues(splitCityState(companyLocation.work_location));
      abbreviation = splitCityState(
        getLocationWithAbbreviatedState(`${companyCityState[0]}, ${companyCityState[1]}`)
      )[1].toUpperCase();
      state = splitCityState(getLocationWithFullState(`${companyCityState[0]}, ${companyCityState[1]}`))[1];
      city = companyCityState[0];
    } else {
      city = companyLocation?.city;
      state = companyLocation?.state?.trim();
      state = splitCityState(getLocationWithFullState(`${city}, ${state}`))[1];
      abbreviation = splitCityState(getLocationWithAbbreviatedState(`${city}, ${state}`))[1].toUpperCase();
    }

    if (companyLocation.mappedPayScaleLocation) {
      const companyCityState = trimValues(splitPayScaleLocation(companyLocation.mappedPayScaleLocation));
      city = companyCityState[0];
      state = companyCityState[1];
      abbreviation = splitCityState(getLocationWithAbbreviatedState(`${city}, ${state}`))[1].toUpperCase();
    }

    return (
      payScaleCityState[0] === capitalizeLocationTerm(city) && payScaleCityState[1] === capitalizeLocationTerm(state)
    );
  });

  const formattedLocation = getFormattedLocation(companyLocation);
  const trendGroups = payScaleData[payScaleLocation];
  const trendData = trendGroups ? getGroupedTrends(trendGroups) : [];

  return {
    ...companyLocation,
    abbreviation,
    city: companyLocation?.city,
    formattedLocation,
    payScaleLocation,
    state: states[abbreviation],
    trendData
  };
};

const capitalizeLocationTerm = term => {
  return term
    ?.split(' ')
    ?.map(word => capitalize(word))
    ?.join(' ');
};

const getQuarter = month => {
  if (month <= 3) return 1;
  else if (month <= 6) return 2;
  else if (month <= 9) return 3;
  else if (month <= 12) return 4;
};

const splitCityState = location => {
  if (!location) {
    return [];
  }

  if (location.includes(',')) {
    return location.split(',').map(item => item.trim());
  } else {
    const newStates = Object.entries(states)
      .flat()
      .map(entry => entry);
    const regex = new RegExp(`(.*)(${newStates.join('|')})$`);
    const locationMatch = location.match(regex);
    if (locationMatch) {
      const city = locationMatch[1]?.trim();
      const state = locationMatch[2]?.trim();
      return [city, state];
    }
  }

  return [location];
};

const splitPayScaleLocation = location => {
  return splitCityState(location.split('; ')[0]);
};

const trimValues = values => {
  return values.map(value => value.trim());
};

export const toPayScaleLocationDisplay = location => {
  if (location === NATIONAL_LOCATION) {
    return 'National';
  } else if (!location || !location.includes(';')) {
    return location;
  }
  return location.split('; ')[0];
};

export const toCompareWithLocationValue = location => {
  if (location && location !== NATIONAL_LOCATION && location !== 'National') {
    return location + '; United States';
  }
  return location;
};

export const applySearch = (searchTerm, data, settings) => {
  if (!searchTerm) {
    return data;
  }
  const { internalData } = settings;
  const nullDataText = 'not set';
  const search = searchTerm.toLowerCase();
  let filteredLocations;
  if (internalData === 'workLocation') {
    filteredLocations = data.reduce((memo, location) => {
      if (location?.family) {
        const filteredSubRows = location.trendData?.filter(trend => {
          return (
            searchMatchesLocationString(trend.formattedLocation, search) ||
            (!trend.formattedLocation && nullDataText.includes(search))
          );
        });

        if (filteredSubRows.length > 0) {
          memo.push({ ...location, trendData: filteredSubRows });
        } else if (location.family.toLowerCase().includes(search)) {
          memo.push(location);
        }

        return memo;
      } else {
        const { formattedLocation } = location;
        const filteredTrends = location.trendData?.filter(subRow => subRow.jobFamily?.toLowerCase().includes(search));

        if (filteredTrends?.length > 0) {
          memo.push({ ...location, trendData: filteredTrends });
        } else if (
          searchMatchesLocationString(formattedLocation, search) ||
          (!formattedLocation && nullDataText.includes(search))
        ) {
          memo.push(location);
        }

        return memo;
      }
    }, []);
  } else if (settings.internalData === 'cityState') {
    filteredLocations = data.reduce((memo, location) => {
      if (location?.family) {
        const filteredSubRows = location.trendData?.filter(trend =>
          searchMatchesLocationString(trend.formattedLocation, search)
        );

        if (filteredSubRows.length > 0) {
          memo.push({ ...location, trendData: filteredSubRows });
        } else if (location.family.toLowerCase().includes(search)) {
          memo.push(location);
        }

        return memo;
      } else {
        const filteredTrends = location.trendData?.filter(subRow => subRow.jobFamily?.toLowerCase().includes(search));
        if (filteredTrends?.length > 0) {
          memo.push({ ...location, trendData: filteredTrends });
        } else if (searchMatchesLocationString(location.formattedLocation, search)) {
          memo.push(location);
        }

        return memo;
      }
    }, []);
  }
  return filteredLocations;
};

export function searchMatchesLocationString(location, search) {
  if (!location) {
    return false;
  }

  const cityState = splitCityState(location);
  const city = cityState[0];
  const state = cityState[1] || '';
  const isAbbreviated = state.length === 2;
  const fullState = isAbbreviated ? states[state] || '' : state;
  const abbreviatedState = isAbbreviated ? state : Object.keys(states)[Object.values(states).indexOf(state)] || '';
  const locationWithAbbreviatedState = getLocationWithAbbreviatedState(location);
  const locationWithFullState = getLocationWithFullState(location);

  return (
    locationWithAbbreviatedState.toLowerCase().includes(search) ||
    locationWithFullState.toLocaleLowerCase().includes(search) ||
    city.toLowerCase().includes(search) ||
    abbreviatedState.toLowerCase().includes(search) ||
    fullState.toLowerCase().includes(search)
  );
}

export const getLocationWithFullState = location => {
  if (!location) {
    return location;
  }
  const cityState = splitCityState(location);
  const state = cityState[1] || '';
  const isAbbreviated = state.length === 2;
  if (!isAbbreviated) {
    return location;
  }
  const fullState = states[state.toUpperCase()] || '';
  return isAbbreviated ? location.replace(state, fullState) : location;
};

export const getLocationWithAbbreviatedState = location => {
  if (!location) {
    return location;
  }
  const cityState = splitCityState(location);
  const state = cityState[1] || '';
  if (state.length <= 2) {
    return location;
  }
  const abbreviatedState = Object.keys(states)[Object.values(states).indexOf(state)] || '';

  if (cityState[0] === state) {
    return `${cityState[0]}, ${abbreviatedState}`;
  }

  return location.replace(state, abbreviatedState);
};

export const setLocationMappingData = data => {
  let abbreviation, city, state;
  return data.map(datum => {
    if (datum.mappedPayScaleLocation) {
      city = datum.mappedPayScaleLocation.split(',')[0]?.trim();
      state = datum.mappedPayScaleLocation.split(',')[1]?.trim();
      abbreviation = splitCityState(getLocationWithAbbreviatedState(`${city}, ${state}`))[1].toUpperCase();
      const formattedLocation = getFormattedLocation(datum);

      return {
        ...datum,
        abbreviation,
        payScaleLocation: `${city}, ${state}; United States`,
        formattedLocation
      };
    } else if (datum.city && datum.state) {
      city = capitalizeLocationTerm(datum.city.toLowerCase());
      state = splitCityState(getLocationWithFullState(`${datum.city}, ${datum.state}`))[1];
      abbreviation = splitCityState(getLocationWithAbbreviatedState(`${datum.city}, ${datum.state}`))[1].toUpperCase();

      return {
        ...datum,
        state,
        abbreviation,
        payScaleLocation: `${city}, ${state}; United States`,
        formattedLocation: `${datum.city}, ${datum.state}`
      };
    } else if (datum.work_location) {
      const splitLocation = splitCityState(datum.work_location);
      city = capitalizeLocationTerm(splitLocation[0]?.trim()?.toLowerCase());
      state = splitLocation[1]?.trim();
      state = splitCityState(getLocationWithFullState(`${city}, ${state}`))[1];
      abbreviation = splitCityState(getLocationWithAbbreviatedState(`${city}, ${state}`))[1].toUpperCase();

      return {
        ...datum,
        city,
        state,
        abbreviation,
        payScaleLocation: `${city}, ${state}; United States`,
        formattedLocation: `${datum.work_location}`
      };
    }

    return {
      ...datum
    };
  });
};
