export const getSentryApiKey = () => {
  let isStagingOrProd = false;
  if (
    window.location.hostname.match('panther') ||
    window.location.hostname.match('merlin') ||
    window.location.hostname.match('lab') ||
    window.location.hostname.match('www.marketpay') ||
    window.location.hostname.match(/mp[1-9]{1,3}.marketpay.net/) ||
    window.location.hostname.match('utx.marketpay.net')
  ) {
    isStagingOrProd = true;
  }

  let publicDSN;
  if (isStagingOrProd) {
    publicDSN = 'https://7ff940c9c6ef401cb844a79cc3083e3b@sentry.io/105343';

    if (window.location.hostname.match('lab')) {
      publicDSN = 'https://55286b498f1e4d6492f30166fbbdde4b@sentry.io/1228381';
    }
  }

  return publicDSN;
};
