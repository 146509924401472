import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Row from '../Table/Row/Row';
import classnames from 'classnames';

class AdvancedRow extends Component {
  static propTypes = {
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    dataRowKey: PropTypes.string,
    className: PropTypes.string,
    rowType: PropTypes.string,
    onClick: PropTypes.func,
    data: PropTypes.any,
    getRowProps: PropTypes.func,
    getRowIcon: PropTypes.func,
    isSelected: PropTypes.bool
  };

  constructor(props) {
    super(props);
    this.callOnClick = this.callOnClick.bind(this);
  }

  callOnClick(event) {
    if (this.props.onClick) {
      if (this.props.data) {
        this.props.onClick(this.props.data, event);
        return;
      }
      return this.props.onClick(event);
    }
  }

  render() {
    const { children, className, dataRowKey, getRowIcon, getRowProps, rowType, isSelected } = this.props;

    return (
      <Row
        getRowProps={getRowProps}
        dataRowKey={dataRowKey}
        onClick={this.callOnClick}
        className={classnames('advanced-table__body-row', className, {
          selected: isSelected
        })}
        rowType={rowType}
      >
        {getRowIcon && getRowIcon(this.props.data)}
        {children}
      </Row>
    );
  }
}

export default AdvancedRow;
