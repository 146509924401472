import React, { Component } from 'react';
import { func } from 'prop-types';
import { ParticipationMatch } from '../../../../types/MatchReviewTypes';
import './ZeroWeightedSurveyJobCard.scss';
import { Segmented } from '@payscale/design';
import TruncatedString from '../../../../../../TruncatedString/TruncatedString';
import capitalize from 'lodash/capitalize';

class ZeroWeightedSurveyJobCard extends Component {
    static propTypes = {
        match: ParticipationMatch.isRequired,
        updateMatch: func.isRequired
    };

    constructor(props) {
        super(props);
        this.clickIncludeToggle = this.clickIncludeToggle.bind(this);
        this.expandJobDescription = this.expandJobDescription.bind(this);
        this.renderJobDescription = this.renderJobDescription.bind(this);
        this.state = { isDescriptionExpanded: false };
    }

    clickIncludeToggle() {
        const { match, updateMatch } = this.props;
        const newExcludeState = !match.exclude;
        updateMatch(match.matchId, newExcludeState);
    }

    expandJobDescription() {
        this.setState({ isDescriptionExpanded: !this.state.isDescriptionExpanded });
    }

    renderJobDescription() {
        const { orgJobDescription } = this.props.match;
        if (!orgJobDescription || orgJobDescription.length === 0) return;

        const maxLength = 200;
        if (orgJobDescription.length < maxLength || this.state.isDescriptionExpanded) {
            return (
                <p className="pcenter-review-duplicates-survey-job-card__description">
                    {orgJobDescription + ' '}
                    <a onClick={this.expandJobDescription}>less</a>
                </p>
            );
        } else {
            return (
                <p className="pcenter-review-duplicates-survey-job-card__description">
                    {orgJobDescription.substring(0, maxLength)}
                    <a onClick={this.expandJobDescription}>...more</a>
                </p>
            );
        }
    }

    getModifiedClassName(className, isIncluded) {
        if (isIncluded) {
            return `${className} ${className}--included`;
        } else {
            return className;
        }
    }

    render() {
        const { match } = this.props;
        const isIncluded = match.exclude;
        return (
            <div className={this.getModifiedClassName('pcenter-review-zero-weights-job-card', isIncluded)}>
                <section className="pcenter-review-zero-weights-job-card__top">
                    <div>
                        <div className="pcenter-review-zero-weights-job-card-top__org-job-title">
                            {match.orgJobTitle}
                        </div>
                        <div className="pcenter-review-zero-weights-job-card-top__org-job-details">
                            <label className="pcenter-review-zero-weights-job-card__label">Job Code</label>
                            <div className="pcenter-review-zero-weights-job-card-top__value">{match.orgJobCode}</div>
                            <label className="pcenter-review-zero-weights-job-card__label">Job Code Key</label>
                            <div className="pcenter-review-zero-weights-job-card-top__value">{match.orgJobCodeKey}</div>
                        </div>
                    </div>
                    <Segmented
                        className="pxl-segmented-secondary"
                        justified={true}
                        value={match.exclude === true}
                        onChange={this.clickIncludeToggle}
                        options={[
                            { text: 'Exclude', value: true },
                            { text: 'Include', value: false }
                        ]}
                    />
                </section>
                <section className="pcenter-review-zero-weights-job-card__bottom">
                    <section className="pcenter-review-zero-weights-job-card__bottom-left">
                        <div className="pcenter-review-zero-weights-job-card-bottom__match-to-label">Matched to</div>
                        <div className="pcenter-review-zero-weights-job-card-bottom__survey-job-code">
                            {match.surveyJobCode}
                        </div>
                        <div
                            className={this.getModifiedClassName(
                                'pcenter-review-zero-weights-job-card-bottom__survey-job-title',
                                isIncluded
                            )}
                        >
                            {match.surveyJobTitle}
                        </div>
                        <TruncatedString maxChar={150}>{match.surveyJobDescription}</TruncatedString>
                    </section>
                    <section className="pcenter-review-zero-weights-job-card__bottom-right">
                        <div className="pcenter-review-zero-weights-job-card__label">Type of Match</div>
                        <div className="pcenter-review-zero-weights-job-card-bottom__match-type">
                            {capitalize(match.matchType)}
                        </div>
                        <div className="pcenter-review-zero-weights-job-card__label">Notes</div>
                        <TruncatedString maxChar={75} className="pcenter-review-zero-weights-job-card-bottom__notes">
                            {match.notes}
                        </TruncatedString>
                    </section>
                </section>
            </div>
        );
    }
}

export default ZeroWeightedSurveyJobCard;
