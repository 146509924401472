import SearchPanel from '../../../../../ps-components/src/redux/facetedSearch';
const SET_SELECTED_FACET_ONLY = 'SURVEYS_SET_SELECTED_FACET_ONLY';
const SET_FILTER_ONLY = 'SURVEYS_SET_FILTER_ONLY';
const SET_FILTER = 'SURVEYS_SET_FILTER';

const initialState = {
  facetSelection: {},
  facets: [],
  loading: false,
  loaded: false,
  error: null,
  items: [],
  query: '',
  showAddButton: false,
  addButtonText: 'Add Survey',
  resetFacetText: 'Reset Survey Filters',
  searchText: 'Find Surveys',
  filters: {}
};

export const surveysSearchPanel = new SearchPanel('SURVEYS', initialState);

function extendReducer(state, action) {
  switch (action.type) {
    case SET_SELECTED_FACET_ONLY:
      return {
        ...state,
        facetSelection: {
          ...state.facetSelection,
          [action.facetId]: { [action.facetItem.key]: true }
        }
      };
    case SET_FILTER_ONLY:
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.id]: action.value
        }
      };
    default:
      return null;
  }
}
export const reducer = surveysSearchPanel.createReducer(extendReducer, {
  searchAPI: '/api/elasticsearch/search/queryStringSiteSurveys'
});

export const actions = surveysSearchPanel.getActions();
export const selectors = surveysSearchPanel.getSelectors();

export function getFacetById(state, id) {
  return state.employeesSearch.facets.filter(facet => facet.id === id)[0];
}

actions.viewFacet = function viewFacet(facetId, facetItem, only) {
  return (dispatch, getStore) => {
    const { facetSelection, loaded } = getStore().jobsSearch;

    dispatch({ type: 'SURVEYS_REMOVE_ALL_SELECTED' });

    if (loaded) {
      const facet = getFacetById(getStore(), facetId);
      actions.facetItemClick(facet, facetItem, only)(dispatch, getStore);
    } else {
      dispatch({
        type: SET_SELECTED_FACET_ONLY,
        facetId,
        facetItem
      });
    }
  };
};

actions.setFilter = function addFilter({ id, value, only }) {
  return (dispatch, getStore) => {
    const { loaded } = getStore().employeesSearch;

    dispatch({ type: 'SURVEYS_SET_QUERY', query: '' });
    dispatch({ type: 'SURVEYS_REMOVE_ALL_SELECTED' });

    dispatch({
      type: only ? SET_FILTER_ONLY : SET_FILTER,
      id,
      value
    });
    if (loaded) {
      const params = selectors.getSearchParameters(getStore());
      dispatch(actions.search({ params }));
    }
  };
};
