/* eslint-disable */
import { CREATE_STRUCTURE_SUCCESS } from './createStructure';
const NEW_STRUCT_MODAL_OPEN = 'NEW_STRUCT_MODAL_OPEN';
const NEW_STRUCT_MODAL_CLOSE = 'NEW_STRUCT_MODAL_CLOSE';
export const NUMBER_INPUT_CHANGE = 'NEW_STRUCT_MODAL_NUMBER_INPUT_CHANGE';
const CURRENCY_CHANGE = 'NEW_STRUCT_MODAL_CURRENCY_CHANGE';
export const NAME_CHANGE = 'NEW_STRUCT_MODAL_NAME_CHANGE';
const TYPE_TOGGLE = 'TYPE_TOGGLE';

const initialState = {
  isOpen: false,
  formValues: {
    structureName: '',
    currency: 'USD',
    highMidpoint: 100000,
    lowMidpoint: 10000,
    numRanges: 20,
    progression: 0.129,
    typeToggleValue: 'annual'
  }
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case NEW_STRUCT_MODAL_CLOSE:
      return { ...state, isOpen: false };
    case NEW_STRUCT_MODAL_OPEN:
      return { ...state, isOpen: true };
    case NUMBER_INPUT_CHANGE:
      return { ...state, formValues: { ...action.newFormValues } };
    case CURRENCY_CHANGE:
      return { ...state, formValues: { ...state.formValues, currency: action.newCurrency } };
    case NAME_CHANGE:
      return { ...state, formValues: { ...state.formValues, structureName: action.newName } };
    case CREATE_STRUCTURE_SUCCESS:
      return action.result.success ? { ...state, isOpen: false } : state;
    case TYPE_TOGGLE:
      return {
        ...state,
        formValues: {
          ...state.formValues,
          typeToggleValue: action.typeToggleValue,
          lowMidpoint: action.lowMidpoint,
          highMidpoint: action.highMidpoint
        }
      };
    default:
      return state;
  }
}

export const typeToggleClick = event => (dispatch, getState) => {
  let lowMidpoint;
  let highMidpoint;

  if (event.target.dataset.togglepreset === 'annual') {
    lowMidpoint = 10000;
    highMidpoint = 100000;
  } else {
    lowMidpoint = '10.00';
    highMidpoint = '100.00';
  }
  dispatch({
    type: 'TYPE_TOGGLE',
    typeToggleValue: event.target.dataset.togglepreset,
    lowMidpoint: lowMidpoint,
    highMidpoint: highMidpoint
  });
  event.target.name = 'highMidpoint';
  event.target.value = highMidpoint;
  const updateProgression = formNumberInputChange(
    getState().createStructureModal.formValues,
    event
  );
  dispatch(updateProgression);
};

export const newStructureModalOpen = () => ({ type: NEW_STRUCT_MODAL_OPEN });
export const newStructureModalClose = () => ({ type: NEW_STRUCT_MODAL_CLOSE });

const parseToDecimalNum = inputVal => {
  let parsedVal = inputVal
    .replace(/[^0-9-.]/g, '')
    .replace(/-/g, '')
    .replace(/([^.]*\.[^.]*)\./g, '$1');
  return parsedVal;
};

const parseToWholeNum = inputVal => {
  let parsedVal = inputVal.replace(/[^0-9]/g, '').replace(/-/g, '');
  return parsedVal;
};

export function formNumberInputChange(formValues, event) {
  let value = event.target.value;
  if (event.target.name === 'progression') {
    value =
      event.target.value || event.target.value === 0 ? parseFloat(event.target.value) / 100 : '';
  }
  if (event.target.name === 'highMidpoint' || event.target.name === 'lowMidpoint') {
    // value = event.target.value || event.target.value === 0 ? Math.round(event.target.value) : '';
    if (formValues.typeToggleValue === 'annual') {
      value = parseToWholeNum(event.target.value);
    } else {
      value = parseToDecimalNum(event.target.value);
    }
  }
  if (event.target.name === 'numRanges') {
    value = parseToWholeNum(event.target.value);
    // value = event.target.value || event.target.value === 0 ? parseFloat(event.target.value) : '';
  }
  const { highMidpoint, lowMidpoint } = formValues;
  const newFormValues = { ...formValues, [event.target.name]: value };

  if (/lowMidpoint|highMidpoint|numRanges/.test(event.target.name)) {
    calculateProgression(newFormValues);
  } else {
    if (lowMidpoint && !highMidpoint) calculateHighMidpoint(newFormValues);
    if (!lowMidpoint && highMidpoint) calculateLowMidpoint(newFormValues);
    if (lowMidpoint && highMidpoint) calculateHighMidpoint(newFormValues);
  }

  return { type: NUMBER_INPUT_CHANGE, newFormValues };
}

export const structureNameChange = event => (dispatch, getState) => {
  const formValues = getState().createStructureModal.formValues;
  const newFormValues = { ...formValues, structureName: event.target.value };
  dispatch({ type: NAME_CHANGE, newName: event.target.value, newFormValues });
};

export const currencyChange = value => ({ type: CURRENCY_CHANGE, newCurrency: value });

const calculateHighMidpoint = newFormValues => {
  const lowMidpoint = parseFloat(newFormValues.lowMidpoint) || 0;
  const numRanges = parseFloat(newFormValues.numRanges) || 0;
  const progression = newFormValues.progression || 0;
  // const {lowMidpoint, numRanges, progression} = newFormValues;
  let accumulator = lowMidpoint;

  for (let rangeCntr = 0; rangeCntr < numRanges - 1; rangeCntr++) {
    accumulator = accumulator * (1 + progression);
  }
  if (accumulator === Infinity || accumulator === -Infinity) accumulator = undefined;
  newFormValues.highMidpoint = Math.round(accumulator) || accumulator;
};

const calculateLowMidpoint = newFormValues => {
  const highMidpoint = parseFloat(newFormValues.highMidpoint) || 0;
  const numRanges = parseFloat(newFormValues.numRanges) || 0;
  const progression = newFormValues.progression || 0;
  // const {highMidpoint, numRanges, progression} = newFormValues;
  let accumulator = highMidpoint;

  for (let rangeCntr = 0; rangeCntr < numRanges - 1; rangeCntr++) {
    accumulator -= accumulator * progression;
  }
  if (accumulator === Infinity || accumulator === -Infinity) accumulator = undefined;
  newFormValues.lowMidpoint = Math.round(accumulator) || accumulator;
};

const calculateProgression = newFormValues => {
  const highMidpoint = parseFloat(newFormValues.highMidpoint) || 0;
  const lowMidpoint = parseFloat(newFormValues.lowMidpoint) || 0;
  let numRanges = parseFloat(newFormValues.numRanges) || 0;

  let progression = Math.pow(highMidpoint / lowMidpoint, 1.0 / (numRanges - 1.0)) - 1.0;
  if (progression === Infinity || progression === -Infinity) progression = undefined;

  newFormValues.progression = progression;
};
