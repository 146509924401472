import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './Button.scss';

class Button extends Component {
  static propTypes = {
    className: PropTypes.string,
    children: PropTypes.any,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    dataTestId: PropTypes.string
  };

  render() {
    const { className, children, onClick, disabled, dataTestId } = this.props;

    return (
      <button className={className} onClick={onClick} disabled={disabled} data-testid={dataTestId}>
        {children}
      </button>
    );
  }
}

export default Button;
