import { CustomJobIndicator } from '../ModelDetailsPage/ModelJobsList/CustomJobIndicator';
import { getJobSettings } from '@payscale/ps-isomorphic/dist/JobRanges/Utils';
import { ANNUAL_PAY_TYPE, HOURLY_PAY_TYPE, THOUSANDS_PAY_TYPE } from './constants';
import React from 'react';
import sortBy from 'lodash/sortBy';

/**
 * Retrieves the market reference value (refer to MPE-2292 for background)
 * @param model the model
 * @param job the job
 * @returns (*) the market data reference point field value
 */
export const getMarketReferenceValue = (model, job) => {
  const field = getMarketReferenceField(model, job);
  if (!field) return null;
  else return job[field];
};

/**
 * Retrieves the market reference field (refer to MPE-2292 for background)
 * @param model the model
 * @param job the job
 * @returns (*) the market data reference point field name
 */
export const getMarketReferenceField = (model, job) => {
  const settings = getJobSettings(model, job);
  if (!settings) {
    return null;
  } else {
    let fieldPrefix;
    //  MPE-2292: Support Market Target values which hold postfix e.g. 25th, 50th, instead of the full market field name
    switch (settings.proposedRangeSetting) {
      case 'target_base_salary':
        fieldPrefix = 'market_base_salary';
        break;
      case 'target_total_cash':
        fieldPrefix = 'market_total_cash_paid';
        break;
      case 'target_total_target_cash':
        fieldPrefix = 'market_ttc';
        break;
      case 'target_total_direct':
        fieldPrefix = 'market_total_direct';
        break;
      case 'target_total_guaranteed_pay':
        fieldPrefix = 'total_guaranteed_pay';
        break;
      default:
        break;
    }

    if (!fieldPrefix) {
      return settings.proposedRangeSetting;
    } else {
      const postfix = job[settings.proposedRangeSetting];
      if (postfix) {
        return `${fieldPrefix}_${postfix}`;
      } else {
        return undefined;
      }
    }
  }
};

/**
 * Converts a value based on a paytype to a rounded annual value
 * @param value an annual or hourly value
 * @param payType must be 'annual' or 'hourly'
 * @returns {*} rounded annual value
 */
export const convertPayTypeToRoundedAnnual = (value, payType) => {
  if (!value) return value;

  if (payType === ANNUAL_PAY_TYPE) {
    return Math.round(value);
  } else if (payType === HOURLY_PAY_TYPE) {
    let annual = value * 2080;
    let annualRound = Math.round(annual * 100) / 100;
    return annualRound;
  } else {
    return null;
  }
};

/**
 * Converts an annual value to a rounded value based on paytype
 * @param value an annual value
 * @param payType must be 'annual' or 'hourly'
 * @returns {*} rounded pay type value
 */
export const convertAnnualToRoundedPayType = (value, payType) => {
  if (!value) return value;

  if (payType === ANNUAL_PAY_TYPE) {
    return Math.round(value);
  } else if (payType === HOURLY_PAY_TYPE) {
    let hourly = value / 2080;
    let hourlyRound = Math.round(hourly * 100) / 100;
    return hourlyRound;
  } else if (payType === THOUSANDS_PAY_TYPE) {
    let thousands = value / 1000;
    let thousandsRound = Math.round(thousands * 100) / 100;
    return thousandsRound;
  } else {
    return null;
  }
};

export const currencyFormatter = (value, { roundToNearest, payType }) => {
  if (!value) return value;

  if (payType === HOURLY_PAY_TYPE) {
    let hourly = value / 2080;
    let hourlyRound = Math.round(hourly / roundToNearest) * roundToNearest;
    return hourlyRound.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,');
  }

  const valueRound = Math.round(value / roundToNearest) * roundToNearest;
  return valueRound
    .toFixed(2)
    .replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
    .slice(0, -3);
};

export const sortByColumnDirection = (direction, column, models) => {
  const columns = column === 'rangeType' ? [column, 'name'] : [column];
  const modelsSortedByColumn = sortBy(models, columns);
  return direction === 'DESC' ? modelsSortedByColumn.reverse() : modelsSortedByColumn;
};

export const filterJobList = (jobs, searchTerm) => {
  return jobs.filter(job => job.org_job_title.toLowerCase().includes(searchTerm.toLowerCase()));
};
