/**
 * Determines if there is at least one unresolved duplicate or zero weighted match
 * @param duplicateMatches duplicate matches
 * @param zeroWeightedMatches zero weighted matches
 * @returns {boolean} <b>true</b> if there are unresolved matches.
 */
export const containsUnresolvedMatches = (duplicateMatches, zeroWeightedMatches) => {
  if (!duplicateMatches || !zeroWeightedMatches) return undefined;

  for (let i = 0; i < duplicateMatches.length; i++) {
    if (areOrgJobDuplicatesUnresolved(duplicateMatches[i])) {
      return true;
    }
  }

  for (let i = 0; i < zeroWeightedMatches.length; i++) {
    if (zeroWeightedMatches[i].exclude !== true) {
      return true;
    }
  }

  return false;
};

/**
 * Retrieves the number of Participation Match Org Jobs that do not have a pending user action to resolve duplicate matches
 * @param participationMatchOrgJobs list of pcenter match org job objects
 * @returns {*}
 */
export const getNumberOfOrgJobsWithUnresolvedDuplicateMatches = participationMatchOrgJobs => {
  if (!participationMatchOrgJobs || participationMatchOrgJobs.length === 0) return 0;

  return participationMatchOrgJobs.reduce((sum, orgJob) => {
    if (areOrgJobDuplicatesUnresolved(orgJob)) {
      return sum + 1;
    } else {
      return sum;
    }
  }, 0);
};

/**
 * Determines if a Participation Match Org Job has unresolved duplicates
 * @param participationMatchOrgJob pcenter match org job objects
 * @returns {boolean} true if pending an update. false if no update is pending
 */
export const areOrgJobDuplicatesUnresolved = participationMatchOrgJob => {
  //  if more than 1 match is included, it is unresolved for duplicates
  const includedDuplicateCount = participationMatchOrgJob.matches.reduce((sum, match) => {
    if (!match.exclude) {
      return sum + 1;
    } else {
      return sum;
    }
  }, 0);

  return includedDuplicateCount > 1 || includedDuplicateCount == 0;
};
