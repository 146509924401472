import PropTypes from 'prop-types';
import React from 'react';

export default class CommonErrorBoundary extends React.Component {
  static propTypes = {
    errorMessage: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
  };

  constructor(props) {
    super(props);
    this.state = { error: null };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { error: error };
  }

  componentDidCatch(error, info) {
    // You can also log the error to an error reporting service
    this.setState({
      error: error
    });
  }

  render() {
    if (this.state.error) {
      const errorMessage = this.props.errorMessage ? this.props.errorMessage : null;
      // You can render any custom fallback UI
      return <div>{errorMessage}</div>;
    }

    return this.props.children;
  }
}
