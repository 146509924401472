import React, { useEffect, useRef } from 'react';
import { array, object, node, number, func, string, bool, arrayOf, shape, oneOfType } from 'prop-types';
import Table from '../Table/Table/Table';
import TableHeader from '../Table/TableHeader/TableHeader';
import TableBody from '../Table/TableBody/TableBody';
import TableIndicator from '../LoadingIndicator/TableIndicator/TableIndicator';
import CellIndicator from '../LoadingIndicator/CellIndicator/CellIndicator';
import AdvancedRow from './AdvancedRow';
import AdvancedCell from './AdvancedCell';
import CheckboxInput from '../Input/CheckboxInput';
import './AdvancedTable.scss';
import classnames from 'classnames';
import { useTable, useSortBy, useFlexLayout, useResizeColumns } from 'react-table';
import AdvancedSubRows from './AdvancedSubRows';

export const ASCENDING = 'asc',
  DESCENDING = 'desc';

export const formatSubHeader = ({ column }) => {
  return (
    <React.Fragment>
      <div>
        {column.HeaderText}
        <span>
          {column.isSorted ? (
            column.isSortedDesc ? (
              <i className="icon-sort-down" />
            ) : (
              <i style={{ top: '3px' }} className="icon-sort-up" />
            )
          ) : (
            ''
          )}
        </span>
      </div>
      <div className={'advanced-table__sub-header'}>{column.SubHeaderText}</div>
    </React.Fragment>
  );
};

formatSubHeader.propTypes = {
  column: object
};

export default function AdvancedTable(props) {
  // If props are simple (i.e. string column headers), render basic table
  if (
    Array.isArray(props.columnHeaders) &&
    props.columnHeaders.length > 0 &&
    typeof props.columnHeaders[0] == 'string'
  ) {
    return BasicTable({
      headers: props.columnHeaders,
      rows: props.rowData,
      className: props.className,
      headerClassName: props.headerClassName,
      headerRowClassName: props.headerRowClassName,
      bodyClassName: props.bodyClassName,
      dataLoading: props.dataLoading,
      getDataRow: props.getDataRow
    });
  }

  // Adjust widths for fixed left columns
  // 32.38 is width of checkbox cell
  const hasSelectCheckbox = props.useSelectAccessor && props.isChecked && !props.hideSelect;

  let leftAlignSum = hasSelectCheckbox ? 32.38 : 0,
    groupLeftWidthSum = hasSelectCheckbox ? 32.38 : 0,
    hasStickyLeftCol = false,
    prevCol = null;
  props.columnHeaders.forEach(col => {
    if (col.stickyLeft) {
      hasStickyLeftCol = true;
      if (col.columns) {
        col.leftAlignOffset = leftAlignSum;
        col.columns.forEach((subCol, idx) => {
          if (
            subCol.accessor?.includes('_default') ||
            subCol.id?.includes('_default') ||
            props.hiddenColumns?.includes(subCol.accessor) ||
            props.hiddenColumns?.includes(subCol.id)
          ) {
            return;
          }
          subCol.leftAlignOffset = leftAlignSum;
          leftAlignSum += subCol.width ? subCol.width : 0;
          groupLeftWidthSum += subCol.width ? subCol.width : 0;
          if (idx > 0) {
            col.columns[idx - 1].lastStickyLeft = false;
          }
          subCol.lastStickyLeft = true;
        });
        col.width = groupLeftWidthSum;
        groupLeftWidthSum = 0;
      } else {
        col.leftAlignOffset = leftAlignSum;
        if (!props.hiddenColumns.includes(col.id) && !props.hiddenColumns.includes(col.accessor)) {
          leftAlignSum += col.width ? col.width : 0;
        }
      }
      col.lastStickyLeft = true;
      if (prevCol) {
        prevCol.lastStickyLeft = false;
      }
      prevCol = col;
    }
  });

  // Adjust widths for fixed right columns
  let rightAlignSum = 0,
    groupRightWidthSum = 0,
    hasStickyRightCol = false;
  prevCol = null;
  props.columnHeaders.forEach(col => {
    if (col.stickyRight) {
      hasStickyRightCol = true;
      if (col.columns) {
        col.rightAlignOffset = rightAlignSum;
        col.columns.forEach((subCol, idx) => {
          if (
            subCol.accessor?.includes('_default') ||
            subCol.id?.includes('_default') ||
            props.hiddenColumns?.includes(subCol.accessor) ||
            props.hiddenColumns?.includes(subCol.id)
          ) {
            return;
          }
          subCol.rightAlignOffset = rightAlignSum;
          rightAlignSum += subCol.width ? subCol.width : 0;
          groupRightWidthSum += subCol.width ? subCol.width : 0;
          if (idx > 0) {
            col.columns[idx - 1].lastStickyRight = false;
          }
          subCol.lastStickyRight = true;
        });
        col.width = groupRightWidthSum;
        groupRightWidthSum = 0;
      } else {
        col.rightAlignOffset = rightAlignSum;
        rightAlignSum += col.width ? col.width : 0;
      }
      col.lastStickyRight = true;
      if (prevCol) {
        prevCol.lastStickyRight = false;
      }
      prevCol = col;
    }
  });

  const shouldAllowSorting = !(props.staticTable || !props.allowSorting);
  let plugins = [useFlexLayout];
  if (shouldAllowSorting) plugins.push(useSortBy);
  if (props.allowResize) plugins.push(useResizeColumns);

  const hiddenColumns =
    props.hiddenColumns?.concat(
      props.columnHeaders
        ?.filter(header => header.id?.endsWith('_default') || (props.hideCustom && header.id?.endsWith('_null')))
        .map(header => header.id)
    ) || [];

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: { sortBy }
  } = useTable(
    {
      columns: props.columnHeaders || [],
      data: props.rowData || [],
      options: props.options,
      disableSortBy: !shouldAllowSorting,
      disableResizing: !props.allowResize,
      manualSortBy: shouldAllowSorting && !props.useClientSideSort,
      initialState: { hiddenColumns, sortBy: props?.initialSortBy },
      getSubRows: props.getSubRows
    },
    ...plugins
  );

  let footerRows = [];
  if (props.summaryData?.length > 0) {
    footerRows = [...props.summaryData];
    if (props.options.showMarketComparison && props.marketComparisonRows?.length > 0) {
      footerRows = footerRows.concat(props.marketComparisonRows);
    }
  }

  let { rows: tfootRows } = useTable(
    {
      columns: props.tfootHeaders || props.columnHeaders || [],
      data: footerRows
    },
    useFlexLayout
  );

  tfootRows.forEach((row, idx) => {
    if (idx < props.summaryData.length) {
      row.isSummary = true;
    } else {
      row.isMarketComparisonRow = true;
    }
  });

  const firstUpdateSort = useRef(true);

  // Use effect to handle onSort calls
  if (shouldAllowSorting) {
    useEffect(() => {
      headerGroups?.forEach(group =>
        group?.headers?.forEach(header => {
          if (header.clearSortBy) header.clearSortBy();
        })
      );
    }, props.resetSortState);

    useEffect(() => {
      if (firstUpdateSort.current) {
        firstUpdateSort.current = false;
        return;
      }
      if (props.onSortChange) props.onSortChange(sortBy);
    }, [sortBy]);
  }

  const getRows = (prepareRow, rows) => {
    return (
      rows &&
      rows.length > 0 &&
      rows.map((el, idx) => {
        // Prevent double render of last row
        prepareRow(el);

        return renderRow(el, idx);
      })
    );
  };

  const rowClick = (el, event) => {
    if (event.target.className.includes('icon-check')) {
      return;
    } else if (props.onRowClick) {
      props.onRowClick(el);
    }
  };

  const renderRow = (el, idx) => {
    const cellClassName = classnames({ disabled: props.isSelectAllDisabled });
    const meta = {
      leftAlignSum,
      rightAlignSum
    };
    const advancedRow = (
      <>
        <AdvancedRow
          getRowProps={el.getRowProps}
          data={el}
          onClick={rowClick}
          className={props.rowClassName}
          dataRowKey={el.id}
          key={el.id}
          getRowIcon={props.getRowIcon}
          isSelected={hasSelectCheckbox && props.isChecked(props.useSelectAccessor(el))}
        >
          {hasSelectCheckbox && (
            <AdvancedCell stickyLeft={hasStickyLeftCol} left={0} className={cellClassName}>
              {props.useSelectAccessor && props.loadingId && props.loadingId === props.useSelectAccessor(el) ? (
                <CellIndicator iconClass="icon-animate-spin icon-spin cell-indicator" />
              ) : (
                <CheckboxInput
                  style={{ visibility: el.isSummary ? 'hidden' : 'visible' }}
                  id={props.useSelectAccessor(el)}
                  onClick={props.onCheck}
                  checked={props.isChecked(props.useSelectAccessor(el))}
                  disabled={
                    props.isSelectAllDisabled ||
                    props.isSelectDisabled?.(props.useDisabledAccessor?.(el) || props.useSelectAccessor?.(el))
                  }
                  tooltipText={props.getCheckBoxTooltipText?.(el)}
                />
              )}
            </AdvancedCell>
          )}
          {props.getDataRow(el, idx, meta)}
        </AdvancedRow>

        {renderSubRows(el, `sub-${idx}`, meta)}
      </>
    );

    return advancedRow;
  };

  function renderSubRows(el, idx, meta) {
    const subRows = el.subRows;
    let subRowData;
    let showSubRows = false;

    if (subRows && subRows.length > 0) {
      subRowData = props.getSubRowData(el);

      if (subRowData) {
        showSubRows = true;
      }
    }

    return (
      <AdvancedSubRows
        className={classnames('advanced-table__sub-rows', { show: showSubRows, hide: !showSubRows })}
        commentComponent={props.commentComponent}
        defaultLeftColumns={props.defaultLeftColumns}
        defaultRightColumns={props.defaultRightColumns}
        getDataRow={props.getDataRow}
        getShowMoreLink={props.getShowMoreLink}
        hiddenColumns={props.hiddenColumns}
        mainRow={el}
        meta={meta}
        options={props.options}
        plugins={plugins}
        rowClassName={props.rowClassName}
        rowIndexPrefix={props.rowIndexPrefix}
        showUdfs={true}
        subRowData={subRowData}
        subRows={subRows}
      />
    );
  }

  const {
    totalCount,
    columnHeaders,
    dataLoading,
    stickyHeader,
    noDataText,
    loadingText,
    staticTable,
    className,
    headerClassName,
    headerRowClassName,
    bodyClassName,
    tfootClass,
    useSelectAll,
    onSelectAll,
    isAllChecked,
    rowData,
    isSelectAllDisabled,
    useSelectAccessor,
    hideSelect,
    useLoadingIconForSelectAll,
    allowSorting,
    customEmptyComponent
  } = props;

  // Determine if table has grouped columns
  const hasGroupedColumns = columnHeaders?.some(header => {
    return header.columns;
  });

  const footerClassNames = classnames('advanced-table__tfoot', tfootClass);

  const noRows =
    !rowData || totalCount === false || totalCount <= 0 || (totalCount === undefined && rowData.length === 0);
  return (
    <React.Fragment>
      <Table getTableProps={getTableProps} className={classnames('advanced-table', className)}>
        <TableHeader
          sortEnabled={!!allowSorting}
          headerGroups={headerGroups}
          rowsHaveSelect={useSelectAccessor ? true : false}
          useSelectAll={useSelectAll}
          hideSelect={hideSelect}
          onSelectAll={onSelectAll}
          isAllChecked={isAllChecked}
          hasStickyLeftCol={hasStickyLeftCol}
          hasGroupedColumns={hasGroupedColumns}
          headerClass={classnames('advanced-table__header', headerClassName, {
            sticky: stickyHeader
          })}
          headerRowClass={classnames('advanced-table__head-row', headerRowClassName)}
          cellClass={classnames('advanced-table__head-cell', {
            static: staticTable
          })}
          isSelectAllDisabled={isSelectAllDisabled}
          useLoadingIconForSelectAll={useLoadingIconForSelectAll}
        />
        {!props.hideBody && (
          <TableBody
            getTableBodyProps={getTableBodyProps}
            className={classnames('advanced-table__body', bodyClassName, { blocked: dataLoading })}
          >
            {dataLoading && (
              <TableIndicator
                colSpan={columnHeaders.length + (useSelectAll ? 1 : 0)}
                stickyTop={200}
                text={loadingText}
                iconClass="icon-animate-spin icon-spin table-indicator"
              />
            )}
            {!dataLoading && noRows && (
              <TableIndicator colSpan={7} text={noDataText}>
                {customEmptyComponent}
              </TableIndicator>
            )}
            {!noRows && getRows(prepareRow, rows)}
          </TableBody>
        )}
        {tfootRows?.length > 0 && !props.hideFooter && (
          <tfoot className={footerClassNames}>{!noRows && tfootRows && getRows(prepareRow, tfootRows)}</tfoot>
        )}
      </Table>
      <div id="advanced-table__popover" />
    </React.Fragment>
  );
}

AdvancedTable.propTypes = {
  /** Set to true if no sorting, no pagination, etc. is required */
  staticTable: bool,

  /** describes the headers and their specifications */
  columnHeaders: arrayOf(
    shape({
      /** Renders either as the text specified or uses a function to render (see renderSubHeader)  */
      Header: oneOfType([string, func]).isRequired,
      /** Optional headerText field passed in if using a function for 'Header' */
      HeaderText: string,
      /** SubHeader required if using renderSubHeader function with 'Header',
       *  sets a subheader line in the column header */
      SubHeaderText: string,
      /** Determines what field to access in 'rowData'.
       * Accepts a function if the field is nested within an object in which case
       * the function returns the nested field
       */
      accessor: oneOfType([string, func]),
      /** Sets the minWidth of the column (partial support) */
      minWidth: number,
      /** Sets the width of the column, required if using sticky columns */
      width: number,
      /** Determines if the column should be right aligned */
      rightAlign: bool,
      /** Used to specify a custom rendering function for the cell;
       * Function supplies rowData and the key for that column as parameters.
       */
      customRender: func,
      /** stickyLeft and stickyRight have the columns bound to the left or right respectively */
      stickyLeft: bool,
      stickyRight: bool,

      /** Calls this function to get custom column class names */
      getClassNames: func
    })
  ).isRequired,

  /**  data elements used to render the rows of the table that maps to each columnHeader for cells */
  rowData: array,

  /**  total count of results of api call; used for determining if 'no Data' state is rendered */
  totalCount: number,

  /** Iterates and renders each rows' cells using this func
   * Note: A common issue when providing a custom 'getDataRow' function is
   * forgetting to provide the cellProps to each cell. You can do so like so:
        <AdvancedCell getCellProps={cellData.cells[0].getCellProps}>
  */
  getDataRow: func.isRequired,

  /** Boolean to show loading overlay */
  dataLoading: bool,

  /** Inidicate if the table header should be sticky */
  stickyHeader: bool,

  /** String to show when data is loading */
  loadingText: string,

  /** String to show when no data is returned via getData */
  noDataText: string,

  /** Class to apply to table header */
  headerClassName: string,

  /** Class to apply to table header row(s) */
  headerRowClassName: string,

  /** Class to apply to table body */
  bodyClassName: string,

  /** Table class name */
  className: string,

  /** Class name applied to the tfoot element */
  tfootClass: string,

  /** Function to determine what field to get to determine if row is checked */
  useSelectAccessor: func,

  /** Function to determine if row is checked */
  isChecked: func,

  /** Function to determine if row checkbox is disabled */
  isSelectDisabled: func,

  /** Determines whether or not to render/use a select all checkbox in the header */
  useSelectAll: bool,

  /** Function called on select all checkbox click */
  onSelectAll: func,

  /** Funcion called on clicking a row */
  onRowClick: func,

  /** Class name to apply to each row */
  rowClassName: string,

  /** Function called on changing a sort column; has a parameter with the sort information */
  onSortChange: func,

  /** assigns prepended string value to row index key */
  rowIndexPrefix: string,

  /** onCheck function called on checking a row */
  onCheck: func,

  /** determines whether or not the 'select all' check box is checked */
  isAllChecked: bool,

  /** summaryData takes the some form as data but instead of being passed to tbody, it is passed to tfoot */
  summaryData: array,

  marketComparisonRows: array,

  /** Array of column IDs to hide from rendering as independent columns but can still be accessed */
  hiddenColumns: arrayOf(string),

  /** boolean to determine if checkbox is disabled */
  isSelectAllDisabled: bool,

  /** Display loading indicator instead of checkbox when loading an action for this id */
  loadingId: oneOfType([string, number]),

  /** boolean to hide all check boxes **/
  hideSelect: bool,

  /** tfoot headers, uses original headers if not defined */
  tfootHeaders: array,

  /** Show the loading icon for the select all icon */
  useLoadingIconForSelectAll: bool,

  /** any options to make available to the table */
  options: object,
  /** option to allow resizing of the columns, default is false */
  allowResize: bool,
  /** option to allow sorting of the columns, default is false */
  allowSorting: bool,
  /** option to use client side sorting, default is true */
  useClientSideSort: bool,
  /** array of items to listen on for resetting sort state */
  resetSortState: array,
  /** array of items to sort by on initial load of table */
  initialSortBy: array,
  /** custom empty state override */
  customEmptyComponent: oneOfType([arrayOf(node), node]),
  /** function to get subrows for a given row */
  getSubRows: func,
  /** function to get data+terms+groups to render a subrow table for a given row */
  getSubRowData: func,
  /** used to display a custom icon to the left of the row */
  getRowIcon: func,
  /** show a load more link for sub rows */
  getShowMoreLink: func,

  defaultLeftColumns: array,
  defaultRightColumns: array,

  /** optional checkbox tooltip text to show on hover  */
  getCheckBoxTooltipText: string,

  /** different accessor for disabled checkboxs */
  useDisabledAccessor: func,

  /** hide custom headers */
  hideCustom: bool,

  hideFooter: bool,
  hideBody: bool,

  /** if is printing, don't use tfoot since table footer is repeated in every page */
  isPrint: bool
};

AdvancedTable.defaultProps = {
  stickyTopOffset: '0',
  stickyHeader: false,
  loadingText: 'Loading...',
  noDataText: 'No Results Found',
  scrollTopOffset: -20,
  options: {},
  allowResize: false,
  allowSorting: false,
  useClientSideSort: true,
  resetSortState: [],
  initialSortBy: [],
  customEmptyComponent: null,
  isPrint: false
};

// If basic props are passed in, render a basicTable
export function BasicTable(props) {
  return (
    <Table className={classnames('advanced-table', props.className)}>
      <TableHeader
        columns={props.headers}
        headerClass={classnames('advanced-table__header', props.headerClassName)}
        headerRowClass={classnames('advanced-table__head-row', props.headerRowClassName)}
        cellClass={classnames('advanced-table__head-cell')}
      />
      {!props.hideBody && (
        <TableBody className={classnames('advanced-table__body', props.bodyClassName, { blocked: props.dataLoading })}>
          {props.rows.map((row, idx) => props.getDataRow(row, idx))}
        </TableBody>
      )}
    </Table>
  );
}

BasicTable.propTypes = {
  className: string,
  headers: arrayOf(string),
  headerClassName: string,
  headerRowClassName: string,
  bodyClassName: string,
  dataLoading: bool,
  rows: array,
  getDataRow: func
};
