export const LINK_HASH_SUPPORT_CONTACT = '#support-contact';
export const LINK_HASH_YEAR_OVER_YEAR = '#year-over-year';
export const LINK_HASH_PCENTER_MATCH_REVIEW = '#pcenter-review-matches';
export const LINK_HASH_SURVEY_MIGRATION_SETTINGS = '#survey-migration-settings';
export const LINK_HASH_STRUCTURE_MODEL_ANALYTICS = '#structure-model-analytics';
export const LINK_HASH_SKILL_GEO_DIFFERENTIAL = '#skill-geo-differentials';
export const LINK_HASH_JOB_RANGES = '#job-ranges';
export const LINK_HASH_TABLEAU = '#tableau';
export const LINK_HASH_JOB_MATCHING = '#job-matching';
export const LINK_HASH_JOB_MATCHING_RESULTS = '#job_matching-results';
export const LINK_HASH_JOB_RANGES_SUMMARY = '#job-ranges-summary';
export const LINK_HASH_JOB_RANGES_JOB_DETAILS = '#job-ranges-job-details';
export const LINK_HASH_SALARY_RANGE = '#salary-range';
export const LINK_HASH_COPY_MATCHES = '#copy-matches';
export const LINK_HASH_SLU_AGREEMENT = '#slu-agreement';
export const LINK_HASH_SAVE_JOB_PRICING = '#save-job-pricing';
export const LINK_HASH_CONFIGURE_VIEW = '#configure-view';
export const LINK_HASH_CONFIGURE_VIEW_EDIT = '#configure-view-edit';
export const LINK_HASH_CROWD_SOURCED_MATCH = '#crowd_sourced_match';
export const LINK_HASH_REMOVE_SURVEYS = '#remove_surveys';
export const LINK_HASH_MPM = '#marketplace_matches';
export const LINK_HASH_STRUC_BULK_COPY = '#structure_bulk_copy';
export const LINK_HASH_STRUC_BULK_CREATE = '#structure_bulk_create';
export const LINK_HASH_MARKETPLACE_MATCHES_SETTINGS = '#marketplace-matches-settings';
export const LINK_HASH_MARKETPLACE_MATCHES_SURVEY_DETAILS = '#marketplace-matches-survey-details';
export const LINK_HASH_MPM_PURCHASE = '#purchase_survey';
export const LINK_GEO_DIFFERENTIAL_SETTINGS = '#geo_diff_settings';
export const LINK_HASH_ADD_WIDGETS = '#add_widgets';
export const LINK_HASH_JOB_EMPLOYEES = '#employees';
export const LINK_HASH_PRIVATE_SURVEY_JOB_MATCH = '#private-survey-job-match';
export const LINK_HASH_PRIVATE_SURVEY = '#private-survey';
export const LINK_HASH_REGRESSION_SURVEY_JOB_MATCH = '#regression-survey-job-match';
export const LINK_HASH_JOB_DETAILS = '#job-details';

export const DIFFERENTIALS_PATH = 'differentials';
export const YEAR_OVER_YEAR_PATH = 'year-over-year';

export const slideoutAddresses = [
  LINK_HASH_SUPPORT_CONTACT,
  LINK_HASH_YEAR_OVER_YEAR,
  LINK_HASH_PCENTER_MATCH_REVIEW,
  LINK_HASH_SURVEY_MIGRATION_SETTINGS,
  LINK_HASH_STRUCTURE_MODEL_ANALYTICS,
  LINK_HASH_STRUC_BULK_COPY,
  LINK_HASH_STRUC_BULK_CREATE,
  LINK_HASH_SKILL_GEO_DIFFERENTIAL,
  LINK_HASH_JOB_RANGES,
  LINK_HASH_TABLEAU,
  LINK_HASH_JOB_MATCHING,
  LINK_HASH_JOB_MATCHING_RESULTS,
  LINK_HASH_JOB_RANGES_SUMMARY,
  LINK_HASH_JOB_RANGES_JOB_DETAILS,
  LINK_HASH_SALARY_RANGE,
  LINK_HASH_COPY_MATCHES,
  LINK_HASH_SLU_AGREEMENT,
  LINK_HASH_SAVE_JOB_PRICING,
  LINK_HASH_CONFIGURE_VIEW,
  LINK_HASH_CONFIGURE_VIEW_EDIT,
  LINK_HASH_MARKETPLACE_MATCHES_SETTINGS,
  LINK_HASH_MARKETPLACE_MATCHES_SURVEY_DETAILS,
  LINK_HASH_CROWD_SOURCED_MATCH,
  LINK_HASH_REMOVE_SURVEYS,
  LINK_HASH_MPM,
  LINK_HASH_MPM_PURCHASE,
  LINK_GEO_DIFFERENTIAL_SETTINGS,
  LINK_HASH_ADD_WIDGETS,
  LINK_HASH_JOB_EMPLOYEES,
  LINK_HASH_PRIVATE_SURVEY_JOB_MATCH,
  LINK_HASH_PRIVATE_SURVEY,
  LINK_HASH_REGRESSION_SURVEY_JOB_MATCH,
  LINK_HASH_JOB_DETAILS
];
