import {
  TOGGLE_EDIT_JOB_RANGES_MODEL,
  CREATE_NEW_JOB_RANGE_MODEL,
  CREATE_NEW_JOB_RANGE_MODEL_SUCCESS,
  GET_SINGLE_JOB_RANGE_MODEL,
  GET_SINGLE_JOB_RANGE_MODEL_SUCCESS,
  GET_SINGLE_JOB_RANGE_MODEL_FAIL,
  CLOSE_ADD_JOBS_TO_JOB_RANGES_MODEL,
  ADD_JOBS_TO_JOB_RANGES_MODEL_CLICK,
  JOB_RANGES_NAME_INPUT_CHANGE,
  CLEAR_JOB_RANGE_MODEL_DETAILS,
  RANGES_PAY_TYPE_CHANGE,
  REFRESH_JOB_RANGE_MODEL_JOBS,
  REFRESH_JOB_RANGE_MODEL_JOBS_SUCCESS,
  REFRESH_JOB_RANGE_MODEL_JOBS_FAIL,
  RANGES_CURRENCY_CHANGE,
  JOB_RANGES_MOVE_TO_CURRENT,
  JOB_RANGES_MOVE_TO_CURRENT_FAIL,
  JOB_RANGES_MOVE_TO_CURRENT_SUCCESS,
  SHOW_MORE_MODEL_JOBS,
  SHOW_MORE_MODEL_JOBS_SUCCESS,
  SHOW_MORE_MODEL_JOBS_FAIL,
  APPLY_JOBS_TO_RANGE_SUCCESS,
  JOB_RANGES_RANGE_TYPE_OPTIONS,
  JOB_RANGES_SEARCH_JOBS,
  JOB_RANGES_INIT_SEARCH_JOBS,
  JOB_RANGES_SEARCH_JOBS_FAIL,
  GET_JOB_SINGLE_RANGE_MODEL_SUCCESS
} from '../../../actions/JobRanges';
import {
  PROPOSED_RANGE_SELECT,
  RANGE_SPREAD_CHANGE,
  RANGE_TYPE_CHANGE,
  MIDPOINT_ADJUSTMENT_CHANGE,
  JOB_RANGE_MODEL_MIDPOINT_VALUE_CHANGE,
  JOB_RANGE_MODEL_JOBS_CHANGE,
  RANGES_ROUND_TO_CHANGE,
  MODEL_SAVE_BUILD_MIN_MAX_BY_INIT,
  MODEL_SAVE_BUILD_MIN_MAX_BY_SUCCESS,
  MODEL_CHANGE_MIN_OF_MID_INIT,
  MODEL_CHANGE_MAX_OF_MID_INIT,
  MODEL_SAVE_BUILD_MIN_MAX_BY_FAIL,
  MODEL_PROPOSED_MAX_SELECT_INIT,
  MODEL_PROPOSED_MIN_SELECT_INIT
} from '../../../actions/expandableHeader';
import { ANNUAL_PAY_TYPE, HOURLY_PAY_TYPE } from '../../../Utils/constants';

const MODEL_JOB_ROW_CLICK = 'MODEL_JOB_ROW_CLICK';

import { filterJobList } from '../../../Utils/Utils';
import jobListOptions from './modelJobsListOptions';
import { JOB_RANGES_JOB_DETAILS_UPDATE_JOB } from '../../../actions/jobRangeDetails/JobDetailsSlideOutSettings';
import chunk from 'lodash/chunk';
export const payTypes = Object.freeze({ ANNUAL: ANNUAL_PAY_TYPE, HOURLY: HOURLY_PAY_TYPE });
export const annualRoundOptions = Object.freeze([
  { name: '  -  ', value: null },
  { name: '10', value: 10 },
  { name: '100', value: 100 },
  { name: '1000', value: 1000 }
]);
export const hourlyRoundOptions = Object.freeze([
  { name: '  -  ', value: null },
  { name: '0.01', value: 0.01 },
  { name: '0.1', value: 0.1 },
  { name: '1', value: 1 }
]);

const initialState = {
  nameInputValue: '',
  showEditJobRangeModel: false,
  showAddJobSlideOut: false,
  modelJobs: [],
  filteredModelJobs: [],
  modelJobsLimit: 50,
  modelJobsOffset: 0,
  modelJobsLoading: false,
  moveToCurrentLoading: false,
  jobListOptions,
  selectedModelJob: [],
  setProposedRangeOptions: [],
  roundOptions: annualRoundOptions,
  modelJobsSearchTerm: '',
  totalFilteredJobs: null,
  filterApplied: false,
  jobRangeModel: {},
  error: null
};

let newFilteredJobs;

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case GET_JOB_SINGLE_RANGE_MODEL_SUCCESS:
      return {
        ...state,
        jobRangeModel: action.result.jobRangeModels[0]
      };
    case CLEAR_JOB_RANGE_MODEL_DETAILS:
      return { ...initialState };
    case JOB_RANGES_NAME_INPUT_CHANGE:
      return { ...state, nameInputValue: action.nameInputValue };
    case TOGGLE_EDIT_JOB_RANGES_MODEL:
      return { ...state, showEditJobRangeModel: !state.showEditJobRangeModel };
    case JOB_RANGES_RANGE_TYPE_OPTIONS:
      return { ...state, setProposedRangeOptions: action.payload };
    case CREATE_NEW_JOB_RANGE_MODEL:
      return {
        ...state,
        jobRangeModel: action.newModel,
        nameInputValue: action.newModel.name,
        modelJobs: [],
        selectedModelJob: []
      };
    case CREATE_NEW_JOB_RANGE_MODEL_SUCCESS:
      return {
        ...state,
        jobRangeModel: { ...state.jobRangeModel, id: action.newModel.id, jobRangeModelId: action.newModel.id }
      };
    case GET_SINGLE_JOB_RANGE_MODEL:
      return { ...state, modelJobsLoading: true };
    case GET_SINGLE_JOB_RANGE_MODEL_SUCCESS:
      return {
        ...state,
        modelJobsLoading: false,
        jobRangeModel: action.jobRangeModel,
        nameInputValue: action.jobRangeModel.name,
        modelJobs: action.modelJobs,
        filteredModelJobs: action.modelJobs,
        roundOptions: action.jobRangeModel.payType === payTypes.HOURLY ? hourlyRoundOptions : annualRoundOptions
      };
    case GET_SINGLE_JOB_RANGE_MODEL_FAIL:
      return { ...state, modelJobsLoading: false };
    case ADD_JOBS_TO_JOB_RANGES_MODEL_CLICK:
      return { ...state, showAddJobSlideOut: true };
    case CLOSE_ADD_JOBS_TO_JOB_RANGES_MODEL:
      return { ...state, showAddJobSlideOut: false };
    case MODEL_JOB_ROW_CLICK:
      return { ...state, selectedModelJob: [action.job] };
    case REFRESH_JOB_RANGE_MODEL_JOBS:
      return { ...state, modelJobsLoading: true };
    case REFRESH_JOB_RANGE_MODEL_JOBS_SUCCESS:
      newFilteredJobs = action.modelJobs;
      if (state.modelJobsSearchTerm.length > 0) {
        newFilteredJobs = filterJobList(action.modelJobs, state.modelJobsSearchTerm);
      }
      return {
        ...state,
        modelJobs: action.modelJobs,
        filteredModelJobs: newFilteredJobs,
        modelJobsLoading: false
      };
    case REFRESH_JOB_RANGE_MODEL_JOBS_FAIL:
      return { ...state, modelJobsLoading: false };
    case PROPOSED_RANGE_SELECT:
      newFilteredJobs = action.newModelJobs;
      if (state.modelJobsSearchTerm.length > 0) {
        newFilteredJobs = filterJobList(action.newModelJobs, state.modelJobsSearchTerm);
      }
      return {
        ...state,
        modelJobs: action.newModelJobs,
        filteredModelJobs: newFilteredJobs,
        jobRangeModel: {
          ...state.jobRangeModel,
          proposedRangeSetting: action.optionValue,
          proposedRangeSettingName: action.optionName
        }
      };
    case RANGE_SPREAD_CHANGE:
      return {
        ...state,
        jobRangeModel: { ...state.jobRangeModel, targetRangeSpread: action.value }
      };
    case MODEL_SAVE_BUILD_MIN_MAX_BY_SUCCESS:
      newFilteredJobs = action.newModelJobs;
      if (state.modelJobsSearchTerm.length > 0) {
        newFilteredJobs = filterJobList(action.newModelJobs, state.modelJobsSearchTerm);
      }

      return { ...state, modelJobs: action.newModelJobs, filteredModelJobs: newFilteredJobs };
    case RANGE_TYPE_CHANGE:
      newFilteredJobs = action.newModelJobs;
      if (state.modelJobsSearchTerm.length > 0) {
        newFilteredJobs = filterJobList(action.newModelJobs, state.modelJobsSearchTerm);
      }

      return {
        ...state,
        modelJobs: action.newModelJobs,
        filteredModelJobs: newFilteredJobs,
        jobRangeModel: { ...state.jobRangeModel, rangeType: action.newRangeType }
      };
    case MIDPOINT_ADJUSTMENT_CHANGE:
      newFilteredJobs = action.newModelJobs;
      if (state.modelJobsSearchTerm.length > 0) {
        newFilteredJobs = filterJobList(action.newModelJobs, state.modelJobsSearchTerm);
      }

      return {
        ...state,
        modelJobs: action.newModelJobs,
        filteredModelJobs: newFilteredJobs,
        jobRangeModel: { ...state.jobRangeModel, midpointAdjustment: action.newMidpointAdjustment }
      };
    case JOB_RANGE_MODEL_MIDPOINT_VALUE_CHANGE:
      return {
        ...state,
        jobRangeModel: { ...state.jobRangeModel, midpointAdjustment: action.newMidpointAdjustment }
      };
    case JOB_RANGE_MODEL_JOBS_CHANGE:
      newFilteredJobs = action.newModelJobs;
      if (state.modelJobsSearchTerm.length > 0) {
        newFilteredJobs = filterJobList(action.newModelJobs, state.modelJobsSearchTerm);
      }

      return {
        ...state,
        modelJobs: action.newModelJobs,
        filteredModelJobs: newFilteredJobs
      };
    case RANGES_ROUND_TO_CHANGE:
      let newRoundOptions;
      if (action.payType) {
        newRoundOptions = action.payType === payTypes.HOURLY ? hourlyRoundOptions : annualRoundOptions;
      } else {
        newRoundOptions = state.roundOptions;
      }

      return {
        ...state,
        jobRangeModel: {
          ...state.jobRangeModel,
          payType: action?.payType || state.jobRangeModel.payType,
          roundTo: action.roundTo
        },
        roundOptions: newRoundOptions
      };
    case RANGES_PAY_TYPE_CHANGE:
      if (!state.jobRangeModel) return state;
      return {
        ...state,
        jobRangeModel: { ...state.jobRangeModel, payType: action.payType },
        roundOptions: action.payType === payTypes.HOURLY ? hourlyRoundOptions : annualRoundOptions
      };
    case RANGES_CURRENCY_CHANGE:
      if (!state.jobRangeModel) return state;
      return {
        ...state,
        jobRangeModel: { ...state.jobRangeModel, currencyId: action.currencyId }
      };
    case JOB_RANGES_MOVE_TO_CURRENT:
      return { ...state, moveToCurrentLoading: true };
    case JOB_RANGES_MOVE_TO_CURRENT_FAIL:
      return { ...state, moveToCurrentLoading: false };
    case JOB_RANGES_MOVE_TO_CURRENT_SUCCESS:
      return { ...state, moveToCurrentLoading: false };
    case SHOW_MORE_MODEL_JOBS:
      return { ...state, modelJobsLoading: true };
    case JOB_RANGES_JOB_DETAILS_UPDATE_JOB:
      return {
        ...state,
        modelJobs: state.modelJobs.map(item => {
          if (item.id !== action.newJob.id) {
            return item;
          } else {
            return action.newJob;
          }
        }),
        filteredModelJobs: state.modelJobs.map(item => {
          if (item.id !== action.newJob.id) {
            return item;
          } else {
            return action.newJob;
          }
        })
      };
    case SHOW_MORE_MODEL_JOBS_SUCCESS:
      let filteredJobs = action.newModelJobs;
      if (action.searchTerm.length > 0) {
        const pages = chunk(action.newModelJobs, action.limit);
        filteredJobs = pages[action.offset / action.limit];
      }
      return {
        ...state,
        modelJobsLoading: false,
        modelJobs: action.newModelJobs,
        filteredModelJobs: filteredJobs,
        modelJobsOffset: action.offset,
        modelJobsSearchTerm: action.searchTerm,
        filterApplied: !!action.searchTerm
      };
    case SHOW_MORE_MODEL_JOBS_FAIL:
      return { ...state, modelJobsLoading: false };
    case APPLY_JOBS_TO_RANGE_SUCCESS:
      return { ...state, jobRangeModel: { ...state.jobRangeModel, jobCount: action.newJobCount } };
    case JOB_RANGES_SEARCH_JOBS:
      return {
        ...state,
        filteredModelJobs: action.data.slice(0, state.modelJobsLimit),
        modelJobsOffset: 0,
        totalFilteredJobs: action.data.length,
        filterApplied: true
      };
    case JOB_RANGES_INIT_SEARCH_JOBS:
      return {
        ...state,
        modelJobsSearchTerm: action.searchTerm
      };
    case JOB_RANGES_SEARCH_JOBS_FAIL:
      return {
        ...state
      };
    case MODEL_SAVE_BUILD_MIN_MAX_BY_INIT: {
      return {
        ...state,
        jobRangeModel: { ...state.jobRangeModel, buildMinMaxBy: action.value }
      };
    }
    case MODEL_CHANGE_MIN_OF_MID_INIT: {
      return {
        ...state,
        jobRangeModel: { ...state.jobRangeModel, minPercentageOfMid: action.value }
      };
    }
    case MODEL_CHANGE_MAX_OF_MID_INIT: {
      return {
        ...state,
        jobRangeModel: { ...state.jobRangeModel, maxPercentageOfMid: action.value }
      };
    }
    case MODEL_PROPOSED_MAX_SELECT_INIT: {
      return {
        ...state,
        jobRangeModel: { ...state.jobRangeModel, maxPercentageMarketElement: action.optionValue }
      };
    }
    case MODEL_PROPOSED_MIN_SELECT_INIT: {
      return {
        ...state,
        jobRangeModel: { ...state.jobRangeModel, minPercentageMarketElement: action.optionValue }
      };
    }
    case MODEL_SAVE_BUILD_MIN_MAX_BY_FAIL: {
      return {
        ...state,
        error: action.error
      };
    }

    default:
      return state;
  }
}

export const modelJobRowClick = job => {
  return {
    type: MODEL_JOB_ROW_CLICK,
    job
  };
};
