import React, { Component } from 'react';
import { arrayOf, func, number, oneOfType, shape, string, object } from 'prop-types';
import RangeSlider from '../RangeSlider/RangeSlider';

class Slider extends Component {
  static propTypes = {
    facet: shape({
      facetSort: number,
      id: string.isRequired,
      items: arrayOf(
        shape({
          key: oneOfType([number, string]),
          doc_count: number
        })
      ),
      max: number.isRequired,
      min: number.isRequired,
      step: number.isRequired,
      title: string.isRequired,
      type: string.isRequired,
      value: arrayOf(number)
    }).isRequired,
    labels: shape({
      min: string.isRequired,
      max: string.isRequired
    }).isRequired,
    rangeChange: func.isRequired,
    rangeTrackStyle: arrayOf(
      shape({
        backgroundColor: string,
        height: string
      })
    ),
    rangeHandleStyle: arrayOf(
      shape({
        border: string,
        height: string,
        width: string
      })
    )
  };

  constructor(props) {
    super(props);

    this.renderLabels = this.renderLabels.bind(this);
    this.slideStop = this.slideStop.bind(this);
  }

  renderLabels() {
    const { labels } = this.props;

    return (
      <span>
        <span className="slider__limitLower">{labels.min}</span>
        <span>&ndash;</span>
        <span className="slider__limitUpper">{labels.max}</span>
      </span>
    );
  }

  slideStop(newValue) {
    const { rangeChange, facet } = this.props;
    const [min, max] = facet.value;
    const [newMin, newMax] = newValue;

    if (min !== newMin || max !== newMax) rangeChange(facet, newValue);
  }

  render() {
    const { facet, rangeTrackStyle, rangeHandleStyle } = this.props;

    return (
      <div className="slider">
        {this.renderLabels()}
        <RangeSlider
          defaultValue={[facet.min, facet.max]}
          max={facet.max}
          min={facet.min}
          onChange={this.slideStop}
          step={facet.step}
          value={facet.value}
          rangeTrackStyle={rangeTrackStyle}
          rangeHandleStyle={rangeHandleStyle}
        />
      </div>
    );
  }
}

export default Slider;
