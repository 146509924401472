import React, { Component } from 'react';
import { bool, func, number, string } from 'prop-types';
import classnames from 'classnames';
import Dropdown from 'ps-components/src/Dropdown/Dropdown';
import ProposedRangeSetting from '../MidPointSetting/ProposedRangeSetting';
import { MARKET_RANGE_TYPE, MIDPOINT_RANGE_TYPE } from '../../Utils/constants';
import './PercentageOfMid.scss';

class PercentageOfMid extends Component {
  static propTypes = {
    handleMaxPercentChange: func,
    handleMinPercentChange: func,
    isGlobal: bool,
    maxPercentageMarketElement: string,
    maxPercentageOfMid: number,
    minPercentageMarketElement: string,
    minPercentageOfMid: number,
    proposedMaxSelect: func,
    proposedMinSelect: func
  };

  constructor(props) {
    super(props);
    this.state = {
      maxPercentage: 115,
      minPercentage: 75
    };
  }

  componentDidMount() {
    const { maxPercentageOfMid, minPercentageOfMid } = this.props;

    this.setState({
      maxPercentage: Math.round(maxPercentageOfMid * 100),
      minPercentage: Math.round(minPercentageOfMid * 100)
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { maxPercentageOfMid, minPercentageOfMid, isSlideoutOpen } = this.props;
    if (
      isSlideoutOpen !== prevProps.isSlideoutOpen ||
      maxPercentageOfMid !== prevProps.maxPercentageOfMid ||
      minPercentageOfMid !== prevProps.minPercentageOfMid
    ) {
      this.setState({
        maxPercentage: Math.round(maxPercentageOfMid * 100),
        minPercentage: Math.round(minPercentageOfMid * 100)
      });
    }
  }

  handleMaxElementChange = e => {
    const { proposedMaxSelect } = this.props;
    proposedMaxSelect(e);
  };

  handleMaxValueAfterChange = () => {
    const { handleMaxPercentChange } = this.props;
    const { maxPercentage } = this.state;

    handleMaxPercentChange(maxPercentage || 0);
  };

  handleMaxValueChange = event => {
    if (event.key === 'Enter') {
      return this.handleMaxValueAfterChange();
    }

    let newValue = event?.target?.value || event;
    newValue = '' ? null : parseInt(newValue);
    this.setState({ maxPercentage: newValue });
  };

  handleMinElementChange = e => {
    const { proposedMinSelect } = this.props;
    proposedMinSelect(e);
  };

  handleMinValueAfterChange = () => {
    const { handleMinPercentChange } = this.props;
    const { minPercentage } = this.state;

    handleMinPercentChange(minPercentage || 0);
  };

  handleMinValueChange = event => {
    if (event.key === 'Enter') {
      return this.handleMinValueAfterChange();
    }
    let newValue = event?.target?.value || event;
    newValue = '' ? null : parseInt(newValue);
    this.setState({ minPercentage: newValue });
  };

  render() {
    const {
      isGlobal,
      maxPercentageMarketElement,
      minPercentageMarketElement,
      proposedMaxSelect,
      proposedMinSelect,
      rangeType,
      setProposedRangeOptions
    } = this.props;
    const { minPercentage, maxPercentage } = this.state;

    return (
      <div
        className={classnames('percentage__container', {
          'global__settings-layout': isGlobal,
          'modified__midpoint-layout': !isGlobal,
          'market__percentage-container': rangeType === MARKET_RANGE_TYPE,
          'slideout__market-percentage': rangeType === MARKET_RANGE_TYPE && !isGlobal
        })}
      >
        <div className="percentage__adjustment">
          <h4 className="adjustment-row">{isGlobal ? 'Min' : 'Minimum'}</h4>
          <div className="adjustment-row">
            <div className="target__range__spread-input-container">
              <input
                className="target__range__spread-input"
                data-test-id="max__percentage-input"
                max={100}
                min={0}
                onBlur={this.handleMinValueAfterChange}
                onChange={this.handleMinValueChange}
                onKeyDown={this.handleMinValueChange}
                type="number"
                value={minPercentage}
              />
              <i className="target__range__spread-input-percent">%</i>
            </div>
            <div className="adjustment-label">{`of ${rangeType !== MARKET_RANGE_TYPE ? MIDPOINT_RANGE_TYPE : ''}`}</div>
            {rangeType === MARKET_RANGE_TYPE && (
              <ProposedRangeSetting
                className="statGroup-content midpoint-setting-content"
                handleProposedRangeSelect={this.handleMinElementChange}
                isBuildMinMaxBy={true}
                proposedRangeSetting={minPercentageMarketElement}
                rangeOptions={setProposedRangeOptions}
              />
            )}
          </div>
        </div>
        <div className="percentage__adjustment">
          <h4 className="adjustment-row">{isGlobal ? 'Max' : 'Maximum'}</h4>
          <div className="adjustment-row">
            <div className="target__range__spread-input-container">
              <input
                className="target__range__spread-input"
                data-test-id="max__percentage-input"
                max={100}
                min={0}
                onBlur={this.handleMaxValueAfterChange}
                onChange={this.handleMaxValueChange}
                onKeyDown={this.handleMaxValueChange}
                type="number"
                value={maxPercentage}
              />
              <i className="target__range__spread-input-percent">%</i>
            </div>
            <div className="adjustment-label">{`of ${rangeType !== MARKET_RANGE_TYPE ? MIDPOINT_RANGE_TYPE : ''}`}</div>
            {rangeType === MARKET_RANGE_TYPE && (
              <ProposedRangeSetting
                className="statGroup-content midpoint-setting-content"
                handleProposedRangeSelect={this.handleMaxElementChange}
                isBuildMinMaxBy={true}
                proposedRangeSetting={maxPercentageMarketElement}
                rangeOptions={setProposedRangeOptions}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default PercentageOfMid;
