import React, { Component } from 'react';
import { arrayOf, func } from 'prop-types';
import ZeroWeightedSurveyJobCard from './Card/ZeroWeightedSurveyJobCard';
import { ParticipationMatch } from '../../../types/MatchReviewTypes';
import './ZeroWeightsPanel.scss';

const propTypes = {
  zeroWeightedMatches: arrayOf(ParticipationMatch).isRequired,
  updateZeroWeightedMatches: func.isRequired,
  updateMatch: func.isRequired
};

class ZeroWeightsPanel extends Component {
  constructor(props) {
    super(props);
    this.includeAll = this.includeAll.bind(this);
    this.excludeAll = this.excludeAll.bind(this);
  }

  includeAll() {
    this.props.updateZeroWeightedMatches(false);
  }

  excludeAll() {
    this.props.updateZeroWeightedMatches(true);
  }

  render() {
    const { updateMatch, zeroWeightedMatches } = this.props;

    return (
      <div className="pcenter-match-review-zero-weights">
        <section className="pcenter-match-review-zero-weights__header">
          <div className="pcenter-match-review-zero-weights-header__top">
            <section className="pcenter-match-review-zero-weights-header__left-content">
              <h3 className="pcenter-match-review-zero-weights-header-left-content__top-instructions">
                Review zero weighted survey job matches
              </h3>
              <label className="pcenter-match-review-zero-weights-header-left-content__bottom-instructions">
                Do you want to include them in the report
              </label>
            </section>
            <section className="pcenter-match-review-zero-weights-header__right-content">
              <div className="pcenter-match-review-zero-weights-header-right-content__section">
                <label className="pcenter-match-review-zero-weights-header-right-content__label">Excluded</label>
                <h3 className="pcenter-match-review-zero-weights-header-right-content__excluded-value">
                  {zeroWeightedMatches.reduce((accumator, match) => {
                    return accumator + (match.exclude ? 1 : 0);
                  }, 0)}
                </h3>
              </div>
              <div className="pcenter-match-review-zero-weights-header-right-content__section">
                <label className="pcenter-match-review-zero-weights-header-right-content__label">Included</label>
                <h3 className="pcenter-match-review-zero-weights-header-right-content__included-value">
                  {zeroWeightedMatches.reduce((accumator, match) => {
                    return accumator + (match.exclude ? 0 : 1);
                  }, 0)}
                </h3>
              </div>
            </section>
          </div>
          <div className="pcenter-match-review-zero-weights-header__bottom">
            <button
              className="pxl-btn pxl-btn-primary pcenter-match-review-zero-weights-header__exclude-btn"
              onClick={this.excludeAll}
            >
              Exclude All
            </button>
            <button className="pxl-btn pxl-btn-primary" onClick={this.includeAll}>
              Include All
            </button>
          </div>
        </section>
        <section className="pcenter-match-review-zero-weights__list">
          {zeroWeightedMatches.map(m => {
            return (
              <ZeroWeightedSurveyJobCard
                key={`${m.orgJobCode} ${m.orgJobCodeKey} ${m.surveyJobCode}`}
                match={m}
                updateMatch={updateMatch}
              />
            );
          })}
        </section>
      </div>
    );
  }
}

ZeroWeightsPanel.propTypes = propTypes;
export default ZeroWeightsPanel;
