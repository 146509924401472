import { useEffect } from 'react';

export const useMeasureDashboardFirstUnmountDelay = () => {
  // This effect sends the `dashboardFirstUnmountDelayMs` user action to Datadog RUM. This measures
  // the delay between RUM being initialized and the first moment the user navigates away from the
  // dashboard (<Home>). To determine the latter we use time of unmount and stick a flag on the
  // DD_RUM object to make sure the action is only sent on the first unmount, since there may be
  // multiple mount/unmount cycles per page load

  useEffect(() => {
    const datadogRum = window.DD_RUM;
    if (!datadogRum || datadogRum._dashboardFirstUnmount) return;

    return () => {
      const delay = getElapsedMsSinceRumInitialized();
      if (!delay) return;
      datadogRum.addUserAction('dashboardFirstUnmountDelayMs', delay);
      datadogRum._dashboardFirstUnmount = true;
    };
  }, []);
};

export function useMeasureMountTime(actionName, timeoutMs) {
  // This effect sends a user action to Datadog RUM measuring the amount of time a component
  // was visible (mounted). It use a timeout in the case of a hanging render (e.g. in the case of
  // a hanging loading spinner)

  useEffect(() => {
    const datadogRum = window.DD_RUM;
    if (!datadogRum) return;

    const mountedAt = new Date();

    let alreadySent = false;

    const sendAction = () => {
      const elapsedMs = new Date() - mountedAt;
      datadogRum.addUserAction(actionName, elapsedMs);
      alreadySent = true;
    };

    const timeoutId = setTimeout(() => {
      sendAction();
    }, timeoutMs);

    return () => {
      clearTimeout(timeoutId);
      if (alreadySent) {
        return;
      }
      sendAction();
    };
  }, []);
}

export const getElapsedMsSinceRumInitialized = () => {
  const datadogRum = window.DD_RUM;
  const ddRumInitializedAtTs = window.sessionStorage.getItem('ddRumInitializedAt');
  if (!datadogRum || !ddRumInitializedAtTs) return null;
  const ddRumInitializedAt = new Date(parseInt(ddRumInitializedAtTs));
  const now = new Date();
  return now - ddRumInitializedAt;
};
