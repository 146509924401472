import React, { Component } from 'react';
import { arrayOf, bool, func, number, oneOfType, shape, string } from 'prop-types';
import './Facet.scss';

class Facet extends Component {
  static propTypes = {
    facet: shape({
      facetSort: number,
      id: string,
      items: arrayOf(
        shape({
          key: oneOfType([number, string]),
          doc_count: number
        })
      ),
      title: string,
      type: string
    }).isRequired,
    facetItem: shape({
      key: oneOfType([number, string]).isRequired,
      doc_count: number.isRequired,
      partialDocCount: number
    }).isRequired,
    facetItemClick: func.isRequired,
    isChecked: bool.isRequired,
    isDisabled: bool
  };

  static defaultProps = {
    isDisabled: false
  };

  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
    this.handleOnlyClick = this.handleOnlyClick.bind(this);
    this.upperCaseFacet = this.upperCaseFacet.bind(this);
    this.renderOnlyLabel = this.renderOnlyLabel.bind(this);
  }

  handleClick() {
    const { facet, facetItem, facetItemClick } = this.props;
    facetItemClick(facet, facetItem);
  }

  handleOnlyClick() {
    const { facet, facetItem, facetItemClick } = this.props;
    facetItemClick(facet, facetItem, 'only');
  }

  upperCaseFacet(facet) {
    if (!facet || typeof facet.charAt === 'undefined') {
      return facet;
    }
    return facet.charAt(0).toUpperCase() + facet.slice(1);
  }

  renderOnlyLabel() {
    return (
      <span className="facet__only-link" onClick={this.handleOnlyClick}>
        only
      </span>
    );
  }

  render() {
    const { isChecked, facetItem, isDisabled } = this.props;
    const labelClass = isDisabled ? 'facet__label facet__disabled' : 'facet__label';
    let facetKey = facetItem.key?.toString();
    const facetTitlesWithActiveBool = ['Survey status in your library'];
    const isActiveSurvey = facetItem.key_as_string === 'Yes' ? 'Active' : 'Not Active';
    const facetText = facetTitlesWithActiveBool.includes(this.props.facet.title)
      ? isActiveSurvey
      : this.props.facet.id === 'archive_p' || this.props.facet.id === 'active_p'
      ? this.upperCaseFacet(facetItem.key_as_string)
      : facetKey === ' '
      ? 'Not Set'
      : this.upperCaseFacet(facetKey);

    return (
      <div className="pxl-text-small facet__value checkbox">
        <div className="facet__label-checkbox-container">
          <input
            checked={isChecked}
            data-test-id={`facet-checkbox-${facetKey}`}
            disabled={isDisabled}
            id={facetKey}
            onChange={isDisabled ? null : this.handleClick}
            type="checkbox"
          />
          <label title={facetKey} className={labelClass} htmlFor={facetKey}>
            <div className={'label__text'}>
              {facetText}
              <span className="facet-checkbox__count">({facetItem.doc_count})</span>
            </div>
          </label>
        </div>
        <div className="facet__label-count-container">{!isDisabled && this.renderOnlyLabel()}</div>
      </div>
    );
  }
}

export default Facet;
