require('core-js');
require('regenerator-runtime/runtime');

const environment = {
  development: {
    isProduction: false
  },
  production: {
    isProduction: true
  }
}[process.env.NODE_ENV || 'development'];

module.exports = Object.assign(
  {
    host: process.env.HOST || 'localhost',
    port: process.env.PORT,
    apiHost: process.env.APIHOST || 'localhost',
    apiPort: process.env.APIPORT,
    app: {
      title: 'Insight Lab',
      description: '',
      head: {
        titleTemplate: '%s',
        meta: [
          { charset: 'utf-8' },
          { property: 'og:site_name', content: 'Insight Lab' },
          {
            property: 'og:image',
            content: 'https://cdn-payscale.com/images/favicon/mstile-144x144.png'
          },
          { property: 'og:locale', content: 'en_US' },
          { property: 'og:title', content: 'Insight Lab' },
          { property: 'og:image:width', content: '144' },
          { property: 'og:image:height', content: '144' }
        ]
      }
    }
  },
  environment
);
