const CURRENT_STRUCTURE = 'CURRENT_STRUCTURE';
const CURRENT_STRUCTURE_SUCCESS = 'CURRENT_STRUCTURE_SUCCESS';
const CURRENT_STRUCTURE_FAIL = 'CURRENT_STRUCTURE_FAIL';

const NEW_CURRENT_STRUCTURE = 'NEW_CURRENT_STRUCTURE';
const NEW_CURRENT_STRUCTURE_SUCCESS = 'NEW_CURRENT_STRUCTURE_SUCCESS';
const NEW_CURRENT_STRUCTURE_FAIL = 'NEW_CURRENT_STRUCTURE_FAIL';

const CURRENT_STRUCTURE_GRID_DRAW = 'CURRENT_STRUCTURE_GRID_DRAW';

const MIGRATE_DATA = 'MIGRATE_DATA';
const MIGRATE_DATA_SUCCESS = 'MIGRATE_DATA_SUCCESS';
const MIGRATE_DATA_FAIL = 'MIGRATE_DATA_FAIL';

const SAVE_COPIED_STRUCTURE = 'SAVE_COPIED_STRUCTURE';
export const SAVE_COPIED_STRUCTURE_SUCCESS = 'SAVE_COPIED_STRUCTURE_SUCCESS';
const SAVE_COPIED_STRUCTURE_FAIL = 'SAVE_COPIED_STRUCTURE_FAIL';

const DELETE_SCENARIO = 'DELETE_SCENARIO';
const DELETE_SCENARIO_SUCCESS = 'DELETE_SCENARIO_SUCCESS';
const DELETE_SCENARIO_FAIL = 'DELETE_SCENARIO_FAIL';

export const SAVE_STRUCTURE = 'SAVE_STRUCTURE';
export const SAVE_STRUCTURE_SUCCESS = 'SAVE_STRUCTURE_SUCCESS';
const SAVE_STRUCTURE_FAIL = 'SAVE_STRUCTURE_FAIL';

const DOWNLOAD_STRUCTURE = 'DOWNLOAD_STRUCTURE';
const DOWNLOAD_STRUCTURE_SUCCESS = 'DOWNLOAD_STRUCTURE_SUCCESS';
const DOWNLOAD_STRUCTURE_FAIL = 'DOWNLOAD_STRUCTURE_FAIL';

const OPEN_NEW_STRUCTURE_MODAL = 'OPEN_NEW_STRUCTURE_MODAL';
const CLOSE_NEW_STRUCTURE_MODAL = 'CLOSE_NEW_STRUCTURE_MODAL';
const CLOSE_STRUCTURE_EDIT = 'CLOSE_STRUCTURE_EDIT';
const OPEN_STRUCTURE_EDIT = 'OPEN_STRUCTURE_EDIT';
const OPEN_DELETE_SCENARIO_MODAL = 'OPEN_DELETE_SCENARIO_MODAL';
const CLOSE_DELETE_SCENARIO_MODAL = 'CLOSE_DELETE_SCENARIO_MODAL';

const RESET_STRUCTURE_LIST = 'RESET_STRUCTURE_LIST';

const CALCULATE_STRUCTURE_SUCCESS = 'CALCULATE_STRUCTURE_SUCCESS';

const STRUCTURE_DISPLAY_CALC = 'STRUCTURE_DISPLAY_CALC';
const MODEL_CHART_SUCCESS = 'MODEL_CHART_SUCCESS';

const RANGE_SPREAD = 'RANGE_SPREAD';

const UPDATE_SELECTED_STRUCTURE = 'UPDATE_SELECTED_STRUCTURE';
const UPDATE_EDIT_FORM_DATA = 'UPDATE_EDIT_FORM_DATA';

const REMOVE_MODEL_CHART_JSON = 'REMOVE_MODEL_CHART_JSON';

const EXPEND_SPREAD_TOGGLE = 'EXPEND_SPREAD_TOGGLE';

const STRUCTURE_EDIT_NAME_CHANGE = 'STRUCTURE_EDIT_NAME_CHANGE';

import { getModelAndStructure } from './modelStructureList';
import { getStructureChart } from './structureChart';
import { structureFilterActions } from './structureFilters';
import { createStructureValidations } from './createStructure';
import { addNestedChildrenReducers } from '../../../helpers/utils';
import { validateInputName } from './structureCopyModal';
import structureCopyModal from './structureCopyModal';

const initialState = {
  loading: false,
  loaded: false,
  error: null,
  selectedStructureCode: '',
  structureCodeBasis: '',
  modelName: '',
  modelMode: '',
  modelId: null,
  editForm: false,
  deleteModal: false,
  deleteLoading: false,
  lowMidpoint: 0,
  structureHighchartsJson: {},
  modelChartJson: {},
  structureDownloadLoading: false,
  numRanges: 20,
  highMidpoint: '',
  midpoint: 0,
  avgProgression: 0,
  progression: '',
  items: [],
  savedStructureItems: [],
  lastModified: '',
  rangeSpread: '',
  highSpread: 21,
  lowSpread: 1,
  structureEqualsModel: false,
  expandSpreadSwitched: false,
  disableCopySaveBtn: false,
  disableEditSaveBtn: false,
  editFormData: {
    gradesCount: 20,
    highMidpoint: 0,
    lowMidpoint: 0,
    name: '',
    rangeSpread: ''
  }
};

export default addNestedChildrenReducers(reducer, { structureCopyModal });

function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case DOWNLOAD_STRUCTURE:
      return {
        ...state,
        loading: true,
        structureDownloadLoading: true
      };
    case DOWNLOAD_STRUCTURE_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: null,
        structureDownloadLoading: false
      };
    case DOWNLOAD_STRUCTURE_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        data: null,
        structureDownloadLoading: false,
        error: action.error
      };
    case CALCULATE_STRUCTURE_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: null,
        items: action.calculatedStructure
      };
    case CURRENT_STRUCTURE:
      return {
        ...state,
        loading: true
      };
    case CURRENT_STRUCTURE_SUCCESS:
      let completeStructure = null; // eslint-disable-line prefer-const
      let selectedStructureCode = state.selectedStructureCode; // eslint-disable-line prefer-const
      if (action.selectedStructureCode) {
        selectedStructureCode = action.selectedStructureCode;
      }

      if (action.modelId) {
        // Need to calculate if the current structure missing values is a modelId is passed in
        completeStructure = structureCalcs(action.result, { grades: action.result.length });
      } else {
        for (let i = 0; i < action.result.length; i++) {
          if (!action.result[i].salary_display_code) {
            action.result[i].salary_display_code = 'annual';
          }
        }
      }

      return {
        ...state,
        loading: false,
        loaded: true,
        error: null,
        editForm: false,
        modelChartJson: {},
        selectedStructureCode: selectedStructureCode,
        structureCodeBasis: action.structureCodeBasis,
        modelId: action.modelId,
        modelName: action.modelName,
        modelMode: action.modelMode,
        lastModified: action.lastModified,
        savedStructureItems: completeStructure ? completeStructure : action.result,
        items: completeStructure ? completeStructure : action.result,
        structureEqualsModel: false
      };
    case CURRENT_STRUCTURE_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        data: null,
        error: action.error
      };
    case CURRENT_STRUCTURE_GRID_DRAW:
      // Need to calculate if the current structure missing values
      const newGridItems = structureCalcs(action.result, { grades: action.result.length });

      return {
        ...state,
        loading: false,
        loaded: true,
        error: null,
        items: newGridItems,
        structureEqualsModel: false
      };
    case NEW_CURRENT_STRUCTURE:
      return {
        ...state,
        loading: true
      };
    case NEW_CURRENT_STRUCTURE_SUCCESS:
      const newSelectedStructureCode = action.selectedStructureCode;

      // Need to calculate if the current structure missing values
      const newCompleteStructure = structureCalcs(action.result, action.options);
      return {
        ...state,
        loading: false,
        loaded: true,
        error: null,
        editForm: false,
        modelChartJson: {},
        selectedStructureCode: newSelectedStructureCode,
        structureCodeBasis: '',
        modelId: action.modelId,
        modelName: action.modelName,
        modelMode: action.modelMode,
        lastModified: action.lastModified,
        savedStructureItems: newCompleteStructure,
        items: newCompleteStructure,
        structureEqualsModel: false
      };
    case NEW_CURRENT_STRUCTURE_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        data: null,
        error: action.error
      };
    case MODEL_CHART_SUCCESS:
      return {
        ...state,
        modelChartJson: action.modelChartJson
      };
    case STRUCTURE_DISPLAY_CALC:
      return {
        ...state,
        lowMidpoint: action.displayValues.lowMidpoint,
        highMidpoint: action.displayValues.highMidpoint,
        midpoint: action.displayValues.midpoint,
        avgProgression: action.displayValues.avgProgression
      };
    case MIGRATE_DATA:
      return {
        ...state,
        migrateLoading: true,
        migrateLoaded: false,
        migrateError: null
      };
    case MIGRATE_DATA_SUCCESS:
      setStructureToModelCalcs(state.items, state.items);
      return {
        ...state,
        migrateLoading: false,
        migrateLoaded: true,
        migrateError: null,
        structureEqualsModel: true
      };
    case MIGRATE_DATA_FAIL:
      return {
        ...state,
        migrateLoading: false,
        migrateLoaded: false,
        data: null,
        migrateError: action.error
      };
    case CLOSE_STRUCTURE_EDIT:
      return {
        ...state,
        items: state.savedStructureItems,
        editForm: false
      };
    case OPEN_STRUCTURE_EDIT:
      const formData = state.expandSpreadSwitched
        ? {
            name: state.modelName || state.editFormData.name,
            highMidpoint: state.highMidpoint || state.editFormData.highMidpoint,
            lowMidpoint: state.lowMidpoint || state.editFormData.lowMidpoint,
            rangeSpread: state.rangeSpread || state.editFormData.rangeSpread,
            gradesCount: state.items.length,
            highSpread: state.highSpread || state.editFormData.highSpread,
            lowSpread: state.lowSpread || state.editForm.lowSpread
          }
        : {
            name: state.modelName || state.editFormData.name,
            highMidpoint: state.highMidpoint || state.editFormData.highMidpoint,
            lowMidpoint: state.lowMidpoint || state.editFormData.lowMidpoint,
            rangeSpread: state.rangeSpread || state.editFormData.rangeSpread,
            gradesCount: state.items.length
          };
      return {
        ...state,
        editForm: true,
        editFormData: formData
      };
    case EXPEND_SPREAD_TOGGLE:
      const updateFormData = action.switchState
        ? {
            name: state.modelName || state.editFormData.name,
            highMidpoint: state.highMidpoint || state.editFormData.highMidpoint,
            lowMidpoint: state.lowMidpoint || state.editFormData.lowMidpoint,
            rangeSpread: state.rangeSpread || state.editFormData.rangeSpread,
            gradesCount: state.items.length,
            highSpread: state.highSpread,
            lowSpread: state.lowSpread
          }
        : {
            name: state.modelName || state.editFormData.name,
            highMidpoint: state.highMidpoint || state.editFormData.highMidpoint,
            lowMidpoint: state.lowMidpoint || state.editFormData.lowMidpoint,
            rangeSpread: state.rangeSpread || state.editFormData.rangeSpread,
            gradesCount: state.items.length
          };
      return {
        ...state,
        expandSpreadSwitched: action.switchState,
        editFormData: updateFormData
      };
    case OPEN_DELETE_SCENARIO_MODAL:
      return {
        ...state,
        deleteModal: true
      };
    case CLOSE_DELETE_SCENARIO_MODAL:
      return {
        ...state,
        deleteModal: false
      };
    case RANGE_SPREAD:
      return {
        ...state,
        rangeSpread: action.rangeSpread,
        highSpread: action.highSpread,
        lowSpread: action.lowSpread,
        expandSpreadSwitched: action.expandSpreadSwitched
      };
    case UPDATE_EDIT_FORM_DATA:
      return {
        ...state,
        editFormData: { ...state.editFormData, ...action.editFormData },
        highSpread: action.highSpread,
        lowSpread: action.lowSpread,
        rangeSpread: action.rangeSpread
      };
    case STRUCTURE_EDIT_NAME_CHANGE:
      return {
        ...state,
        disableEditSaveBtn: action.disableEditSaveBtn
      };
    case SAVE_COPIED_STRUCTURE:
      return {
        ...state,
        loading: true,
        disableCopySaveBtn: true
      };
    case SAVE_COPIED_STRUCTURE_SUCCESS:
      if (action.result.success) {
        return {
          ...state,
          loading: false,
          loaded: true,
          modelName: action.modelName,
          selectedStructureCode: action.modelName,
          structureCodeBasis: action.structureCodeBasis,
          modelId: action.result.model_id,
          structureHighchartsJson: action.structureHighchartsJson,
          items: action.calculatedStructure,
          lastModified: action.result.last_modified,
          error: null,
          structureEqualsModel: false,
          disableCopySaveBtn: false
        };
      }
      return {
        ...state,
        disableCopySaveBtn: false
      };
    case SAVE_COPIED_STRUCTURE_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        data: null,
        disableCopySaveBtn: false,
        error: action.error
      };
    case UPDATE_SELECTED_STRUCTURE:
      return {
        ...state,
        selectedStructureCode: action.selectedStructureCode,
        modelName: action.modelName,
        structureEqualsModel: false,
        error: null
      };
    case SAVE_STRUCTURE:
      return {
        ...state,
        loading: true,
        disableEditSaveBtn: true
      };
    case SAVE_STRUCTURE_SUCCESS:
      if (action.result.success) {
        return {
          ...state,
          savedStructureItems: action.calculatedStructure,
          selectedStructureCode: action.selectedStructureCode,
          modelName: action.modelName,
          items: action.calculatedStructure,
          structureEqualsModel: false,
          lastModified: action.result.last_modified,
          disableEditSaveBtn: false,
          error: null
        };
      }
      return { ...state, disableEditSaveBtn: false };
    case SAVE_STRUCTURE_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        data: null,
        disableEditSaveBtn: false,
        error: action.error
      };
    case DELETE_SCENARIO:
      return {
        ...state,
        loading: true,
        deleteModal: false,
        deleteLoading: true
      };
    case DELETE_SCENARIO_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: null,
        deleteLoading: false
      };
    case DELETE_SCENARIO_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        data: null,
        error: action.error
      };
    case RESET_STRUCTURE_LIST:
      return {
        ...state,
        error: null,
        selectedStructureCode: 'deleted'
      };
    case REMOVE_MODEL_CHART_JSON:
      return {
        ...state,
        modelChartJson: {}
      };
    default:
      return state;
  }
}

export function migrateData(options) {
  return (dispatch, getState) => {
    const ajax = dispatch({
      types: [MIGRATE_DATA, MIGRATE_DATA_SUCCESS, MIGRATE_DATA_FAIL],
      promise: client => client.post('api/structures/structure-data/migrateModelData', { params: options })
    });
    ajax
      .then(function getMigratedChart(result) {
        if (result && result.success) {
          const rateChartOptions = {
            structure_code: getState().currentStructure.selectedStructureCode,
            structure_code_basis: getState().currentStructure.structureCodeBasis,
            model_id: getState().currentStructure.modelId,
            model_name: getState().currentStructure.modelName,
            mode: getState().currentStructure.modelMode
          };
          dispatch(getStructureChart(rateChartOptions));
        }
      })
      .catch(err => {
        console.error('Error migrating model data', err);
      });
  };
}

export function checkRangeSpread(val) {
  for (let i = 1; i < val.length; i++) {
    if (Math.round(val[i].spread) !== Math.round(val[0].spread)) {
      return false;
    }
  }
  return true;
}

export function findSpreadLimit(val) {
  let highSpread = val[0].spread; // eslint-disable-line prefer-const
  for (let x = 0; x < val.length; x++) {
    if (val[x].spread > highSpread) {
      highSpread = val[x].spread;
    }
  }

  let lowSpread = val[0].spread; // eslint-disable-line prefer-const
  for (let y = 0; y < val.length; y++) {
    if (val[y].spread < lowSpread) {
      lowSpread = val[y].spread;
    }
  }
  return {
    highSpread: parseFloat(highSpread).toFixed(1),
    lowSpread: parseFloat(lowSpread).toFixed(1)
  };
}

export function updateEditForm(options) {
  return (dispatch, getState) => {
    if ((options.name || options.name === '') && options.name != getState().currentStructure.modelName) {
      const modelStructureList = getState().modelStructureList.salaryStructureItems.modelAndStructureList;

      const nameValidation = validateInputName(options.name, modelStructureList);
      dispatch({
        type: STRUCTURE_EDIT_NAME_CHANGE,
        disableEditSaveBtn: nameValidation.inputValid === 'error' ? true : false
      });
    } else {
      dispatch({ type: STRUCTURE_EDIT_NAME_CHANGE, disableEditSaveBtn: false });
    }
    dispatch({
      type: 'UPDATE_EDIT_FORM_DATA',
      editFormData: options,
      highSpread: options.highSpread || getState().currentStructure.highSpread,
      lowSpread: options.lowSpread || getState().currentStructure.lowSpread,
      rangeSpread: options.rangeSpread || getState().currentStructure.rangeSpread
    });
  };
}

export function getCurrentStructure(options) {
  return (dispatch, getState) => {
    const ajax = dispatch({
      types: [CURRENT_STRUCTURE, CURRENT_STRUCTURE_SUCCESS, CURRENT_STRUCTURE_FAIL],
      promise: client =>
        client.get('api/structures/grades/getCurrentStructure', {
          params: { structure_code: options.structure_code, model_id: options.model_id }
        }),
      selectedStructureCode:
        (options.mode && options.mode === 'new') || !options.model_name
          ? options.structure_code
          : 'model ' + options.model_name,
      modelId: options.model_id,
      lastModified: options.last_modified,
      structureCodeBasis: options.structure_code_basis ? options.structure_code_basis : options.structure,
      modelName: options.model_name,
      modelMode: options.mode
    });

    // see if the range spread is consistent across grades for easier summarization/changing
    ajax
      .then(function setDefaultRangeSpread(result) {
        let rangeSpreadValue;
        let highSpreadValue;
        let lowSpreadValue;
        let spreadSwitchValue;
        const setRangeSpread = checkRangeSpread(getState().currentStructure.items);
        if (setRangeSpread) {
          rangeSpreadValue = 50;
          highSpreadValue = 50;
          lowSpreadValue = 50;
          spreadSwitchValue = false;
        } else {
          const setSpreadLimit = findSpreadLimit(getState().currentStructure.items);
          rangeSpreadValue = '';
          highSpreadValue = setSpreadLimit.highSpread;
          lowSpreadValue = setSpreadLimit.lowSpread;
          spreadSwitchValue = true;
        }
        dispatch({
          type: 'RANGE_SPREAD',
          rangeSpread: rangeSpreadValue,
          expandSpreadSwitched: spreadSwitchValue,
          highSpread: highSpreadValue,
          lowSpread: lowSpreadValue
        });
      })
      .catch(err => {
        console.error('Error getting current structure', err);
      });

    // dispatch the call to get the chart once the model information is retrieved
    ajax
      .then(function getDisplayCalcs(result) {
        dispatch({
          type: STRUCTURE_DISPLAY_CALC,
          displayValues: calcDisplayValues(getState().currentStructure.items)
        });
        const rateChartOptions = {
          structure_code: getState().currentStructure.selectedStructureCode,
          structure_code_basis: getState().currentStructure.structureCodeBasis,
          model_id: getState().currentStructure.modelId,
          model_name: getState().currentStructure.modelName,
          mode: getState().currentStructure.modelMode,
          salary_display_code: result[0].salary_display_code
        };
        dispatch(getStructureChart(rateChartOptions));
      })
      .catch(err => {
        console.error('Error getting current structure', err);
      });

    // dispatch the call to get the facets once the model information is retrieved
    ajax
      .then(function getDisplayCalcs(result) {
        const searchParams = {
          structure_code: getState().currentStructure.selectedStructureCode,
          structure_code_basis: getState().currentStructure.structureCodeBasis,
          modelId: getState().currentStructure.modelId,
          model_name: getState().currentStructure.modelName,
          mode: getState().currentStructure.modelMode
        };
        dispatch(structureFilterActions.search(searchParams));
      })
      .catch(err => {
        console.error('Error getting current structure', err);
      });
  };
}

export function calcDisplayValues(structure) {
  return {
    highMidpoint: findHighMid(structure),
    lowMidpoint: findLowMid(structure),
    midpoint: findMidpoint(structure),
    avgProgression: findAvgProgression(structure).toFixed(1)
  };
}

export function calcSalaryMin(salaryMidpoint, spread) {
  const salaryMinimum = salaryMidpoint / (1 + (spread * 0.01) / 2);
  return salaryMinimum;
}

export function calcSalaryMax(salaryMinimum, spread) {
  const salaryMax = salaryMinimum * (1 + 0.01 * spread);
  return salaryMax;
}

export function calcMinPctMid(salaryMinimum, salaryMidpoint) {
  const minPctMid = (salaryMinimum / salaryMidpoint) * 100.0;
  return minPctMid;
}

export function calcMaxPctMid(salaryMaximum, salaryMidpoint) {
  const maxPctMid = (salaryMaximum / salaryMidpoint) * 100.0;
  return maxPctMid;
}

export function roundNum(numCalc) {
  let num = parseFloat(numCalc); // eslint-disable-line prefer-const
  const fixedNum = num.toFixed(5);
  const splitFixedNum = fixedNum.toString().split('.');
  if (splitFixedNum[1] == '00') {
    num = parseFloat(splitFixedNum[0]);
  } else {
    num = fixedNum;
  }

  return parseFloat(num);
}

export function setStructureToModelCalcs(currentStuctureItems, modelItems) {
  for (let x = 0; x < currentStuctureItems.length; x++) {
    currentStuctureItems[x] = modelItems[x];
  }
}

export function structureCalcs(structure, options) {
  const grades = parseInt(options.grades, 10);
  const currentStuctureItems = JSON.parse(JSON.stringify(structure));
  const highMid = parseFloat(options.high_mid);
  const lowMid = parseFloat(options.low_mid);
  let rangeSpread = parseFloat(options.range_spread);
  let currentGradeSpread = rangeSpread;
  // console.log(structure);
  const salary_display_code = structure[0].salary_display_code ? structure[0].salary_display_code : 'annual';
  let spreadStep;
  if (options.high_spread && options.low_spread) {
    const lowSpread = parseFloat(options.low_spread);
    const highSpread = parseFloat(options.high_spread);

    const spreadStepTotal = highSpread - lowSpread;
    spreadStep = spreadStepTotal / (grades - 1.0);
    rangeSpread = lowSpread;
    currentGradeSpread = lowSpread;
  }

  let calculatedStructure = []; // eslint-disable-line prefer-const
  let constantProgression = null; // eslint-disable-line prefer-const
  if (highMid && lowMid) {
    constantProgression = Math.pow(highMid / lowMid, 1.0 / (grades - 1.0)) - 1.0;
  }
  // for (let x = 0; x < currentStuctureItems.length; x++) {
  for (let x = 0; x < grades; x++) {
    if (spreadStep) {
      if (x != 0) {
        currentGradeSpread += spreadStep;
      }
    }
    let salaryMidpoint = 0; // eslint-disable-line prefer-const
    let progression = 0; // eslint-disable-line prefer-const
    if (!currentStuctureItems[x]) {
      currentStuctureItems.push({ org_salary_grade: (x + 1).toString() });
      if (currentStuctureItems[x].org_salary_grade.toString().length <= 1) {
        currentStuctureItems[x].org_salary_grade = '0' + currentStuctureItems[x].org_salary_grade.toString();
      }
      currentStuctureItems[x].salary_minimum = currentStuctureItems[x - 1].salary_minimum;
      currentStuctureItems[x].salary_maximum = currentStuctureItems[x - 1].salary_maximum;

      // eslint-disable-next-line no-self-assign
      currentStuctureItems[x].salary_midpoint = currentStuctureItems[x].salary_midpoint;
      currentStuctureItems[x].spread = currentStuctureItems[x - 1].spread;
      currentStuctureItems[x].sort_order = currentStuctureItems[x - 1].sort_order + 1;
      currentStuctureItems[x].num_jobs = currentStuctureItems[x - 1].num_jobs;
      currentStuctureItems[x].num_ees = currentStuctureItems[x - 1].num_ees;
    }
    let salaryMinimum = currentStuctureItems[x].salary_minimum; // eslint-disable-line prefer-const
    let salaryMaximum = currentStuctureItems[x].salary_maximum; // eslint-disable-line prefer-const
    if (x == 0) {
      salaryMidpoint = lowMid ? lowMid : currentStuctureItems[x].salary_midpoint;
      if ((!rangeSpread || rangeSpread === '') && currentStuctureItems[x].spread) {
        currentGradeSpread = currentStuctureItems[x].spread;
      }
      if (constantProgression) {
        salaryMinimum = calcSalaryMin(salaryMidpoint, currentGradeSpread);
        salaryMaximum = calcSalaryMax(salaryMinimum, currentGradeSpread);
      }
    } else if (constantProgression) {
      salaryMidpoint = roundNum(calculatedStructure[x - 1].salary_midpoint * (1.0 + constantProgression));
      progression = roundNum(constantProgression * 100.0);
      salaryMinimum = calcSalaryMin(salaryMidpoint, currentGradeSpread);
      salaryMaximum = calcSalaryMax(salaryMinimum, currentGradeSpread);
    } else {
      salaryMidpoint = roundNum(currentStuctureItems[x].salary_midpoint);
      progression = roundNum((salaryMidpoint / calculatedStructure[x - 1].salary_midpoint - 1) * 100);
    }

    if ((!rangeSpread || rangeSpread === '') && currentStuctureItems[x].spread) {
      currentGradeSpread = currentStuctureItems[x].spread;
    } else {
      currentGradeSpread = (salaryMaximum / salaryMinimum - 1) * 100;
    }

    calculatedStructure.push({
      currency: currentStuctureItems[x].currency,
      org_salary_grade: currentStuctureItems[x].org_salary_grade,
      num_jobs: currentStuctureItems[x].num_jobs,
      num_ees: currentStuctureItems[x].num_ees,
      salary_minimum: salaryMinimum,
      salary_midpoint: salaryMidpoint,
      salary_maximum: salaryMaximum,
      progression: progression,
      spread: currentGradeSpread,
      salary_display_code: salary_display_code,
      min_as_pct_of_mid: calcMinPctMid(salaryMinimum, salaryMidpoint),
      max_as_pct_of_mid: calcMaxPctMid(salaryMaximum, salaryMidpoint),
      sort_order: currentStuctureItems[x].sort_order
    });
  }

  return calculatedStructure;
}

export function calculateStructure(options) {
  return (dispatch, getState) => {
    const calculatedStructure = structureCalcs(getState().currentStructure.savedStructureItems, options);
    dispatch({
      type: CALCULATE_STRUCTURE_SUCCESS,
      calculatedStructure: calculatedStructure
    });
    dispatch({
      type: STRUCTURE_DISPLAY_CALC,
      displayValues: calcDisplayValues(calculatedStructure)
    });
    dispatch(updateChart(getState().structureChart.highChartsJson, calculatedStructure));
  };
}

export function calculateJobBuckets(options) {
  return (dispatch, getState) => {
    const state = getState();
    if (state.currentStructure.modelMode === 'new') {
      const calculatedStructure = structureCalcs(state.currentStructure.savedStructureItems, options);
      const searchParams = {
        structure_code: state.currentStructure.selectedStructureCode,
        structure_code_basis: state.currentStructure.structureCodeBasis,
        modelId: state.currentStructure.modelId,
        model_name: state.currentStructure.modelName,
        mode: state.currentStructure.modelMode,
        proposedData: JSON.stringify(calculatedStructure),
        facetsLoaded: true
      };
      dispatch(structureFilterActions.search({ params: searchParams }));
    }
  };
}

export function findHighMid(structure) {
  let highMidpoint = structure[0].salary_midpoint; // eslint-disable-line prefer-const
  for (let x = 0; x < structure.length; x++) {
    if (structure[x].salary_midpoint > highMidpoint) {
      highMidpoint = structure[x].salary_midpoint;
    }
  }
  return highMidpoint;
}

export function findLowMid(structure) {
  let lowMidpoint = structure[0].salary_midpoint; // eslint-disable-line prefer-const
  for (let x = 0; x < structure.length; x++) {
    if (structure[x].salary_midpoint < lowMidpoint) {
      lowMidpoint = structure[x].salary_midpoint;
    }
  }
  return lowMidpoint;
}

export function findMidpoint(structure) {
  let midpoint = structure[0].salary_midpoint; // eslint-disable-line prefer-const
  const evenOdd = structure.length % 2;
  if (evenOdd == 1) {
    midpoint = structure[structure.length / 2 - 0.5].salary_midpoint;
  } else {
    let xHigh = 0; // eslint-disable-line prefer-const
    let xLow = 0; // eslint-disable-line prefer-const
    if (structure.length > 2) {
      xHigh = structure.length / 2;
      xLow = structure.length / 2 - 1;
    } else if (structure.length == 2) {
      xHigh = 1;
      xLow = 0;
    }

    const lowVal = structure[xLow].salary_midpoint;
    const highVal = structure[xHigh].salary_midpoint;
    midpoint = (lowVal + highVal) / 2;
  }
  return midpoint;
}

export function findAvgProgression(structure) {
  let avgProgression = 0; // eslint-disable-line prefer-const
  for (let x = 0; x < structure.length; x++) {
    avgProgression += structure[x].progression;
  }

  if (structure.length < 2) {
    return 0;
  }

  return avgProgression / (structure.length - 1);
}

export function increaseMidpointPercentCalc(structure, options) {
  const currentStuctureItems = structure;
  const midpointPercent = options.midpointPercent;

  const highMid = findHighMid(currentStuctureItems);
  const lowMid = findLowMid(currentStuctureItems);

  let calculatedStructure = []; // eslint-disable-line prefer-const
  for (let x = 0; x < currentStuctureItems.length; x++) {
    let salaryMidpoint = 0; // eslint-disable-line prefer-const
    salaryMidpoint = roundNum(currentStuctureItems[x].salary_midpoint * (1 + 0.01 * midpointPercent));

    const salaryMinimum = calcSalaryMin(salaryMidpoint, currentStuctureItems[x].spread);
    const salaryMaximum = calcSalaryMax(salaryMinimum, currentStuctureItems[x].spread);

    calculatedStructure.push({
      org_salary_grade: currentStuctureItems[x].org_salary_grade,
      num_jobs: currentStuctureItems[x].num_jobs,
      num_ees: currentStuctureItems[x].num_ees,
      salary_minimum: salaryMinimum,
      salary_midpoint: salaryMidpoint,
      salary_maximum: salaryMaximum,
      progression: currentStuctureItems[x].progression,
      spread: currentStuctureItems[x].spread,
      min_as_pct_of_mid: calcMinPctMid(salaryMinimum, salaryMidpoint),
      max_as_pct_of_mid: calcMaxPctMid(salaryMaximum, salaryMidpoint),
      sort_order: currentStuctureItems[x].sort_order
    });
  }

  return calculatedStructure;
}

export function saveStructure(options, history) {
  return (dispatch, getState) => {
    const calculatedStructure = getState().currentStructure.items;
    const modelId = getState().currentStructure.modelId;
    const saveStructAjax = dispatch({
      types: [SAVE_STRUCTURE, SAVE_STRUCTURE_SUCCESS, SAVE_STRUCTURE_FAIL],
      promise: client =>
        client.post('api/structures/model/saveStructure', {
          params: {
            proposed_data: calculatedStructure,
            model_id: modelId,
            mode: 'existing',
            model_name: options ? options.name : getState().currentStructure.modelName
          }
        }),
      modelName: options ? options.name : getState().currentStructure.modelName,
      selectedStructureCode: options ? 'model ' + options.name : getState().currentStructure.selectedStructureCode,
      calculatedStructure: calculatedStructure
    });
    return saveStructAjax
      .then(function closeeditFormAndSave(results) {
        // Update list with new name
        if (results.success === true) {
          if (options && options.name) {
            dispatch(getModelAndStructure())
              .then(list => {
                if (history) {
                  history.push('/structure-model/' + 'scenario-' + modelId);
                }
              })
              .catch(err => {
                console.error('Error getting model and structure', err);
              });
          }
          if (!options || !options.newStructure) {
            dispatch(editFormClose());
          }
        }
      })
      .catch(err => {
        console.error('Error saving structure', err);
      });
  };
}

export function getNewlyCreatedStructure(options, history) {
  return (dispatch, getState) => {
    // Get the new stucture then do the calcs when it comes back
    const ajax = dispatch({
      types: [NEW_CURRENT_STRUCTURE, NEW_CURRENT_STRUCTURE_SUCCESS, NEW_CURRENT_STRUCTURE_FAIL],
      promise: client =>
        client.get('api/structures/grades/getCurrentStructure', {
          params: { structure_code: options.structure_code, model_id: options.model_id }
        }),
      selectedStructureCode: options.structure_code,
      modelId: options.model_id,
      lastModified: options.last_modified,
      // structureCodeBasis: options.structure_code_basis ? options.structure_code_basis : options.structure,
      modelName: options.model_name,
      options: options,
      modelMode: options.mode
    });
    ajax
      .then(function getDisplayCalcs(result) {
        dispatch(saveStructure(options, history));

        dispatch({
          type: STRUCTURE_DISPLAY_CALC,
          displayValues: calcDisplayValues(getState().currentStructure.items)
        });
      })
      .catch(err => {
        console.error('Error getting current structure', err);
      });
  };
}

export function saveCopiedStructure(options, history) {
  return (dispatch, getState) => {
    const calculatedStructure = increaseMidpointPercentCalc(getState().currentStructure.items, options);
    const structureHighchartsJson = getState().structureChart.highChartsJson;
    const copyAjax = dispatch({
      types: [SAVE_COPIED_STRUCTURE, SAVE_COPIED_STRUCTURE_SUCCESS, SAVE_COPIED_STRUCTURE_FAIL],
      promise: client =>
        client.post('api/structures/grades/saveStructure', {
          params: {
            existing_structure_code_basis: options.existing_structure_code_basis,
            model_name: options.name,
            mode: 'existing',
            model_salary_display_code: getState().currentStructure.items[0].salary_display_code
          }
        }),
      structureCodeBasis: options.existing_structure_code_basis,
      calculatedStructure: calculatedStructure,
      structureHighchartsJson: structureHighchartsJson,
      modelName: options.name
    });
    copyAjax
      .then(function getUpdatedChart(results) {
        if (results.success) {
          const modelId = getState().currentStructure.modelId;
          let doneApis = 0;
          saveStructure()(dispatch, getState)
            .then(() => {
              doneApis++;
              if (doneApis === 2) {
                history.push('/structure-model/' + 'scenario-' + results.model_id);
              }
            })
            .catch(err => {
              console.error('Error saving structure', err);
            });

          dispatch(getModelAndStructure())
            .then(list => {
              doneApis++;
              if (doneApis === 2) {
                history.push('/structure-model/' + 'scenario-' + results.model_id);
              }
            })
            .catch(err => {
              console.error('Error getting model and structure', err);
            });
        }
      })
      .catch(err => {
        console.error('Error saving structure', err);
      });
  };
}

export function updateNewChart(structureHighchartsJson, calculatedStructure) {
  return (dispatch, getState) => {
    let modelStructureItems = getState().currentStructure.items; // eslint-disable-line prefer-const
    let divisor = 1;
    if (modelStructureItems[0].salary_display_code && modelStructureItems[0].salary_display_code === 'hourly') {
      divisor = 1;
    }
    if (calculatedStructure) {
      modelStructureItems = calculatedStructure;
    }

    let modelChartJson = {}; // eslint-disable-line prefer-const
    if (structureHighchartsJson) {
      modelChartJson = JSON.parse(JSON.stringify(structureHighchartsJson));
    } else {
      modelChartJson = JSON.parse(JSON.stringify(getState().currentStructure.structureHighchartsJson));
    }

    modelChartJson.series.forEach((series, index) => {
      if (series.name.split(' ')[0] == 'Minimum' || series.name.split(' ')[1] == 'Minimum') {
        // let calculatedMinSeries = JSON.parse(JSON.stringify(series)); // eslint-disable-line prefer-const
        series.data.forEach(point => {
          if (series.data.length < modelStructureItems.length) {
            let newDataPoints = JSON.parse(JSON.stringify(series.data[series.data.length - 1])); // eslint-disable-line prefer-const
            newDataPoints.name = (series.data.length + 1).toString();
            if (newDataPoints.name.length <= 1) {
              newDataPoints.name = '0' + newDataPoints.name;
            }
            newDataPoints.x = series.data.length;
            newDataPoints.additionalFieldData[0].value = newDataPoints.name;
            series.data.push(newDataPoints);
            // Only push the new category once
            modelChartJson.xAxis.categories.push(newDataPoints.name);
          }
        });
        series.data.forEach(point => {
          modelStructureItems.forEach(gridRow => {
            if (gridRow.org_salary_grade == point.name) {
              point.additionalFieldData[1].value = parseFloat((gridRow.salary_minimum / divisor).toFixed(1));
              point.additionalFieldData[2].value = parseFloat((gridRow.salary_midpoint / divisor).toFixed(1));
              point.additionalFieldData[3].value = parseFloat((gridRow.salary_maximum / divisor).toFixed(1));
              point.high = parseFloat((gridRow.salary_midpoint / divisor).toFixed(1));
              point.low = parseFloat((gridRow.salary_minimum / divisor).toFixed(1));
            }
          });
        });
      }

      if (series.name.split(' ')[0] == 'Midpoint' || series.name.split(' ')[1] == 'Midpoint') {
        // let calculatedMaxSeries = JSON.parse(JSON.stringify(series)); // eslint-disable-line prefer-const
        series.data.forEach(point => {
          if (series.data.length < modelStructureItems.length) {
            let newDataPoints = JSON.parse(JSON.stringify(series.data[series.data.length - 1])); // eslint-disable-line prefer-const
            newDataPoints.name = (series.data.length + 1).toString();
            if (newDataPoints.name.length <= 1) {
              newDataPoints.name = '0' + newDataPoints.name;
            }
            newDataPoints.x = series.data.length;
            newDataPoints.additionalFieldData[0].value = newDataPoints.name;
            series.data.push(newDataPoints);
          }
        });
        series.data.forEach(point => {
          modelStructureItems.forEach(gridRow => {
            if (gridRow.org_salary_grade == point.name) {
              point.additionalFieldData[1].value = parseFloat((gridRow.salary_minimum / divisor).toFixed(1));
              point.additionalFieldData[2].value = parseFloat((gridRow.salary_midpoint / divisor).toFixed(1));
              point.additionalFieldData[3].value = parseFloat((gridRow.salary_maximum / divisor).toFixed(1));
              point.high = parseFloat((gridRow.salary_maximum / divisor).toFixed(1));
              point.low = parseFloat((gridRow.salary_midpoint / divisor).toFixed(1));
            }
          });
        });
      }
    });

    modelChartJson.series.forEach((series, index) => {
      series.data.forEach((dataPoint, dataIndex) => {
        if (dataPoint.x > modelStructureItems.length - 1) {
          modelChartJson.series[index].data[dataIndex] = undefined;
        }
      });
    });

    dispatch({
      type: MODEL_CHART_SUCCESS,
      modelChartJson: modelChartJson
    });
  };
}

export function updateChart(structureHighchartsJson, calculatedStructure) {
  return (dispatch, getState) => {
    if (getState().currentStructure.modelMode == 'new') {
      dispatch(updateNewChart(structureHighchartsJson, calculatedStructure));
    } else {
      let modelStructureItems = getState().currentStructure.items; // eslint-disable-line prefer-const
      let divisor = 1;
      if (modelStructureItems[0].salary_display_code && modelStructureItems[0].salary_display_code === 'hourly') {
        divisor = 1;
      }
      if (calculatedStructure) {
        modelStructureItems = calculatedStructure;
      }

      let modelChartJson = {}; // eslint-disable-line prefer-const
      if (structureHighchartsJson) {
        modelChartJson = JSON.parse(JSON.stringify(structureHighchartsJson));
      } else {
        modelChartJson = JSON.parse(JSON.stringify(getState().currentStructure.structureHighchartsJson));
      }

      modelChartJson.plotOptions.series.groupPadding = 0.3;
      modelChartJson.series.forEach((series, index) => {
        if (series.name.split(' ')[0] == 'Minimum' || series.name.split(' ')[1] == 'Minimum') {
          let calculatedMinSeries = JSON.parse(JSON.stringify(series)); // eslint-disable-line prefer-const
          series.pointPlacement = -0.3;
          series.color = 'rgba(34, 214, 221, 0.40)';

          calculatedMinSeries.data.forEach(point => {
            modelStructureItems.forEach(gridRow => {
              if (gridRow.org_salary_grade == point.name) {
                point.additionalFieldData[1].value = parseFloat((gridRow.salary_minimum / divisor).toFixed(1));
                point.additionalFieldData[2].value = parseFloat((gridRow.salary_midpoint / divisor).toFixed(1));
                point.additionalFieldData[3].value = parseFloat((gridRow.salary_maximum / divisor).toFixed(1));
                point.high = parseFloat((gridRow.salary_midpoint / divisor).toFixed(1));
                point.low = parseFloat((gridRow.salary_minimum / divisor).toFixed(1));
              }
            });
          });
          calculatedMinSeries.name = 'min';
          modelChartJson.series.push(calculatedMinSeries);
        }

        if (series.name.split(' ')[0] == 'Midpoint' || series.name.split(' ')[1] == 'Midpoint') {
          let calculatedMaxSeries = JSON.parse(JSON.stringify(series)); // eslint-disable-line prefer-const
          series.pointPlacement = -0.3;
          series.color = 'rgba(240, 173, 78, 0.40)';

          calculatedMaxSeries.data.forEach(point => {
            modelStructureItems.forEach(gridRow => {
              if (gridRow.org_salary_grade == point.name) {
                point.additionalFieldData[1].value = parseFloat((gridRow.salary_minimum / divisor).toFixed(1));
                point.additionalFieldData[2].value = parseFloat((gridRow.salary_midpoint / divisor).toFixed(1));
                point.additionalFieldData[3].value = parseFloat((gridRow.salary_maximum / divisor).toFixed(1));
                point.high = parseFloat((gridRow.salary_maximum / divisor).toFixed(1));
                point.low = parseFloat((gridRow.salary_midpoint / divisor).toFixed(1));
              }
            });
          });
          calculatedMaxSeries.name = 'max';
          modelChartJson.series.push(calculatedMaxSeries);
        }
        if (series.name.split(' ', 1)[0] == 'Market') {
          series.pointPlacement = 0.24;
        }
      });

      modelChartJson.series.forEach((series, index) => {
        series.data.forEach((dataPoint, dataIndex) => {
          if (dataPoint.x > modelStructureItems.length - 1) {
            modelChartJson.series[index].data[dataIndex] = undefined;
          }
        });
      });

      dispatch({
        type: MODEL_CHART_SUCCESS,
        modelChartJson: modelChartJson
      });
    }
  };
}

export function editFormClose() {
  return (dispatch, getState) => {
    dispatch(updateChart(getState().structureChart.highChartsJson, getState().currentStructure.savedStructureItems));
    dispatch({
      type: STRUCTURE_DISPLAY_CALC,
      displayValues: calcDisplayValues(getState().currentStructure.savedStructureItems)
    });
    dispatch({
      type: 'CLOSE_STRUCTURE_EDIT'
    });
  };
}

export function editFormOpen() {
  return {
    type: 'OPEN_STRUCTURE_EDIT'
  };
}

export function expandSpreadToggle() {
  return (dispatch, getState) => {
    dispatch({
      type: 'EXPEND_SPREAD_TOGGLE',
      switchState: !getState().currentStructure.expandSpreadSwitched
    });
  };
}

export function openDeleteScenarioModal() {
  return {
    type: 'OPEN_DELETE_SCENARIO_MODAL'
  };
}

export function closeDeleteScenarioModal() {
  return {
    type: 'CLOSE_DELETE_SCENARIO_MODAL'
  };
}

export function downloadStructure(options) {
  return (dispatch, getState) => {
    const modelId = getState().currentStructure.modelId;
    dispatch({
      types: [DOWNLOAD_STRUCTURE, DOWNLOAD_STRUCTURE_SUCCESS, DOWNLOAD_STRUCTURE_FAIL],
      promise: client =>
        client
          .post('api/structures/grades/downloadStructure', {
            params: {
              model_id: modelId,
              structure_code_basis: getState().currentStructure.structureCodeBasis,
              structure_code: getState().currentStructure.selectedStructureCode,
              model_name: getState().currentStructure.modelName,
              serialized_data: JSON.stringify(getState().currentStructure.items),
              active_tab: 'proposedStructureGrid',
              downloadMode: 'xlsx',
              columnSalaryGradeText: 'Grade',
              organizationName: window.app.organizationName
            }
          })
          .then(val => {
            let downloadLocation = '';
            if (window.app.hostname !== 'localhost') {
              downloadLocation = '/' + window.app.subsite;
            }
            downloadLocation += '/api/generic-utils/generic-download/downloadGeneric?';
            downloadLocation += 'pretty_name=' + val.resultFileName;
            downloadLocation +=
              '&file_name=' + val.resultFileName + '.' + val.resultFileType + '&download_type=' + val.resultFileType;
            window.location = downloadLocation;
          })
          .catch(err => {
            console.error('Error downloading structure', err);
          })
    });
  };
}

export function deleteScenario(history, options) {
  return (dispatch, getState) => {
    const deleteAjax = dispatch({
      types: [DELETE_SCENARIO, DELETE_SCENARIO_SUCCESS, DELETE_SCENARIO_FAIL],
      promise: client =>
        client.post('api/structures/model/deleteModel', {
          params: { model_id_list: options.modelId }
        })
    })
      .then(val => {
        history.push('/structure-model');
        dispatch(getModelAndStructure());
      })
      .then(val => {
        dispatch({
          type: 'RESET_STRUCTURE_LIST'
        });
      })
      .catch(err => {
        console.error('Error deleting model', err);
      });
  };
}
