import React, { Component } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import './Table.scss';

class Table extends Component {
  static propTypes = {
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    className: PropTypes.string,
    hasStickyColumns: PropTypes.bool,
    getTableProps: PropTypes.func
  };

  static defaultProps = {
    hasStickyColumns: false
  };

  render() {
    const { children, className, hasStickyColumns, getTableProps } = this.props;
    const tableClass = classnames({
      table: true,
      'table-sticky': hasStickyColumns,
      [className]: className
    });

    return (
      <table {...(getTableProps && getTableProps())} className={tableClass}>
        {children}
      </table>
    );
  }
}

export default Table;
