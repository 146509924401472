const COMPARE = 'COMPARE_SEARCH';
const CONFIRM_STEP_ONE = 'CONFIRM_STEP_ONE';
const CONFIRM_STEP_TWO = 'CONFIRM_STEP_TWO';
const OPEN_STEP_ONE = 'OPEN_STEP_ONE';
const CANCEL_PROPAGATE = 'CANCEL_PROPAGATE';
const OPEN_PREVIOUS_STEP = 'OPEN_PREVIOUS_STEP';

const GET_WORKLOCATIONS = 'GET_WORKLOCATIONS';
const GET_WORKLOCATIONS_SUCCESS = 'GET_WORKLOCATIONS_SUCCESS';
const GET_WORKLOCATIONS_FAILURE = 'GET_WORKLOCATIONS_FAILURE';

const ADD_MAPPING = 'ADD_MAPPING';
const ADD_MAPPING_SUCCESS = 'ADD_MAPPING_SUCCESS';
const ADD_MAPPING_FAILURE = 'ADD_MAPPING_FAILURE';

const PROPAGATE_JOBS = 'PROPAGATE_JOBS';
const PROPAGATE_JOBS_SUCCESS = 'PROPAGATE_JOBS_SUCCESS';
const PROPAGATE_JOBS_FAILURE = 'PROPAGATE_JOBS_FAILURE';

const initialState = {
  loading: false,
  step: 'one',
  showModal: false,
  workLocations: {},
  selectedLocations: []
};

function setWorkLocation(rows, key, value) {
  for (let i = 0; i < rows.length; i++) {
    const currentRow = rows[i];
    if (currentRow.org_job_code_key == key) {
      currentRow.payscale_location = value;
    }
  }
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case OPEN_STEP_ONE:
      return {
        ...state,
        loading: false,
        step: 'one',
        showModal: true
      };
    case CANCEL_PROPAGATE:
      return {
        ...state,
        loading: false,
        step: 'one',
        showModal: false
      };
    case CONFIRM_STEP_ONE:
      return {
        ...state,
        loading: false,
        step: 'two',
        showModal: true
      };
    case CONFIRM_STEP_TWO:
      return {
        ...state,
        loading: false,
        step: 'two',
        showModal: true
      };
    case OPEN_PREVIOUS_STEP:
      return {
        ...state,
        loading: false,
        step: 'one',
        showModal: true
      };
    case GET_WORKLOCATIONS:
      return {
        ...state
      };
    case GET_WORKLOCATIONS_SUCCESS:
      return {
        ...state,
        workLocations: action.result
      };
    case GET_WORKLOCATIONS_FAILURE:
      return {
        ...state,
        sucess: false,
        error: action.result
      };
    case ADD_MAPPING:
      return {
        ...state
      };
    case ADD_MAPPING_SUCCESS:
      setWorkLocation(state.workLocations.result, action.result.jobCodeKey, action.result.payscaleLocation);
      let locations = state.selectedLocations;
      const locationIndex = locations.findIndex(obj => obj.jobCodeKey === action.selectedLocation.jobCodeKey);
      if (locationIndex && locationIndex < 0) {
        locations.push(action.selectedLocation);
      } else {
        locations[locationIndex] = action.selectedLocation;
      }
      return {
        ...state,
        selectedLocations: locations
      };
    case ADD_MAPPING_FAILURE:
      return {
        ...state,
        sucess: false,
        error: action.result
      };
    case PROPAGATE_JOBS:
      return {
        ...state
      };
    case PROPAGATE_JOBS_SUCCESS:
      return {
        ...state,
        success: true,
        step: 'three'
      };
    case PROPAGATE_JOBS_FAILURE:
      return {
        ...state,
        success: false,
        step: 'three',
        error: action.result
      };
    default:
      return { ...state };
  }
}

export function openStepOne() {
  return {
    type: OPEN_STEP_ONE
  };
}

export function cancelPropagate() {
  return {
    type: CANCEL_PROPAGATE
  };
}

export function confirmStepOne() {
  return {
    type: CONFIRM_STEP_ONE
  };
}

export function confirmStepTwo() {
  return {
    type: CONFIRM_STEP_TWO
  };
}

export function getWorkLocations(orgJobCode, orgJobId) {
  return (dispatch, getState) => {
    dispatch({
      types: ['GET_WORKLOCATIONS', 'GET_WORKLOCATIONS_SUCCESS', 'GET_WORKLOCATIONS_FAILURE'],
      promise: client =>
        client.get('/api/hris/job-details/getWorkLocations', {
          params: {
            orgJobId: orgJobId,
            orgJobCode: orgJobCode
          }
        })
    });
  };
}

export function addWorkLocationMapping(jobCodeKey, payscaleLocation) {
  return (dispatch, getState) => {
    dispatch({
      types: ['ADD_MAPPING', 'ADD_MAPPING_SUCCESS', 'ADD_MAPPING_FAILURE'],
      promise: client =>
        client.get('/api/hris/job-details/addWorkLocationMapping', {
          params: {
            jobCodeKey: jobCodeKey,
            payscaleLocation: payscaleLocation
          }
        }),
      selectedLocation: { jobCodeKey: jobCodeKey, workLocationExact: payscaleLocation }
    });
  };
}

export function propagateJobs(orgJobId, payscaleLocations) {
  return (dispatch, getState) => {
    dispatch({
      types: ['PROPAGATE_JOBS', 'PROPAGATE_JOBS_SUCCESS', 'PROPAGATE_JOBS_FAILURE'],
      promise: client =>
        client.post('/api/hris/jobCopy/copyJobToExisting', {
          params: {
            org_job_id: orgJobId,
            jobs_to_copy: payscaleLocations
          }
        })
    });
  };
}
