import apiClient from 'ps-components/src/lib/apiClient';
import { actions } from './jobsSearch';
import { JOB_ACTION_SLIDEOUT_FAILURE, JOB_ACTION_SLIDEOUT_START } from './jobComparison';
import { selectors as jobSelectors } from './jobsSearch';

const COPY_SELECTED_JOBS = 'COPY_SELECTED_JOBS';
const COPY_SELECTED_JOBS_SUCCESS = 'COPY_SELECTED_JOBS_SUCCESS';
const COPY_SELECTED_JOBS_FAIL = 'COPY_SELECTED_JOBS_FAIL';

const POLL_COPY = 'POLL_COPY';
const POLL_COPY_SUCCESS = 'POLL_COPY_SUCCESS';
const POLL_COPY_FAIL = 'POLL_COPY_FAIL';

const STOP_COPY_POLLING = 'STOP_COPY_POLLING';
const JOB_ACTION_SLIDEOUT_RESULT = 'JOB_ACTION_SLIDEOUT_RESULT';

const CHECK_JOBS_EXIST = 'CHECK_JOBS_EXIST';
const CHECK_JOBS_EXIST_SUCCESS = 'CHECK_JOBS_EXIST_SUCCESS';
const CHECK_JOBS_EXIST_FAILURE = 'CHECK_JOBS_EXIST_FAILURE';

const MOVE_SELECTED_JOBS = 'MOVE_SELECTED_JOBS';
const MOVE_SELECTED_JOBS_SUCCESS = 'MOVE_SELECTED_JOBS_SUCCESS';
const MOVE_SELECTED_JOBS_FAIL = 'MOVE_SELECTED_JOBS_FAIL';

const initialState = {
  copyRequestId: null,
  copyJobPolling: false,
  bulkCopyJobsLoading: false,
  totalJobs: 0,
  jobsCopied: 0
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case COPY_SELECTED_JOBS:
    case MOVE_SELECTED_JOBS:
      return {
        ...state,
        copyRequestId: null,
        bulkCopyJobsLoading: true
      };
    case COPY_SELECTED_JOBS_SUCCESS:
    case MOVE_SELECTED_JOBS_SUCCESS:
      return {
        ...state,
        copyRequestId: action.result.requestId ? action.result.requestId : null,
        bulkCopyJobsLoading: action.result.status === 200 || action.result.success
      };
    case COPY_SELECTED_JOBS_FAIL:
    case MOVE_SELECTED_JOBS_FAIL:
      return {
        ...state,
        copyRequestId: null,
        bulkCopyJobsLoading: false
      };
    case POLL_COPY:
      return {
        ...state,
        copyJobPolling: true,
        bulkCopyJobsLoading: true
      };
    case POLL_COPY_SUCCESS:
      const loading = !!(action.result.waiting + action.result.active);
      return {
        ...state,
        jobsCopied: action.result.completed,
        totalJobs: action.result.waiting + action.result.active + action.result.completed + action.result.failed,
        copyJobPolling: loading,
        bulkCopyJobsLoading: loading,
        isJobsDeleteAlertModalOpen: loading
      };
    case POLL_COPY_FAIL:
      return {
        ...state,
        copyJobPolling: false,
        bulkCopyJobsLoading: false
      };
    case STOP_COPY_POLLING:
      return {
        ...state,
        copyJobPolling: false,
        bulkCopyJobsLoading: false
      };
    case CHECK_JOBS_EXIST:
      return {
        ...state,
        jobsInSelectedLocation: []
      };
    case CHECK_JOBS_EXIST_SUCCESS:
      return {
        ...state,
        jobsInSelectedLocation: action.result?.jobs || []
      };
    case CHECK_JOBS_EXIST_FAILURE:
      return {
        ...state
      };
    default:
      return { ...state };
  }
}

export function copySelectedJobs(location) {
  const masterAccountId = window.app.masterAccountId;
  return async (dispatch, getState) => {
    dispatch({ type: JOB_ACTION_SLIDEOUT_START });

    try {
      dispatch({
        types: [COPY_SELECTED_JOBS, COPY_SELECTED_JOBS_SUCCESS, COPY_SELECTED_JOBS_FAIL],
        promise: client =>
          client.post(`/cms/api/accounts/${masterAccountId}/copy-jobs`, {
            params: { jobIds: getState().jobComparison.selectedJobIdsToCopy, workLocation: location }
          })
      })
        .then(res => {
          if (res.status === 200) {
            dispatch(pollCopyJobsStatus(masterAccountId));
          } else {
            dispatch({ type: JOB_ACTION_SLIDEOUT_FAILURE });
          }
        })
        .catch(err => {
          console.error('Error starting bulk copy', err);
          dispatch(JOB_ACTION_SLIDEOUT_FAILURE);
        });
    } catch (err) {}
  };
}

function pollCopyJobsStatus(masterAccountId) {
  return (dispatch, getState) => {
    const state = getState().bulkCopyJobs;
    const promise = dispatch({
      types: [POLL_COPY, POLL_COPY_SUCCESS, POLL_COPY_FAIL],
      promise: client => client.get(`/cms/api/accounts/${masterAccountId}/copy-jobs/copyJobs/${state.copyRequestId}`)
    });

    promise
      .then(res => {
        if (res.status && res.status === 200 && (res.waiting > 0 || res.active > 0)) {
          setTimeout(() => dispatch(pollCopyJobsStatus(masterAccountId)), res.retryAfterMilliseconds || 2000);
        } else {
          dispatch({
            type: 'STOP_COPY_POLLING'
          });
          dispatch(copyJobsResult(res));
          // it takes just a few seconds to update es after deleting jobs
          setTimeout(() => {
            dispatch(actions.initialSearch());
          }, 3000);
        }
      })
      .catch(err => {
        console.error('Error polling delete jobs', err);
        dispatch(JOB_ACTION_SLIDEOUT_FAILURE);
      });
  };
}

export function moveJobs(locations) {
  const masterAccountId = window.app.masterAccountId;
  return async (dispatch, getState) => {
    const state = getState();
    dispatch({ type: JOB_ACTION_SLIDEOUT_START });

    const bulkActionParams = {
      ...jobSelectors.getSearchParameters(state),
      includedIds: state.jobsList.multiSelectedRows,
      excludedIds: state.jobsList.multiExcludedRows
    };

    try {
      const selectedIdsRes = await apiClient.apiPost('api/elasticsearch/search/getSelectedIds', {
        ...bulkActionParams
      });

      if (selectedIdsRes.status !== 200 || !selectedIdsRes.data.success) {
        dispatch({ type: JOB_ACTION_SLIDEOUT_FAILURE });
        return;
      }

      dispatch({ type: CHECK_JOBS_EXIST });
      const existingJobsRes = await apiClient.apiPost(
        `cms/api/accounts/${window.app.masterAccountId}/org-jobs/checkJobsExist`,
        {
          jobIds: selectedIdsRes.data.selectedIds,
          workLocation: locations[0]
        }
      );

      if (existingJobsRes.status !== 200 || !existingJobsRes.data.data.success) {
        dispatch({ type: JOB_ACTION_SLIDEOUT_FAILURE });
        dispatch({ type: CHECK_JOBS_EXIST_FAILURE });
        return;
      }

      dispatch({ type: CHECK_JOBS_EXIST_SUCCESS, result: existingJobsRes.data?.data });
      if (existingJobsRes.data?.data?.jobs?.length) {
        // Cannot continue if jobs exist in selected location
        return;
      }

      dispatch({
        types: [MOVE_SELECTED_JOBS, MOVE_SELECTED_JOBS_SUCCESS, MOVE_SELECTED_JOBS_FAIL],
        promise: client =>
          client.post(`api/hris/jobCopy/moveJobs`, {
            params: {
              ...bulkActionParams,
              workLocation: locations[0]
            }
          })
      })
        .then(res => {
          if (res.success) {
            dispatch(pollCopyJobsStatus(masterAccountId));
          } else {
            dispatch({ type: JOB_ACTION_SLIDEOUT_FAILURE });
          }
        })
        .catch(err => {
          console.error('Error starting bulk copy', err);
          dispatch(JOB_ACTION_SLIDEOUT_FAILURE);
        });
    } catch (err) {}
  };
}

const copyJobsResult = result => {
  return {
    type: JOB_ACTION_SLIDEOUT_RESULT,
    result
  };
};
