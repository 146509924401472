const JOB_COUNT = 'JOB_COUNT';
const JOB_COUNT_SUCCESS = 'JOB_COUNT_SUCCESS';
const JOB_COUNT_FAIL = 'JOB_COUNT_FAIL';

const initialState = {
  loading: false,
  loaded: false,
  error: null,
  numberOfJobs: null,
  numberOfNeedsAttentionJobs: null
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case JOB_COUNT:
      return {
        ...state,
        loading: true
      };
    case JOB_COUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: null,
        numberOfJobs: action.result.numberOfJobs,
        numberOfNeedsAttentionJobs: action.result.numberOfNeedsAttentionJobs
      };
    case JOB_COUNT_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        data: null,
        error: action.error
      };
    default:
      return { ...state };
  }
}

export function getJobCount(params = { es_query: 'viewed' }) {
  return {
    types: [JOB_COUNT, JOB_COUNT_SUCCESS, JOB_COUNT_FAIL],
    promise: client => client.get('/api/hris/jobs/totalJobs', { params: params })
  };
}
