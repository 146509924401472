import SearchPanel from '../../../../../ps-components/src/redux/facetedSearch';
import { getMatrixGroupDetails } from './groupList';
import { calcSummaryStats } from './groupList';

const nameSpace = 'MATRIX';
const initialState = {
  facetSelection: {},
  facets: [],
  loading: false,
  loaded: false,
  error: null,
  items: [],
  query: '',
  facetIsCheckedByDefault: true
};

export const matrixFacets = new SearchPanel(nameSpace, initialState);

const extendReducer = (state, action) => {
  switch (action.type) {
    case 'GET_MATRIX_SET':
      return {
        ...state,
        facetSelection: {},
        facets: [],
        loaded: false
      };
    default:
      return null;
  }
};

const reducer = matrixFacets.createReducer(extendReducer, {
  searchAPI: '/api/matrix-filters/updateMatrixFilters',
  additionalSearchParams: state => {
    return { matrixSetId: state.meritMatrix.groupList.matrixSetId };
  }
});

export default reducer;

export const matrixFacetActions = matrixFacets.getActions();

export const applyFilters = selectedGroupId => (dispatch, getState) => {
  const state = getState();
  const params = matrixFacets.getSearchParameters(state);
  const facets = matrixFacets.getFacets(state);
  params.facets = JSON.stringify(facets);

  const promise = dispatch({
    types: ['MATRIX_APPLY_FILTERS', 'MATRIX_APPLY_FILTERS_SUCCESS', 'MATRIX_APPLY_FILTERS_FAIL'],
    promise: client => client.post('/api/matrix-filters/applyMatrixFilters', { params })
  });

  promise
    .then((res, err) => {
      if (!err && res.success !== false) {
        if (selectedGroupId) dispatch(getMatrixGroupDetails(selectedGroupId));
        if (res.matrixSetId) dispatch(calcSummaryStats(res.matrixSetId));
      }
    })
    .catch(err => {
      console.error('Error applying matrix filters', err);
    });
};
