import Fuse from 'fuse.js';
import moment from 'moment';

const JOB_AUDIT_TRAIL = 'JOB_AUDIT_TRAIL';
const JOB_AUDIT_TRAIL_SUCCESS = 'JOB_AUDIT_TRAIL_SUCCESS';
const JOB_AUDIT_TRAIL_FAIL = 'JOB_AUDIT_TRAIL_FAIL';
const JOB_AUDIT_DOWNLOAD = 'JOB_AUDIT_DOWNLOAD';
const JOB_AUDIT_DOWNLOAD_SUCCESS = 'JOB_AUDIT_DOWNLOAD_SUCCESS';
const JOB_AUDIT_DOWNLOAD_FAIL = 'JOB_AUDIT_DOWNLOAD_FAIL';
const JOB_AUDIT_FILTER = 'JOB_AUDIT_FILTER';

const OPEN_PRICING_AUDIT_MODAL = 'OPEN_PRICING_AUDIT_MODAL';
const CLOSE_PRICING_AUDIT_MODAL = 'CLOSE_PRICING_AUDIT_MODAL';

const initialState = {
  loading: false,
  loaded: false,
  error: null,
  OpenPricingAuditModal: false,
  jobAuditData: []
};

const fuseOptions = {
  shouldSort: true,
  threshold: 0.3,
  location: 0,
  distance: 100,
  maxPatternLength: 32,
  minMatchCharLength: 1,
  keys: ['eventTimePretty', 'first_name', 'last_name', 'action']
};

function filterHistoryFn(state, query) {
  if (!query || query === '') {
    if (query === '') {
      for (let i = 0; i < state.jobAuditData.length; i++) {
        state.jobAuditData[i].match = true;
      }
    }
    return state.jobAuditData;
  }

  let searchVals = []; // eslint-disable-line prefer-const

  for (let x = 0; x < state.jobAuditData.length; x++) {
    let auditRec = state.jobAuditData[x]; // eslint-disable-line prefer-const
    auditRec.action = '';

    for (let y = 0; y < auditRec.event_data.changes.length; y++) {
      let change = auditRec.event_data.changes[y]; // eslint-disable-line prefer-const
      auditRec.action += ' ' + change.action + ' ' + change.property;

      if (change.removedItems) {
        for (let z = 0; z < change.removedItems.length; z++) {
          auditRec.action += ' ' + change.removedItems[z].auditDisplayValue;
        }
      }
      if (change.addedItems) {
        for (let z = 0; z < change.addedItems.length; z++) {
          auditRec.action += ' ' + change.addedItems[z].auditDisplayValue;
        }
      }
    }

    searchVals.push(auditRec);
  }

  // Returns array of filtered items
  const fuse = new Fuse(searchVals, fuseOptions); // eslint-disable-line new-cap

  const filteredItems = fuse.search(query);

  for (let i = 0; i < state.jobAuditData.length; i++) {
    // Loop through all items returned by the API, and set item.match to false - used for rerendering.
    const item = state.jobAuditData[i];
    item.match = false;
    for (let j = 0; j < filteredItems.length; j++) {
      // Loop through all filtered items for each item in the base list, compare unique ids, and set match
      if (item.eventTimeSort === filteredItems[j].eventTimeSort) {
        item.match = true;
      }
    }
  }

  const arr = state.jobAuditData.map(cut => {
    return { ...cut };
  });
  return [...arr];
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case OPEN_PRICING_AUDIT_MODAL:
      return {
        ...state,
        OpenPricingAuditModal: true
      };
    case CLOSE_PRICING_AUDIT_MODAL:
      return {
        ...state,
        OpenPricingAuditModal: false
      };
    case JOB_AUDIT_TRAIL:
      return state;
    case JOB_AUDIT_TRAIL_SUCCESS:
      return {
        ...state,
        jobAuditData: action.result
      };
    case JOB_AUDIT_TRAIL_FAIL:
      return {
        ...state,
        error: action.result
      };
    case JOB_AUDIT_DOWNLOAD:
      return state;
    case JOB_AUDIT_DOWNLOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: null
      };
    case JOB_AUDIT_DOWNLOAD_FAIL:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: action.result
      };
    case JOB_AUDIT_FILTER:
      return {
        ...state,
        loading: false,
        loaded: true,
        jobAuditData: filterHistoryFn(state, action.query)
      };
    default:
      return { ...state };
  }
}

export function getJobAudit(options) {
  return {
    types: ['JOB_AUDIT_TRAIL', 'JOB_AUDIT_TRAIL_SUCCESS', 'JOB_AUDIT_TRAIL_FAIL'],
    promise: client =>
      client.get('/api/hris/audit-trail/audit-trail/getAuditHistory', {
        params: { orgJobId: options.orgJobId }
      })
  };
}

export function filterJobAudit(query) {
  return {
    type: 'JOB_AUDIT_FILTER',
    query: query
  };
}

export function jobAuditDownload(options) {
  const clientUtcOffset = moment().utcOffset() / 60;
  return (dispatch, getState) => {
    dispatch({
      types: ['JOB_AUDIT_DOWNLOAD', 'JOB_AUDIT_DOWNLOAD_SUCCESS', 'JOB_AUDIT_DOWNLOAD_FAIL'],
      promise: client =>
        client
          .get('/api/hris/audit-trail/job-audit-trail-print/downloadJobAudit', {
            params: {
              orgJobId: options.orgJobId,
              job_title: options.job_title,
              client_utc_offset: clientUtcOffset
            }
          })
          .then(val => {
            let downloadLocation = '';
            if (window.app.hostname !== 'localhost') {
              downloadLocation = '/' + window.app.subsite;
            }

            downloadLocation += '/api/generic-utils/generic-download/downloadGeneric?';
            downloadLocation += 'pretty_name=' + val.resultFileName;
            downloadLocation +=
              '&file_name=' + val.resultFileName + '.' + val.resultFileType + '&download_type=' + val.resultFileType;
            window.location = downloadLocation;
          })
          .catch(err => {
            console.error('Error downloading job audit', err);
          })
    });
  };
}

export function pricingAuditModalOpen() {
  return {
    type: 'OPEN_PRICING_AUDIT_MODAL'
  };
}

export function pricingAuditModalClose() {
  return {
    type: 'CLOSE_PRICING_AUDIT_MODAL'
  };
}
