const HOME_JOB_LIST = 'JOB_LIST';
const HOME_JOB_LIST_SUCCESS = 'JOB_LIST_SUCCESS';
const HOME_JOB_LIST_FAIL = 'JOB_LIST_FAIL';

const initialState = {
  loading: false,
  loaded: false,
  error: null,
  items: []
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case HOME_JOB_LIST:
      return {
        ...state,
        loading: true
      };
    case HOME_JOB_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: null,
        items: action.result
      };
    case HOME_JOB_LIST_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        data: null,
        error: action.error
      };
    default:
      return { ...state };
  }
}

export function createEntityAction() {
  return {
    type: 'ADD_JOB_LAUNCH'
  };
}

export function getHomeJobs(params = { es_query: 'viewed' }) {
  return {
    types: [HOME_JOB_LIST, HOME_JOB_LIST_SUCCESS, HOME_JOB_LIST_FAIL],
    promise: client => client.get('/api/hris/jobs/recentJobs', { params: params })
  };
}
