import React, { Component } from 'react';
import { arrayOf, func } from 'prop-types';
import { ParticipationMatchOrgJob } from '../../../types/MatchReviewTypes';
import DuplicateMatchesOrgJobList from './OrgJobMatchList/OrgJobMatchList';
import DuplicateMatchesSurveyJobList from './SurveyJobMatchList/SurveyJobMatchList';
import './DuplicateMatchesPanel.scss';

class DuplicateMatchesPanel extends Component {
  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.selectedOrgJob) {
      const { orgJobCode, orgJobCodeKey } = prevState.selectedOrgJob;
      //  make sure to find the currently selected duplicate match participation org job reference
      return {
        selectedOrgJob: nextProps.duplicateMatches.find(x => {
          return x.orgJobCode === orgJobCode && x.orgJobCodeKey === orgJobCodeKey;
        })
      };
    } else {
      if (nextProps.duplicateMatches && nextProps.duplicateMatches.length > 0) {
        return {
          selectedOrgJob: nextProps.duplicateMatches[0]
        };
      }
    }
    return null;
  }

  static propTypes = {
    duplicateMatches: arrayOf(ParticipationMatchOrgJob)
  };

  constructor(props) {
    super(props);
    this.doSelectOrgJob = this.doSelectOrgJob.bind(this);
    this.state = { selectedOrgJob: null };
  }

  doSelectOrgJob(orgJob) {
    this.setState({ selectedOrgJob: orgJob });
  }

  render() {
    const { selectedOrgJob } = this.state;
    return (
      <div className="pcenter-match-review-duplicates">
        <div className="pcenter-match-review-duplicates__main-content">
          <DuplicateMatchesOrgJobList
            {...this.props}
            selection={this.state.selectedOrgJob}
            doSelect={this.doSelectOrgJob}
          />
          <DuplicateMatchesSurveyJobList {...this.props} participationOrgJob={selectedOrgJob} />
        </div>
      </div>
    );
  }
}

export default DuplicateMatchesPanel;
