export const ActivationLinkExpirationInDays = 30;

export enum UserStatuses {
    Locked = 'Locked',
    Active = 'Active',
    PendingInvite = 'Pending'
}

// used to match role_name_pretty property
export enum Roles {
    SuperAdmin = 'Superadmin',
}

export enum RoleIds {
    SuperAdmin = 1,
    Administrator = 2,
    Regularuser = 3,
    Manager = 7
}

export enum SubsiteStatus {
    External = 'Active',
    Internal = 'Internal',
    Demo = 'Demo',
    Inactive = 'Inactive'
}

// used to diplay the roles in the Invite edit user slideout
export enum RolesDisplayText {
    SuperAdmin = 'Super Admin',
    RegularUser = 'Regular User',
    TeamManager = 'Team Manager',
    Admin = 'Admin'
}

export enum RolesDescription {
    SuperAdmin = 'A Super Admin can administer multiple accounts and is reserved for PayScale Internal' +
    ' users to provide support for customers.',
    RegularUser = 'A Regular user can access information within Insight Lab and cannot make any' +
    ' updates to user permissions. They do not have the access to Team if the product is enabled.',
    TeamManager = 'A Team Manager can either view their direct reports and jobs; or jobs assigned to them with' +
    ' the Job Collaboration and Team features. Team Managers do not have access to Insight Lab.',
    Admin = 'An Admin can access all information within Insight Lab and can add and modify other users.' +
    ' Admin users also have full access to Team if the product is enabled.'
}
