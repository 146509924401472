import SummaryStats from '../genericGrid/summaryStats';
import { selectors as employeeSelectors } from './employeesSearch';
import { redirectToPdf } from '../../../helpers/utils';

const EMPLOYEES_REMOVE_ALL_SELECTED = 'EMPLOYEES_REMOVE_ALL_SELECTED';
const EMPLOYEES_LIST_ROW_CHECK = 'EMPLOYEES_LIST_ROW_CHECK';
const EMPLOYEES_LIST_ROW_CLICK = 'EMPLOYEES_LIST_ROW_CLICK';
const EMPLOYEES_SET_SELECTED_ROWS = 'EMPLOYEES_SET_SELECTED_ROWS';
const EMPLOYEE_SUMMARY_CHARTS = 'EMPLOYEE_SUMMARY_CHARTS';
const EMPLOYEE_SUMMARY_CHARTS_SUCCESS = 'EMPLOYEE_SUMMARY_CHARTS_SUCCESS';
const EMPLOYEE_SUMMARY_CHARTS_FAILURE = 'EMPLOYEE_SUMMARY_CHARTS_FAILURE';
const EMPLOYEE_LIST_SUMMARY_DOWNLOAD = 'EMPLOYEE_LIST_SUMMARY_DOWNLOAD';
const EMPLOYEE_LIST_SUMMARY_DOWNLOAD_SUCCESS = 'EMPLOYEE_LIST_SUMMARY_DOWNLOAD_SUCCESS';
const EMPLOYEE_LIST_SUMMARY_DOWNLOAD_FAILURE = 'EMPLOYEE_LIST_SUMMARY_DOWNLOAD_FAILURE';
const EMPLOYEE_ANALYTICS_PDF_SET_SECTION = 'EMPLOYEE_ANALYTICS_PDF_SET_SECTION';

const analyticsSectionLocalStorageKey = key => {
  return `ee-analytics-pdf-${key}`;
};

const getAnalyticsSectionFromLocalStorage = key => {
  const localStorageKey = analyticsSectionLocalStorageKey(key);
  const value = window.localStorage.getItem(localStorageKey);
  if (!value) {
    // Default to true
    setAnalyticsSectionFromLocalStorage(key, true);
    return true;
  }

  return value === 'true';
};

const setAnalyticsSectionFromLocalStorage = (key, value) => {
  const localStorageKey = analyticsSectionLocalStorageKey(key);
  window.localStorage.setItem(localStorageKey, value);
};

const initialState = {
  showSummaryStats: true,
  items: [],
  chartDataLoading: false,
  employeeSummaryChartDetails: {},
  error: null,
  analyticsDownloading: false,
  analyticsPdfSectionMap: {
    // top section
    employeeFilters: getAnalyticsSectionFromLocalStorage('employeeFilters'),
    employeeNames: getAnalyticsSectionFromLocalStorage('employeeNames'),
    genderBreakdown: getAnalyticsSectionFromLocalStorage('genderBreakdown'),
    performanceRating: getAnalyticsSectionFromLocalStorage('performanceRating'),
    // charts & graphs
    genderDiversityChart: getAnalyticsSectionFromLocalStorage('genderDiversityChart'),
    payToMarketChart: getAnalyticsSectionFromLocalStorage('payToMarketChart'),
    countToMarketChart: getAnalyticsSectionFromLocalStorage('countToMarketChart'),
    tenureChart: getAnalyticsSectionFromLocalStorage('tenureChart'),
    // market data
    employeeCountToMarket: getAnalyticsSectionFromLocalStorage('employeeCountToMarket'),
    payToMarket: getAnalyticsSectionFromLocalStorage('payToMarket')
  }
};

export const employeeSummaryStats = new SummaryStats('EMPLOYEES', initialState);

function extendReducer(state, action) {
  switch (action.type) {
    case EMPLOYEES_REMOVE_ALL_SELECTED:
      return {
        ...state,
        showSummaryStats: true
      };
    case EMPLOYEES_LIST_ROW_CHECK:
      return {
        ...state,
        showSummaryStats: false
      };
    case EMPLOYEES_LIST_ROW_CLICK:
      return {
        ...state,
        showSummaryStats: false
      };
    case EMPLOYEES_SET_SELECTED_ROWS:
      return {
        ...state,
        showSummaryStats: false
      };
    case EMPLOYEE_SUMMARY_CHARTS:
      return {
        ...state,
        chartDataLoading: true
      };
    case EMPLOYEE_SUMMARY_CHARTS_SUCCESS:
      return {
        ...state,
        chartDataLoading: false,
        error: null,
        employeeSummaryChartDetails: action.result.employeeSummaryChartDetails
      };
    case EMPLOYEE_SUMMARY_CHARTS_FAILURE:
      return {
        ...state,
        chartDataLoading: false,
        employeeSummaryChartDetails: {},
        error: action.error
      };
    case EMPLOYEE_LIST_SUMMARY_DOWNLOAD:
      return {
        ...state,
        analyticsDownloading: true
      };
    case EMPLOYEE_LIST_SUMMARY_DOWNLOAD_SUCCESS:
      return {
        ...state,
        analyticsDownloading: false
      };
    case EMPLOYEE_LIST_SUMMARY_DOWNLOAD_FAILURE:
      return {
        ...state,
        analyticsDownloading: false,
        error: action.error
      };
    case EMPLOYEE_ANALYTICS_PDF_SET_SECTION:
      return {
        ...state,
        analyticsPdfSectionMap: setAnalyticsPdfSections(state.analyticsPdfSectionMap, action.key, action.value)
      };
    default:
      return null;
  }
}

export const reducer = employeeSummaryStats.createReducer(extendReducer, {});

export const actions = employeeSummaryStats.getActions();
export const selectors = employeeSummaryStats.getSelectors();

actions.getEmployeeSummaryCharts = () => {
  return (dispatch, getState) => {
    let searchParams = employeeSelectors.getSearchParameters(getState());

    searchParams = { ...searchParams, limit: null };

    dispatch({
      types: [EMPLOYEE_SUMMARY_CHARTS, EMPLOYEE_SUMMARY_CHARTS_SUCCESS, EMPLOYEE_SUMMARY_CHARTS_FAILURE],
      promise: client => client.post('/api/hris/employeeSummaries/employeeSummaries', { params: searchParams })
    });
  };
};

actions.downloadEmployeeAnalytics = () => {
  return (dispatch, getState) => {
    const queryParamStr = employeeSelectors.getQueryParamString(getState());
    const analyticsPdfSectionMap = getState().employeeSummary.analyticsPdfSectionMap;
    const toggledAnalyticsPdfSections = [];
    for (const [key, value] of Object.entries(analyticsPdfSectionMap)) {
      if (value) {
        toggledAnalyticsPdfSections.push(key);
      }
    }

    dispatch({
      types: [
        EMPLOYEE_LIST_SUMMARY_DOWNLOAD,
        EMPLOYEE_LIST_SUMMARY_DOWNLOAD_SUCCESS,
        EMPLOYEE_LIST_SUMMARY_DOWNLOAD_FAILURE
      ],
      promise: client =>
        client
          .post('/api/util/report-print/printEmployeeAnalytics', {
            params: {
              download_type: 'pdf',
              queryParamStr,
              sections: toggledAnalyticsPdfSections
            }
          })
          .then(redirectToPdf)
    });
  };
};

actions.toggleAnalyticsPdfSection = (key, value) => {
  return (dispatch, getState) => {
    dispatch({
      type: EMPLOYEE_ANALYTICS_PDF_SET_SECTION,
      key,
      value
    });
  };
};

const setAnalyticsPdfSections = (currentSections, key, value) => {
  currentSections[key] = value;
  setAnalyticsSectionFromLocalStorage(key, value);
  return currentSections;
};
