import apiClient from '../../../lib/apiClient';
import { isLab } from '../../../redux/stateSelectors';

export const PARTICIPATION_CENTER_MATCH_REVIEW_INIT = 'PARTICIPATION_CENTER_MATCH_REVIEW_INIT';

export const PARTICIPATION_CENTER_MATCH_REVIEW_LOAD_REVIEW_ITEMS =
  'PARTICIPATION_CENTER_MATCH_REVIEW_LOAD_REVIEW_ITEMS';
export const PARTICIPATION_CENTER_MATCH_REVIEW_LOAD_REVIEW_ITEMS_SUCCESS =
  'PARTICIPATION_CENTER_MATCH_REVIEW_LOAD_REVIEW_ITEMS_SUCCESS';
export const PARTICIPATION_CENTER_MATCH_REVIEW_LOAD_REVIEW_ITEMS_FAILURE =
  'PARTICIPATION_CENTER_MATCH_REVIEW_LOAD_REVIEW_ITEMS_FAILURE';

export const PARTICIPATION_CENTER_MATCH_REVIEW_DUPLICATE_JOBS_INCLUDE_MATCH =
  'PARTICIPATION_CENTER_MATCH_REVIEW_DUPLICATE_JOBS_INCLUDE_MATCH';

//  Duplicate job actions
export const PARTICIPATION_CENTER_MATCH_REVIEW_SUBMIT_CHANGES = 'PARTICIPATION_CENTER_MATCH_REVIEW_SUBMIT_CHANGES';
export const PARTICIPATION_CENTER_MATCH_REVIEW_SUBMIT_CHANGES_SUCCESS =
  'PARTICIPATION_CENTER_MATCH_REVIEW_SUBMIT_CHANGES_SUCCESS';
export const PARTICIPATION_CENTER_MATCH_REVIEW_SUBMIT_CHANGES_FAILURE =
  'PARTICIPATION_CENTER_MATCH_REVIEW_SUBMIT_CHANGES_SUCCESS';

//  Update Actions
export const PARTICIPATION_CENTER_MATCH_REVIEW_UPDATE_MATCH = 'PARTICIPATION_CENTER_MATCH_REVIEW_UPDATE_MATCH';

export const PARTICIPATION_CENTER_MATCH_REVIEW_UPDATE_ZERO_WEIGHTED_MATCHES =
  'PARTICIPATION_CENTER_MATCH_REVIEW_UPDATE_ZERO_WEIGHTED_MATCHES';

//  Review Status
export const PARTICIPATION_CENTER_MATCH_REVIEW_LOADING = 'PARTICIPATION_CENTER_MATCH_REVIEW_LOADING';

//  Step Navigation
export const PARTICIPATION_CENTER_MATCH_REVIEW_STEP_PREVIOUS = 'PARTICIPATION_CENTER_MATCH_REVIEW_STEP_PREVIOUS';
export const PARTICIPATION_CENTER_MATCH_REVIEW_STEP_NEXT = 'PARTICIPATION_CENTER_MATCH_REVIEW_STEP_NEXT';

export const PARTICIPATION_CENTER_MATCH_REVIEW_SET_PARAMS = 'PARTICIPATION_CENTER_MATCH_REVIEW_SET_PARAMS';

export const PARTICIPATION_CENTER_MATCH_REVIEW_RESET = 'PARTICIPATION_CENTER_MATCH_REVIEW_RESET';
export const PARTICIPATION_CENTER_MATCH_REVIEW_RESET_ERRORS = 'PARTICIPATION_CENTER_MATCH_REVIEW_RESET_ERRORS';

export const PARTICIPATION_CENTER_MATCH_REVIEW_CLOSE_ERROR = 'PARTICIPATION_CENTER_MATCH_REVIEW_CLOSE_ERROR';

export const CREATE_SURVEY_MATCHES = 'CREATE_SURVEY_MATCHES';
export const CREATE_SURVEY_MATCHES_SUCCESS = 'CREATE_SURVEY_MATCHES_SUCCESS';
export const CREATE_SURVEY_MATCHES_FAIL = 'CREATE_SURVEY_MATCHES_FAIL';

export const UPDATE_PARTICIPATION_STATUS = 'UPDATE_PARTICIPATION_STATUS';
export const UPDATE_PARTICIPATION_STATUS_SUCCESS = 'UPDATE_PARTICIPATION_STATUS_SUCCESS';
export const UPDATE_PARTICIPATION_STATUS_FAIL = 'UPDATE_PARTICIPATION_STATUS_FAIL';

const requestData = async (dispatch, apiPath, params, submitAction, successAction, failureAction) => {
  dispatch({ type: submitAction });

  const result = await apiClient.apiGet(apiPath, {
    params: params
  });

  if (result.data.success) {
    dispatch({ type: successAction, payload: result.data.data });
    return { success: true, data: result.data.data };
  } else {
    dispatch({ type: failureAction });
    return { success: false };
  }
};

const postData = async (dispatch, apiPath, params, submitAction, successAction, failureAction) => {
  dispatch({ type: submitAction });

  const result = await apiClient.apiPost(apiPath, {
    params: params
  });

  if (result.data.success) {
    dispatch({ type: successAction, payload: result.data.data });
    return true;
  } else {
    dispatch({ type: failureAction });
    return false;
  }
};

export const openContactSupport = history => async (dispatch, getState) => {
  history.push(getState().participationCenter.matchReview.supportURL);
};

export const resetParticipationMatchReview = () => {
  return {
    type: PARTICIPATION_CENTER_MATCH_REVIEW_RESET
  };
};

export const initParticipationMatchReview = (reportURL, supportURL, openReportInNewTab = true) => {
  return {
    type: PARTICIPATION_CENTER_MATCH_REVIEW_INIT,
    payload: {
      reportURL,
      supportURL,
      openReportInNewTab
    }
  };
};

export const closeErrorNotification = () => {
  return {
    type: PARTICIPATION_CENTER_MATCH_REVIEW_RESET_ERRORS
  };
};

export const generateReport = closeSlideout => async (dispatch, getState) => {
  const { reportURL, openReportInNewTab } = getState().participationCenter.matchReview;
  if (openReportInNewTab) {
    window.open(reportURL);
  } else {
    window.location.replace(reportURL);
  }
  dispatch(closeSlideout());

  const sourceMapPk =
    getState().surveyDetails &&
    getState().surveyDetails.survey_details[0] &&
    getState().surveyDetails.survey_details[0].source_map_pk
      ? getState().surveyDetails.survey_details[0].source_map_pk
      : null;

  if (isLab(getState()) && sourceMapPk) {
    dispatch(
      updateParticipationStatus({
        source_map_pk: getState().surveyDetails.survey_details[0].source_map_pk,
        participation_status: 'Downloaded'
      })
    );
  }
  // dispatch(resetParticipationMatchReview());
};

export const reviewMatches = () => async dispatch => {
  dispatch({ type: PARTICIPATION_CENTER_MATCH_REVIEW_STEP_NEXT });
};

export const updateMatch = (matchId, exclude) => async dispatch => {
  dispatch({
    type: PARTICIPATION_CENTER_MATCH_REVIEW_UPDATE_MATCH,
    payload: {
      matchId,
      exclude
    }
  });
};

export const updateZeroWeightedMatches = exclude => async dispatch => {
  dispatch({
    type: PARTICIPATION_CENTER_MATCH_REVIEW_UPDATE_ZERO_WEIGHTED_MATCHES,
    payload: {
      exclude
    }
  });
};

export const includeDuplicateMatch = (orgJobCode, orgJobCodeKey, matchId) => async dispatch => {
  dispatch({
    type: PARTICIPATION_CENTER_MATCH_REVIEW_DUPLICATE_JOBS_INCLUDE_MATCH,
    payload: {
      matchId,
      orgJobCode,
      orgJobCodeKey
    }
  });
};

export const finishReviewing = closeSlideout => async (dispatch, getState) => {
  //  STEP #1: Submit Changes
  const { duplicateMatches, zeroWeightedMatches } = getState().participationCenter.matchReview;

  //  Grab all of the excluded match ids, since a match id can be 0 weighted and have duplicates
  //  use a set to avoid duplicate id's
  const removeDuplicateMatchIds = [];
  const removeZeroWeightedMatchIds = [];

  duplicateMatches.forEach(orgJobMatch => {
    orgJobMatch.matches.forEach(surveyJobMatch => {
      if (surveyJobMatch.exclude) {
        removeDuplicateMatchIds.push(surveyJobMatch.matchId);
      }
    });
  });

  zeroWeightedMatches.forEach(m => {
    if (m.exclude) removeZeroWeightedMatchIds.push(m.matchId);
  });

  dispatch({ type: PARTICIPATION_CENTER_MATCH_REVIEW_RESET_ERRORS });

  if (removeDuplicateMatchIds.length !== 0 || removeZeroWeightedMatchIds.length !== 0) {
    const success = await postData(
      dispatch,
      '/api/pcenter/survey-matches/updateParticipationMatches',
      {
        updates: [
          {
            auditReason: 'duplicate survey',
            addSurveyJobMatches: [],
            removeSurveyJobMatchIds: removeDuplicateMatchIds
          },
          {
            auditReason: 'zero weighted survey',
            addSurveyJobMatches: [],
            removeSurveyJobMatchIds: removeZeroWeightedMatchIds
          }
        ]
      },
      PARTICIPATION_CENTER_MATCH_REVIEW_SUBMIT_CHANGES,
      PARTICIPATION_CENTER_MATCH_REVIEW_SUBMIT_CHANGES_SUCCESS,
      PARTICIPATION_CENTER_MATCH_REVIEW_SUBMIT_CHANGES_FAILURE
    );

    if (success) {
      dispatch(generateReport(closeSlideout));
    } else {
      //  TODO: MPE-3980 Retrieve data handling needs to be addressed
    }
  } else {
    dispatch(generateReport(closeSlideout));
  }
};

export const loadParticipationMatchReviewItems = surveySourceId => async dispatch => {
  await requestData(
    dispatch,
    '/api/pcenter/survey-matches/getParticipationMatchReviewItems',
    {
      surveySourceId
    },
    PARTICIPATION_CENTER_MATCH_REVIEW_LOAD_REVIEW_ITEMS,
    PARTICIPATION_CENTER_MATCH_REVIEW_LOAD_REVIEW_ITEMS_SUCCESS,
    PARTICIPATION_CENTER_MATCH_REVIEW_LOAD_REVIEW_ITEMS_FAILURE
  );
};

export const updateParticipationStatus = surveyStatusData => async dispatch => {
  await requestData(
    dispatch,
    '/api/hris/surveys/setParticipationStatus',
    {
      ...surveyStatusData
    },
    UPDATE_PARTICIPATION_STATUS,
    UPDATE_PARTICIPATION_STATUS_SUCCESS,
    UPDATE_PARTICIPATION_STATUS_FAIL
  );
};

// This function currently only being used for lab
export const initialParticipationMatchesAction = options => async dispatch => {
  const request = await requestData(
    dispatch,
    '/api/pcenter/pcenter-queries/createSurveyMatches',
    {
      ...options.surveyData
    },
    CREATE_SURVEY_MATCHES,
    CREATE_SURVEY_MATCHES_SUCCESS,
    CREATE_SURVEY_MATCHES_FAIL
  );

  if (request.success) {
    dispatch(
      updateParticipationStatus({
        source_map_pk: options.surveyData.source_map_pk,
        participation_status: 'In Progress'
      })
    );
    await dispatch(initParticipationMatchReview(options.reportUrl, options.supportURL, options.openReportInNewTab));
    await dispatch(loadParticipationMatchReviewItems(options.surveySourceId));
  }
};

export const navigateNextStep = () => {
  return { type: PARTICIPATION_CENTER_MATCH_REVIEW_STEP_NEXT };
};

export const navigatePreviousStep = () => {
  return { type: PARTICIPATION_CENTER_MATCH_REVIEW_STEP_PREVIOUS };
};
