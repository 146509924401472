import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import IdleTimer from 'react-idle-timer';
import { useIdentity } from '@payscale/identity-auth-react-client';
import AlertMessage from '../AlertMessage/AlertMessage';
import apiClient from '../lib/apiClient';

import './IdleTimeWatcher.scss';

const IDLE_MESSAGE_TITLE = 'Still there?';
const IDLE_MESSAGE = 'Your session will expire soon.  Do you want to remain logged in and continue working?';
const IDLE_MESSAGE_SECONDS = 60 * 2; // 2 minute warning - change to something shorter for local testing
const MAX_IDLE_TIME = 1000 * 60 * 30; // 30 minute idle time - change to something shorter for local testing
const CHECK_IDLE_INTERVAL = 1000 * 60; // 60 second check interval - change to something shorter for testing
const IDLE_WARNING_TIME = MAX_IDLE_TIME - 1000 * IDLE_MESSAGE_SECONDS;
const LAST_ACTION_TIME_KEY = 'lastActionTime';
const LAST_VALIDATE_TIME_KEY = 'lastValidateTime';

const IdleTimeWatcher = props => {
  const { enabled, identityEnabled, logout } = props;
  const [documentTitle, setDocumentTitle] = useState(document.title);
  const [showIdleWarning, setShowIdleWarning] = useState(false);
  const interval = useRef(null);

  let logoutFunc = logout;
  let recordUserActivity = () => {};
  if (identityEnabled) {
    const identity = useIdentity();
    logoutFunc = () => identity.logout({ message: `user idle for ${MAX_IDLE_TIME / 1000} seconds` });
    recordUserActivity = identity.recordUserActivity;
  }

  useEffect(() => {
    window.localStorage.setItem(LAST_ACTION_TIME_KEY, Date.now());
    window.localStorage.setItem(LAST_VALIDATE_TIME_KEY, Date.now());
  }, []);

  useEffect(() => {
    if (enabled && !interval.current) {
      resetIdleTimer();
      resetActionTimer();
      interval.current = setInterval(() => {
        checkIdleTime(logoutFunc, setShowIdleWarning);
      }, CHECK_IDLE_INTERVAL);
    } else if (!enabled && interval.current) {
      clearTimeout(interval.current);
      interval.current = null;
    }

    // Cleanup
    return () => {
      if (interval.current) {
        clearTimeout(interval.current);
      }
    };
  }, [enabled]);

  const doOnAction = () => onAction(showIdleWarning, setShowIdleWarning, documentTitle, enabled, recordUserActivity);

  return (
    <div className="idle-time-watcher">
      <IdleTimer
        startOnMount={false}
        ref={idleTimerRef}
        element={document}
        onIdle={() => onIdle(enabled, showIdleWarning, setShowIdleWarning, setDocumentTitle)}
        onAction={doOnAction}
        throttle={1000} // milliseconds
        timeout={IDLE_WARNING_TIME}
      />

      <AlertMessage
        show={showIdleWarning}
        actions={{
          confirm: doOnAction,
          cancel: doOnAction
        }}
        confirmButtonText="Yes, stay logged in"
        hideCancelButton={true}
        hideXButton={true}
        title={IDLE_MESSAGE_TITLE}
        message={IDLE_MESSAGE}
      />
    </div>
  );
};

IdleTimeWatcher.propTypes = {
  enabled: PropTypes.bool,
  identityEnabled: PropTypes.bool,
  logout: PropTypes.func
};

IdleTimeWatcher.defaultProps = {
  enabled: false
};

export default IdleTimeWatcher;

/**
 * Access to the react-idle-timer IdleTimer instance
 */
export const idleTimerRef = React.createRef();

/**
 * Restart the react-idle-timer IdleTimer instance
 */
export const resetIdleTimer = () => {
  window.localStorage.setItem(LAST_VALIDATE_TIME_KEY, Date.now());
  idleTimerRef?.current?.reset();
};

export const resetActionTimer = () => {
  window.localStorage.setItem(LAST_ACTION_TIME_KEY, Date.now());
  idleTimerRef?.current?.reset();
};

const checkIdleTime = (logout, setShowIdleWarning) => {
  // Use local storage to coordinate across multiple browser tabs
  let lastValidateTime = window.localStorage.getItem(LAST_VALIDATE_TIME_KEY);
  let idleTime = Date.now() - lastValidateTime;
  if (idleTime > MAX_IDLE_TIME) {
    setShowIdleWarning(false);
    logout();
  } else {
    let lastActionTime = window.localStorage.getItem(LAST_ACTION_TIME_KEY);
    if (lastActionTime > lastValidateTime) {
      // Last action came after last session renew, so renew now
      resetIdleTimer();
      apiClient.apiGet('/api/util/session-data/renew');
    }
  }
};

const onIdle = (enabled, showIdleWarning, setShowIdleWarning, setDocumentTitle) => {
  if (enabled && !showIdleWarning) {
    setShowIdleWarning(true);
    setDocumentTitle(document.title);
    document.title = IDLE_MESSAGE_TITLE;
  }
};

const onAction = (showIdleWarning, setShowIdleWarning, documentTitle, enabled, recordUserActivity) => {
  if (showIdleWarning) {
    setShowIdleWarning(false);
    document.title = documentTitle;
  }
  if (enabled) {
    idleTimerRef?.current?.reset();
  }
  window.localStorage.setItem(LAST_ACTION_TIME_KEY, Date.now());
  recordUserActivity();
};
