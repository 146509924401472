import { mapDispatchToActions } from '../../../../../panther-react/src/helpers/utils.js';

const MIGRATION_LIST = 'MIGRATION_LIST';
const MIGRATION_LIST_SUCCESS = 'MIGRATION_LIST_SUCCESS';
const MIGRATION_LIST_FAILURE = 'MIGRATION_LIST_FAILURE';

export const initialState = {
  migrationsListLoading: false,
  migrationsList: [],
  error: null
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case MIGRATION_LIST:
      return {
        ...state,
        migrationsListLoading: true
      };
    case MIGRATION_LIST_SUCCESS:
      return {
        ...state,
        migrationsListLoading: false,
        error: null,
        migrationsList: action.result.migrationTransferList
      };
    case MIGRATION_LIST_FAILURE:
      return {
        ...state,
        migrationsListLoading: false,
        migrationsList: [],
        error: action.error
      };
    default:
      return { ...state };
  }
}

export const actions = {};

actions.getMigrationsList = function getMigrationsList(options) {
  return (dispatch, getState) => {
    dispatch({
      types: [MIGRATION_LIST, MIGRATION_LIST_SUCCESS, MIGRATION_LIST_FAILURE],
      promise: client => client.get('/api/payscale/jobsTransfer/insightLabTransferStats', { params: options })
    });
  };
};

export const migrationActions = mapDispatchToActions(actions);
