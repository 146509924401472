import PropTypes from 'prop-types';
import React, { Component } from 'react';

import ListRow from './ListRow';
import ListHeader from './ListHeader';

import './ListPanel.scss';

/**
 * A ListPanel is a container that allows data to be rendered in a normal _table_ format as well as a
 * minimized version where a number of field values (and column names) can be rendered underneath the primary
 * field value.
 */
export default class ListPanel extends Component {
  static propTypes = {
    /**
     * Unique ID field property for the items property objects.
     */
    idProp: PropTypes.string,
    /**
     * Test ID field property for unit tests to assign to identify the row
     */
    testIdProp: PropTypes.string,
    /**
     * Ordered set of items for which table rows will be created
     */
    items: PropTypes.array,
    /**
     * Ordered name of columns.
     *
     * Expected object format: {
            dataKey: 'name',
            OR dataKeyRef: (record) => data key lookup
            field\_type\_code: 'text',
            title: 'title',
            optional: true/false (defaults to FALSE)
        }
     */
    columns: PropTypes.array,
    /**
     * Function executed when row check is clicked.
     */
    rowCheckBoxClick: PropTypes.func,
    /**
     * Function executed when row is clicked.
     */
    rowClick: PropTypes.func,
    /**
     * Renders a loading overlay.
     */
    loading: PropTypes.bool,
    /**
     * Maximum number of columns to expand and show in table.
     * Remainder will be rendered in the id column.
     */
    expandedMaxColumnCount: PropTypes.number,
    /**
     * Array of unique ID field property values for rows that are to have checkbox selected.
     */
    selectedListRows: PropTypes.array,
    /**
     * TODO: This is currently not implemented
     * @ignore
     */
    useInfiniteScroll: PropTypes.bool,
    /**
     * Hides row checkboxes.
     */
    hideCheckBox: PropTypes.bool,
    /**
     * Formatting functions for values columns where 'field\_type\_code' matches the formatter index.
     * E.g. 'currency' formats column values where 'field\_type\_code'='currency'
     */
    fieldTypeValueFormatters: PropTypes.object,
    /**
     * Formatting functions for class names for columns where 'field\_type\_code' matches the formatter index.
     * E.g. 'currency' formats column values where 'field\_type\_code'='currency'
     */
    fieldTypeClassFormatters: PropTypes.object,
    /**
     * Hides the table header.
     */
    hideTableHeader: PropTypes.bool,
    /**
     * The table's div wrapper containers css class.
     */
    className: PropTypes.string,
    /** when an item is selected the width will shrink to allow for a detail panel**/
    shrinkOnSelectedItem: PropTypes.bool,
    /**
     * Disables the on click event and provides disabled styling
     */
    isDisabled: PropTypes.bool
  };

  static defaultProps = {
    hideTableHeader: false,
    hideCheckBox: false,
    useInfiniteScroll: false,
    loading: false,
    expandedMaxColumnCount: 1,
    selectedListRows: [],
    fieldTypeValueFormatters: {},
    fieldTypeClassFormatters: {},
    shrinkOnSelectedItem: false,
    isDisabled: false
  };

  constructor(props) {
    super(props);
    this.state = { scroll: null };
  }

  getColumns() {
    const columns = this.props.columns;
    if (!columns.length) return columns;
    const colCount = this.getColumnCount();

    return columns
      .map((col, index, arr) => {
        if (index === 0) {
          return { ...col, children: arr.slice(colCount) };
        }
        return col;
      })
      .slice(0, colCount);
  }

  getColumnCount() {
    return this.props.expandedMaxColumnCount;
  }

  render() {
    const { selectedListRows, shrinkOnSelectedItem } = this.props;
    const columns = this.getColumns();

    let loadingIndicator = '';
    if (this.props.loading) {
      loadingIndicator = (
        <div className="div__container--loadingIndicator" data-test-id="loading">
          <div className="div--loadingIndicator">
            <svg className="loadingIndicator--circle" viewBox="25 25 50 50">
              <circle
                className="circle--path"
                cx="50"
                cy="50"
                r="20"
                fill="none"
                strokeWidth="2"
                strokeMiterlimit="10"
              />
            </svg>
          </div>
        </div>
      );
    }

    const rowIsSelectedClass = selectedListRows.length && shrinkOnSelectedItem ? 'rowIsSelected' : '';

    return (
      <div className={'psListPanel__container ' + this.props.className}>
        {loadingIndicator}
        <table className={'table__listPanel ' + rowIsSelectedClass}>
          <ListHeader {...this.props} columns={columns} />
          <tbody className={'table__body'}>
            {this.props.items.map((item, index) => (
              <ListRow
                item={item}
                index={index}
                selectedListRows={this.props.selectedListRows}
                rowCheckBoxClick={this.props.rowCheckBoxClick}
                key={item[this.props.idProp || '_id']}
                rowClick={this.props.rowClick}
                columns={columns}
                hideCheckBox={this.props.hideCheckBox}
                fieldTypeValueFormatters={this.props.fieldTypeValueFormatters}
                fieldTypeClassFormatters={this.props.fieldTypeClassFormatters}
                idProp={this.props.idProp}
                testIdProp={this.props.testIdProp}
                isDisabled={this.props.isDisabled}
              />
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}
