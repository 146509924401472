import {
  CLEAR_ALERT,
  GET_FAVORITE_LOCATIONS,
  GET_FAVORITE_LOCATIONS_ERROR,
  GET_FAVORITE_LOCATIONS_SUCCESS,
  GET_LOCATIONS,
  GET_LOCATIONS_ERROR,
  GET_LOCATIONS_SUCCESS,
  GET_PAYSCALE_LOCATIONS,
  GET_PAYSCALE_LOCATIONS_ERROR,
  GET_PAYSCALE_LOCATIONS_SUCCESS,
  GET_SUPPORTED_PAYSCALE_LOCATIONS,
  GET_SUPPORTED_PAYSCALE_LOCATIONS_ERROR,
  GET_SUPPORTED_PAYSCALE_LOCATIONS_SUCCESS,
  GET_TREND_SETTINGS,
  GET_TREND_SETTINGS_ERROR,
  GET_TREND_SETTINGS_SUCCESS,
  MAP_LOCATION_SUCCESS,
  SAVE_GEO_DIFFERENTIALS,
  SAVE_GEO_DIFFERENTIALS_SUCCESS,
  SAVE_GEO_DIFFERENTIALS_ERROR,
  SAVE_TREND_SETTINGS,
  SAVE_TREND_SETTINGS_ERROR,
  SAVE_TREND_SETTINGS_SUCCESS,
  SEARCH_PAYSCALE_LOCATIONS_ERROR,
  SEARCH_PAYSCALE_LOCATIONS_START,
  SEARCH_PAYSCALE_LOCATIONS_SUCCESS,
  SET_TREND_METADATA,
  UPDATE_EMPLOYEE_FILTERS,
  GET_EF_CATEGORY_ELEMENTS_START,
  GET_EF_CATEGORY_ELEMENTS_SUCCESS,
  GET_EF_CATEGORY_ELEMENTS_ERROR,
  DOWNLOAD_GEO_CSV_START,
  DOWNLOAD_GEO_CSV_SUCCESS,
  DOWNLOAD_GEO_CSV_ERROR,
  CLEAR_EMPLOYEE_FILTER
} from './GeoDifferentialActions';

const initialState = {
  alertType: null,
  applyLocations: [],
  applyLocationColumns: [],
  applyLocationsLoaded: false,
  applyLocationsLoading: false,
  cachedTrendData: [],
  payScaleLocationColumns: [],
  companyLocationColumns: [],
  payScaleLocationsLoaded: false,
  payScaleLocationsLoading: false,
  companyLocationsLoaded: false,
  companyLocationsLoading: false,
  favoriteLocationsLoaded: false,
  favoriteLocationsLoading: false,
  orgLocations: [],
  settings: {},
  isInProductFeature: true,
  favoriteLocations: [],
  supportedLocations: new Set(),
  companyLocations: [],
  isSavingGeoDifferentials: false,
  isSettingsLoaded: false,
  isSettingsLoading: false,
  isSupportedLocationsLoaded: false,
  isSupportedLocationsLoading: false,
  totalEmployees: 0,
  totalJobs: 0,
  isSearchingPayscaleLocations: false,
  totalLocations: 0,
  employeeFilters: [],
  categoryElements: {
    loading: false,
    loaded: false,
    elements: []
  },
  csvLoading: false,
  favoriteLocationsErrMsg: null,
  favoriteLocationsErrType: null,
  companyLocationsErrMsg: null,
  companyLocationsErrType: null,
  supportedLocationsErrMsg: null,
  supportedLocationsErrType: null,
  applyLocationsErrMsg: null,
  applyLocationsErrType: null
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case CLEAR_ALERT:
      const message = action.errMessage;
      return {
        ...state,
        alertType: null,
        [message]: null
      };
    case GET_PAYSCALE_LOCATIONS:
      return {
        ...state,
        payScaleLocationsLoaded: false,
        payScaleLocationsLoading: true
      };
    case GET_PAYSCALE_LOCATIONS_ERROR:
      return {
        ...state,
        payScaleLocationsLoaded: true,
        payScaleLocationsLoading: false
      };
    case GET_PAYSCALE_LOCATIONS_SUCCESS:
      return {
        ...state,
        payScaleLocationsLoaded: true,
        payScaleLocationsLoading: false,
        orgLocations: action.data
      };
    case SET_TREND_METADATA: {
      const { alertType, columns, showPayScaleLocations, showApplyGeo } = action;
      const newState = {
        ...state,
        alertType
      };

      if (showPayScaleLocations) {
        newState.payScaleLocationColumns = columns;
      } else if (showApplyGeo) {
        newState.applyLocationColumns = columns;
      } else {
        newState.companyLocationColumns = columns;
      }
      return newState;
    }
    case GET_LOCATIONS:
      return {
        ...state,
        companyLocationsLoaded: false,
        companyLocationsLoading: true,
        applyLocationsLoaded: false,
        applyLocationsLoading: true
      };
    case GET_LOCATIONS_ERROR:
      return {
        ...state,
        companyLocationsLoaded: true,
        companyLocationsLoading: false,
        applyLocationsLoaded: true,
        applyLocationsLoading: false,
        companyLocations: [],
        companyLocationsErrMsg: action.errorMessage,
        companyLocationsErrType: action.errorType,
        applyLocationsErrMsg: action.errorMessage,
        applyLocationsErrType: action.errorType
      };
    case GET_LOCATIONS_SUCCESS:
      const total = action.data.applyLocations.reduce((total, row) => {
        return total + parseInt(row?.totalEmployees);
      }, 0);

      return {
        ...state,
        applyLocationsLoaded: true,
        applyLocationsLoading: false,
        applyLocations: action.data.applyLocations,
        companyLocationsLoaded: true,
        companyLocationsLoading: false,
        companyLocations: action.data.companyLocations,
        totalEmployees: total,
        totalLocations: action.data.applyLocations.length
      };
    case GET_TREND_SETTINGS:
      return {
        ...state,
        isSettingsLoading: true,
        isSettingsLoaded: false
      };
    case GET_TREND_SETTINGS_ERROR:
      return {
        ...state,
        isSettingsLoading: false,
        isSettingsLoaded: false
      };
    case GET_TREND_SETTINGS_SUCCESS:
      return {
        ...state,
        settings: action.data,
        isSettingsLoaded: true,
        isSettingsLoading: false
      };
    case SAVE_TREND_SETTINGS:
      return {
        ...state
      };
    case SAVE_TREND_SETTINGS_ERROR:
      return {
        ...state
      };
    case SAVE_TREND_SETTINGS_SUCCESS:
      return {
        ...state,
        settings: action.data
      };
    case MAP_LOCATION_SUCCESS: {
      const orgLocation = state.orgLocations.find(location => location.formattedLocation === action.location);
      if (orgLocation) {
        orgLocation.mappedPayScaleLocation = action.payScaleLocation;
      }
      return {
        ...state
      };
    }
    case GET_FAVORITE_LOCATIONS:
      return {
        ...state,
        favoriteLocationsLoaded: false,
        favoriteLocationsLoading: true
      };
    case GET_FAVORITE_LOCATIONS_ERROR:
      return {
        ...state,
        favoriteLocationsLoaded: true,
        favoriteLocationsLoading: false,
        favoriteLocationsErrMsg: action.errorMessage,
        favoriteLocationsErrType: action.errorType
      };
    case GET_FAVORITE_LOCATIONS_SUCCESS:
      return {
        ...state,
        favoriteLocationsLoaded: true,
        favoriteLocationsLoading: false,
        favoriteLocations: action.locations
      };
    case GET_SUPPORTED_PAYSCALE_LOCATIONS: {
      return {
        ...state,
        isSupportedLocationsLoading: true
      };
    }
    case GET_SUPPORTED_PAYSCALE_LOCATIONS_ERROR: {
      return {
        ...state,
        isSupportedLocationsLoading: false,
        isSupportedLocationsLoaded: true,
        supportedLocationsErrMsg: action.errorMessage,
        supportedLocationsErrType: action.errorType
      };
    }
    case GET_SUPPORTED_PAYSCALE_LOCATIONS_SUCCESS: {
      return {
        ...state,
        isSupportedLocationsLoading: false,
        isSupportedLocationsLoaded: true,
        supportedLocations: new Set(action.data)
      };
    }
    case SEARCH_PAYSCALE_LOCATIONS_START: {
      return {
        ...state,
        isSearchingPayscaleLocations: true
      };
    }
    case SEARCH_PAYSCALE_LOCATIONS_SUCCESS: {
      return {
        ...state,
        isSearchingPayscaleLocations: false
      };
    }
    case SEARCH_PAYSCALE_LOCATIONS_ERROR: {
      return {
        ...state,
        isSearchingPayscaleLocations: false
      };
    }
    case SAVE_GEO_DIFFERENTIALS: {
      return {
        ...state,
        isSavingGeoDifferentials: true
      };
    }
    case SAVE_GEO_DIFFERENTIALS_SUCCESS: {
      return {
        ...state,
        isSavingGeoDifferentials: false
      };
    }
    case SAVE_GEO_DIFFERENTIALS_ERROR: {
      return {
        ...state,
        isSavingGeoDifferentials: false
      };
    }
    case UPDATE_EMPLOYEE_FILTERS: {
      return {
        ...state,
        employeeFilters: action.filters
      };
    }
    case GET_EF_CATEGORY_ELEMENTS_START: {
      return {
        ...state,
        categoryElements: {
          loading: true,
          loaded: false,
          elements: null
        }
      };
    }
    case GET_EF_CATEGORY_ELEMENTS_SUCCESS: {
      return {
        ...state,
        categoryElements: {
          loading: false,
          loaded: true,
          elements: action.elements
        }
      };
    }
    case GET_EF_CATEGORY_ELEMENTS_ERROR: {
      return {
        ...state,
        categoryElements: {
          loading: false,
          loaded: true,
          elements: null
        }
      };
    }
    case DOWNLOAD_GEO_CSV_START: {
      return {
        ...state,
        csvLoading: true
      };
    }
    case DOWNLOAD_GEO_CSV_SUCCESS: {
      return {
        ...state,
        csvLoading: false
      };
    }
    case DOWNLOAD_GEO_CSV_ERROR: {
      return {
        ...state,
        csvLoading: false
      };
    }
    case CLEAR_EMPLOYEE_FILTER: {
      return {
        ...state,
        employeeFilters: []
      };
    }
    default:
      return state;
  }
}
