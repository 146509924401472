import React from 'react';
import { Modal } from 'react-bootstrap/lib';
import PropTypes from 'prop-types';
import './ProgressStatusOverlay.scss';

const propTypes = {
  inProgress: PropTypes.bool,
  primaryText: PropTypes.string,
  secondaryText: PropTypes.string,
  hideSecondaryText: PropTypes.bool
};

const defaultProps = {
  inProgress: true,
  hideSecondaryText: false
};

const ProgressStatusOverlay = props => {
  if (!props.inProgress) return null;

  const secondaryText = !props.secondaryText ? 'This might take a moment' : props.secondaryText;
  const hideSecondaryText = props.hideSecondaryText === true;

  return (
    <div className="progress-status-popup">
      <div className="progress-status-popup__bar-container">
        <div className="progress-status-popup__bar" />
      </div>
      <div className="progress-status-popup__window">
        <div className="progress-status-popup__primary-text">{props.primaryText}</div>
        {!hideSecondaryText && <div className="progress-status-popup__secondary-text">{secondaryText}</div>}
      </div>
    </div>
  );
};

ProgressStatusOverlay.propTypes = propTypes;
ProgressStatusOverlay.defaultProps = defaultProps;
export default ProgressStatusOverlay;
