import PropTypes from 'prop-types';
import React, { Component } from 'react';

export default class GoogleTagManager extends Component {
  static propTypes = {
    appLoadFinalProps: PropTypes.bool,
    isLab: PropTypes.bool
  };

  componentDidMount() {
    if (!this.props.isLab || !this.props.appLoadFinalProps) {
      return false;
    }
    this.loadGoogleTagManager();
  }

  componentDidUpdate(prevProps) {
    // only render the GTM tag once after all state is ready
    if (!this.props.isLab && !(prevProps.appLoadFinalProps !== true && this.props.appLoadFinalProps === true)) {
      return false;
    }
    this.loadGoogleTagManager();
  }

  loadGoogleTagManager() {
    const s = document.createElement('script');
    s.type = 'text/javascript';
    s.async = true;

    let gtmTag;

    if (
      window.location.hostname.match('lab-dev') ||
      window.location.hostname.match('lab-collab') ||
      window.location.hostname.match('rc3') ||
      window.location.hostname.match('rc4')
    ) {
      // PayScale B2B non-production tag
      gtmTag = 'GTM-WBVD2TN';
    } else if (
      window.location.hostname.match('lab.payscale.com') ||
      window.location.hostname.match('marketpay.payscale.com')
    ) {
      // PayScale B2B Production tag
      gtmTag = 'GTM-W3WP9BD';
    }

    if (gtmTag) {
      s.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                '//www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
              })(window,document,'script','dataLayer','${gtmTag}')`;
      this.instance.appendChild(s);
    }
  }

  render() {
    return <div ref={el => (this.instance = el)} />;
  }
}
