import React, { Component } from 'react';
import { array, func, number } from 'prop-types';
import './JobDetailsSlideOutHeader.scss';

export default class JobDetailsSlideOutHeader extends Component {
  static propTypes = {
    hideSlideout: func,
    modelJobs: array,
    navigateJob: func,
    selectedJobIndex: number
  };

  render() {
    const { hideSlideout, modelJobs, navigateJob, selectedJobIndex } = this.props;
    const showNext = modelJobs.length > 1 && selectedJobIndex !== modelJobs.length - 1;
    const showPrev = modelJobs.length > 1 && selectedJobIndex !== 0;

    return (
      <div className="contents__header">
        <div className="contents__header-left">
          {showPrev && (
            <div
              className="action-container previous"
              onClick={navigateJob.bind(null, -1)}
              data-test-id="jbr-navigate-previous-job-button"
            >
              <div className="action-icon">
                <i className="icon-left-open" />
              </div>
              <div className="action-label">Previous Job</div>
            </div>
          )}
        </div>
        <div className="contents__header-right">
          {showNext && (
            <div
              className="action-container next"
              data-test-id="jbr-navigate-next-job-button"
              onClick={navigateJob.bind(null, 1)}
            >
              <div className="action-label">Next Job</div>
              <div className="action-icon">
                <i className="icon-right-open" />
              </div>
            </div>
          )}
          <button className="contents__header-close" onClick={hideSlideout} data-test-id="jbr-close-job-details-button">
            <i className="icon-cancel" />
          </button>
        </div>
      </div>
    );
  }
}
