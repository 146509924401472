import PropTypes from 'prop-types';
import React, { Component } from 'react';
import isEmpty from 'lodash/isEmpty';
import ReactSelect from '../../../../../ps-components/src/ReactSelect/ReactSelect';

export default class SiteSwitcher extends Component {
  static propTypes = {
    subsite: PropTypes.string,
    subsites: PropTypes.array,
    className: PropTypes.string,
    newSubsites: PropTypes.object
  };

  constructor(props) {
    super(props);

    let value;

    const selected = this.props.subsites.filter(subsite => {
      return subsite.url === this.props.subsite;
    });

    if (!isEmpty(selected)) {
      value = selected[0].url;

      this.state = {
        value: value,
        label: selected[0].org_name
      };
    }
  }

  /*
   Unsuccessful in using this.context.router.transitionTo as
   well as browserHistory.push, falling back to non-React means of
   doing a full page navigation with `window`. Also will not work locally
   due to the difference in URL structure between dev (/views/home/) and prod (/<subsite>/app/views/home/).
   */
  goToSubsite(selectedOption) {
    if (!isEmpty(selectedOption)) {
      window.location.pathname = `/${selectedOption.value}/app/views/bp/`;
    }
  }

  sortByOrgName(first, second) {
    const firstSubsite = first.org_name.toUpperCase();
    const secondSubsite = second.org_name.toUpperCase();

    if (firstSubsite < secondSubsite) {
      return -1;
    }

    if (firstSubsite > secondSubsite) {
      return 1;
    }

    return 0;
  }

  render() {
    if (this.props.subsites.length < 2) {
      return <div />;
    }

    //fullActiveSiteList is for super admins only
    //admins only get the subsites they are assigned to
    let subsiteOptions = this.props.newSubsites ? this.props.newSubsites.fullActiveSiteList : null;
    if ((!subsiteOptions || subsiteOptions.length == 0) && this.props.subsites) {
      subsiteOptions = this.props.subsites;
    }

    // sort a copy of the array without mutating the existing props
    const options = subsiteOptions.sort(this.sortByOrgName).map(subsite => {
      return { value: subsite.url, label: subsite.org_name };
    });

    return (
      <ReactSelect
        name="switch_to_subsite"
        id="site-switcher"
        placeholder={this.state ? this.state.label : ''}
        options={options}
        getOptionValue={option => option.value}
        getOptionLabel={option => option.label}
        className={this.props.className}
        onChange={this.goToSubsite.bind(this)}
        isClearable={false}
      />
    );
  }
}
