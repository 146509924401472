import React, { Component } from 'react';
import { arrayOf, shape, string, array, number, func } from 'prop-types';
import Range from 'rc-slider/lib/Range';
import 'rc-slider/assets/index.css';
import './RangeSlider.scss';

class RangeSlider extends Component {
  static propTypes = {
    defaultValue: array,
    value: array,
    max: number,
    min: number,
    onChange: func,
    step: number,
    onAfterChange: func,
    rangeTrackStyle: arrayOf(
      shape({
        backgroundColor: string,
        height: string
      })
    ),
    rangeHandleStyle: arrayOf(
      shape({
        border: string,
        height: string,
        width: string
      })
    )
  };

  render() {
    const {
      defaultValue,
      value,
      max,
      min,
      onChange,
      step,
      onAfterChange,
      rangeTrackStyle,
      rangeHandleStyle
    } = this.props;

    const handleStyle = rangeHandleStyle
      ? rangeHandleStyle
      : [
          { border: '1px solid #c0c0bf', height: '20px', width: '20px' },
          { border: '1px solid #c0c0bf', height: '20px', width: '20px' }
        ];

    const trackStyle = rangeTrackStyle
      ? rangeTrackStyle
      : [{ backgroundColor: '#b5db6f', height: '10px' }, { backgroundColor: '#b5db6f', height: '10px' }];

    const railStyle = { height: '10px' };

    const optionalProps = {};
    if (defaultValue) optionalProps.defaultValue = defaultValue;
    if (value) optionalProps.value = value;

    return (
      <div className="range__slider">
        <Range
          handleStyle={handleStyle}
          max={max}
          min={min}
          onChange={values => onChange(values)}
          onAfterChange={onAfterChange}
          railStyle={railStyle}
          step={step}
          trackStyle={trackStyle}
          {...optionalProps}
        />
      </div>
    );
  }
}

export default RangeSlider;
