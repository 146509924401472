import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { getCurrencies, getUseJobCodeKey, isLab, getReplacementTokens } from '../../../../redux/stateSelectors';

import LoadingIndicator from '../../../../LoadingIndicator/LoadingIndicator';
import SlideOutHeader from './JobDetailsSlideOutHeader/JobDetailsSlideOutHeader';
import JobDetailsSlideOutCharts from './JobDetailsSlideOutCharts/JobDetailsSlideOutCharts';
import './JobRangesJobDetailsSlideOut.scss';
import JobDetailsEmployeeSummary from './JobDetailsEmployees/JobDetailsEmployeeSummary';
import JobDetailsEmployeeTable from './JobDetailsEmployees/JobDetailsEmployeeTable';
import SubLabelList from '../../../../SubLabelList/SubLabelList';
import { getJobRangeCostAnalysisEmployees, navigateJob, openAuditModal } from '../../actions/jobRangeDetails';
import { refreshPricing } from '../../actions/jobRangeDetails/JobDetailsSlideOutSettings';
import { shortDateString } from '../../../../lib/formatters';
import JobDetailsSlideOutSettings from './JobDetailsSlideOutSettings/JobDetailsSlideOutSettings';
import { BUILD_BY_PERCENTAGE, MARKET_RANGE_TYPE } from '../../Utils/constants';

const propTypes = {
  hideSlideout: PropTypes.func,
  jobRangeModel: PropTypes.object,
  job: PropTypes.object,
  loading: PropTypes.bool,
  roundTo: PropTypes.number,
  employeesOffset: PropTypes.number,
  employeesLimit: PropTypes.number,
  showEditJobRangeModel: PropTypes.bool,
  currencies: PropTypes.array,
  summaryStats: PropTypes.object,
  employees: PropTypes.array,
  getJobRangeCostAnalysisEmployees: PropTypes.func,
  navigateJob: PropTypes.func,
  useJobCodeKey: PropTypes.bool,
  openAuditModal: PropTypes.func,
  isLab: PropTypes.bool
};
const defaultProps = {};

const JobRangesSummarySlideOut = props => {
  const {
    hideSlideout,
    jobRangeModel,
    job,
    loading,
    summaryStats,
    navigateJob,
    employees,
    useJobCodeKey,
    openAuditModal,
    isLab,
    replacementTokens,
    preferences,
    modelJobs,
    selectedJobIndex,
    lastModifiedDate
  } = props;

  let payType = undefined;
  if (jobRangeModel) {
    payType = jobRangeModel.payType;
  }

  let showMoreEmployees = null;
  const currentEmployeeMaxIndex = props.employeesLimit + props.employeesOffset;
  const totalEmployees = props.summaryStats ? props.summaryStats.total_employees : 0;
  if (currentEmployeeMaxIndex < totalEmployees) {
    showMoreEmployees = () =>
      props.getJobRangeCostAnalysisEmployees(
        props.job,
        props.employeesOffset + props.employeesLimit,
        props.employeesLimit
      );
  }

  const hasJobCodeKeyReplacement = replacementTokens.find(value => value.token === 'job_code_key_text');
  const jobCodeKeyText =
    hasJobCodeKeyReplacement && hasJobCodeKeyReplacement.value ? hasJobCodeKeyReplacement.value : 'Job Code Key ';
  const baseLocation = window.location.href.split('ranges')[0];
  const pricingDetailsURL = isLab
    ? `${baseLocation}jobs/detail/${job?.org_job_id}`
    : `${baseLocation}market-analysis/jobs/${job?.org_job_id}/pricing`;
  const auditLog = isLab ? null : (
    <a className="range__details-action-link pointer" onClick={() => openAuditModal(job)}>
      Audit Log
    </a>
  );
  const pricingDetails = (
    <a className="range__details-action-link pointer" href={pricingDetailsURL} rel="noreferrer" target="_blank">
      Pricing Details
    </a>
  );
  const subLabels = [
    { label: 'Job Code', text: job?.org_job_code },
    { label: useJobCodeKey && jobCodeKeyText, text: useJobCodeKey && job?.org_job_code_key },
    { label: 'Currency', text: job?.currency }
  ];
  const refreshJobPricing = () => {
    props.refreshPricing(job.id);
  };

  return (
    <div className="job__ranges-job-details-slideout">
      <SlideOutHeader
        hideSlideout={hideSlideout}
        modelJobs={modelJobs}
        navigateJob={navigateJob}
        selectedJobIndex={selectedJobIndex}
      />
      <div className="contents__main">
        <div className="job-info">
          <h3 className="range__details-title">{job && job.org_job_title}</h3>
          <div className="range__details-refresh">
            {props.slideout.open && lastModifiedDate ? (
              <span className="range__details-refresh-text">{`Last update ${shortDateString(
                new Date(lastModifiedDate)
              )}`}</span>
            ) : null}
            {props.loading ? (
              <div className="range__details-refresh-text">
                <i className="icon-spin icon-animate-spin" />
                Refresh...
              </div>
            ) : (
              <a className="range__details-action-link" onClick={refreshJobPricing}>
                Refresh Pricing
              </a>
            )}
          </div>
        </div>
        <div className="job-info">
          <SubLabelList subLabels={subLabels} />
          <div className="range__details-actions">
            {pricingDetails}
            {auditLog}
          </div>
        </div>
        <JobDetailsSlideOutSettings job={job} />
        <div
          className={classnames({
            'main-data-container': props.showEditJobRangeModel,
            'main-data-container--collapsed': !props.showEditJobRangeModel,
            'modified__header-single': props?.job?.build_min_max_by === BUILD_BY_PERCENTAGE,
            'modified__header-double':
              props?.job?.build_min_max_by === BUILD_BY_PERCENTAGE && props?.job?.range_type === MARKET_RANGE_TYPE
          })}
        >
          <JobDetailsSlideOutCharts
            job={job}
            jobRangeModel={jobRangeModel}
            payType={payType}
            preferences={preferences}
            summaryStats={summaryStats}
          />
          <JobDetailsEmployeeSummary {...summaryStats} preferences={preferences} />
          <JobDetailsEmployeeTable
            tableItems={employees}
            preferences={preferences}
            currentSalaryMinimum={job && job.salary_minimum}
            currentSalaryMidPoint={job && job.salary_midpoint}
            currentSalaryMaximum={job && job.salary_maximum}
            proposedSalaryMinimum={job && job.proposed_minimum}
            proposedSalaryMidPoint={job && job.proposed_midpoint}
            proposedSalaryMaximum={job && job.proposed_maximum}
            showMoreEmployees={showMoreEmployees}
            payType={payType}
          />
          <LoadingIndicator loading={loading} />
        </div>
      </div>
    </div>
  );
};

JobRangesSummarySlideOut.propTypes = propTypes;
JobRangesSummarySlideOut.defaultProps = defaultProps;

const connectState = state => ({
  ...state.jobRanges.jobRangeDetails,
  preferences: state?.session?.user?.preferences,
  jobRangeModel: state.jobRanges.modelDetails.jobRangeModel,
  modelJobs: state.jobRanges.modelDetails.filteredModelJobs,
  selectedJobIndex: state.jobRanges.jobRangeDetails.index,
  lastModifiedDate: state.jobRanges.jobRangeDetails.lastModifiedDate,
  currencies: getCurrencies(state),
  useJobCodeKey: getUseJobCodeKey(state),
  replacementTokens: getReplacementTokens(state),
  isLab: isLab(state)
});
const actions = {
  navigateJob,
  getJobRangeCostAnalysisEmployees,
  openAuditModal,
  refreshPricing
};
export default connect(connectState, actions)(JobRangesSummarySlideOut);
